import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'folderFilter'
})

export class FolderFilterPipe implements PipeTransform{

    transform(docs: any[], searchTerm: string){
        //Arguments sent from HTML.
        //const args = arguments;
        const originalDocs = docs.map(e => {
            return Object.assign({}, e);
        });
        if(!docs || !searchTerm){
            return docs;
        }
        if(searchTerm.length < 3){
            return originalDocs;
        }
        else{
            const documents = docs.map( e => e.parentfiles.filter(d => d.filenamechange.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ));
            docs.map( (e, i) => {
                e['parentfiles'] = documents[i];
            });
            return docs;
        }
    }
}