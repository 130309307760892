import { Injectable } from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  sName: string;
  excelFileName: string = '';
  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  cols = ['Acc.No', 'Description', 'Original', 'Adjusting', 'Final', 'PY1', 'Change', 'Map No'];
  summaryCols = ['DATE', 'TEAM MEMBER','CATEGORY', 'NOTES', 'TIME SPENT(HRS)', 'HOURLY RATE($)', 'TOTAL($)'];
  reportTotalData: any = [];
  private  readonly columnNames = [
    'Account code',
    'Account Name',
    'Final Amount',
    'Tax code',
    'Group',
    'Sub-group'
  ];
  constructor() { }
  exportToExcel(userselected, data, engagementDetails, year,ckeys?, exclfilename?,req_cols?,finaltotindex?,edlabel?) {
    this.excelFileName = 'Trial Balance Grouping';
    const workbook = new Excel.Workbook();
    workbook.creator = 'Web';
    workbook.lastModifiedBy = 'Web';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', ySplit: 6, activeCell: 'A1', showGridLines: true }] });
    const sheet = workbook.getWorksheet(1);
    sheet.mergeCells('A1', 'H2');
    sheet.getCell('A1').value = engagementDetails.clientName ? engagementDetails.clientName : '';
    sheet.getCell('A1').alignment = { horizontal: 'left' };
    sheet.getCell('A1').font = { name: 'Calibri', family: 4, size: 12, bold: true }

    sheet.mergeCells('A3', 'H3');
    sheet.getCell('A3').value = year ? edlabel ==undefined?'As at'+ ' ' + year :edlabel + ' ' + year : ' ';
    sheet.getCell('A3').alignment = { horizontal: 'left' };
    sheet.getCell('A3').font = { name: 'Calibri', family: 4, size: 12, bold: true }

    sheet.mergeCells('A4', 'H4');
    sheet.getCell('A4').value = 'Trial Balance Grouping';
    sheet.getCell('A4').alignment = { horizontal: 'left' };
    sheet.getCell('A4').font = { name: 'Calibri', family: 4, size: 12, bold: true }

    sheet.addRow('');
    sheet.getRow(6).values =req_cols?req_cols: this.cols;
    sheet.getRow(6).font={bold:true};
    sheet.columns = ckeys?ckeys:[
      { key: 'accountcode' },
      { key: 'accountname' },
      { key: 'originalbalance' },
      { key: 'adjustmentamount' },
      { key: 'finalamount' },
      { key: 'py1' },
      { key: userselected },
      { key: 'mapno' },
      // { key: 'py2' },
      // { key: 'leadsheetcode' },
      // { key: 'fingroupname' },
      // { key: 'finsubgroupname' },
      // { key: 'gificode' },
      // { key: 'fxtranslation' },
      // { key: 'mappingstatus' }
    ];
    sheet.addRows(data);

    sheet.columns.forEach(function (column, index) {
      if (index === 0 || index === 8) {
        column.width = 10;
      } else if (index === 1) {
        column.width = 17;
      } else if (index === 4 || index === 6 || index === 7) {
        column.width = 15;
      } else if(index === 2 || index === 5){
        column.width = 8;
       } else if (index === 3) {
        column.width = 45;
      }
    });

    sheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      if (rowNumber > 5) {
        row.eachCell(function (cell, colNumber) {
          if (colNumber > 0 && colNumber< 4) {
            cell.alignment = {
              vertical: 'middle', horizontal: 'center'
            };
          }
          else if(colNumber > 4){
            cell.alignment = {
              vertical: 'middle', horizontal: 'right'
            };
          }

        });
      }
    });

    if(finaltotindex){
    finaltotindex.forEach(e => {
      sheet.getRow(e).border = {
        top: {style:'thin', color: {argb:'000000'}},
        bottom: {style:'thin', color: {argb:'000000'}},
      }
      sheet.getRow(e).font = {bold:true};
    });
  }

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = this.excelFileName?this.excelFileName:exclfilename;
      a.click();
    });
  }
  exportSummary(data, yearEndDate, companyName, reportType, isBorder){
    this.excelFileName = 'Time_Summary';
    const workbook = new Excel.Workbook();
    workbook.creator = 'Web';
    workbook.lastModifiedBy = 'Web';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', ySplit: 6, activeCell: 'A1', showGridLines: true }] });
    const sheet = workbook.getWorksheet(1);

    sheet.mergeCells('A1', 'G1');
    sheet.getCell('A1').value = companyName;
    sheet.getCell('A1').alignment = { horizontal: 'left' };
    sheet.getCell('A1').font = { name: 'Calibri', family: 4, size: 12, bold: true }

    sheet.mergeCells('A2', 'G2');
    sheet.getCell('A2').value = reportType;
    sheet.getCell('A2').alignment = { horizontal: 'left' };
    sheet.getCell('A2').font = { name: 'Calibri', family: 4, size: 12, bold: true }

    sheet.mergeCells('A3', 'G3');
    sheet.getCell('A3').value = yearEndDate;
    sheet.getCell('A3').alignment = { horizontal: 'left' };
    sheet.getCell('A3').font = { name: 'Calibri', family: 4, size: 12, bold: true }

    sheet.addRow('');
    sheet.getRow(6).values = this.summaryCols;
    sheet.columns = [
      { key: 'date' },
      { key: 'teamMemberName' },
      { key: 'category' },
      { key: 'notes' },
      { key: 'timeSpentHours' },
      { key: 'hourlyRate' },
      { key: 'totals' }
    ];
    //sheet.addRows(data);
    data.forEach((d, index) => {
      let row = sheet.addRow(d);
      //let qty = row.getCell(5);
      const idCol = row.getCell(5);
      const nameCol = row.getCell(6);
      const ageCol = row.getCell(7);
      /* let color = 'FF99FF99';
      if (+qty.value < 500) {
        color = 'FF9999'
      }
      qty.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
      }*/
      idCol.alignment = { horizontal: 'right'};
      nameCol.alignment = { horizontal: 'right'};
      ageCol.alignment = { horizontal: 'right'};
      if(index == data.length-3 && isBorder){
        row.border = { top: { style: 'thin' } }
        //row.font = { name: 'Calibri', family: 4, size: 12, bold: true }
      }
      if(index == data.length-1 && isBorder){
        row.border = { bottom: { style: 'thin' } }
        //row.font = { name: 'Calibri', family: 4, size: 12, bold: true }
      }
      if(index == data.length-3 && !isBorder){
        row.border = { top: { style: 'thin' }, bottom: { style: 'thin' } }
        //row.font = { name: 'Calibri', family: 4, size: 12, bold: true }
      }
    });
    sheet.columns.forEach(function (column, index) {
      if (index === 1 || index === 2) {
        column.width = 25;
      } else if (index === 3) {
        column.width = 45;
      } if (index === 0 || index === 6) {
        column.width = 12;
      } else if (index == 4 || index == 5) {
        column.width = 18;
      }
    });
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = this.excelFileName;
      a.click();
    });
  }

  exportUsTaxCodes(data,fileName:string) {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Set up the column headers
    worksheet.getRow(1).values = this.columnNames;

    // Populate the data rows
    data.forEach((item, index) => {
       worksheet.addRow([
        item.accountcode,
        item.accountname,
        item.finalamount,
        item.gificode,
        item.fingroupname,
        item.finsubgroupname
      ]);
    });

    // Auto-fit column widths
    worksheet.columns.forEach((column) => {
      column.width = 15;
    });

    // Generate the Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: this.blobType });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${fileName}.xlsx`;
      link.click();
      window.URL.revokeObjectURL(url);
    });
  }


}

