import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

/**
 * Please notice that google recaptcha is loaded in the index.html, if we do not load it in the index then
 * we need to use the LoadScript service here.
 */
@Injectable({providedIn: 'root'})
export class CaptchaService {

  readonly CAPTCHA_SITE_KEY = environment.CAPTCHA_SITE_KEY;

  getCaptchaToken(action: string): Promise<string> {
    return window.grecaptcha.execute(this.CAPTCHA_SITE_KEY, {action: action});
  }

  hideCaptchaBanner() {
    this.executeWhenCaptchaReady(() => {
      if ((document.querySelector('.grecaptcha-badge') as HTMLElement)) {
        (document.querySelector('.grecaptcha-badge') as HTMLElement).style.visibility = 'hidden';
      }
    });
  }

  showCaptchaBanner() {
    this.executeWhenCaptchaReady(() => {
      if ((document.querySelector('.grecaptcha-badge') as HTMLElement)) {
        (document.querySelector('.grecaptcha-badge') as HTMLElement).style.visibility = 'visible';
      }
    });
  }

  private executeWhenCaptchaReady(cb) {
    if (typeof window.grecaptcha === 'undefined') {
      const c = '___grecaptcha_cfg';
      window[c] = window[c] || {};
      (window[c]['fns'] = window[c]['fns'] || []).push(cb);
    } else {
      cb();
    }
  }
}
