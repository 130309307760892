import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
// import custom validator to validate that password and confirm password fields match
import { MustMatch } from 'src/app/helpers/must-match.validator';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from '../../services/auth.service';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProfileComponent implements OnInit {

  user: any;
  myprofiledetails: any;
  changePasswordForm: UntypedFormGroup; // Declare the changePassword
  myaccountform: UntypedFormGroup; // Declare the changePassword
  profilePic: any = '';
  userData: any;
  nameAcronym: any;
  profileImage: any;
  updateUserData = {
    'useraccountid': 0,
    'firstname': '',
    'lastname': '',
    'pcphonenumber': 0,
    'pbphonenumber': 0,
    'memberlicenseno': 0,
    'businessname': '',
    'emailaddress': '',
    'address': '',
    'profilepict': ''
  };

  constructor(
    public router: Router,
    public sharedService: SharedService,
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private toastr: ToastrService,
    private apiService: ApiService, private authService: AuthService, private spinner: NgxSpinnerService) { }

  get oldPassword() {
    return this.changePasswordForm.get('oldPassword');
  }

  get newPassword() {
    return this.changePasswordForm.get('newPassword');
  }

  get confirmPassword() {
    return this.changePasswordForm.get('confirmPassword');
  }

  get f() { return this.changePasswordForm.controls; }

  get firstName() {
    return this.myaccountform.get('firstName');
  }

  get lastName() {
    return this.myaccountform.get('lastName');
  }

  get cellPhone() {
    return this.myaccountform.get('cellPhone');
  }

  get businessPhone() {
    return this.myaccountform.get('businessPhone');
  }

  get memberLicenseNo() {
    return this.myaccountform.get('memberLicenseNo');
  }

  ngOnInit() {
    this.userDetails();

    this.myaccountform = this.fb.group({
      'firstName': new UntypedFormControl(this.userData.firstname, [Validators.required]),
      'lastName': new UntypedFormControl(this.userData.lastname, [Validators.required]),
      'cellPhone': new UntypedFormControl(this.userData.cellphonenumber, [Validators.required]),
      'businessPhone': new UntypedFormControl(this.userData.businessphoneNumber, [Validators.required]),
      'memberLicenseNo': new UntypedFormControl(this.userData.licenseno),
      'profileImgFile': ['']
    });

    this.changePasswordForm = this.fb.group({

      'oldPassword': new UntypedFormControl('', [Validators.required]),
      'newPassword': new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      'confirmPassword': new UntypedFormControl('', [Validators.required])
    }, {validator: MustMatch('newPassword', 'confirmPassword')});
  }

  //Submitting form
  public onFormSubmit() {

    if (this.changePasswordForm.valid) {
      this.user = this.changePasswordForm.value;
      let data: any = {
        'puseraccountid': this.userData.useracctid,
        'oldpassword': this.user.oldPassword,
        'newpassword': this.user.newPassword
      };
      this.spinner.show();
      console.log('it should not reach this line! if it does, please contact Mo.');
      this.authService.changePassword(data).subscribe(response => {
        this.spinner.hide();
        if (response) {
          this.toastr.success('');
          sessionStorage.removeItem('userDetails');
          this.router.navigate(['/login']);
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error(error.error);
      });
    }
  }

  //my account Submitting form
  public myAccountSubmit(image) {
    if (this.myaccountform.valid) {
      this.myprofiledetails = this.myaccountform.value;
      this.updateUserData.useraccountid = this.userData.useracctid;
      this.updateUserData.firstname = this.myprofiledetails.firstName;
      this.updateUserData.lastname = this.myprofiledetails.lastName;
      this.updateUserData.pcphonenumber = this.myprofiledetails.cellPhone;
      this.updateUserData.pbphonenumber = this.myprofiledetails.businessPhone;
      this.updateUserData.memberlicenseno = this.myprofiledetails.memberLicenseNo;
      this.updateUserData.businessname = this.userData.businessName;
      this.updateUserData.emailaddress = this.userData.emailAddress;
      this.updateUserData.address = this.userData.address;
      this.updateUserData.profilepict = image ? image : '';
      this.spinner.show();
      this.apiService.updateUserProfile(this.updateUserData).subscribe(response => {
        this.spinner.hide();
        this.toastr.success('Account details updated successfully');
        if (response && response.length > 0) {
          sessionStorage.setItem('userDetails', JSON.stringify(response[0]));
          let updatedDetails: any = response[0];
          this.sharedService.profileObj.next(updatedDetails);
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error(error.error);
      });
    }


  }

  //File upload
  uploadProfilePic(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.profileImage = file;
      const reader = new FileReader();
      reader.onload = e => this.profilePic = reader.result;
      reader.readAsDataURL(file);
    }
  }

  saveProfileDetails() {
    if (this.profileImage) {
      let pathNum: any = 1;
      let formData: FormData = new FormData();
      let s3signatureKey = 'profilepicture/' + this.profileImage.name;
      // formData.append('bucketname', 'adinovisemailtemplateimage');
      formData.append('status', pathNum);
      formData.append('signatureKey', s3signatureKey);
      formData.append('file', this.profileImage, this.profileImage.name);
      this.spinner.show();
      this.apiService.saveProfileImageToS3(formData).subscribe(response => {
        this.spinner.hide();
        if (response) {
          let imageUrl = 'https://s3.amazonaws.com/adinovisemailtemplateimage/profilepicture/' + this.profileImage.name;
          this.myAccountSubmit(imageUrl);
        }
      }, error => {
        this.spinner.hide();
        this.toastr.error('error in updating profile picture');
        this.myAccountSubmit('');
      });
    } else {
      this.myAccountSubmit(this.profilePic);
    }

  }

  userDetails() {
    this.userData = JSON.parse((localStorage.getItem('userDetails')));
    if (this.userData && this.userData.fullName) {
      let matches = this.userData.fullName.match(/\b(\w)/g);
      let acronym = matches.join('');
      if (acronym && acronym.length > 2) {
        acronym = acronym.slice(0, 2);
      }
      this.nameAcronym = acronym;
    } else {
      if (!this.userData) {
        this.router.navigate(['/login']);
      }
    }
    this.profilePic = this.userData ? this.userData.profilepicture : '';
  }

  //
  public handlePhone(event, type) {
    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
    if (onlyNums.length < 10) {
      if (type == 'cell') {
        this.cellPhone.setValue(onlyNums);
      } else if (type == 'business') {
        this.businessPhone.setValue(onlyNums);
      } else {
        return;
      }
    } else if (onlyNums.length === 10) {
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      if (type == 'cell') {
        this.cellPhone.setValue(number);
      } else if (type == 'business') {
        this.businessPhone.setValue(number);
      } else {
        return;
      }
    }
  };

  public onlyChars(event, type) {
    const noSpecialCHars = event.target.value.replace(/[^a-zA-Z]/g, '');
    if (type == 'fn') {
      this.firstName.setValue(noSpecialCHars);
    } else if (type == 'ln') {
      this.lastName.setValue(noSpecialCHars);
    } else {
      return;
    }
  }

  public aplphaNumericInput(event) {
    const noSpecialCHars = event.target.value.replace(/[^a-zA-Z0-9]/g, '');
    this.memberLicenseNo.setValue(noSpecialCHars);
  }

  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57);
  }

}
