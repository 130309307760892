import {Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../../../../../services/api.service';
import {SharedService} from '../../../../shared/shared.service';

@Component({
    selector: 'app-partner-capital-horizontal',
    templateUrl: './partner-capital-horizontal.component.html',
    styleUrls: ['./partner-capital-horizontal.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers     : [DatePipe]
})

export class PartnerCapitalHorizontalComponent implements OnInit {

  @Input() horozontalPanelsState;
  @ViewChild('confirmDelete') confirmDelete: TemplateRef<any>;
  /*-----------------Property Declaration------------------- */
  Data                        : any
  dataObj                     : any = [];
  addindex                    : any
  tablObj                     : any
  selectedEngagementData      : any
  partCapitalHorizontalDetails: any;
  addRow                      : any = [];
  yearEnd                     : number;
  acctYear                    : number;
  userDetails                 : any;
  userLoginId                 : number;
  engagementId                : any;
  inputRow: any=[];
  addRows: any[];
  currentRowDataCopy: any;
  /*--------------Property Declaration End----------------- */
  constructor(
    private apiService  : ApiService,
    public dialog       : MatDialog,
    private datePipe    : DatePipe,
    private toaster     : ToastrService,
    private spinner     : NgxSpinnerService,
    private shareService: SharedService  ) {
    this.horozontalPanelsState = this.horozontalPanelsState;
  }

  ngOnInit() {
    this.selectedEngagementData = JSON.parse(localStorage.getItem('selectedEngagementObj'));
    let year                    = this.datePipe.transform(this.selectedEngagementData.yearEnd, 'y');
    this.yearEnd                = +year;
    this.engagementId           = this.selectedEngagementData.engagementsid;
    this.acctYear               = +year;
    //get the user details
    this.userDetails            = JSON.parse(localStorage.getItem('userDetails'));
    this.userLoginId            = this.userDetails.useracctid;
    this.getPartnerCapitalStatement();
  }
  ngOnChanges(){
    this.horozontalPanelsState = this.horozontalPanelsState;
  }
  delete(data,index, year) {
    let objCopy=data[index];
    //pushing dyanamic object with partnerlist
    this.addRow= {
      "parentid" : objCopy.parentid,
      "linenamechange":objCopy.lineitemname,
      "partnershiplineitemid":objCopy.partnershiplineitemid,
      "yearamount":objCopy.yearamount,
      "issource" : objCopy.issource,
      "isdelete": 1,
      "engagementsid": this.engagementId,
      "acctyear": this.datePipe.transform(year, 'y'),
      "isEdit":true,
      "isAdd":false,
      "partners_list":{"partners_list":[]},
      "loginid": this.userLoginId
    }
    if(data.length>0){
      var dataCopy=data[0];
      if(dataCopy.partners_list.partners_list.length)
        this.addRow.partners_list.partners_list = [];
      var i;
      for (i = 0; i < dataCopy.partners_list.partners_list.length; i++){
        var partObj = {
          "amount":objCopy.partners_list.partners_list[i]['amount'],
          "partnershipaccountid": objCopy.partners_list.partners_list[i]['partnershipaccountid']
        }
        this.addRow.partners_list.partners_list.push(partObj)
      }
    }
    const dialogRef = this.dialog.open(this.confirmDelete, {});
    dialogRef.afterClosed().subscribe(result => { });
  }
  insertData(data, index, year){
    var objCopy=data[index];
    //pushing dyanamic object with partnerlist
    this.addRow= {
      "parentid" : objCopy.parentid,
      "linenamechange":null,
      "partnershiplineitemid":0,
      "yearamount":null,
      // "isAdded":true,
      "issource" : 0,
      "isdelete": 0,
      "engagementsid": this.engagementId,
      "acctyear": this.datePipe.transform(year, 'y'),
      "isEdit":true,
      "isAdd": true,
      "partners_list": {"partners_list":[]},
      "loginid": this.userLoginId
    }

    if(data.length>0){
      var dataCopy=data[0]
      if(dataCopy.partners_list.length)
        this.addRow.partners_list.partners_list=[]
      var i;
      for (i = 0; i < dataCopy.partners_list.partners_list.length; i++){
        var partObj={
          "amount":null,
          //"partnername":objCopy.partners_list[i]['partnername'],
          "partnershipaccountid": objCopy.partners_list.partners_list[i]['partnershipaccountid']
        }
        this.addRow.partners_list.partners_list.push(partObj);
      }
    }
    data.splice(index+1, 0, this.addRow);// pushing object at specified index
  }
  editData(data, index, year,currentRow){

   this.currentRowDataCopy=currentRow;
    let objCopy=data[index];
    //pushing dyanamic object with partnerlist
    this.addRow= {
      "parentid" : objCopy.parentid,
      "linenamechange":objCopy.linenamechange,
      "partnershiplineitemid":objCopy.partnershiplineitemid,
      "yearamount":objCopy.yearamount,
      // "isAdded":true,
      "issource" : objCopy.issource,
      "isdelete": 0,
      "engagementsid": this.engagementId,
      "acctyear": this.datePipe.transform(year, 'y'),
      "isEdit":true,
      "isAdd":false,
      "partners_list":{"partners_list":[]},
      "loginid": this.userLoginId
    }
    if(data.length>0){
      var dataCopy=data[0]
      if(dataCopy.partners_list.length)
        this.addRow.partners_list.partners_list=[]
      var i;
      for (i = 0; i < dataCopy.partners_list.partners_list.length; i++){
        var partObj={
          "amount":objCopy.partners_list.partners_list[i]['amount'],
          //"partnername":objCopy.partners_list[i]['partnername'],
          "partnershipaccountid": objCopy.partners_list.partners_list[i]['partnershipaccountid']
        }
        this.addRow.partners_list.partners_list.push(partObj);
      }
    }
    data.splice(index, 1);
    data.splice(index, 0, this.addRow); // pushing object at specified index
  }
  clearAddedRow(data,index,currRowData){
    currRowData.linenamechange=this.currentRowDataCopy.linenamechange;
    currRowData.acctyear=this.currentRowDataCopy.acctyear;
    currRowData.partners_list.partners_list=this.currentRowDataCopy.partners_list.partners_list;
    //currRowData=this.currentRowDataCopy
    if(data[index].isAdd){
      data[index]['isAdd'] = false;
      data.splice(index, 1);
    }
    else{
      data[index]['isEdit'] = false;
    }
  }
  //get partner-capital details
  getPartnerCapitalStatement() {
    const inputData = {
      "engagementid": this.engagementId,
      "datatype":0 // horizontal "datatype":0 -- vertical "datatype":1
    };
    const inputString = JSON.stringify(inputData);
    let data = {
      "procedureName": "getstatementofpartnerscapital",
      "inputParameters": inputString
    };
    this.spinner.show();
    this.apiService.getData(data).subscribe(response => {
      const responseData = JSON.parse(response);
      if (responseData && responseData.length > 0) {
        this.spinner.hide();
        this.partCapitalHorizontalDetails = responseData;
        for(const body of responseData){
          for (const ele of body.body) {
            ele['isAdd'] = false;
            ele['isEdit'] = false;
          }
        }
      }
      else {
        this.toaster.warning("No data available for your enagegement");
      }
    }, error => {
      this.spinner.hide();
      this.toaster.warning("failed to load partner capital details");
    });
  }
  saveRow(data){
    let data1;
    if(data){
      data1 = data.partners_list.partners_list.filter(ele => ele.amount !== null && ele.amount !== "");
      if(data.linenamechange !== null && data.linenamechange !== "") {
        if(data.linenamechange.length > 255){
          this.toaster.error("Not allowing more than 255 characters!");
          return;
        }
        this.inputRow = [{"amount":data.linenamechange}, ...data1];
      }
      else{
        this.inputRow = data1;
      }
    }
    let rowLength = data.partners_list.partners_list.length+1;
    if(rowLength !== this.inputRow.length){
      this.toaster.error("Please provide required fields!");
    }
    else{
      if(data.isAdd){
        data.issource = 1;
      }
      /* delete data.isEdit;
      delete data.isAdd;
      delete data.yearamount; */
      var obj={
        "hozpartners":  [data]
      }
      const inputObject = JSON.stringify(obj);
      const object = {
        "procedureName"   : "savepartnerscapitalhorizontal",
        "inputParameters" : inputObject
      }

      data['isEdit'] = false;
      data['isAdd'] = false;
      this.spinner.show();
      this.apiService.saveData(object).subscribe(response => {
        if (response) {
          this.getPartnerCapitalStatement();
          this.refreshPreview();
          this.spinner.hide();
          this.toaster.success("Successfully Saved");
        }
      }, error => {
        this.toaster.error("Something Went Wrong!");
        this.spinner.hide();
      });
    }

  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57);
  }
  refreshPreview(){
    this.shareService.refreshPreview.next(0);
  }
}


