export const bot = btoa('uwp7-S@#sc%#m1298-12#$-sizpb-1rgxZedE-90,#!-gnF67#D==');
export const generateId = ['gbaizKUmh6', 'q0c9axwHej', 'i5na9oUwXQ', 'TLpd4iepSy', 'fTkjicq30F', 'mpyH31Eg3v', 'jWPi8BG60J', 'KCqj4VYnxq', 'woZOgZrJTP', 'A3h7abhurJ', 'vad9HNexYt', 'RZgslG2SLE', 'xiEwIQFHxS', 'NohqBYLQJK', 'XyoQZTCiQO', 'tFxjtEATSS', 'Xmb72zcsxi', 'qr7R1L3szj', 'DIeDvLVuac', 'FWfVRE7swa'];
export const procedures = ['firm_member_list',
  'getfirmpartnerassign_test',
  'getdashboardengagement',
  'gettracknotification',
  'getuserwebsocket',
  'getusernotificationengagement',
  'getengagementsetting',
  'getclientonboarding',
  'gettrialbalancesignoff',
  'getleftfinancialstatement',
  'getengdocumnetfolderfile',
  'loginafterupdateuserdata',
  'getfirminformation',
  'getfirmpartnerteamaccess',
  'getuseraccesstype',
  'getnotificationstatus',
  'getengdocrequestresponce',
  'getfirmcountdetails',
  'migratefirmpartnerlogin',
  'getassignteam_new',
  'getnewauditrole',
  'getindividualclient',
  'getteamclient',
  'getusersid',
  'getrightsidepartnerfolder_new',
  'getmastersignoff',
  'getcompletiondetails',
  'getcompletionassignpackager',
  'getcompletionsignoffdisclaimer',
  'getcompletionsignoff',
  'getclientfiles',
  'getinsideengdocrepositary_new',
  'getRFTemplate',
  'getengagementuser',
  'getaccessclients'
];
export const updateProcedures = [
  'saveoriginalbalanceedit',
  'savetrailbalanceadjustmententry_new'
];
export const modules = [
  'Outside ENG Repo', //0
  'Inside ENG Repo',
  'Inside ENG Batch Req',//2
  'Client Response',
  'Inside ENG Left Section',//4
  'Compilation Report',//5
  'Cover Page',
  'Signature',//7
  'Copy File Left: Menu',
  'Copy File Middle: Procedures',//9
  'Copy File Middle: Planning',
  'Copy File Middle: Client Acceptance',//11
  'Copy File Middle: Independence',
  'Copy File Middle: Knowledge Of Client Business',//13
  'Copy File Middle: Completion',
  'Firm Components',//15
  'Notes To FS: Workbook-Middle', //16
  'Notes To FS: Master Template',
  'Notes To FS: Workbook-Update',
  'Notes To FS: FirmTemplate', // 19
  'Notes To FS: FirmTemplate-Update',
  'Reports: Master Template', // 21
  'Reports: Firm Template',
  'Reports: Engagement', //23
  'Reports: Master Template US'
];
export const idleTime = 500000;//100000: 1 minute
export const idleTimeout = 14400;//1800: 30 minute
export const warningTime = 60;//120: 2 minute
export const digitsInfo = '1.2-2';//format: {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}

const apiKey = 'gmqj6nvnn3k4116akcqjx4cufm8dny822m62oqwnkbk684dk'; //'gmqj6nvnn3k4116akcqjx4cufm8dny822m62oqwnkbk684dk';
const config = {
  height: 500,
  plugins: 'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image imagetools link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists textcolor wordcount contextmenu colorpicker textpattern',
  toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent fontselect fontsizeselect | removeformat',
  autosave_ask_before_unload: true,
  autosave_interval: '30s',
  autosave_prefix: '{path}{query}-{id}-',
  autosave_restore_when_empty: false,
  autosave_retention: '2m',
  image_advtab: true,
  branding: false,
  link_list: [
    {title: 'My page 1', value: 'https://www.tiny.cloud'},
    {title: 'My page 2', value: 'http://www.moxiecode.com'}
  ],
  image_list: [
    {title: 'My page 1', value: 'https://www.tiny.cloud'},
    {title: 'My page 2', value: 'http://www.moxiecode.com'}
  ],
  image_class_list: [
    {title: 'None', value: ''},
    {title: 'Some class', value: 'class-name'}
  ],
  importcss_append: true,
  templates: [
    {
      title: 'New Table',
      description: 'creates a new table',
      content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>'
    },
    {title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...'},
    {
      title: 'New list with dates',
      description: 'New List with dates',
      content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>'
    }
  ],
  template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
  template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
  image_caption: true,
  placeholder: 'Type something...',
  quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
  noneditable_noneditable_class: 'mceNonEditable',
  toolbar_mode: 'sliding'
};
export { apiKey, config };
export class ModuleInfo {
  public static MODULE_PROCEDURE = 9;
  public static MODULE_CLIENT_ON_BOARDING_PLANNING = 10;
  public static MODULE_CLIENT_ON_BOARDING_ACCEPTANCE = 11;
  public static MODULE_CLIENT_ON_BOARDING_INDEPENDENCE = 12;
  public static MODULE_CLIENT_ON_BOARDING_KNOWLEDGE = 13;
  public static MODULE_WORKSHEETS = 913;

  public static getS3DestinationDirectory(code: number): string {
    switch (code) {
      case ModuleInfo.MODULE_PROCEDURE:
        return 'Procedure';
      case ModuleInfo.MODULE_CLIENT_ON_BOARDING_ACCEPTANCE:
        return 'Client Acceptance';
      case ModuleInfo.MODULE_CLIENT_ON_BOARDING_PLANNING:
        return 'Planning';
      case ModuleInfo.MODULE_CLIENT_ON_BOARDING_INDEPENDENCE:
        return 'Independence';
      case ModuleInfo.MODULE_CLIENT_ON_BOARDING_KNOWLEDGE:
        return 'Knowledge of client business';
      case ModuleInfo.MODULE_WORKSHEETS:
        return 'WorkSheets';
    } // To be completed for all modules
  }
}

export const enum SubEntityName {
  LLC = '(a limited liability company)',
  CC = '(a C corporation)',
  SC = '(an S corporation)'
}
