export class ActivateTermsModel {
  header: string;
  text: string[];
  isChecked?: boolean = false;
  isDisabled?: boolean = false;
}

export class LucaTermsModel {
  title: string;
  updatedOn: string;
  terms: ActivateTermsModel[] | null = null;
  isChecked: boolean = false;
  showTermsOnly: boolean = false;
}

export class SetUpStepModel {
  title: string;
  stepType: 'INFORMATION' | 'CONFIRMATION';
  step: number;
  nextStep: number;
  nextStepBtn: string;
  isProgress: boolean;
  hasCompleted: boolean;
  stepInfo: string[];
  showStepInfo?: string[] = [];
  subStepDetails: ActivateTermsModel[];
}

export const LucaTermsInfo: ActivateTermsModel[] = [
  {
    header: 'Introduction',
    text: [
      '1.1. These terms and conditions govern your use of Luca, an AI preparer integrated into [Accounting Software Name].',
      '1.2. By using Luca, you agree to comply with these terms and conditions.'
    ]
  },
  {
    header: 'Service Description',
    text: [
      '2.1. Luca is an AI preparer designed to assist users in preparing financial documents and providing financial insights.',
      '2.2. The service is integrated into [Accounting Software Name] to enhance user experience and efficiency in financial management tasks.'
    ]
  },
  {
    header: 'User Responsibilities',
    text: [
      '3.1. Users are responsible for the accuracy and completeness of the data provided to LucaAI.',
      '3.2. Users must ensure compliance with relevant accounting standards and regulations in their jurisdiction.',
      '3.3. Users are prohibited from using Luca for any unlawful or fraudulent activities.'
    ]
  },
  {
    header: 'Limitation of Liability',
    text: [
      '4.1. Luca provides suggestions and insights based on the data provided by users, but it does not guarantee the accuracy or suitability of its recommendations.',
      '4.2. The developers of Luca are not liable for any direct or indirect damages arising from the use or inability to use the service.'
    ]
  },
  {
    header: 'Data Privacy',
    text: [
      '5.1. Luca may collect and process personal data as outlined in the [Accounting Software Name] privacy policy.',
      '5.2. User data is handled securely and in accordance with applicable data protection laws.'
    ]
  },
  {
    header: 'Intellectual Property',
    text: [
      '6.1. The intellectual property rights of Luca and its associated software belong to the developers.',
      '6.2. Users are granted a limited, non-exclusive license to use Luca for the purposes outlined in these terms and conditions.'
    ]
  },
  {
    header: 'Termination',
    text: [
      '7.1. The developers reserve the right to terminate or suspend access to Luca at any time, without prior notice, for any reason. ',
      '7.2. Upon termination, users must cease all use of Luca and delete any copies of the software.'
    ]
  },
  {
    header: 'Changes to Terms and Conditions ',
    text: [
      '8.1. The developers may update these terms and conditions from time to time.',
      '8.2. Users will be notified of any changes, and continued use of Luca constitutes acceptance of the revised terms.'
    ]
  },
  {
    header: 'Governing Law',
    text: [
      '9.1. These terms and conditions are governed by the laws of [Jurisdiction].'
    ]
  }
];

export const LucaDisplayText: string = `Hi there! I'm Luca, named after the esteemed Luca Pacioli, who is often referred to as the 'Father of Accounting'. Born in 1447, Pacioli made an indelible mark on the accounting world by formalizing the system of double-entry bookkeeping, a cornerstone of modern accounting practices. His contributions laid the foundation for the seamless financial record-keeping we benefit from today. Know me further -
Drawing inspiration from Pacioli's legacy, I've been crafted with the aim of simplifying and enhancing your year end engagement work and accounting workflows.
Whether you're managing complex financial transactions or seeking clarity on accounting principles, I'm here to lend a helping hand.
Think of me as your virtual assistant, available round the clock to address your accounting inquiries and streamline your processes. From generating reports to reconciling accounts, I'm equipped to handle a wide range of tasks, empowering you to focus on what matters most to your business.
So, feel free to reach out to me anytime with your accounting queries or tasks, and I'll be more than happy to assist you on your financial journey.`;

export const subStepOne: SetUpStepModel = {
  title: 'Gathering Firm Details',
  stepType: 'INFORMATION',
  step: 1,
  nextStep: 2,
  nextStepBtn: 'Proceed to step 2',
  isProgress: true,
  hasCompleted: false,
  stepInfo: [],
  showStepInfo: [],
  subStepDetails: null
};

export const subStepTwo: SetUpStepModel = {
  title: 'Identifying Firm Type',
  stepType: 'INFORMATION',
  step: 2,
  nextStep: 3,
  nextStepBtn: 'Proceed to step 3',
  isProgress: false,
  hasCompleted: false,
  stepInfo: [],
  showStepInfo: [],
  subStepDetails: null
};

export const subStepThree: SetUpStepModel = {
  title: 'Confirm Privacy Settings',
  stepType: 'CONFIRMATION',
  step: 4,
  nextStep: 4,
  nextStepBtn: 'Complete Setup',
  isProgress: false,
  hasCompleted: false,
  stepInfo: [
    'Permissions to access files and data.',
    '(Note: You privacy will be limited to the fund and will not be shared with anybody)'
  ],
  subStepDetails: [
    {
      header: 'Limited Access',
      isChecked: true,
      isDisabled: false,
      text: [
        'Limited access focuses on core functionality, allowing key workflow automation.',
        'Leverage AI-powered features without sharing any data externally.',
        'Your data remains private and secure, not used to train or improve models.',
        'Ideal for firms prioritizing maximum data protection while benefiting from automation.'
      ]
    },
    {
      header: 'Full Access',
      isChecked: false,
      isDisabled: true,
      text: [
        'Full access unlocks Luca\'s advanced AI features, optimizing workflows further.',
        'Your data remains private and secure throughout.',
        'Anonymous, aggregated interactions help improve the experience and results.',
        'Data isn\'t used for model training but enhances overall service quality.'
      ]
    }
  ]
};

export class DeactivateAIModel {
  title: string;
  isDeactivate: boolean = false;
  hasValidInput: boolean = false;
  list: string[];
  confirmHeader: string;
  confirmSubHeader: string;
  deactivateHeader: string;
  deactivateSubHeader: string;
  validName: 'Deactivate';
}

export const DeactivateInfo: DeactivateAIModel = {
  title: 'Deactivate LucaAI',
  isDeactivate: false,
  hasValidInput: false,
  list: [
    `Other users will experience interruptions in their workflows.`,
    `You may lose unsaved data and tasks managed by LucaAI.`,
    `AI-assisted processes will be disabled, leading to potential delays.`,
    `Project completion timelines may be affected`
  ],
  confirmHeader: 'LucaAI will be deactivated for all your firm users from tonight at 12:00AM',
  confirmSubHeader: 'Are you sure you want to deactivate LucaAI?',
  deactivateSubHeader: 'If you still wish to proceed, please enter ‘Deactivate’ below',
  deactivateHeader: 'Note: This is an irreversible action.',
  validName: 'Deactivate'
};

export class AiFirmInfo {
  numClients: number;
  numConnectedIntegrations: number;
  numDisconnectedIntegrations: number;
  numTeamMembers: number;
}

export class AiFirmModel {
  id: number = null;
  firmId: number;
  onboardingStep: number;
  isActivated: boolean = false;
  isFullAccess: boolean = false;
  activatedBy: number;
  deactivatedBy: number;
}

export class AiInfoModel {
  isFirmActivateLuca: boolean = false;
  isUserActivateLuca: boolean = false;
  deactivatedDate: string;
  deactivatedBy: number;
  firstName: string;
  lastName: string;
  email: string;
  isDeactivationAccepted: boolean = false;
}

export const AiActivationInfo: string[] = [
  `Generate prompts for your engagements and automate your workflow with one click.`,
  `Search, download, and save documents directly to your engagements.`,
  `Easily expand engagement discovery, simplify workflows, and meet compliance standards.`
];
