export class AclModel {
  // all the acl items
  permissionList: AccessItem[];

  // acl:
  hasDashboard: boolean = false;
  hasFirmInformationEdit: boolean = false;
  hasBilling: boolean = false;
  hasExportData: boolean = false;
  hasTeamMemberAdd: boolean = false;
  hasTeamMemberModifyOther: boolean = false;
  hasTeamMemberDelete: boolean = false;
  hasClientAdd: boolean = false;
  hasClientView: boolean = false;
  hasClientModify: boolean = false;
  hasClientDelete: boolean = false;
  hasEngView: boolean = false;
  hasEngAdd: boolean = false;
  hasEngModify: boolean = false;
  hasEngDelete: boolean = false;
  hasEngArchive: boolean = false;
  hasEngReOpen: boolean = false;
  hasTemplateViewAll: boolean = false;
  hasTemplateAddEditDeleteOwn: boolean = false;
  hasTemplateEditDeleteAll: boolean = false;
  hasTimeSummaryView: boolean = false;
  hasEngEditAssignedEng: boolean = false;
  hasEngExportAssignedEng: boolean = false;
  hasEngEditAll: boolean = false;
  hasEngExportAll: boolean = false;
  hasEngViewEdit: boolean = false;

  // default ones from user detail
  isSuperAdmin: boolean = false;
  isAdmin: boolean = false;
  userAccessTypeId: number = 0;
  userRoleId: number = 0;
  isClient: boolean = null;
}

export class AccessItem {
  ufid: string; // "c71fe13c-72b"
  accesstypeitemufi: number; // 1
  accesstypeitemname: string; // Access to dashboard
}
