import { ApiService } from '../services/api.service';
import { AsyncValidatorFn, AsyncValidator, NG_ASYNC_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { Directive } from '@angular/core';
import { map, catchError } from 'rxjs/operators';

export function uniqueMapnoValidator(apiService: ApiService): AsyncValidatorFn {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        if(!c.value){
            return of(null);
        }
        let useraccountId,loggedInUserData, engagementData, engagementId;
        loggedInUserData = JSON.parse((localStorage.getItem('userDetails')));
        engagementData = JSON.parse(localStorage.getItem("selectedEngagementObj"));
        engagementId = engagementData.engagementsid;
        if (loggedInUserData && loggedInUserData.useracctid) {
            useraccountId = loggedInUserData.useracctid;
        }
        const inputData = {
            "validateid":
                [{
                    "mapno": c.value,
                    "validatestatus": 0,
                    "loginid": useraccountId,
                    "engagementsid": engagementId
                }]
        };
        const inputString = JSON.stringify(inputData);
        let getData = {
            "procedureName": "validatemapno",
            "inputParameters": inputString
        };
        let debounceTime = 500; //milliseconds
        return timer(debounceTime).pipe(() => {
            return apiService.getData(getData).pipe(
                map(res => {
                    let result: any = [];
                    result = JSON.parse(res);
                    return result[0].validatestatus === 1 ? { 'uniqueMapno': true } : null;
                }),
                catchError(() => of(null))
            );
        });
    };
}

@Directive({
    selector: '[uniqueMapno][formControlName],[uniqueMapno][formControl],[uniqueMapno][ngModel]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: UniqueMapnoValidatorDirective, multi: true }]
})
export class UniqueMapnoValidatorDirective implements AsyncValidator {
    constructor(public apiService: ApiService) { }
    validate(c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return uniqueMapnoValidator(this.apiService)(c);
    }
}
