export enum RoutePath {

  CLIENT_ACCEPTANCE = '/dashboard/ntrdashboard/ClientOnboarding/ClientAcceptance',
  INDEPENDENCE = '/dashboard/ntrdashboard/ClientOnboarding/Independence',
  KNOWLEDGE = '/dashboard/ntrdashboard/ClientOnboarding/Knowledge-Of-clients-business',
  PLANNING = '/dashboard/ntrdashboard/ClientOnboarding/Planning',
  ENGAGEMENT_LETTER = '/dashboard/ntrdashboard/ClientOnboarding/EngagementLetter',
  TRAIL_BALANCE = '/dashboard/ntrdashboard/TrialBalance',
  PROCEDURES = '/dashboard/ntrdashboard/Procedure',
  COVER_PAGE = '/dashboard/ntrdashboard/FinancialStatement/doc/CoverPage',
  TABLE_CONTENTS = '/dashboard/ntrdashboard/FinancialStatement/doc/TableOfContents',
  COMPILATION_REPORT = '/dashboard/ntrdashboard/FinancialStatement/doc/CompilationReport',
  BALANCE_SHEET = '/dashboard/ntrdashboard/FinancialStatement/doc/BalanceSheet',
  INCOME_STATEMENT = '/dashboard/ntrdashboard/FinancialStatement/doc/IncomeStatement',
  RETAINED_EARNINGS = '/dashboard/ntrdashboard/FinancialStatement/doc/statement-of-retained-earning',
  NOTES_FS = '/dashboard/ntrdashboard/FinancialStatement/doc/NotesToFinancialStatement',
  COMPLETION_SIGN_OFFS = '/dashboard/ntrdashboard/CompletionSignOff',
  COMPLETION_LETTER_VIEW = '/dashboard/ntrdashboard/ClientOnboarding/ManagementLetter/Preview',
  COMPLETION_LETTER_EDIT = '/dashboard/ntrdashboard/ClientOnboarding/ManagementLetter/Edit',
  ENGAGEMENT_LETTER_VIEW = "/dashboard/ntrdashboard/ClientOnboarding/EngagementDetails/",
  REVIEW_CHECKLISTS = "/dashboard/ntrdashboard/ClientOnboarding/Review-Checklists/db950ab9-edc",
  CHECKLISTS_REVIEW_PATH = "/dashboard/ntrdashboard/ClientOnboarding/Review-Checklists/",
  MAERIALITY = '/dashboard/ntrdashboard/ClientOnboarding/Materiality',
  CLIENT_ENG_LETTER = '/dashboard/clients/engagement-details/engagement-letters',
  CLIENT_MANAGEMENT_LETTER = '/dashboard/clients/engagement-details/management-letter',
  LETTER_PREVIEW = '/dashboard/ntrdashboard/ClientOnboarding/Letter/Preview',
  LETTER_EDIT = '/dashboard/ntrdashboard/ClientOnboarding/Letter/Edit',
  ML_PREVIEW = '/dashboard/ntrdashboard/ClientOnboarding/ManagementLetter/Preview',
  TB_PREVIEW = '/dashboard/ntrdashboard/TrialBalance',
  TB_IMPORT = '/dashboard/ntrdashboard/ImportTrialBalance',
  BLANKCHECKLIST_TEMPLATE = '/dashboard/firm/templates/blank-template/'
}

export enum PlaceholderText {
   NO_DOC_REQUEST = "No document requests raised",
   NO_DOC_REQ_TEXT = "Currently no requests has been raised. Please raise a request clicking on ‘+ New request’ action",
   DOC_PLACEHOLDER = "Document, Spreadsheet, Presentation or PDF",
   DELETE_DOC_REQUEST = "Delete document request",
   DELETE_DOC_REQUEST_TEXT = "You are trying to delete a document request already sent to client, this will remove the request from the client end also. Do you want to continue?",
   NO_DOCS = "No document available",
   NO_BATCH = "No batch document requests added",
   NO_BATCH_TEXT = "Currently no batch document requests has been added. Please add a batch request clicking on ‘Add to batch’ under ‘Send request’ action",


}

export enum DialogBoxText {
  DELETE_CHECKLIST_HEADER = "Are you sure you want to delete checklist from a section? This action cannot be undone.",
  ADD_CHECKLIST = "Select checklist template",
  ADD_LETTER = "Select Letter template",
  CHECKLIST_DISCARD = ""
}

export enum ToasterMessage {
  DELETE_CHECKLIST = "Checklist deleted successfully",
  NO_SIGNATURES = "No Signature Available.Please update signature in components section.",
  NO_HEADERS = "No Header Available.Please update Header in components section.",
  NO_FOOTERS = "No Footer Available.Please update Footer in components section."
}
