export class RoundingModel {
  hasCYRoundingDifference : boolean = false;
  hasPyRoundingDifference : boolean = false;
  cyRoundingValue : number;
  pyRoundingValue: number;
  cyDisplayValue: string;
  pyDisplayValue: string;
  isCyNegative: boolean;
  isPyNegative: boolean;
}
