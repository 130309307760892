import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { apiKey, config } from 'src/app/services/app-config';
import { ApiService } from '../../../services/api.service';

var useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

@Component({
    selector: "app-fs-editor",
    templateUrl: './fs-editor.component.html',
})
export class FsEditorComponent implements OnInit {

    html = "";
    @Input() htmlContent = {};
    @Input() hideButtons = true;
    @Input() height = 500;
    @Input() isReadOnly = "false";
    @Output() onClickSave = new EventEmitter<object>();
    @Output() onClickCancel = new EventEmitter<boolean>();

    config = config;
    apiKey = apiKey;

    constructor(
        private apiService: ApiService,
        public toastr: ToastrService
    ) { }

    ngOnInit() {
        this.config.height = this.height;
        if(this.isReadOnly) {
            this.config.toolbar =  "false";
        }
    }

    saveContent(){
        this.onClickSave.emit(this.htmlContent);
    }

    closeEditor(){
        this.onClickCancel.emit(false);
    }

    saveData() {
        let jsonString = JSON.stringify(this.html);
        var blob = new Blob([jsonString], { type: "text/json;charset=utf-8" });

        let formData: FormData = new FormData();
        let jsonFileName = '2003' + '_statement.json';
        let s3signatureKey = 'Engagements';
        let status: any = 0
        formData.append('status', status);
        formData.append('signatureKey', s3signatureKey);
        formData.append('file', blob, jsonFileName);
        formData.append('extension', 'comp');
        this.apiService.saveAndGetFileNamePath(formData).subscribe(response => {
            if (response) {
                this.getJSON();
                this.toastr.success("Saved successfully");
            }
        }, error => { })
    }
    getJSON() {
        let jsonFileName = '2003' + '_statement.json';
        //let url = 'https://s3.amazonaws.com/' + this.bucketName + '/Engagements/' + this.loggedInUserData.firmUserAcctId + '_Firm/' + this.selectedEngagement.clientfirmid + '_Client/' + this.selectedEngagement.engagementsid + '_engagement/' + 'FinancialStatement/' + jsonFileName;
        let url = 'Engagements/' + jsonFileName;
        let obj = {
            status: 0,
            signatureKey: url
        }
        this.apiService.getJsonData(obj).subscribe((response: any) => {
            //this.sharedService.getS3URLJSONData(this.bucketName, url, isSaveGet).then((response: any) => {
            let parseObject = JSON.parse(response);
            this.html = parseObject;
            var parser = new DOMParser();
            var doc = parser.parseFromString(this.html, 'text/html');
            this.showTableData();
        });
    }
    showTableData() {
        let info = document.getElementById('info');
      var myTab = document.getElementById('productTable');
    }
}
