import { Directive, AfterContentInit, Input, ElementRef } from '@angular/core';

@Directive({ selector: '[appAutofocus]' })

export class AutoFocusDirective implements AfterContentInit {

    @Input() public autoFocus: boolean;

    public constructor(private el: ElementRef) { }

    public ngAfterContentInit(): void {
        if (this.autoFocus) {
            setTimeout(() => {
                this.el.nativeElement.focus();
            }, 500);
        }
    }
}