import { DatePipe, formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: "timeago",
    pure: true
})
export class TimeAgoPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private locale: string, private datePipe: DatePipe){}
    transform(value: any, args?: any): any {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29 && args == "TB") // less than 30 seconds ago will show as 'Just now'
                return 'Just now';
            if(seconds < 86400 && args == "CLIENT")
                //return this.datePipe.transform(value,'h:mm a');
                return formatDate(value,'h:mm a', this.locale);
            const intervals = {
                'year': 31536000,
                'month': 2592000,
                'week': 604800,
                'day': 86400,
                'hour': 3600,
                'minute': 60,
                'second': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        return counter + ' ' + i + ' ago'; // singular (1 day ago)
                    } else {
                        return counter + ' ' + i + 's ago'; // plural (2 days ago)
                    }
            }
        }
        return value;
    }
}

