export const TinymceEditorConfig = {
  height: 250,
  plugins: 'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image imagetools link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern noneditable',
  noneditable_editable_class: 'is-editable',
  noneditable_noneditable_class: 'is-locked',
  toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent fontselect fontsizeselect | removeformat',
  toolbar_mode: 'sliding',
  image_advtab: true,
  imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
  menubar: false,
  statusbar: false,
  content_css: [
    '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
    '//www.tinymce.com/css/codepen.min.css'
  ]
};
