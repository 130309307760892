import { CFStatusModel, FinGroupModel, SectionModel, TbImportErrorsList } from 'countable@model';
import { MapStatusModel } from '../model/eng/trial-balance.model';

export enum StoredProcedures {
  GET_CHECKLIST_DATA_INSIDE_ENGAGEMENT = "getquestionanswer",
  GET_SHORTCUT_BUTTONS = "getshortcutbutton",
  SAVE_CHECKLIST_REFERENCE = "savechecklistref",
  GET_LEFT_SIDE_FOLDERS = "getleftsidefolders", //we have to add new review folders to these
  GET_ISSUES = "getengissue",
  GET_ENGAGEMENT_USERS = "getengagementuser",
  GET_LEFT_SIDE_CHILD_FOLDERS = "getleftsidefolderchildname",
  DELETE_CHECKLISTS = "delete_checklist",
  MOVE_LEFT_DOCUMENTS = "moveengleftsidedocs",
  SAVE_ENGAGEMENT_SECTION_SORT = "save_engagement_section_sort",
  SAVE_DOC_REQUEST = 'saveengdocrequest',
  GET_DOC_REQUEST = 'getengdocrequestresponce',
  PARENT_LEFT_SIDE_FOLDER_LIST = "getengparentfoldername", //Used for ShowAllFolders in DOC REQUEST
  CHILD_LEFT_SIDE_FOR_A_LEFT_PARENT_FOLDER = 'getengparentchildname', //Used to Show Respective child folders for a selected parent folder
  LEFT_SIDE_PARENT_FOLDERS_LIST = "getleftsidefolders", //Used for getting all left side folders in new request / edit request
  LEFT_SIDE_CHILD_LEADSHEETS = "getleftsidechildleadsheetname" //USed to display leadsheets under procedures folder
}


export enum SubModuleUniqueId {
  CLIENT_ONBOARDING = "2f20ecb7-00d",
  NEW_ENG_ACCEPTANCE_REV = "c4d65488-f3d",
  EXISTING_ENG_CONTINUANCE = "f0ece127-f3d",
  INITIAL_ENGAGEMENT = "0fe774bb-f3d",
  ENGAGEMENT_LETTER = "2f20fb34-00d",
  CLIENT_REQUEST_LIST = "929ae1b0-bc4",
  ENTITY_BASICS = "21a8f5f9-bc4",
  ENTITY_SYSTEM = "24e09f7c-bc4",
  MATERIALITY ="2b5411ed-bc4",
  ENGAGEMENT_SCOPE ="2e259fdf-bc4",
  PLANNING_REVIEW ="db950ab9-edc",
  DOCUMENTS = "2f20fc0e-00d",
  FINANCIAL_STATEMENTS = "2f21078e-00d",
  COMPLETION_SIGNOFFS = '106',
  FS_STATEMENTS = "2f23ac84-00d",
  MANAGEMENT_LETTER = "1e996525-bc4",
  WORKSHEET_COMPLETION = "87e12ad8-060a-11ee-9a77-0",
  PROCEDURES = '2f210188-00d',
  COVER_LETTER = '5206dce0-bc4',
  COMPLETION_SIGNOFFUUID = '2f210cd5-00d',
  COM_ML = '565030a5-24c',
  REVIEW_CHECKLISTS_DEFAULT = '98a4053c-bc4',
  REVIEW_CHECKLISTS_SUBEQUENT_EVENT = '4e9c3770-bc4',
  REVIEW_REPORTING_CHECKLISTS = '57107972-82f',
  FINAL_REVIEW = '57070136-bc4',
  SIGN_OFFS = '2f210e74-00d'
}

export type FolderTypes = 'Client-Onboarding' | 'Planning' | 'Documents' | 'Trail-Balance' | 'Financial-Statements' | 'Procedures' | 'Completion-Signoffs' ;

export const REVIEW_FOLDER_DATA: ['Client-Onboarding','Planning','Documents','Trail-Balance','Financial-Statements','Procedures','Completion-Signoffs']
= ['Client-Onboarding','Planning','Documents','Trail-Balance','Financial-Statements','Procedures','Completion-Signoffs'];

export const COMPILATION_FOLDER_DATA : ['Client-Onboarding','Documents','Trail-Balance','Financial-Statements','Completion-Signoffs']
= ['Client-Onboarding','Documents','Trail-Balance','Financial-Statements','Completion-Signoffs'];

export enum MenuItems {
  ISSUES = "Raise issue",
  COMMENTS = "Add comment",
  REQUEST = "Raise a request",
  RENAME = "Rename",
  DELETE = "Delete",
  EDIT = "Edit",
  MOVE = "Move",
  DOCUMENT = "Add document",
  DOWNLOAD = "Download",
  SECTION = "Add Section",
  REORDERSECTIONS = "Reorder sections",
  REORDER = "Reorder",
  RENUMBER = "Renumber"
}

export enum DocumentPreviewTypes {
  BALANCE_SHEET = "balance sheet",
  INCOME_STATEMENT = "income statement",
  STOCK_HOLDERS = "stockholder",
  CASH_FLOW = "cashflow",
  NOTES = "notes",
  COMPILATION_REPORT = "compilation report",
  COVER_PAGE = "cover",
  RETAINED_EARNINGS = "income statement",
  TABLE_OF_CONTENTS = "table of content"
}

export type STORE_KEYS = "firmchecklist" | 'letters' | 'masterChecklist';

export type DIALOG_TYPES = 'delete_checklist' | 'save_new_checklist' | 'replace_checklist' | 'move_leftsidedoc' | 'add_letter' |
  'upgrade_plan' | 'account_paused' | 'trial_expired' | 'storage_limit' | 'eng_limit' | 'addTeamBillingInfo' | 'scheduledToDeleteOrPause' |
  'pegPactExpiresInMonthOrDays' | 'switchFirm' | 'delete_CMS_no_eng_assigned' | 'delete_CMS_without_reassigned' | 'delete_eng_CMS' | 'delete_CMS_one' | 'update_partial_assignment' | 'not_cms_member' | 'flex_engagements_info' |
  'upgrade_plan_creating_flex_free_trial'
  | 'flex_added_not_activated'
  | 'flex_account_policy'
  | 'send_email'
  | 'email_success';

export type LETTER_VIEW_TYPES = 'Client-Onboarding' | 'Completion-Signoffs' | 'No-Letter-View';

export type LETTER_ACTION_TYPES = 'create' | 'duplicate' | 'MasterTemplate' | 'CopyTemplates' | 'move' | 'blank';

export const SUPER_ADMIN_TEAMS_COLUMNS = ['select', 'memberid', 'fullname', 'title', 'status', 'email', 'category',
  'clients', 'businessphone', 'cellphone', 'assignedpartner', 'hourlyrate', '2fastatus', 'licenseno', 'action'];

export const OTHER_TEAMS_COULMNS = ['select', 'memberid', 'fullname', 'title', 'status', 'email', 'category', 'clients',
  'businessphone', 'cellphone', 'assignedpartner', 'hourlyrate', 'licenseno', 'action'];

export const SUPER_ADMIN_PARTNER_COLUMNS = ['pselect', 'pmemberid', 'pname', 'ptitle', 'pstatus', 'pemail', 'pclients',
  'pbusinessphone', 'pcellphone', 'plicenseno', 'phourlyrate', '2fastatus', 'paction'];

export const OTHER_PARTNER_COLUMNS = ['pselect', 'pmemberid', 'pname', 'ptitle', 'pstatus', 'pemail', 'pclients',
  'pbusinessphone', 'pcellphone', 'plicenseno', 'phourlyrate', 'paction'];

export const SUPER_ADMIN_STAFF_COLUMNS = ['sselect', 'smemberid', 'sname', 'stitle', 'sstatus', 'semail', 'sclients', 'sbusinessphone',
  'scellphone', 'sassignedpartner', 'shourlyrate', '2fastatus', 'slicenseno', 'saction'];

export const OTHER_STAFF_COLUMNS = ['sselect', 'smemberid', 'sname', 'stitle', 'sstatus', 'semail', 'sclients', 'sbusinessphone',
  'scellphone', 'sassignedpartner', 'shourlyrate', 'slicenseno', 'saction'];

export const SUPER_ADMIN_CMS_COLUMNS = ['cselect', 'cmemberid', 'cname', 'ctitle', 'cstatus', 'cemail', 'ccategory', 'cclients', 'cbusinessphone',
  'ccellphone', 'cassignedpartner', 'chourlyrate', '2fastatus', 'clicenseno', 'caction'];

export const OTHER_CMS_COLUMNS = ['cselect', 'cmemberid', 'cname', 'ctitle', 'cstatus', 'cemail', 'ccategory', 'cclients', 'cbusinessphone',
  'ccellphone', 'cassignedpartner', 'chourlyrate', 'clicenseno', 'caction'];

export type LETTER_OPTIONS_STATUS = 'pdfSignedOff' | 'jsonSignedOff' | 'pdfNotSigned' | 'jsonNotSigned';

export type FS_STATEMENTS_PREVIEWS = "balance sheet" | "income statement" | "stockholder" | "cashflow" | "notes" | "compilation report" | "cover" | "retained earnings" | "table of content";

export const ACTIVE_PAGE_MAP: Map<string, Partial<FS_STATEMENTS_PREVIEWS>> = new Map<string, Partial<FS_STATEMENTS_PREVIEWS>>([
  ['BalanceSheet', DocumentPreviewTypes.BALANCE_SHEET],
  ['IncomeStatement', DocumentPreviewTypes.INCOME_STATEMENT],
  ['statement-of-retained-earning', DocumentPreviewTypes.RETAINED_EARNINGS],
  ['StatementStockholdersEquity', DocumentPreviewTypes.STOCK_HOLDERS],
  ['StatementCashFlows', DocumentPreviewTypes.CASH_FLOW]
])

export const WORKBOOK_ACTIVE_PAGE_MAP: Map<string, Partial<FS_STATEMENTS_PREVIEWS>> = new Map<string, Partial<FS_STATEMENTS_PREVIEWS>>([
  ['balance-sheet', DocumentPreviewTypes.BALANCE_SHEET],
  ['income-statement', DocumentPreviewTypes.INCOME_STATEMENT],
  ['retained-earnings', DocumentPreviewTypes.RETAINED_EARNINGS],
  ['Stockholdersequity', DocumentPreviewTypes.STOCK_HOLDERS],
  ['cashflow', DocumentPreviewTypes.CASH_FLOW]
])

export type STATEMENT_VIEW_TYPES = 'Engagement' | 'Workbook' | 'None';

export const PLANNING_MAP: Map<string, SectionModel> = new Map<string, SectionModel>([
  ["Materiality", null],
  ["Engagement Scope", null]
])

export type CONCLUDE_WORKSHEETS = "Conclude" | "Re-Conclude" | "None" | "Open" | "Re-Open";

export const CONCLUDE_DETAILS = {

}

export enum WorksheetUUID {
  REVENUE = "1aa4f25b-e2c7-11ed-a7b9-0",
  RELATED_PARTY = "1aa4d704-e2c7-11ed-a7b9-0",
  PROPERTY_PLANT_EQUIPMENT = "1aa4d7ab-e2c7-11ed-a7b9-0",
  PAYROLL = "1aa4f38e-e2c7-11ed-a7b9-0",
  OTHER_INVESTMENTS = "1aa4ed3c-e2c7-11ed-a7b9-0",
  OTHER_EXPENSES = "1aa4f42a-e2c7-11ed-a7b9-0",
  NOTE_PAYABLE_BANK_DEBT = "1aa4efdc-e2c7-11ed-a7b9-0",
  MATERIALITY = "4faaeb55-e523-11ed-a7b9-0",
  LONG_TERM_DEBT = "1aa4f11b-e2c7-11ed-a7b9-0",
  LOANS_AND_ADVANCE_RECEIVABLE = "1aa4d64d-e2c7-11ed-a7b9-0",
  LOANS_AND_ADVANCE_PAYABLE = "1aa4f07e-e2c7-11ed-a7b9-0",
  INVESTMENTS = "1aa4d579-e2c7-11ed-a7b9-0",
  INVENTORY = "1aa4d3ea-e2c7-11ed-a7b9-0",
  INTANGIBLES_AND_GOODWILL = "1aa4ec8d-e2c7-11ed-a7b9-0",
  INCOME_TAXES = '1aa4ef34-e2c7-11ed-a7b9-0',
  EQUITY = "1aa4f1bf-e2c7-11ed-a7b9-0",
  ENGAGEMENT_SCOPE = "3379f402-e52d-11ed-a7b9-0",
  COST_OF_SALES = "1aa4f2f6-e2c7-11ed-a7b9-0",
  CASH = '1aa4ce95-e2c7-11ed-a7b9-0',
  BANK_INDEBTEDNESS = "1aa4edda-e2c7-11ed-a7b9-0",
  AIM = "87e12ad8-060a-11ee-9a77-0",
  AR = "1aa4d2fe-e2c7-11ed-a7b9-0",
  AP_AL = "1aa4ee7f-e2c7-11ed-a7b9-0"
}

export type SELECT_OPTIONS = "Yes" | "No" | "None";

export type FINAL_REVIEW_TYPES =
  'compilation-canada'
  | 'compilation-tax-canada'
  | 'review-canada'
  | 'review-tax-canada'
  | 'none' | 'compilation-us' | 'compilation-tax-us' | 'review-us' | 'review-tax-us';

export type COMPONENTS_TYPES = 'HEADERS' | 'FOOTERS' | 'SIGNATURES';

export type CLIENT_LETTER_TYPES = 'EL' | 'ML' | 'No-EL' | 'No-ML' | 'COM-EL' | 'None';

export type CLIENT_LETTER_ORIGIN = 'EL' | 'ML' | 'COM-EL' | 'None';

export type CLIENT_ML_SIGN_TYPES = 'draw' | 'text' | 'upload' | 'None';

export type CLIENT_ML_IMAGE_TYPES = 'header' | 'footer' | 'signature';

export type CLIENT_IMAGES_FOLDERS = 'Signature' | 'Header' | 'Footer' | 'None' | 'Signatures';

export type LETTER_TYPES = 'Engagement' | 'Management' | 'Compilation_Management' | 'None';

export const LEAD_SHEET_MAPPING_WITH_PROCEDURES = [
  {
    "procedureWorksheetName": "Cash",
    "procedureWorksheetJsonFileName": "a_cash_master.json",
    "procedureWorksheetUUID": "1aa4ce95-e2c7-11ed-a7b9-0",
    "leadSheetCode": "A",
    "leadSheetId": [1]
  },
  {
    "procedureWorksheetName": "Accounts Receivable",
    "procedureWorksheetJsonFileName": "b_accounts_receivable_master.json",
    "procedureWorksheetUUID": "1aa4d2fe-e2c7-11ed-a7b9-0",
    "leadSheetCode": "B",
    "leadSheetId": [2]
  },
  {
    "procedureWorksheetName": "Inventory",
    "procedureWorksheetJsonFileName": "c_inventory_master.json",
    "procedureWorksheetUUID": "1aa4d3ea-e2c7-11ed-a7b9-0",
    "leadSheetCode": "C",
    "leadSheetId": [3]
  },
  {
    "procedureWorksheetName": "Investments",
    "procedureWorksheetJsonFileName": "d_investments_master.json",
    "procedureWorksheetUUID": "1aa4d579-e2c7-11ed-a7b9-0",
    "leadSheetCode": "D",
    "leadSheetId": [4]
  },
  {
    "procedureWorksheetName": "Loans & Advances Receivable",
    "procedureWorksheetJsonFileName": "e_loans_advances_receivable_master.json",
    "procedureWorksheetUUID": "1aa4d64d-e2c7-11ed-a7b9-0",
    "leadSheetCode": "E",
    "leadSheetId": [5]
  },
  {
    "procedureWorksheetName": "Related party",
    "procedureWorksheetJsonFileName": "f_related_party_master.json",
    "procedureWorksheetUUID": "1aa4d704-e2c7-11ed-a7b9-0",
    "leadSheetCode": "F",
    "leadSheetId": [37,38,40]
  },
  {
    "procedureWorksheetName": "Property, Plant and Equipment",
    "procedureWorksheetJsonFileName": "h_property_plant_and_equipment_master.json",
    "procedureWorksheetUUID": "1aa4d7ab-e2c7-11ed-a7b9-0",
    "leadSheetCode": "H",
    "leadSheetId": [11,12,13,14,15,16,17,18,19]
  },
  {
    "procedureWorksheetName": "Intangibles and Goodwill",
    "procedureWorksheetJsonFileName": "j_intangibles_and_goodwill_master.json",
    "procedureWorksheetUUID": "1aa4ec8d-e2c7-11ed-a7b9-0",
    "leadSheetCode": "J",
    "leadSheetId": [20,21,22,23]
  },
  {
    "procedureWorksheetName": "Other Investments",
    "procedureWorksheetJsonFileName": "k_other_investments_master.json",
    "procedureWorksheetUUID": "1aa4ed3c-e2c7-11ed-a7b9-0",
    "leadSheetCode": "K",
    "leadSheetId": [6,7,8,25,26,27,28,29,30]
  },
  {
    "procedureWorksheetName": "Bank indebtedness",
    "procedureWorksheetJsonFileName": "aa_bank_indebtedness_master.json",
    "procedureWorksheetUUID": "1aa4edda-e2c7-11ed-a7b9-0",
    "leadSheetCode": "AA",
    "leadSheetId": [32]
  },
  {
    "procedureWorksheetName": "Accounts Payable and Accrued Liability",
    "procedureWorksheetJsonFileName": "bb_accounts_payable_and_accrued_liability_master.json",
    "procedureWorksheetUUID": "1aa4ee7f-e2c7-11ed-a7b9-0",
    "leadSheetCode": "BB",
    "leadSheetId": [33]
  },
  {
    "procedureWorksheetName": "Income Taxes",
    "procedureWorksheetJsonFileName": "cc_income_taxes_master.json",
    "procedureWorksheetUUID": "1aa4ef34-e2c7-11ed-a7b9-0",
    "leadSheetCode": "CC",
    "leadSheetId": [34]
  },
  {
    "procedureWorksheetName": "Note Payable and Bank Debt",
    "procedureWorksheetJsonFileName": "dd_note_payable_and_bank_debt_master.json",
    "procedureWorksheetUUID": "1aa4efdc-e2c7-11ed-a7b9-0",
    "leadSheetCode": "DD",
    "leadSheetId": [35]
  },
  {
    "procedureWorksheetName": "Related party",
    "procedureWorksheetJsonFileName": "f_related_party_master.json",
    "procedureWorksheetUUID": "1aa4d704-e2c7-11ed-a7b9-0",
    "leadSheetCode": "FF",
    "leadSheetId": [6,7,8,37,38,40]
  },
  {
    "procedureWorksheetName": "Loans & Advances Payable",
    "procedureWorksheetJsonFileName": "jj_loans_&_advances_payable_master.json",
    "procedureWorksheetUUID": "1aa4f07e-e2c7-11ed-a7b9-0",
    "leadSheetCode": "JJ",
    "leadSheetId": [49]
  },
  {
    "procedureWorksheetName": "Long term debt",
    "procedureWorksheetJsonFileName": "kk_nn_long_term_debt_master.json",
    "procedureWorksheetUUID": "1aa4f11b-e2c7-11ed-a7b9-0",
    "leadSheetCode": "KK",
    "leadSheetId": [44]
  },
  {
    "procedureWorksheetName": "Equity",
    "procedureWorksheetJsonFileName": "tt_equity_master.json",
    "procedureWorksheetUUID": "1aa4f1bf-e2c7-11ed-a7b9-0",
    "leadSheetCode": "TT",
    "leadSheetId": [52,53,54,55,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76]
  },
  {
    "procedureWorksheetName": "Revenue",
    "procedureWorksheetJsonFileName": "20_50_revenue_master.json",
    "procedureWorksheetUUID": "1aa4f25b-e2c7-11ed-a7b9-0",
    "leadSheetCode": "20",
    "leadSheetId": [84,85,86,87,88,89,90,91,92,93,94,95,96,97]
  },
  {
    "procedureWorksheetName": "Cost of Sales",
    "procedureWorksheetJsonFileName": "30_cost_of_sales_master.json",
    "procedureWorksheetUUID": "1aa4f2f6-e2c7-11ed-a7b9-0",
    "leadSheetCode": "30",
    "leadSheetId": [98,533]
  },
  {
    "procedureWorksheetName": "Payroll",
    "procedureWorksheetJsonFileName": "40_55_payroll_master.json",
    "procedureWorksheetUUID": "1aa4f38e-e2c7-11ed-a7b9-0",
    "leadSheetCode": "40",
    "leadSheetId": [104,117,118,119,137,138]  //No Data to match with leadsheet Code
  },
  {
    "procedureWorksheetName": "Other Expenses",
    "procedureWorksheetJsonFileName": "40_55_other_expenses_master.json",
    "procedureWorksheetUUID": "1aa4f42a-e2c7-11ed-a7b9-0",
    "leadSheetCode": "40",
    "leadSheetId": [99,100,101,102,103,105,106,107,108,109,110,111,112,,113,114,115,116,120,121,122,123,124,134,135,136,139,140,141,142,143,144,534,535,780]
  }
]

export const IMAGE_VALIDATION_MESSAGES = {
  'Header': 'Unsupported Format or Size Please upload a header image in PNG, JPEG, or GIF format with a maximum size of 720px by 200px',
  'Footer': 'Unsupported Format or Size Please upload a footer image in PNG, JPEG, or GIF format with a maximum size of 720px by 70px',
  'Signature': 'Unsupported Format or Size Please upload a signature image in PNG, JPEG, or GIF format with a maximum size of 125px by 200px'
};

export type EL_KEYS = 'Firm Signature' | 'Client Signature' | 'Firm Signed' | 'Client Portal';

export enum SignatureTypes {
  DRAW = 'draw',
  TEXT = 'text',
  UPLOAD = 'upload'
}
export const BLANK_TEMPLATE = {
  "coverHeaderData": {},
  "contentHeaderData": {},
  "coverFooterData": {},
  "contentFooterData": {},
  "coverLetter": [],
  "Subject": {},
  'content': [
    {
      'EditableMode': false,
      'checked': true,
      'content': '<p><span class="auto-elements is-locked" style="background-color: #ddf0df;">Date</span><br><br><br><span class="auto-elements is-locked" style="background-color: #ddf0df;">Entity Name</span><br><span class="auto-elements is-locked" style="background-color: #ddf0df;">Complete address</span><br><br><br>Dear&nbsp;<span class="auto-elements is-locked" style="background-color: #ddf0df;">First Name</span>,</p>',
      'editSection': false,
      'header': 'Section 1',
      'id': 0,
      'islock': false,
      'sortId': 0
    },
    {
      'id': 1,
      'islock': false,
      'header': 'Description',
      'checked': true,
      'EditableMode': false,
      'editSection': false,
      'content': '<p>&lt;Enter Description here&gt;</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>',
      'userAdded': 1,
      'sortId': 1
    },
    {
      'id': 2,
      'islock': false,
      'header': 'Signature',
      'checked': true,
      'EditableMode': false,
      'editSection': false,
      'content': '<p>Yours truly,<br><strong>ON BEHALF OF</strong> <span class="auto-elements is-locked" style="background-color: #ddf0df;">Firm Name</span><br><br><span class="auto-elements is-locked" style="background-color: #ddf0df;">Firm Signature</span> <br>(Signature)<br><br><span class="auto-elements is-locked" style="background-color: #ddf0df;">Date</span> <br>(Date)</p>',
      'userAdded': 1,
      'sortId': 2
    }
  ],
  "signatures": [],
  "createdDate": 0,
  'engagementLetterName': '',
  'typeOfTemplate': 'blank',
  "signatureSrc": "",
  "signHeader": "",
  "signatureDesignation": "",
  "signatureName": "",
  'isLetterEditedAfterSend': false,
  'contentBeforeEdit': []
}

export const MapStatusInfo: MapStatusModel[] = [
  {
    id: 1,
    status: 'Unmapped',
    checked: false,
    mappingstatusid: 1
  },
  {
    id: 2,
    status: 'Auto Mapped',
    checked: false,
    mappingstatusid: 2
  },
  {
    id: 3,
    status: 'New row',
    checked: false,
    mappingstatusid: 3
  }, {
    id: 4,
    status: 'Added row',
    checked: false,
    mappingstatusid: 4
  },
  {
    id: 5,
    status: 'Change row',
    checked: false,
    mappingstatusid: 5
  }
];

export const CF_TYPES_LIST: CFStatusModel[] = [
  {
    id: 1,
    name: 'CFO',
    description: 'Cash Flow from Operating',
    isDeleted: false
  },
  {
    id: 2,
    name: 'CFI',
    description: 'Cash Flow from Investing',
    isDeleted: false
  },
  {
    id: 3,
    name: 'CFF',
    description: 'Cash Flow from Financing',
    isDeleted: false
  },
  {
    id: 4,
    name: 'NC',
    description: 'Non Cash',
    isDeleted: false
  },
  {
    id: 5,
    name: 'Excl',
    description: 'Excluded',
    isDeleted: false
  },
  {
    id: 6,
    name: 'NA',
    description: 'Not Available',
    isDeleted: false
  }
];

export const FIN_GROUP_LIST: FinGroupModel[] = [
  {
    fingroupid: 1,
    fingroupname: 'Assets'
  },
  {
    fingroupid: 2,
    fingroupname: 'Liabilities'
  },
  {
    fingroupid: 3,
    fingroupname: 'Equity'
  },
  {
    fingroupid: 4,
    fingroupname: 'Revenue'
  },
  {
    fingroupid: 5,
    fingroupname: 'Cost of sales'
  },
  {
    fingroupid: 6,
    fingroupname: 'Expenses'
  }
];

export enum ENG_STATUS_ID {
  TB_NOT_YET_LOADED = 11,
  TB_LOAD_SUCCESS,
  TB_LOAD_ERROR = 13,
  TB_NO_ROWS,
  ENG_IN_PROGRESS = 7,
  ENG_COMPLETED = 8,
  ENG_NEW = 9,
  ENG_RE_OPEN = 17,
  ENG_CLOSED,
  ENG_ARCHIVED = 19,
}

export const TB_IMPORT_HEADERS: string[][] = [
  ['Account No', 'Description', 'Debit', 'Credit', 'Group'],
  ['Acc No', 'Description', 'Debit', 'Credit', 'Group'],
  ['Accountno', 'Description', 'Debit', 'Credit', 'Group']
];

export type NUMBER_STATUS = 'POSITIVE' | 'NEGATIVE' | 'ZERO' | 'NOT_NUMBER';
export const VALID_GROUP_NAMES = ['Assets', 'Liabilities', 'Equity', 'Revenue', 'Cost of sales', 'Expenses'];

export enum TB_IMPORT_ERRORS {
  IN_VALID_COLUMNS = 'File doesn\'t have five columns,Please check the Standard Template and import again.',
  HEADER_NAMES = 'Header Names in The File are not matching with our system.Please check the Standard Template and import again',
  NO_ROWS = 'File has No Rows to Import',
  GROUP_NAMES = 'Provided group names are not matching with countable groups, please update the group column data and import again.',
  DUP_NAMES = 'Provided Account Description\'s are duplicated in the File',
  DUP_CODES = 'Provided Account No\'s are duplicated in the File',
  NO_DEBIT = 'Provided File has No Debit Values',
  NO_CREDIT = 'Provided File Has No Credit Values',
  NO_GROUPS = 'Provided File Has No Groups',
  TB_IN_COMPLETE = 'The Trial Balance data is incomplete. Re-check and import the data again.',
  NO_DESC = 'Provided File has a blank description for some of the row(s)',
  NOT_VALID_FILE = 'Please import valid CSV/Excel file'
}

export const ERROR_LIST: TbImportErrorsList[] = [
  {
    error: 'Group column data matching with countable groups',
    id: 1,
    hasError: false
  },
  {
    error: 'No duplicate account descriptions found',
    id: 2,
    hasError: false
  },
  {
    error: 'No duplicate account numbers found',
    id: 3,
    hasError: false
  },
  {
    error: 'Trial Balance data is complete',
    id: 4,
    hasError: false
  },
  {
    error: 'Rows description available',
    id: 5,
    hasError: false
  }
];
