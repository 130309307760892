import { Injectable } from '@angular/core';
import { AutoElements, AutoStringElements, deleteLetterTModel, EditDisableModel, ElementsModel, LetterTemplateViewModel, updateTemplateIdModel } from 'countable@model';
import { BehaviorSubject, Subject } from 'rxjs';
import { EncodingService } from './encoding.service';


@Injectable({
  providedIn: 'root'
})

export class TemplatesSharedService {

  public lettersFolderData : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public refreshLettersData : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public emitSelectedTemplate : Subject<LetterTemplateViewModel> = new Subject<LetterTemplateViewModel>();
  public deleteLetterTemplate : Subject<deleteLetterTModel> = new Subject<deleteLetterTModel>();
  public duplicateLetterTemplate : Subject<any> = new Subject<any>();
  public CopyLetterTemplate : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public editDisable: Subject<EditDisableModel> = new Subject<EditDisableModel>();
  public addElements = new Subject<any>();
  public isMultiCheck: boolean = false;
  public refreshTemplateName: Subject<updateTemplateIdModel> = new Subject<updateTemplateIdModel>();

  constructor(private encodingService: EncodingService) {}

  getLettersFolderNames(templatesData) {
    return templatesData.map(folder => folder.foldername.toLocaleLowerCase());
  }

  getLettersTemplateNames(templatesData) {
    return templatesData.map(folder => folder.templates && folder.templates.map(file => file.TemplateName.toLocaleLowerCase())).flat(Infinity);
  }

  getLetterNamesInFolder(templates: any[]) {
    return templates.map(template => template.TemplateName.toLocaleLowerCase());
  }

  parseData(data) {
    if (!data) return {};
    if (typeof data === 'object') return data;
    if (typeof data === 'string') return JSON.parse(data);
    return {};
  }

  getUserDetails() {
    return JSON.parse((localStorage.getItem('userDetails')));
  }

  getUserId() :number{
    return this.getUserDetails().useracctid;
  }

  getFirmId() :number{
    return this.getUserDetails().firmUserAcctId;
  }

  getS3FilePath() :string {
    return 'Engagements/'+this.getFirmId()+ '_Firm/Templates/UserTemplate';
  }

  getBlankTemplatePath() :string {
    return 'Engagements/masterTemplate/blankTemplate.json';
  }

  getMasterFolderPath() :string {
    return 'Engagements/masterTemplate';
  }

  getCountryId() : 38 | 226 {
    return this.getUserDetails().origin_country_id;
  }

  getFormDataS3(s3FileName:string,finalSaveContent) :FormData{
    const jsonString = JSON.stringify(finalSaveContent);
    const blob = new Blob([jsonString],{type: 'text/json;charset=utf-8'});
    const formS3Data: FormData = new FormData();
    const uploadObj = JSON.stringify({
      module: "Firm Templates",
      firmId: this.getFirmId(),
      status: 0,
      folderName: "UserTemplate",
      appSyncId: this.getUserId(),
      extension: "comp",
    });
    formS3Data.append('upload',this.encodingService.enData(uploadObj, 'body'));
    formS3Data.append('file',blob,s3FileName);
    return formS3Data;
  }


  // getDefaultObj(data,imageType:'header'|'footer',isDefaultFrom:'db'|'s3'|'dialog'): ImageModel {
  //   if(data.length === 0) {
  //     return ;
  //   }
  //   const obj = isDefaultFrom === 'db' ?  data.filter(header => header.isuserselected === 1)[0] : data;
  //   const ImageObj = new ImageModel();
  //   ImageObj.imageId = imageType === 'header' ? obj.FirmheaderID : obj.firmfooterID;
  //   ImageObj.isDefaultFrom = isDefaultFrom;
  //   ImageObj.isImageType = imageType;
  //   ImageObj.s3FilePath = obj.JsonFilePath;
  //   ImageObj.s3ImagePath = '';
  //   return ImageObj ? ImageObj : null;
  // }

  getElements() :ElementsModel[] {
    const elements: AutoElements = new AutoElements();
     return this.getCountryId() === 38 ? elements.canadaelements : elements.usreviewelements;
  }

  getElementStrings(): string[] {
    const elements: AutoStringElements = new AutoStringElements();
    return this.getCountryId() === 38 ? elements.canadaElements : elements.usReviewElements;
  }

  getMaxNumber(idArr: number[]) :number {
    return Math.max(...idArr) + 1;
  }

  getOneSectionId(contentArr: Array<any>,isOneSection: boolean) {
      if(!isOneSection)  return 1000;
      const oneSectionObj = contentArr.filter(content => content.checked)[0];
      return oneSectionObj.id;

  }

}
