import { DatePipe } from '@angular/common';
import * as moment from 'moment';

export class DateUtils {

  public readonly FULL_DATE = 'MMMM dd, yyyy, h:mm a';
  public readonly ONLY_DATE = 'MMMM d, yyyy';

  private datePipe: DatePipe;
  constructor(datePipe: DatePipe) {
    this.datePipe = datePipe;
  }

   public convertToLocale(dateTimeStamp: string): string {
    if(!dateTimeStamp) return "";
    const localeStamp =  dateTimeStamp + " UTC";
    return this.datePipe.transform(localeStamp,'MMMM d ,YYYY hh:mm a');
  }

  public  formatDateTime(stamp: string) :string {
    return moment(stamp).format('MMMM DD, YYYY');
  }

  public onlyDate(date: Date):string {
    if(!date) return "";
    return this.datePipe.transform(date,'yyyy-MM-dd')
  }
   // moment(date,'MMM Do YYYY h:mm;ss A').format('MMM DD, YYYY h:mm A');

  public getTomorrowDate(): string {
    const today = new Date();
    const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    return this.datePipe.transform(tomorrow, 'yyyy-MM-dd');
  }

  /**
   * @param inputDate
   * @param format
   * @returns Sep 16, 2023, 5:51 AM
   */

  public convertToFormat(inputDate: string, format: string) {
    if (!inputDate) {
      return '';
    }
    const date = new Date(inputDate);
    return this.datePipe.transform(date, format) || '';
  }

  public convertToLocaleFormat(dateTimeStamp: string): string {
    if (!dateTimeStamp) {
      return '';
    }
    const localeStamp = new Date(dateTimeStamp) + ' UTC';
    return this.datePipe.transform(localeStamp, 'MMMM dd ,YYYY h:mm a');
  }

  public convertToUTC(inputDate: string) {
    if (!inputDate) {
      return '';
    }
    const date = new Date(inputDate);
    return this.datePipe.transform(inputDate, this.FULL_DATE, 'UTC')
  }


}
