/* For reference
 *ngFor="let c of oneDimArray | sortBy:'asc'"
 *ngFor="let c of arrayOfObjects | sortBy:'asc':'propertyName'"
*/
import {Pipe, PipeTransform } from '@angular/core';
export type SortOrder = 'asc' | 'desc';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

  transform(array: any[], args: string): Array<string> {
    if (!array) return [];
    if (array) {
     return   array = array.sort((a,b)=> a.engNoteId-b.engNoteId)
    }
}}
    
    