import { PROMPT_MSG_TYPES } from './chat.model';

export class SuggestedPromptsModel {
  prompt: string;
  type: string;
  header: string;
  id: number;
  historyName: string;
  promptType: PROMPT_MSG_TYPES;
  showForEng: number[];
}

export const PROMPTS: SuggestedPromptsModel[] = [
  {
    prompt: 'Variance Analysis',
    historyName: 'Generate Variance Analysis',
    type: 'prompt',
    header: 'Certainly! To proceed with the variance analysis, I will need at least one of the following two inputs:',
    id: 1,
    promptType: 'VARIANCE_ANALYSIS_INPUTS',
    showForEng: [1, 2, 3]
  },
  {
    prompt: 'Aged AR Analysis',
    historyName: 'Generate Aged AR Analysis',
    type: 'prompt',
    header: '',
    id: 2,
    promptType: 'AR',
    showForEng: [2]
  },
  {
    prompt: 'General Ledger Analysis',
    historyName: 'Generate General Ledger Analysis',
    type: 'prompt',
    header: '',
    id: 3,
    promptType: 'GENERAL_LEDGER',
    showForEng: [1, 2, 3]
  },
  {
    prompt: 'Investment Schedule',
    historyName: 'Generate Investment Schedule',
    type: 'prompt',
    header: '',
    id: 4,
    promptType: 'IS',
    showForEng: [1, 2, 3]
  },
  // {
  //   prompt: 'HST/GST Plausibility',
  //   historyName: 'HST/GST Plausibility',
  //   type: 'prompt',
  //   header: '',
  //   id: 5,
  //   promptType: 'HST',
  //   showForEng: [2]
  // },
  // {
  //   prompt: 'Bank Reconciliation',
  //   historyName: 'HST Reconciliation',
  //   type: 'prompt',
  //   header: '',
  //   id: 6,
  //   promptType: 'BR',
  //   showForEng: [1, 2, 3]
  // },
  {
    prompt: 'Capital Asset Amortization',
    historyName: 'Generate Capital Asset Amortization',
    type: 'prompt',
    header: '',
    id: 6,
    promptType: 'CAAS_PREFERENCES',
    showForEng: [1, 2, 3]
  },
  // {
  //   prompt: 'Loan Amortization Schedule',
  //   historyName: 'Loan Amortization Schedule',
  //   type: 'prompt',
  //   header: '',
  //   id: 10,
  //   promptType: 'LAS',
  //   showForEng: [1, 2, 3]
  // },
];

export const UPLOAD_FILE_PROMPT: SuggestedPromptsModel = {
  prompt: 'File Analysis',
  historyName: 'Generate File Analysis',
  type: 'prompt',
  header: '',
  id: 7,
  promptType: 'UF',
  showForEng: [1, 2, 3]
};

export const DOC_REPO_FILE_PROMPT: SuggestedPromptsModel = {
  prompt: 'File Analysis',
  historyName: 'Generate File Analysis',
  type: 'prompt',
  header: '',
  id: 9,
  promptType: 'DFA',
  showForEng: [1, 2, 3]
};
