export class FirmModel {
  hash: string;
  legacyFirmId: number;
  name: string;
  onboardedInSp: boolean;
  onboarded: boolean;
  superUserEmail: string;
  isAccountLockedForBilling: boolean = false;
  isDeleteInProgress: boolean = false;
  isDeleted: boolean;
  deleteAt: Date;
  deletedBy: number;
  isPaused: boolean;
  isPauseInProgress: boolean = false;
  pausedAt: Date;
  pausedBy: number;
  isPegPacSubscribed: boolean = false;
  isPegPacMandatory: boolean = false;
  isPegPacValid: boolean = true;
  pegPacInvoice: string;
  pegPacExpiryDate: Date;
  countryId: number;
  countryCode: string;
  stateId: number;
  stateCode: string;
  currency: string;
  pauseCanceledBy: number;
}
