import { DatePipe } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { CLIENT_IMAGES_FOLDERS, DialogBoxText, LETTER_TYPES } from 'countable@helpers';
import { DialogInputModel, EngLetterStatusInputs, FirmModel, ManagementLetterModel } from 'countable@model';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { DateUtils } from '../helpers/DateUtils';
import { BillingModel } from '../model/billing/billing.model';
import { ClientImageInput, SaveClientSignature } from '../model/template/client-eng-letter.model';
import { AutomatedElements, CanadaAutomatedElements, ElementsInputObj, LetterStatusModel, LetterViewModel, SendMailModel, setStatusModel, TemplateMappedSectionDetails, UsAutomatedElements } from '../model/template/eng-letter.model';
import { AuthService } from './auth.service';
import { BillingService } from './billing.service';
import { EncodingService } from './encoding.service';
import { LETTER_SIGNATURES } from './eng-letter-constants';
import { FirmService } from './firm.service';


@Injectable({
  providedIn: 'root'
})

export class EngLetterService implements OnDestroy {

  constructor(private encodingService: EncodingService, private datePipe: DatePipe, public authService: AuthService,
    private firmService: FirmService, private billingService: BillingService) {

    this.allSubscriptions.add(
      this.firmService.subject.subscribe(firm => {
        if (firm) {
          this.firm = firm;
        }
      })
    )

    this.allSubscriptions.add(
      this.billingService.subject.subscribe(billing => {
        if (billing) {
          this.billing = billing;
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.allSubscriptions && this.allSubscriptions.unsubscribe();
  }

  private static readonly engLetter = 'Engagement Letter';
  private static readonly COMPLETION_LETTER = 'Management Letter';
  private static readonly folderName = "EngagementDocs/Engagement Letter";
  private static readonly COMPLETION_FOLDER = 'Management Letter';
  private readonly SPAN_TAG_OPEN = '<span>';
  private readonly SPAN_TAG_CLOSE = '</span>';
  // private readonly FIRM_SIGNATURE_BUTTON = `<a>Firm Sign Here</a>`
  public elementsValue: AutomatedElements | null = new AutomatedElements();
  public addElements: Subject<string> = new Subject<string>();
  public engLetter$: BehaviorSubject<LetterViewModel> = new BehaviorSubject<LetterViewModel>(null);
  public editEngLetter$: BehaviorSubject<LetterViewModel> = new BehaviorSubject<LetterViewModel>(null);
  public editDisable$: Subject<boolean> = new Subject<boolean>();
  public sign: 'draw' | 'text' | 'upload';
  public letterSignedDate: any;
  public dateUtil = new DateUtils(this.datePipe);
  public missedAutoElements$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public managementLetter$: BehaviorSubject<ManagementLetterModel> = new BehaviorSubject<ManagementLetterModel>(null);
  public editManagementLetter$: BehaviorSubject<ManagementLetterModel> = new BehaviorSubject<ManagementLetterModel>(null);
  public letterSignedOff$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public signatureList$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public billing: BillingModel;
  public firm: FirmModel;
  private allSubscriptions: Subscription = new Subscription();
  public compilationML$: BehaviorSubject<ManagementLetterModel> = new BehaviorSubject<ManagementLetterModel>(null);
  public editCompilationML$: BehaviorSubject<ManagementLetterModel> = new BehaviorSubject<ManagementLetterModel>(null);

  getUserDetails() {
    return this.authService.getUserDetail();
  }

  getEngagementDetails() {
    return JSON.parse((localStorage.getItem('selectedEngagementObj')));
  }

  getUserId(): number {
    return this.getUserDetails().useracctid;
  }

  getFirmId(): number {
    return this.getUserDetails().firmUserAcctId;
  }

  getClientFirmId(): number {
    return this.getEngagementDetails().clientfirmid;
  }

  getEngagementId(): number {
    return this.getEngagementDetails().engagementsid;
  }

  getClientEngId(): number {
    return +(localStorage.getItem('engagementID'));
  }

  getClientEngObj() {
    return JSON.parse(localStorage.getItem('selectedClientEngagementObj'));
  }

  getClientPortalFirmId(): number {
    return this.getClientEngObj().clientfirmid;
  }

  getClientEMail(): string {
    return this.getEngagementDetails().emailaddress;
  }

  getClientFirmName(): string {
    return this.getEngagementDetails().clientName;
  }

  getSignatureKey(): string {
    return 'Engagements/' + this.getFirmId() + '_Firm/' + this.getClientFirmId() + '_Client/' + this.getEngagementId() + '_Engagements/EngagementDocs/Engagement Letter';
  }

  getCountryId(): number {
    return this.getUserDetails().origin_country_id;
  }

  isPegPactSubscribed(): boolean {
    return this.billing?.isOnTrial ? this.billing?.isOnTrial : this.firm?.isPegPacValid;
  }

  getEngagementName(): string {
    return this.getEngagementDetails().engagementname ? this.getEngagementDetails().engagementname : this.getEngagementDetails().engagementName;
  }

  getLetterPreviewPath(fileName: string): string {
    return '/dashboard/ntrdashboard/ClientOnboarding/EngagementDetails/' + fileName;
  }

  getLetterEditPath(fileName: string): string {
    return '/dashboard/ntrdashboard/ClientOnboarding/EngagementTemplates/' + fileName;
  }

  getSignS3filePath(): string {
    return 'Engagements/' + this.getFirmId() + '_Firm/Templates/Common/Signatures';
  }

  getUploadSignFilePath(): string {
    return 'Engagements/' + this.getFirmId() + '_Firm/' + this.getClientPortalFirmId() + '_Client/Signature/';
  }
  getDrawnSignFilePath(): string {
    return 'Engagements/' + this.getFirmId() + '_Firm/' + this.getClientPortalFirmId() + '_Client/Signatures/';
  }

  isReviewEngagement(): boolean {
    return this.getEngagementDetails().pengagementtypeid === 2
  }

  getMLS3Path(folderName: CLIENT_IMAGES_FOLDERS): string {
    return 'Engagements/' + this.getFirmId() + '_Firm/' + this.getClientPortalFirmId() + '_Client/' + folderName + '/';
  }

  getMLResizedS3Path(folderName: CLIENT_IMAGES_FOLDERS): string {
    return "internal-data/" + this.getFirmId() + "-firm/" + this.getClientPortalFirmId() + "-client/resize/"+folderName.toLocaleLowerCase() + '/';
  }


  getCompletionEditPath(id: number): string {
    return '/dashboard/ntrdashboard/ClientOnboarding/ManagementLetter/Edit/' + id;
  }

  getCompletionPreviewPath(id: number): string {
    return '/dashboard/ntrdashboard/ClientOnboarding/ManagementLetter/Preview/' + id;
  }

  public getEngLetterEditPath = (id: number) => {return `/dashboard/ntrdashboard/ClientOnboarding/Letter/Edit/${id}`;};

  public getEngLetterPreviewPath = (id: number) => {return `/dashboard/ntrdashboard/ClientOnboarding/Letter/Preview/${id}`;};

  getIncorporationDate(): string {
    return this.getEngagementDetails().incorporationdate;
  }

  private getAssignedMembersToEngagement(): Array<any> {
    const selectedEngagementObj = this.getEngagementDetails();
    let assignedMembersList = [];
    if (selectedEngagementObj?.auditor_list) {
      assignedMembersList = JSON.parse(selectedEngagementObj.auditor_list);
    } else if (selectedEngagementObj?.auditorrole) {
      assignedMembersList = JSON.parse(selectedEngagementObj.auditorrole);
    } else if (selectedEngagementObj?.Assignedteam?.firmteam) {
      assignedMembersList = selectedEngagementObj?.Assignedteam?.firmteam;
    }
    return assignedMembersList;
  }

  private getPartnerName() {
    const assignedMembers = structuredClone(this.getAssignedMembersToEngagement());
    if ((assignedMembers && assignedMembers.length > 0)) {
      const index = assignedMembers.findIndex(m => m?.roleid === 1);
      if (index >= 0) {
        return assignedMembers[index]?.fullname;
      }
    }
    return '';
  }

  getFormDataS3(s3FileName: string, finalSaveContent): FormData {
    const jsonString = JSON.stringify(finalSaveContent);
    const blob = new Blob([jsonString], { type: 'text/json;charset=utf-8' });
    const formS3Data: FormData = new FormData();
    const uploadObj = JSON.stringify({
      module: EngLetterService.engLetter,
      firmId: this.getFirmId(),
      clientId: this.getClientFirmId(),
      engagementId: this.getEngagementId(),
      status: 0,
      folderName: EngLetterService.folderName,
      appSyncId: this.getUserId(),
      extension: "comp",
    });
    formS3Data.append('upload', this.encodingService.enData(uploadObj, 'body'));
    formS3Data.append('file', blob, s3FileName);
    return formS3Data;
  }

  getCompletionFormDataS3(finalSaveContent: any): FormData {
    const jsonString = JSON.stringify(finalSaveContent);
    const blob = new Blob([jsonString], { type: 'text/json;charset=utf-8' });
    const formS3Data: FormData = new FormData();
    const uploadObj = JSON.stringify({
      module: EngLetterService.COMPLETION_LETTER,
      firmId: this.getFirmId(),
      clientId: this.getClientFirmId(),
      engagementId: this.getEngagementId(),
      status: 0,
      folderName: EngLetterService.COMPLETION_FOLDER,
      appSyncId: this.getUserId(),
      extension: "comp",
    });
    formS3Data.append('upload', this.encodingService.enData(uploadObj, 'body'));
    formS3Data.append('file', blob, 'Management_Letter.json');
    return formS3Data;
  }

  getCompilationManagementFormDataS3(finalSaveContent: any, fileName: string): FormData {
    const jsonString = JSON.stringify(finalSaveContent);
    const blob = new Blob([jsonString], {type: 'text/json;charset=utf-8'});
    const formS3Data: FormData = new FormData();
    const uploadObj = JSON.stringify({
      module: EngLetterService.COMPLETION_LETTER,
      firmId: this.getFirmId(),
      clientId: this.getClientFirmId(),
      engagementId: this.getEngagementId(),
      status: 0,
      folderName: EngLetterService.COMPLETION_FOLDER,
      appSyncId: this.getUserId(),
      extension: 'comp'
    });
    formS3Data.append('upload', this.encodingService.enData(uploadObj, 'body'));
    formS3Data.append('file', blob, fileName);
    return formS3Data;
  }

  //Replacing the data with span tags
  getReplaceElements(): AutomatedElements {
    this.elementsValue.date = this.letterSignedDate ? this.letterSignedDate : 'Date';
    const replaceElements: AutomatedElements = new AutomatedElements();
    replaceElements.date = this.SPAN_TAG_OPEN + this.elementsValue.date + this.SPAN_TAG_CLOSE;
    replaceElements.entityName = this.SPAN_TAG_OPEN + this.elementsValue.entityName + this.SPAN_TAG_CLOSE;
    replaceElements.addBoDir = this.SPAN_TAG_OPEN + this.elementsValue.addBoDir + this.SPAN_TAG_CLOSE;
    replaceElements.addComplete = this.SPAN_TAG_OPEN + this.elementsValue.addComplete + this.SPAN_TAG_CLOSE;
    replaceElements.cityProvince = this.SPAN_TAG_OPEN + this.elementsValue.cityProvince + this.SPAN_TAG_CLOSE;
    replaceElements.postalCode = this.SPAN_TAG_OPEN + this.elementsValue.postalCode + this.SPAN_TAG_CLOSE;
    replaceElements.cityState = this.SPAN_TAG_OPEN + this.elementsValue.cityState + this.SPAN_TAG_CLOSE;
    replaceElements.clientCity = this.SPAN_TAG_OPEN + this.elementsValue.clientCity + this.SPAN_TAG_CLOSE;
    replaceElements.yearEndCurr = this.SPAN_TAG_OPEN + this.elementsValue.yearEndCurr + this.SPAN_TAG_CLOSE;
    replaceElements.yearEndPrev = this.SPAN_TAG_OPEN + this.elementsValue.yearEndPrev + this.SPAN_TAG_CLOSE;
    replaceElements.addShare = this.SPAN_TAG_OPEN + this.elementsValue.addShare + this.SPAN_TAG_CLOSE;
    replaceElements.addManagement = this.SPAN_TAG_OPEN + this.elementsValue.addManagement + this.SPAN_TAG_CLOSE;
    replaceElements.firstName = this.SPAN_TAG_OPEN + this.elementsValue.firstName + this.SPAN_TAG_CLOSE;
    replaceElements.lastName = this.SPAN_TAG_OPEN + this.elementsValue.lastName + this.SPAN_TAG_CLOSE;
    replaceElements.note = this.SPAN_TAG_OPEN + this.elementsValue.note + this.SPAN_TAG_CLOSE;
    replaceElements.engId = this.SPAN_TAG_OPEN + this.elementsValue.engId + this.SPAN_TAG_CLOSE;
    replaceElements.sc = this.SPAN_TAG_OPEN + this.elementsValue.sc + this.SPAN_TAG_CLOSE;
    replaceElements.balanceSheet = this.SPAN_TAG_OPEN + this.elementsValue.balanceSheet + this.SPAN_TAG_CLOSE;
    replaceElements.sire = this.SPAN_TAG_OPEN + this.elementsValue.sire + this.SPAN_TAG_CLOSE;
    replaceElements.scse = this.SPAN_TAG_OPEN + this.elementsValue.scse + this.SPAN_TAG_CLOSE;
    replaceElements.firmName = this.SPAN_TAG_OPEN + this.elementsValue.firmName + this.SPAN_TAG_CLOSE;
    replaceElements.firmSignature = LETTER_SIGNATURES.FIRM_SIGN_HERE;
    replaceElements.firmAddress = this.SPAN_TAG_OPEN + this.elementsValue.firmAddress + this.SPAN_TAG_CLOSE;
    replaceElements.partnerName = this.SPAN_TAG_OPEN + this.elementsValue.partnerName + this.SPAN_TAG_CLOSE;
    replaceElements.streetAddress = this.SPAN_TAG_OPEN + this.elementsValue.streetAddress + this.SPAN_TAG_CLOSE;
    return replaceElements;
  }

  // API call response loaded to AutomatedElementsModel due to key's are not accepted
  public parseElementsResponse(elements: any) {
    if (!elements) {
      return this.fetchStaticElements();
    }

    const parsedElements = JSON.parse(elements)[0];
    this.checkEmptyElements(structuredClone(parsedElements));
    const ele = JSON.parse(elements);
    if (Array.isArray(ele) && ele.length === 0) {
      return this.fetchStaticElements();
    }
    this.elementsValue.addBoDir = parsedElements["addressBoardOfDirectors"];
    this.elementsValue.addManagement = parsedElements["addressManagement"];
    this.elementsValue.addShare = parsedElements["addressShareholders"];
    this.elementsValue.cityProvince = parsedElements["cityAndProvince"] || '';
    this.elementsValue.clientCity = parsedElements["clientCity"];
    this.elementsValue.addComplete = parsedElements['completeAddress'] ? parsedElements['completeAddress'] : '';
    this.elementsValue.entityName = parsedElements["entityName"];
    this.elementsValue.yearEndCurr = parsedElements["financialYearEnddate"];
    this.elementsValue.yearEndPrev = parsedElements["previousYearEndDate"];
    this.elementsValue.balanceSheet = parsedElements["balanceSheet"];
    this.elementsValue.cityState = parsedElements["cityAndState"] || '';
    this.elementsValue.firstName = parsedElements["firstName"];
    this.elementsValue.lastName = parsedElements["lastName"];
    this.elementsValue.postalCode = parsedElements["postCode"];
    this.elementsValue.date = this.letterSignedDate ? this.letterSignedDate : null;
    this.elementsValue.firmName = this.authService.getUserDetail().firmName;
    this.elementsValue.firmAddress = parsedElements['firmAddress'] || '';
    this.elementsValue.partnerName = parsedElements['partnerName'] ? parsedElements['partnerName'] : '';
    this.elementsValue.streetAddress = parsedElements['streetAddress'] ? parsedElements['streetAddress'] : '';
    return structuredClone(this.elementsValue);
  }

  private fetchStaticElements() {
    this.elementsValue = new AutomatedElements();
    this.elementsValue.cityProvince = '';
    this.elementsValue.clientCity = '';
    this.elementsValue.addComplete = '';
    this.elementsValue.entityName = '';
    this.elementsValue.yearEndCurr = '';
    this.elementsValue.yearEndPrev = '';
    this.elementsValue.balanceSheet = 'Balance Sheet';
    this.elementsValue.cityState = '';
    this.elementsValue.firstName = '';
    this.elementsValue.lastName = '';
    this.elementsValue.postalCode = '';
    this.elementsValue.addComplete = '';
    this.elementsValue.date = this.letterSignedDate ? this.letterSignedDate : null;
    this.elementsValue.firmName = this.authService.getUserDetail().firmName;
    this.elementsValue.addBoDir = 'To Board of Directors';
    this.elementsValue.addManagement = 'To management';
    this.elementsValue.addShare = 'To shareholders';
    this.elementsValue.scse = 'statement of income and retained earnings';
    this.elementsValue.note = 'Add a note';
    this.elementsValue.firmAddress = '';
    this.elementsValue.partnerName = '';
    this.elementsValue.streetAddress = '';
    return structuredClone(this.elementsValue);
  }

  private checkEmptyElements(elements: any) {
    const elementsObj = this.mapToElements(elements);
    const checkNullValues = this.checkNullValues(elementsObj);
    this.missedAutoElements$.next(checkNullValues);
  }

  private checkNullValues(elementsObj: any): boolean {
    const obj = JSON.parse(JSON.stringify(elementsObj));
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === null) {
        return true;
      }
    }
    return false;
  }

  private mapToElements(elements: any): CanadaAutomatedElements | UsAutomatedElements {
    if (this.getCountryId() === 226) {
      const elements: CanadaAutomatedElements = new CanadaAutomatedElements();
      elements.addComplete = elements["completeAddress"];
      elements.cityProvince = elements["cityAndProvince"];
      elements.clientCity = elements["clientCity"];
      elements.entityName = elements["entityName"];
      elements.yearEndCurr = elements["financialYearEnddate"];
      elements.yearEndPrev = elements["previousYearEndDate"];
      elements.postalCode = elements["postCode"];
      elements.cityProvince = elements["cityAndProvince"];
      elements.firstName = elements["firstName"];
      elements.lastName = elements["lastName"];
      return elements;
    }
    if (this.getCountryId() === 38) {
      const elements: UsAutomatedElements = new UsAutomatedElements();
      elements.addComplete = elements["completeAddress"];
      elements.cityState = elements["cityAndProvince"];
      elements.clientCity = elements["clientCity"];
      elements.entityName = elements["entityName"];
      elements.yearEndCurr = elements["financialYearEnddate"];
      elements.yearEndPrev = elements["previousYearEndDate"];
      elements.postalCode = elements["postCode"];
      elements.firstName = elements["firstName"];
      elements.lastName = elements["lastName"];
      return elements;
    }
  }

  getLetterData(data: any): LetterViewModel {
    const letterData: LetterViewModel = new LetterViewModel();
    letterData.engagementtemplateid = data.engagementtemplateid;
    letterData.engleftsidedocid = data.engleftsidedocid;
    letterData.fileName = data.fileNameWithoutExt;
    letterData.orginalFileName = data.originalfilename;
    letterData.s3FilePath = data.filepath;
    letterData.isDialogShow = 'no';
    letterData.isFrom = 'Client-Onboarding';
    return letterData;
  }

  getStatusActionObj(type: 'add' | 'remove' | 'send' | 'resend' | 'signoff' | 'removeSignoff', letterObj: LetterViewModel, id: number, leftSideDocid?: number, signOffDate?: any): setStatusModel {
    const inputData: setStatusModel = new setStatusModel();
    inputData.engagementsid = this.getEngagementId();
    inputData.engagementtemplateid = letterObj.engagementtemplateid ? letterObj.engagementtemplateid : 0;
    inputData.isdelete = 0;
    inputData.useracctid = this.getUserId();
    switch (type) {
      case ('add'):
        inputData.SignatureImageId = id;
        inputData.isresent = 0;
        inputData.issent = 0;
        break;
      case ('remove'):
        inputData.SignatureImageId = 0;
        inputData.isresent = 0;
        inputData.issent = 0;
        break;
      case ('send'):
        inputData.SignatureImageId = id;
        inputData.isresent = 0;
        inputData.issent = 1;
        break;
      case ('resend'):
        inputData.SignatureImageId = id;
        inputData.isresent = 1;
        inputData.issent = 0;
        break;
      case ('signoff'):
        inputData.engleftsidedocid = leftSideDocid ? leftSideDocid : 0;
        const date = this.dateUtil.onlyDate(signOffDate);
        inputData.isclientaccepted = 1;
        inputData.clientaccepteddate = date ? date : 0;
        break;
      case ('removeSignoff'):
        inputData.isremoval = 1;
        inputData.engleftsidedocid = leftSideDocid ? leftSideDocid : 0;
        break;
    }
    return inputData;
  }


  getStatusInputObj(id: number, from?: 'firm' | 'client', leftSideDocid?: number): EngLetterStatusInputs {
    const Obj: EngLetterStatusInputs = new EngLetterStatusInputs();
    Obj.engagementId = from === 'firm' ? this.getEngagementId() : this.getClientEngId();
    Obj.engagementTemplateId = id ? id : 0;
    Obj.engLeftSideDocId = leftSideDocid ? leftSideDocid : 0;
    return Obj;
  }

  getLetterStatus(res: any): LetterStatusModel {
    const status: any = JSON.parse(res) ? JSON.parse(res) : res;
    const statusResponse = status["Engagementtemplate"] ? status["Engagementtemplate"][0] : status;
    const letterStatus: LetterStatusModel = new LetterStatusModel();
    if (statusResponse) {
      const date = statusResponse.signedDate;
      letterStatus.createdDate = date ? moment(date, 'MMM Do YYYY h:mm;ss A').format('DD/MM/YY') : null;
      const d = date ? moment(date, 'MMM Do YYYY h:mm;ss A').format('MMMM DD, YYYY') : null;
      letterStatus.signedDate = d;
      this.letterSignedDate = d;
      letterStatus.isLetterSigned = statusResponse.lettersigned === 1;
      letterStatus.isReadyToSend = statusResponse.lettersigned === 1 &&
        ((statusResponse.issent === 0 || !statusResponse.issent) && (statusResponse.isresent === 0 || !statusResponse.isresent));
      letterStatus.isLetterSend = statusResponse.lettersigned === 1 && statusResponse.issent === 1;
      letterStatus.isReadyToResend = statusResponse.lettersigned === 1 && ((statusResponse.issent === 1 && statusResponse.isresent === 0) || (statusResponse.issent === 0 && statusResponse.isresent === 1));
      letterStatus.isLetterResend = statusResponse.lettersigned === 1 && (statusResponse.isresent === 1 && statusResponse.issent === 0);
      letterStatus.isRemoveHide = (letterStatus.isLetterSend && !letterStatus.isLetterResend) || (!letterStatus.isLetterSend && letterStatus.isLetterResend);
      letterStatus.isResendEnableAfterEdit = (statusResponse.lettersigned === 1 && statusResponse.issent === 1) || (statusResponse.lettersigned === 1 && statusResponse.isresent === 1);
      letterStatus.hasSharedToClient = (statusResponse.issent === 1 || statusResponse.isresent === 1);
      letterStatus.signatureData.imageId = statusResponse.firmsignatureid || 0;
      letterStatus.signatureData.imageType = 'sign';
      letterStatus.signatureData.signatureText = statusResponse.sign_text;
      letterStatus.signatureData.signatureType = statusResponse.signature_type;
      letterStatus.signatureData.s3FilePath = statusResponse.signature_type !== 'text' ? statusResponse.jsonfilepath : null;
      letterStatus.sendTimeStamp = this.dateUtil.convertToLocale(statusResponse.senddatestamp);
      letterStatus.clientaccepteddate = statusResponse.clientaccepteddate ? statusResponse.clientaccepteddate : 0;
      letterStatus.isclientaccepted = statusResponse.isclientaccepted ? statusResponse.isclientaccepted : 0;
      letterStatus.isPDFSignedOff = statusResponse.isclientaccepted === 1;
      letterStatus.isClientSignEnable = !statusResponse.ClientSignature ? true : (statusResponse.ClientSignature[0].isclientaccepted === 0);
      if (statusResponse.ClientSignature) {
        letterStatus.ClientSignatureDetails.isClientSigned = !!statusResponse.ClientSignature[0].signature_type;
        letterStatus.ClientSignatureDetails.signatureType = statusResponse.ClientSignature[0].signature_type;
        letterStatus.ClientSignatureDetails.signatureText = statusResponse.ClientSignature[0].sign_text;
        letterStatus.ClientSignatureDetails.jsonFilePath = statusResponse.ClientSignature[0].jsonfilePath;
        letterStatus.ClientSignatureDetails.jsonFileName = statusResponse.ClientSignature[0].jsonfileName;
        letterStatus.ClientSignatureDetails.isclientaccepted = statusResponse.ClientSignature[0].isclientaccepted || 0;
        letterStatus.ClientSignatureDetails.clientsignatureid = statusResponse.ClientSignature[0].clientsignatureid || 0;
        const date = statusResponse.ClientSignature[0].clientaccepteddate ? statusResponse.ClientSignature[0].clientaccepteddate : null;
        letterStatus.ClientSignatureDetails.clientaccepteddate = this.dateUtil.convertToLocale(date);
        letterStatus.ClientSignatureDetails.clientsigneddate = this.dateUtil.formatDateTime(date)
        letterStatus.isClientAcceptedSigned = statusResponse.ClientSignature[0].isclientaccepted === 1;
      } else {
        letterStatus.ClientSignatureDetails = null;
      }
      return letterStatus;
    } else {
      letterStatus.isLetterSigned = false;
      letterStatus.signatureData = null;
      letterStatus.isSendToClient = 0;
      return letterStatus;
    }
  }

  getClientSaveInputs(id: number, type: 'draw' | 'text' | 'upload' | 'signoff', text: string, clientSignData?: LetterStatusModel) {
    const inputObj: SaveClientSignature = new SaveClientSignature();
    inputObj.loginid = this.getUserId();
    inputObj.engagementtemplateid = id;
    inputObj.signature_type = type;
    switch (type) {
      case ('text'):
        inputObj.sign_text = text;
        break;
      case ('upload'):
        inputObj.json_name = text;
        inputObj.json_path = this.getUploadSignFilePath() + text;
        break;
      case ('draw'):
        inputObj.json_name = text;
        inputObj.json_path = this.getDrawnSignFilePath() + text;
        break;
      case ('signoff'):
        inputObj.isclientaccepted = 1;
        inputObj.client_signature_id = clientSignData.ClientSignatureDetails.clientsignatureid;
        inputObj.signature_type = clientSignData.ClientSignatureDetails.signatureType;
        inputObj.json_name = clientSignData.ClientSignatureDetails.jsonFileName;
        inputObj.sign_text = clientSignData.ClientSignatureDetails.signatureText;
        inputObj.json_path = this.getUploadSignFilePath() + inputObj.json_name;
        break;
    }
    const data = {
      "procedureName": "save_client_signature",
      "inputParameters": JSON.stringify(inputObj)
    }
    return data;
  }

  getMailNotificationsInputs(type: 'send' | 'resend', letterName: string): SendMailModel {
    const input: SendMailModel = new SendMailModel();
    input.email = this.getClientEMail();
    input.firmName = this.getClientFirmName();
    input.letterName = letterName;
    input.isResend = type === 'resend';
    return input;
  }

  getAutomatedElementsInput(origin: 'firm' | 'client'): ElementsInputObj {
    const inputObj: ElementsInputObj = new ElementsInputObj();
    inputObj.clientFirmId = origin === 'firm' ? this.getClientFirmId() : this.getClientPortalFirmId();
    inputObj.engagementId = origin === 'firm' ? this.getEngagementId() : this.getClientEngId();
    inputObj.loginId = this.getUserId();
    inputObj.firmHash = this.firmHash();
    return inputObj;
  }

  getMaxNumber(idArr: number[]): number {
    return Math.max(...idArr) + 1;
  }

  getOneSectionId(contentArr: Array<any>, isOneSection: boolean) {
    if (!isOneSection) return 1000;
    const oneSectionObj = contentArr.filter(content => content.checked === true)[0];
    return oneSectionObj.id;
  }

  getPDFTitle(): string {
    return this.getEngagementName() + '-EL.pdf';
  }

  getWordTitle(): string {
    return this.getEngagementName() + '-EL.doc';
  }

  getClientPDFTitle(): string {
    return this.getClientEngObj().engagementName ?? this.getClientEngObj().engagementname
  }

  getManagementLetterData(letterObj: any): ManagementLetterModel {
    const Obj: ManagementLetterModel = new ManagementLetterModel();
    Obj.isFrom = 'Management';
    Obj.fileName = letterObj.submodulename || 'Management representation  Letter';
    Obj.subModuleId = letterObj.submoduleid;
    Obj.subModuleUUID = letterObj.SMUFID;
    Obj.isLetterMappedFromTemplateSaved = letterObj.is_copied_to_engagement === 1;
    if (letterObj?.is_copied_to_engagement === 1) {
      return Obj;
    }
    Obj.sectionTemplateDetails = new TemplateMappedSectionDetails();
    Obj.sectionTemplateDetails.firmTemplateId = letterObj?.firmtemplateid ?? 0;
    Obj.sectionTemplateDetails.masterTemplateId = letterObj?.mastertemplateid ?? 0;
    Obj.sectionTemplateDetails.jsonFileName = letterObj?.JsonFileName ?? '';
    Obj.sectionTemplateDetails.jsonFilePath = letterObj?.JsonFilePath ?? '';
    Obj.sectionTemplateDetails.templateName = letterObj.submodulename;
    Obj.sectionTemplateDetails.isCopiedToEngagement = letterObj?.is_copied_to_engagement === 1;
    Obj.sectionTemplateDetails.subModuleId = letterObj?.submoduleid;
    Obj.sectionTemplateDetails.subModuleUUID = letterObj?.SMUFID;
    return Obj;
  }

  public getCompilationMLData(letterObj: any, isFrom: LETTER_TYPES): ManagementLetterModel {
    const Obj: ManagementLetterModel = new ManagementLetterModel();
    Obj.fileName = letterObj.name;
    Obj.isFrom = isFrom;
    Obj.subModuleId = letterObj.id;
    Obj.subModuleUUID = letterObj.subModuleUUID;
    Obj.isLetterMappedFromTemplateSaved = letterObj?.is_copied_to_engagement === 1;

    if (letterObj?.is_copied_to_engagement === 1) {
      return Obj;
    }
    Obj.sectionTemplateDetails = new TemplateMappedSectionDetails();
    Obj.sectionTemplateDetails.firmTemplateId = letterObj?.firmtemplateid;
    Obj.sectionTemplateDetails.masterTemplateId = letterObj?.mastertemplateid;
    Obj.sectionTemplateDetails.jsonFileName = letterObj?.JsonFileName;
    Obj.sectionTemplateDetails.jsonFilePath = letterObj?.JsonFilePath;
    Obj.sectionTemplateDetails.templateName = letterObj.name;
    Obj.sectionTemplateDetails.isCopiedToEngagement = letterObj?.is_copied_to_engagement === 1;
    Obj.sectionTemplateDetails.subModuleId = letterObj?.id;
    Obj.sectionTemplateDetails.subModuleUUID = letterObj?.subModuleUUID;
    return Obj;
  }

  getCompletionLetterAddData(Obj: any): MatDialogConfig<any> {
    const data: DialogInputModel = new DialogInputModel();
    data.dialogType = 'add_letter';
    data.dialogHeader = DialogBoxText.ADD_LETTER;
    data.data = { ...Obj }
    const config: MatDialogConfig<any> = new MatDialogConfig<any>();
    config.data = { ...data };
    config.disableClose = true;
    config.disableClose = false;
    config.id = 'add_letter';
    config.autoFocus = true;
    config.height = '100%';
    config.maxWidth = '100%';
    config.width = '350px';
    config.panelClass = 'right-drawer-outer';
    config.position = { 'top': '0', right: '0' };
    return config
  }

  public getReplacedClientNameContent(content, clientName) {
    return content.map(obj => {
      const header = obj.header.replace('clientNameReplace', clientName);
      const text = obj.text.replace('clientNameReplace', clientName);
      return { header, text };
    });
  }

  public getTomorrowDate(): string {
    return this.dateUtil.getTomorrowDate();
  }

  public isEngLetterValid = (inputArr: any[]): boolean =>
    inputArr.some(section => section.checked && section.content && section.content.includes(LETTER_SIGNATURES.FIRM_SIGNATURE))
    && inputArr.some(section => section.checked && section.content && section.content.includes(LETTER_SIGNATURES.CLIENT_SIGNATURE));

  public imageFirmSignUrl = (url: string, isChangeable: boolean): string => {
    return isChangeable ? `<img src="${url}" alt="Firm Signature Image">` :
      `<img src="${url}" alt="Firm Signature Image"><br><a class="cursor-pointer mt-2 button-small mat-button
                                  mat-flat-button mat-primary mat-success">Change signature</a>`;
  }
  public textFirmSign = (url: string, isChangeable: boolean): string => {
    return isChangeable ? `<span class="font-sign fs-50">${url}</span>` :
      `<span class="font-sign fs-50">${url}</span><br><a class="mt-2 button-small mat-button
                                  mat-flat-button mat-primary mat-success">Change signature</a>`;
  }

  public imageClientSignUrl = (url: string, letterView: 'Firm' | 'Client' | 'Signed-Url' | 'Signed-Text'): string => {
    switch (letterView) {
      case 'Firm':
        return LETTER_SIGNATURES.CLIENT_SIGN_HERE;
      case 'Client':
        return LETTER_SIGNATURES.CLIENT_SIGN_HERE;
      case 'Signed-Url':
        return `<img src="${url}" alt="Client Signature Image">`;
      case 'Signed-Text':
        return `<span class="font-sign fs-50">${url}</span>`;
    }
  }

  public getClientMLImageInputs(typeOfAction: 'upload' | 'text' | 'draw', text: string, typeOfImage: CLIENT_IMAGES_FOLDERS): any {
    const Obj: ClientImageInput = new ClientImageInput();
    switch (typeOfAction) {
      case 'upload':
        Obj.json_name = text;
        // Obj.json_path = this.getMLS3Path(typeOfImage);
        Obj.signature_type = 'upload';
        break;

    }
    return {
      'procedureName': 'save_client_signature',
      'inputParameters': JSON.stringify(Obj)
    };
  }

  public generateRandomNumber(): number {
    const min = 1000000;
    const max = 9999999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getMLPDFTitle(): string {
    return this.getEngagementName() + '-ML.pdf';
  }

  public getMLWordTitle = (): string => {return this.getEngagementName() + '-ML.doc';};

  public S3PathForSavingInCompletion(fileName: string): string {
    return 'Engagements/' + this.getFirmId() + '_Firm/' + this.getClientFirmId() + '_Client/' + this.getEngagementId() +
      '_Engagements/EngagementDocs/Management Letter/' + fileName;
  }

  public clientFirmId = (): number => { return this.engagementDetails() ? +(this.engagementDetails()?.clientfirmid) : 0; };

  public engagementDetails = (): any => { return JSON.parse((localStorage.getItem('selectedEngagementObj'))); };

  checkEmptyAutomatedElements() {
    const emptyArray = []
    const replaceElements: AutomatedElements = new AutomatedElements();
    const elements = structuredClone(this.elementsValue);
    for (const key in elements) {
      if (this.elementsValue.hasOwnProperty(key)) {
        const isEmpty = this.checkEmptyValue(key, elements);
        if (isEmpty) {
          const emptyHeader = replaceElements[key];
          emptyArray.push(emptyHeader)
        }
      }
    }
    return emptyArray
  }
  private checkEmptyValue(key, data) {
    if (data[key] === null || data[key] === "" || data[key] === " ") {
      if (key === 'date') {
        return false
      }
      if ((key == 'cityState')) {
        if (data['cityProvince']?.split(',')?.length > 1 || key === 'cityState') {
          return false;
        }
      }
      return true;
    } else {
      if (key == 'addComplete') {
        const regExp = /[a-zA-Z]/g;
        data['addComplete'] = data['addComplete']?.replace(/<br\s*\/?>/gi, '')
        if (regExp.test(data['addComplete'])) {
          return false;
        } else {
          return true
        }
      }
      if (key === 'firmAddress') {
        const regExp = /[a-zA-Z]/g;
        data['firmAddress'] = data['firmAddress']?.replace(/<br\s*\/?>/gi, '');
        if (regExp.test(data['firmAddress'])) {
          return false;
        } else {
          return true;
        }
      }
    }
  }

  public firmHash = (): string => {return localStorage.getItem('firm-hash');};

  public getEngTyepId = (): number => {
    const engInfo = structuredClone(this.getEngagementDetails());
    return engInfo?.pengagementtypeid ? engInfo?.pengagementtypeid :
      (engInfo?.engagementtypeid ? engInfo?.engagementtypeid : 0);
  };

  public updateTemplateValiditiyByEngTypeId(templateEngTypeId: number = 0) {
    if (templateEngTypeId === 0) {
      return false;
    }
    switch (this.getEngTyepId()) {
      case 1:
        return ![1, 34, 35, 36, 37].includes(templateEngTypeId);
      case 2:
        return ![2, 34, 35, 36, 37].includes(templateEngTypeId);
      case 34:
      case 35:
      case 36:
      case 37:
        return ![1,34, 35, 36, 37].includes(templateEngTypeId);
      default:
        return true;
    }
  }
}
