export const FS_TABLE_FORMATS = [
  {
    value: 'italic',
    title: 'Italicize Highlighted Text',
    path: '../../../../assets/images/ui-uplift-icons/global/italic-01.svg',
    active: false
  },
  {
    value: 'bold',
    title: 'Bold Highlighted Text',
    path: '../../../../assets/images/ui-uplift-icons/global/bold-01.svg',
    active: false
  },
  {
    value: 'underline',
    title: 'Underline Highlighted Text',
    path: '../../../../assets/images/ui-uplift-icons/global/underline.svg',
    active: false
  },
  {
    value: 'border-bottom',
    title: 'Border Bottom',
    path: '../../../../assets/images/ui-uplift-icons/global/border-bottom.svg',
    active: false
  },
  {
    value: 'border-top',
    title: 'Border Top',
    path: '../../../../assets/images/ui-uplift-icons/global/border-top.svg',
    active: false
  }
  // {
  //   value: 'border',
  //   title: 'Border Around',
  //   path: '../../../../assets/images/ui-uplift-icons/global/border-around.svg',
  //   active: false
  // }
];

export const NOT_MANUAL_TABLE = ['hide', 'description', 'search', 'select', 'currYear', 'priorYear', 'total'];

export const FS_TABLE_COLUMNS = [
  { key: 'currYear', value: 'Current Year', subMenu: [] },
  { key: 'priorYear', value: 'Prior Year', subMenu: [] },
  {
    key: 'manual', value: 'Manual', subMenu: [
      { key: 1, value: '1 Column', isDisabled: false, max: 4 },
      { key: 2, value: '2 Columns', isDisabled: false, max: 3 },
      { key: 3, value: '3 Columns', isDisabled: false, max: 2 },
      { key: 4, value: '4 Columns', isDisabled: false, max: 1 },
      { key: 5, value: '5 Columns', isDisabled: false, max: 0 }
    ]
  },
  { key: 'total', value: 'Total', subMenu: [] }
];

export const FS_TABLE_ROWS = [
  { key: 'subheader', value: 'Subheader', subMenu: [], disabled: false },
  {
    key: 'general', value: 'General', disabled: false, subMenu: [
      { key: 1, value: '1 Row' },
      { key: 2, value: '2 Rows' },
      { key: 3, value: '3 Rows' },
      { key: 4, value: '4 Rows' }
    ]
  },
  {
    key: 'textonly', value: 'Text Only', disabled: false, subMenu: [
      { key: 0, value: 'Text Only Row' },
      { key: 1, value: 'With 1 Subrow' },
      { key: 2, value: 'With 2 Subrows' },
      { key: 3, value: 'With 3 Subrows' }
    ]
  },
  { key: 'subtotal', value: 'Subtotal', subMenu: [], disabled: false }
];

export const FS_TABLE_ROW_DEFAULT = [
  {
    key: 'mmyyformat', value: 'Month and Year Format', subMenu: [
      { key: 'long', name: 'Long Format (Month DD, 20XX)', value: 'Month DD, 20XX' },
      { key: 'short', name: 'Short Format (MON DD, 20XX)', value: 'MON DD, 20XX' },
      { key: 'year', name: 'Year Only (20XX)', value: '20XX' }
    ]
  },
  { key: 'rename', value: 'Rename', subMenu: [] },
  { key: 'format', value: 'Format', subMenu: [] },
  {
    key: 'delete', value: 'Delete', subMenu: [
      { key: 'one', name: 'Delete Column' },
      { key: 'all', name: 'Delete All Columns' }
    ]
  }
];

export const FS_TABLE_ROW_ACTIONS = [
  // { key: 'edit', value: 'Edit' },
  { key: 'hide', value: 'Hide', allowedRows: ['subtotal', 'totalrow', 'subtotal_subrow'], default: false, disable: false },
  { key: 'subrow', value: 'Add sub-row', allowedRows: ['textonly'], default: false, disable: false },
  { key: 'format', value: 'Format', allowedRows: ['subheader', 'general', 'textonly'], default: true, disable: false },
  { key: 'delete', value: 'Delete', allowedRows: ['general', 'textonly', 'subtotal', 'subtotal_subrow'], default: true, disable: false }
];

export enum NOTES {
  NONE_FORMAT = 'NONE',
  SINGLE_FORMAT = 'SINGLE_COLUMN',
  ALL_FORMAT = 'ALL_FORMAT',
  TOTAL_COL = 'total',
  MANUAL = 'MANUAL',
  TOTAL = 'TOTAL',
  TOTAL_COL_NAME = 'Total'
};

export enum NOTES_MESSAGES {
  COLUMN_UPDATED = 'Column(s) updated successfully',
  COLUMN_DELETED = 'Column(s) deleted successfully',
  ROW_UPDATED = 'Row(s) updated successfully',
  HEADER_UPDATED = 'Header(s) updated successfully',
  SUB_HEADER_UPDATED = 'Subheader(s) updated successfully',
  SUB_HEADER_DELETED = 'Subheader(s) deleted successfully',
  HEADER_AND_SUB_HEADER_UPDATED = 'Header(s) and subheader(s) updated successfully',
};
