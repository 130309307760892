import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../../../../services/api.service';

@Component({
    selector: 'delete-leadsheet-modal',
    templateUrl: 'delete-leadsheet-modal.component.html',
    styleUrls: ['./delete-leadsheet-modal.component.scss']
})
export class DeleteLeadsheetModalComponent {
    constructor(
        public thisDialogRef: MatDialogRef<DeleteLeadsheetModalComponent>,
        @Inject(MAT_DIALOG_DATA) public modalData: any,
        private apiService: ApiService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService
    ) { }

    onCloseConfirm() {
        this.thisDialogRef.close(true);
        const deleteLsObj = {
            "dellsname": [{
                "leadsheetid": this.modalData.lsId,
                "loginid": this.modalData.loginId
            }]
        }
        const deleteLsCodeObj = JSON.stringify(deleteLsObj);
        const saveObj = {
            "inputParameters": deleteLsCodeObj,
            "procedureName": "deleteleadsheetname"
        }
        this.spinner.show();
        this.apiService.saveData(saveObj).subscribe(response => {
            if (response) {
                this.spinner.hide();
                this.toastr.success("Leadsheet deleted successfully");
            }
        }, error => {
            this.spinner.hide();
            this.toastr.error(error.error);
        });
    }

    onCloseCancel() {
        this.thisDialogRef.close(false);
    }

}
