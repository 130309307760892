import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { BillingModel } from 'src/app/model/billing/billing.model';
import { FirmModel } from 'src/app/model/firm/firm.model';
import { ApiService } from 'src/app/services/api.service';
import { BillingService } from 'src/app/services/billing.service';
import { FirmService } from 'src/app/services/firm.service';
import { TimeTrackerService } from '../../../../services/time-tracker.service';
import { ModalService } from '../../../shared/modal.service';
import { SharedService } from '../../../shared/shared.service';

@Component({
    selector: 'modal-body',
    templateUrl: './modal-body.component.html',
    styleUrls: ['./modal-body.component.scss']
})
export class ModalBodyComponent implements OnInit, AfterViewInit {
  progressbarValue = 100;
  curSec: number = 0;
  notifications: any = [];
  alertSub: Subscription;
  loggedInUserData:any;
  EngagementYearDetails: any;
  trialBalYear: any;
  permissionsSub: Subscription;
  accessPermissions: any;
  billing: BillingModel;
  firm: FirmModel;




private subscriptions: Subscription = new Subscription();

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private apiService: ApiService,
    private router:Router,
    private modalService: ModalService,
    private trackerService: TimeTrackerService,
    private firmService: FirmService,
    private billingService: BillingService
  ) {
    this.subscriptions.add(
      this.firmService.subject.subscribe(firm => {
       if (firm) {
         this.firm = firm;

       }
     })
   )

    this.subscriptions.add(
      this.billingService.subject.subscribe(billing => {
       if (billing) {
         this.billing = billing;

       }
     })
   )
  }

  ngOnInit(): void {
    this.loggedInUserData = JSON.parse((localStorage.getItem('userDetails')));
    this.alertSub = this.sharedService.notifyAlertSubject.subscribe( alert => {

      if (this.loggedInUserData.toshowtermpolicy == 0) {
        if (alert) {
          this.notifications = alert[0] ? alert[0] : '';
          setTimeout(() => { this.openModal('custom-modal-1');}, 2000);
          setTimeout(() => { this.closeModal('custom-modal-1'); }, 10000);
        }
      }
    });
    this.permissionsSub = this.sharedService.accessPermissionsSubject.subscribe(reponse => {
      if (reponse) {
        this.accessPermissions = reponse;
      }
    });
  }
  ngAfterViewInit(){
    //this.openModal("custom-modal-1");
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
    //this.onNotificationRead(this.notifications);
  }
  startTimer(seconds: number) {
    const time = seconds;
    const timer$ = interval(1000);

    const sub = timer$.subscribe((sec) => {
      this.progressbarValue = 100 - sec * 100 / seconds;
      this.curSec = sec;

      if (this.curSec === seconds) {
        sub.unsubscribe();
      }
    });
  }
  onNotificationRead(value) {
    if (value.isread === 0) {
        const inputData = {
            "usertrack":
                [{
                    "tracknotificationid": value.tracknotificationid,
                    "useraccountid": value.useraccountid,
                    "isread": 1,
                    "isallcleared": 0,
                    "usertracknotificationid": 0
                }]
        };
        const inputString = JSON.stringify(inputData);
        let saveData = {
            "procedureName": "saveusertracknotification",
            "inputParameters": inputString
        };
        this.apiService.saveData(saveData).subscribe(response => {

        }, error => {

        });
    }
  }

  redirection(value) {
    if (this.loggedInUserData.userroleId !== 4) {
      if ((this.billing && this.billing.isOnTrial && !this.billing.isTrialExpired) || (this.billing?.plan && !this.firm?.isAccountLockedForBilling && !(this.billing?.isPaused || this.firm?.isPaused))) {
        if (value.moduleid === 4) {
          if (this.accessPermissions.viewmodifiengagement) {
            this.getInsideEngagementData(value);
          }
        }
        else if (value.moduleid === 3) {
          this.router.navigate(['/dashboard/engagement/engagement-list']);
        }
        else if (value.moduleid === 2) {
          this.router.navigate(['/dashboard/members/clients']);
        }
        else if (value.moduleid === 1) {
          this.router.navigate(['/dashboard/members/teammember']);
        }
      }
    }
    else if(this.loggedInUserData.userroleId == 4){
      if ((this.billing && this.billing.isOnTrial && !this.billing.isTrialExpired) || (this.billing?.plan && !this.firm?.isAccountLockedForBilling && !(this.billing.isPaused || this.firm?.isPaused))) {
        if (value.moduleid === 5) {
          this.router.navigate(['/dashboard/clients/engagement-details/documents']);
        }
        else if (value.moduleid === 6) {
          this.router.navigate(['/dashboard/clients/engagement-details/engagement-letters']);
        }
      }
    }

  }
getInsideEngagementData(value){
  const inputData = { "loginid": this.loggedInUserData.useracctid, "engagementsid": value.engagementsid};
  const inputString = JSON.stringify(inputData);
  let data = {
    "procedureName": "getengfornotification",
    "inputParameters": inputString
  };
  this.spinner.show();
  this.apiService.getData(data).subscribe(response => {
    let data = JSON.parse(response);
    let obj = data[0];
    this.sharedService.notificationRedirectionSubject.next(obj);
    this.onNotificationRead(obj);
    this.redirectToTrialBal(obj)
    this.spinner.hide()
  }, error => {
    this.spinner.hide();
    this.toastr.error(error.error);
  });
}

redirectToTrialBal(engagement) {
  // this.closeModal("custom-modal-1");
  this.sharedService.engagementId = engagement.engagementsid;
  this.sharedService.engagementDetails = engagement;
  this.sharedService.headerObj.next(engagement);
  localStorage.setItem('incorporationDate', engagement.incorporationdate)
  localStorage.setItem('engagementID', engagement.engagementsid);
  localStorage.setItem('engagementClientID', engagement.clientfirmid);
  localStorage.setItem('selectedEngagementObj', JSON.stringify(engagement));
  localStorage.setItem('selectedEngagementObjCopy', JSON.stringify(engagement));
  this.apiService.getEngagementYearDetails(engagement.engagementsid).subscribe(response => {
      if (response) {
      this.EngagementYearDetails = response;

      if (this.EngagementYearDetails && this.EngagementYearDetails.length > 0) {
          this.trialBalYear = this.EngagementYearDetails[0].acctyear;
          localStorage.setItem('trailBalYear', this.trialBalYear);
      }
      }
  }, error => {
      this.toastr.error(error.error);
  })

  localStorage.setItem('endYear', engagement.yearEnd); //both are same
  setTimeout(() => {
    this.trackerService.timeIconCount().subscribe( count => {
      this.sharedService.updateTimerCount.next(count);
      this.sharedService.isTimeTrackerStart.next('startTimer');
    });
    this.router.navigate(['/dashboard/ntrdashboard/ClientOnboarding/ClientAcceptance']);
  }, 500);
}

ngOnDestroy(){
  if(this.permissionsSub){
    this.permissionsSub.unsubscribe();
  }
  if(this.alertSub){
    this.alertSub.unsubscribe();
  }
  this.subscriptions && this.subscriptions.unsubscribe();
}

}
