export class AiDocRepoFolderModel {
  isExpand: boolean = false;
  isChecked: boolean = false;
  parentFolderId: number = 0;
  parentFolderName: string = '';
  childFiles: AiDocRepoFileModel[] = null;
  fileCount: number = 0;
}

export class AiDocRepoFileModel {
  fileId: number = 0; //engteamclientdocid
  ext: string;
  extImgPath: string;
  fileName: string;
  filePath: string;
  isExpand: boolean = false;
  isChecked: boolean = false;
  parentFolderId: number = 0;
  nameWithExt: string;
  nameSummary: string;
  hasValidFile: boolean = false;
  searchFilePath?: string = '';
}
