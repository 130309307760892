import { FS_STATEMENTS_PREVIEWS, STATEMENT_VIEW_TYPES } from 'countable@helpers';

export class ExportModel {
    id: number;
    year: number;
}

export class ExportPDFModel {
  id: number;
  year: number;
  isChangeInDollar: boolean;
}

export class FSExportModel {
  engagementsid: number;
  acctyear: string;
  isPreview: boolean;
  isPageBreak: boolean;
  oneYear: boolean;
  separateIncome: boolean;
  tableOfContents: boolean;
  watermark: boolean;
  isWord: boolean;
  approvalText: string;
  documentName: string;
  yearEndDate: string;
  approvedBy: Array<any>;
  isApprovedByBoard: boolean;
  typeOfEntity: number;
}

export class StatementPreviewModel {
  engagementsid: number;
  acctyear: string;
  readonly isWord: boolean = false;
  typeOfEntity: number;
  documentName: FS_STATEMENTS_PREVIEWS;
  yearEndDate: string;
  isFromWorkbook: boolean = false;
  isLastYear: boolean = false;
}
