import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'textarea[autoExpand]'
})
export class AutoExpandDirective {

  constructor(private elementRef: ElementRef) {}

  @HostListener('input') onInput(): void {
    const textarea = this.elementRef.nativeElement;
    textarea.style.height = 'auto';  // Reset height to auto so it can shrink if needed
    textarea.style.height = `${textarea.scrollHeight}px`;  // Set height based on scrollHeight
  }
}
