import { FolderTypes } from 'src/app/helpers/stored-procedures.constant';

export class ReviewEngFoldersModel {
  engagementtype: "Compilation (COM)";
  engagementtypeid: 1;
  foldersData: FolderModel[]
}

export class FolderModel {
  submoduleid: number = 0;
  submodulename: string;
  newsubmodule_smufid: string;
  moduleid:number;
  sectionData:any;
  subTitile?: string;
}

export class FromIssuesModel {
  leftSideMenu: any;  //parentsubmoduleid
  selectType:any;
  submoduleid: number;  //left side folder id
  parentFolderTitleName: string;
  subFolderTitleName: string;
  originFolderUUID: string;
  folderType: 'parent-folder' | 'child-folder' = 'child-folder';
  from: FolderTypes
}

export class MoveDocumentInputModel {
  engleftsidedocid: number;
  submoduleid: number;
  finsubgroupid: number;
  readonly refpkid: 0 = 0;
  loginid: number;
  engteamclientdocid: number;
  engagementsid: number
}

export class MoveFolderModel {
  folderType: FolderTypes
  folderName: string;
  subModuleUUID: string;
  subModuleId: number;
  sectionData: MoveSectionsModel[];
}

export class MoveSectionsModel {
  subModuleUUID: string;
  subModuleId: number;
  sectionName: string;
  finsubgroupid: number | null= null;
}

export class SaveEngagementTemplate {
  engagementsid: number;
  submoduleid: number;
  loginid: number;
  JsonFileName: string;
  JsonFilePath: string;
}

export class SendPackagerEmailModel {
  notes: string;
  emails: string[]
  engagementName: string
  isReassignedPackage: boolean
}

export class ClientPortalStatusModel {
  clientFirmId: number;
  clientUserAccountId: number;
  emailAddress: string;
  invitationStatus: string;
}

export const SELECT_TYPES = [{'typeid': 1, 'typename': 'General'}, {'typeid': 2, 'typename': 'Line Item'}];

export const PRIORITY_LIST = [
  {
    'requestpriorityid': 1,
    'requestpriorityname': 'High'
  },
  {
    'requestpriorityid': 2,
    'requestpriorityname': 'Medium'
  },
  {
    'requestpriorityid': 3,
    'requestpriorityname': 'Low'
  }
];
