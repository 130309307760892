export enum MFAConstants {
     DISABLE_MESSAGE = 'Disable Two-Factor Authentication for firm users',
     ENABLE_MESSAGE = 'Enable Two-Factor Authentication for firm users',
     OTP_SUCCESS = 'otp verified successfully!',
     OTP_WRONG = 'wrong otp!',
     OTP_SENT ='An otp has been send to your email!',
     ENABLE_SUCCESS ='Two-Factor Authentication has been enabled successfully for the firm users',
     DISABLE_SUCCESS = 'Two-Factor Authentication has been Disabled successfully for the firm users',
     SECRET_NOT_GENERATED = "Secret hasn't Been Generated for this user!",
     EMAIL = "EMAIL",
     GOOGLE = "GOOGLE_AUTHENTICATOR",
     RESETMFA_TEAM_MESSAGE = "Reset Two-Factor Authentication for selected firm users",
     RESETMFA_SUCCESS_MESSAGE = "Two-Factor Authentication has been reset successfully for selected users",
     RESETMFA_MESSAGE = "Reset Two-Factor Authentication for personal account",
     RESET_SUCCESS = "Mfa has been reset for user successfully!",
     ROUTE_MY_ACCOUNT = "/dashboard/members/settings/myaccount",
     ROUTE_NOTIFICATIONS = "/dashboard/members/settings/notifications",
     ROUTE_BILLING = "/dashboard/members/settings/billing",
     ROUTE_MFA = "/dashboard/members/settings/privacy-and-security",
     RESEND_TIMER = "Resend code in",
     MFA_SUCCESS = "Two-Factor Authentication has been enabled successfully",
     MFA_RESET_SUCCESS= "Two-Factor Authentication has been reset successfully",
     WRONG_OTP = "You have entered wrong code, try again",
     DISABLE_MFA_MESSAGE = "Disable Two-Factor Authentication for personal account",
     ENABLE_MFA_MESSAGE = "Enable Two-Factor Authentication for personal account",
     DISABLE_PERSONAL_SUCCESS = "Two-Factor Authentication has been disabled successfully"
}
