import { PROMPT_MSG_TYPES } from 'countable@model';

export class AiLoadingModel {
  totalTime: number;
  totalSteps: number;
  intervalTime: number;
  steps: string[][];
  promptType: PROMPT_MSG_TYPES;
}

export const LOADING_INFO: AiLoadingModel[] = [
  {
    totalTime: 36000,
    totalSteps: 5,
    intervalTime: 6000,
    steps: [
      ['Gathering engagement data', 'Fetching financial details', 'Retrieving engagement records'],
      ['Calculating variances', 'Analyzing financial changes', 'Computing dollar & percentage shifts'],
      ['Processing general ledger entries', 'Consolidating GL transactions', 'Verifying GL data integrity'],
      ['Generating variance report', 'Compiling variance summary', 'Preparing variance analysis'],
      ['Reviewing changes in balances', 'Validating account movements', 'Confirming report accuracy']
    ],
    promptType: 'VARIANCE_ANALYSIS_INPUTS'
  },
  {
    totalTime: 36000,
    totalSteps: 3,
    intervalTime: 4000,
    steps: [
      ['Gathering AR data', 'Fetching AR records', 'Retrieving engagement details'],
      ['Reconciling with Trial Balance', 'Matching with financial ledgers', 'Verifying accounts accuracy'],
      ['Calculating AR totals', 'Summing receivables', 'Computing outstanding balances'],
      ['Compiling AR summary', 'Generating AR report', 'Preparing accounts report']
    ],
    promptType: 'AR'
  },
  {
    totalTime: 36000,
    totalSteps: 3,
    intervalTime: 7000,
    steps: [
      ['Fetching engagement details', 'Retrieving client info', 'Accessing ledger data'],
      ['Mapping account structure', 'Organizing financial data', 'Categorizing accounts'],
      ['Merging trial balance records', 'Cross-referencing entries', 'Integrating data points'],
      ['Analyzing ledger transactions', 'Reviewing transactions', 'Checking year-end balances'],
      ['Preparing final report', 'Generating insights', 'Compiling output data']
    ],
    promptType: 'GENERAL_LEDGER'
  },
  {
    totalTime: 9000,
    totalSteps: 3,
    intervalTime: 3000,
    steps: [
      ['Verifying asset details', 'Validating asset records', 'Checking asset data'],
      ['Categorizing CCA classes', 'Classifying assets', 'Organizing asset types'],
      ['Preparing formulas', 'Setting up calculations', 'Configuring asset values']
    ],
    promptType: 'CAAS_PREFERENCES'
  },
  {
    totalTime: 9000,
    totalSteps: 3,
    intervalTime: 3000,
    steps: [
      ['Structuring amortization schedule', 'Organizing depreciation entries', 'Mapping asset categories'],
      ['Applying amortization rates', 'Calculating depreciation values', 'Adjusting asset balances'],
      ['Compiling final report', 'Generating amortization summary', 'Preparing asset statement'],
      ['Reviewing adjustment entries', 'Validating calculations', 'Finalizing balance adjustments']
    ],
    promptType: 'CAAS_SCHEDULE'
  },
  {
    totalTime: 36000,
    totalSteps: 3,
    intervalTime: 5000,
    steps: [
      ['Uploading your files', 'Receiving documents', 'Processing uploads'],
      ['Extracting content', 'Reading file data', 'Decoding information'],
      ['Analyzing document details', 'Scanning for key insights', 'Reviewing file summary'],
      ['Generating report', 'Compiling findings', 'Creating summary output']
    ],
    promptType: 'UF'
  },
  {
    totalTime: 36000,
    totalSteps: 3,
    intervalTime: 5000,
    steps: [
      ['Uploading your files', 'Receiving documents', 'Processing uploads'],
      ['Extracting content', 'Reading file data', 'Decoding information'],
      ['Analyzing document details', 'Scanning for key insights', 'Reviewing file summary'],
      ['Generating report', 'Compiling findings', 'Creating summary output']
    ],
    promptType: 'DFA'
  },
  {
    totalTime: 36000,
    totalSteps: 3,
    intervalTime: 7000,
    steps: [
      ['Reviewing uploaded files', 'Scanning documents', 'Inspecting file contents'],
      ['Validating file format', 'Checking file compatibility', 'Ensuring document integrity'],
      ['Extracting investment data', 'Parsing financial details', 'Decoding investment info'],
      ['Verifying investment periods', 'Checking timeline accuracy', 'Analyzing monthly coverage'],
      ['Compiling investment summary', 'Aggregating details', 'Creating investment snapshot'],
      ['Generating final report', 'Preparing schedule output', 'Finalizing investment view']
    ],
    promptType: 'IS'
  },
  {
    totalTime: 36000,
    totalSteps: 3,
    intervalTime: 4000,
    steps: [
      ['Interpreting your query', 'Analyzing request', 'Understanding your input'],
      ['Gathering information', 'Collecting relevant data', 'Searching knowledge base'],
      ['Crafting response', 'Generating insights', 'Formulating solution'],
      ['Validating details', 'Checking accuracy', 'Reviewing response'],
      ['Preparing output', 'Finalizing answer', 'Compiling results'],
      ['Adding a personal touch', 'Tailoring response', 'Refining details'],
      ['Ensuring clarity', 'Simplifying explanation', 'Clarifying insights'],
      ['Wrapping up', 'Summarizing information', 'Polishing response']
    ],
    promptType: 'FR'
  }
];
