import { Injectable } from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js';
@Injectable({
  providedIn: 'root'
})
export class AllAdjustmententryService {
data=[
  {
    "isdelete": 0,
    "acctdebit": 10,
    "acctcredit": 0,
    "accountcode": "",
    "accountname": "Accounting",
    "leadsheetcode": null,
    "trailbalanceid": 45105,
    "trailadjustmentid": 366
  },
  {
    "isdelete": 0,
    "acctdebit": 0,
    "acctcredit": 2,
    "accountcode": "",
    "accountname": "Test",
    "leadsheetcode": null,
    "trailbalanceid": 45106,
    "trailadjustmentid": 367
  }
]
  sName: string;
  excelFileName: string = '';
  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  cols = ['Acc.No', 'Description', 'Type', 'Debit', 'Credit'];
  reportTotalData: any = [];
  constructor() {

   }
  exportToExcel(data, engagementDetails, year) {
    this.excelFileName = 'All Adjustment Entries';
    const workbook = new Excel.Workbook();
    workbook.creator = 'Web';
    workbook.lastModifiedBy = 'Web';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', ySplit: 6, activeCell: 'A1', showGridLines: true }] });
    const sheet = workbook.getWorksheet(1);

    sheet.mergeCells('C1', 'J2');
    sheet.getCell('C1').value = engagementDetails.engagementName ? 'Engagement Name: ' + engagementDetails.engagementName : '';
    sheet.getCell('C1').alignment = { horizontal: 'center' };
    sheet.getCell('C1').font = { name: 'Calibri', family: 4, size: 12, bold: true }

    sheet.mergeCells('C3', 'J3');
    sheet.getCell('C3').value = year ? 'As of ' + year : '';
    sheet.getCell('C3').alignment = { horizontal: 'center' };

    sheet.mergeCells('C4', 'J4');
    sheet.getCell('C4').value = 'Trial Balance';
    sheet.getCell('C4').alignment = { horizontal: 'center' };
    sheet.getCell('C4').font = { name: 'Calibri', family: 4, size: 12, bold: true }

    sheet.addRow('');
    sheet.getRow(6).values = this.cols;
    sheet.columns = [
      { key: 'accountcode' },
      { key: 'accountname' },
      { key: 'adjustmenttypename' },
      { key: 'acctdebit' },
      { key: 'acctcredit' }
    ];
    sheet.addRows(data);
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = this.excelFileName;
      a.click();
    });
  }
}
