import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.env === 'prod') {
  console.log = function (): void { };
  console.trace = function (): void { };
  console.debug = function (): void { };
  console.info = function (): void { };
  console.warn = function (): void { };
}

platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
  // Ensure Angular destroys itself on hot reloads.
  if (window['ngRef']) {
    window['ngRef'].destroy();
  }
  window['ngRef'] = ref;
  // Otherwise, log the boot error
}).catch(err => console.error(err));
