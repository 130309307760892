export enum AdditionalInfoTax {
  T2 = 'T2 returns are filed by Canadian Corporations to report their income, taxes owed, and other relevant financial information to the Canada Revenue Agency (CRA).',
  C1120 = 'Form 1120 is used to complete the U.S. corporation income tax return. It is used to report their credits, deductions, losses, gains and income.',
  S1120 = 'Form 1120S is used to report the income, gains, losses, deductions, credits, etc., of a Domestic corporation or other entity for any tax year covered by an election to be an S corporation.',
  U1065 = 'Form 1065 is used by Partnerships to  file an information return to report their income, gains, losses, deductions, credits, etc. A partnership does not pay tax on its income but "passes through" any profits or losses to its partners. Partners must include partnership items on their tax or information returns.'
}

export enum EngagementType {
  Compilation = 'compilation',
  Review = 'review',
  T2 = 'T2',
  C1120= '1120',
  S1120 = '1120S'
}

export enum CountryCodes {
  Canada = 38,
  US = 226
}

export enum AdditionalInfoHeadingsTax {
  T2 = 'T2',
  C1120 = '1120',
  S1120 = '1120S',
  U1065 = '1065'
}

export const ADDITIONAL_INFO_MAP = {
  34: {
    info1: 'T2 returns are filed by Canadian Corporations to report their income, taxes owed, and other relevant financial information to the Canada Revenue Agency (CRA).',
    heading: 'T2'
  },
  35: {
    info1: 'Form 1120 is used to complete the U.S. corporation income tax return.',
    info2: 'It is used to report their credits, deductions, losses, gains and income.',
    heading: '1120'
  },
  36: {
    info1: 'Form 1120S is used to report the income, gains, losses, deductions, credits, etc., of a Domestic corporation or other entity for any tax year covered by an election to be an S corporation.',
    heading: '1120S'
  },
  37: {
    info1: 'Form 1065 is used by Partnerships to file an information return to report their income, gains, losses, deductions, credits, etc.' ,
    info2: 'A partnership does not pay tax on its income but "passes through" any profits or losses to its partners.',
    info3: 'Partners must include partnership items on their tax or information returns.',
    heading: '1065'
  }
};

export const FORBIDDEN_DND_URLS = [
  'CompletionSignOff/Significance-Adjusted-Errors'
];

export enum BlobType  {
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
};
