import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, Observer} from 'rxjs';
import {SharedService} from './components/shared/shared.service';
import {UnsavedChangesDialogComponent} from './components/dialogs/unsavedChangesDialog/unsavedChangesDialog.component';
import {MatDialog} from '@angular/material/dialog';


export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class DeactivateGuardService  {
  constructor(public sharedService: SharedService, public dialog: MatDialog) {
  }

  canDeactivate(component: CanComponentDeactivate, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Allow navigation if the component says that it is OK or it doesn't have a canDeactivate function
    if (!component?.canDeactivate || component?.canDeactivate()) {
      return true;
    }

    return Observable.create((observer: Observer<boolean>) => {
      let dialogRef = this.dialog.open(UnsavedChangesDialogComponent, { disableClose: true });

      dialogRef.afterClosed().subscribe(result => {
          observer.next(result);
          observer.complete();
        }, (error) => {
          observer.next(false);
          observer.complete();
        }
      );
    });
  }
}
