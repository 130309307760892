import { HttpClient } from '@angular/common/http';
import { Injectable, signal, WritableSignal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserDetailModel, UserModel } from 'countable@model';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GenericResModel } from '../model/generic-res.model';
import { LoginUserModel } from '../model/login-user.model';
import { SetPasswordReqModel } from '../model/pasword/set-password-req.model';
import { SetPasswordResModel } from '../model/pasword/set-password-res.model';
import { TokenValidationResModel } from '../model/pasword/token-validation-res.model';
import { SignupReqModel } from '../model/register/signup-req.model';
import { SignupSsoInfoModel } from '../model/register/signup-sso-info.model';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public static readonly FIRM_SELECTED_HASH = 'firm-hash';
  private static readonly URI_AUTH = environment.api + '/auth';
  private static readonly TOKEN_KEY = 'auth-token'; // we should make this private as soon as StorageService gets ready
  private static readonly USER_KEY = 'auth-user';
  private static readonly USER_DETAIL_KEY = 'userDetails';
  public readonly subject = new BehaviorSubject<boolean>(false);

  private jwtHelper = new JwtHelperService();
  private userDetail: UserDetailModel = null;
  private user: UserModel = null;
  private loginUser: LoginUserModel = null; // old way, we should remove its usages and replace with the `getUser()`
  private token: string = null;
  public showLuca: WritableSignal<boolean> = signal<boolean>(false);

  constructor(private http: HttpClient, private dialog: MatDialog, private router: Router, private cookie: CookieService) {
    if (localStorage.getItem(AuthService.USER_KEY) && localStorage.getItem(AuthService.USER_KEY) !== 'undefined') {
      this.user = JSON.parse(localStorage.getItem(AuthService.USER_KEY));
    }
    if (localStorage.getItem(AuthService.USER_DETAIL_KEY) && localStorage.getItem(AuthService.USER_DETAIL_KEY) !== 'undefined') {
      this.userDetail = JSON.parse(localStorage.getItem(AuthService.USER_DETAIL_KEY));
    }
    if (localStorage.getItem(AuthService.TOKEN_KEY) && localStorage.getItem(AuthService.TOKEN_KEY) !== 'undefined') {
      this.token = localStorage.getItem(AuthService.TOKEN_KEY);
      if (this.isAuthenticated()) {
        this.subject.next(true);
      }
    }
  }

  isAuthenticated(): boolean {
    return this.token && !this.jwtHelper.isTokenExpired(this.token);
  }

  setUser(user: UserModel): void {
    this.user = user;
    this.token = this.user.token;
    localStorage.setItem(AuthService.FIRM_SELECTED_HASH, user.firm);
    localStorage.setItem(AuthService.TOKEN_KEY, this.token);
    localStorage.setItem(AuthService.USER_KEY, JSON.stringify(user));
    localStorage.setItem('isUserLoggedIn', 'true');
  }

  getUser(): UserModel | null {
    return this.user;
  }

  getToken(): string | null {
    return this.token;
  }

  setUserDetail(data: UserDetailModel): void {
    localStorage.setItem(AuthService.USER_DETAIL_KEY, JSON.stringify(data));
    localStorage.setItem('istimetrackingon', JSON.stringify(1));
    this.userDetail = data;
    this.subject.next(true);
  }

  getUserDetail(): UserDetailModel | null {
    return this.userDetail;
  }

  getLoginUser(): LoginUserModel { // old approach, do not use it, current usages should be refactored
    this.loginUser = JSON.parse((localStorage.getItem(AuthService.USER_DETAIL_KEY)));
    if (this.loginUser == null || this.loginUser.useracctid == null) {
      this.logout();
    }
    this.loginUser.token = this.token;
    return this.loginUser;
  }

  logout(softLogout: boolean = false) {
    this.dialog.closeAll();
    localStorage.clear();
    sessionStorage.clear();
    this.loginUser = null;
    this.user = null;
    this.userDetail = null;
    StorageService.reset();
    this.subject.next(false);
    if (!softLogout) {
      this.cookie.delete('email', '/login');
      this.cookie.delete('pass', '/login');
      this.router.navigate(['/login']).then();
    }
  }

  login(data: { password: string; captcha: string; username: string }): Observable<GenericResModel<UserModel>> {
    return this.http.post<GenericResModel<UserModel>>(AuthService.URI_AUTH + '/login', data);
  }

  loginWithGoogle(data: { token: string, captcha: string }): Observable<GenericResModel<UserModel>> {
    return this.http.post<GenericResModel<UserModel>>(AuthService.URI_AUTH + '/login-google', data);
  }

  loginWithSso(code: string, captcha: string): Observable<GenericResModel<UserModel>> {
    return this.http.post<GenericResModel<UserModel>>(AuthService.URI_AUTH + '/login-oauth', captcha, { headers: { 'X-CODE': code } });
  }

  public prepareForRefreshAfterSwitchFirm(user: UserModel): void {
    localStorage.clear();
    sessionStorage.clear();
    StorageService.reset();
    this.setUser(user);
  }

  public refreshUserProfile(): Observable<void> {
    return this.fetchUserProfile().pipe(map(res => {
      if (Array.isArray(res) && res.length > 0) {
        this.setUserDetail(res[0]);
      }
    }));
  }

  public fetchUserProfile(): Observable<any> {
    return this.http.get(environment.apiV1 + '/user/profile', {
      responseType: 'json',
      headers: { Authorization: `Bearer ${this.user.token}` }
    });
  }

  public signup(data: SignupReqModel): Observable<any> {
    return this.http.post<any>(AuthService.URI_AUTH + '/signup', data);
  }

  public prepareSignupWithSso(code: string, captcha: string) {
    return this.http.post<GenericResModel<SignupSsoInfoModel>>(AuthService.URI_AUTH + '/signup-prepare-sso', captcha, { headers: { 'X-CODE': code } });
  }

  public prepareSignupWithGoogle(params: { token: string, captcha: string }) {
    return this.http.post<GenericResModel<SignupSsoInfoModel>>(AuthService.URI_AUTH + '/signup-prepare-sso-google', params);
  }

  public forgotPassword(email: string, token: string): Observable<GenericResModel<boolean>> {
    return this.http.post<GenericResModel<boolean>>(AuthService.URI_AUTH + '/forgot-password', {
      email: email,
      captcha: token
    }, { headers: { responseType: 'json' } });
  }

  public validateActivationToken(token: string): Observable<TokenValidationResModel> {
    return this.http.post<TokenValidationResModel>(AuthService.URI_AUTH + '/token-validation', token);
  }

  public setPassword(req: SetPasswordReqModel): Observable<SetPasswordResModel> {
    return this.http.post<SetPasswordResModel>(AuthService.URI_AUTH + '/set-password', req);
  }

  changePassword(req: { newPassword: string; captcha: string; oldPassword: string }) {
    return this.http.post<SetPasswordResModel>(environment.apiV1 + '/user/change-password', req);
  }
}
