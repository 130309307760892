import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {Subscription} from 'rxjs';
import {ApiService} from '../../../../../services/api.service';
import {SharedService} from '../../../../shared/shared.service';

@Component({
    selector: 'app-partner-capital-preview',
    templateUrl: './partner-capital-preview.component.html',
    styleUrls: ['./partner-capital-preview.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [DatePipe]
})
export class PartnerCapitalPreviewComponent implements OnInit {

    @Input() isEnabledHorizantal: boolean;
    @Input() isEnabledVertical: boolean;
    responseData: any = [];
    isRowAdded: boolean = false;
    verticalData: any = [];
    viewType: number = 0;
    engagementId: number;
    selectedEngagementObj: any;
    yearEnd: number;
    acctYear: number;
    userDetails: any;
    userLoginId: number;
    partCapitalHorizontalDetails: any;
    private previewInitialSub: Subscription;
    private previewRefreshSub: Subscription;

    constructor(
        private apiService  : ApiService,
        public dialog       : MatDialog,
        private datePipe    : DatePipe,
        private toaster     : ToastrService,
        private spinner     : NgxSpinnerService,
        private shareService: SharedService
    ) {}

    ngOnInit() {
      this.selectedEngagementObj = JSON.parse((localStorage.getItem('selectedEngagementObj')));
      let year = this.datePipe.transform(this.selectedEngagementObj.yearEnd, 'y');
      this.yearEnd = +year;
      this.engagementId = this.selectedEngagementObj.engagementsid;
      this.acctYear = +year;
      //get the user details
      this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      this.userLoginId = this.userDetails.useracctid;
      this.getPartnerCapitalVerticalStatement(1);
      this.getPartnerCapitalStatement(0);
      this.previewInitialSub = this.shareService.isEnableSettings.subscribe( res => {
        if(res.statementViewType == 0){
          this.getPartnerCapitalStatement(0);
        }
        else{
          this.getPartnerCapitalVerticalStatement(1);
        }
      });
      this.previewRefreshSub = this.shareService.refreshPreview.subscribe(response => {
        if(response == undefined){
          //this.getPartnerCapitalVerticalStatement(1);
          //this.getPartnerCapitalStatement(0);
        }
        else if(response == 0){
          this.getPartnerCapitalStatement(0);
        }
        else{
          this.getPartnerCapitalVerticalStatement(1);
        }
      });
    }
    /* refreshComponent(){
      this.getPartnerCapitalVerticalStatement(1);
      this.getPartnerCapitalStatement(0);
    } */
    /* ngOnChanges(changes: SimpleChanges): void{

      if(changes.isEnabledHorizantal){
        if(changes.isEnabledHorizantal.currentValue)
          this.getPartnerCapitalVerticalStatement(1);
      }
      if(changes.isEnabledVertical){
        if(changes.isEnabledVertical.currentValue)
          this.getPartnerCapitalStatement(0);
      }
      this.refreshComponent();
    } */
    getPartnerCapitalVerticalStatement(type: number){
      const inputData = {
        "engagementid": this.engagementId,
        //"acctyear": this.acctYear,
        "datatype": type
      };
      const inputString = JSON.stringify(inputData);
      let data = {
          "procedureName": "getstatementofpartnerscapital",
          "inputParameters": inputString
      };
      //this.spinner.show();
      this.apiService.getData(data).subscribe(response => {
          //this.spinner.hide();
          const responseData = JSON.parse(response);
          if (responseData && responseData.length > 0) {
              this.verticalData = responseData;
          }
          else {
              this.verticalData = [];
          }


      }, error => {
          //this.spinner.hide();
      });
    }
    getPartnerCapitalStatement(type: number) {
      const inputData = {
        "engagementid": this.engagementId,
        "datatype": type
      };
      const inputString = JSON.stringify(inputData);
      let data = {
          "procedureName": "getstatementofpartnerscapital",
          "inputParameters": inputString
      };
      //this.spinner.show();
      this.apiService.getData(data).subscribe(response => {
          const responseData = JSON.parse(response);
          if (responseData && responseData.length > 0) {
              this.partCapitalHorizontalDetails = responseData;

          }
          else {
              //this.toaster.warning("No data available for your enagegement")
          }
      }, error => {
          //this.spinner.hide();
          //this.toaster.warning("failed to load partner capital details")
      });
    }
    ngOnDestroy() {
      if(this.previewRefreshSub){
        this.previewRefreshSub.unsubscribe();
      }
      if(this.previewInitialSub){
        this.previewRefreshSub.unsubscribe();
      }
    }
}
