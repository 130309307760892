import { DatePipe } from '@angular/common';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentPreviewTypes, STATEMENT_VIEW_TYPES } from 'countable@helpers';
import { FsExportService } from 'countable@services';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SharedService } from 'src/app/components/shared/shared.service';
import { modules } from 'src/app/services/app-config';
import { ApiService } from '../../../../../services/api.service';

@Component({
	selector: 'cashflow-preview',
	templateUrl: 'cashflow-preview.component.html',
	styleUrls: ['./cashflow-preview.component.scss']
})

export class CashflowPreviewComponent implements OnInit {

	cashflowDetails: any = [];
	isPrevyear: boolean = false;
	currentYear: any;
	userData: any;
	engagementData: any;
	selectedEngagementDetails: any;
	previousYear: any;
	userLoginId: any;
	clientName: string;
	accYearEnd: number;
	selectedYearTitle: string;
	isSearchExist = true;
	searchText: string;
	shortCutMenuDetails: any;
	notes: any = [];
	todayYear: any;
	footerNotes = '';
	isIncomePrevYear = false;
	headerNotesColumn1: string;
	headerNotesColumn2: string;
	headerNotesColumn3: string;
	@Input() selectedYearEnd: any;
	private reFreshSubscription: Subscription;
	private headerFooterSubscription: Subscription;
	@Input() isRefreshEvents: Observable<any>;
	@Input() headerFooterData: Observable<any>;
	submoduleId: any;
	showFooter = false;
	firmUserAcctId: any;
	cashFlowData: any;
	approvalText: any;
	isWorkBook: boolean;
	public placeOfPreview: STATEMENT_VIEW_TYPES = "None";
	public subscriptions: Subscription = new Subscription();
	public cashFlow$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	// footer text
	footerText: string = '';
	isHideNotesToFS = false;

	@HostListener('window:storage', ['$event'])
	onStorageChange(ev: StorageEvent) {
		if ((ev.key === 'refreshCashFlow_CF') && (ev.newValue === 'refresh')) {
			this.spinner.show();
			this.getCashFlowPreview();
		}
		if (ev.key == 'isfooterUpdated') {
			const note = localStorage.getItem('isfooterUpdated');
			this.updateFooterText(note);
		}
	}
	constructor(
		private apiService: ApiService,
		private fsService: FsExportService,
		private toastr: ToastrService,
		private spinner: NgxSpinnerService,
		public datePipe: DatePipe,
		private sharedservice: SharedService,
		private router: Router

	) {
		this.placeOfPreview = this.sharedservice.setPlaceofPreview(this.router.url);
	}

	ngOnInit() {
		localStorage.removeItem('workbookChangeDate');
		localStorage.removeItem('workbookChangeYearEnd');
		localStorage.removeItem('isLastYear');
		this.todayYear = new Date().getFullYear();
		this.userData = JSON.parse(localStorage.getItem("userDetails"));
		this.engagementData = JSON.parse(localStorage.getItem("selectedEngagementObj"));
		this.selectedEngagementDetails = JSON.parse((localStorage.getItem('selectedEngagementObjCopy')));
		this.shortCutMenuDetails = JSON.parse(localStorage.getItem('shortCutMenuDetails'));
		this.accYearEnd = parseInt(this.datePipe.transform(this.engagementData.yearEnd, 'y'));
		this.clientName = this.selectedEngagementDetails.clientName;
		this.firmUserAcctId = this.sharedservice.getUserData("field", "firmUserAcctId");
		this.isWorkBook = localStorage.getItem('isWorkBook') == "true" ? true : false;

		if (this.router.url == "/dashboard/ntrdashboard/FinancialStatement/doc/StatementCashFlows") {
			this.submoduleId = JSON.parse(localStorage.getItem('FsSubmoduleId'));
		} else {
			this.submoduleId = JSON.parse(localStorage.getItem('workBookSubmoduleId'));
		}

		this.subscriptions.add(
			this.cashFlow$.pipe().subscribe({
				next: cashFlowData => {
					if (cashFlowData) {
						document.getElementById("cashflow-preview").innerHTML = cashFlowData;

						// Setting watermark
						const waterMark = this.sharedservice.showdraft.value;
						const waterMarkUrl = this.sharedservice.waterMarkUrl.getValue();
						if ((waterMark && waterMark.isShowDraft) && waterMarkUrl) {
							document.getElementById("cashflow-preview").style.backgroundImage = `url(${waterMarkUrl})`;
						} else {
							document.getElementById("cashflow-preview").style.backgroundImage = "none";
						}

						this.getHeaderTitles();

						setTimeout(() => {
							const eleHeight = document.getElementById('cashflow-preview') as HTMLElement;
							if (eleHeight.clientHeight < 759) {
								eleHeight.style.height = '760px';
							}
						}, 10);

						this.spinner.hide();
					}
				}
			})
		);

		this.userLoginId = this.userData['useracctid'];
		// this.getFSNotes();
		// this.getwbleftside();

		if (this.isRefreshEvents) {
			this.reFreshSubscription = this.sharedservice.cashFlowRefresh.subscribe((value) => {
				const userData = JSON.parse(JSON.stringify(value));
				this.cashflowDetails = userData.data;
				this.cashflowDetails.splice(this.cashflowDetails.length - 1, 0, { id: 'crossCheck', crossCheckValueCY: userData.crossCheck.crossCheckValueCY, crossCheckValuePY: userData.crossCheck.crossCheckValuePY });
				this.formattingNotesList();
				this.checkingIsNotesFsExists();
				setTimeout(() => {
					this.spinner.show();
					this.getCashFlowPreview();

				}, 3000);
			});
		}

		if (this.headerFooterData) {
			this.headerFooterSubscription = this.headerFooterData.subscribe((results) => {
				if (results === 'footer') {
					const note = localStorage.getItem('isfooterUpdated');
					this.updateFooterText(note);
				} else {
					this.headerNotesColumn1 = results[0].header[0].changednameline1;
					this.headerNotesColumn2 = results[0].header[0].changednameline2 + ' ' + (this.datePipe.transform(this.engagementData.yearEnd, 'longDate'));
					this.headerNotesColumn3 = results[0].header[0].changednameline3;
					this.footerNotes = results[1].note ? results[1].note[0].note : "";
				}

			});
		}

		if (!(this.isRefreshEvents && this.headerFooterData)) {
			//this.getwbleftside();
			this.spinner.show();
			this.getCashFlowPreview();
		}


		// this.getCashFlowPreview();

		this.subscriptions.add(
			this.sharedservice.updateFinStatement$.subscribe({
				next: refreshStatement => {
					if (refreshStatement && refreshStatement.has(DocumentPreviewTypes.CASH_FLOW) && refreshStatement.get(DocumentPreviewTypes.CASH_FLOW)) {
						this.fsService.removeFsDocPreviewPage(DocumentPreviewTypes.CASH_FLOW);
						this.spinner.show();
						this.getCashFlowPreview();
					}

				}
			})
		)



	}

	getCashFlowPreview() {
		this.subscriptions.add(
			this.fsService.fetchFSDocPreview(DocumentPreviewTypes.CASH_FLOW, this.placeOfPreview).subscribe({
				next: res => {
					if (res) {
						let cashFlowJSON = JSON.parse(res);
						cashFlowJSON = cashFlowJSON.cashFlow;
						this.cashFlow$.next(cashFlowJSON["cashFlowPreview"]);
						this.spinner.hide();
						// this.getHeaderTitles();
					}
				}
			})
		);
	}

	// get cash-flow data
	getcashflowData() {
		const payload = {
			subModuleId: this.submoduleId + '',
			engagementsId: this.engagementData.engagementsid + "",
			curYear: this.datePipe.transform(this.engagementData.yearEnd, 'y') + "",
		}
		this.apiService.getCashflowData(payload).subscribe((res: any) => {
			if (res) {
				this.cashflowDetails = JSON.parse(res).sections;
				this.cashflowDetails.forEach((section, i) => {
					if (section.cashFlowName === 'NC' && section?.rows && section.rows.length) {
						let manualRows = [];
						let tbRows = [];
						section.rows.forEach(c => {
							if (c.trialBalanceId === 0) {
								manualRows.push(c)
							} else if (!c.isManual) {
								tbRows.push(c)
							}
						});
						const arrayUniqueByKey = [...new Map(tbRows.map(item =>
							[item['trialBalanceId'], item])).values()];

						this.cashflowDetails[i].rows = manualRows.concat(arrayUniqueByKey);

					}
				})
				this.cashflowDetails.splice(this.cashflowDetails.length - 1, 0, { id: 'crossCheck', crossCheckValueCY: JSON.parse(res).crossCheckValueCY, crossCheckValuePY: JSON.parse(res).crossCheckValuePY });
				this.formattingNotesList();
				this.checkingIsNotesFsExists()
			}
			this.spinner.hide();
		}, error => {
			this.spinner.hide();
			this.toastr.warning(error.error)
		})
	}

	// get FS settings data to know which year data to be shown
	getFsSettings() {
		let typeOfEntityId = this.engagementData.typeofentityid ? this.engagementData.typeofentityid : this.engagementData.typeOfEntityId;
    this.apiService.getFsSettings(this.engagementData.engagementsid, typeOfEntityId).subscribe(response => {
			if (response) {
				const fsSettingsData = JSON.parse(response);
				fsSettingsData.map(e => {
					if (e.settings != null) {
						e.settings.map(setting => {
							if (setting.engFinSettingId === 7) {
								setting.options.map(fsYear => {
									if (fsYear.userSelected) {
										this.isPrevyear = fsYear.name == "1 Year" ? false : true;
										//this.isOneYearDataAvailable == true ? localStorage.setItem('isOneYearDataAvailable', "true") : localStorage.setItem('isOneYearDataAvailable', "false");
									}
								})
							}
						})
					}
				});

			}
		}, error => {
			this.toastr.warning(error.error)
		})

	}

	ngOnDestroy() {
		if (this.reFreshSubscription) {
			this.reFreshSubscription.unsubscribe();
		}
		if (this.headerFooterSubscription) {
			this.headerFooterSubscription.unsubscribe();
		}

		localStorage.removeItem('workbookChangeDate');
		localStorage.removeItem('workbookChangeYearEnd');
		localStorage.removeItem('isLastYear');
		this.subscriptions && this.subscriptions.unsubscribe();

	}


	checkingIsNotesFsExists() {
		this.isHideNotesToFS = false;
		if (this.cashflowDetails.length) {
			this.cashflowDetails.forEach(e => {
				if (e?.rows && e.rows.length) {
					e.rows.forEach(g => {
						if (g?.notes && g.notes.length) this.isHideNotesToFS = true;
					});
				}
				if (e?.subGroupChildren && e.subGroupChildren.length) {
					e.subGroupChildren.forEach(f => {
						if (f?.rows && f.rows.length) {
							f.rows.forEach(g => {
								if (g?.notes && g.notes.length) this.isHideNotesToFS = true;
							});
						}
					});
				}
			});
		}
	}

	getwbleftside() {
		let inputData = { 'engagementsid': this.engagementData.engagementsid };
		let inputString = JSON.stringify(inputData);

		let data = {
			'procedureName': 'getwbleftside',
			'inputParameters': inputString
		};


		this.apiService.getData(data).subscribe(response => {
			if (response) {
				JSON.parse(response).forEach(e => {
					if (e.uirouting === "cashflow") {
						this.submoduleId = e.submoduleid;
						this.getHeaderTitles();
						// this.getcashflowData();
					}
				});
			}
		}, error => {
			this.toastr.error(error.error);
		});
	}


	getHeaderTitles() {
		const inputObj = {
			"engagementsid": this.engagementData.engagementsid,
			"submoduleid": this.submoduleId
		}
		const stringifyObj = JSON.stringify(inputObj);
		const finalInputObj = {
			"inputParameters": stringifyObj,
			"procedureName": "getwbheadernote"
		}
		this.apiService.getData(finalInputObj).subscribe(response => {
			if (response) {
				let results = JSON.parse(response);
				this.headerNotesColumn1 = results[0].header[0].changednameline1;
				this.headerNotesColumn2 = results[0].header[0].changednameline2 + ' ' + (this.datePipe.transform(this.engagementData.yearEnd, 'longDate'));
				this.headerNotesColumn3 = results[0].header[0].changednameline3;
				this.footerNotes = results[1].note ? results[1].note[0].note : "";
				this.getFSNotes();
			}
		}, error => {
			this.toastr.error(error.error);
		});
	}


	formattingNotesList() {
		this.cashflowDetails.forEach(e => {
			if (e?.subGroupChildren && e.subGroupChildren.length) {
				e.subGroupChildren.forEach(s => {
					s.notes = [];
					if (s?.rows && s.rows.length) {
						s.rows.forEach(d => {
							if (d?.notes && d.notes.length) {
								let notesList = s.notes.concat(d.notes);
								const key = 'engNoteId';
								const arrayUniqueByKey = [...new Map(notesList.map(item =>
									[item[key], item])).values()];
								s.notes = arrayUniqueByKey;
							}
						});
					}
				});
			}
		});
	}

	getFSNotes() {
		let jsonFileName = this.engagementData.engagementsid + '_notes.json';
		const payloadObj = {
			"module": modules[16],
			"firmId": this.firmUserAcctId,
			"clientId": this.selectedEngagementDetails.clientfirmid,
			"engagementId": this.selectedEngagementDetails.engagementsid,
			"status": 0,
			"folderName": jsonFileName,
			"appSyncId": this.userLoginId,
			"extension": 'comp',
		}

		// this.spinner.show();
		this.apiService.getJsonData(payloadObj).subscribe((response: any) => {
			if (response) {
				const notes = !response ? [] : JSON.parse(response);
				this.isHideNotesToFS = notes.length ? true : false;

				this.footerText = this.isHideNotesToFS ? "<div class='my-custom-footer' style = 'position: absolute; bottom: 0px;left: 0; left:0; margin-top: 35px; padding-bottom: 17px; width: 100%; text-align: center;'> <p style = 'font-family: Arial,sans-serif !important; font-size: 12px;'>" + this.footerNotes + "</p></div>" : "";
				let preview = document.getElementById("cashflow-preview").innerHTML;
				document.getElementById("cashflow-preview").innerHTML = preview + this.footerText;
			}
			// this.spinner.hide();
		}, (error) => {
			// this.spinner.hide();
			this.showFooter = false;
		});
	}

	updateFooterText(newFooterNote: string) {
		let preview = document.getElementById("cashflow-preview");
		if (preview) {
			let footerElements = preview.getElementsByClassName("my-custom-footer");
			for (let i = 0; i < footerElements.length; i++) {
				footerElements[i].remove();
			}
			let newFooterText = "<div class='my-custom-footer' style = 'position: absolute; bottom: 0px; left:0; margin-top: 35px; padding-bottom: 17px; width: 100%; text-align: center;'> <p style = 'font-family: Arial,sans-serif !important; font-size: 12px;'>" + newFooterNote + "</p></div>";
			preview.innerHTML += newFooterText;
		}
	}

}

