import { Component, Optional } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
// import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'; 

@Component({
	selector: 'confirmation-dialog',
	templateUrl: 'confirmation-dialog.component.html',
	styleUrls: ['./confirmation-dialog.component.scss']
})

export class ConfirmationDialog{
	constructor(@Optional() public dialogRef: MatDialogRef<ConfirmationDialog>) {}

	public confirmMessage:string;
	public dialogboxHeader: string;
	public dialogboxText: string;
	public dialogboxAdditionalText: string;

	public confirmAction: string = "Delete";
	public cancelAction: string = "Cancel";
}