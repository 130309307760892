import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()

export class SpinnerService {

  constructor(private spinnerModule: NgxSpinnerService) {}

  showSpinner() {
    this.spinnerModule.show().then()
  }

  hideSpinner() {
    this.spinnerModule.hide().then()
  }
}
