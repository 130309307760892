import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { SharedService } from '../components/shared/shared.service';
import { ApiService } from './api.service';

@Injectable()
export class AdobeEmbedPdfService {

  private annotationManager: any;
  private userDetails: any;
  private fileData: any;
  private adobeDCView: any;

  constructor(private sharedService: SharedService,
              private apiService: ApiService,
              private toaster: ToastrService,
              private spinner: NgxSpinnerService) {
    this.userDetails = JSON.parse((localStorage.getItem('userDetails')));
  }

  private static getFileName(fileData) {
    if (fileData.englettername) {
      return fileData.englettername;
    }

    if (fileData.fileNameWithoutExt) {
      return fileData.fileNameWithoutExt;
    }

    if (fileData.withoutextension) {
      return fileData.withoutextension;
    }

    return fileData.filename;
  }

  ready() {
    return new Promise<void>((resolve) => {
      if (window.AdobeDC) {
        resolve();
      } else {
        document.addEventListener('adobe_dc_view_sdk.ready', () => {
          resolve();
        });
      }
    });
  }

  previewFile(divId: string, viewerConfig: any) {
    const config: any = {
      clientId: environment.ADOBE_EMBED_PDF_KEY,
      divId
    };

    this.adobeDCView = new window.AdobeDC.View(config);
    this.sharedService.getS3url(this.userDetails.s3BucketName, this.fileData.filepath).subscribe(res => {
      return this.adobeDCView.previewFile({
          content: {
            location: {
              url: res
            }
          },
          metaData: {
            fileName: AdobeEmbedPdfService.getFileName(this.fileData),
            id: this.fileData.mssdkfileid
          }
        },
        viewerConfig
      );
    });
  }

  registerSaveApiHandler() {
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.SAVE_API,
      (metaData: any, content: any, options: any) => {
        return new Promise((resolve) => {
          const formData = new FormData();
          formData.append('status', String(0).valueOf());
          formData.append('signature', this.fileData.filepath);
          formData.append('extension', '.pdf');
          formData.append('fileReference', this.fileData);
          formData.append('file', new Blob([new Uint8Array(content)], {type: 'application/pdf'}),
            this.fileData.originalfilename != null ? this.fileData.originalfilename : this.fileData.filename);

          this.spinner.show();
          this.apiService.replaceUploadedFile(formData).subscribe(resp => {
            this.spinner.hide();
          }, error => {
            this.toaster.error(error.error);
            this.spinner.hide();
          });

          setTimeout(() => {
            const response = {
              code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
              data: {
                metaData: Object.assign(metaData, {updatedAt: new Date().getTime()})
              }
            };
            resolve(response);
          }, 2000);
        });
      },
      {}
    );
  }

  registerEventsHandler() {
    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API,
      () => {
        return new Promise((resolve, reject) => {
          resolve({
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: {
              userProfile: {
                name: this.userDetails.fullName,
                firstName: this.userDetails.firstname,
                lastName: this.userDetails.firmName,
                email: this.userDetails.emailAddress
              }
            }
          });
        });
      }
    );
  }

  public setFileData(fileData: any) {
    this.fileData = fileData;
  }
}
