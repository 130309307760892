import { CLIENT_ML_SIGN_TYPES } from 'countable@helpers';

export class ClientLetterViewModel {
    engagementtemplateid :number = 0;
    fileName :string = ''; //fileNameWithoutExt
    orginalFileName :string = ''; //originalfilename
    s3FilePath :string = '';
}

export class obj {
  header: string;
  text: string;
}

export class TermsConditions {
  header :string = 'Sign Engagement Letter';
  terms :string = 'Terms and conditions';
  create :string = 'Create Signature';
  content :obj[] = [
    {
      header : '1. Agreement to Terms:',
      text: 'By signing this document, you agree to be bound by the terms and conditions contained herein.'
    },
    {
      header : '2. Electronic Signature:',
      text: 'Your electronic signature is legally binding and has the same effect as a handwritten signature.'
    },
    {
      header : '3. Accuracy of Information:',
      text: 'You represent and warrant that the information provided on this document is true, accurate, and complete.'
    },
    {
      header : '4. Data Privacy:',
      text: 'You acknowledge and agree that your personal data will be processed and stored in accordance with applicable laws and regulations.'
    },
    {
      header : '5. Intellectual Property:',
      text: 'All intellectual property rights, including but not limited to, trademarks, copyrights, and patents, belong to the clientNameReplace. and are protected by applicable laws.'
    },
    {
      header : '6. Limitation of Liability:',
      text: 'All intellectual property rights, including but not limited to, trademarks, copyrights, and patents, belong to the clientNameReplace. and are protected by applicable laws.'
    },
    {
      header : '7. Governing Law:',
      text: 'This agreement shall be governed by and construed in accordance with the laws of the jurisdiction in which the clientNameReplace. is located.'
    },
    {
      header : '8. Entire Agreement:',
      text: 'This agreement constitutes the entire agreement between you and the clientNameReplace. and supersedes all prior agreements, understandings, and representations.'
    },
    {
      header : '9. Amendment:',
      text: 'This agreement may be amended at any time by the clientNameReplace, with or without notice to you. Your continued use of the service after such amendment shall be deemed as your acceptance of the amendment.'
    },
    {
      header : '10. Dispute Resolution:',
      text: 'Any dispute arising out of or in connection with this agreement shall be resolved through arbitration in accordance with the rules of the arbitral institution agreed by both parties.'
    }
  ]
}

export class SaveSignatureModel {
  firmuseraccountid: number = 0;
  signature: string = '';
  jsonfilename: string = '';
  jsonfilepath: string = '';
  isdelete: number = 0;
  isuserselected: number = 0;
  signature_type: 'draw' | 'upload' | 'text';
  signature_text: string;
  s3JSONFilePath?: string;
  firmsignatureid: number = 0;
}
export class SaveClientSignature {
  loginid: number = 0;
  engagementtemplateid: number = 0;
  client_signature_id: number = 0;
  signature_type: 'draw' | 'text' | 'upload' | 'signoff' = 'text';
  sign_text: string = '';
  json_name: string = '';
  json_path: string = '';
  isclientaccepted: 0 | 1 = 0;
  readonly is_delete:number = 0;
}

export class ClientSignatureModel {
  signatureType: 'draw' | 'upload' | 'text';
  signatureText: string;
  jsonFileName: string;
  jsonFilePath: string;
  isClientSigned: boolean;
  s3ImagePath: string = '';
  isclientaccepted: 0 | 1 = 0;
  clientaccepteddate: string = '';
  clientsigneddate: string = '';
  clientsignatureid:number = 0;
}

export class ClientSignTypes {
 clientSignArray: Array<SignTypeModel> = [
   {
     "radioId": 3,
     "radioName": "Draw"
   },
    {
      "radioId" : 4,
      "radioName" : "Type"
    },
    {
      "radioId" : 5,
      "radioName" : "Upload"
    }
   ]
}

export class SignTypeModel {
  radioId: 0 | 3 | 4 | 5;
  radioName: string;
}


export class ClientImageInput {
  loginid: number = 0;
  signature_type: 'draw' | 'text' | 'upload' | 'header' | 'footer' | 'signature' = 'text';
  sign_text: string = '';
  json_name: string = '';
  json_path: string = '';
  readonly is_delete: number = 0;
}

export class ImageMLModel {
  firmUserAccountId: number;
  clientFirmId: number;
  headerName: string;
  jsonFileName: string | number;
  jsonFilePath: string;
  readonly isUserSelected = 1;
  footerName: string;
  signText: string;
  signatureType: CLIENT_ML_SIGN_TYPES = 'None';
  subModuleId: number;
  engagementId: number;
  type: 'header' | 'footer' | 'none';
  resizeJsonFilePath?: string;
}

export class HeaderResponseModel {
  clientFirmId: number;
  createdAt: string;
  createdBy: string;
  firmUserAccountId: number;
  headerName: string;
  id: number;
  isActive: boolean;
  isDeleted: boolean;
  isUserSelected: 0 | 1;
  jsonFileName: string;
  jsonFilePath: string;
  modifiedAt: string;
  modifiedBy: string;
}

export class ClientApproveModel {
  engagementTemplateId: number;
  subModuleId: number;
  engagementId: number;
  isReviewedByClient: 0 | 1 = 1;
  reviewedClientFirmId: number;
  isClientAccepted: 0 | 1 = 1;
  acceptedByClientFirmId: number;
  clientSignatureId: number;
}

export class ComponentHeaderModel {
  headerId: number;
  headerName: string;
  jsonFileName: string;
  jsonFilePath: string;
  is_stretch_fit?: number;
  picture_alignment?: string;
  resizeJsonFilePath: any;
}

export class ComponentFooterModel {
  footerId: number;
  footerName: string;
  jsonFileName: string;
  jsonFilePath: string;
  is_stretch_fit?: number;
  picture_alignment?: string;
  resizeJsonFilePath: any;
}

export class ComponentModel {
  footer: ComponentFooterModel | null;
  header: ComponentHeaderModel | null;
}
