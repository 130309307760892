import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CountryModel } from '../model/country.model';
import { EngCountModel } from '../model/eng/eng-count.model';
import { DropMiddleRequestModel } from '../model/file/drag/drop-middle-request.model';
import { ResponseModel } from '../model/file/drag/responce/response.model';
import { FileExtensionModel } from '../model/file/file-extension.model';
import { FirmNotificationModel } from '../model/firm-notification.model';
import { GenericResModel } from '../model/generic-res.model';
import { procedures, updateProcedures } from './app-config';
import { AuthService } from './auth.service';
import { EncodingService } from './encoding.service';
import { HearAboutUsModel } from '../model/hear-about-us.model';
import { SurveyFeedback } from '../helpers/survey.contant';

const textResponse: any = {responseType: 'text'};

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private httpClient: HttpClient, private authService: AuthService, private encodingService: EncodingService) {
  }

  public createClient(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/client-profile', this.encodingService.enData(data), textResponse);
  }

  public updateClient(data): Observable<any> {
    return this.httpClient.put(environment.apiV1 + '/client-profile', this.encodingService.enData(data), textResponse);
  }

  public deleteClient(clientid, auditorid, isarchivedclient): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(clientid)) + '/' + encodeURIComponent(this.encodingService.enData(auditorid)) + '/' + encodeURIComponent(this.encodingService.enData(isarchivedclient)) + '/client-profile';
    return this.httpClient.delete(path, textResponse);
  }

  public getJurisdiction(): Observable<any> {
    return this.httpClient.get(environment.apiV1 + '/jurisdiction');
  }

  public getTypeOfEntity(): Observable<any> {
    return this.httpClient.get(environment.apiV1 + '/entity-type');
  }

  public engDocMail(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/eng-doc-mail', this.encodingService.enData(data), textResponse);
  }

  public engMail(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/eng-mail', this.encodingService.enData(data), textResponse);
  }

  public getClientStatus(): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(this.authService.getLoginUser().useracctid)) + '/client-status';
    return this.httpClient.get(path);
  }

  public getEngagementStatus(): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(this.authService.getLoginUser().useracctid)) + '/engagement-status';
    return this.httpClient.get(path);
  }

  public updateUserProfile(data): Observable<any> {
    return this.httpClient.put(environment.apiV1 + '/user/profile', this.encodingService.enData(data));
  }

  public saveProfileImageToS3(data) {
    data.append('appSyncId', this.authService.getLoginUser().useracctid);
    return this.httpClient.post(environment.apiV1 + '/file', this.encodingService.enData(data), {responseType: 'text'});
  }

  public getFilesFromS3Folder(data): Observable<any> {
    data.append('appSyncId', this.authService.getLoginUser().useracctid);
    return this.httpClient.post(environment.apiV1 + '/all-files', this.encodingService.enData(data));
  }

  /**************** Create engagement * *******************/
  public getAllAuditor(): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(this.authService.getLoginUser().useracctid)) + '/auditor';
    return this.httpClient.get(path);
  }

  public getAuditorRole(): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(this.authService.getLoginUser().useracctid)) + '/auditor-role';
    return this.httpClient.get(path);
  }

  public getBusinessDetails(auditorId): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(auditorId)) + '/business-details';
    return this.httpClient.get(path);
  }

  public getAllEngagement(auditorId): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(+auditorId)) + '/engagement';
    return this.httpClient.get(path, textResponse).pipe(map(res => {
      return JSON.parse(this.encodingService.deData(res));
    }));
  }

  public getClientEngagements(clientfirmid, useraccounid): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(clientfirmid)) + '/' + encodeURIComponent(this.encodingService.enData(useraccounid)) + '/engagement';
    return this.httpClient.get(path, textResponse).pipe(map(res => {
      return JSON.parse(this.encodingService.deData(res));
    }));
  }

  public deleteEngagement(id, auditorId): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(id)) + '/' + encodeURIComponent(this.encodingService.enData(auditorId)) + '/engagement';
    return this.httpClient.delete(path, textResponse);
  }

  public getEngagement(): Observable<any> {
    return this.httpClient.get(environment.apiV1 + '/engagement');
  }

  public getEngagementCountDetail(): Observable<EngCountModel> {
    return this.httpClient.get<EngCountModel>(environment.apiV1 + '/engagement-counts');
  }

  /****Mapping Balance */
  getMappingSheet() {
    return this.httpClient.get(environment.apiV1 + '/map-sheet');
  }

  getMappingStatus(): Observable<any> {
    return this.httpClient.get(environment.apiV1 + '/mapping-status');
  }

  getEngagementYearDetails(engagementId) {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(+engagementId)) + '/' + encodeURIComponent(this.encodingService.enData(this.authService.getLoginUser().useracctid)) + '/engagement-year';
    return this.httpClient.get(path);
  }

  // Balance statement with ID's
  getBalanceSheet(engagementId, year, navSource): Observable<any> {
    return this.httpClient.get(environment.apiV1 + '/balance-sheet/' + encodeURIComponent(this.encodingService.enData(+engagementId)) + '/' + encodeURIComponent(this.encodingService.enData(+year)) + '/' + encodeURIComponent(this.encodingService.enData(+navSource)));
  }

  // Balance sheet get linkto list
  getBalanceSheetLink(engementId, year) {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(this.authService.getLoginUser().useracctid)) + '/notes-balance-sheet?engagementsId=' + encodeURIComponent(this.encodingService.enData(engementId)) + '&acctYear=' + encodeURIComponent(this.encodingService.enData(year));
    return this.httpClient.get(path);
  }

  saveNotesBalanceSheet(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/save-notes-balance-sheet', this.encodingService.enData(data), textResponse);
  }

  unSaveNotesBalanceSheet(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/unlink-all-balance-sheet', this.encodingService.enData(data), textResponse);
  }

  saveFsDetails(data): Observable<any> {
    data.appSyncId = this.authService.getLoginUser().useracctid;
    return this.httpClient.post(environment.apiV1 + '/fs-details', this.encodingService.enData(data), textResponse);
  }

  // Income statement
  getIncomeStatement(engagementId, year): Observable<any> {
    const path = environment.apiV1 + '/income-statement/' + encodeURIComponent(this.encodingService.enData(engagementId)) + '/' + encodeURIComponent(this.encodingService.enData(year)) + '/' + encodeURIComponent(this.encodingService.enData(this.authService.getLoginUser().useracctid));
    return this.httpClient.get(path);
  }

  // Adjustment entry
  getAdjustmentType(): Observable<any> {
    return this.httpClient.get(environment.apiV1 + '/adj-type');
  }

  saveTrialBalanceAdjustment(data): Observable<any> {
    data.appSyncId = this.authService.getLoginUser().useracctid;
    return this.httpClient.post(environment.apiV1 + '/tb/adj-entry', this.encodingService.enData(data), textResponse);
  }

  getFinancialDocumentDetailsFromJSON(url) {
    const headers = new HttpHeaders({'Cache-Control': 'no-cache', Pragma: 'no-cache'}).set('Target', 'S3');
    return this.httpClient.get(url, {headers});
  }

  saveFinancialDocumentJSON(data) {
    data.append('appSyncId', this.authService.getLoginUser().useracctid);
    return this.httpClient.post(environment.apiV1 + '/file', data, {responseType: 'text'});
  }

  // getting file name and file path from S3
  saveAndGetFileNamePath(data): Observable<any> {
    data.append('appSyncId', this.authService.getLoginUser().useracctid);
    return this.httpClient.post(environment.apiV1 + '/fileNamePathV2', data, {
      responseType: 'text'
    });
  }

  replaceUploadedFile(data): Observable<any> {
    return this.httpClient.put(environment.apiV1 + '/replaceFile', data);
  }

  // getting file name and file path from S3
  generateId(data): Observable<any> {
    data.appSyncId = this.authService.getLoginUser().useracctid;
    return this.httpClient.post(environment.apiV1 + '/generateIdV2', this.encodingService.enData(data, 'body'), {
      responseType: 'text'
    });
  }

  // Copies file and then renames copied file
  public copyAndRenameFile(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/copy-and-rename-file', this.encodingService.enData(data, 'body'), textResponse);
  }

  // ----------------Firm on-boarding---------------------------
  public getCountries(onlyOperationalCountry: number = 0): Observable<CountryModel[]> {
    return this.httpClient.get<CountryModel[]>(environment.apiV1 + '/address/country/list', {
      params: new HttpParams().append('only-operational', String(onlyOperationalCountry))
    }).pipe(retry(20));
  }

  // get cities by stateID
  public getCitiesInState(stateId): Observable<any> {
    return this.httpClient.get(environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(stateId)) + '/city');
  }

  // get practice units
  public getPracticeUnits(userAccountId) {
    return this.httpClient.get(environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(userAccountId)) + '/practice-units');
  }

  // get, create, update and delete partner in firm
  public getAllPartners(firmId): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(firmId)) + '/partner-details';
    return this.httpClient.get(path);
  }

  public createPartner(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/partner', this.encodingService.enData(data), textResponse);
  }

  public deletePartner(partnerId, firmId): Observable<any> {
    return this.httpClient
      .delete(environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(partnerId)) + '/' + encodeURIComponent(this.encodingService.enData(firmId)) + '/delete-partner', textResponse);
  }

  public getFirmClients(): Observable<any> {
    return this.httpClient.get(environment.apiV1 + '/get-clients-of-firm');
  }

  // get clients under partner
  public getClientsOfPartner(firmPartnerId): Observable<any> {
    return this.httpClient
      .get(environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(firmPartnerId)) + encodeURIComponent(this.encodingService.enData(this.authService.getLoginUser().useracctid)) + '/get-clients-of-partner');
  }

  // get partner clients - updated
  public getPartnerClients(firmId, firmPartnerId) {
    return this.httpClient
      .get(environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(firmId)) + '/' + encodeURIComponent(this.encodingService.enData(firmPartnerId)) + '/get-firm-partner-client-details');
  }

  public savePartnerClients(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/save-clients-assignedTo-partner', this.encodingService.enData(data), textResponse);
  }

  public saveClientPartner(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/save-partner-assignedTo-client', this.encodingService.enData(data), textResponse);
  }

  public savePartnerStaff(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/staff-assigned', this.encodingService.enData(data), textResponse);
  }

  public saveFirmFourSteps(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/firm-4-steps', this.encodingService.enData(data), textResponse);
  }

  // save partners list from csv
  public savePartnerJson(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/partner-json', this.encodingService.enData(data), textResponse);
  }

  public saveStaffJson(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/staff-json', this.encodingService.enData(data), textResponse);
  }

  // Partner client details
  public getClientDetailsOfPartner(partnerAccountId): Observable<any> {
    return this.httpClient.get(environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(partnerAccountId)) + '/get-client-details-of-partner');
  }

  public getPartnerDetailsOfPartner(): Observable<any> {
    return this.httpClient.get(environment.apiV1 + '/partner-assigned');
  }

  // ------------------Staff on-boarding --------------------------------------------
  public getStaffData() {
    return this.httpClient.get(environment.apiV1 + '/staff');
  }

  public saveStaff(data): Observable<any> {
    const path = environment.apiV1 + '/staff';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  // -------delete staff-------
  public deleteStaff(data) {
    return this.httpClient.post(environment.apiV1 + '/delete-staff', this.encodingService.enData(data), textResponse);
  }

  public getDesignation(): Observable<any> {
    return this.httpClient.get(environment.apiV1 + '/designation');
  }

  // -------------------------------compilation procedure------------------------------------------------------------
  // Get Lead sheet details
  public getProcedureDetails(engagementId, year): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(+engagementId)) + '/' + encodeURIComponent(this.encodingService.enData(+year)) + '/' + encodeURIComponent(this.encodingService.enData(this.authService.getLoginUser().useracctid)) + '/procedures';
    return this.httpClient.get(path, textResponse).pipe(map(res => {
      return JSON.parse(this.encodingService.deData(res));
    }));
  }

  // Get Lead sheet details
  public getTrailBalanceByLeadSheet(engagementId, leadSheet, year, finSubGroupId): Observable<any> {
    return this.httpClient
      .get(environment.apiV1 + '/leadsheet-trialbalance/' + encodeURIComponent(this.encodingService.enData(+engagementId)) + '/' + encodeURIComponent(this.encodingService.enData(+leadSheet)) + '/' + encodeURIComponent(this.encodingService.enData(+year)) + '/' + encodeURIComponent(this.encodingService.enData(+finSubGroupId)));
  }

  // Get partnerClientManagerStaff details
  public partnerClientManagerStaff(partnerLoginId) {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(+partnerLoginId)) + '/partner-client-manager-staff';
    return this.httpClient.get(path);
  }

  // partner-staff-list
  public savePartnerManagerStaff(data): Observable<any> {
    const path = environment.apiV1 + '/partner-staff-list';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  // gifi
  public gifiCode(gifiEngagementID, acctYear): Observable<any> {
    return this.httpClient
      .get(environment.apiV1 + '/gifi/' + encodeURIComponent(this.encodingService.enData(+gifiEngagementID)) + '/' + encodeURIComponent(this.encodingService.enData(acctYear)),
        {responseType: 'blob'});
  }

  public fetchClients(): Observable<GenericResModel<string>> {
    return this.httpClient.get<GenericResModel<string>>(environment.apiV1 + '/client/list');
  }

  /*** client on-boarding ***/
  public getQuestionsAndAnswers(questionId, engagementId): Observable<any> {
    return this.httpClient.get(environment.apiV1 + '/question-answer/' + encodeURIComponent(this.encodingService.enData(+questionId)) + '/' + encodeURIComponent(this.encodingService.enData(+engagementId)));
  }

  // get api with json as input
  public getData(inputData): Observable<any> {
    const loginUser = this.authService.getLoginUser();
    inputData.loginId = loginUser.useracctid;
    const path = environment.apiV1 + '/get-procedure-json-data';
    return this.httpClient.post(path, this.encodingService.enData(inputData), textResponse).pipe(map(res => {
      if (procedures.includes(inputData.procedureName) === true) {
        return this.encodingService.deData(res);
      } else {
        return res;
      }
    }));
  }

  // common post api
  public saveData(data): Observable<any> {
    let inputData;
    data.loginId = this.authService.getLoginUser().useracctid;
    if (updateProcedures.includes(data.procedureName) === true) {
      data.inputParameters = this.encodingService.enData(data.inputParameters, 'body');
      inputData = data;
    } else {
      inputData = data;
    }
    const path = environment.apiV1 + '/update-procedure-json-data';
    // return this.http.post(path, this.objectStringify(inputData), textResponse);
    return this.httpClient.post(path, this.encodingService.enData(inputData), textResponse);
  }

  /* WOPI URL */
  public getS3DocumentWopiInfo(hash: string, isReadOnly?: boolean): Observable<GenericResModel<string>> {
    const readOnlyParam = isReadOnly !== undefined ? `&readOnly=${isReadOnly}` : '';
    return this.httpClient.post<GenericResModel<string>>(
      environment.apiV1 + `/wopi/url?action=view${readOnlyParam}`,
      hash,
      {responseType: 'json'}
    );
  }

  // file download
  fileDownload(data): Observable<any> {
    data = JSON.parse(data);
    data.appSyncId = this.authService.getLoginUser().useracctid;
    const path = environment.apiV1 + '/fileDownloadFromPath';
    return this.httpClient.post(path, this.encodingService.enData(JSON.stringify(data)), {responseType: 'blob'});
  }

  // copy file from one s3 folder to another
  copyFileInS3(data) {
    const path = environment.apiV1 + '/single-fileV2';
    return this.httpClient.put(path, data, textResponse);
  }

  //copy firm template from one s3 folder to another
  copyFirmTemplate(data) {
    const path = environment.apiV1 + '/copy-firm-template';
    return this.httpClient.put(path, data, textResponse);
  }

  copyFiles(data) {
    const path = environment.apiV1 + '/copy-files';
    return this.httpClient.post(path, data, textResponse);
  }

  getFileData(data) {
    data.appSyncId = this.authService.getLoginUser().useracctid;
    const path = environment.apiV1 + '/fileconversionJSON';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  getJsonData(data): Observable<any> {
    data.appSyncId = this.authService.getLoginUser().useracctid;
    const path = environment.apiV1 + '/get-file-dataV2';
    return this.httpClient.post(path, this.encodingService.enData(data, 'body'), textResponse);
  }

  getJsonFirmTemplate(data): Observable<any> {
    data['appSyncId'] = this.authService.getLoginUser().useracctid;
    const path = environment.apiV1 + '/get-firm-template';
    return this.httpClient.post(path, this.encodingService.enData(data, 'body'), textResponse);
  }

  getEngagementTemplateAutomationData(data) {
    data.appSyncId = this.authService.getLoginUser().useracctid;
    const path = environment.apiV1 + '/elements';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  getFiles(filepath): Observable<any> {
    this.authService.getLoginUser();
    const inputData = {
      appSyncId: this.authService.getLoginUser().useracctid,
      status: 0,
      signatureKey: filepath
    };
    const path = environment.apiV1 + '/signed-urlV2';
    return this.httpClient.post(path, this.encodingService.enData(inputData, 'body'), textResponse);
  }

  exportToExcel(inputData): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/tb/adjustment-entries', this.encodingService.enData(inputData), textResponse);
  }

  makeId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789#@$!%^&*';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getHeardAboutUsList(): Observable<GenericResModel<HearAboutUsModel[]>> {
    return this.httpClient.get<GenericResModel<HearAboutUsModel[]>>(environment.apiV1 + '/about-us');
  }

  docMailTemplateData(data): Observable<any> {
    const path = environment.apiV1 + '/doc-mail';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  docBatchMailTemplateData(data): Observable<any> {
    const path = environment.apiV1 + '/batch-send-mail';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  public moveTemplate(data: any): Observable<GenericResModel<boolean>> {
    const path = environment.apiV1 + '/templates';
    return this.httpClient.put<GenericResModel<boolean>>(path, data);
  }

  addTemplateToEngagement(data): Observable<any> {
    const path = environment.apiV1 + '/eng-template';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  renameTemplateToEngagement(data): Observable<any> {
    const path = environment.apiV1 + '/eng-template';
    return this.httpClient.put(path, this.encodingService.enData(data), textResponse);
  }

  public deleteTemplateToEngagement(data): Observable<any> {
    const path = environment.apiV1 + '/delete-template';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  public deleteLeftSideFile(fileId) {
    return this.httpClient.delete(environment.apiV1 + '/file/delete-from-left/' + this.encodingService.enData(fileId), textResponse);
  }

  clientTemplate(data): Observable<any> {
    const path = environment.apiV1 + '/client-templates';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  createReferenceInTheMiddle(droppedFiles: DropMiddleRequestModel[]): Promise<ResponseModel> {
    const data: any = {};
    data.appSyncId = this.authService.getLoginUser().useracctid;
    data.files = droppedFiles;
    return this.httpClient.post<ResponseModel>(environment.apiV1 + '/drop-in-middle', this.encodingService.enData(data), {
      headers: {'Content-Type': 'application/json', responseType: 'json'}
    }).toPromise();
  }

  async getAllFileExtensions(): Promise<FileExtensionModel[]> {
    return await this.httpClient.get<FileExtensionModel[]>(environment.apiV1 + '/file/get-all-extensions').toPromise();
  }

  // API calls for approved by board in FS settings
  getFsSettings(engagementId, typeOfEntityId): Observable<any> {
    const path = environment.apiV1
      + '/fs-settings?engagementsId=' + encodeURIComponent(this.encodingService.enData(engagementId))
      + '&typeOfEntityId=' + encodeURIComponent(this.encodingService.enData(typeOfEntityId));
    return this.httpClient.get(path, textResponse);
  }

  getBoardMembers(engagementId: number): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(engagementId)) + '/board-members';
    return this.httpClient.get(path, textResponse);
  }

  saveBoardMember(data): Observable<any> {
    const path = environment.apiV1 + '/save-board-member';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  applySettings(data): Observable<any> {
    const path = environment.apiV1 + '/update-approval-text';
    return this.httpClient.put(path, this.encodingService.enData(data), textResponse);
  }

  getSubgroupSort(loginId, data): Observable<any> {
    const path = environment.apiV1
      + '/' + encodeURIComponent(this.encodingService.enData(loginId))
      + '/get-sorted-records?engagementsId=' + encodeURIComponent(this.encodingService.enData(data['engagementsid']))
      + '&accYear=' + encodeURIComponent(this.encodingService.enData(data['acctyear']))
      + '&finSubgroupId=' + encodeURIComponent(this.encodingService.enData(data['finsubgrpid']))
      + '&submoduleId=' + encodeURIComponent(this.encodingService.enData(data['submoduleid']));
    return this.httpClient.get(path, textResponse);
  }

  saveSubgroupSort(data): Observable<any> {
    const path = environment.apiV1 + '/save-wb-sorting';
    return this.httpClient.post(path, data, textResponse);
  }

  saveWbCompressStatus(data): Observable<any> {
    const path = environment.apiV1 + '/save-compress-status';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  getUSNotesPath(loginId, countryId): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(loginId)) + '/' + encodeURIComponent(this.encodingService.enData(countryId)) + '/master-notes';
    return this.httpClient.get(path, textResponse);
  }

  // Stockholder's Equity
  saveStockholderColumn(data): Observable<any> {
    const path = environment.apiV1 + '/save-stockholder-columns';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  saveStockholderRow(data): Observable<any> {
    const path = environment.apiV1 + '/save-stockholder-rows';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  getStockholderDefaults(data): Observable<any> {
    const path = environment.apiV1 + '/get-stockholder-defaults?data=' + encodeURIComponent(this.encodingService.enData(data));
    return this.httpClient.get(path, textResponse);
  }

  saveNotesStockholderEquity(data): Observable<any> {
    const path = environment.apiV1 + '/save-notes-stockholder-equity';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  rollForwardStockholder(data): Observable<any> {
    const path = environment.apiV1 + '/roll-forward-stockholders';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  // End of Stockholder's Equity

  getFSPreview(data): Observable<any> {
    const path = environment.apiV1 + '/generate-pdf';
    return this.httpClient.post(path, data, textResponse);
  }

  getIncomeStatementPreview(data): Observable<any> {
    const path = environment.apiV1 + '/generate-pdf';
    return this.httpClient.post(path, data, textResponse);
  }

  // Report templates
  getElementValues(loginId, engagementId): Observable<any> {
    const path = environment.apiV1
      + '/' + encodeURIComponent(this.encodingService.enData(loginId))
      + '/' + encodeURIComponent(this.encodingService.enData(engagementId))
      + '/elements';
    return this.httpClient.get(path, textResponse);
  }

  // End of report templates

  // Statement of cash flows
  mapCashFlowTypes(data): Observable<any> {
    const path = environment.apiV1 + '/map-all-cash-flow-types';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  getCFTypeFromMapSheet(mapSheetId): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(mapSheetId)) + '/cf-type';
    return this.httpClient.get(path, textResponse);
  }

  getMappingFromLeadSheet(leadSheetId): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(leadSheetId)) + '/mapping-details';
    return this.httpClient.get(path, textResponse);
  }

  getAllCFTypes(): Observable<any> {
    const path = environment.apiV1 + '/cf-types-all';
    return this.httpClient.get(path, textResponse);
  }

  saveCashFlowRowInTb(data): Observable<any> {
    const path = environment.apiV1 + '/save-cf-mapping';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  unmapAllCashFlowinTb(data): Observable<any> {
    const path = environment.apiV1 + '/unmap-all-cash-flow-types';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  saveCashFlowRowInWb(data): Observable<any> {
    const path = environment.apiV1 + '/save-cash-flow-row';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  saveCashFlowType(data): Observable<any> {
    const path = environment.apiV1 + '/save-cash-flow-type';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  saveCashFlowSectionInWb(data): Observable<any> {
    const path = environment.apiV1 + '/save-cash-flow-section';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  rollForwardCashFlow(data): Observable<any> {
    const path = environment.apiV1 + '/roll-forward-cash-flow';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  getCashflowData(data): Observable<any> {
    const path = environment.apiV1 + '/get-cashflow-data?data=' + encodeURIComponent(this.encodingService.enData(data));
    return this.httpClient.get(path, textResponse);
  }

  getNonCashDropdown(data): Observable<any> {
    const path = environment.apiV1 + '/get-non-cash-dropdown?data=' + encodeURIComponent(this.encodingService.enData(data));
    return this.httpClient.get(path, textResponse);
  }

  getCashflowYearData(engagementsId): Observable<any> {
    const path = environment.apiV1 + '/get-cashflow-years?engagementId=' + engagementsId;
    return this.httpClient.get(path);
  }

  // End of Statement of cash flows

  // FS Export
  uploadFinalPackage(data): Observable<any> {
    const path = environment.apiV1 + '/upload-final-package';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  getFinalPackage(data): Observable<any> {
    const path = environment.apiV1 + '/fs-final-package?data=' + encodeURIComponent(this.encodingService.enData(data));
    return this.httpClient.get(path, textResponse);
  }

  getFSClientStatus(clientFirmId): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(clientFirmId)) + '/client-status';
    return this.httpClient.get(path, textResponse);
  }

  sendFSPackage(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/send-fs-package', data, {
      responseType: 'text'
    });
  }

  getFSPackageSentStatus(engagementId, acctYear): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(engagementId))
      + '/' + encodeURIComponent(this.encodingService.enData(acctYear)) + '/send-fs-status';
    return this.httpClient.get(path, textResponse);
  }

  getFinalSentPackage(data): Observable<any> {
    const path = environment.apiV1 + '/fs-final-sent-package?data=' + encodeURIComponent(this.encodingService.enData(data));
    return this.httpClient.get(path, textResponse);
  }

  // End of FS Export

  // Balance income for materiality data
  getBalanceIncome(data): Observable<any> {
    const path = environment.apiV1 + '/tb/report/balance-income';
    return this.httpClient.post(path, data, textResponse);
  }

  getNotesTableTrialBalance(data): Observable<any> {
    const path = environment.apiV1 + '/get-notes-table-trial-balance?data=' + encodeURIComponent(this.encodingService.enData(data));
    return this.httpClient.get(path, textResponse);
  }

  // Document Repository
  getAutomatedDocumentRepository(data): Observable<any> {
    const path = environment.apiV1 + '/get-automated-document-repository?data=' + encodeURIComponent(this.encodingService.enData(data));
    return this.httpClient.get(path);
  }

  public deleteEngagementDocumentRepository(data): Observable<any> {
    const path = environment.apiV1 + '/delete-engagement-document-repository?data=' + encodeURIComponent(this.encodingService.enData(data));
    return this.httpClient.delete(path, textResponse);
  }

  public deleteOrRenameFileInS3andDB(data): Observable<any> {//IMPORTANT: only for document repository automated folder files
    const path = environment.apiV1 + '/delete-rename-file-s3-db?data=' + encodeURIComponent(this.encodingService.enData(data));
    return this.httpClient.put(path, textResponse);
  }

  // End of Document Repository

  // getting file name and file path from S3 for base64
  saveBase64Image(data): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/signature-upload', (data), {responseType: 'json'});
  }

  public renameFileInS3andDB(data): Observable<any> {
    return this.httpClient.put(environment.apiV1 + '/rename-file-s3-db', this.encodingService.enData(data), textResponse);
  }

  // get CMS member engagements
  public getCmsEngagements(userId: number, cmsUSerID: number): Observable<GenericResModel<any>> {
    return this.httpClient.get<GenericResModel<any>>(environment.apiV1 + '/' + userId + '/cms/engagement/' + cmsUSerID);
  }

  public reassignCMSteam(data): Observable<GenericResModel<any>> {
    return this.httpClient.put<GenericResModel<any>>(environment.apiV1 + '/staff/reassign', data);
  }

  public getFirmNotifications(): Observable<GenericResModel<FirmNotificationModel[]>> {
    return this.httpClient.get<GenericResModel<FirmNotificationModel[]>>(environment.apiV2 + '/notification');
  }

  public setNotificationStatus(id: number): Observable<GenericResModel<boolean>> {
    return this.httpClient.put<GenericResModel<boolean>>(environment.apiV2 + '/notification', id);
  }

  getAutoNotesData(engId, acctYear) {
    const path = environment.apiV1 + '/auto-ppe-default?engagementId=' + engId + '&acctYear=' + acctYear;
    return this.httpClient.get(path);
  }

  getLeadSheetData(engId, acctYear) {
    const path = environment.apiV1 + '/auto-ppe-dropdown?engagementId=' + engId + '&acctYear=' + acctYear;
    return this.httpClient.get(path);
  }

  saveQueueNotePref(engId, data) {
    const path = environment.apiV1 + '/notes-engagement-notification?engagementId=' + engId;
    return this.httpClient.post(path, '', {
      headers: new HttpHeaders()
        .set('X-Action-Type', data.action_type)
        .set('X-Module-Type', data.module_type)
    });
  }

  public reorderDocInsideSection(data): Observable<any> {
    return this.httpClient.put(environment.apiV1 + '/reorder-document', data);
  }

  public renumberDocInsideSection(data): Observable<any> {
    return this.httpClient.put(environment.apiV1 + '/renumber-document', data);
  }

  public deleteEngScopeLeadsheet(data): Observable<any> {
    const path = environment.apiV1 + '/file/delete-planning-template?engagementId=' + data.engagementId + '&leadSheetId=' + data.leadSheetId;
    return this.httpClient.delete(path, textResponse);
  }

  public saveClientApprovalDate(data) {
    return this.httpClient.post(environment.apiV1 + '/completion/client-approval-signoff', data);
  }

  public engLetterSignOffDate(engagementID: number) {
    return this.httpClient.get(environment.apiV1 + '/' + engagementID + '/engagement-letter-sign-date');
  }


  public completionArchiveDetail(data): Observable<any> {
    const path = environment.apiV1 + '/completion-archive-detail?engagementId=' + data.engagementsid;
    return this.httpClient.get(path);
  }

  getAllQuestions(): Observable<any> {
    return this.httpClient.get(environment.apiV1 + '/feedback/question');
  }

  submitFeedback(feedback: SurveyFeedback): Observable<any> {
    return this.httpClient.post(environment.apiV1 + '/feedback/save', feedback);
  }

  surveyStatus(): Observable<any> {
    return this.httpClient.get(environment.apiV1 + '/feedback/status');
  }

  public fetchS3File(filePath: string): Observable<GenericResModel<string>> {
    return this.httpClient.post<GenericResModel<string>>(environment.apiV1 + '/luca/get-file', {signatureKey: filePath});
  }
}
