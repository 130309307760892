export class PasswordValidationModel {

  private static readonly REG_ALPHABETS = /(?=.*?[a-zA-Z])/;
  private static readonly REG_NUMBER = /(?=.*?[0-9])/;
  private static readonly REG_UPPER_CASE = /(?=.*?[A-Z])/;
  private static readonly REG_LOWER_CASE = /(?=.*?[a-z])/;
  private static readonly REG_SPECIAL_CHAR = /\W|_/;

  public isValid: boolean = false;
  public hasAlphabet: boolean = false;
  public hasNumber: boolean = false;
  public hasUpperCase: boolean = false;
  public hasLowerCase: boolean = false;
  public hasUpperLowerCase: boolean = false;
  public isLengthValid: boolean = false;
  public hasEspecialChar: boolean = false;

  public constructor() {
  }

  public validate(password: string) {
    if (password && password.length > 0) {
      this.hasAlphabet = PasswordValidationModel.REG_ALPHABETS.test(password);
      this.hasNumber = PasswordValidationModel.REG_NUMBER.test(password);
      this.hasLowerCase = PasswordValidationModel.REG_LOWER_CASE.test(password);
      this.hasUpperCase = PasswordValidationModel.REG_UPPER_CASE.test(password);
      this.isLengthValid = password.length >= 8;
      this.hasUpperLowerCase = this.hasUpperCase && this.hasLowerCase;
      this.hasEspecialChar = PasswordValidationModel.REG_SPECIAL_CHAR.test(password);
      this.isValid = (this.hasAlphabet && this.hasNumber && this.hasUpperLowerCase && this.isLengthValid && this.hasEspecialChar);
    }
  }
}
