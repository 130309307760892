import { ApiService } from '../services/api.service';
import { AsyncValidatorFn, AsyncValidator, NG_ASYNC_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { Directive } from '@angular/core';
import { map, catchError } from 'rxjs/operators';

export function UniqueEmailValidator(apiService: ApiService): AsyncValidatorFn {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        let useraccountId, loggedInUserData, teamEditData, teamEmail;
        loggedInUserData = JSON.parse((localStorage.getItem('userDetails')));
        if (localStorage.getItem("teamMemberData")) {
            teamEditData = JSON.parse((localStorage.getItem('teamMemberData')));
        }
        if (localStorage.getItem("settingsUserAccessTeamMemberData")) { //coming from Settings > teamaccess
            teamEditData = JSON.parse((localStorage.getItem('settingsUserAccessTeamMemberData')));
        }
        if (teamEditData) {
            if (localStorage.getItem("settingsUserAccessTeamMemberData")) {
                teamEmail = teamEditData.email;
            }
            else {
                teamEmail = teamEditData.emailaddress;
            }
        }
        if (loggedInUserData && loggedInUserData.useracctid) {
            useraccountId = loggedInUserData.useracctid;
        }
        const inputData = {
            "checkemail":
                [{
                    "emailaddress": c.value,
                    "validatestatus": 0,
                    "loginid": useraccountId,
                    "userroleid": localStorage.getItem('addteamMemeberCat') ? +localStorage.getItem('addteamMemeberCat') : (teamEditData?.useraccountroleid ? teamEditData.useraccountroleid : (teamEditData?.userroleid ? teamEditData?.userroleid : loggedInUserData.userroleId))
                }]
        }
        const inputString = JSON.stringify(inputData);
        let getData = {
            "procedureName": "validateuseremail",
            "inputParameters": inputString
        }
        let debounceTime = 200; //milliseconds
        return timer(debounceTime).pipe(() => {
            if ((teamEmail == c.value || loggedInUserData?.emailAddress == c.value)) {
                return of(null);
            }
            else {
                return apiService.getData(getData).pipe(
                    map(res => {
                        let result: any = [];
                        result = JSON.parse(res);
                        localStorage.setItem('uniqueMailValidateCMS', JSON.stringify(result[0]));
                        if ((inputData.checkemail[0].userroleid == 11)) {
                            return ((result[0]?.IsExistsInSameFirm === 1) ? { 'uniqueEmail': true } : null);
                        } else {
                            return result[0].validatestatus === 1 ? { 'uniqueEmail': true } : null;
                        }

                    }),
                    catchError(() => of(null))
                );
            }
        });
    };
}

@Directive({
    selector: '[uniqueEmail][formControlName],[uniqueEmail][formControl],[uniqueEmail][ngModel]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: UniqueEmailValidatorDirective, multi: true }]
})
export class UniqueEmailValidatorDirective implements AsyncValidator {
    constructor(public apiService: ApiService) { }
    validate(c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return UniqueEmailValidator(this.apiService)(c);
    }
}
