import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, takeUntil } from 'rxjs';
import { ApiService } from './api.service';
import { SurveyUtils } from '../helpers/survey-util';
import { FirmService } from 'countable@services';
import { BillingService } from './billing.service';
import { BillingModel } from '../model/billing/billing.model';

@Injectable({
  providedIn: 'root'
})
export class NPSSurveyService implements OnDestroy {
  private isVisibleSubject = new BehaviorSubject<boolean>(false);
  modalVisibility = this.isVisibleSubject.asObservable();
  isSurveyCompleted: boolean = false;
  private allSubscriptions: Subscription = new Subscription();
  public billing: BillingModel = null;
  public ignoreCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private apiService: ApiService, private firmService: FirmService, private billingService: BillingService) {
    this.allSubscriptions.add(
      this.billingService.subject.subscribe(billing => {
        if (billing) {
          this.billing = billing;
        }
      })
    )
  }

  checkSurveyStatus(): void {
    if (this.billing && this.billing?.isOnTrial === true) {
      return;
    } else {
      this.apiService.surveyStatus().subscribe((response) => {
        if (response.data) { // If ignored/completed the survey
          this.isSurveyCompleted = response.data.feedbackType === 'Ignore' ? false : true;
          if (!this.isSurveyCompleted) {
            const surveyIgnoreCount = response.data.ignoreCount;
            this.ignoreCount$.next(surveyIgnoreCount);
            const lastIgnoreDate = new Date(new Date(response.data.lastIgnoreDate).setHours(0, 0, 0, 0));
            const nextSurveyDate = SurveyUtils.calculateNextSurveyShowDate(lastIgnoreDate, surveyIgnoreCount);
            const today = new Date();
            if (today >= nextSurveyDate) {
              this.checkUrlAndShow();
            } else {
              // Wait untill next survey date
            }
          } else {
            // Do Nothing if survey is completed
          }

        } else {  // Very first time login
          this.checkUrlAndShow();
        }
      })
    }
  }

  public checkUrlAndShow(): void {
    const currentUrl = window.location.pathname;
    if (!SurveyUtils.isPathBlacklisted(currentUrl)) {
      setTimeout(() => {
        this.show();
      }, 60000); // 30 seconds
    }
  }

  show(): void {
    this.isVisibleSubject.next(true);
  }

  hide(): void {
    this.isVisibleSubject.next(false);
  }

  ngOnDestroy(): void {
    this.allSubscriptions && this.allSubscriptions.unsubscribe();
  }

}
