import { Component, OnInit, Input, Output , ViewEncapsulation, EventEmitter} from '@angular/core';

@Component({
	selector: 'editableLabel',
	templateUrl: 'editableLabel.component.html',
	styleUrls: ['./editableLabel.component.scss']
})

export class EditableLabelComponent implements OnInit {
	@Input() label: string = '';

	isEditable: boolean = false;
	private oldvalue: string;


	ngOnInit() { 
		this.oldvalue = this.label;
	}

	onCancel()
	{
		this.label = this.oldvalue;
		this.isEditable = !this.isEditable;
	}

	onUpdate()
	{
		this.oldvalue =this.label;
		this.isEditable = !this.isEditable;
	}
}