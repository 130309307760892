import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-alerts',
  templateUrl: './message-alerts.component.html',
  styleUrls: ['./message-alerts.component.scss']
  // styles: [`
  //   .example-pizza-party {
  //     color: hotpink;
  //   }
  // `],
})
export class MessageAlertsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
