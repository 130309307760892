export enum PLANS_ADDONS_MESSAGES {
    SUPERCHARGE_ADDED = 'The supercharge add-on has been successfully added to your plan',
    FLEX_HEADER_ADDED = 'Countable Flex Engagements',
    FLEX_SUBHEADER_ADDED = 'To remove Countable Flex from your subscription plan, deactivate the add-on from the billing page.',
    FLEX_HEADER_REMOVED = 'Countable Flex Removed Successfully',
    FLEX_SUBHEADER_REMOVED = 'The Countable Flex add-on has been successfully excluded from your plan',
    FLEX_ACTIVATION = 'Countable Flex add-on successfully activated on your plan',
    FLEX_DEACTIVATION = 'Countable Flex add-on successfully deactivated on your plan',
    FLEX_ADDED = 'The Countable Flex add-on has been successfully added to your plan',
    FLEX = 'Countable Flex Included Successfully',
    FLEX_DEACTIVATED_WHEN_PAUSED = 'Please Deactivate Countable Flex before proceeding',
    FLEX_DISPLAY_NAME = 'Countable Flex',
    FLEX_COMMITMENT = `To deactivate this add-on, all commitments must be met. Once fulfilled, the 'Deactivate' button will become enabled.`,
    AI_LUCA = 'LucaAI',
    AI_HEADER_ADDED = 'AI Copilot',
    AI_SUBHEADER_ADDED = 'Deactivate AI Prepare add-on to enable removal.',
    AI_HEADER_REMOVED = 'AI Removed Successfully',
    AI_SUBHEADER_REMOVED = 'The AI add-on has been successfully excluded from your plan',
    AI_ACTIVATION = 'AI add-on successfully activated on your plan',
    AI_DEACTIVATION = 'AI add-on successfully deactivated on your plan',
    AI_ADDED = 'The AI-Preparer add-on has been successfully added to your plan',
    AI = 'AI Included Successfully',
    NOTE = 'Note: Go back to the billing page to activate Countable Flex from `My Add-ons` section',
    LAST_MONTH = 'Please try again in the last month of Subscription'
}
