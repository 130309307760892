import { CONCLUDE_WORKSHEETS } from 'countable@helpers';

export class EScopeProceduresModel {
  leadSheetName: string;
  leadSheetCode: string;
  leadSheetId: number;
  isProcedureMapped: boolean;
  isProcedureChanged: boolean;
}

export class WorksheetProceduresS3Model {
 sourceJsonFilePath: string;
 destinationJsonFilePath: string;
}

export class MultiWorksheetProceduresS3Model {
  readonly status: number = 0;
  readonly foldername: string = "Worksheets";
  filenames: string[];
  destinationFileNames: string[];
  firmId: number;
  appSyncId: number;
}

export class WorksheetProceduresDBModel {
  engagementsid: number;
  leadsheetid:number;
  loginid: number;
  JsonFileName: string;
  JsonFilePath: string;
  is_mappinng_enabled: 0 | 1 = 1;
  isdelete: 0 | 1 = 0;
  additional_procedure :number;
}

export class SectionModel {
  id: number;
  subModuleUUID: string;
  JsonFileName: string;
  JsonFilePath: string;
  is_copied_to_engagement: number;
  is_master: number;
  name: string;
}

export class ESLeadSheetModel {
  leadsheetid: number;
  leadsheetcode: string;
  leadsheetname: string;
  finaltotal: string;
  leadsheetidcode: number;
  is_mappinng_enabled: number;
  fingroupid: number;
  numericalTotal: number;
  hasMateriality: "Yes" | "No" | "" = ""
}

export class FSATableModel {
  FirmTemplateID: number;
  JsonFileName: string;
  JsonFilePath: string;
  materialityML:string;
  disableMatField:boolean;
  finaltotal: string;
  isactive: number;
  leadsheetcode: string;
  leadsheetid: number;
  leadsheetname: string;
  mappedTemplateIndex: number;
  master_template_uid: string;
  materiality: string
  procedures: string;
  mastertemplateid: number;
  fingroupid: number;
  description: string;
  isProceduresMapEnabled: 0 | 1 = 1; //1 = procedures mapping can be changed
                                      // 0 = procedures mapping is disabled due to change is procedures worksheet
}

export class FirmProceduresModel {
  DateofCreated: string;
  FirmTemplateID: number
  JsonFileName: string;
  JsonFilePath: string;
  TemplateName: string;
  createmodifydelete: number;
  is_default: number;
  isactivemenu: number;
  isexpand: string;
  ishighlight: string;
  master_template_uid: string;
  mastertemplateid: number;
  sub_template_type:"Procedure" = "Procedure";
  templatefolderid: number;
  templatetype:"worksheet" = "worksheet";
  isProcedureMapped: boolean = false;
}

export class MasterProceduresModel {
  JsonFileName: string;
  JsonFilePath: string;
  isactivemenu: number;
  isexpand: string;
  ishighlight: string;
  istemplateChecked: string;
  master_template_uid: string;
  mastertemplateid: number;
  sub_template_type:"Procedure" = "Procedure";
  templatename:string;
  templatetype:"worksheet" = "worksheet";
  isProcedureMapped: boolean = false;
}

export class ProceduresModel {
  firmProcedures: FirmProceduresModel[] = [];
  masterProcedures: MasterProceduresModel[] = [];
}

export class ConcludeDetailsModel {
  signedBy: string;
  signedDate: any;
  concludeDetails: CONCLUDE_WORKSHEETS = "None";
  concludeText: string = '';
  name: "Conclusion" = "Conclusion";
}

export class BenchMarkModel {
  id: number;
  is_editatble: boolean;
  is_enabled: boolean;
  materiality: any;
  name: string;
  sort_order: number;
  threshold: number;
}

export class ThresholdModel {
  materialityValue: any;
  finGroupId: number;

}

export class RearrangeNumbersModel {
  engagementId: number;
  leadSheetId: number;
}
