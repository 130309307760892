import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class TemplatesConstantsService {

  constructor() {}

    public addLetterDialogBox :Object = {
      createHeader : 'Create new letter',
      selectHeader : 'Select a location',
      contextSelection : 'Select where you want to add this letter',
      letterDuplicate: 'Select where you want to duplicate this letter',
      moveContext :'Select where you want to move this letter',
      existFolder : 'Existing folder',
      createFolder : 'Create new folder',
      folderName : 'Folder name',
      addExistFolder : 'Add to existing folder',
      letterName: 'Letter name',
      letterPlaceholder: 'Letter Name',
      reviewcreateheader : 'Create new engagement template',
      reviewtemplatename: 'Engagement template name',
      reviewplacholder: 'Enter template name',
      reviewcontextselection: 'Select where you want to add this engagement template',
      reviewDuplicateselection: 'Select where you want to duplicate this engagement template',
      reviewmovecontent: 'Select where you want to move this engagement template',
      worksheetcreateheader: 'Create new worksheet',
      worksheettemplatename: 'Worksheet template name',
      worksheetplacholder: 'Enter template name',
      worksheetName: 'Worksheet name',
      worksheetcontextselection: 'Select where you want to add this worksheet template',
      worksheetDuplicate: 'Select where you want to duplicate this worksheet',
      worksheetmovecontent: 'Select where you want to move this worksheet template',
    }

    public constStrings :any = {
      eL : 'Enagement Letter',
      checkList: 'CheckList',
      folderSuccessFirm: 'folder successfully created in firm templates',
      ReviewFirm: 'Engagement left flow',
      WorksheetFirm: 'Worksheet'
    }


    public radioGroupArray :Array<any> = [
      {'radioId':7, 'radioName': 'Existing folder'},
      {'radioId':9,'radioName': 'Create new folder'}
    ]

    public deleteLetter :Object = {
      deleteHeader: 'Delete Letter',
      deleteContext: 'You are trying to delete an engagement letter from firm templates. Are you sure you want to continue?',
      deleteFldrHeader:'Delete Folder',
      deleteFldrContext: 'You are trying to delete an folder from firm templates. Are you sure you want to continue?'
    }

    public deleteMateriality :Object = {
      deleteHeader: 'Delete Materiality',
      deleteContext: 'You are trying to delete a materiality from firm templates. Are you sure you want to continue?',
    }

    public reviewdeleteLetter : any = {
      deleteHeader: 'Delete engagement template',
      deleteContext: 'You are trying to delete an engagement template under firm templates. Are you sure you want to continue?',
      Successmessage : 'Engagement template(s) deleted successfully'
    }

    public reviewSaveChanges : object = {
      header: 'Save Changes to template',
      content: 'You have unsaved changes to template. Are you sure you want to save the changes ?'
    }

    public messages :any = {
      moveWorksheet : 'Worksheet Moved Successfully',
      duplicateWorksheet :'Successfully duplicated worksheet to a new folder',
      folderDelete : 'Folder Deleted Successfully',
      templateDelete : 'Successfully deleted worksheet template'
    }

    public defaultDialog :any = {
      header: 'Save Changes to default template',
      Context: `The changes made to the default template will be reflected to all the
       new engagements going forward and won’t be applied to existing engagements. Do you want to continue?`
    }

    public errorMessage :any = {
      letterExists: 'Template name already exisits, please enter a different name',
      folderExists:  'Folder Name already exists',
      ReviewfolderExists: "Folder Name already exists, please try with different name",
      WorksheetFolderExists: "Folder name already exists, please try with a different name",
      worksheetTemplateExists: "Worksheet with the name already exists, please try with a different name",
      fsNoteNameExists: "FS Note with the name already exists, please try with a different name"
    }

    public engDialogs :any = {
      removeHeader : 'Remove signature?',
      removeContext : 'Are you sure you wish to remove signature from the letter?',
      signHeader : 'Choose date',
      signContext : 'Choose a signoff date:',
      overwriteHeader : 'Overwrite engagement Letter',
      overwriteContext : `You already have a letter added under engagement letter section by upload as a file.
       If you wish to continue this will delete all other letters. Do you want to continue?`,
      DeleteContent1 : 'You are trying to delete a Engagement Letter',
      DeleteContent2 : 'Are you sure you want to continue ?'
    }

    public engLetter : any = {
      saveHeader : 'Save changes',
      saveContext : `There are unsaved changes in the engagement letter. Are you sure, you want to discard changes?`,
      deleteHeader : 'Delete section',
      deleteContext : `You are trying to delete a section from the letter. Are you sure you want to continue?`,
      removeHeader : 'Remove letter',
      removeContext : `You are trying to remove an engagement letter. Are you sure you want to continue?`,
      cancelHeader : 'Cancel the Template',
      cancelContext : 'Changes made must be lost. Are you sure, you want to discard changes?',
      signHeader : 'Select a signee',
      signContext : 'Who is signing the letter?'
    }


}
