import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericResModel } from 'countable@model';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IntegrationSourceInterface } from '../model/eng/integration-source.interface';
import { IntegrationInterface } from '../model/integration/integration.interface';
import { OauthDisconnectModel } from '../model/oauth/oauth-disconnect.model';
import { OauthKickOffModel } from '../model/oauth/oauth-kick-off.model';

@Injectable({
  providedIn: 'root'
})
export class IntegrationApiService {

  private static readonly oauthBaseUri = environment.api + '/oauth';
  private static readonly integrationBaseUri = environment.apiV1 + '/integration';

  constructor(private httpClient: HttpClient) {}

  public async fetchAccountingIntegration(code: number): Promise<IntegrationInterface> {
    return await this.httpClient
      .get<IntegrationInterface>(IntegrationApiService.integrationBaseUri + '/get/' + code, {headers: {responseType: 'json'}})
      .toPromise();
  }

  public async fetchAccountingIntegrations(): Promise<IntegrationInterface[]> {
    return await this.httpClient.get<IntegrationInterface[]>(IntegrationApiService.integrationBaseUri + '/get/accounting', {headers: {responseType: 'json'}}).toPromise();
  }

  public async fetchAccountingIntegrationByClientId(clientId: number): Promise<IntegrationSourceInterface> {
    return await this.httpClient
      .post<IntegrationSourceInterface>(IntegrationApiService.integrationBaseUri + '/get/client', clientId, {responseType: 'json'})
      .toPromise();
  }

  public async fetchAccountingIntegrationByEngagement(engagementId: number): Promise<IntegrationSourceInterface> {
    return await this.httpClient
      .post<IntegrationSourceInterface>(IntegrationApiService.integrationBaseUri + '/get/engagement', engagementId, {responseType: 'json'})
      .toPromise();
  }

  public async fetchSageOrganizationDetails(clientId: number): Promise<any> {
    return await this.httpClient
      .post<any>(IntegrationApiService.integrationBaseUri + '/get/organizations', clientId, {responseType: 'json'})
      .toPromise();
  }

  public async saveSageOrganizationDetails(orgDetails): Promise<any> {
    return await this.httpClient
      .put<any>(IntegrationApiService.integrationBaseUri + '/save/organizations', orgDetails)
      .toPromise();
  }

  public async disconnect(data: OauthDisconnectModel): Promise<boolean> {
    return await this.httpClient
      .post<boolean>(IntegrationApiService.integrationBaseUri + '/disconnect', data)
      .toPromise();
  }

  public oauthKickOff(data: OauthKickOffModel): Observable<GenericResModel<string>> {
    return this.httpClient.post<GenericResModel<string>>(IntegrationApiService.oauthBaseUri + '/connect', data, {responseType: 'json'});
  }

  public checkConnection(data: { source: number, hash: string }): Observable<boolean> {
    return this.httpClient.post<boolean>(IntegrationApiService.integrationBaseUri + '/check-connection', data, {responseType: 'json'});
  }

  public connectEngagementToSource(engagementsId: any, clientFirmId: any): Observable<GenericResModel<boolean>> {
    const data = {
      'engagementsid': engagementsId,
      'clientfirmid': clientFirmId
    };
    return this.httpClient.post<GenericResModel<boolean>>(environment.apiV1 + '/connect-engagement-to-source', data, {responseType: 'json'});
  }

  public fetchSsoProviders(): Observable<GenericResModel<number[]>> {
    return this.httpClient.get<GenericResModel<number[]>>(IntegrationApiService.integrationBaseUri + '/sso-provider');
  }

  public disconnectSsoProvider(integration: number): Observable<GenericResModel<void>> {
    return this.httpClient.delete<GenericResModel<void>>(IntegrationApiService.integrationBaseUri + '/sso-provider', {body: integration});
  }
}

