import { AiDownloadModel, CategoryModel, ChatHistoryOLDModel, ChatListModel, ChatLucaMsgModel, ChatsModel, ChatUserMsgModel, EngagementIntegrationInfo, GlGroupModel, GlSubGroupModel, GlTbModel, LsAccountsModel, LucaEngListModel, LucaFileModel, PROMPT_MSG_TYPES, SavedChatHistoryModel, SORT_OPTIONS } from 'countable@model';
import { FileDataModel } from '../model/docrepo/file-data.model';
import { IntegrationSourceInterface } from '../model/eng/integration-source.interface';

export class AiUtility {
  static getEngIntegrationInfo(tenantInfo: IntegrationSourceInterface) {
    const integrationDetails: EngagementIntegrationInfo = new EngagementIntegrationInfo();
    if (tenantInfo.integrationId === 1 && tenantInfo.isActive === true) {
      integrationDetails.integrationName = 'QuickBooks';
      integrationDetails.logoPath = '/assets/images/quickbook/active-qb.png';
      integrationDetails.integrationId = 1;
      integrationDetails.orgName = tenantInfo.organization;
      integrationDetails.isActiveConnection = true;
      integrationDetails.isConnected = tenantInfo.isConnected;
      integrationDetails.sourceName = 'QUICKBOOK';
    } else if (tenantInfo.integrationId === 1 && tenantInfo.isActive === false) {
      integrationDetails.integrationName = 'QuickBooks';
      integrationDetails.logoPath = '/assets/images/quickbook/error-qb.png';
      integrationDetails.integrationId = 1;
      integrationDetails.orgName = tenantInfo.organization;
      integrationDetails.isActiveConnection = false;
      integrationDetails.isConnected = tenantInfo.isConnected;
      integrationDetails.sourceName = 'QUICKBOOK';
    } else if (tenantInfo.integrationId === 2 && tenantInfo.isActive === true) {
      integrationDetails.integrationName = 'Xero';
      integrationDetails.logoPath = '/assets/images/xero-img.png';
      integrationDetails.integrationId = 2;
      integrationDetails.orgName = tenantInfo.organization;
      integrationDetails.isActiveConnection = true;
      integrationDetails.isConnected = tenantInfo.isConnected;
      integrationDetails.sourceName = 'XERO';
    } else if (tenantInfo.integrationId === 2 && tenantInfo.isActive === false) {
      integrationDetails.integrationName = 'Xero';
      integrationDetails.logoPath = '/assets/images/xero/error-xero1.png';
      integrationDetails.integrationId = 2;
      integrationDetails.orgName = tenantInfo.organization;
      integrationDetails.isActiveConnection = false;
      integrationDetails.isConnected = tenantInfo.isConnected;
      integrationDetails.sourceName = 'XERO';
    } else if (tenantInfo.integrationId === 4 && tenantInfo.isActive === true) {
      integrationDetails.integrationName = 'Sage';
      integrationDetails.logoPath = 'assets/images/sage/Sage-logo.png';
      integrationDetails.integrationId = 4;
      integrationDetails.orgName = tenantInfo.organization;
      integrationDetails.isActiveConnection = true;
      integrationDetails.isConnected = tenantInfo.isConnected;
      integrationDetails.sourceName = 'SAGE';
    } else if (tenantInfo.integrationId === 4 && tenantInfo.isActive === false) {
      integrationDetails.integrationName = 'Sage';
      integrationDetails.logoPath = 'assets/images/sage/error-sage.png';
      integrationDetails.integrationId = 4;
      integrationDetails.orgName = tenantInfo.organization;
      integrationDetails.isActiveConnection = false;
      integrationDetails.isConnected = tenantInfo.isConnected;
      integrationDetails.sourceName = 'SAGE';
    }
    return integrationDetails;
  }

  static sortByEngCreatedDate(data: LucaEngListModel[], order: 'ASC' | 'DSC' = 'ASC'): LucaEngListModel[] {
    return data.sort((a, b) => {
      const dateA = new Date(a.engCreatedDate).getTime();
      const dateB = new Date(b.engCreatedDate).getTime();
      return order === 'ASC' ? dateA - dateB : dateB - dateA;
    });
  }

  static sortByYearEnd(data: LucaEngListModel[], order: 'ASC' | 'DSC' = 'ASC'): LucaEngListModel[] {
    return data.sort((a, b) => {
      const dateA = new Date(a.yearEnd).getTime();
      const dateB = new Date(b.yearEnd).getTime();
      return order === 'ASC' ? dateA - dateB : dateB - dateA;
    });
  }

  static isToday = (date: Date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  };

  static isYesterday = (date: Date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date.getDate() === yesterday.getDate() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getFullYear() === yesterday.getFullYear();
  };

  static isLastWeek = (date: Date) => {
    const today = new Date();
    const lastWeekStart = new Date();
    lastWeekStart.setDate(today.getDate() - today.getDay() - 7);
    const lastWeekEnd = new Date(lastWeekStart);
    lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
    return date >= lastWeekStart && date <= lastWeekEnd;
  };

  static isThisMonth = (date: Date) => {
    const today = new Date();
    return date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  };

  static isLastMonth = (date: Date) => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    return date.getMonth() === lastMonth.getMonth() &&
      date.getFullYear() === lastMonth.getFullYear();
  };

  static emptyChatByDate() {
    return new Map<string, ChatsModel[]>([
      ['Today', []],
      ['Yesterday', []],
      ['Last Week', []],
      ['This Month', []],
      ['Last Month', []]
    ]);
  }

  static categorizeDataToMap = (data: any[]) => {
    const categorizedMap = this.emptyChatByDate();

    data.forEach(item => {
      const createdAt = new Date(item.createdAt);

      if (this.isToday(createdAt)) {
        categorizedMap.get('Today')?.push(item);
      } else if (this.isYesterday(createdAt)) {
        categorizedMap.get('Yesterday')?.push(item);
      } else if (this.isLastWeek(createdAt)) {
        categorizedMap.get('Last Week')?.push(item);
      } else if (this.isThisMonth(createdAt)) {
        categorizedMap.get('This Month')?.push(item);
      } else if (this.isLastMonth(createdAt)) {
        categorizedMap.get('Last Month')?.push(item);
      }
    });

    categorizedMap.forEach((items, key) => {
      items.sort((a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime());
    });

    return categorizedMap;
  };

  static convertMapToArray(categorizedMap: Map<string, ChatsModel[]>, currentChatList: ChatListModel[] | null) {
    const safeCurrentChatList = currentChatList ?? [];
    const listInfo = Array.from(categorizedMap, ([key, value]) => ({ key, value }));
    const transformChat = listInfo.map((list, index) => {
        const existingChat = safeCurrentChatList.find(c => c.displayKey === list.key);
        return <ChatListModel>{
            isExpanded: existingChat ? existingChat.isExpanded :  (index === 0),
            displayKey: list.key,
            displayList: list.value,
          id: index + 1
        };
    });
    return structuredClone(transformChat);
}

  static convertNumValue(total: any, isConvertNumber: boolean = false) {
    let updatedTotal = total?.toLocaleString('en-CA', {
      minimumFractionDigits: 2
    });
    if (isConvertNumber) {
      return updatedTotal.replace('-', '(') + updatedTotal.substring(updatedTotal?.length) + ')';
    }
    return updatedTotal;
  }

  static getTimeStamp() {
    const date = new Date();
    return date.toISOString();
  }

  static getUserChatMsg(type: 'USER' | 'SYSTEM_USER' | 'WORKFLOW' | 'SYSTEM_AI', content: string = '', fileInputs: any[] = [], promptInfo: any = null) {
    const history: ChatUserMsgModel = new ChatUserMsgModel();
    history.messageFrom = 'user';
    history.messageType = type;
    history.userPrompt = content;
    history.timestamp = this.getTimeStamp();
    history.userInputFileNames = fileInputs;
    if (type === 'SYSTEM_AI' && !!promptInfo) {
      history.specialPromptMessage = 'CAAS_PREFERENCES';
      history.specialPromptData = structuredClone(promptInfo);
    }
    return structuredClone(history);
  }

  static getAiChatMsg(type: 'USER_PROMPT_INPUT' | 'SYSTEM_PROMPT_RES_LOADING' | 'SYSTEM_PROMPT_RES_OUTPUT' | 'WORKFLOW' | 'SYSTEM_USER', content: string = '') {
    switch (type) {
      case 'SYSTEM_PROMPT_RES_LOADING':
      case 'SYSTEM_PROMPT_RES_OUTPUT':
      case 'WORKFLOW':
        const historyObj: ChatLucaMsgModel = new ChatLucaMsgModel();
        historyObj.messageFrom = 'luca';
        historyObj.lucaMarkdownResponse = content;
        historyObj.lucaResponse = content;
        historyObj.lucaOutputFilePath = '';
        historyObj.lucaResponseHtml = '';
        historyObj.lucaResponseType = 'text';
        historyObj.outputTokens = 0;
        historyObj.messageType = type !== 'WORKFLOW' ? (type === 'SYSTEM_PROMPT_RES_LOADING' ? 'SYSTEM_AI' : 'ASSISTANT') : 'WORKFLOW';
        historyObj.showSpinner = type === 'SYSTEM_PROMPT_RES_LOADING';
        return historyObj;
    }
  }

  static setWorkFlowInfo(type: PROMPT_MSG_TYPES) {
    const history: ChatLucaMsgModel = new ChatLucaMsgModel();
    history.messageFrom = 'luca';
    history.specialPromptMessage = type;
    history.promptType = type;
    history.messageType = 'WORKFLOW';
    return structuredClone(history);
  }

  static getChatApiObj(history: SavedChatHistoryModel = null) {
    const updatedHistory = history?.messages?.filter(h => h?.messageType === 'USER' || h?.messageType === 'ASSISTANT');
    const transformHistory: ChatHistoryOLDModel[] = [];
    updatedHistory?.forEach((h: any) => {
      const chat: ChatHistoryOLDModel = new ChatHistoryOLDModel();
      if (h?.messageType === 'USER') {
        chat.role = 'user';
        chat.content = h?.userPrompt;
        chat.isShowSpinner = false;
        transformHistory.push(chat);
      }
      if (h?.messageType === 'ASSISTANT') {
        const chat: ChatHistoryOLDModel = new ChatHistoryOLDModel();
        chat.isShowSpinner = false;
        chat.content = h?.lucaMarkdownResponse ? h?.lucaMarkdownResponse : h?.lucaResponseHtml;
        chat.role = 'assistant';
        transformHistory.push(chat);
      }
    });
    return transformHistory;
  }

  static getSortType(type: 'YEAR' | 'DATE', mode: 'ASC' | 'DSC' | 'DEF' | 'NONE') {
    const sortInfo = SORT_OPTIONS?.filter(s => s?.sort === type)?.find(s => s?.sortType === mode);
    return structuredClone(sortInfo);
  }

  static getAppropriateExtension = (Obj: AiDownloadModel) => {
    if (Obj?.exportType === 'PDF') {
      return '.pdf';
    }
    if (Obj?.exportType === 'XLSX') {
      return '.xlsx';
    }
    if (Obj?.exportType === 'DOCX') {
      return '.docx';
    }
  };

  static getSortActionType(sortType: 'ASC' | 'DSC' | 'DEF' | 'NONE'): 'ASC' | 'DSC' | 'DEF' | 'NONE' {
    if (sortType === 'DEF') {
      return 'ASC';
    }
    if (sortType === 'ASC') {
      return 'DSC';
    }
    if (sortType === 'DSC' || sortType === 'NONE') {
      return 'DEF';
    }
    return 'NONE';
  }

  static convertToLucaFileModel(data: FileDataModel[]): LucaFileModel[] {
    return data.map(fileData => {
      const lucaFile = new LucaFileModel();
      lucaFile.file = null; // Assuming file is not present in FileDataModel
      lucaFile.filePath = fileData.filepath;
      lucaFile.fileName = fileData.filename;
      lucaFile.size = null; // Assuming size is not present in FileDataModel
      lucaFile.fileContent = []; // Assuming fileContent is not present in FileDataModel
      lucaFile.isInProgress = true; // Assuming isInProgress is not present in FileDataModel
      lucaFile.hasUploaded = true;
      lucaFile.extension = fileData.extension;
      lucaFile.fileType = ''; // Assuming fileType is not present in FileDataModel
      lucaFile.progressPercent = 100; // Assuming progressPercent is not present in FileDataModel
      return lucaFile;
    });
  }

  static convertFilesToSaveInfo(files: File[]) {
    let updatedFiles: LucaFileModel[] = [];
    files?.forEach(f => {
      const file: LucaFileModel = new LucaFileModel();
      file.size = f?.size / 1024;
      file.fileName = f?.name;
      file.fileType = f?.type;
      file.file = f;
      if ((updatedFiles && updatedFiles?.length > 0)) {
        updatedFiles.push(file);
      } else {
        updatedFiles = [file];
      }
    });
    return structuredClone(updatedFiles);
  }

  static formatNumber(value: any) {
    if (!value) {
      return 0;
    }
    if (typeof value === 'string' && value.includes(',')) {
      return value.replace(/,/g, '');
    }
    return value;
  }

  static parseAndReturnAbs(input: any) {
    let cleanedInput = input.replace(/,/g, '');
    if (cleanedInput.startsWith('(') && cleanedInput.endsWith(')')) {
      cleanedInput = '-' + cleanedInput.slice(1, -1);
    }
    return Math.abs(parseFloat(cleanedInput));
  }

  static calculateCB1(OB1: any, ADD: any, DIS: any) {
    const OB1Num = this.parseAndReturnAbs(OB1);
    const ADDNum = this.parseAndReturnAbs(ADD);
    const DISNum = this.parseAndReturnAbs(DIS);
    let result = OB1Num + ADDNum - DISNum;

    return result < 0
      ? `(${Math.abs(result).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})})`
      : result.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
  }

  static performCalculation(valueA: any, valueB: any, type: 'ADD' | 'SUB' = 'SUB'): string {
    const valueANum = this.parseAndReturnAbs(valueA);
    const valueBNum = this.parseAndReturnAbs(valueB);

    let result = 0;
    if (type === 'ADD') {
      result = valueANum + valueBNum;
    } else if (type === 'SUB') {
      result = valueANum - valueBNum;
    }
    return result < 0
      ? `(${Math.abs(result).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})})`
      : result.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
  }

  static formatToNumber(value: string): number {
    if (!value || value === '0.00' || value === '0') {
      return 0.00;
    }
    let cleanedValue = value.replace(/,/g, '');
    const isNegative = /\(([^)]+)\)/.test(cleanedValue);
    if (isNegative) {
      cleanedValue = cleanedValue.replace(/[()]/g, '');
    }
    let numberValue = parseFloat(cleanedValue);
    if (isNegative) {
      numberValue = -numberValue;
    }
    return parseFloat(numberValue.toFixed(2));
  }

  static convertUtcToUtcMinus4(dateString: string): string {
    const utcDate = new Date(dateString);
    const utcMinus4Date = new Date(utcDate.getTime() - (4 * 60 * 60 * 1000)); // Subtract 4 hours
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };
    return new Intl.DateTimeFormat('en-CA', options).format(utcMinus4Date);
  }

  static hasVrInputsValid(percent: string, value: string) {
    return this.isValidNumber(percent) || this.isValidNumber(value);
  }

  static isValidNumber(value: string): boolean {
    const num = parseFloat(value);
    return !isNaN(num) && num > 0;
  }

  static checkIsDeactivated(date: string = '') {
    if (!date) {
      return false;
    }
    const today = new Date();
    const todayEnd = new Date(today.setHours(23, 59, 59, 999)); // Today's end time (11:59:59 PM)
    const deactivatedDate = new Date(date);
    return (this.isSameDay(deactivatedDate, new Date()) && deactivatedDate <= todayEnd);

  }

  static isSameDay(d1: Date, d2: Date): boolean {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  static provideInitials(name: string = '') {
    if (!name) {
      return '';
    }
    const words = name.trim().split(/\s+/);
    let initials = '';
    for (const word of words) {
      if (word.length > 0) {
        initials += word[0].toUpperCase();
      }
      if (initials.length === 2) {
        break;
      }
    }
    return initials;
  }

  static setAttachedFiles(files: File[]) {
    const fileMap = new Map<string, File>([]);
    files?.forEach(f => {
      fileMap.set(f?.name, f);
    });
    return fileMap;
  }

  static getPromptId(promptId: number = 0) {
    switch (promptId) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 6:
        return promptId;
      case 8:
        return 6;
      case 7:
      case 9:
      default:
        return 8;
    }
  }

  static formatAndTransformValue(input: string | number): string {
    let numberStr = String(input).trim();
    if (!numberStr || isNaN(Number(numberStr.replace(/,/g, '').replace(/[()]/g, '')))) {
      return '0.00';
    }
    let isNegative = false;
    if (numberStr.startsWith('(') && numberStr.endsWith(')')) {
      isNegative = true;
      numberStr = numberStr.slice(1, -1); // Remove brackets
    }
    numberStr = numberStr.replace(/,/g, '');
    let number = parseFloat(numberStr);
    if (isNaN(number) || number === 0) {
      return '0.00';
    }
    let formattedNumber = number.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    if (isNegative || number < 0) {
      formattedNumber = `(${formattedNumber.replace('-', '')})`;
    }
    return formattedNumber;
  }

  static filterSortGroups(tbRows: GlTbModel[] = []) {
    const filteredRows = tbRows?.map(item => <GlGroupModel>{
      groupId: item?.fingroupid,
      groupName: item?.fingroupname
    });
    const uniqueRows = filteredRows?.filter((value, index, self) =>
      index === self.findIndex((t) => t.groupId === value.groupId)
    );
    const sortRows = uniqueRows?.sort((a, b) => a.groupId - b.groupId);
    return structuredClone(sortRows) ?? [];
  }

  static filterSortSubGroups(tbRows: GlTbModel[] = []) {
    const filteredRows = tbRows?.map(item => <GlSubGroupModel>{
      subGroupId: item?.finsubgroupid,
      subGroupName: item?.finsubgroupname,
      groupId: item?.fingroupid
    });
    const uniqueRows = filteredRows?.filter((value, index, self) =>
      index === self.findIndex(v => v.groupId === value.groupId && v.subGroupId === value.subGroupId)
    );
    const sortRows = uniqueRows?.sort((a, b) => a.subGroupId - b.subGroupId);
    return structuredClone(sortRows) ?? [];
  }

  static filterSortLs(tbRows: GlTbModel[] = []) {
    const filteredRows = tbRows?.map(item => <LsAccountsModel>{
      lsName: item?.leadsheetname,
      lsCode: item?.leadsheetcode,
      lsId: item?.leadsheetid,
      subGroupId: item?.finsubgroupid,
      groupId: item?.fingroupid
    });
    const uniqueRows = filteredRows?.filter((item, index, self) =>
        index === self.findIndex(
          (t) => t.lsId === item.lsId && t.subGroupId === item.subGroupId && t.groupId === item.groupId
        )
    );
    const sortRows = uniqueRows?.sort((a, b) => a.lsId - b.lsId);
    return structuredClone(sortRows);
  }

  static filterSortAccounts(tbRows: GlTbModel[] = []) {
    const filteredRows = tbRows?.map(item => <LsAccountsModel>{
      accountCode: item?.accountcode,
      accountName: item?.accountname,
      trialBalId: item?.trailbalanceid,
      finalAmount: item?.finalamount,
      lsId: item?.leadsheetid,
      subGroupId: item?.finsubgroupid,
      groupId: item?.fingroupid
    });
    const uniqueRows = filteredRows?.filter((item, index, self) =>
      index === self.findIndex((t) => t.trialBalId === item.trialBalId)
    );
    const sortRows = uniqueRows?.sort((a, b) => a.trialBalId - b.trialBalId);
    return structuredClone(sortRows);
  }

  static getGroupsFromGl(tbRows: GlTbModel[] = [], groups: number[]) {
    return tbRows?.filter(r => groups?.includes(r?.fingroupid));
  }

  static getSubGroupsFromGl(tbRows: GlTbModel[] = [], subGroups: GlSubGroupModel[] = []) {
    const groups = subGroups?.map(r => r?.groupId);
    const filterGroups = this.getGroupsFromGl(tbRows, groups);
    const subGroup = subGroups?.map(r => r?.subGroupId);
    return filterGroups?.filter(r => subGroup?.includes(r?.finsubgroupid));
  }

  static getLsFromGl(tbRows: GlTbModel[] = [], lsRows: LsAccountsModel[] = []) {
    const groups = lsRows?.map(r => r?.groupId);
    const filterGroups = this.getGroupsFromGl(tbRows, groups);
    const subGroup = lsRows?.map(r => r?.subGroupId);
    const filterSubGroups = filterGroups?.filter(r => subGroup?.includes(r?.finsubgroupid));
    const lsIds = lsRows?.map(r => r?.lsId);
    return filterSubGroups?.filter(r => lsIds?.includes(r?.leadsheetid));
  }

  static sortCategories(categories: CategoryModel[]) {
    if (!Array.isArray(categories) || !categories) {
      return [];
    }
    return categories?.sort((a, b) => a?.categoryName?.localeCompare(b?.categoryName));
  }
}
