import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { TextSection } from 'src/app/model/notes/notes-to-fs.model';
import { SharedService } from '../../shared/shared.service';
import * as DOMPurify from 'dompurify';
import { ApiService } from 'src/app/services/api.service';
@Component({
    selector: "app-custom-editor",
    templateUrl: './custom-editor.component.html',
    styleUrls: ['./custom-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class CustomEditorComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() htmlContent: any;
    @Input() isViewMode: boolean;
    @Input() showDelete = false;
    @Input() isInsideWorkbook = false;
    @Output() emitEveryChange = new EventEmitter<TextSection>();
    @Output() deleteSection = new EventEmitter<boolean>();
    @Output() updateTextEdit = new EventEmitter<boolean>();
    selectedLineHeight: any = 1;
    lineSpacingOptions = [1, 1.5, 2, 2.5];
    initialData: TextSection;
    isMasterTab = false;
    dynamicId = this.apiService.makeId(4);

    constructor(public sharedService: SharedService, private apiService: ApiService) { }

    ngOnInit() {
        this.initialData = JSON.parse(JSON.stringify(this.htmlContent));

        this.sharedService.selectedTab$.subscribe(response => {
            this.isMasterTab = (response === 1) ? true : false;
        });
    }

    ngAfterViewInit(): void {
        this.updatingLineHeight(this.dynamicId);
    }
    initializeData() {
        if (this.initialData.isEdit) {
            const div = document.getElementById(this.initialData.id);
            if (div) {
                div.innerHTML = this.initialData.content;
                this.selectedLineHeight = this.initialData.lineSpacing;
                div.style.lineHeight = this.selectedLineHeight;
                if (this.initialData.content === "") {
                    document.getElementById(this.initialData.id).style.border = "1px solid transparent";
                }
            }
        } else {
            setTimeout(() => {
                this.updatingLineHeight(this.dynamicId);
            }, 5);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.htmlContent) {
            if (changes.htmlContent.currentValue) {
                this.initialData = JSON.parse(JSON.stringify(changes.htmlContent.currentValue));
                this.initializeData();
            }
        }
        if (changes.isViewMode) {
            if (this.initialData) {
                this.initialData.isEdit = changes.isViewMode.currentValue;
                if (this.initialData.isEdit) {
                    setTimeout(() => {
                        this.initializeData();
                        this.setFocus(this.initialData.id);
                    });
                } else {
                    setTimeout(() => {
                        this.updatingLineHeight(this.dynamicId);
                    }, 5);
                }
            }
        }
    }

    openInEditMode() {
        if (!this.isMasterTab) {
            this.initialData.isEdit = true;
            setTimeout(() => {
                this.initializeData();
                this.setFocus(this.initialData.id);
                this.updateTextEdit.emit(this.initialData.isEdit);
            });
        }
    }

    emitContent(event: any) {
        if (!(event.inputType === 'insertFromPaste')) {
            this.initialData.content = event.target.innerHTML;
            this.emitEveryChange.emit(this.initialData);
        }
    }

    deleteTextSection() {
        this.deleteSection.emit(true);
    }

    changeLineSpacing(value: any) {
        this.selectedLineHeight = value;
        const div = document.getElementById(this.initialData.id);
        const elements = div.querySelectorAll('p,div,span.h1,h2,h3,h4,h5,h6');
        elements.forEach((tag: any) => {
            tag.style.lineHeight = value + '';
        });
        div.style.lineHeight = value + '';
        this.initialData.lineSpacing = value;
        this.initialData.content = div.innerHTML;
        this.emitEveryChange.emit(this.initialData);
    }

    setFocus(divId: any) {
        const div = document.getElementById(divId);
        setTimeout(function () {
            div.focus();
            window.getSelection().selectAllChildren(div);
            window.getSelection().collapseToEnd();
        }, 0);
    }

    emitTextSectionData() {
        this.emitEveryChange.emit(this.initialData);
    }

    sanitizePaste(event: ClipboardEvent): string {
        event.preventDefault();
        const clipboardData = event.clipboardData || window['clipboardData'];
        let pastedData = clipboardData.getData('text/html') || clipboardData.getData('text');

        // Strip unwanted HTML tags and inline styles
        pastedData = this.stripUnwantedTagsAndStyles(pastedData);

        // Sanitize the content to ensure it is safe
        return DOMPurify.sanitize(pastedData, { FORBID_TAGS: ['style'] });
    }

    stripUnwantedTagsAndStyles(html: string): string {
        const div = document.createElement('div');
        div.innerHTML = html;
        const elements = div.querySelectorAll('*');

        // Remove inline styles
        elements.forEach((element) => element.removeAttribute('style'));

        // Remove unwanted tags: i, em, b, strong
        const unwantedTags = ['i', 'em', 'b', 'strong'];
        unwantedTags.forEach(tag => {
            const tags = div.getElementsByTagName(tag);
            while (tags.length > 0) {
                const parent = tags[0].parentNode;
                while (tags[0].firstChild) {
                    parent.insertBefore(tags[0].firstChild, tags[0]);
                }
                parent.removeChild(tags[0]);
            }
        });

        return div.textContent || div.innerText || '';
    }


    onPaste(event: ClipboardEvent) {
        const cleanData = this.sanitizePaste(event);
        const pasteTarget = event.target as HTMLElement;

        // Insert the clean data at the caret position
        this.insertTextAtCaret(pasteTarget, cleanData);
        setTimeout(() => {
            const div = document.getElementById(this.initialData.id);
            if (div) {
                this.initialData.content = div.innerHTML;
            }
            this.emitEveryChange.emit(this.initialData);
        }, 5);
    }

    insertTextAtCaret(el: HTMLElement, text: string) {
        let range, node;
        if (window.getSelection && window.getSelection().getRangeAt) {
            range = window.getSelection().getRangeAt(0);
            range.deleteContents();
            node = document.createTextNode(text);
            range.insertNode(node);

            // Move the caret to the end of the inserted text node
            range.setStartAfter(node);
            range.collapse(true);
            const selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        }
    }

    updatingLineHeight(id: string) {
        const eleId = id ? this.initialData.id + id : this.initialData.id;
        const div = document.getElementById(eleId);
        if (div) {
            div.innerHTML = this.initialData.content;
            this.selectedLineHeight = this.initialData.lineSpacing;
            div.style.lineHeight = this.selectedLineHeight + '';
            const elements = div.querySelectorAll('p,div,span.h1,h2,h3,h4,h5,h6');
            elements.forEach((tag: any) => {
                tag.style.lineHeight = this.selectedLineHeight + '';
            });
            if (this.initialData.content === "" && !id) {
                document.getElementById(this.initialData.id).style.border = "1px solid transparent";
            }
        }
    }

}
