import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { UserDetailModel } from 'countable@model';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { AccessItem, AclModel } from '../model/acl.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AclService implements OnDestroy {

  private static readonly ACL_KEY = 'acl-cache';
  private static readonly URI_ACL = environment.apiV1 + '/user';

  public readonly subject: BehaviorSubject<AclModel> = new BehaviorSubject(null);

  public aclModel: AclModel = new AclModel();
  private readonly authSub: Subscription;

  constructor(private http: HttpClient, private authService: AuthService) {
    if (localStorage.getItem(AclService.ACL_KEY)) {
      this.aclModel = JSON.parse(localStorage.getItem(AclService.ACL_KEY));
    }

    this.authSub = this.authService.subject.subscribe(userDetail => {
      if (userDetail) {
        this.adjustPermissionsByUserDetail(this.authService.getUserDetail());
        localStorage.setItem(AclService.ACL_KEY, JSON.stringify(this.aclModel));
        this.refresh().subscribe(res => { this.subject.next(this.aclModel); });
      }
    });
  }

  public refresh(): Observable<AclModel> {
    return this.getAcl();
  }

  ngOnDestroy(): void {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  private getAcl(): Observable<AclModel> {
    return this.http.get<AccessItem[]>(AclService.URI_ACL + '/acl')
      .pipe(map(acl => this.processAcl(acl)));
  }

  private processAcl(acl: AccessItem[]): AclModel {
    if (!this.aclModel) {
      this.aclModel = new AclModel();
    }
    this.aclModel.permissionList = acl;

    acl.forEach((x) => {
      switch (x.accesstypeitemufi) {
        case 1:
          this.aclModel.hasDashboard = !!x.ufid;
          break;
        case 2:
          this.aclModel.hasFirmInformationEdit = !!x.ufid;
          break;
        case 3:
          this.aclModel.hasBilling = !!x.ufid;
          break;
        case 4:
          this.aclModel.hasExportData = !!x.ufid;
          break;
        case 5:
          this.aclModel.hasTeamMemberAdd = !!x.ufid;
          break;
        case 6:
          this.aclModel.hasTeamMemberModifyOther = !!x.ufid;
          break;
        case 7:
          this.aclModel.hasTeamMemberDelete = !!x.ufid;
          break;
        case 8:
          this.aclModel.hasClientAdd = !!x.ufid;
          break;
        case 9:
          this.aclModel.hasClientView = !!x.ufid;
          break;
        case 10:
          this.aclModel.hasClientModify = !!x.ufid;
          break;
        case 11:
          this.aclModel.hasClientDelete = !!x.ufid;
          break;
        case 12:
          this.aclModel.hasEngView = !!x.ufid;
          break;
        case 13:
          this.aclModel.hasEngAdd = !!x.ufid;
          break;
        case 14:
          this.aclModel.hasEngModify = !!x.ufid;
          break;
        case 15:
          this.aclModel.hasEngDelete = !!x.ufid;
          break;
        case 16:
          this.aclModel.hasEngArchive = !!x.ufid;
          break;
        case 17:
          this.aclModel.hasEngReOpen = !!x.ufid;
          break;
        case 18:
          this.aclModel.hasTemplateViewAll = !!x.ufid;
          break;
        case 19:
          this.aclModel.hasTemplateAddEditDeleteOwn = !!x.ufid;
          break;
        case 20:
          this.aclModel.hasTemplateEditDeleteAll = !!x.ufid;
          break;
        case 21:
          this.aclModel.hasTimeSummaryView = !!x.ufid;
          break;
        case 22:
          this.aclModel.hasEngEditAssignedEng = !!x.ufid;
          break;
        case 23:
          this.aclModel.hasEngExportAssignedEng = !!x.ufid;
          break;
        case 24:
          this.aclModel.hasEngEditAll = !!x.ufid;
          break;
        case 25:
          this.aclModel.hasEngExportAll = !!x.ufid;
          break;
        case 999:
          this.aclModel.hasEngViewEdit = !!x.ufid;
          break;
      }
    });

    localStorage.setItem(AclService.ACL_KEY, JSON.stringify(this.aclModel));
    return this.aclModel;
  }

  private adjustPermissionsByUserDetail(userDetail: UserDetailModel): void {
    this.aclModel.hasBilling = (userDetail.userroleId != 10 && userDetail.useraccesstypeid === 2) || userDetail.isbillingenabled;
    this.aclModel.isSuperAdmin = userDetail.useraccesstypeid === 2;
    this.aclModel.userAccessTypeId = userDetail.useraccesstypeid;
    this.aclModel.isAdmin = userDetail.useraccesstypeid === 3;
    this.aclModel.isClient = userDetail.userroleId === 4;
  }
}
