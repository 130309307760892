import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RoutePath } from 'countable@helpers';
import { UserDetailModel, UserModel } from 'countable@model';
import { Observable, Subscription } from 'rxjs';
import { ReviewService } from 'src/app/services/review.service';
import { AclModel } from '../../../model/acl.model';
import { BillingModel } from '../../../model/billing/billing.model';
import { FirmModel } from '../../../model/firm/firm.model';
import { AclService } from '../../../services/acl.service';
import { AuthService } from '../../../services/auth.service';
import { BillingService } from '../../../services/billing.service';
import { FirmService } from '../../../services/firm.service';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit, OnDestroy {
  @Input('permissions') permissions: any;

  activePage: any = '';
  routeSubscription: any;
  isTrackerOn: number;
  accessPermissions: any;
  savechangesstatus: boolean = false;
  public mfamandtory: boolean = false;
  firm: FirmModel = null;
  billing: BillingModel;
  user: UserModel;
  userDetail: UserDetailModel;
  acl: AclModel;
  private onOffSub: Subscription;
  private refreshPEGPACTSubscription: Subscription;
  private savechangesstatusSub: Subscription;
  private viewpageSub: Subscription;
  private mfamandtorySub: Subscription;
  private readonly billingSub: Subscription;
  private readonly firmSubject: Subscription;
  private readonly authSubject: Subscription = null;
  private readonly permissionsSub: Subscription;
  private readonly aclSubject: Subscription;
  public disableAccess : boolean = false;

  constructor(private billingService: BillingService, private firmService: FirmService, private router: Router,private dialog: MatDialog,public sharedService: SharedService,
              private sharedServices: SharedService, private ReviewService: ReviewService, private authService: AuthService, private aclService: AclService) {

    this.aclSubject = this.aclService.subject.subscribe(acl => {
      this.acl = acl;
    });

    this.authSubject = this.authService.subject.subscribe(state => {
      this.user = this.authService.getUser();
      this.userDetail = this.authService.getUserDetail();
    });

    this.billingSub = this.billingService.subject.subscribe(billing => {
      this.billing = billing;
    });

    this.firmSubject = this.firmService.subject.subscribe(firm => {
      this.firm = firm;
    });

    this.permissionsSub = this.sharedServices.accessPermissionsSubject.subscribe(res => {
      if (res) {
        this.accessPermissions = res;
      }
    });
  }

  ngOnInit() {
    this.isTrackerOn = +localStorage.getItem('istimetrackingon');
    this.onOffSub = this.sharedServices.updateTrackerOnOff.subscribe(isOnOff => this.isTrackerOn = isOnOff);

    this.refreshPEGPACTSubscription = this.sharedServices.refreshPEGPACTSubscription.subscribe();

    this.routeSubscription = this.sharedServices.routeData.subscribe(data => {
      if (data) {
        let currentRoute = data.url;
        if (currentRoute === '/dashboard/client/client-list' || currentRoute === '/dashboard/client/addclient' || currentRoute === '/dashboard/client/editclient') {
          this.activePage = 'client';
        } else if (currentRoute === '/dashboard/engagement/engagement-list' ||
          currentRoute === '/dashboard/engagement/createengagement' ||
          currentRoute === '/dashboard/engagement/create-engagement' ||
          currentRoute === '/dashboard/engagement/create-engagement/1' ||
          currentRoute === '/dashboard/engagement/create-engagement/2' ||
          currentRoute === '/dashboard/ntrdashboard/ClientOnboarding/ClientAcceptance' ||
          currentRoute === '/dashboard/ntrdashboard/ClientOnboarding/Independence' ||
          currentRoute === '/dashboard/ntrdashboard/ClientOnboarding/Knowledge-Of-clients-business' ||
          currentRoute === '/dashboard/ntrdashboard/ClientOnboarding/Planning' ||
          currentRoute === '/dashboard/ntrdashboard/ClientOnboarding/EngagementLetter' ||
          currentRoute === '/dashboard/ntrdashboard/Procedure' ||
          currentRoute === '/dashboard/ntrdashboard/FinancialStatement/CoverPage' ||
          currentRoute === '/dashboard/ntrdashboard/TrialBalance' ||
          currentRoute === RoutePath.MAERIALITY ||
          currentRoute === RoutePath.REVIEW_CHECKLISTS ||
          currentRoute === RoutePath.ENGAGEMENT_LETTER_VIEW
        ) {
          this.activePage = 'engagement';
        } else if (currentRoute === '/dashboard/myaccount') {
          this.activePage = 'myaccount';
        } else if (currentRoute === '/dashboard/partner/partnerclients' ||
          currentRoute === '/dashboard/importcsv?role=partnerclient' ||
          currentRoute === '/dashboard/engagement/create-engagement' ||
          currentRoute === '/dashboard/engagement/create-engagement/1' ||
          currentRoute === '/dashboard/engagement/create-engagement/2'
        ) {
          this.activePage = 'partnerclients';
        } else if (currentRoute === '/dashboard/members/clients' || currentRoute === '/dashboard/firm/addclients' || currentRoute === '/dashboard/firm/editclients' || currentRoute === '/dashboard/importcsv?role=client') {
          this.activePage = 'firmclients';
        } else if (currentRoute === '/dashboard/members/client-engagements') {
          this.activePage = 'firmclients';
        } else if (currentRoute === '/dashboard/members/Dashboard') {
          this.activePage = 'Dashboard';
        } else if (currentRoute === '/dashboard/members/teammember' || currentRoute === '/dashboard/members/addteammember' || currentRoute === '/dashboard/members/uploadteam' || currentRoute === '/dashboard/firm/editpartner' || currentRoute === '/dashboard/firm/assign-client' || currentRoute === '/dashboard/importcsv?role=partner') {
          this.activePage = 'firmpartners';
        } else if (currentRoute === '/dashboard/clients/Dashboard') {
          this.activePage = 'clientDashboard';
        } else if (currentRoute === '/dashboard/clients/engagements' ||
          currentRoute === '/dashboard/clients/engagement-details/engagement-letters' ||
          currentRoute === '/dashboard/clients/engagement-details/documents' ||
          currentRoute === '/dashboard/clients/engagement-details/financial-statement' ||
          currentRoute === '/dashboard/clients/engagement-details/final-report') {
          this.activePage = 'engagements';
        } else if (currentRoute === '/dashboard/clients/documents') {
          this.activePage = 'documents';
        } else if (currentRoute === '/dashboard/clients/client-settings') {
          this.activePage = 'client-settings';
        } else if (currentRoute === '/dashboard/clients/client-integrations') {
          this.activePage = 'client-integrations';
        } else if (currentRoute === '/dashboard/firm/templates' || currentRoute === '/dashboard/firm/templates/engagement-letters'
          || currentRoute === '/dashboard/firm/templates/headers' || currentRoute === '/dashboard/firm/templates/engagement-details'
          || currentRoute === '/dashboard/firm/templates/header-view' || currentRoute === '/dashboard/firm/templates/footers' || currentRoute === '/dashboard/firm/templates/footer-view' || currentRoute === '/dashboard/firm/templates/engagement-templates' || currentRoute === '/dashboard/firm/templates/signature' || currentRoute === '/dashboard/firm/templates/logos') { this.activePage = 'templates'; } else if ((currentRoute === '/dashboard/firm/settings') || (currentRoute === '/dashboard/firm/settings/teamroles') || (currentRoute === '/dashboard/firm/settings/myaccount')) {
          this.activePage = 'settings';
        } else if ((currentRoute === '/dashboard/members/settings/myaccount#firminfo') || (currentRoute === '/dashboard/members/settings/myaccount#profile') || (currentRoute === '/dashboard/members/settings/myaccount') ||
          (currentRoute === '/dashboard/members/settings/notifications') || (currentRoute === '/dashboard/members/settings/teamaccess') || (currentRoute === '/dashboard/members/settings/billing') || (currentRoute === '/dashboard/members/settings/stripe')) {
          this.activePage = 'settings';
        } else if (currentRoute === ('/dashboard/timetracking/time-summary')) {
          this.activePage = 'timetracking';
        } else { }
      }
    });

    this.savechangesstatusSub = this.ReviewService.savechangesbtnstatus.subscribe(response => {
      this.savechangesstatus = response;
    });

    this.viewpageSub = this.ReviewService.viewPage.subscribe(response => {
      if (response) {
        this.savechangesstatus = false;
        this.ReviewService.savechangesbtnstatus.next(false);
        this.viewPage(response);
      }
    });

    this.mfamandtorySub = this.sharedServices.mandatorymfa.subscribe({
      next: (response) => {
        this.mfamandtory = response;
      }
    });

    this.sharedService.disablekebabmenuopt$.subscribe(response => {
      if (response) {
        this.disableAccess = true;
      }else {
        this.disableAccess = false;
      }
    })
  }
  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return !this.savechangesstatus;
  }
  viewPage(page) {
    this.ReviewService.savechangesbtnstatus.next(this.savechangesstatus);
    const url = window.location.pathname;
    if (page != url) {
      sessionStorage.removeItem('teamTabIndex');
      localStorage.removeItem('selectedTemplateEngObj');
      localStorage.removeItem('selectedEngagementObj');
      localStorage.removeItem('selectedClientDetails');
      localStorage.removeItem('templateEngTemplateLetter');
      localStorage.removeItem('tempSection');
    }
    this.sharedServices.filterEngagements = false;
    this.sharedServices.selectedClientId = 0;
    this.router.navigate([page]);
    if (page == '/dashboard/firm/templates/engagement-templates' && this.router.url != '/dashboard/firm/templates/engagement-templates') {
      setTimeout(() => {
        this.sharedServices.onloadChecklist.next(true);
      }, 0);
    }

  }

    openHomePage() {
        window.open('https://countable.co/', "_blank");
    }

  ngOnDestroy() {
    this.onOffSub ? this.onOffSub.unsubscribe() : '';
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.viewpageSub) {
      this.viewpageSub.unsubscribe();
    }
    if (this.refreshPEGPACTSubscription) {
      this.refreshPEGPACTSubscription.unsubscribe();
    }
    if (this.savechangesstatusSub) {
      this.savechangesstatusSub.unsubscribe();
    }
    if (this.billingSub) {
      this.billingSub.unsubscribe();
    }
    if (this.firmSubject) {
      this.firmSubject.unsubscribe();
    }
    if (this.authSubject) {
      this.authSubject.unsubscribe();
    }
    if (this.permissionsSub) {
      this.permissionsSub.unsubscribe();
    }
    if (this.mfamandtorySub) {
      this.mfamandtorySub.unsubscribe();
    }
    if (this.aclSubject) {
      this.aclSubject.unsubscribe();
    }
  }
}
