import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import { DIALOG_TYPES } from 'countable@helpers';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, map, mergeMap, Observable, of, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { CommonDialogComponent } from '../components/Engagements/NTR_Dashboard/common-dialog/common-dialog.component';
import { BillingModel } from '../model/billing/billing.model';
import { ChangePlanReqModel } from '../model/billing/change-plan-req.model';
import { ChangePlanResModel } from '../model/billing/change-plan-res.model';
import { CurrentInvoiceModel } from '../model/billing/current-invoice.model';
import { FeatureModel } from '../model/billing/feature.model';
import { FlexHistoryResModel } from '../model/billing/flex-history-res.model';
import { FlexUsageModel } from '../model/billing/flex-usage.model';
import { NextPaymentModel } from '../model/billing/next-payment.model';
import { PaymentMethodResModel } from '../model/billing/payment-method-res.model';
import { ProductModel } from '../model/billing/product.model';
import { ReceiptModel } from '../model/billing/receipt.model';
import { FirmDowngradeModel } from '../model/firm/firm-downgrades';
import { GenericResModel } from '../model/generic-res.model';
import { FirmService } from './firm.service';
import { StorageService } from './storage.service';

export const enum BillingStateEnum {
  TRIALING = 'trialing',
  ACTIVE = 'active',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  PAST_DUE = 'past_due',
  UNPAID = 'unpaid'
}

export const enum PaymentFrequencyEnum {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY'
}

export enum PriceType {
  RECURRING, ONE_TIME
}

export enum PricePeriod {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum PricePeriodType {
  MONTHLY = 'MONTHLY',
  YEARLY_15 = 'YEARLY_15',
  YEARLY_20 = 'YEARLY_20',
  YEARLY_25 = 'YEARLY_25',
}

export const pricePeriodMap: Record<PricePeriodType, PricePeriod> = {
  MONTHLY: PricePeriod.MONTHLY,
  YEARLY_15: PricePeriod.YEARLY,
  YEARLY_20: PricePeriod.YEARLY,
  YEARLY_25: PricePeriod.YEARLY
} as const;

export enum Addons {
  FLEX = 'FLEX',
  AI = 'AI'
}

@Injectable({
  providedIn: 'root'
})
export class BillingService implements OnDestroy {

  private static readonly API = environment.apiV2 + '/billing';
  private static readonly BILLING_KEY = 'billing-key';
  private static readonly BILLING_OLD_PRODUCT = 'b-o-p';
  private static readonly BILLING_CHANGE_PLAN_PARAM = 'b-c-p-p';
  public readonly subject: BehaviorSubject<BillingModel> = new BehaviorSubject(null);
  private changePlanParams: ChangePlanReqModel = null;
  private readonly firmSubject: Subscription;

  constructor(private httpClient: HttpClient, private firmService: FirmService, public toaster: ToastrService, private dialog: MatDialog, private router: Router) {
    if (localStorage.getItem(BillingService.BILLING_KEY)) {
      StorageService.applicationModel.billing = JSON.parse(localStorage.getItem(BillingService.BILLING_KEY));
    }
    this.firmSubject = this.firmService.subject.subscribe(firm => {
      if (firm) {
        this.refreshBilling().subscribe();
      }
    });
  }

  public refreshBilling(reload = true): Observable<BillingModel> {
    return this.getPlans().pipe(
      mergeMap(() => this.fetchBillingDetail(reload)),
      map(detail => this.processRefresh(detail))
    );
  }

  public getBillingDetail(): Observable<BillingModel> {
    return this.refreshBilling();
  }

  public getPlans(): Observable<Map<string, ProductModel>> {
    if (StorageService.applicationModel.billingProducts) {
      return of(StorageService.applicationModel.billingProducts);
    }
    return this.httpClient.get<ProductModel[]>(BillingService.API + '/plans')
      .pipe(map(res => {
          return this.filterProducts(res);
        })
      );
  }

  public getFeatures(): Observable<Map<number, string>> {
    if (StorageService.applicationModel.billingFeatures) {
      return of(StorageService.applicationModel.billingFeatures);
    }
    return this.httpClient.get<FeatureModel[]>(BillingService.API + '/feature')
      .pipe(map(res => {
        StorageService.applicationModel.billingFeatures = new Map<number, string>();
        res.forEach(feature => {
          StorageService.applicationModel.billingFeatures.set(feature.id, feature.name);
        });
        return StorageService.applicationModel.billingFeatures;
      }));
  }

  public canSwitchToMonthlyPlan(): boolean {
    const billing = StorageService.applicationModel.billing;
    console.log('billing.service: canSwitchToMonthlyPlan: ', billing);
    if (!billing || !billing.pricePeriodType) {
      return false;
    }
    if (billing.isCanceled || billing.isOnTrial || (billing.isPaused && billing.eligibleForSupercharge)) {
      return true;
    }
    if ((billing.pricePeriodType === PricePeriodType.YEARLY_15 || billing.pricePeriodType === PricePeriodType.YEARLY_20 || billing.pricePeriodType === PricePeriodType.YEARLY_25) && billing.isItLastMonthOfPeriod) {
      return true;
    }
    return (billing.pricePeriodType === PricePeriodType.MONTHLY);
  }

  public isOnSupercharge(): boolean {
    return StorageService.applicationModel.billing.isOnSupercharge;
  }

  public getChangePlanParam(): ChangePlanReqModel {
    if (!this.changePlanParams) {
      this.changePlanParams = localStorage.getItem(BillingService.BILLING_CHANGE_PLAN_PARAM) ?
        JSON.parse(localStorage.getItem(BillingService.BILLING_CHANGE_PLAN_PARAM)) :
        new ChangePlanReqModel();
    }
    return this.changePlanParams;
  }

  public saveChangePlanParam(params: ChangePlanReqModel): void {
    this.changePlanParams = params;
    localStorage.setItem(BillingService.BILLING_CHANGE_PLAN_PARAM, JSON.stringify(params));
    console.log('save change plan finished: ', params);
  }

  public resetChangePlanParam(): void {
    this.changePlanParams = new ChangePlanReqModel();
    localStorage.removeItem(BillingService.BILLING_CHANGE_PLAN_PARAM);
  }

  public fetchReceiptList(): Observable<ReceiptModel[]> {
    return this.httpClient.get<ReceiptModel[]>(BillingService.API + '/receipts');
  }

  public fetchReceiptUrl(chargeId: string): Observable<string> {
    return this.httpClient.get(BillingService.API + '/receipt-url/' + chargeId, {responseType: 'text'});
  }

  public changePlanPreview(params: ChangePlanReqModel): Observable<GenericResModel<ChangePlanResModel>> {
    return this.httpClient.post<GenericResModel<ChangePlanResModel>>(BillingService.API + '/change-plan-preview', params);
  }

  public createPaymentSetupForTrialUser(changePlanReq: ChangePlanReqModel): Observable<GenericResModel<string>> {
    return this.httpClient.post<GenericResModel<string>>(BillingService.API + '/payment-setup', changePlanReq);
  }

  public changePlan(changePlanReq: ChangePlanReqModel): Observable<GenericResModel<boolean>> {
    return this.httpClient.post<GenericResModel<boolean>>(BillingService.API + '/change-plan', changePlanReq);
  }

  public getPaymentMethods(isRefresh?: boolean): Observable<PaymentMethodResModel[]> {
    isRefresh ? StorageService.applicationModel.paymentMethodRes = null : '';
    if (StorageService.applicationModel.paymentMethodRes) {
      return of(StorageService.applicationModel.paymentMethodRes);
    } else {
      return this.httpClient.get<GenericResModel<PaymentMethodResModel[]>>(BillingService.API + '/payment-method')
        .pipe(map(res => {
          StorageService.applicationModel.paymentMethodRes = res.data;
          return StorageService.applicationModel.paymentMethodRes;
        }));
    }
  }

  ngOnDestroy(): void {
    if (this.firmSubject) {
      this.firmSubject.unsubscribe();
    }
    if (this.subject) {
      this.subject.unsubscribe();
    }
  }

  public setOldPlanInStorage(plan: string) {
    localStorage.setItem(BillingService.BILLING_OLD_PRODUCT, plan);
  }

  public getOldPlanInStorage(): string | null {
    return localStorage.getItem(BillingService.BILLING_OLD_PRODUCT);
  }

  public deleteOldPlanInStorage(): void {
    localStorage.removeItem(BillingService.BILLING_OLD_PRODUCT);
  }

  public shouldShowTrialExpiryDialog(numberOfDaysToExpire: number): boolean {
    if (!StorageService.applicationModel.billing || !StorageService.applicationModel.billing.isOnTrial) {
      return false;
    }
    if (StorageService.applicationModel.billing.isTrialExpired) {
      return true;
    }
    const today = new Date(StorageService.applicationModel.billing.now);
    const trialExpiry = new Date(StorageService.applicationModel.billing.trialExpiryDate);
    const numberOfDaysRemainInTrial = Math.floor(
      (Date.UTC(trialExpiry.getFullYear(), trialExpiry.getMonth(), trialExpiry.getDate()) - Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()))
      / (1000 * 60 * 60 * 24));
    return numberOfDaysRemainInTrial <= numberOfDaysToExpire;
  }

  public deletePaymentMethod(id: string): Observable<GenericResModel<PaymentMethodResModel>> {
    return this.httpClient.delete<GenericResModel<PaymentMethodResModel>>(BillingService.API + '/payment-method', {body: id});
  }

  public setAsDefaultPaymentMethod(id: string): Observable<GenericResModel<PaymentMethodResModel>> {
    return this.httpClient.put<GenericResModel<PaymentMethodResModel>>(BillingService.API + '/payment-method', id);
  }

  public addNewPaymentMethod(): Observable<GenericResModel<string>> {
    return this.httpClient.get<GenericResModel<string>>(BillingService.API + '/setup-intent');
  }

  async initializeStripePayment(firmAddress, htmlId) {
    const stripe = await loadStripe(environment.STRIPE_PK);
    const stripeElement = stripe.elements({
      mode: 'setup',
      currency: StorageService.applicationModel.firm.currency.toLowerCase(),
      setupFutureUsage: 'off_session',
      loader: 'always',
      appearance: {
        theme: 'stripe'
      }
    });
    // Create and mount the Payment Element
    const paymentElement = stripeElement.create('payment', {
      defaultValues: {
        billingDetails: {
          address: {
            country: StorageService.applicationModel.firm.countryCode,
            postal_code: firmAddress.postalCode
          }
        }
      },
      layout: {
        type: 'accordion',
        defaultCollapsed: false,
        radios: true,
        spacedAccordionItems: true
      }
    });
    paymentElement.mount(htmlId);
    return {stripe, stripeElement};
  }

  async commonForAddSubscriptionAndAddCard(event, stripe, stripeElement, redirectURL: string | null, changePlanReq: ChangePlanReqModel): Promise<boolean> {
    event.preventDefault();
    return new Promise(async resolve => {
      let enablePaymentSubmitButton = false;
      if (!stripe) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        this.toaster.error('stripe has not been loaded');
        resolve(true);
      }
      // validation
      const {error: submitError} = await stripeElement.submit();
      if (submitError) {
        this.toaster.error(submitError.message);
        enablePaymentSubmitButton = true;
        resolve(enablePaymentSubmitButton);
      }
      if (redirectURL) {
        this.addNewPaymentMethod().subscribe({
          next: async (v) => {
            enablePaymentSubmitButton = await this.onStripeSubmitting(v, stripeElement, stripe, redirectURL);
            resolve(enablePaymentSubmitButton);
          },
          error: async (e) => {
            enablePaymentSubmitButton = await this.onErrorHandler(e);
            resolve(enablePaymentSubmitButton);
          }
        });
      } else {
        this.createPaymentSetupForTrialUser(changePlanReq).subscribe({
          next: async (v) => {
            this.setOldPlanInStorage(StorageService.applicationModel.billing.plan ? StorageService.applicationModel.billing.plan : 'Trial');
            enablePaymentSubmitButton = await this.onStripeSubmitting(v, stripeElement, stripe, redirectURL);
            resolve(enablePaymentSubmitButton);
          },
          error: async (e) => {
            enablePaymentSubmitButton = await this.onErrorHandler(e);
            resolve(enablePaymentSubmitButton);
          }
        });
      }
    });
  }

  // submitting data and redirect URL to stripe
  onStripeSubmitting(res: GenericResModel<string>, stripeElement: any, stripe: any, redirectURL: any): Promise<boolean> {
    return new Promise(resolve => {
      console.log('onStripeSubmitting', res);
      let enablePaymentSubmitButton = false;
      if (res.status === 200) {
        const elements = stripeElement;
        const secret = res.data;
        if (!redirectURL) {
          redirectURL = res.description;
        }

        stripe.confirmSetup({elements, clientSecret: secret, confirmParams: {return_url: redirectURL}}).then(res => {
          if (res.error) {
            this.toaster.error(res.error.message);
            enablePaymentSubmitButton = true;
            resolve(enablePaymentSubmitButton);
          } else {
            // this is not getting executed!
            // Your customer is redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer is redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
          }
        }).catch(() => {
          this.toaster.error('Unable to connect to billing server, please contact support');
          enablePaymentSubmitButton = true;
          resolve(enablePaymentSubmitButton);
        });

      } else {
        enablePaymentSubmitButton = true;
        res.errors.forEach(error => {
          this.toaster.error(error);
        });
        resolve(enablePaymentSubmitButton);
      }
    });
  }

  onErrorHandler(e: any): Promise<boolean> {
    return new Promise(resolve => {
      e.errors.forEach(error => {
        this.toaster.error(error);
      });
      resolve(true);
    });
  }

  public checkPaymentStatus(): Observable<GenericResModel<any>> {
    return this.httpClient.post<GenericResModel<any>>(BillingService.API + '/payment-intent-check', null);
  }

  public addAddOn(addon: Addons): Observable<GenericResModel<boolean>> {
    return this.httpClient.put<GenericResModel<boolean>>(BillingService.API + '/addons-status', addon)
      .pipe(map(incoming => {
        if (incoming.data && incoming.status === 200) { this.refreshBilling().subscribe(); }
        return incoming;
      }));
  }

  public removeAddOn(addon: Addons): Observable<GenericResModel<boolean>> {
    return this.httpClient.delete<GenericResModel<boolean>>(BillingService.API + '/addons-status', {body: addon})
      .pipe(map(incoming => {
        if (incoming.data && incoming.status === 200) { this.refreshBilling().subscribe(); }
        return incoming;
      }));
  }

  public getNextPayment(): Observable<GenericResModel<NextPaymentModel>> {
    return this.httpClient.get<GenericResModel<NextPaymentModel>>(BillingService.API + '/next-payment');
  }

  public activateAddOn(addon: Addons): Observable<GenericResModel<boolean>> {
    return this.httpClient.put<GenericResModel<boolean>>(BillingService.API + '/addons', addon)
      .pipe(map(incoming => {
        if (incoming.data && incoming.status === 200) { this.refreshBilling().subscribe(); }
        return incoming;
      }));
  }

  public deactivateAddOn(addon: Addons): Observable<GenericResModel<boolean>> {
    return this.httpClient.delete<GenericResModel<boolean>>(BillingService.API + '/addons', {body: addon})
      .pipe(map(incoming => {
        if (incoming.data && incoming.status === 200) { this.refreshBilling().subscribe(); }
        return incoming;
      }));
  }

  public fetchCurrentInvoice(): Observable<GenericResModel<CurrentInvoiceModel>> {
    return this.httpClient.get<GenericResModel<CurrentInvoiceModel>>(BillingService.API + '/current-invoice');
  }

  public flexHistory(): Observable<GenericResModel<FlexHistoryResModel[]>> {
    return this.httpClient.get<GenericResModel<FlexHistoryResModel[]>>(BillingService.API + '/flex/history');
  }

  public getDowngrades(isRefresh?: boolean): Observable<FirmDowngradeModel> {
    isRefresh ? StorageService.applicationModel.firmDowngrade = null : '';
    if (StorageService.applicationModel.firmDowngrade) {
      return of(StorageService.applicationModel.firmDowngrade);
    } else {
      return this.httpClient.get<GenericResModel<FirmDowngradeModel>>(BillingService.API + '/downgrades')
        .pipe(map(res => {
          StorageService.applicationModel.firmDowngrade = res.data;
          return StorageService.applicationModel.firmDowngrade;
        }));
    }
  }

  public getFlexUsage(isRefresh?: boolean): Observable<FlexUsageModel> {
    if (!isRefresh && StorageService.applicationModel.flexUsage) {
      return of(StorageService.applicationModel.flexUsage);
    } else {
      return this.httpClient.get<GenericResModel<FlexUsageModel>>(BillingService.API + '/flex/usage')
        .pipe(map(res => {
          if (res.status === 200) {
            StorageService.applicationModel.flexUsage = res.data;
          } else {
            StorageService.applicationModel.flexUsage = null;
          }
          return StorageService.applicationModel.flexUsage;
        }));
    }
  }

  // common dialog box for eng limit and upgrade plans
  public upgradePlanDialog(dialogType: DIALOG_TYPES, dialogHeader?: string, dialogData?: any) {
    const config: MatDialogConfig = {
      data: {
        dialogType: dialogType,
        dialogHeader: dialogHeader,
        data: dialogData
      },
      disableClose: false,
      id: dialogType
    };
    this.dialog.open(CommonDialogComponent, config).afterClosed().subscribe(result => {
      if (result) {
        if (dialogType === 'upgrade_plan') {
          if (StorageService.applicationModel.billing.paymentNeedsAttention) {
            this.router.navigate(['/dashboard/members/settings/billing']).then();
          } else {
            this.startChangePlanStepper(null);
          }
        } else {
          this.router.navigate(['/dashboard/members/settings/billing']).then();
        }
      }
    });
  }

  public startChangePlanStepper(goToStep: string): void {
    this.saveChangePlanParam({
      product: StorageService.applicationModel.billing.plan,
      price: StorageService.applicationModel.billing.price,
      pricePeriodType: StorageService.applicationModel.billing.pricePeriodType,
      flex: StorageService.applicationModel.billing.isFlexEnabled,
      ai: StorageService.applicationModel.billing.isAiEnabled,
      paymentFrequency: StorageService.applicationModel.billing.paymentFrequency || PaymentFrequencyEnum.MONTHLY,
      seats: StorageService.applicationModel.billing.totalLicense,
      goToStep: goToStep
    });
    this.router.navigate(['/dashboard/members/settings/billing/stepper']).then();
  }

  private filterProducts(products: ProductModel[]): Map<string, ProductModel> {
    StorageService.applicationModel.billingProducts = new Map<string, ProductModel>();
    products.forEach(product => {
      if (product.isFlexPlan) {
        StorageService.applicationModel.flexPlan = product;
      }
      if (product.isPausePlan) {
        StorageService.applicationModel.pausePlan = product;
      }
      if (product.isTrial) {
        StorageService.applicationModel.trialPeriod = product;
      }
      StorageService.applicationModel.billingProducts.set(product.hash, product);
    });
    return StorageService.applicationModel.billingProducts;
  }

  private fetchBillingDetail(reload = true): Observable<BillingModel> {
    if (reload || !StorageService.applicationModel.billing) {
      return this.httpClient.get<BillingModel>(BillingService.API + '/detail');
    }
    return of(StorageService.applicationModel.billing);
  }

  private processRefresh(billing: BillingModel): BillingModel {
    this.processDetail(billing);
    this.subject.next(billing);
    return StorageService.applicationModel.billing;
  }

  private processDetail(detail: BillingModel) {
    StorageService.applicationModel.plan = StorageService.applicationModel.billingProducts.get(detail.plan);
    StorageService.applicationModel.billing = detail;
    if (StorageService.applicationModel.billing.status == BillingStateEnum.TRIALING) {
      StorageService.applicationModel.billing.healthy = !StorageService.applicationModel.billing.isTrialExpired;
      StorageService.applicationModel.billing.explanation = StorageService.applicationModel.billing.isTrialExpired ?
        'Trial Expired' :
        this.calculateTrialDays(detail);
    } else {
      this.getDowngrades(true).subscribe();
      this.getFlexUsage(true).subscribe();
    }
    localStorage.setItem(BillingService.BILLING_KEY, JSON.stringify(StorageService.applicationModel.billing));
    return StorageService.applicationModel.billing;
  }

  private calculateTrialDays(status: BillingModel): string {
    const time = new Date(status.trialExpiryDate).getTime() - new Date(status.now).getTime();
    if (time <= 0) {
      return 'Trial Expired';
    }
    const remainingTime = time / (1000 * 3600);
    const remainingDays = Math.floor(remainingTime / 24);
    const remainingHours = Math.floor(remainingTime % 24);
    if (remainingDays === 0 && remainingHours === 0) {
      return 'Trial will expire in an hour!';
    }
    if (remainingDays === 0) {
      return remainingHours + ' hour(s) left from trial!';
    }
    return remainingDays + ' Days Left';
  }

}
