import { Pipe, PipeTransform, Inject, LOCALE_ID } from "@angular/core";
import { DatePipe, formatDate } from '@angular/common';

@Pipe({
    name: "dateFormat"
})

export class DateFormatPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private locale: string, private datePipe: DatePipe) { }

    transform(value: any) {
        var dif = Math.floor(((+new Date() - +new Date(value)) / 1000) / 86400);

        if (dif < 30) {
            return this.customFormateDate(value);
        } else {
            value = this.datePipe.transform(value, 'MMM dd');
            return value;
        }
    }

    customFormateDate(time: string) {
        let dateMessage = '';

        var date = new Date(time),
            diff = (((new Date()).getTime() - date.getTime()) / 1000),
            daydiff = Math.floor(diff / 86400);

        if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31) {
            return '';
        }

        if (daydiff === 0) {
            dateMessage = formatDate(date, 'h:mm a', this.locale);
        }

        return daydiff == 0 && dateMessage ||
            daydiff == 1 && "Yesterday" ||
            daydiff < 7 && daydiff + " days ago" ||
            daydiff < 31 && Math.ceil(daydiff / 7) + " weeks ago";
    }
}

