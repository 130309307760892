import { DIALOG_TYPES } from 'countable@helpers';


export class GetChecklistModel {
  submoduleid: number;
  engagementsid: string;
  ChecklistStandardid: number;
}

export class EngagementsInputModel {
  engagementsid: string;
  loginid: number;
}

export class AddReferenceModel {
  clientquestionid: number;
  submoduleid: number;
  engagementsid: string;
  engleftsidedocid: number;
  loginid: number;
  isdelete: 0 | 1 = 0;
}

export class DeleteChecklistModel {
  engagementsid: number;
  submoduleid: number;
  loginid: number;
}

export class ChecklistActionModel {
  /** actionType decides which type of the Dialog should be in the View  */
  actionType: DIALOG_TYPES;
  /** subModuleId is the Unique Id for Engagement Left Side Folder Section */
  subModuleId: number;
  /** firmTemplateId is the Unique id for a checklist firm template */
  firmTemplateId?: number;
  // ChecklistStandardid
  checkListStandardId?: number;
}

