import { Component, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { NPSSurveyService } from 'src/app/services/survey.service';
import { SurveyFeedback, SurveyHeadings } from 'src/app/helpers/survey.contant';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'nps-survey',
  templateUrl: './nps-survey.component.html',
  styleUrl: './nps-survey.component.scss'
})
export class NpsSurveyComponent implements OnInit, OnDestroy{

  constructor(private npsService: NPSSurveyService,private toaster: ToastrService,private apiService: ApiService) { }

  isVisible = false;
  SurveyHeadings = SurveyHeadings;
  showFeedback: boolean = false;
  selectedRating: number = -1;
  feedbackText: string = '';
  showTqNote: WritableSignal<boolean> = signal<boolean>(false);
  ignoreCount: number = 0;
  allSurveyQuestions: any[] = [];
  selectedQueID: any;
  selectedQuestion: string;
  private allSubscriptions: Subscription = new Subscription();  

  ngOnInit(): void {
    this.npsService.modalVisibility.subscribe(isVisible => {
      this.isVisible = isVisible;
    });
    this.getSurveyQuestions();
    
    this.allSubscriptions = this.npsService.ignoreCount$.subscribe(ignoreCount => {
      if(ignoreCount){
        this.ignoreCount = ignoreCount;
      }
    })
  }

  close(): void {
     this.npsService.hide();
    !this.showTqNote() && this.submitFeedback('Ignore');
  }

  getSurveyQuestions(){
    this.apiService.getAllQuestions().subscribe((response) => {
      this.allSurveyQuestions = response.data;
    });
  }

  onClickIcon(i: number){
    this.showFeedback = true;
    this.selectedRating = i;
    const selectedQuestionObj = this.allSurveyQuestions.find(question => {
      const ratingScale = question.ratingScale.split('-');
      const minRating = parseInt(ratingScale[0]);
      const maxRating = parseInt(ratingScale[1]);
      return this.selectedRating >= minRating && this.selectedRating <= maxRating;
    });

    if (selectedQuestionObj) {
      this.selectedQueID = selectedQuestionObj.id;
      this.selectedQuestion = selectedQuestionObj.surveyQuestion;
    }
  }

  submitFeedback(feedbackType: 'Done' | 'Ignore') {
    let feedbackObj: SurveyFeedback;
    let errorMsg: string;
    if (feedbackType === 'Done') {
      feedbackObj = {
        questionId: this.selectedQueID,
        feedbackType: feedbackType,
        ratingScale: this.selectedRating,
        userComment: this.feedbackText,
        ignoreCount: this.ignoreCount
      };
      errorMsg = SurveyHeadings.SubmitError;
    } else {
      feedbackObj = {
        feedbackType: feedbackType,
        ignoreCount: this.ignoreCount + 1
      };
      errorMsg = SurveyHeadings.IgnoreError;
    }
    this.apiService.submitFeedback(feedbackObj).subscribe((response) => {
      if (response.status === 200 && response.data) {
        this.showTqNote.set(true);
      } else {
        this.toaster.error(errorMsg, 'Error');
      }
    }, (error) => {
      this.toaster.error(errorMsg, 'Error');
    });
  }


  ngOnDestroy(): void {
    if(this.allSubscriptions){
      this.allSubscriptions.unsubscribe();
    }
  }

}
