import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LucaClosedModel, LucaDialogInputModel } from 'countable@model';
import { AiPreviewComponent } from '../ai-preview/ai-preview.component';


@Component({
  selector: 'app-ai-bot-window',
  templateUrl: './ai-bot-window.component.html',
  styleUrl: './ai-bot-window.component.scss'
})
export class AiBotWindowComponent {


  constructor(@Inject(MAT_DIALOG_DATA) public data: LucaDialogInputModel, private dialogRef: MatDialogRef<AiPreviewComponent>) {
  }

  public updateAction(event: Partial<LucaClosedModel>) {
    this.dialogRef.close(event);
  }

}
