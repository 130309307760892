import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minusSignToParenthesis'
})
export class MinusSignToParenthesisPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (typeof(value) === 'string'){ //(value instanceof String) {
      if (value)
        return value.charAt(0) === '-' ?
          '(' + value.substring(1, value.length) + ')' :
          value;
      else
        return value;
    } else if (value){
        return (value < 0) ? '(' + Math.abs(value) + ')' : value;
    }
  }
}
