import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export function getenvUrl(): string {
  const loggedInUserData = JSON.parse((localStorage.getItem('userDetails')));
  if (loggedInUserData) {
    return 'https://' + loggedInUserData.s3BucketName + '.s3.' + environment.region + '.amazonaws.com/';
  }
  return null;
}

@Injectable({
  providedIn: 'root'
})
export class appConfigService {
}
