import {Component, Inject, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {DatePipe, DOCUMENT} from '@angular/common';
import {Subject} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {uniqueMapnoValidator} from '../../../../../helpers/unique-mapno-validator.directive';
import {MatDialog} from '@angular/material/dialog';
import {MatExpansionPanel} from '@angular/material/expansion';
import {MatMenuTrigger} from '@angular/material/menu';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {NgSelectComponent} from '@ng-select/ng-select';
import {ApiService} from '../../../../../services/api.service';
import {SharedService} from '../../../../shared/shared.service';

@Component({
  selector: 'map-dropdown',
  templateUrl: './map-dropdown.component.html',
  styleUrls: ['./map-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MapDropDownComponent implements OnInit {

  /* map parameters */
  private subject: Subject<string> = new Subject();
  private mappedSubject: Subject<string> = new Subject();
  isActiveNewMap: boolean = false;
  allMaps: any = [];
  newMaps: any = [];
  originalNewMaps: any = [];
  originalAllMaps: any = [];
  mapTabIndex: number = 0;
  mapNoForm: UntypedFormGroup;
  groups: any = [];
  subGroups: any = [];
  categories: any = [];
  leadSheets: any = [];
  gifiCode: any = [];
  deleteMapData: { mapNo: any; mapSheetId: any; };
  isEditMapNo: boolean = false;
  editMapData: any;
  mapsheetId: any;
  changedMapType: string = "ALL";
  isShowMapError: boolean = false;
  selectedMapNumber: any;
  gifiCodeTitle: any;
  leadSheetTitle: any;
  @ViewChild('deletedialog1') deletedialog1: TemplateRef<any>;
  @ViewChild('workBookbulkmap') workBookbulkmap: TemplateRef<any>;
  @ViewChild('closeNmapNo') closeNmapNo: MatMenuTrigger;
  @ViewChild('closeAmapNo') closeAmapNo: MatMenuTrigger;
  trailbalId: any;
  //isMapping: boolean;
  isMappingNum: any;
  userLoginId: any;
  addLeadSheetForm: any;
  userData: any;
  engagementData: any;
  selectedEngagementDetails: any;
  mappingColumns: string[] = ['checkbox', 'mapno', 'mapsheetname', 'subgroup', 'unasgn', 'mapactions'];
  @Input() tbMapsheetId: number;
  @Input() parentObject: any;
  @Input() moveType: string;
  @Input() yearType: string;
  @Input() yearEnd: string;
  @Input() subscribeFrom: string;
  engagementId: any;
  searchDropdownData = [];
  searchDropdownMappedData: any = [];
  selectedGroups: any = [];
  showCategory: boolean = false;
  isCloseAllDropdown: false;
  unMappedData: any = [];
  mappedDatabaseDetails: any = [];
  connectedTo: any = [];
  noUnMappedRecord: boolean = false;
  noMappedRecord: boolean = false;
  selectedMappedGroups: any = [];
  searchMapTerm: string = '';
  selectedUnMappedData: any = [];
  unAssignRecordData: any = {};
  singleUnAssignMessage: boolean = false;
  allUnAssignMessage: boolean = false;
  selectedMappedDatabase: any = [];
  finalMappedObject: any = [];
  mapSearchTerm: string;
  mapObject:any;
  groupTitle: any;
  subgroupTitle: any;
  categoryTitle: any;
  constructor(private apiService: ApiService, private sharedService: SharedService, public dialog: MatDialog, private toastr: ToastrService,
    private spinner: NgxSpinnerService, public datePipe: DatePipe, private fb: UntypedFormBuilder, @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('userDetails'))
    this.engagementData = JSON.parse(localStorage.getItem("selectedEngagementObj"))
    this.selectedEngagementDetails = JSON.parse((localStorage.getItem('selectedEngagementObj')));
    this.userLoginId = this.userData['useracctid'];
    this.engagementId = this.engagementData.engagementsid;
    this.mapSearchTerm = '';
    this.selectedGroups = [];
    this.getMasterMaps('');
    //Map No. start
    this.loadNewMapForm();
    this.subject.pipe(
      // if character length greater then 2
      filter(res => res.length > 2 || res.length < 3)
      // Time in milliseconds between key events
      , debounceTime(1000)
      // If previous query is diffent from current
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      // this.getMasterMaps(text);
      this.filterMasterMaps(text);
    });

    // Mapped Database Search
    this.mappedSubject.pipe(
      filter(res => res.length > 2 || res.length < 3),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((text: string) => {
      this.mappingDatabaseDetails();
    });

    this.getSearchDropdownData();
  }

  bulkmappingColumns: string[] = ['drag', 'mapno', 'mapdescription', 'subgroup', 'maporiginal', 'assign'];
  unmappingColumns: string[] = ['drag', 'select', 'accountcode', 'accountname', 'finalamount'];

  /*Map dropdown start*/
  handleSearch(searchTerm: string) {
    this.subject.next(searchTerm);
  }
  getMasterMaps(searchTerm) {
    let finGroups = [];
    let object = {};
    if (this.selectedGroups) {
      this.selectedGroups.map(e => {
        if (e.finsubgroupid) {
          object = {
            "finsubgroupid": e.finsubgroupid,
            "finsubgroupchildid": 0
          }
          finGroups.push(object);
        }
        else {
          object = {
            "finsubgroupid": 0,
            "finsubgroupchildid": e.finsubgroupchildid
          }
          finGroups.push(object);
        }
      })
    }
    else {
      finGroups = [];
    }
    let search = searchTerm ? searchTerm : null;
    let obj = {
      "searchindex": search,
      "engagementsid": this.engagementId,
      "loginid": this.userLoginId,
      "fingroup": finGroups
    };
    let inputString = JSON.stringify(obj);
    let data = {
      "procedureName": "getmastermapsheet",
      "inputParameters": inputString
    };

    this.spinner.show();
    this.apiService.getData(data).subscribe(response => {
      if (response) {
        let maps = JSON.parse(response);
        this.allMaps = maps.all ? maps.all : [];
        this.originalAllMaps = maps.all ? maps.all : [];
        this.newMaps = maps.new ? maps.new : [];
        this.originalNewMaps = maps.new ? maps.new : [];
        this.tabChanged(this.changedMapType);
      }
      else {
        this.toastr.warning("Fail to load mapping sheet");
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }
  activeNewMap() {
    this.isActiveNewMap = true;
    this.isEditMapNo = false;
    this.groupTitle = "";
    this.subgroupTitle = "";
    this.leadSheetTitle = "";
    this.gifiCodeTitle = "";
    this.enableMapInputs();
    this.mapNoForm.reset();
    this.hideMoveButton(true);
    const mapControl = this.mapNoForm.get('mapNo');
    mapControl.setAsyncValidators(uniqueMapnoValidator(this.apiService));
    mapControl.updateValueAndValidity();
  }
  tabChanged(type: string): void {
    this.changedMapType = type;
    if (type === "ALL") { // All maps
      this.mapTabIndex = 0;
    }
    else { // New map
      this.mapTabIndex = 1;
    }
  }
  backToMap(type: "ADD" | "EDIT") {
    if (type === "ADD") {
      if (this.mapNoForm.controls['group'].value
        || this.mapNoForm.controls['subGroup'].value
        || this.mapNoForm.controls['category'].value
        || this.mapNoForm.controls['mapNo'].value
        || this.mapNoForm.controls['description'].value
        || this.mapNoForm.controls['leadSheet'].value
        || this.mapNoForm.controls['gifi'].value
        || this.mapNoForm.controls['masterMapSheetId'].value) {
        this.isActiveNewMap = true;
        this.isShowMapError = true;
      }
      else {
        this.isShowMapError = false;
        this.isActiveNewMap = false;
        this.mapNoForm.reset();
        this.hideMoveButton(false);
      }
    }
    else {
      this.isShowMapError = false;
      this.isActiveNewMap = false;
      this.mapNoForm.reset();
      this.hideMoveButton(false);
    }
  }
  cancelMapNo() {
    this.isShowMapError = false;
    this.isActiveNewMap = false;
    this.hideMoveButton(false);
    this.mapNoForm.reset();
  }
  loadNewMapForm() {
    this.mapNoForm = this.fb.group({
      'group': ["", Validators.required],
      'subGroup': ["", Validators.required],
      'category': ["", Validators.required],
      'mapNo': ["", [Validators.required, Validators.pattern(/^[.\d]+$/)]],
      'description': ["", [Validators.required, Validators.maxLength(150)]],
      'leadSheet': ["", Validators.required],
      'gifi': ["", Validators.required],
      'masterMapSheetId': [""],
    });
    this.getGroups();
  }
  getGroups() {
    let obj = {
      "loginid": this.userLoginId
    };
    let inputString = JSON.stringify(obj);
    let data = {
      "procedureName": "getmapgroup",
      "inputParameters": inputString
    };
    this.apiService.getData(data).subscribe(response => {
      if (response) {
        this.groups = JSON.parse(response);
      }
      else {
        this.toastr.warning("Fail to load groups");
      }
    }, error => {
      this.toastr.error(error.error);
    });
  }
  getSubGroups(gId?) {
    let groupId: number;
    groupId = gId ? gId : this.mapNoForm.get('group').value;
    let obj = {
      "fingroupid": groupId,
      "loginid": this.userLoginId
    };
    let inputString = JSON.stringify(obj);
    let data = {
      "procedureName": "getmapsubgroup",
      "inputParameters": inputString
    };
    this.apiService.getData(data).subscribe(response => {
      if (response) {
        this.subGroups = JSON.parse(response);
        if (!gId) {
          this.categories = [];
          this.mapNoForm.controls['subGroup'].setValue(null);
          this.mapNoForm.controls['category'].setValue(null);
          this.mapNoForm.controls['mapNo'].setValue(null);
          this.mapNoForm.controls['leadSheet'].setValue(null);
          this.mapNoForm.controls['gifi'].setValue(null);
        }
      }
      else {
        this.toastr.warning("Fail to load data");
      }
    }, error => {
      this.toastr.error(error.error);
    });
  }
  //getting categories, Lead sheet and Gifi
  getCatsLsGifi(sGroupId?) {
    let subGroupId: number = sGroupId ? sGroupId : this.mapNoForm.get('subGroup').value;
    let obj = {
      "finsubgroupid": subGroupId,
      "engagementsid": this.engagementId,
      "loginid": this.userLoginId
    };
    let inputString = JSON.stringify(obj);
    let data = {
      "procedureName": "getmapsubgroupmapping",
      "inputParameters": inputString
    };
    this.apiService.getData(data).subscribe(response => {
      if (response) {
        let res = JSON.parse(response);
        this.categories = res.category;
        this.gifiCode = res.gificode;
        this.leadSheets = res.leadsheet;
        if (!sGroupId) {
          this.mapNoForm.controls['mapNo'].setValue(null);
          this.mapNoForm.controls['category'].setValue(null);
          this.mapNoForm.controls['leadSheet'].setValue(null);
          this.mapNoForm.controls['gifi'].setValue(null);
        }
      }
      else {
        this.toastr.warning("Fail to load categories");
      }
    }, error => {
      this.toastr.error(error.error);
    });
  }
  showCategoryDropdown() {
    // this.showCat.nativeElement.focus();
    this.showCategory = false;
  }
  appendCategory(element) {
    let mapNo = element.mapno;
    let nextMapNo = element.nextmapno;
    let gifiCode = element.gificode;
    let leadSheetId = element.leadsheetid;
    this.selectedMapNumber = element.mapno;
    this.categoryTitle = element.mapno;
    //this.leadSheets.filter(e => e.leadsheetid === leadSheetId)
    if (mapNo) {
      this.mapNoForm.controls['category'].setValue(mapNo);
      this.mapNoForm.controls['mapNo'].setValue(nextMapNo);
      this.mapNoForm.controls['leadSheet'].setValue(leadSheetId);
      this.mapNoForm.controls['gifi'].setValue(gifiCode);
      this.gifiCode.filter(e => {
        if (e.gificode === gifiCode) {
          this.gifiCodeTitle = e.gifidescription;
        }
      })
      this.leadSheetTitle = element.leadsheetname;
      this.showCategory = true;
    }
    else {
      this.mapNoForm.controls['category'].setValue(null);
      this.mapNoForm.controls['mapNo'].setValue(null);
      this.mapNoForm.controls['leadSheet'].setValue(null);
      this.mapNoForm.controls['gifi'].setValue(null);
    }
    //this.showCat.nativeElement.focusout();

  }
  saveNewMapNo() {
    if (!this.mapNoForm.valid) {
      return;
    }
    else {
      let obj, mappingSheetId, finSubGroupChildId;
      obj = this.mapNoForm.value;
      this.categories.forEach(e => {
        if (e.mapno === obj.category) {
          mappingSheetId = e.mastermapsheetid;
          finSubGroupChildId = e.finsubgroupchildid;
        }
      })
      const saveMapObj = {
        "mapsheet": [{
          "engagementsid": this.engagementId,
          "mappingsheetid": mappingSheetId,
          "gificode": obj.gifi,
          "mapno": obj.mapNo,
          "mastermapsheetname": obj.description,
          "finsubgroupchildid": finSubGroupChildId,
          "leadsheetid": obj.leadSheet,
          "isdelete": 0,
          "loginid": this.userLoginId
        }]
      }
      const finalSaveMappingObj = JSON.stringify(saveMapObj);
      const saveObj = {
        "inputParameters": finalSaveMappingObj,
        "procedureName": "savemappingsheet"
      }
      this.spinner.show();
      this.apiService.saveData(saveObj).subscribe(response => {
        if (response) {
          this.isActiveNewMap = false;
          this.spinner.hide();
          this.mapNoForm.reset();
          this.hideMoveButton(false);
          this.toastr.success("New Map No. added successfully");
          this.getMasterMaps(this.mapSearchTerm);
        }
        else {
          this.toastr.error("Mapping failed");
        }

      }, error => {
        this.isActiveNewMap = false;
        this.spinner.hide();
        this.mapNoForm.reset();
        this.toastr.error(error.error);
      });
    }
  }
  deletedialog(type: "all"|"new") {
    let obj = this.mapObject;
    this.deleteMapData = {
      "mapNo": obj.mapno,
      "mapSheetId": obj.mastermapsheetid
    }
    const dialogRef = this.dialog.open(this.deletedialog1, { disableClose: true });
    dialogRef.afterClosed().subscribe(result => { });
    if(type === "all"){
      this.closeAmapNo.closeMenu();
    }
    else{
      this.closeNmapNo.closeMenu();
    }
  }
  deleteMapNo() {
    const deleteMapObj = {
      "mapno": [{
        "mastermapsheetid": this.deleteMapData.mapSheetId,
        "loginid": this.userLoginId
      }]
    }
    const deleteMapnoObj = JSON.stringify(deleteMapObj);
    const saveObj = {
      "inputParameters": deleteMapnoObj,
      "procedureName": "deletemapno"
    }
    this.spinner.show();
    this.apiService.saveData(saveObj).subscribe(response => {
      if (response) {
        this.spinner.hide();
        this.hideMoveButton(false);
        this.toastr.success("Map No. deleted successfully");
        this.getMasterMaps(this.mapSearchTerm);
      }
    }, error => {
      this.isActiveNewMap = false;
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }
  editMapNo() {
    let obj = this.mapObject;
    this.isActiveNewMap = true;
    let masterMapSheetId = obj.mastermapsheetid;
    this.isEditMapNo = true;
    this.hideMoveButton(true);
    const mapControl = this.mapNoForm.get('mapNo');
    mapControl.clearAsyncValidators();
    mapControl.updateValueAndValidity();
    this.disableMapInputs();
    const editMapObj = {
      "mastermapsheetid": masterMapSheetId,
      "loginid": this.userLoginId
    }
    const editMapnoObj = JSON.stringify(editMapObj);
    const saveObj = {
      "inputParameters": editMapnoObj,
      "procedureName": "getmastermapsheetonid"
    }
    this.spinner.show();
    this.apiService.getData(saveObj).subscribe(response => {
      if (response) {
        let res = JSON.parse(response);
        this.spinner.hide();
        this.editMapData = res.category[0];
        this.getSubGroups(this.editMapData.fingroupid);
        this.getCatsLsGifi(this.editMapData.finsubgroupid);
        this.selectedMapNumber = this.editMapData.category;
        this.spinner.hide();
        this.mapNoForm.patchValue({
          'group': this.editMapData.fingroupid,
          'subGroup': this.editMapData.finsubgroupid,
          'category': this.editMapData.category,
          'mapNo': this.editMapData.mapno,
          'description': this.editMapData.description,
          'leadSheet': this.editMapData.leadsheetid,
          'gifi': this.editMapData.gificode,
          'masterMapSheetId': this.editMapData.mastermapsheetid
        });
      }
    }, error => {
      this.isActiveNewMap = false;
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }
  updateMapNo() {
    let obj = this.mapNoForm.value;
    const updateMapObj = {
      "mapname": [{
        "mastermapsheetid": obj.masterMapSheetId,
        "name": obj.description,
        "loginid": this.userLoginId
      }]
    }
    const updateMapnoObj = JSON.stringify(updateMapObj);
    const saveObj = {
      "inputParameters": updateMapnoObj,
      "procedureName": "updatemapname"
    }
    this.spinner.show();
    this.apiService.saveData(saveObj).subscribe(response => {
      if (response) {
        this.isActiveNewMap = false;
        this.isEditMapNo = false;
        this.spinner.hide();
        this.mapNoForm.reset();
        this.toastr.success("Updated description successfully");
        this.hideMoveButton(true);
        this.getMasterMaps(this.mapSearchTerm);
      }
      else {
        this.toastr.error("Mapping failed");
      }

    }, error => {
      this.isActiveNewMap = false;
      this.isEditMapNo = false;
      this.spinner.hide();
      this.toastr.error(error.error);
      this.mapNoForm.reset();
    });
  }
  confirmSaveMapNo(parentData, element, type) {
    if (type === "SINGLE") {
      parentData.isMap = false;
    }
    let data = {
      "subscribeFrom": this.subscribeFrom,
      "parent": parentData,
      "child": element,
      "moveType": type,
      "moveFrom": 'MAP',
      "isDisableMoveButton": element.mastermapsheetid ? false : true,
      "yearType": this.yearType
    }
    this.tbMapsheetId = element.mastermapsheetid;
    this.sharedService.isMovingLeadsheet.next(data);
  }
  unAssignMapNumber(parentData) {
    let data = {
      "parent": parentData,
      "yearType": this.yearType
    }
    this.sharedService.unAssignMapNumber.next(data);
  }
  getSearchDropdownData() {
    let obj = {
      "engagementsid": this.engagementId
    };
    let inputString = JSON.stringify(obj);
    let data = {
      "procedureName": "getgroupmastermapsheet",
      "inputParameters": inputString
    };
    this.apiService.getData(data).subscribe(response => {
      if (response) {
        let res = JSON.parse(response);
        this.searchDropdownData = res.subgroup;
        this.searchDropdownMappedData = res.subgroup;
      }
      else {
        this.toastr.warning("Fail to load data");
      }
    }, error => {
      this.toastr.error(error.error);
    });
  }
  compareGroups = (item, selected) => {
    if (selected.finsubgroupid && item.finsubgroupid) {
      return item.finsubgroupid === selected.finsubgroupid;
    }
    if (item.name && selected.name) {
      return item.leadsheetid === selected.leadsheetid;
    }
    return false;
  };
  trackAllMaps(index, item) {
    return index; // or item.id
  }
  trackNewMaps(index, item) {
    return index; // or item.id
  }
  disableMapInputs() {
    this.mapNoForm.controls.group.disable();
    this.mapNoForm.controls.subGroup.disable();
    this.mapNoForm.controls.category.disable();
    this.mapNoForm.controls.leadSheet.disable();
    this.mapNoForm.controls.gifi.disable();
  }
  enableMapInputs() {
    if (this.mapNoForm.controls.group.disabled) {
      this.mapNoForm.controls.group.enable();
    }
    if (this.mapNoForm.controls.subGroup.disabled) {
      this.mapNoForm.controls.subGroup.enable();
    }
    if (this.mapNoForm.controls.category.disabled) {
      this.mapNoForm.controls.category.enable();
    }
    if (this.mapNoForm.controls.leadSheet.disabled) {
      this.mapNoForm.controls.leadSheet.enable();
    }
    if (this.mapNoForm.controls.gifi.disabled) {
      this.mapNoForm.controls.gifi.enable();
    }
  }
  showMore(event) {
    event.stopPropagation()
    const parentElem = event.target.parentNode;
    parentElem.classList.toggle('expanded')
    parentElem.parentNode.classList.toggle('expanded-row')
  }
  openSelect(select: NgSelectComponent) {
    select.open();
  }

  // Bulk Map Code Start from here
  bulkMapDialogOpen(parentData, type) {
    if (type === "SINGLE") {
      parentData.isMap = false;
    }
    const dialogRef = this.dialog.open(this.workBookbulkmap, { disableClose: true });
    dialogRef.afterClosed().subscribe(result => { });
  }

  unmappedAccountDetails() {
    this.unMappedData = [];
    let trailBalanceYearEnd = localStorage.getItem('trailBalYear')

    let obj = {
      "engagementsid": this.engagementId,
      "acctyear": trailBalanceYearEnd
    };

    let inputString = JSON.stringify(obj);
    let data = {
      "procedureName": "getunmaprecords",
      "inputParameters": inputString
    };

    this.apiService.getData(data).subscribe(response => {
      this.unMappedData = JSON.parse(response);

      if (this.unMappedData.length === 0) {
        this.noUnMappedRecord = true;
      } else {
        this.noUnMappedRecord = false;
      }
    }, error => {
      this.toastr.error(error.error);
    });
  }

  mappingDatabaseDetails() {
    let finGroups = [];
    let object = {};
    // if (this.selectedMappedGroups) {
    this.selectedMappedGroups.map(e => {
      if (e.finsubgroupid) {
        object = {
          "finsubgroupid": e.finsubgroupid,
          "finsubgroupchildid": 0
        }
        finGroups.push(object);
      }
      else {
        object = {
          "finsubgroupid": 0,
          "finsubgroupchildid": e.finsubgroupchildid
        }
        finGroups.push(object);
      }
    })
    // }

    let search = this.searchMapTerm ? this.searchMapTerm : '';
    this.connectedTo = [];
    this.mappedDatabaseDetails = [];
    this.selectedMappedDatabase =  [];

    let trailBalanceYearEnd = localStorage.getItem('trailBalYear');

    let obj = {
      "engagementsid": this.engagementId,
      "acctyear": trailBalanceYearEnd,
      "searchindex": search,
      "loginid": this.userLoginId,
      "fingroup": finGroups
    };

    let inputString = JSON.stringify(obj);
    let data = {
      "procedureName": "getmappedmastermapsheet",
      "inputParameters": inputString
    };

    this.spinner.show();
    this.apiService.getData(data).subscribe(response => {
      this.spinner.hide();
      let result: any = [];
      result = JSON.parse(response);
      this.mappedDatabaseDetails = result.all;

      if (this.mappedDatabaseDetails === null || this.mappedDatabaseDetails.length === 0) {
        this.noMappedRecord = true;
      } else {
        this.noMappedRecord = false;
        this.mappedDatabaseDetails.forEach(mappedData => {
          mappedData.selected = false;
          this.connectedTo.push(mappedData.mapno);
        });
      }

    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }

  isUnMappedSelectedAll(data) {
    this.selectedUnMappedData = [];
    if (data.checked) {
      data.map(x => {
        if (x.checked) {
          x.checked = true;
        } else {
          x.checked = true;
        }
      });
      this.selectedUnMappedData = data;
    } else {
      this.selectedUnMappedData = [];
      data.map(x => {
        if (x.checked) {
          x.checked = false
        } else {
          x.checked = false;
        }
      });
    }
  }

  selectedItemsChange(data) {
    let unMappedSelectedData = [];
    var count = 0
    data.map(x => {
      if (x.checked) {
        count = count + 1
        if (x.checked) {
          unMappedSelectedData.push({ "accountcode": x.accountcode, "accountname": x.accountname, "finalamount": x.finalamount });
        }
      }
    });

    if (this.unMappedData.length == count) {
      data.checked = true
    } else {
      data.checked = false
    }
    this.selectedUnMappedData = unMappedSelectedData;
  }

  openUnAssignPopUp(value, type) {
    this.unAssignRecordData = value;
    if (type === 'single') {
      this.singleUnAssignMessage = true;
      this.allUnAssignMessage = false;
    } else {
      this.singleUnAssignMessage = false;
      this.allUnAssignMessage = true;
    }
  }

  onUnAssignNo() {
    this.unAssignRecordData = {};
    this.singleUnAssignMessage = false;
    this.allUnAssignMessage = false;
  }

  onUnAssignYes() {
    if (this.singleUnAssignMessage) {
      this.unAssignRecord(this.unAssignRecordData);
    } else {
      this.unAssignAllRecord(this.unAssignRecordData);
    }
  }

  unAssignRecord(value) {
    let data = [{
      "trailbalanceid": value.trailbalanceid,
      "loginid": this.userLoginId
    }];

    var saveMapObj = {
      "tbrow": data
    }

    var finalSaveMappingObj = JSON.stringify(saveMapObj)
    var getLeadSheetGroupObj = {
      "inputParameters": finalSaveMappingObj,
      "procedureName": "unassigntbsinglerow"
    }

    this.spinner.show();
    this.apiService.saveData(getLeadSheetGroupObj).subscribe(response => {
      if (response == 'true') {
        this.spinner.hide();
        this.toastr.success("Record unassigned successfully");
        this.onUnAssignNo();
        this.unmappedAccountDetails();
        this.mappingDatabaseDetails();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }

  unAssignAllRecord(value) {
    let trailBalanceYearEnd = localStorage.getItem('trailBalYear');

    let data = [{
      "engagementsid": this.engagementId,
      "acctyear": trailBalanceYearEnd,
      "mappingsheetid": value.mastermapsheetid,
      "loginid": this.userLoginId
    }];

    var saveMapObj = {
      "unassignall": data
    }

    var finalSaveMappingObj = JSON.stringify(saveMapObj)
    var getLeadSheetGroupObj = {
      "inputParameters": finalSaveMappingObj,
      "procedureName": "tbrowunassignall"
    }

    this.spinner.show();
    this.apiService.saveData(getLeadSheetGroupObj).subscribe(response => {
      if (response == 'true') {
        this.spinner.hide();
        this.toastr.success("All records unassign successfully");
        this.onUnAssignNo();
        this.unmappedAccountDetails();
        this.mappingDatabaseDetails();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }

  selectedMappedRecord(data) {
    this.selectedMappedDatabase = [];
    this.selectedMappedDatabase.push(data);

    this.mappedDatabaseDetails.forEach(mappedElement => {
      if (mappedElement.mapno === data.mapno) {
        mappedElement.selected = true;
      } else {
        mappedElement.selected = false;
      }
    });
  }

  onAssignClick() {
    if (this.selectedMappedDatabase.length === 0) {
      this.toastr.error('Please select one Mapping Database Record to Assign');
    } else {
      this.unMappedData.forEach((element, index) => {
        if (element.checked) {
          this.mappedDatabaseDetails.forEach(mappedElement => {
            if (mappedElement.mastermapsheetid === this.selectedMappedDatabase[0].mastermapsheetid) {
              this.finalMappedObject.push({
                trailbalanceid: element.trailbalanceid,
                mapsheetid: this.selectedMappedDatabase[0].mastermapsheetid,
                acctyear: this.yearEnd,
                loginid: this.userLoginId
              });
            }
          });
        }
      });
      this.saveMappingDetails();
    }
  }

  mouseEnterHandler(event: MouseEvent, openExpansionPanel: MatExpansionPanel) {
    if (event.buttons && !openExpansionPanel.expanded) {
      openExpansionPanel.open();
    }
  }

  saveMappingDetails() {
    var obj = {
      "tbmapping": this.finalMappedObject
    }

    var saveObj = JSON.stringify(obj)
    var finalSaveObj = {
      "inputParameters": saveObj,
      "procedureName": "savenewtrailbalancemapping"
    }

    this.spinner.show();
    this.apiService.saveData(finalSaveObj).subscribe(response => {
      if (response == 'true') {
        this.spinner.hide();
        this.toastr.success("Mapped successfully");
        this.clearBulkMap();
        this.unmappedAccountDetails();
        this.mappingDatabaseDetails();
        this.sharedService.sendNotifySubject.next({'uuid': this.userData.useracctid, 'isfirm': 1});
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }

  clearBulkMap() {
    this.unMappedData = [];
    this.selectedUnMappedData = [];
    this.mappedDatabaseDetails = [];
    this.finalMappedObject = [];
  }

  cancelBulkMap() {
    this.unMappedData = [];
    this.selectedUnMappedData = [];
    this.mappedDatabaseDetails = [];
    this.finalMappedObject = [];
    this.searchMapTerm = '';
    this.selectedMappedGroups = [];
    this.sharedService.isBulkMap.next(this.moveType);
  }

  dropUnMappedData(event) {
    if (event.previousContainer !== event.container) {
      this.finalMappedObject.push({
        trailbalanceid: event.item.data.trailbalanceid,
        mapsheetid: event.container.data.mastermapsheetid,
        acctyear: this.yearEnd,
        loginid: this.userLoginId
      });

      this.saveMappingDetails();
    }
  }

  dropMappedData(event) {
    if (event.previousContainer !== event.container) {
      this.finalMappedObject.push({
        trailbalanceid: event.item.data.trailbalanceid,
        mapsheetid: event.container.data[0].mastermapsheetid,
        acctyear: this.yearEnd,
        loginid: this.userLoginId
      });

      this.saveMappingDetails();
    }
  }

  // onDragMoved(event) {
  //   if (event.delta.y === 1) {
  //     this.document.getElementById('bulkMapGrid').scrollTop += 5;
  //   } else {
  //     this.document.getElementById('bulkMapGrid').scrollTop -= 5;
  //   }
  // }

  bulkMapSearch(searchTerm: string) {
    this.mappedSubject.next(searchTerm);
  }
  hideMoveButton(hideType: boolean){
    if(this.moveType === "MULTI" && hideType){
        this.sharedService.hideMoveButton.next(true);
    }
    else{
        this.sharedService.hideMoveButton.next(false);
    }
  }
  onChangeGroup(event) {
    if (event) {
        this.groupTitle = event.fingroupname.trim();
    }
  }
  onChangeSubGroup(event) {
    if (event) {
        this.subgroupTitle = event.finsubgroupname.trim();
    }
  }
  getLSTitle(event) {
    if (event) {
      this.leadSheetTitle = event.leadsheetname.trim();
    }
  }
  getGifiTitle(event) {
    if (event) {
      this.gifiCodeTitle = event.gifidescription.trim();
    }
  }

  filterMasterMaps(searchTerm){
    if (!searchTerm) {
      this.allMaps = this.originalAllMaps.slice();
      this.newMaps = this.originalNewMaps.slice();
  } else {

      this.allMaps = this.originalAllMaps.filter(item =>
          item.description.toLowerCase().includes(searchTerm.toLowerCase()) || item.mapno.includes(searchTerm)
      );

      this.newMaps = this.originalNewMaps.filter(item =>
          item.description.toLowerCase().includes(searchTerm.toLowerCase() || item.mapno.includes(searchTerm))
      );
  }
  }
}
