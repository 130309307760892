// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editableLabel .mat-icon-button {
  width: 20px;
  height: 20px;
}
.editableLabel .mat-icon-button .clear-icon {
  font-size: 18px;
  color: #E21212;
}
.editableLabel .mat-icon-button .update-icon {
  font-size: 18px;
  color: #339C7D;
}`, "",{"version":3,"sources":["webpack://./src/app/components/editableLabel/editableLabel.component.scss"],"names":[],"mappings":"AACI;EACQ,WAAA;EACA,YAAA;AAAZ;AACQ;EACI,eAAA;EACA,cAAA;AACZ;AACQ;EACI,eAAA;EACA,cAAA;AACZ","sourcesContent":[".editableLabel {\n    .mat-icon-button {\n            width: 20px;\n            height: 20px;\n        .clear-icon {\n            font-size: 18px;\n            color: #E21212;\n        }\n        .update-icon {\n            font-size: 18px;\n            color: #339C7D;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
