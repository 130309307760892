import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-file-preview-ms-sdk',
  templateUrl: './microsoft-sdk.component.html',
  styleUrls: ['./microsoft-sdk.component.scss']
})
export class MicrosoftSdkComponent implements AfterViewInit {

  @ViewChild('form') postForm: ElementRef<HTMLFormElement>;

  @Input() fileType: string;
  @Input() isInline: boolean;
  @Input() hash: string;
  @Input() viewMode = 'view';
  @Input() isReadOnly :boolean;

  accessToken: any;
  accessTokenTTL = 4354354;
  userId;
  private reqBody: any;
  private wopiUrl: SafeResourceUrl = null;

  constructor(private http: HttpClient, private apiService: ApiService, private authService: AuthService,
              private sanitizer: DomSanitizer, private toaster: ToastrService) {
    this.wopiUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
  }

  ngAfterViewInit() {
    this.apiService.getS3DocumentWopiInfo(this.hash,this.isReadOnly).subscribe(res => {
      if (res.status === 200) {
        this.accessToken = this.authService.getToken();
        this.userId = this.authService.getUserDetail().useracctid;
        this.wopiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(res.data);
        // to overcome the html limitation on iframe
        this.reqBody = new FormData();
        this.reqBody.append('access_token', this.authService.getToken());
        this.reqBody.append('access_token_ttl', this.accessTokenTTL);
        this.reqBody.append('user_id', this.authService.getUserDetail().useracctid);
        this.reqBody.append('owner_id', this.authService.getUserDetail().useracctid);

        this.postForm.nativeElement.submit();
      }
      res.errors.forEach(error => {
        this.toaster.error(error);
      });
    }, () => {
      this.toaster.error('Could not load the file, please contact Countable support team');
    });
  }

  getWopiUrl(): SafeResourceUrl {
    return this.wopiUrl;
  }

  submitForm($event): boolean {
    $event.stopPropagation();
    this.http.post<any>('', this.reqBody).subscribe();
    return true;
  }
}
