import { Directive, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appNumeriminusdecimal]'
})
export class NumeriminusdecimalDirective {
  private regex: RegExp = new RegExp(/^-?\d*\.?\d{0,2}$/);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    let input: HTMLInputElement = this.el.nativeElement;
    let currentValue: string = input.value;
    const caretPos: number = input.selectionStart;

    // Remove "0.00" if it is the current value and user starts typing
    if (currentValue === '0.00' && /\d|\./.test(event.key)) {
      input.value = '';
      this.ngModelChange.emit(input.value);
      return;
    }

    if (event.key === '-' && currentValue === '0.00') {
      // Remove "0.00" and add "-"
      input.value = '';
      this.ngModelChange.emit(input.value);
      return;
    }

    if (this.specialKeys.indexOf(event.key) !== -1 || /\d/.test(event.key)) {
      return;
    }


    if (event.key === '-' && currentValue.indexOf('-') === -1 && caretPos === 0) {
      if (currentValue.includes('(') || currentValue.includes(')')) {
        event.preventDefault();
      }
      return;
    }

    const nextValue: string =
      currentValue.slice(0, caretPos) + (event.key === '.' ? '.' : event.key) + currentValue.slice(caretPos);

    if (!this.regex.test(nextValue) && event.key !== '.') {
      event.preventDefault();
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event: any): void {
    this.formatValue();
  }

  private formatValue(): void {
    let inputValue: string = this.el.nativeElement.value.replace(/,/g, '');

    const isNegativeInput: boolean = inputValue.startsWith('(') && inputValue.endsWith(')');
    const numericValue: number = parseFloat(inputValue.replace(/[^\d.-]/g, ''));

    if (!isNaN(numericValue)) {
      const isNegative: boolean = numericValue < 0 || isNegativeInput;
      const formattedValue: string = this.formatNumber(Math.abs(numericValue));
      this.el.nativeElement.value = isNegative ? `(${formattedValue})` : formattedValue;

      // Emit ngModelChange event
      this.ngModelChange.emit(this.el.nativeElement.value);
    }
  }

  private formatNumber(value: number): string {
    const formattedValue: string = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    const isNegative: boolean = value < 0;
    return isNegative ? `(${formattedValue.slice(1)})` : formattedValue;
  }
}
