import { Injectable } from '@angular/core';
// import { Subject } from 'rxjs';
// import * as SockJS from 'sockjs-client';
// import * as Stomp from 'stompjs';
// import { environment } from '../../environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})

export class WebSocketService {
  // topic: string = '/queue/eng';
  // public notifyData = new Subject<any>();
  // private stompClient: any;
  // private webSocketEndPoint = environment.apiV1.replace('/v1', '') + '/socket';

  constructor(public apiService: ApiService) {}

  // _connect() {
  //   //console.log('connecting web socket');
  //   let ws = new SockJS(this.webSocketEndPoint);
  //   this.stompClient = Stomp.over(ws);
  //   const _this = this;
  //   _this.stompClient.connect({}, function (frame) {
  //     _this.stompClient.subscribe(_this.topic, function (sdkEvent) {
  //       _this.onMessageReceived(sdkEvent);
  //     });
  //     //_this.stompClient.reconnect_delay = 2000;
  //   }, this.errorCallBack);
  //   //to print value in console comment below line
  //   // _this.stompClient.debug = null;
  // }

  // _disconnect() {
  //   if (this.stompClient !== null) {
  //     this.stompClient.disconnect();
  //   }
  // }

  // // on error, schedule a reconnection attempt
  // errorCallBack(error) {
  //   setTimeout(() => {
  //     this._connect();
  //   }, 5000);
  // }

  // _send(message) {
  //   this.stompClient.send('/app/hello', {}, message);
  // }

  // onMessageReceived(message) {
  //   console.log('Web Socket: ' + message);
  //   // StorageService.applicationModel.integrations.accountTypes.clear();
  //   // this.tbService.fetchAccountTypes();
  //   //let res = this.apiService.deData(JSON.parse(message.body).content);
  //   //this.notifyData.next(res);
  // }
}
