import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { EncodingService } from './encoding.service';

let textResponse: any = {responseType: 'text'};

@Injectable({
  providedIn: 'root'
})

export class NotesToFsService {

  constructor(private http: HttpClient, private encodingService: EncodingService) { }

  getNotesPath(loginId, templateType): Observable<any> {
    return this.http.get(environment.apiV1 + '/' +
      encodeURIComponent(this.encodingService.enData(loginId)) + '/' +
      encodeURIComponent(this.encodingService.enData(templateType)) + '/master-template-json-details', textResponse);
  }
}
