import {DIALOG_TYPES} from 'countable@helpers';

export class DialogInputModel {
    data: any;
    dialogType: DIALOG_TYPES;
    dialogHeader: string;
    dialogBody?: string;
    dialogButtons?: any[];
    originId?: any;
}

export class DialogResponseModel {
    executeAction:boolean = false;
    executeActionType: DIALOG_TYPES;
    responseData: any;
}
