import { ChatsModel, SavedChatHistoryModel } from './chat.model';
import { LucaEngListModel, pageInfo } from './luca.model';
import { SuggestedPromptsModel } from './suggested-prompts.model';

export enum AI_KEYS {
  aiState = 'AI_WINDOW_STATE',
  aiInfo = 'AI_WINDOW_INFO'
}

export class AiStateModel {
  engList: LucaEngListModel[];
  hasNoEngagements: boolean = false;
  selectedEng: LucaEngListModel;
  chatsList: ChatsModel[];
  chatHistory: SavedChatHistoryModel;
  selectedPrompt: SuggestedPromptsModel;
  hasSelectedEng: boolean = false;
  hasActiveChat: boolean = false;
  activeChat: ChatsModel;
  pageInfo: pageInfo;
}


