import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, signal, ViewChild, WritableSignal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { RenameInputModel, RenameUpdateModel } from '../../../model/notes/notes-to-fs.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogInputModel } from 'countable@model';

@Component({
  selector: 'app-countable-rename',
  standalone: true,
  imports: [CommonModule, MatInputModule, MatButtonModule, NgOptimizedImage, ReactiveFormsModule],
  templateUrl: './countable-rename.component.html',
  styleUrl: './countable-rename.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountableRenameComponent {
  @ViewChild('autoSize') autoSize: any;
  originalBold: boolean;
  @Input() set renameInfo(name: RenameInputModel) {
    if (name?.name) {
      this.renameText.setValue(name?.name);
      this.rename.set(name);
      this.originalBold = name?.isBolded;
    }
  }

  @Output() updatedName: EventEmitter<RenameUpdateModel> = new EventEmitter<RenameUpdateModel>(true);
  public renameText: FormControl<string | null> = new FormControl<string | null>('');
  public rename: WritableSignal<RenameInputModel> = signal<RenameInputModel>(null);

  public get originalName() {
    return this.renameText?.getRawValue() ?? '';
  }

  constructor(public dialogRef: MatDialogRef<CountableRenameComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(this.data)
    this.renameInfo = this.data.renameInfo;
    this.rename.set(this.data.renameInfo);
  }



  onKeydown(event: Event) {
    console.log(event)
    const txtare: any = event;
    const textarea = event.target as HTMLTextAreaElement;
    const lines = textarea?.value?.split('\n');
    if (!txtare.target?.value?.trim().length || lines?.length === 4) {
      event.preventDefault();
      return;
    }

    lines.forEach(li => {
      if (!li.trim().length) {
        event.preventDefault();
        return;
      }
    });
  }

  onInputChange(event: Event) {
    this.handleRename(event.target as HTMLTextAreaElement)
  }

  onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text') ?? '';
    const input = event.target as HTMLTextAreaElement;
    // Get the current text up to the paste position
    const selectionStart = input.selectionStart ?? 0;
    const selectionEnd = input.selectionEnd ?? 0;
    const currentText = input.value;
    // Combine text before selection, pasted text, and text after selection
    const newText = currentText.slice(0, selectionStart) + pastedText + currentText.slice(selectionEnd);
    // Set the new value to the input and handle it
    input.value = newText;
    this.handleRename(input);
  }

  handleRename(textarea: HTMLTextAreaElement): void {
    // const textarea = event.target as HTMLTextAreaElement;
    let lines = textarea.value.split('\n');
    // Restrict each line to 14 characters
    lines = lines.map(line => line.length >= 14 ? line.slice(0, 14) : line);
    // Join the lines and enforce the total character limit of 250
    let joinedText = lines.join('\n');
    if (joinedText.length > 60) {
      joinedText = joinedText.slice(0, 60);
    }
    // Update the text property and the textarea value
    textarea.value = joinedText;
    this.autoSize?.resizeToFitContent(true);
  }


  public cancelRename() {
    const obj: RenameUpdateModel = new RenameUpdateModel();
    obj.isUpdate = false;
    this.updatedName.emit(obj);
    this.dialogRef.close({ updatedName: obj });
  }

  public updateRename() {
    const textAreaValue = this.renameText.getRawValue();
    let lines = textAreaValue?.split('\n');
    lines = lines.map(line => line.slice(0, 14));
    const obj: RenameUpdateModel = new RenameUpdateModel();
    obj.updatedName = lines.join('\n');
    obj.isUpdate = true;
    obj.updateId = this.rename()?.id;
    obj.isBolded = this.rename()?.isBolded;
    this.updatedName.emit(obj);
    this.dialogRef.close({ updatedName: obj });
  }

  onChange(value: string) {
    if (value === 'bold') {
      const obj: RenameInputModel = new RenameInputModel();
      obj.id = this.rename().id;
      obj.isBolded = !this.rename().isBolded;
      obj.name = this.rename().name;
      this.rename.set(obj);
    } else {
      const textAreaValue = this.renameText.getRawValue();
      const lines = textAreaValue?.split('\n');
      let isLineEmpty = false;
      if (lines?.length === 4 || !textAreaValue.trim().length) {
        return;
      }
      lines?.forEach(v => {
        if (v.length === 13 || !v.trim().length) {
          isLineEmpty = true;
        }
      });

      if (!isLineEmpty) {
        this.renameText.setValue(textAreaValue + '\n');
      }
      document.getElementById('textAreaId').focus();
    }

  }

}
