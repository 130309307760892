export class MethodsModel {
  methodName: string;
  methodId: number;
  methodKey: string;
}

export const METHODS: MethodsModel[] = [
  {
    methodName: 'Straight Line',
    methodId: 1,
    methodKey: 'SL'
  },
  {
    methodName: 'Decline',
    methodKey: 'DL',
    methodId: 2
  },
  {
    methodName: 'Double Decline',
    methodKey: 'DDL',
    methodId: 3
  },
  {
    methodName: 'Units of Production',
    methodKey: 'UoP',
    methodId: 4
  },
  {
    methodName: 'Sum Of Years Digit',
    methodKey: 'SoY',
    methodId: 5
  }
];

export class AmortizationInputsModel {
  tbAccountName: string; //Description
  tbAccountCode: string; //Acc. No.
  category: string; //Category
  categoryId: number;
  methodName: string; //Method
  methodId: number;
  halfYearRule: string; //Half Year rule
  rate: string; //Rate (%)
  usefulLife: string; //Useful Life (Years)
  residualValue: number | string; //Useful Life (Years)
  estimatedProduction: number | string; //Estimated Production (Units)
  unitProduced: number | string; //Units Produced (Units)
  remainingLife: number | string; //Remaining Life (Years)
  trialBalId: number = 0; // Trail Balance Id
  amortId: number = 0; // Amortization Accounted Matched Tb Id
}

export class CategoryModel {
  categoryName: string;
  categoryId: number;
  methodName: string;
  methodId: number;
  rate: string;
  usefulLife: string;
  method_name: string;
}

export class AmortizationScheduleModel {
  accNo: string; //AccountNumber
  description: string; //AccountName
  openingBalOne: string | number = '0.00'; //Opening Balance (OB1)
  addAdj: string | number = '0.00'; //Addition/Adjustments (ADD)
  disAdj: string | number = '0.00'; //Disposition/Adjustments (DIS)
  closingBal: string | number = '0.00'; //Closing Balance (CB1=OB1+ADD-DIS)
  openingBalTb: string | number = '0.00'; //Opening Balance (OB2)
  amortization: string | number = '0.00'; //Amortization (A)
  closingBalTb: string | number = '0.00'; //Closing Balance (CB2=OB2+A)
  currYear: string | number = '0.00'; //Current Year (CY)
  prevYear: string | number = '0.00'; //Previous Year (PY)
  balTb: string | number = ' 0.00'; //Trial Balance (TB)
  underOver: string | number = '0.00'; //Under/Over (U/O),
  trialBalId: number; // From DB
  accountUUID: string; // From DB
  rowType: 'TB' | 'TOTAL' | 'Difference' | 'Balance' | 'Total';
  amortTbId: number;
}

export class ScheduleAdjustmentModel {
  accNo: string; //AccountNumber
  description: string; //AccountName
  debit: string; //Debit
  credit: string; //Credit
  trialBalId: number; // From DB
  accountUUID: string; // From DB
  rowType: 'DEPRECIATION' | 'AMORTIZATION' | 'TOTAL';
}
