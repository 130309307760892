import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { updateTemplateIdModel } from 'countable@model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { EncodingService } from './encoding.service';

const textResponse: any = {responseType: 'text'};

@Injectable({
  providedIn: 'root'
})
export class ReviewService {
  public ReviewFolderData : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public refreshreviewsData: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public AddFirmTemplateDialog: Subject<any> = new Subject<any>();
  public EngagmentSearchSub: Subject<any> = new Subject<any>();
  public EnagagementTemplateDuplicate: Subject<any> = new Subject<any>();
  public DeleteEngagementTemplate: Subject<any> = new Subject<any>();
  public savechangesbtnstatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public changeTab$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public UpdateReviewJSON: Subject<any> = new Subject<any>();
  public savechangesDialog : Subject<any> = new Subject<any>();
  public viewPage: Subject<any> = new Subject<any>();
  public navigateToRoute: Subject<any> = new Subject<any>();
  public redirection: Subject<any> = new Subject<any>();
  public renameTemplatename: Subject<any> = new Subject<any>();
  public refreshTemplateName: Subject<updateTemplateIdModel> = new Subject<updateTemplateIdModel>();
  public validateChecklist: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private httpClient: HttpClient, handler: HttpBackend, private authService: AuthService, private encodingService: EncodingService) { }

  getUserDetails() {
    return JSON.parse((localStorage.getItem('userDetails')));
  }

  getUserId() :number{
    return this.getUserDetails().useracctid;
  }

  getFirmId() :number{
    return this.getUserDetails().firmUserAcctId;
  }

  getS3FilePath() :string {
    return 'Engagements/'+this.getFirmId()+ '_Firm/Templates/Engagement_Left_flow';
  }

  getMasterFolderPath() :string {
    return 'Engagements/masterTemplate/Engagement_Left_flow';
  }

  getReviewFolderNames(templatesData) {
    return templatesData.map(folder => folder.foldername.toLocaleLowerCase());
  }

  getEngagementReviewTemplateNames(templatesData) {
    return templatesData.map(folder => folder.templates && folder.templates.map(file => file.TemplateName.toLocaleLowerCase())).flat(Infinity);
  }

  getTemplateNamesInFolder(templates: any[]) {
    return templates.map(template => template.TemplateName.toLocaleLowerCase());
  }

  getFormDataS3(s3FileName:string,finalSaveContent) :FormData{
    const jsonString = JSON.stringify(finalSaveContent);
    const blob = new Blob([jsonString],{type: 'text/json;charset=utf-8'});
    const formS3Data: FormData = new FormData();
    const uploadObj = JSON.stringify({
      module: "Engagement_Left_flow: Firm Template",
      firmId: this.getFirmId(),
      status: 0,
      folderName: "null",
      appSyncId: this.getUserId(),
      extension: "comp",
    });
    formS3Data.append('upload',this.encodingService.enData(uploadObj, 'body'));
    formS3Data.append('file',blob,s3FileName);
    return formS3Data;
  }

  getProceduresDataS3(s3FileName:string,finalSaveContent) :FormData {
    const jsonString = JSON.stringify(finalSaveContent);
    const blob = new Blob([jsonString],{type: 'text/json;charset=utf-8'});
    const formS3Data: FormData = new FormData();
    const uploadObj = JSON.stringify({
      module: "Procedures: Firm Template",
      firmId: this.getFirmId(),
      status: 0,
      folderName: "null",
      appSyncId: this.getUserId(),
      extension: "comp",
    });
    formS3Data.append('upload',this.encodingService.enData(uploadObj, 'body'));
    formS3Data.append('file',blob,s3FileName);
    return formS3Data;
  }

  getProceduresDataS3InsideEng(s3FileName:string,finalSaveContent) :FormData {
    const jsonString = JSON.stringify(finalSaveContent);
    const blob = new Blob([jsonString],{type: 'text/json;charset=utf-8'});
    const formS3Data: FormData = new FormData();
    let selectedEngagement = JSON.parse((localStorage.getItem('selectedEngagementObj')));

    const uploadObj = JSON.stringify({
      module: "Inside ENG Worksheets",
      firmId: this.getFirmId(),
      status: 0,
      clientId:selectedEngagement.clientfirmid,
      engagementId:selectedEngagement.engagementsid,
      folderName: "null",
      appSyncId: this.getUserId(),
      extension: "comp",
    });
    formS3Data.append('upload',this.encodingService.enData(uploadObj, 'body'));
    formS3Data.append('file',blob,s3FileName);
    return formS3Data;
  }

  public  getReviewTemplate(loginId:number) :Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(loginId)) + '/review/template';
    return  this.httpClient.get(path, textResponse);
  }

  public BLANK_TEMPLATE = [
    {
      "parent": [
        {
          "childern": [
            {
              "section": "",
              "category": "",
              "moduleid": 0,
              "submoduleid": 0,
              "newsubmodule_smufid": ""
            }
          ],
          "moduleid": 0,
          "modulename": "Add Folder",
          "submoduleid": 0,
          "submodulename": "",
          "newsubmodule_smufid": ""
        },
        {
          "childern": [
            {
              "section": "Signoffs",
              "category": "Module",
              "moduleid": 5,
              "submoduleid": 108,
              "newsubmodule_smufid": "2f210e74-00d"
            }
          ],
          "moduleid": 5,
          "modulename": "Module",
          "submoduleid": 108,
          "submodulename": "Signoffs",
          "newsubmodule_smufid": "2f210e74-00d"
        }
      ]
    }
  ]
}
