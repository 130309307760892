import { AmortizationInputsModel, AmortizationScheduleModel, CategoryModel, ScheduleAdjustmentModel } from './cass-prompt.model';
import { ContextApiInputModel, GLSelectionModel, LucaFileModel } from './luca.model';

export class ChatsModel {
  id: number;
  firmUserId: number;
  title: string;
  promptId: number;
  filePath: string;
  engagementId: number;
  parentChatId: number;
  isPinned: boolean = false;
  pinnedAt: string;
  isDelete: boolean;
  createdAt: string;
  tokensUsed: number;
  updatedAt: string;
  isUpdateContext: any;
  context: any;
  titleWithDateTime: string;
  freeContext: ContextApiInputModel;
  isFreeContextUpdated: boolean = false;
  createdAtApi: string;
}

export class AiDownloadModel {
  inputHtml: string;
  exportType: 'PDF' | 'XLSX' | 'DOCX';
  hasSystemFile: boolean = false;
  filePath: string = '';
  fileName: string;
  isSaveToEngagement: boolean = false;
  engagementId: number = 0;
}

export class FeedbackBugModel {
  engagementId: number;
  description: string;
  engagementName: string;
  files: File[];
  chatId: number;
  type: 'BUG' | 'FEEDBACK';
}

export class contextTrialBalanceModel {
  isCYSelected: boolean = false;
  isPY1Selected: boolean = false;
  isPY2Selected: boolean = false;
  trialBalanceIds: number[];
  selectedFinGroupIds: number[];
}

export class glModel {
  generalLedger: contextTrialBalanceModel
}

export class SavedChatHistoryModel {
  chat_id: number;
  context: any;
  engagementId: number;
  filepathPromptGeneratedFile: string;
  messages: (ChatUserMsgModel | ChatLucaMsgModel)[] = [];
  promptId: number = 0;
  freeContext: ContextApiInputModel = new ContextApiInputModel();
}

export type PROMPT_MSG_TYPES =
  | 'GENERAL_LEDGER'
  | 'AR'
  | 'VARIANCE_ANALYSIS_INPUTS'
  | 'IS'
  | 'HST'
  | 'BR'
  | 'LAS'
  | 'UF'
  | 'DFA'
  | 'CAAS_PREFERENCES'
  | 'CAAS_SCHEDULE'
  | 'CAPITAL_ASSETS'
  | 'FR'

export type INPUT_PROMPT_TYPES =
  'FILE'
  | 'GENERAL_LEDGER'
  | 'CAAS_PREFERENCES'
  | 'CAAS_SCHEDULE'
  | 'VARIANCE_ANALYSIS_INPUTS'

export class ChatMsgTypeModel {
  timestamp: string;
  messageType: MESSAGE_TYPE;
  specialPromptMessage: PROMPT_MSG_TYPES;
  specialPromptData: any;
}

export class ChatUserMsgModel extends ChatMsgTypeModel {
  userPrompt: string;
  userPromptInputs: any;
  inputTokens: number;
  userInputFileNames: string[];
  messageFrom: string;
  promptType: 'FILE_INPUT' | 'GENERAL_LEDGER' | 'VARIANCE_ANALYSIS' | 'AMORTIZATION_INPUTS';
  promptInfo: any;// Inputs for  above promptType
}

export class ChatLucaMsgModel extends ChatMsgTypeModel {
  lucaResponse: string;
  lucaResponseHtml: string;
  lucaResponseType: string;
  lucaMarkdownResponse: string;
  lucaOutputFilePath: string;
  outputTokens: number;
  messageFrom: string;
  showSpinner: boolean = false;
  loadingContent: string;
  promptType: PROMPT_MSG_TYPES;
  promptInfo: any;// Inputs for  above promptType
}

export class LucaInputsModel<T = any> {
  inputType: PROMPT_MSG_TYPES;
  data: T;
  index: number;
  glSelection: GLSelectionModel;
  isDisabled: boolean = false;
  adjustmentEntries: ScheduleAdjustmentModel[];
  scheduleRows: AmortizationScheduleModel[];
  year: string;
  matPercent: number;
  matVal: number;
  selectedFiles: File[] | null;
  prefAccounts: AmortizationInputsModel[] = [];
  prefCategories: CategoryModel[] = [];
  tbAccounts: any[] = [];
  generalLedger: any[] = [];
  hasConfirmation: boolean = false;
  attachedFiles: LucaFileModel[] | null = [];
  message: string = '';
  hasProceedAction?: boolean;
  amortizationInputs: AmortizationInputsModel[] = [];
  hasInValidFile?: boolean = false;
}

export type CHAT_MESSAGE_TYPE = 'user' | 'assistant' | 'workflow' | 'SYSTEM_AI' | 'SYSTEM_USER'
  | 'USER' | 'ASSISTANT' | 'WORKFLOW' | 'system_user';

export type MESSAGE_TYPE = 'USER' | 'ASSISTANT' | 'WORKFLOW' | 'SYSTEM_AI' | 'SYSTEM_USER';

export class SaveChatMsgModel {
  filePath: string;
  message: any;
  messages: any[];
}

export class ChatListModel {
  displayKey: string;
  displayList: ChatsModel[];
  isExpanded: boolean = false;
  id: number;
}

export class SaveExcelModel {
  prompt_type: string;
  json_data: any;
  clientName: string;
  engagement_name: string;
  finanicalyearenddate: string;
}

export class ChatActionModel {
  actionType: 'START_CHAT' | 'DELETE_CHAT' | 'SELECT_CHAT' | 'FEEDBACK' | 'NAVIGATE' | 'RENAME_CHAT';
  chat: ChatsModel;
  performAction: boolean = false;
  feedbackInfo?: FeedbackBugModel = null;
  route?: string;
  isActiveSession: boolean = false;
}
