

export class LetterTemplateViewModel {

  isTypeTemplate: 'firm' | 'master';
  isEditMode: boolean;
  templateData: any;
  s3FilePath: string;
  routingName: string;
  isDefaultTemplate: 0 | 1;
  subTemplateType: 'letter' | 'management' = 'letter';
}
export class copyFileS3Model {
  readonly status: number = 0;
  readonly sourceFolder: string = 'Engagements/masterTemplate';
  readonly module: number = 11;
  readonly folderName: string = "UserTemplate";
  destinationFolder: string;
  fileName: string;
  destFileName: string;
  firmId: number;
  appSyncId: number;
  signatureKey: string;
}

export class DuplicateFileS3Model {
  readonly status: number = 0;
  readonly module: number = 11;
  readonly folderName: string = "UserTemplate";
  sourceFolder: string;//same as destinationFolder -->Folder path in s3
  destinationFolder: string;//same as sourceFolder -->Folder path in s3
  signatureKey: string; //same as sourceFolder,destinationFolder --> Folder path in s3
  fileName: string; //same as destFileName --> Filename to saved in s3
  filenames: string[];// Saving Multiple files in S3
  destinationFileNames: string[]; // Saving Multiple files in S3
  destinationFileName: string;//same as fileName --> Filename to saved in s3
  firmId: number;
  appSyncId: number; //loginId
}

export class saveTemplateModel {
  readonly templatetype: string = 'engagement';
  mastertemplateid: number = null;
  JsonFileName: string;
  JsonFilePath: string;
  TemplateName: string;
  templatefolderid: number;
  loginid: number;
  sub_template_type = 'engagement';
}

export class DuplicateTemplateModel {
  readonly templatetype: string = 'engagement';
  mastertemplateid: number | null = null;
  JsonFileName: string;
  JsonFilePath: string;
  TemplateName: any; // Type any for assigning Fun for changing name dynamicaaly
  templatefolderid: number;
  sub_template_type: string;
  loginid: number;
}

export class updateTemplateIdModel {
  FirmTemplateID: number;
  TemplateName: string;
  JsonFileName: string;
  JsonFilePath: string;
  loginid: number;
}

export class deleteLetterTModel {
  FirmTemplateID: number;
  templatefolderid?: number;
  loginid: number;
  from?:'Engagement' | 'Letter' |'Worksheet';
}

export class deleteLetterFModel {
  templatefolderid: number;
  loginid: number;
}

export class ImageModel {
  imageId: number;
  s3FilePath: string;//jsonFilePath
  s3ImagePath: string = '';//s3Imagefilepath dynamically added thoeugh a API call
  isDefaultFrom: 'db' | 's3' | 'dialog'; //to see the Header/Footer Obj emerging from
  isImageType: 'header' | 'footer';
}

export class MoveTemplateModel {
  templateFolderId: number;
  loginId: number;
  firmTemplateId: number;
  templateName: string;
}

export class EditDisableModel {
  firmTemplateId: number;
  isEditMode: boolean;
}

export class setDefaultModel {
  firmtemplateid: number;
  loginid: number;
}

export class ElementsModel {
  id: number;
  header: string;     //Name dispaly on the Elements
  content: string;    //to display the content for automatation inside engagement
  idForCss: string;   //idForCss
  showPreview: boolean;  //element showns in the the preview part
}

export class RenameFolderModel {
  templatefolderid: number;
  foldername: string;
  loginid: number;
}

export class S3EngLetterModel {
  readonly module = "Inside ENG Left Section";
  readonly status: number = 0;
  readonly folderName: string = "Engagement Letter";
  firmId: number;
  clientId: number;
  engagementId: number;
  appSyncId: string;
}

export class engLetterS3Model {
  readonly status: number = 0;
  readonly foldername: string = 'Engagement Letter';
  filenames: string[];
  destinationFileNames: string[];
  firmId: number;
  appSyncId: number;
}

export class TemplateEngModel {
  firmTemplateId: number = null;
  templateName: string;
  engagementId: number;
  loginId: number;
  jsonFileName: string;
  jsonFilePath: string;
  submoduleid: number;
  masterTemplateId: number = null;
}

export class AutoElements {
  canadaelements: Array<ElementsModel> = [
    {
      id : 1,
      idForCss : 'tdate',
      header : 'Date',
      content : 'Date',
      showPreview :true
    },
    {
      id : 2,
      idForCss : 'tentityname',
      header : 'Entity Name',
      content : 'Entity Name',
      showPreview :true
    },
    {
      id : 3,
      idForCss : 'tcladdress',
      header : 'Addressee - Management',
      content : 'Addressee - Management',
      showPreview :false
    },
    {
      id : 4,
      idForCss : 'tcladdress',
      header : 'Addressee - Board of directors',
      content : 'Addressee - Board of directors',
      showPreview :false
    },
    {
      id : 5,
      idForCss : 'tcladdress',
      header : 'Addressee - Shareholders',
      content : 'Addressee - Shareholders',
      showPreview :false
    },
    {
      id : 6,
      idForCss : 'tcydate',
      header :  'Year end date (CY)',
      content : 'Year end date (CY)',
      showPreview :false
    },
    {
      id : 7,
      idForCss : 'tpydate',
      header :  'Year end date (PY)',
      content : 'Year end date (PY)',
      showPreview :false
    },
    {
      id : 8,
      idForCss : 'tnotex',
      header : 'Note X',
      content : 'Note X',
      showPreview :false
    },
    {
      id : 9,
      idForCss : 'tccity',
      header : 'Client City',
      content : 'Client City',
      showPreview :false
    },
    {
      id : 10,
      idForCss : 'tcstatate',
      header : 'City, Province',
      content : 'City, Province',
      showPreview :false
    },
    {
      id : 11,
      idForCss : 'taddress',
      header : 'Complete address',
      content : 'Complete address',
      showPreview :true
    },
    {
      id : 12,
      idForCss : 'tpostal',
      header : 'Postal Code',
      content : 'Postal Code',
      showPreview :false
    },
    {
      id : 13,
      idForCss : 'tfirst',
      header : 'First Name',
      content : 'First Name',
      showPreview :false
    },
    {
      id : 14,
      idForCss : 'tlast',
      header : 'Last Name',
      content : 'Last Name',
      showPreview :false
    },
    {
      id : 15,
      idForCss : 'tworkbook',
      header : 'Balance Sheet',
      content : 'Balance Sheet',
      showPreview :false
    },
    {
      id : 16,
      idForCss : 'tworkbook',
      header : 'Statement of income and Retained Earnings',
      content : 'Statement of income and Retained Earnings',
      showPreview :false
    },
  ];
  uscompilationelements: Array<ElementsModel> = [
    {
      id : 1,
      idForCss : 'tdate',
      header : 'Date',
      content : 'Date',
      showPreview :true
    },
    {
      id : 2,
      idForCss : 'tentityname',
      header : 'Entity Name',
      content : 'Entity Name',
      showPreview :true
    },
    {
      id : 3,
      idForCss : 'tcladdress',
      header : 'Addresse - Management',
      content : 'Addresse - Management',
      showPreview :false
    },
    {
      id : 4,
      idForCss : 'tcladdress',
      header : 'Addressse - Board of directors',
      content : 'Addressse - Board of directors',
      showPreview :false
    },
    {
      id : 5,
      idForCss : 'tcladdress',
      header : 'Addresse - Shareholders',
      content : 'Addresse - Shareholders',
      showPreview :false
    },
    {
      id : 6,
      idForCss : 'tcydate',
      header : 'Year end date (CY)',
      content : 'Year end date (CY)',
      showPreview :false
    },
    {
      id : 7,
      idForCss : 'tpydate',
      header : 'Year end date (PY)',
      content : 'Year end date (PY)',
      showPreview :false
    },
    {
      id : 8,
      idForCss : 'tnotex',
      header : 'Note X',
      content : 'Note X',
      showPreview :false
    },
    {
      id : 9,
      idForCss : 'tccity',
      header : 'Client City',
      content : 'Client City',
      showPreview :false
    },
    {
      id : 10,
      idForCss : 'tcstatate',
      header : 'City, State',
      content : 'City, State',
      showPreview :false
    },
    {
      id : 11,
      idForCss : 'taddress',
      header : 'Complete address',
      content : 'Complete address',
      showPreview :true
    },
    {
      id : 12,
      idForCss : 'tpostal',
      header : 'Postal Code',
      content : 'Postal Code',
      showPreview :false
    },
    {
      id : 13,
      idForCss : 'tfirst',
      header : 'First Name',
      content : 'First Name',
      showPreview :false
    },
    {
      id : 14,
      idForCss : 'tlast',
      header : 'Last Name',
      content : 'Last Name',
      showPreview :false
    },
    {
      id : 15,
      idForCss : 'tbs',
      header : 'Balance Sheet',
      content : 'Balance Sheet',
      showPreview :false
    },
    {
      id : 16,
      idForCss : 'tsire',
      header : 'Statement of income and Retained Earnings',
      content : 'Statement of income and Retained Earnings',
      showPreview :false
    },
    {
      id : 17,
      idForCss : 'tsc',
      header : 'Statement of cashflow',
      content : 'Statement of cashflow',
      showPreview :false
    }
  ];
  usreviewelements: Array<ElementsModel> = [
    {
      id : 1,
      idForCss : 'tdate',
      header : 'Date',
      content : 'Date',
      showPreview :true
    },
    {
      id : 2,
      idForCss : 'tentityname',
      header : 'Entity Name',
      content : 'Entity Name',
      showPreview :true
    },
    {
      id : 3,
      idForCss : 'tcladdress',
      header : 'Addressee - Management',
      content : 'Addressee - Management',
      showPreview :false
    },
    {
      id : 4,
      idForCss : 'tcladdress',
      header : 'Addressee - Board of directors',
      content : 'Addressee - Board of directors',
      showPreview :false
    },
    {
      id : 5,
      idForCss : 'tcladdress',
      header : 'Addressee - Shareholders',
      content : 'Addressee - Shareholders',
      showPreview :false
    },
    {
      id : 6,
      idForCss : 'tcydate',
      header : 'Year end date (CY)',
      content : 'Year end date (CY)',
      showPreview :false
    },
    {
      id : 7,
      idForCss : 'tpydate',
      header : 'Year end date (PY)',
      content : 'Year end date (PY)',
      showPreview :false
    },
    {
      id : 8,
      idForCss : 'tnotex',
      header : 'Note X',
      content : 'Note X',
      showPreview :false
    },
    {
      id : 9,
      idForCss : 'tccity',
      header : 'Client City',
      content : 'Client City',
      showPreview :false
    },
    {
      id : 10,
      idForCss : 'tcstatate',
      header : 'City,State',
      content : 'City,State',
      showPreview :false
    },
    {
      id : 11,
      idForCss : 'taddress',
      header : 'Complete address',
      content : 'Complete address',
      showPreview :true
    },
    {
      id : 12,
      idForCss : 'tpostal',
      header : 'Postal Code',
      content : 'Postal Code',
      showPreview :false
    },
    {
      id : 13,
      idForCss : 'tfirst',
      header : 'First Name',
      content : 'First Name',
      showPreview :false
    },
    {
      id : 14,
      idForCss : 'tlast',
      header : 'Last Name',
      content : 'Last Name',
      showPreview :false
    },
    {
      id : 15,
      idForCss : 'tworkbook',
      header : 'Balance Sheet',
      content : 'Balance Sheet',
      showPreview :false
    },
    {
      id : 16,
      idForCss : 'tworkbook',
      header : 'Statement of income and Retained Earnings',
      content : 'Statement of income and Retained Earnings',
      showPreview :false
    },
    {
      id : 17,
      idForCss : 'tworkbook',
      header : 'Statement of cashflow',
      content : 'Statement of cashflow',
      showPreview :false
    },
    {
      id : 18,
      idForCss : 'tworkbook',
      header : 'Statement of changes in stockholders equity',
      content : 'Statement of changes in stockholders equity',
      showPreview :false
    },
  ];
}

export class AutoStringElements {
  canadaElements: string[] = [
    "Date",
    "Entity Name",
    'Addresse - Management',
    'Addresse - Board of directors',
    'Addresse - Shareholders',
    "Year end date (CY)",
    "Year end date (PY)",
    "Note X",
    "Client City",
    "City, Province",
    "Complete address",
    "Street Address",
    'Firm address',
    "Postal Code",
    "First Name",
    "Last Name",
    "Balance Sheet",
    'Statement of income and Retained Earnings',
    'Firm Name',
    'Firm Signature',
    'Client Signature',
    'Client Signed Date',
    'Partner Name'
  ]
  usCompilationElements: string[] = [
    "Date",
    "Entity Name",
    "Addresse - Management",
    "Addresse - Board of directors",
    "Addresse - Shareholders",
    "Year end date (CY)",
    "Year end date (PY)",
    "Note X",
    "Client City",
    "City, State",
    "Complete address",
    "Postal Code",
    "First Name",
    "Last Name",
    "Balance Sheet",
    "Statement of income and Retained Earnings",
    "Statement of cashflow",
    "Firm Signature",
    'Client Signature',
    'Partner Name'
  ]
  usReviewElements: string[] = [
    "Date",
    "Entity Name",
    'Addresse - Management',
    'Addresse - Board of directors',
    'Addresse - Shareholders',
    "Year end date (CY)",
    "Year end date (PY)",
    "Note X",
    "Client City",
    "City,State",
    "Complete address",
    "Street Address",
    'Firm address',
    "Postal Code",
    "First Name",
    "Last Name",
    "Balance Sheet",
    "Statement of income and Retained Earnings",
    "Statement of cashflow",
    "Statement of changes in stockholders equity",
    'Firm Name',
    "Firm Signature",
    'Client Signature',
    'Client Signed Date',
    'Partner Name'
  ]
}
