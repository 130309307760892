export class TBStaticInfo {
  mappingStatus: MapStatusModel[] = null;

}

export class MapStatusModel {
  id: number;
  status: string;
  checked?: boolean = false;
  mappingstatusid: number;
}

export class CFStatusModel {
  id: number;
  name: string;
  description: string;
  isDeleted: false;
}

export class FinGroupModel {
  fingroupid: number;
  fingroupname: string;
}
