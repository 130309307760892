import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-file-preview-general',
  templateUrl: './general-popup.component.html',
  styleUrls: ['./general-popup.component.scss']
})
export class GeneralPopupComponent implements OnInit, OnChanges {

  static readonly O_STORAGE_PREFIX = 'o-';
  static readonly O_ERROR_TEXT = 'The file can not be shown!';
  static readonly O_ERROR_TITLE = 'Technical error!';

  @Input() fileType: string;
  @Input() isInline: boolean;
  @Input() hash: string;
  @Input() viewMode = 'view';

  public demoEndpoints: any;
  public fileExtension: string;
  url: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer, private toaster: ToastrService) {
  }

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(localStorage.getItem('docUrl'));
  }

  ngOnChanges() {
    if (!this.hash || !localStorage.getItem(GeneralPopupComponent.O_STORAGE_PREFIX + this.hash + '-docUrl')) {
      this.toaster.error(GeneralPopupComponent.O_ERROR_TEXT, GeneralPopupComponent.O_ERROR_TITLE);
      return;
    }

    this.demoEndpoints = localStorage.getItem(GeneralPopupComponent.O_STORAGE_PREFIX + this.hash + '-docUrl');
    this.fileExtension = localStorage.getItem(GeneralPopupComponent.O_STORAGE_PREFIX + this.hash + '-fileExtention');
  }

}
