export class CurrentPasswordValidationModel {

  public isValid: boolean = false;
  public isLengthValid: boolean = false;

  public constructor() {
  }

  public validate(password: string) {
    if (password && password.length > 0) {
      this.isLengthValid = password.length >= 5; // Using

      this.isValid = (this.isLengthValid);
    }
  }
}
