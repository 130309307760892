// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-sidebar mat-list-item,
.left-sidebar .mdc-list-item {
  overflow: visible;
}
.left-sidebar mat-list-item .mdc-list-item__content,
.left-sidebar .mdc-list-item .mdc-list-item__content {
  overflow: visible;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dashboard/side-menu/side-menu.component.scss"],"names":[],"mappings":"AAKI;;EAEI,iBAAA;AAJR;AAMQ;;EACI,iBAAA;AAHZ","sourcesContent":["// @import '../../../../assets/scss/global/global';\n\n// @import '../../../../assets/scss/layout/dashboard';\n\n.left-sidebar {\n    mat-list-item,\n    .mdc-list-item {\n        overflow: visible;\n\n        .mdc-list-item__content {\n            overflow: visible;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
