import { ApiService } from '../services/api.service';
import { AsyncValidatorFn, AsyncValidator, NG_ASYNC_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { Directive } from '@angular/core';
import { map, catchError } from 'rxjs/operators';

export function UniqueClientEmailValidator(apiService: ApiService): AsyncValidatorFn {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        let useraccountId,loggedInUserData,clientEditData, clientEmail;
        loggedInUserData = JSON.parse((localStorage.getItem('userDetails')));
        if (localStorage.getItem("clientListObj")) {
            clientEditData = JSON.parse((localStorage.getItem('clientListObj')));
        }
        // if (localStorage.getItem("settingsUserAccessTeamMemberData")) { //coming from Settings > teamaccess
        //     teamEditData = JSON.parse((localStorage.getItem('settingsUserAccessTeamMemberData')));
        // }
        if(clientEditData){
            // if (localStorage.getItem("settingsUserAccessTeamMemberData")) {
            //     clientEmail = clientEditData.email;
            // }
            // else {
            //     clientEmail = clientEditData.emailaddress;
            // }
            clientEmail = clientEditData.emailAddress;
        }
        if (loggedInUserData && loggedInUserData.useracctid) {
            useraccountId = loggedInUserData.useracctid;
        }
        const inputData = {
            "checkemail":
                [{
                    "emailaddress": c.value,
                    "validatestatus": 0,
                    "loginid": useraccountId
                }]
        }
        const inputString = JSON.stringify(inputData);
        let getData = {
            "procedureName": "validateuseremailforclient",
            "inputParameters": inputString
        }
        let debounceTime = 200; //milliseconds
        return timer(debounceTime).pipe(() => {
            if(clientEmail == c.value){
                return of(null);
            }
            else{
                return apiService.getData(getData).pipe(
                    map(res => {
                        let result: any = [];
                        result = JSON.parse(res);
                        return result[0].validatestatus === 1 ? { 'uniqueEmail': true } : null;
                    }),
                    catchError(() => of(null))
                );
            }
        });
    };
}

@Directive({
    selector: '[uniqueEmail][formControlName],[uniqueEmail][formControl],[uniqueEmail][ngModel]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: UniqueClientEmailValidatorDirective, multi: true }]
})
export class UniqueClientEmailValidatorDirective implements AsyncValidator {
    constructor(public apiService: ApiService) { }
    validate(c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return UniqueClientEmailValidator(this.apiService)(c);
    }
}
