import {Component, Inject, LOCALE_ID, OnInit, ViewEncapsulation} from '@angular/core';
import {formatDate, Location} from "@angular/common";
import {TimeTrackerService} from 'src/app/services/time-tracker.service';
import {MatTableDataSource} from '@angular/material/table';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {SharedService} from '../../shared/shared.service';
import {Subscription} from 'rxjs';

let openedWorkbookWindow = [];
@Component({
  selector: 'app-timetracker-dashboard-header',
  templateUrl: './timetracker-dashboard-header.component.html',
  styleUrls: ['./timetracker-dashboard-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimeTrackerDashboardHeaderComponent implements OnInit {
  displayedColumns: string[] = ['checkbox', 'clientName', 'engagementId', 'activeTime', 'idleTime', 'logtime'];
  loggedInUserData: any;
  userLoginId: any;
  timeEntries: any = [];
  timerackingCount: any;
  isDisableLogall = true;
  allEntriesCheked = false;
  singleData: any;
  timerStatus: string;
  engagementId: number;
  refreshRecordBtnSub: Subscription;

  constructor(
      private loc: Location,
      private sharedService: SharedService,
      private trackerService: TimeTrackerService,
      private toastr: ToastrService,
      private spinner: NgxSpinnerService,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit() {
    this.userLoginId = this.sharedService.getUserData("field", "useracctid");
    this.getDropdownTimeEntries();
    this.refreshRecordBtnSub = this.sharedService.refreshRecordBtn.subscribe( res => {
      if(res)
        this.getDropdownTimeEntries();
    })
  }

  openTImeEntries() {
    const angularRoute = this.loc.path();
    const url = window.location.href;
    const domainAndApp = url.replace(angularRoute, '');
    const newWindowUrl = domainAndApp + '/mytime-tracker';
    if (openedWorkbookWindow.length > 0) {
      openedWorkbookWindow.map(ele => ele.close());
    }
    let WorkbookWindow = window.open(newWindowUrl, "_blank", 'location=yes,height=640,width=1467,resizable=no,scrollbars=yes,status=yes, fullscreen=no, left=400,top=250');
    openedWorkbookWindow.push(WorkbookWindow);
  }
  getDropdownTimeEntries() {
    //this.spinner.show();
    this.trackerService.getDropdownTimeEntries(this.userLoginId).subscribe(res => {
      if (res) {
        let data = JSON.parse(res);
        this.timerStatus = localStorage.getItem('timerStatus');
        this.engagementId = +localStorage.getItem("engagementID");
        this.timerackingCount = data.count;
        this.bindMatGrid(data.data == '[]' ? [] : data.data);
        //this.spinner.hide()
      }

    }, error => {
      //this.spinner.hide();
    });
  }
  bindMatGrid(data) {
    this.timeEntries = new MatTableDataSource(data);
  }
  singleCheck() {
    let totalDataCount = this.timeEntries.data.length;
    let checkedCount = this.timeEntries.data.filter(ele => ele.checked).length;
    if (checkedCount > 1)
      this.isDisableLogall = false;
    else
      this.isDisableLogall = true;
    if (totalDataCount == checkedCount)
      this.allEntriesCheked = true;
    else
      this.allEntriesCheked = false;
  }
  multiCheck() {
    if (this.allEntriesCheked) {
      this.timeEntries.data.forEach(ele => {
        ele.checked = true;
        this.isDisableLogall = false;
      })
    }
    else {
      this.timeEntries.data.forEach(ele => {
        ele.checked = false;
        this.isDisableLogall = true;
      })
    }
  }
  saveSingleLog(element) {
    let endTime, endTimeWithSec;
    this.singleData = element;
    endTime = formatDate(new Date(),'hh:mm a', this.locale);
    endTimeWithSec = formatDate(new Date(),'yyyy-MM-dd HH:mm:ss', this.locale);
    let requestObj = { "engagementsId": this.singleData.engagementsId, "loginId": this.userLoginId, "endTime": endTime,"automaticEndTime": endTimeWithSec }
    this.spinner.show();
    this.trackerService.logTimeEntries(requestObj).subscribe( res => {
      if(res){
        this.toastr.success("Time entry logged successfully");
        this.getDropdownTimeEntries();
        this.trackerService.timeIconCount().subscribe( count => this.sharedService.updateTimerCount.next(count));
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
    });
  }
  saveMultiLog(){
    let endTime, endTimeWithSec;
    endTime = formatDate(new Date(),'hh:mm a', this.locale);
    endTimeWithSec = formatDate(new Date(),'yyyy-MM-dd HH:mm:ss', this.locale);
    let requestObj = { "engagementsId": 0, "loginId": this.userLoginId, "endTime": endTime, "automaticEndTime": endTimeWithSec }
    this.spinner.show();
    this.trackerService.logTimeEntries(requestObj).subscribe( res => {
      if(res){
        this.allEntriesCheked = false;
        this.isDisableLogall = true;
        this.toastr.success("Time entries logged successfully");
        this.getDropdownTimeEntries();
        this.trackerService.timeIconCount().subscribe( count => this.sharedService.updateTimerCount.next(count));
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
    });
  }
  ngOnDestroy(){
    if(this.refreshRecordBtnSub)
      this.refreshRecordBtnSub.unsubscribe();
  }
}
