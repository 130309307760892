import {Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import {DocumentPreviewTypes, STATEMENT_VIEW_TYPES} from 'countable@helpers';
import {UserDetailModel} from 'countable@model';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {TimeTrackerService} from 'src/app/services/time-tracker.service';
import {idleTime, modules} from 'src/app/services/app-config';
import {ApiService} from 'src/app/services/api.service';
import {SharedService} from 'src/app/components/shared/shared.service';
import {AdobeExportService, FsExportService, SpinnerService} from 'countable@services';


interface BalanceSheetTree {
  name: string;
  children?: BalanceSheetTree[];
  grouptotal?: string;
  subgrouptotal?: string;
  total?: string;
  bal?: any;
}

/** Flat node with expandable and level information */
interface TreeFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'balance-sheet-preview',
  templateUrl: './balance-sheet-preview.component.html',
  styleUrls: ['./balance-sheet-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe]
})
export class BalanceSheetPreviewComponent implements OnInit, OnDestroy {
  treeControl = new NestedTreeControl<BalanceSheetTree>(node => node.children);
  dataSource = new MatTreeNestedDataSource<BalanceSheetTree>();
  balanceStatementDetails: any;
  yearEnd: any;
  engementName: any;

  userLoginId: any;
  public userDetails: UserDetailModel | null = null;
  TREE_DATA: any;
  engagementId: any;
  year: number;
  balanceStatementYear: string;
  engagementName: string;
  mappedtotal: any;
  previousYear: any;
  isprevYear: boolean = false;
  balSheetExportItemMenu: boolean = false;
  balanceSheetName: any;
  isEditable: boolean = false;
  isBalanceSheet: boolean = true;
  selectedEngagementDetails: any;
  isBalSheetUpdateSubscription: any;
  hideItem: boolean;
  currentYearFBS: any;
  previousYearFBS: any;
  headerNotesColumn1: string;
  headerNotesColumn2: string;
  headerNotesColumn3: string;
  headerSub: Subscription;
  refreshBalanceSheet: Subscription;
  submoduleId: any;
  selectedEngagementDetailsCopy: any;
  clientName: any;
  unmappedArray = [];
  isFromFS = 0;
  showDraftSub: Subscription;
  isShowDraft: boolean = false;
  trackobj: { engagementsId: any; submoduleId: any; submoduleUuid: any; };
  userActivity;
  userInactiveSub: Subscription;
  userInactive: Subject<any> = new Subject();
  balanceSheetDetails: any = [];
  footerNotes: any = '';
  countryId: number;
  NotesExists: any;
  balanceSheetData:any;
  balanceSheetRefreshOnChange:Subscription;

  // Approved by board section
  approvalText: string;
  boardMembers: any[];
  boardMembersSub: Subscription;
  isShowAppByBoard: boolean;
JSOjson
  // Balance sheet page break
  blPageBreakSub: Subscription;
  isPageBreakForBl: boolean;
  balancesheetlinkednotesExists: any=false;
  isWorkBook:any;

  public subscriptions: Subscription = new Subscription();
  public balanceSheet$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public placeOfPreview: STATEMENT_VIEW_TYPES = "None";
  public imageUrl: string = 'src/assets/images/PDF-watermark.png';

  // footer text
	footerText: string = '';
	isHideNotesToFS = false;

  @HostListener('window:mousemove', ["$event"])
  @HostListener('window:resize', ["$event"])
  @HostListener('window:keydown', ["$event"])
  refreshUserState() {
    clearTimeout(this.userActivity);
    if (localStorage.getItem("timerStatus") == "Idle")
      this.trackerService.startTimer("Active");
    this.setTimeout();
  }
  @HostListener('window:storage', ['$event'])
  onStorageChange(ev: StorageEvent) {

    this.NotesExists = localStorage.getItem('NotesExists')
    if (ev.key == 'isBalancesheetPageBreakApplied') {
      this.isPageBreakForBl = JSON.parse((localStorage.getItem('isBalancesheetPageBreakApplied')))
    }
    if (ev.key == 'refreshFinancialStatements-BS' || ev.key == 'refreshFinancialStatements-LSG') {
      console.log("Local-Storage-Keys-Changed-In-Balance-Sheet",this.router.url)
      if (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/BalanceSheet') {
        // this.getFSPreview();
        this.fsService.removeFsDocPreviewPage(DocumentPreviewTypes.BALANCE_SHEET);
        this.setBalanceSheetView();
      } else {
        localStorage.removeItem("refreshFinancialStatements-BS");
      }

    }
    if (ev.key == 'isfooterUpdated') {
      const note = localStorage.getItem('isfooterUpdated');
      this.updateFooterText(note);
    }
  }

  constructor(private apiservice: ApiService,
              private apiService: ApiService,
              private sharedservice: SharedService,
              private toaster: ToastrService,
              private spinner: NgxSpinnerService,
              private datepipe: DatePipe, public router: Router,
              public dialog: MatDialog,
              private trackerService: TimeTrackerService,
              private exportService: AdobeExportService,
              private fsService: FsExportService,
              private renderer: Renderer2,
              private el: ElementRef,
              private spinnerService: SpinnerService)
  {
              this.setTimeout();
              this.userInactive.subscribe(() => {
                this.trackerService.startTimer("Idle");
              });
              console.log("Active-Routing-URL",this.router.url);
              this.placeOfPreview = this.sharedservice.setPlaceofPreview(this.router.url);
              console.log("placeOfPreview",this.placeOfPreview);
    //this.dataSource.data = this.TREE_DATA;
  }
  hasChild = (_: number, node: BalanceSheetTree) => !!node.children && node.children.length > 0;
  //@ViewChild('tree') tree;

  ngOnInit() {
    localStorage.removeItem('workbookChangeDate');
    localStorage.removeItem('workbookChangeYearEnd');
    localStorage.removeItem('isLastYear');
    this.selectedEngagementDetails = JSON.parse(localStorage.getItem("selectedEngagementObj"));
    this.submoduleId = (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/BalanceSheet')?  JSON.parse(localStorage.getItem('FsSubmoduleId')) : JSON.parse(localStorage.getItem('workBookSubmoduleId'));
    setTimeout(() => {
      this.setBalanceSheetView();
    }, 1000);

  //  this.getFSNotes();
  //  this.getHeaderTitles();

    this.subscriptions.add(
      this.balanceSheet$.pipe(distinctUntilChanged()).subscribe({
        next: balanceSheetData => {
          if(balanceSheetData) {
            // document.getElementById("balance-sheet-preview").innerHTML= balanceSheetData;
            const view = this.el.nativeElement.querySelector('div');
            this.renderer.setProperty(view,'innerHTML',balanceSheetData);
            const waterMark = this.sharedservice.showdraft.value;
            const waterMarkUrl = this.sharedservice.waterMarkUrl.getValue();
            if((waterMark && waterMark.isShowDraft) && waterMarkUrl) {
              console.log("background: url('../../../../../../assets/images/PDF-watermark.png') no-repeat 0 0 !important;",waterMark);
              this.renderer.setStyle(view, 'background-image', `url(${waterMarkUrl})`);
            }

            if (this.footerText == '') {
							this.getHeaderTitles();
						} else {
              this.footerText = '';
							document.getElementById("eng_dynamicHeight").innerHTML = balanceSheetData + this.footerText;
              this.getHeaderTitles();
						}

            setTimeout(() => {
              const eleHeight = document.getElementById('eng_dynamicHeight') as HTMLElement;
              console.log(eleHeight.clientHeight)
              if(eleHeight.clientHeight < 759){
                eleHeight.style.height = '760px';
              }
            }, 10);

          }
        }
      })

    )

    this.subscriptions.add(
      this.sharedservice.updateFinStatement$.subscribe({
        next: refreshStatement => {
            if(refreshStatement && refreshStatement.has(DocumentPreviewTypes.BALANCE_SHEET) && refreshStatement.get(DocumentPreviewTypes.BALANCE_SHEET)) {
              this.fsService.removeFsDocPreviewPage(DocumentPreviewTypes.BALANCE_SHEET);
              this.setBalanceSheetView();
            }

        }
      })
    )
    this.isBalSheetUpdateSubscription = this.sharedservice.isBalSheetFooterUpdate.subscribe(resp => {
      if (resp.isfooterUpdated == true) {
        this.updateFooterText(resp?.note);
      }
    })
  }

  setBalanceSheetView() {
    this.subscriptions.add(
      this.fsService.getFsDocsPreviewPage(DocumentPreviewTypes.BALANCE_SHEET).subscribe(resp => {
        if(this.fsService.hasFsDocsPreviewPage(DocumentPreviewTypes.BALANCE_SHEET) && resp) {
          this.balanceSheet$.next(resp);
        } else if(!this.fsService.hasFsDocsPreviewPage(DocumentPreviewTypes.BALANCE_SHEET) && !resp) {
          this.spinnerService.showSpinner();
          this.fsService.fetchFSDocPreview(DocumentPreviewTypes.BALANCE_SHEET,this.placeOfPreview).subscribe({
            next: res => {
              this.balanceSheet$.next(res);
              // Refreshing watermark application from fs settings
              const view = this.el.nativeElement.querySelector('div');
              const waterMark = this.sharedservice.showdraft.value;
              const waterMarkUrl = this.sharedservice.waterMarkUrl.getValue();

              if((waterMark && waterMark.isShowDraft) && waterMarkUrl) {
                this.renderer.setStyle(view, 'background-image', `url(${waterMarkUrl})`);
              } else {
                this.renderer.setStyle(view, 'background-image', "none");
              }

            }
          }).add(() =>{
            this.spinnerService.hideSpinner();
          })
        }
      })
    )
  }


  ngOnInitOldCode() {
    console.log("this.fsService.hasFsDocsPreviewPage(DocumentPreviewTypes.BALANCE_SHEET)",this.fsService.hasFsDocsPreviewPage(DocumentPreviewTypes.BALANCE_SHEET));
    // this.fsService.hasFsDocsPreviewPage(DocumentPreviewTypes.BALANCE_SHEET) && this.setBalanceSheetView();



    localStorage.removeItem("refreshFinancialStatements-BS");
    localStorage.removeItem("refreshFinancialStatements-BSH");
    this.NotesExists = localStorage.getItem('NotesExists')
    this.userDetails = this.fsService.authService.getUserDetail();
    this.userLoginId = this.userDetails.useracctid;
    this.selectedEngagementDetailsCopy = JSON.parse((localStorage.getItem('selectedEngagementObjCopy')));
    this.clientName = this.selectedEngagementDetailsCopy.clientName;
    this.countryId = +this.sharedservice.getUserData('field', 'origin_country_id');
    this.isWorkBook=localStorage.getItem('isWorkBook');
    //this.getBalanceSheetDetails(true);
    this.submoduleId = JSON.parse(localStorage.getItem('workBookSubmoduleId'));
    this.previousYear = localStorage.getItem('PreviousYear');
    if (this.router.url == "/dashboard/ntrdashboard/FinancialStatement/doc/BalanceSheet") {
      this.trackobj = {
        "engagementsId": this.selectedEngagementDetailsCopy.engagementsid,
        "submoduleId": JSON.parse(localStorage.getItem("FsSubmoduleId")),
        "submoduleUuid": localStorage.getItem("UUID"),
      }
      localStorage.setItem("timeTrackerObj", JSON.stringify(this.trackobj));
      this.trackerService.startTimer("Active");
    }
    // this.isBalSheetUpdateSubscription = this.sharedservice.isBalSheetUpdate.subscribe(resp => {
    //   if (resp.isRefresh == true) {
    //     this.year = resp.currentYear;
    //     //this.getBalanceSheetDetails();
    //     this.getFSPreview();
    //   }
    // })
    // this.headerSub = this.sharedservice.isBalSheetHeaderUpdate.subscribe(resp => {
    //   if (resp) {
    //     this.headerNotesColumn1 = resp[0].header[0].changednameline1;
    //     this.headerNotesColumn2 = resp[0].header[0].changednameline2;
    //     this.headerNotesColumn3 = resp[0].header[0].changednameline3;
    //     console.log('checktest='+ this.headerNotesColumn3)
    //   }
    // })
    // this.refreshBalanceSheet = this.sharedservice.refreshBlIS.subscribe(res => {
    //   //this.getBalanceSheetDetails();
    //   res && this.getFSPreview();
    // })

    // this.refreshBalanceSheet =
    // if (this.router.url !== ('/trial-balance-working-sheet/balance-sheet') || ('/dashboard/ntrdashboard/FinancialStatement/doc/financialstatementexport')) {
    //   this.hideItem = true;
    // }
    // else {
    //   this.hideItem = false;
    // }
    //this.getHeaderTitles();
    // let draft = JSON.parse((localStorage.getItem('showdraft')));
    // if (draft) {
    //   this.isShowDraft = draft.isShowDraft ? draft.isShowDraft : '';
    // }
    // this.showDraftSub = this.sharedservice.showdraft.subscribe(res => {
    //   this.isShowDraft = res.isShowDraft;
    // });

    // FS Settings refresh
    // this.boardMembersSub = this.sharedservice.showAppByBoard.subscribe(res => {  // currently not implementing this is new approach
    //   if (res) {
    //     this.approvalText = res.approvalText;
    //     this.isShowAppByBoard = res.isShow;
    //     this.boardMembers = res.boardMembers.filter(member => member.name != "");
    //   }
    // });

    // this.blPageBreakSub = this.sharedservice.blPageBreak.subscribe(res => {
    //   if (res) {
    //     this.isPageBreakForBl = res.isPageBreakForBl;
    //     // console.log("---------bl oninit----------");
    //     // console.log(res);

    //   }
    // });
    // console.log("Left-Side-Folder-Data")
    // let FSData=JSON.parse(localStorage.getItem('FSMenuData'))[0].subchild[0].child;
    // for(let i=0;i<FSData.length;i++){
    //   if(FSData[i].originalfilename=="Statement of Income (Loss)"){
    //     let  separateIncomeStatement =  true;
    //     localStorage.setItem('separateIncomeStatement',separateIncomeStatement.toString());
    //   }
    //   else if(FSData[i].originalfilename=="Statement of Income (Loss) and Retained Earnings (Deficit)"){
    //     let  separateIncomeStatement =  false;
    //     localStorage.setItem('separateIncomeStatement',separateIncomeStatement.toString());
    //   }
    // }


    //Refresh on changes in  FS Settings
    // this.balanceSheetRefreshOnChange=this.sharedservice.balanceSheetRefresh.subscribe((response)=>{
    //   if(response){
    //
    //   }
    // });

  }

  getFSPreview() {
    let boardMemberData: Array<any> = [];
    if( localStorage.getItem('appovedByText') == null){
      this.approvalText = "Approved by";
      localStorage.setItem('appovedByText',this.approvalText)
    }
    if(localStorage.getItem('approvedBy')[0] == 't'){
     let  boardData=JSON.parse(localStorage.getItem('boardMemberData'));
     for(let i=0;i<boardData.length;i++){
      if(boardData[i].name.length!=0) {
      let newBoardMember = {
       name: boardData[i].name.toString(),
       title: boardData[i].title.toString()
      };
      boardMemberData.push(newBoardMember);
    }

  }
    }

      this.balanceSheetData= {
        "engagementsid":Number(localStorage.getItem('engagementID')),
        "acctyear": localStorage.getItem('trailBalYear'),
        "isPageBreak": (localStorage.getItem('isBalancesheetPageBreakApplied'))[0] == 't' ? true : false,
        "oneYear":(localStorage.getItem('isOneYearDataAvailable'))[0] == 't' ? true : false,
        "separateIncome":(localStorage.getItem('separateIncomeStatement'))[0] == 't' ? true : false,
        "isPreview":true,
        "isWord":true,
        "tableOfContents":(localStorage.getItem('isTableOfContentSelected'))[0] == 't' ? true : false,
        "watermark":(localStorage.getItem('showWaterMark'))[0] == 't' ? true : false,
        "approvedBy":boardMemberData.length==0? []:boardMemberData,
        "approvalText": localStorage.getItem('appovedByText')
       };
       this.spinner.show();
       // this.sharedservice.exportService.getFSDocsPreview(this.balanceSheetData).subscribe(res => {
       //   console.log("getFSDocsPreview---",res);
       // })
      this.apiservice.getFSPreview(this.balanceSheetData).subscribe((responseData)=>{
        if(responseData){
          var data = JSON.parse(responseData);
          this.balanceSheetData = data['balanceSheetHtml'];
          document.getElementById("balance-sheet-preview").innerHTML=this.balanceSheetData;
          if(data.watermark!=null){document.getElementById("balance-sheet-preview").style.backgroundImage = "url("+data.watermark+")";}
          this.spinner.hide();
         }
      },(error:any)=>{this.spinner.hide();
        document.getElementById("balance-sheet-preview").innerHTML="Error loading PDF" });


  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), idleTime);
  }



  ngOnDestroy() {
    if (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/BalanceSheet') {
      clearTimeout(this.userActivity);
    }
    if (this.isBalSheetUpdateSubscription) {
      this.isBalSheetUpdateSubscription.unsubscribe();
    }
    if (this.headerSub) {
      this.headerSub.unsubscribe();
    }
    if (this.refreshBalanceSheet) {
      this.refreshBalanceSheet.unsubscribe();
    }
    if (this.showDraftSub) {
      this.showDraftSub.unsubscribe();
    }
    const refreshMap = this.sharedservice.removeAfterRefresh(DocumentPreviewTypes.BALANCE_SHEET);
    this.sharedservice.updateFinStatement$.next(refreshMap);
    this.subscriptions && this.subscriptions.unsubscribe();
    localStorage.removeItem('workbookChangeDate');
    localStorage.removeItem('workbookChangeYearEnd');
    localStorage.removeItem('isLastYear');
    this.fsService.removeFsDocPreviewPage(DocumentPreviewTypes.BALANCE_SHEET);
  }

  getFSNotes() {
    let jsonFileName = this.selectedEngagementDetails.engagementsid + '_notes.json';
    const uploadObj = {
      "module": modules[16],
      "firmId": this.sharedservice.getUserData("field", "firmUserAcctId"),
      "clientId": this.selectedEngagementDetails.clientfirmid,
      "engagementId": this.selectedEngagementDetails.engagementsid,
      "status": 0,
      "folderName": jsonFileName,
      "appSyncId": this.userLoginId,
      "extension": 'comp',
    }

    // this.spinner.show();
    this.apiService.getJsonData(uploadObj).subscribe((response: any) => {
      if (response) {
        const notes = !response ? [] : JSON.parse(response);
        this.isHideNotesToFS = notes.length ? true : false;

        this.footerText = this.isHideNotesToFS ? "<p class='my-custom-footer right-prev-footer' <span>" + this.footerNotes + "</span></p>" : "";
        let preview = document.getElementById("eng_dynamicHeight").innerHTML;
				document.getElementById("eng_dynamicHeight").innerHTML = preview + this.footerText;

        // this.spinner.hide();
      }
    }, (error) => {
      // this.spinner.hide();
    });
  }

  getHeaderTitles() {
    const inputObj = {
      "engagementsid": this.selectedEngagementDetails.engagementsid,
      "submoduleid": this.submoduleId
    }
    const stringifyObj = JSON.stringify(inputObj);
    const finalInputObj = {
      "inputParameters": stringifyObj,
      "procedureName": "getwbheadernote"
    }
    this.apiService.getData(finalInputObj).subscribe(response => {
      if (response) {
        let results = JSON.parse(response);
        this.headerNotesColumn1 = results[0].header[0].changednameline1;
        this.headerNotesColumn2 = results[0].header[0].changednameline2;
        this.headerNotesColumn3 = results[0].header[0].changednameline3;
        this.footerNotes = results[1].note ? results[1].note[0].note : "";
        this.getFSNotes();
      }
    }, error => {
      this.toaster.error(error.error);
    });
  }

  updateFooterText(newFooterNote: string) {
    let preview = document.getElementById("eng_dynamicHeight");
    if (preview) {
      let footerElements = preview.getElementsByClassName("my-custom-footer");
      for (let i = 0; i < footerElements.length; i++) {
        footerElements[i].remove();
      }
      let newFooterText = "<div class='my-custom-footer' style = 'position: absolute; bottom: 0px;left: 0; margin-top: 35px; padding-bottom: 17px; width: 100%; text-align: center;'> <p style = 'font-family: Arial,sans-serif !important; font-size: 12px;'>" + newFooterNote + "</p></div>";
      preview.innerHTML += newFooterText;
    }
  }

}
