import { Component, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-ai-working-stepper-info',
  templateUrl: './ai-working-stepper-info.component.html',
  styleUrl: './ai-working-stepper-info.component.scss'
})
export class AiWorkingStepperInfoComponent {

  @ViewChild('stepper') stepper: MatStepper;

  constructor(private dialogRef: MatDialogRef<any>) {}

  public finish() {
    this.dialogRef.close(true);
  }

  public skip() {
    this.stepper.selectedIndex = this.stepper.steps.length - 1;
  }
}
