import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApprovalMailModel, ClientApproveModel, ClientPortalStatusModel, compArchiveModel, ComponentModel, EngLetterStatusInputs, GenericResModel, HeaderResponseModel, ImageMLModel, ManagementLetter, ML, RearrangeNumbersModel, ReShareToClientModel, SendPackagerEmailModel, ShareToClientModel } from 'countable@model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { addsectionReqModel, deleteSectionReqModel, reorderDataModel } from '../model/eng/add-section.model';
import { EngSaveModel } from '../model/eng/eng-save.model';
import { IntegrationSourceInterface } from '../model/eng/integration-source.interface';
import { EncodingService } from './encoding.service';

const textResponse: any = {responseType: 'text'};

@Injectable({
  providedIn: 'root'
})
export class EngApiService {

  private static readonly engBaseUri = environment.apiV1 + '/eng';
  private static readonly getEngUri = environment.apiV1 + '/integration/get/engagement';
  private static readonly SEND_EMAIL = environment.apiV1 + '/sign-letter-mail';
  private static readonly SEND_PACKAGER_EMAIL = environment.apiV1 + '/mail/assign-packager';
  private static readonly MAP_CHECKLISTS = environment.apiV1 + '/map-checklists';
  private static readonly ADD_SECTION: string = environment.apiV1 + '/engagement-section';
  private readonly GET_ELEMENTS = environment.apiV1 + '/elements';
  public readonly elementSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public readonly elements$: Observable<any> = this.elementSubject.asObservable();
  private readonly ADD_ML = environment.apiV1 + '/management-letter/add';
  private readonly GET_ML_DATA = environment.apiV1 + '/management-letter/get';
  private readonly SHARE_ML_TO_CLIENT = environment.apiV1 + '/management-letter/share';
  private readonly RE_SHARE_ML_TO_CLIENT = environment.apiV1 + '/management-letter/re-share';
  private readonly UN_SHARE_ML_TO_CLIENT = environment.apiV1 + '/management-letter/un-share';
  private readonly REVIEW_ML_BY_CLIENT = environment.apiV1 + '/management-letter/client/review';
  private readonly GET_CLIENT_HEADER = environment.apiV1 + '/management-letter/client/header/get';
  private readonly SAVE_CLIENT_HEADER = environment.apiV1 + '/management-letter/client/header/save';
  private readonly GET_CLIENT_FOOTER = environment.apiV1 + '/management-letter/client/footer/get';
  private readonly SAVE_CLIENT_FOOTER = environment.apiV1 + '/management-letter/client/footer/save';
  private readonly SAVE_CLIENT_SIGNATURE = environment.apiV1 + '/management-letter/client/signature/save';
  private readonly GET_CLIENT_SIGNATURE = environment.apiV1 + '/management-letter/client/signature/get';
  private readonly GET_CLIENT_COMPONENT = environment.apiV1 + '/management-letter/client/components';
  private readonly REMOVE_CLIENT_SIGNATURE = environment.apiV1 + '/management-letter/client/signature/remove';
  private readonly SAVE_CLIENT_COMPONENT = environment.apiV1 + '/management-letter/client/component/save';
  private readonly GET_CLIENT_HEADER_FOOTER = environment.apiV1 + '/management-letter/client/header-footer/get';
  private readonly SEND_ML_MAIL = environment.apiV1 + '/management-letter/email';
  private readonly DELETE_ML = '/management-letter/delete';
  private static readonly REORDER_SECTIONS = environment.apiV1 + '/reorder-section';
  private readonly GET_ENG_LETTER_SIGN_STATUS = environment.apiV1 + '/engagement-letter-status';
  private readonly COMPLETION_ARCHIVE = environment.apiV1 + '/completion-archive';
  private readonly FINAL_REVIEW_BENCHMARK = environment.apiV1 + '/final-review-benchmark';
  private readonly EXPORT_ENGAGEMENT_LIST = environment.apiV1 + '/eng/export';
  private readonly EXPORT_CLIENT_LIST = environment.apiV1 + '/client/export';

  constructor(private httpClient: HttpClient, private encodingService: EncodingService) {}

  public async fetchIntegrationSource(engagementId: number): Promise<IntegrationSourceInterface> {
    return await this.httpClient
      .post<IntegrationSourceInterface>(EngApiService.engBaseUri + '/integration-source', engagementId, {responseType: 'json'})
      .toPromise();
  }

  public saveEngagement(data: EngSaveModel): Observable<GenericResModel<string>> {
    return this.httpClient.post<GenericResModel<string>>(environment.apiV1 + '/eng', data);
  }

  public getAllEngagementsList(filter?: string): Observable<any> {
    const path = environment.apiV1 + '/engagement' + '/' + encodeURIComponent(this.encodingService.enData(filter));
    return this.httpClient.get(path, textResponse).pipe(map(res => {
      return JSON.parse(this.encodingService.deData(res));
    }));
  }

  public getEngagementsDetail(engId: number): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(+engId)) + '/engagement-detail';
    return this.httpClient.get<GenericResModel<String>>(path).pipe(map(res => {
      return JSON.parse(this.encodingService.deData(res.data));
    }));
  }

  public fetchIntegrationByEngagement(engagementId: number): Observable<IntegrationSourceInterface> {
    return this.httpClient.post<IntegrationSourceInterface>(EngApiService.getEngUri, engagementId, {responseType: 'json'});
  }

  public addTemplateToEngagement(data): Observable<any> {
    const path = environment.apiV1 + '/eng-template';
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse);
  }

  public sendMailNotifications(data): Observable<any> {
    return this.httpClient.post(EngApiService.SEND_EMAIL, data, textResponse);
  }

  public sendAssignedPackagerEmail(data): Observable<GenericResModel<SendPackagerEmailModel>> {
    return this.httpClient.post<GenericResModel<SendPackagerEmailModel>>(EngApiService.SEND_PACKAGER_EMAIL, data, {responseType: 'json'});
  }

  public mapChecklists(data): Observable<any> {
    return this.httpClient.post(EngApiService.MAP_CHECKLISTS, data, textResponse);
  }

  public addSectionToEngagment(data: addsectionReqModel): Observable<any> {
    return this.httpClient.post(EngApiService.ADD_SECTION, data, textResponse);
  }

  public deleteSectionFromEngagement(data: deleteSectionReqModel): Observable<any> {
    return this.httpClient.delete(EngApiService.ADD_SECTION, {body: data});
  }

  public getAutomatedElements(data: any) {
    if (this.elementSubject.getValue()) {
      return this.elements$;
    }
    return this.httpClient.post(this.GET_ELEMENTS, this.encodingService.enData(data), textResponse).pipe(
      tap(res => this.elementSubject.next(res))
    );
  }

  public addManagementLetter(data: any): Observable<any> {
    return this.httpClient.post(this.ADD_ML, data, textResponse);
  }

  public getMLData(id: number, clientFirmId: number = 0): Observable<ManagementLetter> {
    return this.httpClient.post<ManagementLetter>(this.GET_ML_DATA, {'engagementId': id, 'clientFirmId': clientFirmId});
  }

  public shareToClient(Obj: ShareToClientModel): Observable<any> {
    return this.httpClient.post(this.SHARE_ML_TO_CLIENT, Obj);
  }

  public reShareToClient(Obj: ReShareToClientModel): Observable<any> {
    return this.httpClient.post(this.RE_SHARE_ML_TO_CLIENT, Obj);
  }

  public saveClientHeader(Obj: ImageMLModel): Observable<any> {
    return this.httpClient.post(this.SAVE_CLIENT_HEADER, Obj, textResponse);
  }

  public getClientHeader(firmUserAcctId: number, clientFirmId: number): Observable<HeaderResponseModel> {
    return this.httpClient.post<HeaderResponseModel>(this.GET_CLIENT_HEADER, {
      'firmUserAcctId': firmUserAcctId,
      'clientFirmId': clientFirmId
    });
  }

  public saveClientFooter(Obj: ImageMLModel): Observable<any> {
    return this.httpClient.post(this.SAVE_CLIENT_FOOTER, Obj, textResponse);
  }

  public saveClientComponent(Obj: ImageMLModel): Observable<any> {
    return this.httpClient.post(this.SAVE_CLIENT_COMPONENT, Obj, textResponse);
  }

  public getClientFooter(firmUserAcctId: number, clientFirmId: number): Observable<any> {
    return this.httpClient.post(this.GET_CLIENT_FOOTER, {
      'firmUserAcctId': firmUserAcctId,
      'clientFirmId': clientFirmId
    });
  }

  public getClientMLSignature(engagementId: number, clientFirmId: number): Observable<any> {
    return this.httpClient.post(this.GET_CLIENT_SIGNATURE, {
      'clientFirmId': clientFirmId,
      'engagementId': engagementId
    });
  }

  public saveClientMLSignature(Obj: ImageMLModel): Observable<any> {
    return this.httpClient.post(this.SAVE_CLIENT_SIGNATURE, Obj, textResponse);
  }

  public saveClientApproval(Obj: ClientApproveModel): Observable<any> {
    return this.httpClient.post(this.REVIEW_ML_BY_CLIENT, Obj, textResponse);
  }

  /**
   * gets header,footer,signature for an Management Letter
   * @param firmUserAcctId
   * @param clientFirmId
   *
   */

  public getClientComponents(firmUserAcctId: number, clientFirmId: number): Observable<any> {
    return this.httpClient.post(this.GET_CLIENT_COMPONENT, {
      'firmUserAcctId': firmUserAcctId,
      'clientFirmId': clientFirmId
    });
  }

  public removeClientSignature(clientFirmId: number, subModuleId: number): Observable<any> {
    return this.httpClient.post(this.REMOVE_CLIENT_SIGNATURE, {
      'clientFirmId': clientFirmId,
      'subModuleId': subModuleId
    });
  }

  public unShareManagementLetter(Obj: ML): Observable<any> {
    return this.httpClient.post(this.UN_SHARE_ML_TO_CLIENT, Obj, textResponse);
  }

  public getClientHeaderFooter(id: number, clientFirmId: number): Observable<ComponentModel> {
    return this.httpClient.post<ComponentModel>(this.GET_CLIENT_HEADER_FOOTER, {
      'engagementId': id,
      'clientFirmId': clientFirmId
    });
  }

  public sendMailForApproval(Obj: ApprovalMailModel): Observable<any> {
    return this.httpClient.post(this.SEND_ML_MAIL, Obj);
  }

  public deleteManagementLetter(id: number): Observable<any> {
    const path = environment.apiV1 + '/' + id + this.DELETE_ML;
    return this.httpClient.delete(path);
  }

  public fetchClientPortalStatus(clientFirmId: number): Observable<ClientPortalStatusModel> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.encodingService.enData(clientFirmId)) + '/client-status';
    return this.httpClient.get<ClientPortalStatusModel>(path);
  }

  public deleteEngagement(id: number): Observable<GenericResModel<boolean>> {
    return this.httpClient.delete<GenericResModel<boolean>>(environment.apiV1 + '/engagement', {
      body: id
    });
  }

  public renameSectionName(data: addsectionReqModel): Observable<any> {
    return this.httpClient.put<GenericResModel<any>>(EngApiService.ADD_SECTION, data, textResponse);
  }

  public reorderSections(data: reorderDataModel): Observable<any> {
    return this.httpClient.put<GenericResModel<any>>(EngApiService.REORDER_SECTIONS, data, textResponse);
  }

  public getEngLetterSignStatus(Obj: EngLetterStatusInputs): Observable<GenericResModel<string>> {
    return this.httpClient.post<GenericResModel<string>>(this.GET_ENG_LETTER_SIGN_STATUS, Obj);
  }

  public getCompletionArchive(engagmentid): Observable<any> {
    return this.httpClient.get<GenericResModel<any>>(this.COMPLETION_ARCHIVE + '?engagementId=' + engagmentid);
  }

  public saveCompleitonArchive(data: compArchiveModel): Observable<any> {
    return this.httpClient.put<GenericResModel<any>>(this.COMPLETION_ARCHIVE, data);
  }

  public getBenchMarkDetails(engagmentid): Observable<any> {
    return this.httpClient.get<GenericResModel<any>>(this.FINAL_REVIEW_BENCHMARK + '?engagementId=' + engagmentid);
  }

  public getEngagementTypes() {
    return this.httpClient.get(environment.apiV1 + '/engagement-types');
  }

  public getMasterFirmTemplates() {
    return this.httpClient.get(environment.apiV1 + '/template/master/firm');
  }

  public rearrangeNumbers(data: RearrangeNumbersModel[]): Observable<GenericResModel<boolean>> {
    return this.httpClient.post<GenericResModel<boolean>>(environment.apiV1 + '/rearrange-document-numbers', {'rearrangeDocuments': data});
  }

  public sendEmailToPlaid(engagementID: any, clientId: number): Observable<GenericResModel<string>> {
    return this.httpClient.post<GenericResModel<string>>(environment.apiV1 + '/plaid/token', {
      'clientId': clientId,
      'engagementId': engagementID,
      'type': 'link'
    });
  }

  public saveToken(clientKey: string, public_token: string) {
    return this.httpClient.post<GenericResModel<string>>(environment.apiV1 + '/plaid/client/token', {
      'public_token': public_token,
      'clientKey': clientKey,
      'type': 'save'
    });
  }

  public fetchAccounts(clientKey: string) {
    return this.httpClient.get<GenericResModel<any>>(environment.apiV1 + '/plaid/accounts?clientKey=' + clientKey);
  }

  public fetchAccountsClientSide(clientKey: string) {
    return this.httpClient.get<GenericResModel<any>>(environment.apiV1 + '/plaid/client/accounts?clientKey=' + clientKey);
  }

  public plaidStatus(clientId: number, engagementId: number) {
    return this.httpClient.get<GenericResModel<any>>(environment.apiV1 + '/plaid/status?clientId=' + clientId + '&engagementId=' + engagementId);
  }

  public sendPlaidEmail(data) {
    return this.httpClient.post<GenericResModel<any>>(environment.apiV1 + '/plaid/request', data);
  }

  public getClientDetails(clientKey: string) {
    return this.httpClient.get<GenericResModel<any>>(environment.apiV1 + '/plaid/detail?clientKey=' + clientKey);
  }


  public getClientSideDetails(clientKey: string) {
    return this.httpClient.get<GenericResModel<any>>(environment.apiV1 + '/plaid/client/detail?clientKey=' + clientKey);
  }

  public linkBankingClientSide(clientKey: string) {
    return this.httpClient.post(environment.apiV1 + '/plaid/client/token', {'clientKey': clientKey, 'type': 'link'});
  }

  public exportEngagementList(data) : Observable<any>{
    return this.httpClient.post<GenericResModel<any>>(this.EXPORT_ENGAGEMENT_LIST, {'engagementsId' : data});
  }

  public exportClientList(data) : Observable<any>{
    return this.httpClient.post<GenericResModel<any>>(this.EXPORT_CLIENT_LIST, {'clientFirmIds' : data});
  }
}
