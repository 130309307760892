import { Injectable } from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import {DatePipe} from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class AdjustmentExcelService {
  sName: string;
  excelFileName: string = '';
  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  cols = ['*JournalNo', '*JournalDate', '*Currency', 'Memo', '*AccountName', 'Debits', 'Credits', 'Description', 'Name', 'Location', 'Class'];
  reportTotalData: any = [];
  constructor(private datePipe:DatePipe) { }
  exportToExcel(data, engagementDetails,year) {
    this.excelFileName = 'Adjusting  Entries';
    const workbook = new Excel.Workbook();
    workbook.creator = 'Web';
    workbook.lastModifiedBy = 'Web';
    workbook.created = new Date();
    workbook.modified = new Date();
    // workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', ySplit: 6, activeCell: 'A1', showGridLines: true }] });
    workbook.addWorksheet(this.sName, { views: [{activeCell: 'A1', showGridLines: true }] });
    const sheet = workbook.getWorksheet(1);

    // sheet.mergeCells('C1', 'J2');
    // sheet.getCell('C1').value = engagementDetails.engagementName ? 'Engagement Name: ' + engagementDetails.engagementName : '';
    // sheet.getCell('C1').alignment = { horizontal: 'center' };
    // sheet.getCell('C1').font = { name: 'Calibri', family: 4, size: 12, bold: true }

    // sheet.mergeCells('C3', 'J3');
    // sheet.getCell('C3').value = engagementDetails.engagementName ? 'Client Name: ' + engagementDetails.clientName : '';
    // sheet.getCell('C3').alignment = { horizontal: 'center' };
    // sheet.getCell('C3').font = { name: 'Calibri', family: 4, size: 12, bold: true }

    // sheet.mergeCells('C4', 'J4');
    // sheet.getCell('C4').value = engagementDetails.yearEnd ? 'As of ' +this.datePipe.transform(engagementDetails.yearEnd, 'MMM d, ') +year: '';
    // sheet.getCell('C4').alignment = { horizontal: 'center' };

    // sheet.mergeCells('C5', 'J5');
    // sheet.getCell('C5').value = 'Adjusting  Entries';
    // sheet.getCell('C5').alignment = { horizontal: 'center' };
    // sheet.getCell('C5').font = { name: 'Calibri', family: 4, size: 12, bold: true }

    // sheet.addRow('');
    sheet.getRow(1).values = this.cols;
    sheet.columns = [
      { key: 'JournalNo' },
      { key: 'JournalDate' },
      { key: 'Currency' },
      { key: 'Memo' },
      { key: 'AccountName' },
      { key: 'Debits' },
      { key: 'Credits' },
      { key: 'Descrption' },
      { key: 'Name' },
      { key: 'Location' },
      { key: 'Class' }
    ];
    sheet.addRows(data);
    //to adjust width of selected column
    sheet.columns.forEach(function (column, index) {
      if (index == 4 || index==7) {
        column.width = 40;
      } else if (index === 1 ||index === 5|| index === 6) {
        column.width = 20;
      }
       else if (index === 0) {
        column.width = 12;
      }
    });
   //setting right alignment for number
    sheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
        row.eachCell(function (cell, colNumber) {
          if (colNumber=== 6 || colNumber == 7) {
            cell.alignment = {
              vertical: 'middle', horizontal: 'right'
            };
          }
        });
    });
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = this.excelFileName;
      a.click();
    });
  }
}
