import { NestedTreeControl } from '@angular/cdk/tree';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { DocumentPreviewTypes, STATEMENT_VIEW_TYPES } from 'countable@helpers';
import { FsExportService, SpinnerService } from 'countable@services';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { TimeTrackerService } from 'src/app/services/time-tracker.service';
import { ApiService } from '../../../../../services/api.service';
import { generateId, idleTime,modules } from '../../../../../services/app-config';
import { SharedService } from '../../../../shared/shared.service';

interface IncomeStatementTree {
  name: string;
  children?: IncomeStatementTree[];
  grouptotal?: string;
  subgrouptotal?: string;
  total?: string;
  bal?: any;
}

/** Flat node with expandable and level information */
interface TreeFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'income-sheet-preview',
  templateUrl: './income-sheet-preview.component.html',
  styleUrls: ['./income-sheet-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],

})
export class IncomeSheetPreviewComponent implements OnInit, OnDestroy {
  treeControl = new NestedTreeControl<IncomeStatementTree>(node => node.children);
  dataSource = new MatTreeNestedDataSource<IncomeStatementTree>();
  engagementId: number;
  year: number;
  incomeStatementDetails: any;
  incomeStmtYear: string;
  yearEnd: string;
  engagementName: string;
  previousYear: string;
  isIncomePrevYear: boolean = false;
  incomeStatementExportItemMenu: boolean = false;
  isEditable: boolean = false;
  selectedEngagementDetails: any;
  balStmtDetails: any;
  engagementID: any;
  ntrSettingsSubject: any;
  engfinsettingid: any;
  isyesno: any;
  hideItem: boolean;
  currentYearFIS: any;
  previousYearFIS: any;
  submoduleId: number;
  engagementData: any;
  headerNotesColumn1 = '';
  headerNotesColumn2: any;
  headerNotesColumn3: any;
  clientName: any
  currentYear: any;
  refreshData: any;
  refreshIncomeStatement: Subscription;
  incomeHeaderInfo: any;
  footerNotes: string = '';
  userData: any;
  refreshISHeader: Subscription;
  retainedToIncomeSub: any;
  isShowDraft: boolean = false;
  showDraftSub: Subscription;
  trackobj: { engagementsId: any; submoduleId: any; submoduleUuid: any; };
  userActivity;
  userInactiveSub: Subscription;
  userInactive: Subject<any> = new Subject();
  isHideNotesToFS: boolean;
  incomeSheetdetails: any;
  countryId: number;
  NotesExists: any;
  LocalStorageprevYear:any
  incomesheetlinkednotesExists: boolean=false;
  incomeStatementData:any;
  statementOfIncome:any;
  statementOFDeficit:any;
  userLoginId: any;
  userDetails: any;
  isWorkBook:any;
  footerNote: string;
  isIncomeStatementUpdateSubscription: Subscription;
  // footer text
	footerText: string = '';
	isHideFooterNotesToFS = false;

  public subscriptions: Subscription = new Subscription();
  public incomeSheet$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public placeOfPreview: STATEMENT_VIEW_TYPES = "None";

  @HostListener('window:mousemove', ["$event"])
  @HostListener('window:resize', ["$event"])
  @HostListener('window:keydown', ["$event"])
  refreshUserState() {
    clearTimeout(this.userActivity);
    if (localStorage.getItem("timerStatus") == "Idle")
      this.trackerService.startTimer("Active");
    this.setTimeout();
  }
  @HostListener('window:storage', ['$event'])
  onStorageChange(ev: StorageEvent) {
    this.LocalStorageprevYear=localStorage.getItem('prevYear')
    this.NotesExists = localStorage.getItem('NotesExists')
    if (ev.key == 'RetainedEarningStatement') {
      this.isyesno = parseInt(localStorage.getItem('isyesno'))
      if (this.isyesno == 0) {
        this.submoduleId = this.submoduleId == 436 ? this.submoduleId : 436;
      }
      else {
        this.submoduleId = this.submoduleId == 437 ? this.submoduleId : 437;
      }
      localStorage.setItem('workBookSubmoduleId', this.submoduleId.toString());
      // this.getTBLSHeaderNotes();
      // this.getIncomeStatement();
    }
    if (ev.key == 'isfooterUpdated') {
      const note = localStorage.getItem('isfooterUpdated');
      this.updateFooterText(note);
    }
    if (ev.key == 'refreshFinancialStatements-IS' || ev.key == 'refreshFinancialStatements-LSG') {
      if (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/IncomeStatement') {
        // this.getIncomeStatement(true);
      } else {
        localStorage.removeItem("refreshFinancialStatements-IS");
      }
    }
  }
  constructor(private apiservice: ApiService,
    private sharedservice: SharedService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    public router: Router,
    private trackerService: TimeTrackerService,
    private fsService: FsExportService,
    private renderer: Renderer2,
    private el: ElementRef,
    private spinnerService: SpinnerService) {
    //  this.dataSource.data = TREE_DATA;
    this.setTimeout();
    this.userInactive.subscribe(() => {
      this.trackerService.startTimer("Idle");
    });
    this.placeOfPreview = this.sharedservice.setPlaceofPreview(this.router.url);
  }

  hasChild = (_: number, node: IncomeStatementTree) => !!node.children && node.children.length > 0;
  @ViewChild('tree') tree;

  //this.sharedService.refreshBlIS

  ngOnInit() {
    localStorage.removeItem('workbookChangeDate');
    localStorage.removeItem('workbookChangeYearEnd');
    localStorage.removeItem('isLastYear');
    this.selectedEngagementDetails = JSON.parse(localStorage.getItem("selectedEngagementObj"));
    this.submoduleId = (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/IncomeStatement')?  JSON.parse(localStorage.getItem('FsSubmoduleId')) : JSON.parse(localStorage.getItem('workBookSubmoduleId'));
    // this.getFSNotes();
    // this.getHeaderTitles();
    setTimeout(() => {
      this.setIncomeStatementView();
    }, 1000);

    this.subscriptions.add(
      this.incomeSheet$.pipe(
        distinctUntilChanged()
        ).subscribe({
        next: incomeSheetData => {
          if(incomeSheetData) {
            let previewData: any;
            try{
              console.log("Try-Block");
              previewData = JSON.parse(incomeSheetData);
            } catch (err) {
              previewData = incomeSheetData;
            }
            console.log('typeof parsedData === \'object\'',typeof previewData === 'object')
            const setViewData = typeof previewData === 'object' && previewData.hasOwnProperty('incomeStatement') ? previewData["incomeStatement"] : incomeSheetData;
            const view = this.el.nativeElement.querySelector('div');
            this.renderer.setProperty(view,'innerHTML',setViewData);
            const waterMark = this.sharedservice.showdraft.value;
            const waterMarkUrl = this.sharedservice.waterMarkUrl.getValue();
            if((waterMark && waterMark.isShowDraft) && waterMarkUrl) {
              console.log("background: url('../../../../../../assets/images/PDF-watermark.png') no-repeat 0 0 !important;",waterMark);
              this.renderer.setStyle(view, 'background-image', `url(${waterMarkUrl})`);
            }

            if (this.footerText == '') {
							this.getHeaderTitles();
						} else {
							// document.getElementById("dynamicHeight").innerHTML = setViewData + this.footerText;
						}

            // setTimeout(() => {
            //   const eleHeight = document.getElementById('dynamicHeight') as HTMLElement;
            //   console.log(eleHeight.clientHeight)
            //   if(eleHeight.clientHeight < 759){
            //     eleHeight.style.height = '760px';
            //   }
            // }, 10);
          }
        }
      })
    )

    this.subscriptions.add(
      this.sharedservice.updateFinStatement$.subscribe({
        next: refreshStatement => {
            if(refreshStatement && (refreshStatement.has(DocumentPreviewTypes.INCOME_STATEMENT) && refreshStatement.get(DocumentPreviewTypes.INCOME_STATEMENT))) {
              console.log("Refresh-IncomeStatement");
              this.fsService.removeFsDocPreviewPage(DocumentPreviewTypes.INCOME_STATEMENT);
              this.setIncomeStatementView();
              // this.getFSNotes();
              // this.getHeaderTitles();
            }

        }
      })
    )
    // CPT-8892
    this.isIncomeStatementUpdateSubscription = this.sharedservice.isIncomeStatementFooterUpdate.subscribe(resp => {
      if (resp.isfooterUpdated == true) {
        this.updateFooterText(resp?.note);
      }
    })

  }

  setIncomeStatementView() {
    this.subscriptions.add(
      this.fsService.getFsDocsPreviewPage(DocumentPreviewTypes.INCOME_STATEMENT).subscribe(resp => {
        console.log("Has-Income-Sheet-Data-Or-not",this.fsService.hasFsDocsPreviewPage(DocumentPreviewTypes.INCOME_STATEMENT));
        if(this.fsService.hasFsDocsPreviewPage(DocumentPreviewTypes.INCOME_STATEMENT) && resp) {
          console.log("Income-Sheet-Preview-Has-executed-By-Subscription");
          this.incomeSheet$.next(resp);
        } else if(!this.fsService.hasFsDocsPreviewPage(DocumentPreviewTypes.INCOME_STATEMENT) && !resp) {
          console.log("Income-Sheet-Preview-Has-executed-By-Calling-Api");
          this.spinnerService.showSpinner();
          this.fsService.fetchFSDocPreview(DocumentPreviewTypes.INCOME_STATEMENT,this.placeOfPreview).subscribe({
            next: res => {
              if(res) {
                this.incomeSheet$.next(res);
                // this.getHeaderTitles();
                // Refreshing watermark application from fs settings
                const view = this.el.nativeElement.querySelector('div');
                const waterMark = this.sharedservice.showdraft.value;
                const waterMarkUrl = this.sharedservice.waterMarkUrl.getValue();

                if((waterMark && waterMark.isShowDraft) && waterMarkUrl) {
                  this.renderer.setStyle(view, 'background-image', `url(${waterMarkUrl})`);
                } else {
                  this.renderer.setStyle(view, 'background-image', "none");
                }

              }
            }
          }).add(() => {
            this.spinnerService.hideSpinner();
          })
        }
      })
    )
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), idleTime);
  }
  ngOnDestroy() {
    this.sharedservice.updateFinStatement$.next(null);
    if (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/IncomeStatement') {
      clearTimeout(this.userActivity);
    }

    if (this.ntrSettingsSubject) {
      this.ntrSettingsSubject.unsubscribe();
    }
    this.refreshData && this.refreshData.unsubscribe();
    this.refreshIncomeStatement && this.refreshIncomeStatement.unsubscribe();
    this.refreshISHeader && this.refreshISHeader.unsubscribe();
    this.retainedToIncomeSub && this.retainedToIncomeSub.unsubscribe();
    this.showDraftSub && this.showDraftSub.unsubscribe();
    localStorage.removeItem('workbookChangeDate');
    localStorage.removeItem('workbookChangeYearEnd');
    localStorage.removeItem('isLastYear');

    this.fsService.removeFsDocPreviewPage(DocumentPreviewTypes.INCOME_STATEMENT);
    if(this.isIncomeStatementUpdateSubscription){
      this.isIncomeStatementUpdateSubscription.unsubscribe();
    }
  }

  getFSNotes() {
    let jsonFileName = this.selectedEngagementDetails.engagementsid + '_notes.json';
    const uploadObj = {
      "module": modules[16],
      "firmId": this.sharedservice.getUserData("field", "firmUserAcctId"),
      "clientId": this.selectedEngagementDetails.clientfirmid,
      "engagementId": this.selectedEngagementDetails.engagementsid,
      "status": 0,
      "folderName": jsonFileName,
      "appSyncId": this.sharedservice.getUserData("field", "useracctid"),
      "extension": 'comp',
    }
    // this.spinner.show();
    this.apiservice.getJsonData(uploadObj).subscribe((response: any) => {
      if (response) {
        const notes = !response ? [] : JSON.parse(response);
        this.isHideFooterNotesToFS = notes.length ? true : false;

        this.footerText = this.isHideFooterNotesToFS ? "<div class='my-custom-footer' style = 'position: absolute; bottom: 0px;left: 0; margin-top: 35px; padding-bottom: 17px; width: 100%; text-align: center;'> <p style = 'font-family: Arial,sans-serif !important; font-size: 12px;'>" + this.footerNote + "</p></div>" : "";
				let preview = document.getElementById("eng_dynamicHeight").innerHTML;
				document.getElementById("eng_dynamicHeight").innerHTML = preview + this.footerText;

        // this.spinner.hide();
      }
    }, (error) => {
      // this.spinner.hide();
    });
  }

  getHeaderTitles() {
    const inputObj = {
      "engagementsid": this.selectedEngagementDetails.engagementsid,
      "submoduleid": this.submoduleId
    }
    const stringifyObj = JSON.stringify(inputObj);
    const finalInputObj = {
      "inputParameters": stringifyObj,
      "procedureName": "getwbheadernote"
    }
    this.apiservice.getData(finalInputObj).subscribe(response => {
      if (response) {
        let results = JSON.parse(response);
        this.headerNotesColumn1 = results[0].header[0].changednameline1;
        this.headerNotesColumn2 = results[0].header[0].changednameline2;
        this.headerNotesColumn3 = results[0].header[0].changednameline3;
        this.footerNote = results[1].note ? results[1].note[0].note : "";
        this.getFSNotes();
      }
    }, error => {
      this.toaster.error(error.error);
    });
  }

  // CPT-8892
  updateFooterText(newFooterNote: string) {
    let preview = document.getElementById("eng_dynamicHeight");
    if (preview) {
      let footerElements = preview.getElementsByClassName("my-custom-footer");
      for (let i = 0; i < footerElements.length; i++) {
        footerElements[i].remove();
      }
      let newFooterText ="<div class='my-custom-footer' style = 'position: absolute; bottom: 0px;left: 0; margin-top: 35px; padding-bottom: 17px; width: 100%; text-align: center;'> <p style = 'font-family: Arial,sans-serif !important; font-size: 12px;'>" + newFooterNote + "</p></div>";
      preview.innerHTML += newFooterText;
    }
  }
}
