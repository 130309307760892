import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as _html2canvas from "html2canvas";
import * as _htmlToPdfmake from "html-to-pdfmake";

import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
const htmlToPdfmake = require("html-to-pdfmake");
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

import { SharedService } from '../../../../shared/shared.service';
import { TimeTrackerService } from '../../../../../services/time-tracker.service';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { idleTime, modules } from '../../../../../services/app-config';
import { Router } from '@angular/router';
import { ApiService } from '../../../../../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe, formatDate } from '@angular/common';
import { Column, ColumnValues, Row } from 'src/app/model/notes/notes-to-fs.model';
import { DocumentPreviewTypes } from 'countable@helpers';
import { FsExportService } from 'countable@services';

const html2canvas: any = _html2canvas;
//const htmlToPdfmake: any = _htmlToPdfmake;
@Component({
	selector: 'notes-to-fs-preview',
	templateUrl: 'notes-to-financial-statements-preview.component.html',
	styleUrls: ['notes-to-financial-statements-preview.component.scss'],
	encapsulation: ViewEncapsulation.None,
	providers: [DatePipe]
})

export class NotesToFinancialStatementsPreviewComponent implements OnInit, OnDestroy {

	@ViewChild('notesPreview') el!: ElementRef;
	userInactiveSub: Subscription;
	previewSub: Subscription;
	userInactive: Subject<any> = new Subject();

	trackobj: { engagementsId: any; submoduleId: any; submoduleUuid: any; };
	userActivity;
	engId: number;
	submoduleId: number;
	submoduleFsId: number;
	previewData = [];
	engName: string;
	clientFirmId: number;
	firmUserAcctId: number;
	userLoginId: number;
	headerNotesColumn1: string;
	headerNotesColumn2: string;
	headerNotesColumn3: string;
	headerSub: Subscription;
	clientName: any;
	currentYearFBS: any;
	htmlContent: string;
	isHideEditor: boolean;
	showDraftSub: Subscription;
	isShowDraft: boolean = false;
	isWorkBook: boolean;
	notesDataFromAPI: any;
	documentDefinition: object;
	pdfSrc: string = "";
	workBookSubModuleId: number;

	public subscriptions: Subscription = new Subscription();
	public notes$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	@HostListener('window:mousemove', ["$event"])
	@HostListener('window:resize', ["$event"])
	@HostListener('window:keydown', ["$event"])
	refreshUserState() {
		clearTimeout(this.userActivity);
		if (localStorage.getItem("timerStatus") == "Idle")
			this.trackerService.startTimer("Active");
		this.setTimeout();
	}

	@HostListener('window:storage', ['$event'])
	onStorageChange(ev: StorageEvent) {
		if ((ev.key === 'refreshNotes_to_FS') && (ev.newValue === 'refresh') && this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/NotesToFinancialStatement') {
			this.getPreviewNotes();
		}
	}

	constructor(
		private apiService: ApiService,
		private fsService: FsExportService,
		private router: Router,
		private spinner: NgxSpinnerService,
		private toaster: ToastrService,
		private sharedService: SharedService,
		private trackerService: TimeTrackerService,
		private datepipe: DatePipe
	) {
		this.setTimeout();
		this.userInactive.subscribe(() => {
			this.trackerService.startTimer("Idle");
		});
	}
	ngOnInit() {
		this.engId = this.sharedService.getEngData("field", "selectedEngagementObjCopy", "engagementsid");
		this.engName = this.sharedService.getEngData("field", "selectedEngagementObjCopy", "engagementName");
		this.clientFirmId = this.sharedService.getEngData("field", "selectedEngagementObjCopy", "clientfirmid");
		this.clientName = this.sharedService.getEngData("field", "selectedEngagementObjCopy", "clientName");
		this.currentYearFBS = localStorage.getItem("endYear");
		this.firmUserAcctId = this.sharedService.getUserData("field", "firmUserAcctId");
		this.userLoginId = this.sharedService.getUserData("field", "useracctid");
		this.submoduleFsId = JSON.parse(localStorage.getItem('FsSubmoduleId'));
		this.workBookSubModuleId = JSON.parse(localStorage.getItem('workBookSubmoduleId'));
		this.isWorkBook = localStorage.getItem('isWorkBook') == "true" ? true : false;

		this.trackobj = {
			"engagementsId": this.engId,
			"submoduleId": this.submoduleFsId,
			"submoduleUuid": localStorage.getItem("UUID"),
		}
		if (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/NotesToFinancialStatement') {
			localStorage.setItem("timeTrackerObj", JSON.stringify(this.trackobj));
			this.trackerService.startTimer("Active");
			this.submoduleId = this.submoduleFsId;
		}
		else {
			this.submoduleId = this.workBookSubModuleId;
		}
		this.getHeaderTitles('preview');
		var draft = JSON.parse((localStorage.getItem('showdraft')));
		if (draft) {
			this.isShowDraft = draft.isShowDraft ? draft.isShowDraft : '';
		}
		this.showDraftSub = this.sharedService.showdraft.subscribe(res => {
			if (res) {
				this.isShowDraft = res?.isShowDraft;
				this.getHeaderTitles('pdf');
			}
		});
		this.headerSub = this.sharedService.isFsNotesHeaderUpdate.subscribe(resp => {
			if (resp) {
				this.headerNotesColumn1 = resp[0].header[0].changednameline1;
				this.headerNotesColumn2 = resp[0].header[0].changednameline2;
				this.headerNotesColumn3 = resp[0].header[0].changednameline3;
				this.generatePDF();
			}
		})

		this.previewSub = this.sharedService.refreshNotesToFsPreview.subscribe(previewData => {
			if (previewData) {
				this.previewData = previewData;
				// this.createTableData();
				/* if(this.previewData){
					setTimeout(() => {
						var commentNodes = this.findComments(this.el.nativeElement);
						if (commentNodes && commentNodes.length) {
							commentNodes.forEach(element => {
								if (element.data == ' my page break ') {
									var span = document.createElement("span");
									span.innerText = " ";
									span.style.height = "0 !important";
									span.style.display = "none!important";
									span.classList.add('pdf-pagebreak-before');
									//element.nextElementSibling.firstChild.classList.add("pdf-pagebreak-before")
									element.parentNode.replaceChild(span, element);
								}
							});
						}
					}, 1000);
					this.generatePDF();
				} */
			}
		})
		this.headerSub = this.sharedService.isFsNotesHeaderUpdate.subscribe(resp => {
			if (resp) {
				this.headerNotesColumn1 = resp[0].header[0].changednameline1;
				this.headerNotesColumn2 = resp[0].header[0].changednameline2;
				this.headerNotesColumn3 = resp[0].header[0].changednameline3;
			}
		})

		var draft = JSON.parse((localStorage.getItem('showdraft')));
		if (draft) {
			this.isShowDraft = draft.isShowDraft ? draft.isShowDraft : '';
		}
		this.showDraftSub = this.sharedService.showdraft.subscribe(res => {
			if (res) {
				this.isShowDraft = res?.isShowDraft;
				this.getPreviewNotes();
			}
		});
		// this.getPreviewNotes(); Calling as a part of this.getHeaderTitles('preview'); line no 125
		this.updateWorkBook();
		this.subscriptions.add(
			this.notes$.pipe().subscribe({
				next: notesData => {
					this.notesDataFromAPI = localStorage.getItem('NotesExists')
					if (notesData) {
						document.getElementById("notes-preview").innerHTML = notesData;
						// console.log("preview data html",notesData)
						// Setting watermark
						const waterMark = this.sharedService.showdraft.value;
						const waterMarkUrl = this.sharedService.waterMarkUrl.getValue();
						if ((waterMark && waterMark.isShowDraft) && waterMarkUrl) {
							document.getElementById("notes-preview").style.backgroundImage = `url(${waterMarkUrl})`;
						} else {
							document.getElementById("notes-preview").style.backgroundImage = "none";
						}

						setTimeout(() => {
							const eleHeight = document.getElementById('notes-preview') as HTMLElement;
							/** Below code is commented, because it was setting height for notes 
							preview for the first time and was showing partial notes data */
							//if(eleHeight.clientHeight < 759){
							//  eleHeight.style.height = '760px';
							//}
						}, 10);

						this.spinner.hide();
					}
				}
			})
		);
	}

	findComments(el) {
		var arr = [];
		for (var i = 0; i < el.childNodes.length; i++) {
			var node = el.childNodes[i];
			if (node.nodeType === 8) {
				arr.push(node);
			} else {
				arr.push.apply(arr, this.findComments(node));
			}
		}
		return arr;
	};
	generatePDF(): void {

		let clientName = this.clientName;
		let headerNotesColumn1 = this.headerNotesColumn1;
		let headerNotesColumn2 = this.headerNotesColumn2 + " " + this.datepipe.transform(this.currentYearFBS, 'MMMM d, yyyy');
		let headerNotesColumn3 = this.headerNotesColumn3;
		let waterMark: string, pageSize: any, fontSize: number, x2Size: number;
		if (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/NotesToFinancialStatement') {
			waterMark = this.isShowDraft ? "DRAFT UNDER DISCUSSION" : "";
			pageSize = "A4";
			fontSize = 11;
			x2Size = 575;
		}
		else {
			waterMark = "";
			pageSize = {
				width: 325.28,
				height: 1069
			},
				fontSize = 10;
			x2Size = 305;
		}
		this.spinner.hide();

		setTimeout(() => {
			if (this.el) {
				const pdfTable = this.el.nativeElement;
				var html = htmlToPdfmake(pdfTable.innerHTML);
				//console.log(pdfTable.innerHTML)
				//console.log(html)
				const documentDefinition = {
					//compress: false,
					pageSize: pageSize,
					pageMargins: [20, 120, 20, 20],
					header: function (currentPage, pageCount, pageSize) {
						// you can apply any logic and return any valid pdfmake element
						return [
							{ text: clientName, alignment: 'left', fontSize: 15, bold: true, margin: [20, 15, 20, 2] },
							{ text: headerNotesColumn1, alignment: 'left', fontSize: 15, bold: true, margin: [20, 2] },
							{ text: headerNotesColumn2, alignment: 'left', fontSize: 15, bold: true, margin: [20, 2] },
							{ text: headerNotesColumn3, alignment: 'left', fontSize: 15, italics: true, bold: true, margin: [20, 2, 2, 6] },
							{
								canvas: [{
									lineColor: '#0000001a',
									type: 'line',
									x1: 20,
									y1: 0,
									x2: x2Size,
									y2: 0,
									lineWidth: 1
								}
								]
							},
							{ canvas: [{ type: 'rect', x: 170, y: 232, w: pageSize.width - 170, h: 260 }] },

						]
					},
					//footer: function(currentPage, pageCount) { return currentPage.toString() + ' of ' + pageCount; },
					watermark: { text: waterMark, color: '#0000001a', opacity: 0.1, bold: true, italics: false },
					content: [html],
					pageBreakBefore: function (currentNode) {
						return currentNode.style && currentNode.style.indexOf('pdf-pagebreak-before') > -1;
					},
					styles: {
						'pdf-pagebreak-before': {
							lineHeight: 0
						}
					},
					defaultStyle: {
						fontSize: fontSize,
						color: "#212529",
						characterSpacing: .7,
						width: 790
					}
				};
				pdfMake.createPdf(documentDefinition).getDataUrl(function (dataUrl) {
					if (dataUrl) {
						this.pdfSrc = dataUrl;
					}
				}.bind(this));
				if (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/NotesToFinancialStatement') {
					this.spinner.hide();
				}
			}
		}, 1500);
	}

	setTimeout() {
		this.userActivity = setTimeout(() => this.userInactive.next(undefined), idleTime);
	}
	getHeaderTitles(apiCall?) {
		this.spinner.show();
		const inputObj = {
			"engagementsid": this.engId,
			"submoduleid": this.submoduleId
		}
		const stringifyObj = JSON.stringify(inputObj);
		const finalInputObj = {
			"inputParameters": stringifyObj,
			"procedureName": "getwbheadernote"
		}
		this.apiService.getData(finalInputObj).subscribe(response => {
			if (response) {
				let results = JSON.parse(response);
				this.headerNotesColumn1 = results[0].header[0].changednameline1;
				this.headerNotesColumn2 = results[0].header[0].changednameline2;
				this.headerNotesColumn3 = results[0].header[0].changednameline3;
				if (apiCall == 'preview') {
					this.getPreviewNotes();
				} else if (apiCall == 'pdf') {
					this.generatePDF()
				}
			}
		}, error => {
			this.toaster.error(error.error);
			this.spinner.hide();
		});
	}
	getPreviewNotes() {
		
		this.subscriptions.add(
			this.fsService.fetchFSDocPreview(DocumentPreviewTypes.NOTES).subscribe({
				next: res => {
					if (res) {
						this.notes$.next(res);
					}
				}
			})
		);

		// let jsonFileName = this.engId + '_notes.json';
		// const uploadObj = {
		// 	"module": modules[16],
		// 	"firmId": this.firmUserAcctId,
		// 	"clientId": this.clientFirmId,
		// 	"engagementId": this.engId,
		// 	"status": 0,
		// 	"folderName": jsonFileName,
		// 	"extension": 'comp',
		// 	"appSyncId": this.userLoginId
		// }
		// if (this.router.url == "/dashboard/ntrdashboard/FinancialStatement/doc/NotesToFinancialStatement") {
		// 	this.spinner.show();
		// }
		// this.apiService.getJsonData(uploadObj).subscribe((response: any) => {
		// 	if (response) {
		// 		this.previewData = JSON.parse(response);

		// 		if(this.previewData){
		// 			this.createTableData();
		// 			/* setTimeout(() => {
		// 				var commentNodes = this.findComments(this.el.nativeElement);
		// 				if (commentNodes && commentNodes.length) {
		// 					commentNodes.forEach(element => {
		// 						if (element.data == ' my page break ') {
		// 							var span = document.createElement("span");
		// 							span.innerText = " ";
		// 							span.style.height = "0 !important";
		// 							span.style.display = "none!important";
		// 							span.classList.add('pdf-pagebreak-before');
		// 							//element.nextElementSibling.firstChild.classList.add("pdf-pagebreak-before")
		// 							element.parentNode.replaceChild(span, element);
		// 						}
		// 					});
		// 				}
		// 			}, 1000);
		// 			this.generatePDF(); */
		// 		}
		// 		if (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/NotesToFinancialStatement') {
		// 			this.isHideEditor = true;
		// 			localStorage.removeItem('refreshNotes_to_FS');
		// 			this.spinner.hide();
		// 		}
		// 		else {
		// 			this.isHideEditor = false;
		// 		}
		// 	}
		// 	else {
		// 		this.previewData = [];
		// 		if (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/NotesToFinancialStatement') {
		// 			this.spinner.hide();
		// 		}
		// 	}
		// }, (error) => {
		// 	this.previewData = [];
		// });
	}
	updateWorkBook() {
		this.subscriptions.add(
			this.sharedService.updateWorkBookPreview.subscribe((resp) => {
				if (resp) {
					this.notesDataFromAPI = localStorage.getItem('NotesExists');
				}
			})
		);
	}
	trackByNotes(index, item) {
		return index;
	}

	createTableData() {
		this.previewData.forEach(note => {
			if (note.content && note.content.length) {
				note.content.forEach(section => {
					if (section.type === 'table') {
						if (section.rows && section.rows.length) {
							const totaRowIndex = section.rows.findIndex(val => val.rowId === 'totalrow');
							if (totaRowIndex === -1) {
								section.rows.push(this.createRowObject('totalrow', 'Total', false, []));
							}
						}
						section['viewModeDispCol'] = (section.columns && section.columns.length) ? ['description', ...section.columns.map(col => col.colId)] : [];
						section['viewModeRowsData'] = (section.rows && section.rows.length) ? section.rows : [];
						if (section.columns.length) {
							const engagementYearEnd = localStorage.getItem("endYear");
							const currIndex = section.columns.findIndex(ele => ele.colId === 'currYear');
							const prevIndex = section.columns.findIndex(ele => ele.colId === 'priorYear');
							if (currIndex > -1) {
								section.columns[currIndex].name = this.getFormattedDate(engagementYearEnd);
							}
							if (prevIndex > -1) {
								section.columns[prevIndex].name = this.getFormattedDate(engagementYearEnd, true);
							}
						}
					}
				})
			}
		});
		// console.log("preview data",this.previewData)	
	}

	createRowObject(rowId: string, name: string, isHide: boolean, columns: Column[]): Row {
		const colvalues: ColumnValues[] = [];

		columns.forEach(val => {
			const obj: ColumnValues = {
				colId: val.colId,
				colValue: 0
			}
			colvalues.push(obj);
		})

		const row: Row = {
			rowId,
			name,
			isHide,
			values: JSON.parse(JSON.stringify(colvalues))
		}
		return row;
	}

	getFormattedDate(date: string, minusOneYear?: boolean) {
		const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		const dateObj = new Date(date);
		if (!minusOneYear) {
			return `${months[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
		} else {
			dateObj.setDate(dateObj.getDate() + 1);
			if (months[dateObj.getMonth()] === 'January' && dateObj.getDate() === 1) {
				return `December 31, ${dateObj.getFullYear() - 2}`;
			}
			return `${months[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear() - 1}`;
		}
	}

	getColValue(columnValues: ColumnValues[], colId: string): number {
		const index = columnValues.findIndex(col => col.colId === colId);
		return (index > -1) ? columnValues[index].colValue : 0;
	}

	getTotalColValue(rows: any, colId: string): number {
		let cols: ColumnValues[] = []
		rows.forEach(row => {
			if (row.values.length && !row.isHide) {
				cols.push(row.values.filter(val => val.colId === colId)[0]);
			}
		});
		const values = cols.map(col => {
			return (col && col.colValue && typeof col.colValue === 'number') ? col.colValue : 0;
		});
		return values.reduce((a, b) => a + b, 0);
	}

	getRowTotalValue(columnValues: ColumnValues[]): number {
		const values = columnValues.map(col => {
			return (col && col.colValue && typeof col.colValue === 'number') ? col.colValue : 0;
		});
		return values.reduce((a, b) => a + b, 0);
	}

	getGrandTotalValue(rows: any) {
		const values: number[] = [];
		rows.forEach(row => {
			if (row.values.length && !row.isHide) {
				row.values.forEach(col => {
					const val = (col && col.colValue && typeof col.colValue === 'number') ? col.colValue : 0;
					values.push(val);
				})
			}
		});
		return values.reduce((a, b) => a + b, 0);
	}

	showFormattedNumber(data: number | string) {
		if (typeof data === 'number') {
			if (data > 0) {
				return data.toLocaleString('en-us');
			} else if (data < 0) {
				const absoluteNumber = data * -1;
				const formatted = absoluteNumber.toLocaleString('en-US');
				return '(' + formatted + ')';
			} else {
				return '-';
			}
		} else {
			return data;
		}
	}

	ngOnDestroy() {
		if (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/NotesToFinancialStatement') {
			clearTimeout(this.userActivity);
		}
		this.previewSub ? this.previewSub.unsubscribe() : '';
		this.headerSub ? this.headerSub.unsubscribe() : '';
		this.showDraftSub ? this.showDraftSub.unsubscribe() : '';
		this.subscriptions && this.subscriptions.unsubscribe();
		this.fsService.removeFsDocPreviewPage(DocumentPreviewTypes.NOTES);
	}
}
