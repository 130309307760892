import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FS_STATEMENTS_PREVIEWS, STATEMENT_VIEW_TYPES } from 'countable@helpers';
import { StatementPreviewModel } from 'countable@model';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';

const textResponse: any = {responseType: "text"}

@Injectable({
  providedIn: 'root'
})

export class FsExportService {

  private readonly PATH = environment.apiV1;
  private fsDocsMap = new Map<FS_STATEMENTS_PREVIEWS,boolean>;
  public fsDocsStore: Map<FS_STATEMENTS_PREVIEWS, BehaviorSubject<any>> = new Map<FS_STATEMENTS_PREVIEWS, BehaviorSubject<any>>();
  private readonly FS_STATEMENT_PREVIEW: string = this.PATH + '/get-preview-document';

  constructor(public authService: AuthService, private httpClient: HttpClient) {}

  getEngagementDetails() {
    return JSON.parse(localStorage.getItem('selectedEngagementObj'));
  }

  getEngagementId(): number {
   return this.getEngagementDetails().engagementsid;
  }

  getTBAcctYear(): string {
    return localStorage.getItem('trailBalYear')
  }

  getTypeOfEntityId(): number {
    return this.getEngagementDetails().typeofentityid ??  this.getEngagementDetails().typeOfEntityId;
  }

  getYearEndDate(): string {
    return localStorage.getItem('yearEnd');
  }

  private getFsPreviewInputs(typeOfPreview: FS_STATEMENTS_PREVIEWS,placeOfPreview: STATEMENT_VIEW_TYPES): StatementPreviewModel {
    const Obj: StatementPreviewModel = new StatementPreviewModel();
    Obj.engagementsid = this.getEngagementId();
    Obj.acctyear = (localStorage.getItem('workbookChangeDate') && placeOfPreview === "Workbook") ? localStorage.getItem('workbookChangeDate') : this.getTBAcctYear();
    Obj.documentName = typeOfPreview;
    Obj.typeOfEntity = this.getTypeOfEntityId();
    Obj.yearEndDate = (localStorage.getItem('workbookChangeYearEnd') && placeOfPreview === "Workbook") ? localStorage.getItem('workbookChangeYearEnd') : this.getYearEndDate();
    Obj.isFromWorkbook = placeOfPreview === "Workbook";
    Obj.isLastYear = (localStorage.getItem('isLastYear') && (placeOfPreview === "Workbook") && (localStorage.getItem('isLastYear') === 'true')) ? true : false;
    return Obj;
  }

  public fetchFSDocPreview(typeOfPreview: FS_STATEMENTS_PREVIEWS,placeOfPreview: STATEMENT_VIEW_TYPES = 'None'): Observable<any> {
    const Obj = this.getFsPreviewInputs(typeOfPreview,placeOfPreview)
    return this.httpClient.post(this.FS_STATEMENT_PREVIEW,Obj,textResponse).pipe(
      tap(response => this.setFsDocsPreviewPage(typeOfPreview,response)),
      tap(response => (response && (placeOfPreview === 'Workbook' && localStorage.removeItem('refreshWorkbookPreview'))))
    )
  }

  public getFsDocsPreviewPage(typeOfStatement: FS_STATEMENTS_PREVIEWS) :Observable<any> {
    if(!this.fsDocsStore.has(typeOfStatement)) {
      this.fsDocsStore.set(typeOfStatement,new BehaviorSubject<any>(null));
      this.fsDocsMap.set(typeOfStatement,null);
    }
    this.logMapsData('setFsDocsPreviewPage');
    // return this.fsDocsStore.get(typeOfStatement)?.asObservable();
    return this.fsDocsStore.get(typeOfStatement)
  }

  public setFsDocsPreviewPage(typeOfStatement: FS_STATEMENTS_PREVIEWS,previewHtml: any) {
    if(!this.fsDocsStore.has(typeOfStatement)){
      this.fsDocsStore.set(typeOfStatement,new BehaviorSubject<any>(null));
    }
    this.fsDocsStore.get(typeOfStatement)?.next(previewHtml)
    this.fsDocsMap.set(typeOfStatement,previewHtml);
    this.logMapsData('setFsDocsPreviewPage');
  }

  public hasFsDocsPreviewPage(typeOfStatement: FS_STATEMENTS_PREVIEWS): boolean {
    this.logMapsData('hasFsDocsPreviewPage');
    return this.fsDocsMap.has(typeOfStatement) && (this.fsDocsMap.get(typeOfStatement) !== null);
  }

  public removeFsDocPreviewPage(typeOfStatement: FS_STATEMENTS_PREVIEWS) {
    if(this.fsDocsStore.has(typeOfStatement) && this.fsDocsStore.get(typeOfStatement)?.value) {
      this.fsDocsStore.delete(typeOfStatement);
      this.fsDocsMap.delete(typeOfStatement);
    }
    this.logMapsData('removeFsDocPreviewPage');
  }

  public removeAllFsDocs() {
    this.fsDocsStore.clear();
    this.fsDocsMap.clear();
    this.logMapsData('removeAllFsDocs');
  }

  logMapsData(step) {
  }
}
