import { Location } from '@angular/common';
import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChildWindowService } from '../../../services/window-service';
import { SharedService } from '../shared.service';
import { GeneralPopupComponent } from './general-popup/general-popup.component';
import { PdfComponent } from './pdf/pdf.component';
import { BlurService } from 'src/app/services/blur.service';
import { InvalidExtensions, MSOfficeExtensions } from 'src/app/helpers/extensions.const';

/**
 * This directive will decide to
 * open the file which was clicked by user
 * in the middle panel or
 * load it in a popup based on the acceptable extensions.
 *
 * @see doc-preview Route
 * @see getleftfinancialstatement.sql to see the structure of document
 */
@Directive({
  selector: '[appFilePreview]'
})
export class FilePreviewDirective implements OnInit {

  @Input() fileData: any;
  @Input() inline = false;
  @Input() viewMode = 'view';
  @Input() isReadOnly : boolean;

  private officeExtensions: string[] = Object.values(MSOfficeExtensions);
  private invalidExtensions: string[] = Object.values(InvalidExtensions);
  private userDetails: any;

  constructor(
    private sharedService: SharedService,
    private router: Router,
    private windowService: ChildWindowService,
    private activatedRoute: ActivatedRoute,
    private toaster: ToastrService,
    private location:Location,
    private blurService:BlurService) {
  }

  ngOnInit(): void {
    this.userDetails = JSON.parse((localStorage.getItem('userDetails')));
  }

  @HostListener('click')
  onClick() {
    if (this.invalidExtensions.includes(this.fileData.extension)) {
      this.toaster.error('The file cannot be opened because the file format is unsupported. Please download the file to view it.', 'Error');
      return;
    }

    if(!this.fileData.mssdkfileid && this.fileData.engleftsidedocid && this.fileData.engteamclientdocid) {
      if (this.fileData.extension === 'json') {
        return;
      } else {
        this.toaster.error('Could not process the request! ', 'Error');
        return;
      }
    }

    if (this.fileData.extension.toLowerCase() === 'pdf') {
      this.openPdfFile();
    } else if (this.officeExtensions.includes(this.fileData.extension)) { // https://s3.amazonaws.com/client-docs-qa-01/Engagements/2123_Firm/2405_Client/497_engagement/ClientDocs/Client%20Acceptance/window10ppt-170119143021.pdf
      this.openOfficeFile();
    } else if(!this.fileData.engleftsidedocid && !this.fileData.engteamclientdocid) {
      this.openWorkSheetProcedures();
    } else {
      if (this.fileData.extension.toLowerCase() === 'json') {
        return;
      } else {
        this.openGeneralFile();
      }
    }
  }

  private openOfficeFile() {
    this.blurService.setBlurState(true);
    localStorage.setItem('currentFileinWindow', this.fileData.mssdkfileid);
    const newWindow = window.open('file-preview/ms/' + this.inline + '/' + this.fileData.mssdkfileid + '/' + this.viewMode+ '/' + this.isReadOnly,'_blank',
      'toolbar=0,location=0,scrollbars=1,statusbar=1,menubar=0,resizable=1,width=1200,height=800,left=100,top=50');
      const interval = setInterval(() => {
        if (newWindow.closed) {
          clearInterval(interval);
          this.blurService.setBlurState(false);
        }
      }, 1000);
  }

  private openPdfFile() {
    let isReadonly = this.isReadOnly? this.isReadOnly : false;
    this.inline = false; // CPT-2740, we keep the old approach just in case.
    localStorage.setItem(PdfComponent.PDF_STORAGE_PREFIX + this.fileData.mssdkfileid, JSON.stringify(this.fileData));
    localStorage.setItem('currentFileinWindow', this.fileData.mssdkfileid);
    if (this.inline) {
      this.router.navigate(['file-preview', 'pdf', this.inline, this.fileData.mssdkfileid, this.viewMode,isReadonly], {relativeTo: this.activatedRoute});
    } else {
      if (isReadonly) {
        this.blurService.setBlurState(true);
        const newWindow = window.open('file-preview/pdf/' + this.inline + '/' + this.fileData.mssdkfileid + '/' + this.viewMode + '/' + isReadonly, '_blank',
          'toolbar=0,location=0,scrollbars=1,statusbar=1,menubar=0,resizable=1,width=1200,height=800,left=100,top=50');
          const interval = setInterval(() => {
            if (newWindow.closed) {
              clearInterval(interval);
              this.blurService.setBlurState(false);
            }
          }, 1000);

      } else {
        this.blurService.setBlurState(true);
        const newWindow = window.open('file-preview/pdf/' + this.inline + '/' + this.fileData.mssdkfileid + '/' + this.viewMode, '_blank',
          'toolbar=0,location=0,scrollbars=1,statusbar=1,menubar=0,resizable=1,width=1200,height=800,left=100,top=50');
          const interval = setInterval(() => {
            if (newWindow.closed) {
              clearInterval(interval);
              this.blurService.setBlurState(false);
            }
          }, 1000);
      }
    }
  }

  private openGeneralFile() {
    this.sharedService.getS3url('', this.fileData.filepath).subscribe(res => {
      localStorage.setItem(GeneralPopupComponent.O_STORAGE_PREFIX + this.fileData.mssdkfileid + '-docUrl', res);
    }, error => {
    });

    localStorage.setItem(GeneralPopupComponent.O_STORAGE_PREFIX + this.fileData.mssdkfileid + '-fileExtention', this.fileData.extension);
    localStorage.setItem('currentFileinWindow', this.fileData.mssdkfileid);
    this.blurService.setBlurState(true);
    const newWindow = window.open('file-preview/o/' + false + '/' + this.fileData.mssdkfileid + '/' + this.viewMode, '_blank',
      'toolbar=0,location=0,scrollbars=1,statusbar=1,menubar=0,resizable=1,width=1200,height=800,left=100,top=50');
      const interval = setInterval(() => {
        if (newWindow.closed) {
          clearInterval(interval);
          this.blurService.setBlurState(false);
        }
      }, 1000);
  }


  private openWorkSheetProcedures() {
    const engId = +localStorage.getItem('engagementID');
    const angularRoute = this.location.path();
    const url = window.location.href;
    const domainAndApp = url.replace(angularRoute, '');
    const procedureUrl = domainAndApp + '/view-procedures/' + engId + '/' + this.fileData.leadsheetid;

    const STORAGE_KEY = 'windowWorksheetProcedure-' + engId + '-' + `${this.fileData.leadsheetid}`;
    const REFRESH_KEY = 'isRefreshProceduresWindow-' + engId + '-' + `${this.fileData.leadsheetid}`;
    localStorage.setItem(STORAGE_KEY, JSON.stringify(this.fileData));
    localStorage.setItem(REFRESH_KEY, 'false');
    const proceduresWindow = window.open(procedureUrl, '_blank', 'location=yes,height=670,width=1022,scrollbars=yes,status=yes,left=600,top=250');
    this.windowService.addWorkSheetWindow(proceduresWindow, this.fileData.filepath);
  }
}
