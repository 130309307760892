import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from '../shared/shared.service';
import { Subscription } from 'rxjs';
import { WebSocketService } from '../../services/websocket.service';
import { MatAccordion } from '@angular/material/expansion';
import { UntypedFormGroup, Validators, FormBuilder, UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { generateId } from '../../services/app-config';
@Component({
    selector: 'app-user-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserNotificationsComponent implements OnInit {
    @ViewChild(MatAccordion) accordion: MatAccordion; getScheduleData: any;
    getFrimScheduleTime: any;
    conCateDateScheduleTime: string;
    ttNotifications: any = [];
    expand: boolean = false;
    isNotifications: boolean = true;
    isSettingsOn: boolean = false;
    isAdminSettings: boolean = false;
    isEngagementsNotifications: boolean = false;
    loggedInUserData: any;
    notificationDetails: any = [];
    engagementNotificatinons: any = [];
    otherNotificatinons: any = [];
    result: any = [];
    selectedEngagement: any = [];
    engagementDropdownList: any = [];
    selectedFilter: string = '';
    greeting: any;
    name: string;
    scheduleTime: string[] = ['06: 00 AM', '06: 30 AM', '07: 00 AM', '07: 30 AM', '08: 00 AM', '08: 30 AM'];
    notifySub: Subscription;
    uuid: number;
    recieveNotifySub: Subscription;
    scheduleForm: UntypedFormGroup;
    isTrackerOn: number;
    onOffSub: Subscription;

    constructor(private spinner: NgxSpinnerService,
        private apiService: ApiService,
        private sharedService: SharedService, public toaster: ToastrService,
    ) { }

    ngOnInit() {
        this.loggedInUserData = JSON.parse((localStorage.getItem('userDetails')));
        this.isTrackerOn = +localStorage.getItem('istimetrackingon');
        this.onOffSub = this.sharedService.updateTrackerOnOff.subscribe( isOnOff => this.isTrackerOn = isOnOff);
        this.uuid = this.loggedInUserData.useracctid;
        this.scheduleForm = new UntypedFormGroup({
            scheduleTime: new UntypedFormControl(''),
            reminderTime: new UntypedFormControl(''),
        })
        //this.getNotificationDetails(); remove this call
        this.getNotificationStatus();
        this.getScheduleTimeData(); //get default time
        this.getScheduleTime(); // get time list
    }

    viewSettings() {
        this.isSettingsOn = true;
        this.isNotifications = false;
        this.isEngagementsNotifications = true;
        this.selectNotifications();
    }

    closeSettings() {
        this.isSettingsOn = false;
        this.isNotifications = true;
    }

    trackByRecords(index, item) {
        return index; // or item.id
    }
    trackByfirstRecords(index, item) {
        return index; // or item.id
    }
    selectNotifications() {
        this.selectedEngagement = [];
        this.selectedFilter = '';
        if (this.isEngagementsNotifications) {
            this.isEngagementsNotifications = false;
            this.notificationDetails = this.result;
        } else {
            this.isEngagementsNotifications = true;
            this.notificationDetails = this.result.filter(function (element) {
                return (element.engagementsid > 0);
            });

            const result = [];
            const map = new Map();
            for (const item of this.notificationDetails) {
                if (!map.has(item.engagementsid)) {
                    map.set(item.engagementsid, true);
                    result.push({
                        engagementsid: item.engagementsid,
                        engagementname: item.engagementname
                    });
                }
            }
            this.engagementDropdownList = result;
        }
    }

    getNotificationDetails() {
        const inputData = { "loginid": this.loggedInUserData.useracctid, "isnew": 1, 'isfirm': 0 };//Need to remove "isnew": 1
        const inputString = JSON.stringify(inputData);

      let data = {
          "procedureName": "gettracknotification",
          "inputParameters": inputString,
          "inputEnc": generateId[Math.floor(Math.random() * generateId.length)]
      };
      this.spinner.show();
      this.apiService.getData(data).subscribe(response => {
          this.spinner.hide();
          if (response) {
              let result = JSON.parse(response);
              if(result){
                  result.forEach(e => {
                      if(e.loginid == this.uuid){
                          this.result = e.notification;
                          this.notificationDetails = e.notification;
                          let notifyCount = e.notecountunread;
                          let overdueCount = e.overduecount;
                          let remindercount = e.remindercount;
                          this.sharedService.notifyCountSubject.next(notifyCount);
                          this.sharedService.notifyOverdueCountSubject.next(overdueCount);
                          this.sharedService.notifyRemindercountSubject.next(remindercount);
                      }
                  });
              }
          }
      }, error => {
          this.spinner.hide();
      });
  }

    getNotificationStatus() {
        const inputData = { "loginid": this.loggedInUserData.useracctid };
        const inputString = JSON.stringify(inputData);

        let data = {
            "procedureName": "getnotificationstatus",
            "inputParameters": inputString,
            "inputEnc": generateId[Math.floor(Math.random() * generateId.length)]
        };
        this.spinner.show();
        this.apiService.getData(data).subscribe(response => {
            this.spinner.hide();
            let result: any = [];
            if (response) {
                result = JSON.parse(response);
                this.engagementNotificatinons = result.EngagementNotificatinons;
                this.otherNotificatinons = result.otherNotificatinons;
                this.ttNotifications = result.timetrackingnotificatinons;
            }
        }, error => {
            this.spinner.hide();
        });
    }

    getScheduleTimeData() {
        const inputData = {
            "loginid": this.loggedInUserData.useracctid,
            "firmuseraccountid": this.loggedInUserData.firmUserAcctId
        };
        const inputString = JSON.stringify(inputData);

        let data = {
            "procedureName": "getfirmscheduletime",
            "inputParameters": inputString
        };
        this.spinner.show();
        this.apiService.getData(data).subscribe(response => {
            this.spinner.hide();
            let result: any = [];
            if (response) {
                result = JSON.parse(response);
                let schdulerSystemTime, reminderSystemTime
                this.getFrimScheduleTime = result;
                if (this.getFrimScheduleTime[0].systemtime != null) {
                    schdulerSystemTime = moment(this.getFrimScheduleTime[0].systemtime).format('hh:mm A');
                }
                if (this.getFrimScheduleTime[1].systemtime != null) {
                    reminderSystemTime = moment(this.getFrimScheduleTime[1].systemtime).format('hh:mm A');
                }
                // console.log('IST: ',  schdulerSystemTime)
                // console.log('IST: ',  reminderSystemTime)
                this.scheduleForm.patchValue({
                    scheduleTime: schdulerSystemTime ? schdulerSystemTime : this.getFrimScheduleTime[0].sctime,
                    reminderTime: reminderSystemTime ? reminderSystemTime : this.getFrimScheduleTime[1].sctime
                });

            }
        }, error => {
            this.spinner.hide();
            this.toaster.error(error.error);
        });
    }

    getScheduleTime() {
        const inputData = { "loginid": this.loggedInUserData.useracctid };
        const inputString = JSON.stringify(inputData);

        let data = {
            "procedureName": "getscheduletime",
            "inputParameters": inputString
        };
        this.spinner.show();
        this.apiService.getData(data).subscribe(response => {
            this.spinner.hide();
            let result: any = [];
            if (response) {
                result = JSON.parse(response);
                // console.log(result);
                this.getScheduleData = result;
            }
        }, error => {
            this.spinner.hide();
            this.toaster.error(error.error);

        });
    }

    dateSchedulecutter(dateTime) {
        let dayDate, time, geoCode, geoAbbr
        var d = new Date().toString();
        // console.log(d);
        var index = d.lastIndexOf(':') - 6
        dayDate = d.substring(0, index);
        // console.log("dayDate-->",dayDate); //dayMonth Year
        geoAbbr = d.split(' ').slice(6, 9).join(' ');
        // console.log("geoAbbr", geoAbbr);
        let g = d.indexOf(geoAbbr);
        geoCode = d.substring(24, g)
        // console.log("geoCode-->",geoCode); // GeoLocation Code
        let geoCodeItem = d.substr(d.lastIndexOf(geoCode))
        // console.log("geoCodeItem-->",geoCodeItem); // GMT+0530 (India Standard Time)
        // Thu May 13 2021 20:33:15 GMT+0530 (India Standard Time)
        var convertedTime = moment(dateTime, 'hh:mm A').format('HH:mm') + ":00"
        this.conCateDateScheduleTime = dayDate.trim() + ' ' + convertedTime.trim() + ' ' + geoCodeItem.trim();
        // console.log("conCateDate -->", this.conCateDateScheduleTime);

        // var utc = moment.utc(this.conCateDateScheduleTime).format('hh:mm A')
        // console.log('Geo UTC: ',  utc)
        // console.log('Geo IST: ',  moment(this.conCateDateScheduleTime).format('hh:mm A'))
    }

    //Save Schedule Time
    onScheduleTime(data, type) {
        this.dateSchedulecutter(data.time);
        // console.log(type);
        var scheduleTime = new Date(this.conCateDateScheduleTime);
        var utcDate = scheduleTime.toUTCString();
        // console.log(utcDate);
        // console.log('IST: ',  moment(scheduleTime).format('hh:mm A'))
        var local = moment.utc(utcDate).local().format('hh:mm A');
        // console.log('Local Time: ', local);
        // console.log(d);
        // console.log('IST: ',  moment(d).format('hh:mm A'))
        // var ist = moment(d).format('hh:mm A');
        var utc = moment.utc(scheduleTime).format('hh:mm A');
        // console.log('IST: ',  moment(utc).format('hh:mm A'))

        //   console.log('UTC: ',  utc)
        //   console.log(type);
        let inputData: any;
        if (type == "1") {
            inputData = {
                "save":
                    [{
                        "firmuseraccountid": this.loggedInUserData.firmUserAcctId,
                        "bulkrequesttypeid": 1,
                        "sctime": utc,
                        "firmbulkrequestid": this.getFrimScheduleTime[0].firmbulkrequestid,
                        "loginid": this.loggedInUserData.useracctid,
                        "systemtime": this.conCateDateScheduleTime
                    }]
            };
        } else {
            inputData = {
                "save":
                    [{
                        "firmuseraccountid": this.loggedInUserData.firmUserAcctId,
                        "bulkrequesttypeid": 2,
                        "sctime": utc,
                        "firmbulkrequestid": this.getFrimScheduleTime[1].firmbulkrequestid,
                        "loginid": this.loggedInUserData.useracctid,
                        "systemtime": this.conCateDateScheduleTime
                    }]
            };
        }

        const inputString = JSON.stringify(inputData);
        let saveData = {
            "procedureName": "savefirmdailyrequesttime",
            "inputParameters": inputString
        };
        this.spinner.show();
        this.apiService.getData(saveData).subscribe(response => {
            this.spinner.hide();
            let result: any = [];
            if (response) {
                result = JSON.parse(response);
                // console.log(result);
                this.getScheduleTimeData();
                // this.getScheduleData = result;
            }
        }, error => {
            this.spinner.hide();
            this.toaster.error(error.error);
        });
    }



    slideToggleChanged(event, data) {
        const inputData = {
            "notify":
                [{
                    "notificationid": data.notificationid,
                    "useraccountid": data.useraccountid,
                    "isnotify": event.checked,
                    "usernotificationid": data.usernotificationid
                }]
        };
        const inputString = JSON.stringify(inputData);
        let saveData = {
            "procedureName": "saveusernotification",
            "inputParameters": inputString
        };

        this.spinner.show();
        this.apiService.saveData(saveData).subscribe(response => {
            this.spinner.hide();
            if (response) {
                this.getNotificationStatus();
                //this.getNotificationDetails();
                this.sharedService.notifySubject.next(true);//don't remove it i am calling to refresh: this.getNotificationDetails()
            }
        }, error => {
            this.spinner.hide();
        });
    }

    onSelectedEngagement(selected) {
        if (selected) {
            this.selectedFilter = selected.engagementname;
        } else {
            this.selectedFilter = '';
        }
    }

    onTurnOffNotification(value) {
        const inputData = {
            "notify":
                [{
                    "notificationid": value.notificationid,
                    "useraccountid": value.useraccountid,
                    "isnotify": false,
                    "usernotificationid": value.usernotificationid
                }]
        };
        const inputString = JSON.stringify(inputData);
        let saveData = {
            "procedureName": "saveusernotification",
            "inputParameters": inputString
        };

        this.spinner.show();
        this.apiService.saveData(saveData).subscribe(response => {
            this.spinner.hide();
            if (response) {
                this.getNotificationStatus();
                this.getNotificationDetails();
                this.isEngagementsNotifications = true;
                this.selectNotifications();
            }
        }, error => {
            this.spinner.hide();
        });
    }

    trackByNotificationRead(index: number, notificationDetails: any): string {
        return notificationDetails.isread;
    }

    onNotificationRead(value) {
        if (value.isread === 0) {
            const inputData = {
                "usertrack":
                    [{
                        "tracknotificationid": value.tracknotificationid,
                        "useraccountid": value.useraccountid,
                        "isread": 1,
                        "isallcleared": 0,
                        "usertracknotificationid": 0
                    }]
            };
            const inputString = JSON.stringify(inputData);
            let saveData = {
                "procedureName": "saveusertracknotification",
                "inputParameters": inputString
            };

            this.spinner.show();
            this.apiService.saveData(saveData).subscribe(response => {
                this.spinner.hide();
                if (response) {
                    this.getNotificationStatus();
                    this.getNotificationDetails();
                    this.isEngagementsNotifications = true;
                    this.selectNotifications();
                }
            }, error => {
                this.spinner.hide();
            });
        }
    }
    ngOnDestroy() {
        this.onOffSub ? this.onOffSub.unsubscribe():'';
    }

}
