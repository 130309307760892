export enum LetterConstants {
   MOVE_SUCCESS = 'Letter template is moved successfully',
   EL_SAVE_SUCCESS = 'Engagement letter saved successfully',
   SECTION_ADD_SUCCESS = 'New section added successfully',
   SECTION_RENAME_SUCCESS = 'Section renamed successfully',
   SECTION_NAME_VALIDATION_ERROR = 'Please enter a valid Section Name',
   SECTION_DELETE_SUCCESS = 'Section deleted successfully',
   SECTION_NAME_EMPTY = 'Please enter a section name',
   VALID_PDF_MESSAGE ='Please import valid Pdf file',
   DELETE_SUCCESS = 'Deleted successfully',
   LETTER_ADD_SUCCESS = 'Letter added to the engagement successfully',
   LETTER_REPLACE_SUCCESS = 'letter replaced in the engagement successfully',
   EL = 'Engagement Letter',
   ACCEPTED = 'accepted and signed successfully',
   SIGN_SUCCESS = 'signed off successfully',
   REMOVE_SUCCESS = 'signature removed successfully',
   RESEND_SUCCESS = 'successfully resend to client',
   RL = 'Remove Letter',
   RL_CONTENT = 'You are trying to remove an engagement letter Are you sure you want to continue ?',
   SS = 'Select a Signee',
   SIGN_WHO = 'Who is sigining the letter?',
   HEADER = 'Header goes here',
   FOOTER = 'Footer goes here',
   RS = 'Remove Signature',
   FS = 'Firm Sign Here',
   DL = 'Delete Letter',
   DC_ONE = 'You are trying to delete a engagement letter',
   DC_TWO = 'Are you sure you want to continue ?',
   SENT_SUCCESS ='Engagement letter successfully sent to client',
   RESEND_SUCESSS = 'Engagement letter successfully resent to client',
   SF = "Signature - Firm",
   FN = "Firm Name",
   SC = "Signature - Client",
   EN = "Entity Name",
   NO_TEMPLATE = " Select a template to view",
   ADD_CONTENT = "Click here to add content",
   LETTER_EXISTS = "Template name already exists, please enter a different name",
   LN ="Letter name",
   DUPLICATE_SUCCESS = "Letter template is duplicated successfully",
   DEFAULT_HEADER = "Save Changes to default template",
   DEFAULT_CONTEXT = `The changes made to the default template will be reflected to all the
   new engagements going forward and won’t be applied to existing engagements. Do you want to continue?`,
   SAVE_SUCCESS = 'Template saved successfully',
   DELETE_FOLDER = 'Delete Folder',
   DELETE_CONTEXT = 'You are trying to delete an folder from firm templates. Are you sure you want to continue?',
   CANCEL_HEADER = 'Cancel the Template',
   CANCEL_CONTEXT_ONE = 'There maybe unsaved changes',
   CANCEL_CONTEXT_TWO = 'Do you want to cancel the changes to template?',
   CHECK_WARNING_HEADER = 'Copying Letters',
   CHECK_WARNING_CONTEXT = 'To copy the selected letters, please use copy icon beside search to copy them to firm templates',
   DL_T = 'Delete letter',
   DL_T_CONTEXT = 'Are you sure you want to delete this letter?\n' + 'This action cannot be undone.',
   FIRM_SIGN = 'Add Signature - Firm',
   CLIENT_SIGN = 'Add Signature - Client',
   YT = 'Yours truly',
   ON_BEHALF = 'On Behalf of,',
   PLACE_HOLDER = 'Please use elements and sections from the right panel to create your letter',
   US_COMPILATION_FOLDER = "SSARS 23-25 - Statement on Standards for Accounting and Review Services",
   EMPTY_ELEMENTS = "The automated elements are not populated",
   CREATE_BLANK_LETTER = "Create New Letter",
   LETTER_NAME = 'Letter name',
   CREATE_NEW_LETTER = "Create new letter",
   SELECT_LOCATION = "Select a location",
   PLACEHOLDER = "Letter Name",
   SELECT_CONTEXT = "Select where you want to add this letter",
   MOVE_CONTEXT = "Select where you want to move this letter",
   EXISTING_FOLDER = "Add to existing folder",
   FOLDER_NAME = "Folder name",
   NEW_FOLDER = "New folder name",
   FOLDER_EXISTS = "Folder Name already exists",
   DELETE_LETTER_SUCCESS = 'Letter(s) deleted successfully',
   RENAME_EXISTS = 'Letter with name already exists',
   RENAME_ENG_EXISTS = 'Engagement template with name already exists',
   FIRM_SIGN_HEADER = 'Select a signee',
   FIRM_SIGN_BODY = 'Who is signing the letter?',
   SEND_TO_CLIENT_INFO = 'firm signature and client signature components are present \n client should be accepted \n Firm Signature Should be Added',
   CANCEL_CONTEXT = 'Changes made must be lost. Are you sure, you want to discard changes?',
   SAVE_CONTEXT = `There are unsaved changes in the engagement letter. Are you sure, you want to discard changes?`,
   SAVE_HEADER = 'Save changes',
   DELETE_TEMPLATE = 'Delete Letter',
   DELETE_TEMPLATE_BODY = 'You are trying to remove an management letter. Are you sure you want to continue ?',
   ML_SAVE_SUCCESS = 'Successfully saved changes to the ',
   ML_ADD_SUCCESS = 'Successfully added letter',
   ML_REPLACE_SUCCESS = 'Successfully replaced letter',
   ML_DELETE_SUCCESS = 'Successfully deleted letter',
   LETTER_COPY_SUCCESS = 'Letter(s) copied successfully to firm templates',
   LETTER_PLACEHOLDER = 'Please use elements and sections from the right panel to create your letter',
   REPORT_PLACEHOLDER = 'Please use elements and sections from the right panel to create your report'
}

export enum LETTER_SIGNATURES {
   CLIENT_SIGN_HERE = `<a class="mt-2 button-small mat-button mat-flat-button mat-primary mat-success">Client Sign Here</a>`,
   FIRM_SIGNATURE = `<span class="auto-elements is-locked" style="background-color: #ddf0df;">Firm Signature</span>`,
   FIRM_SIGN_HERE = `<a class="mt-2 button-small mat-button mat-flat-button mat-primary mat-success">Firm Sign Here</a>`,
   CLIENT_SIGNATURE = `<span class="auto-elements is-locked" style="background-color: #ddf0df;">Client Signature</span>`,
   FIRM_SIGNATURE_CONTENT = `<p>Yours truly,<br><strong>ON BEHALF OF</strong>
                              <span class="auto-elements is-locked" style="background-color: #DDF0DF;">Firm Name</span><br><br>
                              <span class="auto-elements is-locked" style="background-color: #DDF0DF;">Firm Signature</span>
                              <br>(Signature)<br><br>
                              <span class="auto-elements is-locked" style="background-color: #DDF0DF;">Date</span>
                              <br>(Date)</p>`,
   CLIENT_SIGNATURE_CONTENT = `<p><strong>ACKNOWLEDGED AND AGREED ON BEHALF OF THE MANAGEMENT OF</strong>&nbsp;
                               <span class="auto-elements is-locked" style="background-color: #DDF0DF;">Entity Name</span></p>
                              <p>&nbsp;</p>
                              <p><span class="auto-elements is-locked" style="background-color: #DDF0DF;">Client Signature</span><br>(Signature)<br><br></p>
                              <p><span class="auto-elements is-locked" style="background-color: #ddf0df;">Client Signed Date</span><br>(Date)</p>`,
   CLIENT_SIGNATURE_CONTENT_ML = `<p>Yours Truly,</p>
                              <p>&nbsp;</p>
                              <p><span class="auto-elements is-locked" style="background-color: #DDF0DF;">Client Signature</span><br>(Signature)<br><br></p>
                              <p><span class="auto-elements is-locked" style="background-color: #ddf0df;">Client Signed Date</span><br>(Date)</p>`
}
