import { NestedTreeControl } from '@angular/cdk/tree';
import { DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { DocumentPreviewTypes, STATEMENT_VIEW_TYPES } from 'countable@helpers';
import { FsExportService, SpinnerService } from 'countable@services';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { TimeTrackerService } from 'src/app/services/time-tracker.service';
import { ApiService } from '../../../../../services/api.service';
import { idleTime, modules } from '../../../../../services/app-config';
import { SharedService } from '../../../../shared/shared.service';

interface IncomeStatementTree {
  name: string;
  children?: IncomeStatementTree[];
  grouptotal?: string;
  subgrouptotal?: string;
  total?: string;
  bal?: any;
}
@Component({
  selector: 'app-statement-of-retained-earning',
  templateUrl: './statement-of-retained-earning.component.html',
  styleUrls: ['./statement-of-retained-earning.component.scss']
})
export class StatementOfRetainedEarningComponent implements OnInit {
  treeControl = new NestedTreeControl<IncomeStatementTree>(node => node.children);
  dataSource = new MatTreeNestedDataSource<IncomeStatementTree>();
  @ViewChild('tree') tree;
  headerNotesColumn1 = '';
  headerNotesColumn2: any;
  headerNotesColumn3: any;
  clientName: any
  currentYear: any;
  currentYearFIS: any;
  previousYearFIS: any;
  submoduleId: number;
  engagementID: any;
  engagementData: any;
  engagementId: number;
  year: number;
  retainedEarningsData: any;
  incomeStmtYear: string;
  yearEnd: string;
  engagementName: string;
  balStmtDetails: any;
  isIncomePrevYear: boolean = false;
  selectedDate: any;
  incomeHeaderInfo: any;
  refreshRetainedEarnings: any;
  refreshIncomeStatement: any;
  onLoadDataSub: Subscription;
  rEPreviewSub: Subscription;
  retainedToIncomeSub: Subscription;
  isShowDraft: boolean = false;
  showDraftSub: Subscription;
  trackobj: { engagementsId: any; submoduleId: any; submoduleUuid: any; };
  userActivity;
  userInactiveSub: Subscription;
  userInactive: Subject<any> = new Subject();
  isHideNotesToFS: boolean;
  footerNotes: any = '';
  NotesExists: any;
  retainedearningslinkednotesExists: boolean = false;
  LocalStorageprevYear: any
  deficitStatementData: any;
  userLoginId: any;
  userDetails: any;
  approvalText: string;
  isWorkBook: any;

  private subscriptions: Subscription = new Subscription();
  public retainedEarnings$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public placeOfPreview: STATEMENT_VIEW_TYPES = "None";

  // footer text
  footerText: string = '';
  isHideFooterNotesToFS = false;
  retainedEarningData: any;
  @Input() headerFooterData: Observable<any>;

  @HostListener('window:mousemove', ["$event"])
  @HostListener('window:resize', ["$event"])
  @HostListener('window:keydown', ["$event"])
  refreshUserState() {
    clearTimeout(this.userActivity);
    if (localStorage.getItem("timerStatus") == "Idle")
      this.trackerService.startTimer("Active");
    this.setTimeout();
  }
  @HostListener('window:storage', ['$event'])
  onStorageChange(ev: StorageEvent) {
    this.LocalStorageprevYear = localStorage.getItem('prevYear')
    this.NotesExists = localStorage.getItem('NotesExists')
    if (ev.key == 'RetainedEarningStatement') {
      let isyesno = parseInt(localStorage.getItem('isyesno'));
      if (isyesno == 0) {
        this.router.navigate(['/dashboard/ntrdashboard/FinancialStatement/doc/IncomeStatement']);
        localStorage.setItem('FsSubmoduleId', '436');
      }
    }
    if (ev.key == 'refreshFinancialStatements-RE' || ev.key == 'refreshFinancialStatements-LSG') {
      if (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/statement-of-retained-earning') {
        // this.getRetainedEarnings(true);
        this.footerText = '';
        this.fsService.removeFsDocPreviewPage(DocumentPreviewTypes.RETAINED_EARNINGS);
        this.setRetainedEarningsView();
        // this.getTBLSHeaderNotes();
      } else {
        localStorage.removeItem("refreshFinancialStatements-RE");
      }
    }
    if (ev.key == 'refreshFinancialStatements-REH') {
      if (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/statement-of-retained-earning') {
        this.getTBLSHeaderNotes();
      } else {
        localStorage.removeItem("refreshFinancialStatements-REH");
      }
    }
    if (ev.key == 'isfooterUpdated') {
      const note = localStorage.getItem('isfooterUpdated');
      this.showWaterMark(this.retainedEarningData, note);
    }
  }
  constructor(private apiservice: ApiService,
    private sharedservice: SharedService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    public router: Router,
    private trackerService: TimeTrackerService,
    private fsService: FsExportService,
    private renderer: Renderer2,
    private el: ElementRef,
    private spinnerService: SpinnerService) {
    //  this.dataSource.data = TREE_DATA;
    this.setTimeout();
    this.userInactive.subscribe(() => {
      this.trackerService.startTimer("Idle");
    });
    this.placeOfPreview = this.sharedservice.setPlaceofPreview(this.router.url);
  }

  ngOnInit() {
    localStorage.removeItem('workbookChangeDate');
    localStorage.removeItem('workbookChangeYearEnd');
    localStorage.removeItem('isLastYear');
    this.engagementData = JSON.parse(localStorage.getItem("selectedEngagementObj"));
    if (this.router.url == "/dashboard/ntrdashboard/FinancialStatement/doc/statement-of-retained-earning") {
      this.submoduleId = JSON.parse(localStorage.getItem('FsSubmoduleId'));
    } else {
      this.submoduleId = JSON.parse(localStorage.getItem('workBookSubmoduleId'));
    }
    setTimeout(() => {
      this.setRetainedEarningsView()
    }, 1000);
    // this.getTBLSHeaderNotes();
    this.subscriptions.add(
      this.retainedEarnings$.pipe(
        distinctUntilChanged(),
      ).subscribe({
        next: retainedEarningsData => {
          if (retainedEarningsData) {
            this.showWaterMark(retainedEarningsData);

            setTimeout(() => {
              const eleHeight = document.getElementById('eng_dynamicHeight') as HTMLElement;
              if (eleHeight.clientHeight < 759) {
                eleHeight.style.height = '760px';
              }
            }, 10);
          }
        }
      })
    )

    this.subscriptions.add(
      this.sharedservice.updateFinStatement$.subscribe({
        next: refreshStatement => {
          if (refreshStatement && (refreshStatement.has(DocumentPreviewTypes.RETAINED_EARNINGS) && refreshStatement.get(DocumentPreviewTypes.RETAINED_EARNINGS))) {
            console.log("Refresh-Statement-Of-Retained-Earnings");
            this.fsService.removeFsDocPreviewPage(DocumentPreviewTypes.RETAINED_EARNINGS);
            this.setRetainedEarningsView();
          }
        }
      })
    )

    this.sharedservice.reloadRetainEarningsPreview.subscribe(res => {
      this.fsService.removeFsDocPreviewPage(DocumentPreviewTypes.RETAINED_EARNINGS);
      this.setRetainedEarningsView();
      // this.getTBLSHeaderNotes();
    });

    this.subscriptions.add(
      this.headerFooterData.subscribe(response => {
        const note = localStorage.getItem('isfooterUpdated');
        this.showWaterMark(this.retainedEarningData, note);
      })
    )
  }

  setRetainedEarningsView() {
    this.subscriptions.add(
      this.fsService.getFsDocsPreviewPage(DocumentPreviewTypes.INCOME_STATEMENT).subscribe(resp => {
        console.log("Has-balance-Sheet-Data-Or-not", this.fsService.hasFsDocsPreviewPage(DocumentPreviewTypes.INCOME_STATEMENT));
        if (this.fsService.hasFsDocsPreviewPage(DocumentPreviewTypes.INCOME_STATEMENT) && resp) {
          console.log("Balance-Sheet-Preview-Has-executed-By-Subscription");
          this.retainedEarnings$.next(resp);
        } else if (!this.fsService.hasFsDocsPreviewPage(DocumentPreviewTypes.INCOME_STATEMENT) && !resp) {
          console.log("Balance-Sheet-Preview-Has-executed-By-Calling-Api", !resp);
          this.spinnerService.showSpinner();
          this.fsService.fetchFSDocPreview(DocumentPreviewTypes.INCOME_STATEMENT, this.placeOfPreview).subscribe({
            next: res => {
              this.retainedEarnings$.next(res);
              this.showWaterMark(res);
            }
          }).add(() => {
            this.spinnerService.hideSpinner();
          })
        }
      })
    )
  }

  ngOnInitOldCode() {
    this.spinner.show();
    localStorage.removeItem("refreshFinancialStatements-RE");
    localStorage.removeItem("refreshFinancialStatements-REH");
    this.NotesExists = localStorage.getItem('NotesExists');
    this.isWorkBook = localStorage.getItem('isWorkBook');
    this.onLoadDataSub = this.sharedservice.retainedSarnings.subscribe(res => {
      this.headerNotesColumn1 = res[0].header[0].changednameline1;
      this.headerNotesColumn2 = res[0].header[0].changednameline2;
      this.headerNotesColumn3 = res[0].header[0].changednameline3;
    });
    let draft = JSON.parse((localStorage.getItem('showdraft')));
    if (draft) {
      this.isShowDraft = draft.isShowDraft ? draft.isShowDraft : '';
    }
    this.currentYear = (localStorage.getItem('endYear'))
    this.engagementData = JSON.parse(localStorage.getItem("selectedEngagementObj"));
    if (this.router.url == "/dashboard/ntrdashboard/FinancialStatement/doc/statement-of-retained-earning") {
      this.submoduleId = JSON.parse(localStorage.getItem('FsSubmoduleId'));
    } else {
      this.submoduleId = JSON.parse(localStorage.getItem('workBookSubmoduleId'));
    }
    let selectedObj = JSON.parse(localStorage.getItem("selectedEngagementObjCopy"));

    this.trackobj = {
      "engagementsId": this.engagementData.engagementsid,
      "submoduleId": this.submoduleId,
      "submoduleUuid": localStorage.getItem("UUID"),
    }
    if (this.router.url == "/dashboard/ntrdashboard/FinancialStatement/doc/statement-of-retained-earning") {
      localStorage.setItem("timeTrackerObj", JSON.stringify(this.trackobj));
      this.trackerService.startTimer("Active");
    }
    this.clientName = selectedObj.clientName
    this.engagementID = localStorage.getItem('engagementID');
    this.getRetainedEarnings();
    this.rEPreviewSub = this.sharedservice.reloadRetainEarningsPreview.subscribe(res => {
      this.selectedDate = res;
      //this.getRetainedEarnings();
    });
    //this.getTBLSHeaderNotes();

    this.refreshRetainedEarnings = this.sharedservice.refreshBlIS.subscribe(res => {
      // this.getRetainedEarnings();
    });
    this.retainedToIncomeSub = this.sharedservice.retainedToIncome.subscribe(res => {
      // this.spinner.show();
      // let isyesno = parseInt(localStorage.getItem('isyesno'));
      // if (isyesno == 0) {
      //   this.router.navigate(['/dashboard/ntrdashboard/FinancialStatement/doc/IncomeStatement']);
      //   localStorage.setItem('FsSubmoduleId', '436');
      // }
      // setTimeout(() => {
      //   this.spinner.show();
      //   this.getDeficitStatementPreview();
      // }, 2000);
    });
    this.refreshIncomeStatement = this.sharedservice.refreshBlIS.subscribe(res => {
      // this.getRetainedEarnings()
    });
    this.showDraftSub = this.sharedservice.showdraft.subscribe(res => {
      this.isShowDraft = res.isShowDraft;
    });
    this.LocalStorageprevYear = localStorage.getItem('prevYear')
    let FSData = JSON.parse(localStorage.getItem('FSMenuData'))[0].subchild[0].child;
    for (let i = 0; i < FSData.length; i++) {
      if (FSData[i].originalfilename == "Statement of Income (Loss)") {
        let separateIncomeStatement = true;
        localStorage.setItem('separateIncomeStatement', separateIncomeStatement.toString());
      }
      else if (FSData[i].originalfilename == "Statement of Income (Loss) and Retained Earnings (Deficit)") {
        let separateIncomeStatement = false;
        localStorage.setItem('separateIncomeStatement', separateIncomeStatement.toString());
      }
    }
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (this.userDetails && this.userDetails.useracctid) {
      this.userLoginId = this.userDetails.useracctid;
    }
    let engagemenetData = JSON.parse(localStorage.getItem('selectedEngagementObj'));
    let entityId = engagemenetData.typeOfEntityId == null ? engagemenetData.typeofentityid : engagemenetData.typeOfEntityId
    this.apiservice.getFsSettings(Number(localStorage.getItem('engagementID')), entityId).subscribe(response => {
      if (response) {
        let fsSettingsData = JSON.parse(response);
        fsSettingsData.map(e => {
          if (e.settings != null) {
            e.settings.map(setting => {
              if (setting.engFinSettingId === 2) {
                setting.selectedOption == "Yes" ? localStorage.setItem('isTableOfContentSelected', "true") : localStorage.setItem('isTableOfContentSelected', "false");
              }
              if (setting.engFinSettingId === 7) {
                if (setting.selectedOption != undefined) {
                  setting.selectedOption == "Yes" ? localStorage.setItem('isOneYearDataAvailable', "true") : localStorage.setItem('isOneYearDataAvailable', "false");
                }
                else {
                  setting.options[0].userSelected == 1 ? localStorage.setItem('isOneYearDataAvailable', "true") : localStorage.setItem('isOneYearDataAvailable', "false");
                }
              }
              if (setting.engFinSettingId === 11) {
                setting.selectedOption == "Yes" ? localStorage.setItem('isBalancesheetPageBreakApplied', "true") : localStorage.setItem('isBalancesheetPageBreakApplied', "false");
              }
              if (setting.engFinSettingId === 8) {
                setting.selectedOption == "Yes" ? localStorage.setItem('showWaterMark', "true") : localStorage.setItem('showWaterMark', "false");
              }
              if (setting.engFinSettingId === 10) {
                setting.selectedOption == "Yes" ? localStorage.setItem('approvedBy', "true") : localStorage.setItem('approvedBy', "false");
              }
            });
          }
        });
      }
      //get board memeber data if null
      if (localStorage.getItem('boardMemberData') == null) {
        this.apiservice.getBoardMembers(Number(localStorage.getItem('engagementID'))).subscribe(response => {
          let boardMemberDataResponse: any;
          if (response && response.length != 0) {
            boardMemberDataResponse = JSON.parse(response);
            this.approvalText = boardMemberDataResponse[0].approvalText;
          }

          if (boardMemberDataResponse.length == 0 || (boardMemberDataResponse.length != 0 && boardMemberDataResponse[boardMemberDataResponse.length - 1].name != "")) {
            let newBoardMember = { boardMemberId: 0, name: "", title: "" };
            boardMemberDataResponse.push(newBoardMember);
          }

          // console.log("---------get board member--------------");
          // console.log(this.boardMemberData);
          localStorage.setItem("boardMemberData", JSON.stringify(boardMemberDataResponse));
          this.getDeficitStatementPreview();
          // this.refreshAppByBoardInBalSheet();
        }, error => { });
      }
      else {
        this.getDeficitStatementPreview();
      }
    });
  }


  getDeficitStatementPreview() {
    this.spinner.show();
    let boardMemberData: Array<any> = [];
    if (localStorage.getItem('approvedBy')[0] == 't') {
      let boardData = JSON.parse(localStorage.getItem('boardMemberData'));
      for (let i = 0; i < boardData.length; i++) {
        if (boardData[i].name.length != 0) {
          let newBoardMember = {
            name: boardData[i].name.toString(),
            title: boardData[i].title.toString()
          };
          boardMemberData.push(newBoardMember);
        }
      }
    }
    this.deficitStatementData = {
      "engagementsid": Number(localStorage.getItem('engagementID')),
      "acctyear": localStorage.getItem('trailBalYear'),
      "isPageBreak": (localStorage.getItem('isBalancesheetPageBreakApplied'))[0] == 't' ? true : false,
      "oneYear": (localStorage.getItem('isOneYearDataAvailable'))[0] == 't' ? true : false,
      "separateIncome": (localStorage.getItem('separateIncomeStatement'))[0] == 't' ? true : false,
      "isPreview": true,
      "isWord": true,
      "tableOfContents": (localStorage.getItem('isTableOfContentSelected'))[0] == 't' ? true : false,
      "watermark": (localStorage.getItem('showWaterMark'))[0] == 't' ? true : false,
      "approvedBy": boardMemberData.length == 0 ? [] : boardMemberData,
      "approvalText": "approved by"
    };
    this.apiservice.getIncomeStatementPreview(this.deficitStatementData).subscribe((responseData) => {
      if (responseData) {
        this.spinner.show();
        var data = JSON.parse(responseData);
        if (data['financialStatementHtml']) {
          this.spinner.show();
          document.getElementById('statement-of-deficit-preview').style.display = "none";
          document.getElementById('statement-of-combined-income-preview').style.display = "block";
          document.getElementById('statement-of-combined-income-preview').innerHTML = data['financialStatementHtml'];
          if (data.watermark != null) { document.getElementById("statement-of-combined-income-preview").style.backgroundImage = "url(" + data.watermark + ")"; }
          this.spinner.hide();
        }
        if (data['statementOfDeficitHtml']) {
          this.spinner.show();
          document.getElementById('statement-of-combined-income-preview').style.display = "none";
          document.getElementById('statement-of-deficit-preview').style.display = "block";
          document.getElementById('statement-of-deficit-preview').innerHTML = data['statementOfDeficitHtml'];
          if (data.watermark != null) { document.getElementById("statement-of-deficit-preview").style.backgroundImage = "url(" + data.watermark + ")"; }
          this.spinner.hide();
        }
      }
    }, (error: any) => {
      this.spinner.hide();
      document.getElementById("balance-sheet-preview").innerHTML = "Error loading PDF"
    });
  }
  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), idleTime);
  }
  getTBLSHeaderNotes() {
    let obj = {
      "engagementsid": this.engagementData.engagementsid,
      "submoduleid": this.submoduleId
    }

    let finalObj = JSON.stringify(obj);
    let data = {
      "inputParameters": finalObj,
      "procedureName": "getwbheadernote"
    }

    this.spinner.show()
    this.apiservice.getData(data).subscribe(response => {
      this.spinner.hide()
      if (response) {
        this.incomeHeaderInfo = JSON.parse(response);
        this.headerNotesColumn1 = this.incomeHeaderInfo[0].header[0].changednameline1;
        this.headerNotesColumn2 = this.incomeHeaderInfo[0].header[0].changednameline2;
        this.headerNotesColumn3 = this.incomeHeaderInfo[0].header[0].changednameline3;
        this.footerNotes = this.incomeHeaderInfo[1].note ? this.incomeHeaderInfo[1].note[0].note : "";
        this.getFSNotes();
      }
      let obj = this.incomeHeaderInfo;// to add missing properties to obj.
      localStorage.removeItem("refreshFinancialStatements-REH");
      this.sharedservice.retainedSarnings.next(obj)
    }, error => {
      this.toaster.error(error.error)
    })
  }
  getRetainedEarnings(isRemoveKey?: boolean) {
    let val;
    // condition for showing always current year data
    if (this.router.url == "/dashboard/ntrdashboard/FinancialStatement/doc/statement-of-retained-earning") {
      val = 1;
    }
    else {
      val = 0;
    }

    this.engagementId = parseInt(localStorage.getItem('engagementID'))
    this.year = parseInt(localStorage.getItem('trailBalYear')) //coming from enagement year Drop down(trail-bal-grid comp)
    this.yearEnd = (localStorage.getItem('endYear'))
    this.engagementName = (localStorage.getItem('engementName'))
    if (!this.year) {
      this.year = parseInt(this.datePipe.transform(this.yearEnd, 'y'))
    }

    if (this.engagementId && this.year) {
      this.spinner.show();
      let obj = {
        "engagementsid": this.engagementID,
        "submoduleid": this.submoduleId,
        "acctyear": parseInt(this.selectedDate ? this.selectedDate : this.datePipe.transform(this.engagementData.yearEnd, 'y')),
        "isfromfs": val
      }

      let finalObj = JSON.stringify(obj);
      let data = {
        "inputParameters": finalObj,
        "procedureName": "getincomestatement"
      }
      this.apiservice.getData(data).subscribe(response => {
        localStorage.removeItem('RetainedEarningStatement');
        if (response) {
          this.balStmtDetails = response;
          this.NotesExists = localStorage.getItem('NotesExists')
          if (this.balStmtDetails == null) {
            this.toaster.warning("No data available for year " + this.year)
          }
          this.retainedEarningsData = JSON.parse(this.balStmtDetails);
          this.retainedearningslinkednotesExists = false;
          this.retainedEarningsData.forEach(element => {
            if (element.notes && element.notes.length > 0) {
              this.retainedearningslinkednotesExists = true;
            }
          });
          this.isHideNotesToFS = this.retainedEarningsData[this.retainedEarningsData.length - 1].isnotesshow == 1 ? true : false
          this.retainedEarningsData.pop();
          var prev = this.retainedEarningsData[0]['Prevgrouptotal'];
          this.currentYearFIS = this.retainedEarningsData[0]['curyear'];
          this.previousYearFIS = this.retainedEarningsData[0]['prevyear'];
          if (prev || prev == 0) {
            this.isIncomePrevYear = true
          }
          else {
            this.isIncomePrevYear = false
          }

          this.spinner.hide();
        }
        else if (response['_statusCode'] == 404) {
          this.toaster.warning("No data available");
        }
        if (isRemoveKey) {
          localStorage.removeItem("refreshFinancialStatements-RE");
          localStorage.removeItem("refreshFinancialStatements-LSG");
        }
        this.spinner.hide();
      }, error => {
        if (isRemoveKey) {
          localStorage.removeItem("refreshFinancialStatements-RE");
          localStorage.removeItem("refreshFinancialStatements-LSG");
        }

      })
    }
    this.getDeficitStatementPreview();
  }
  refresh() {
    this.getRetainedEarnings();
  }
  ngOnDestroy() {
    if (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/statement-of-retained-earning') {
      clearTimeout(this.userActivity);
    }
    this.refreshRetainedEarnings && this.refreshRetainedEarnings.unsubscribe();
    this.refreshIncomeStatement && this.refreshIncomeStatement.unsubscribe();
    this.onLoadDataSub && this.onLoadDataSub.unsubscribe();
    this.rEPreviewSub && this.rEPreviewSub.unsubscribe();
    this.retainedToIncomeSub && this.retainedToIncomeSub.unsubscribe();
    this.showDraftSub && this.showDraftSub.unsubscribe();
    localStorage.removeItem('workbookChangeDate');
    localStorage.removeItem('workbookChangeYearEnd');
    localStorage.removeItem('isLastYear');

    this.fsService.removeFsDocPreviewPage(DocumentPreviewTypes.RETAINED_EARNINGS);
  }

  getFSNotes() {
    let jsonFileName = this.engagementData.engagementsid + '_notes.json';
    const uploadObj = {
      "module": modules[16],
      "firmId": this.sharedservice.getUserData("field", "firmUserAcctId"),
      "clientId": this.engagementData.clientfirmid,
      "engagementId": this.engagementData.engagementsid,
      "status": 0,
      "folderName": jsonFileName,
      "appSyncId": this.sharedservice.getUserData("field", "useracctid"),
      "extension": 'comp',
    }
    // this.spinner.show();
    this.apiservice.getJsonData(uploadObj).subscribe((response: any) => {
      if (response) {
        const notes = !response ? [] : JSON.parse(response);
        this.isHideFooterNotesToFS = notes.length ? true : false;

        this.footerText = this.isHideFooterNotesToFS ? "<div  class='my-custom-footer' style = 'position: absolute; bottom: 0px; left: 0; margin-top: 35px; padding-bottom: 17px; width: 100%; text-align: center;'> <p style = 'font-family: Arial,sans-serif !important; font-size: 12px;'>" + this.footerNotes + "</p></div>" : "";
        let preview = document.getElementById("eng_dynamicHeight").innerHTML;
        document.getElementById("eng_dynamicHeight").innerHTML = preview + this.footerText;

        // this.spinner.hide();
      }
    }, (error) => {
      // this.spinner.hide();
    });
  }

  // water mark on pdf preveiw
  showWaterMark(retainedEarningsData, note?) {
    let previewData: any;
    try {
      previewData = JSON.parse(retainedEarningsData);
    } catch (err) {
      previewData = retainedEarningsData;
    }
    this.retainedEarningData = previewData;
    const setViewData = typeof previewData === 'object' ? previewData["retainedEarnings"] : previewData;
    const view = this.el.nativeElement.querySelector('div');
    this.renderer.setProperty(view, 'innerHTML', setViewData);
    const waterMark = this.sharedservice.showdraft.value;
    const waterMarkUrl = this.sharedservice.waterMarkUrl.getValue();
    if ((waterMark && waterMark.isShowDraft) && waterMarkUrl) {
      this.renderer.setStyle(view, 'background-image', `url(${waterMarkUrl})`);
    } else {
      this.renderer.removeStyle(view, 'background-image');
    }

    if (note) {
      this.updateFooterText(note);
    } else {
      this.getTBLSHeaderNotes();
    }

  }

  updateFooterText(newFooterNote: string) {
    let preview = document.getElementById("eng_dynamicHeight");
    if (preview) {
      let footerElements = preview.getElementsByClassName("my-custom-footer");
      for (let i = 0; i < footerElements.length; i++) {
        footerElements[i].remove();
      }
      let newFooterText = "<div class='my-custom-footer' style = 'position: absolute; bottom: 0px; margin-top: 35px;left: 0; padding-bottom: 17px; width: 100%; text-align: center;'> <p style = 'font-family: Arial,sans-serif !important; font-size: 12px;'>" + newFooterNote + "</p></div>";
      preview.innerHTML += newFooterText;
    }
  }

}
