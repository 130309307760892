import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, signal, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { RoutePath, SubModuleUniqueId } from 'countable@helpers';
import { DialogInputModel } from 'countable@model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ConfirmationDialog } from 'src/app/components/firm/templates-dashboard/dialogs/confirmation-dialog/confirmation-dialog.component';
import { CommonFlexComponent } from 'src/app/components/remote-shared/common-flex/common-flex.component';
import { SharedService } from 'src/app/components/shared/shared.service';
import { BillingModel } from 'src/app/model/billing/billing.model';
import { EnagegementNavigationStartModel, EngagementFilteredListModel } from 'src/app/model/eng/engagements-list.model';
import { FirmModel } from 'src/app/model/firm/firm.model';
import { ApiService } from 'src/app/services/api.service';
import { generateId } from 'src/app/services/app-config';
import { AuthService } from 'src/app/services/auth.service';
import { BillingService } from 'src/app/services/billing.service';
import { EngApiService } from 'src/app/services/eng-api.service';
import { FirmService } from 'src/app/services/firm.service';
import { TimeTrackerService } from 'src/app/services/time-tracker.service';
import { EngService } from '../../../../services/eng.service';
import { IntegrationService } from '../../../../services/integration.service';
import { BlobType } from 'src/app/helpers/engagement.constants';
import { MSOfficeExtensions } from 'src/app/helpers/extensions.const';

@Component({
  selector: 'engagements-list-grid',
  templateUrl: './engagements-list-grid.component.html',
  styleUrls: ['./engagements-list-grid.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class EngagementsListGridComponent implements OnInit, OnDestroy {
  @Input('permissions') permissions: any;
  @Output() clearSearch = new EventEmitter<boolean>();
  sixMonthsFilter: string = '6Months';
  engagementListFilter = [
    {value: this.sixMonthsFilter, viewValue: 'Last 6 Month Engagements'},
    {value: 'Engagements', viewValue: 'All Engagements'},
    {value: 'Assigned', viewValue: 'Assigned to me'},
    {value: 'Created', viewValue: 'Created by me'}
  ];

  displayedColumns = [];

  columnsToDisplay: string[] = this.displayedColumns.slice();
  selection = new SelectionModel(true, []);
  @ViewChild(MatSort) sort: MatSort;
  engagementData: any[];
  sixMonthEngagementData: any[];
  multiEngagementData: any[];

  gridDataEngage: any;
  filterEngagementData: any;
  sixMonthFilterEngagementData: any;
  clientNameForDelete: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('deleteEngagementDialog') deleteEngagementDialog: TemplateRef<any>;
  @ViewChild('deleteMultiEngagementDialog') deleteMultiEngagementDialog: TemplateRef<any>;
  hidePagination: any = true;
  engagementId: any;
  preparerName: string;
  firstReviewerName: string;
  secondReviewerName: string;
  adminName: string;

  engagementsStatusLabels: any;
  completed: any;
  isProgress: any;
  newsts: any;
  loggedInUserData: any;
  auditorId: any;
  EngagementYearDetails: any;
  trialBalYear: any;
  clientEngData: any;
  enagementFilter: string;
  isEngagementListAvailable: boolean = false;
  currentEngCount: number;
  completedEngCount: number;
  sortedEngCount: number;
  engagementDetailsCopy: any;
  openMultiEngDailogSub: Subscription;
  openMultiEngDailogStatus: any;
  isEngListAllSelected: boolean = false;
  userAccessTypeId: any;
  userRoleId: any;
  selectedEngList: any = [];
  archivedEngCount: number;
  isTrackerOn: number;
  onOffSub: Subscription;
  public engagementsNavigationSubscription: Subscription;
  public sixMonthEngagementsNavigationSubscription: Subscription;
  private subscriptions: Subscription = new Subscription();
  billing: BillingModel;
  firm: FirmModel;
  cmsMemeberList = signal<[]>([]);
  checkConnectionSub: any;

  // Deleting files for deleted engagement
  clientFirmId: number;
  firmUserAccountId: number;
  dialogRef: MatDialogRef<ConfirmationDialog>;
  isDefaultSortMode: boolean = false;
  isAscSortMode: boolean = false;
  isDesSortMode: boolean = false;
  selectedSortOptions = {
    engagementName: 'def',
    clientName: 'def',
    engagementtype: 'def',
    yearEnd: 'def',
    dateCreated: 'def'
  };
  disableExportBtn: boolean = false;

  constructor(private sharedService: SharedService, private authService: AuthService, public router: Router, private apiservice: ApiService, private trackerService: TimeTrackerService,
              public dialog: MatDialog, private toastr: ToastrService, private spinner: NgxSpinnerService, private datePipe: DatePipe, private engService: EngService,
              private engApiService: EngApiService, private firmService: FirmService, private billingService: BillingService, private cdr: ChangeDetectorRef,
              private integrationService: IntegrationService) {
    this.subscriptions.add(
      this.firmService.subject.subscribe(firm => {
        if (firm) {
          this.firm = firm;
          this.billingService.subject.subscribe(billing => {
            if (billing) {
              this.billing = billing;
            }
          });
        }
      })
    );
  }

  ngOnInit() {
    if (this.router.url == '/dashboard/members/client-engagements') {
      this.displayedColumns = ['select', 'engagementName', 'engagementtype', 'yearEnd', 'assignedTo', 'status', 'dateCreated', 'actions'];
    } else {
      this.displayedColumns = ['select', 'engagementName', 'clientName', 'engagementtype', 'yearEnd', 'assignedTo', 'status', 'dateCreated', 'actions'];
    }
    this.loggedInUserData = this.authService.getUserDetail();
    if (this.loggedInUserData && this.loggedInUserData.useracctid) {
      this.auditorId = this.loggedInUserData.useracctid;
      this.firmUserAccountId = this.loggedInUserData.firmUserAcctId;
    }
    this.isTrackerOn = +localStorage.getItem('istimetrackingon');//this.sharedService.getUserData("field",
                                                                 // "istimetrackingon");
    //this.onOffSub = this.sharedService.updateTrackerOnOff.subscribe( isOnOff => this.isTrackerOn = isOnOff);
    // this.getAllengagement();
    this.engagementsStatusLbl();
    this.enagementFilter = (this.router.url === '/dashboard/engagement/engagement-list')
      ? this.sixMonthsFilter : 'Engagements';

    this.openMultiEngDailogSub = this.sharedService.openMultiEngDailog.subscribe(res => {
      this.openMultiEngDailogStatus = res;

      if (this.openMultiEngDailogStatus) {
        this.dailogDeleteEngagement();
      }
    });

    this.engagementsNavigationSubscription = this.engService.engNavigationStarted$.subscribe(response => {
      if (response && response.startedPlace !== 'dashboard') {
        if (this.router.url === '/dashboard/engagement/engagement-list') {
          this.engagementData = response.rawEngsList ? response.rawEngsList : [];
          this.filterEngagementData = response.engListData ? response.engListData : [];
        } else if (this.router.url === '/dashboard/members/client-engagements') {
          this.clientEngData = JSON.parse((localStorage.getItem('clientListObj')));
          this.engagementData = response.rawEngsList ? response.rawEngsList.filter(e => e.clientfirmid === this.clientEngData.clientfirmId) : [];
          this.filterEngagementData = response.engListData ? response.engListData.filter(e => e.clientfirmid === this.clientEngData.clientfirmId) : [];
          const engFilteredList = new EngagementFilteredListModel();
          engFilteredList.totalengagement = this.engagementData ? this.engagementData.length : 0;
          engFilteredList.current = this.engagementData.filter(e => (e.statusid === 7 || e.statusid === 9)) ? this.engagementData.filter(e => (e.statusid === 7 || e.statusid === 9)).length : 0;
          engFilteredList.completed = this.engagementData.filter(e => e.statusid === 8) ? this.engagementData.filter(e => e.statusid === 8).length : 0;
          engFilteredList.archived = this.engagementData.filter(e => e.statusid === 19) ? this.engagementData.filter(e => e.statusid === 19).length : 0;
          engFilteredList.totalclient = this.engagementData ? this.engagementData.length : 0;
          this.sharedService.clientEngStatusCount.next(engFilteredList);
          this.enagementFilter = 'Client-Engagements';
        }
        this.isEngagementListAvailable = this.filterEngagementData && this.filterEngagementData.length > 0;
        this.processEngagementFilter(this.enagementFilter);
        this.checkConnection(this.filterEngagementData);
      } else if (response === null && this.router.url === '/dashboard/members/client-engagements') {
        this.getAllengagement();
      }
    });

    this.sixMonthEngagementsNavigationSubscription = this.engService.sixMonthEngNavigationStarted$.subscribe(response => {
      if (response && response.startedPlace !== 'dashboard') {
        if (this.router.url === '/dashboard/engagement/engagement-list') {
          this.sixMonthEngagementData = response.rawEngsList ? response.rawEngsList : [];
          this.sixMonthFilterEngagementData = response.engListData ? response.engListData : [];
        } else if (this.router.url === '/dashboard/members/client-engagements') {
          this.clientEngData = JSON.parse((localStorage.getItem('clientListObj')));
          this.sixMonthEngagementData = response.rawEngsList ? response.rawEngsList.filter(e => e.clientfirmid === this.clientEngData.clientfirmId) : [];
          this.sixMonthFilterEngagementData = response.engListData ? response.engListData.filter(e => e.clientfirmid === this.clientEngData.clientfirmId) : [];
          const engFilteredList = new EngagementFilteredListModel();
          engFilteredList.totalengagement = this.sixMonthEngagementData ? this.sixMonthEngagementData.length : 0;
          engFilteredList.current = this.sixMonthEngagementData.filter(e => (e.statusid === 7 || e.statusid === 9)) ? this.sixMonthEngagementData.filter(e => (e.statusid === 7 || e.statusid === 9)).length : 0;
          engFilteredList.completed = this.sixMonthEngagementData.filter(e => e.statusid === 8) ? this.sixMonthEngagementData.filter(e => e.statusid === 8).length : 0;
          engFilteredList.archived = this.sixMonthEngagementData.filter(e => e.statusid === 19) ? this.sixMonthEngagementData.filter(e => e.statusid === 19).length : 0;
          engFilteredList.totalclient = this.sixMonthEngagementData ? this.sixMonthEngagementData.length : 0;
          this.sharedService.clientEngStatusCount.next(engFilteredList);
        }
        this.isEngagementListAvailable = this.sixMonthFilterEngagementData && this.sixMonthFilterEngagementData.length > 0;
        this.bindGrid(this.sixMonthFilterEngagementData);
        this.checkConnection(this.sixMonthFilterEngagementData);
      } else if (response === null && this.router.url === '/dashboard/engagement/engagement-list') {
        this.getSixMonthEng();
      }
    });


    this.subscriptions.add(
      this.sharedService.loadMapChecklists.pipe(
        delay(2500)
      ).subscribe({
        next: engId => {
          if ((this.router.url === '/dashboard/engagement/engagement-list') && (engId && engId !== 0)) {
            this.loadChecklistFromTemplates(engId);
          }
        }
      })
    );

    this.userAccessTypeId = this.loggedInUserData.useraccesstypeid;
    this.userRoleId = this.loggedInUserData.userroleId;
    this.getTeamMemberDetails();
    this.getEngagement(this.sixMonthsFilter);
  }

  checkConnection(filterEngagementData) {
    if (this.integrationService.checkConnectionNeeded()) {
      let connectedClients = filterEngagementData.filter(eng => {
        if (eng.sourcelinkid > 0 && eng.clientDataSourceId == 1) {
          return eng;
        }
      }).map(eng => eng.clientfirmid);
      let connectedClientsSet = [...new Set(connectedClients)];
      this.checkConnectionSub = this.integrationService.checkConnectionStatus(connectedClientsSet).subscribe({
        next: res => {
          if (res.data) {
            this.engService.engNavigationStarted$.next(null);
            this.engService.sixMonthEngNavigationStarted$.next(null);
          }
        }
      });
    }
  }

  editEngagement(value, type) {
    if (value.statusid == 8 || value.statusid == 19) {
      return;
    }
    this.spinner.show();
    this.engApiService.getEngagementsDetail(value.engagementsid).subscribe(res => {
      this.spinner.hide();
      this.sharedService.selectedClient = value;
      let obj = res;
      console.log(obj, 'Edit Engagement Detals from API');

      if (obj) {
        obj.yearEnd = value.yearEnd; //year end is not present in get Object instead of that financialyearenddate is
        // there
        localStorage.setItem('selectedEngagementObj', JSON.stringify(obj));
      }

      if (type == 'allEngagements') {
        this.router.navigate(['/dashboard/engagement/create-engagement/2']);
      } else if (type == 'clientEngagemente') {
        this.router.navigate(['/dashboard/engagement/create-engagement/2']);
      }
    });

  }

  // Called on clicking delete icon to delete an engagement
  deleteEngagement(value, name) {
    this.clientFirmId = value.clientfirmid;

    if (value.statusid == 8 || value.statusid == 19 || this.permissions.deleteengagement == false) {
      return;
    }
    this.engagementId = value.engagementsid;
    this.clientNameForDelete = name;
    if (this.engagementId) {
      const objectsInEachArray = this.cmsMemeberList().filter((o: any) => value.auditorsData.find(arr => arr.useraccountid == o.useraccountid));
      if (objectsInEachArray.length && (value.statusid === 7 || value.statusid === 17 || value.statusid === 8 || value.statusid === 9)) {
        const data: DialogInputModel = new DialogInputModel();
        data.dialogType = 'delete_eng_CMS';
        data.dialogHeader = '';
        data.data = '';
        const config: MatDialogConfig<any> = new MatDialogConfig<any>();
        config.data = {...data};
        config.disableClose = true;
        config.id = 'delete_eng_CMS';
        const dialogRef = this.dialog.open(CommonFlexComponent, config);
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.confirmDeleteEngagement();
          }
        });
      } else {
        const dialogRef = this.dialog.open(this.deleteEngagementDialog);
        dialogRef.afterClosed().subscribe(result => {
        });
      }
    }
  }

  // Called on confirming delete engagement action in dialog box
  confirmDeleteEngagement() {
    this.dialog.closeAll();
    this.engApiService.deleteEngagement(this.engagementId).subscribe({
      next: (res) => {
        if (res.status === 200) {
          this.engService.refreshAiEngagements$.next(true);
          this.dialogRef = this.dialog.open(ConfirmationDialog, {
            disableClose: false
          });
          this.dialogRef.componentInstance.dialogboxHeader = 'Delete Files?';
          this.dialogRef.componentInstance.dialogboxText = 'There\'s an automated folder associated with this engagement inside the repository.';
          this.dialogRef.componentInstance.dialogboxAdditionalText = 'Do you want to keep the files or delete them?';
          this.dialogRef.componentInstance.confirmAction = 'Delete files';
          this.dialogRef.componentInstance.cancelAction = 'Keep files';
          this.dialogRef.afterClosed().subscribe(result => {
            if (result) {
              // do confirmation actions
              this.confirmDeleteEngagementFiles();
            } else {
              this.dialog.closeAll();
              this.toastr.info('You have chosen to retain the files from the deleted engagement', 'Your files are safe!');
              if (this.enagementFilter === this.sixMonthsFilter) {
                this.getSixMonthEng();
              } else {
                this.getAllengagement();
              }
              this.sharedService.sendNotifySubject.next({'uuid': this.loggedInUserData.useracctid, 'isfirm': 1});
              this.sharedService.refreshClientListCount.next(true);
            }
            this.dialogRef = null;
          });
        } else {
          this.toastr.error('Could not delete the engagement, please contact support');
        }
      }, error: (err) => {
        this.toastr.error('Could not delete the engagement, please contact support');
      }
    });
  }

  // Called on confirming engagement files deletion
  confirmDeleteEngagementFiles() {
    this.dialog.closeAll();

    const data = {
      'engagementsId': this.engagementId,
      'firmUserAccountId': this.firmUserAccountId,
      'clientFirmId': this.clientFirmId
    };

    this.apiservice.deleteEngagementDocumentRepository(data).subscribe(response => {

      if (response) {
        this.toastr.success('The engagement and associated files have been successfully deleted', 'Engagement and Files deleted');
        if (this.enagementFilter === this.sixMonthsFilter) {
          this.getSixMonthEng();
        } else {
          this.getAllengagement();
        }
        this.sharedService.sendNotifySubject.next({'uuid': this.loggedInUserData.useracctid, 'isfirm': 1});
        this.sharedService.refreshClientListCount.next(true);
      }
    }, error => {
    });

  }

  getAllengagement() {
    this.getEngagement('All');
    this.firmService.getEngCount(true).subscribe();
  }

  getSixMonthEng() {
    this.getEngagement(this.sixMonthsFilter);
  }

  getEngagement(filter) {
    this.engApiService.getAllEngagementsList(filter).subscribe(response => {
      if (response) {
        const data = this.engService.getRefinedAllEngagementsList(response, this.router.url);
        if (filter === this.sixMonthsFilter) {
          if ('firmEngagementsList' in data) {
            this.sixMonthEngagementData = data.rawResponse ? data.rawResponse : [];
            this.sixMonthFilterEngagementData = data.firmEngagementsList ? data.firmEngagementsList : [];
            this.filterEngagementData = data.firmEngagementsList ? data.firmEngagementsList : [];
          } else if ('clientEngagementsList' in data) {
            this.clientEngData = JSON.parse((localStorage.getItem('clientListObj')));
            this.sixMonthEngagementData = data.rawResponse ? data.rawResponse : [];
            this.sixMonthFilterEngagementData = data.clientEngagementsList.engagementsList ? data.clientEngagementsList.engagementsList : [];
            this.filterEngagementData = data.clientEngagementsList.engagementsList ? data.clientEngagementsList.engagementsList : [];
            const emitObj = data.clientEngagementsList.filteredListObj ? data.clientEngagementsList.filteredListObj : {};
            this.sharedService.clientEngStatusCount.next(emitObj);
          }
        } else {
          if ('firmEngagementsList' in data) {
            this.engagementData = data.rawResponse ? data.rawResponse : [];
            this.filterEngagementData = data.firmEngagementsList ? data.firmEngagementsList : [];
          } else if ('clientEngagementsList' in data) {
            this.clientEngData = JSON.parse((localStorage.getItem('clientListObj')));
            this.engagementData = data.rawResponse ? data.rawResponse : [];
            this.filterEngagementData = data.clientEngagementsList.engagementsList ? data.clientEngagementsList.engagementsList : [];
            const emitObj = data.clientEngagementsList.filteredListObj ? data.clientEngagementsList.filteredListObj : {};
            this.sharedService.clientEngStatusCount.next(emitObj);
          }
        }

        const emitData = new EnagegementNavigationStartModel();
        this.router.url;
        emitData.startedPlace = 'fromEngList';
        emitData.routerUrl = '/dashboard/engagement/engagement-list';
        emitData.engagemenId = null;
        emitData.userLoginId = +this.auditorId;
        if (filter === this.sixMonthsFilter) {
          emitData.engListData = this.sixMonthFilterEngagementData;
          emitData.rawEngsList = [...this.sixMonthEngagementData];
          this.engService.sixMonthEngNavigationStarted$.next(emitData);
          this.isEngagementListAvailable = this.sixMonthFilterEngagementData && this.sixMonthFilterEngagementData.length > 0;
        } else {
          emitData.engListData = this.filterEngagementData;
          emitData.rawEngsList = [...this.engagementData];
          this.engService.engNavigationStarted$.next(emitData);
          this.isEngagementListAvailable = this.filterEngagementData && this.filterEngagementData.length > 0;
        }
      }
    });
  }

  bindGrid(list) {
    this.gridDataEngage = new MatTableDataSource(list);
    this.sharedService.gridDataEngage = this.gridDataEngage;
    try {
      this.gridDataEngage.filterPredicate =
        (data: any, search: string) => {
          let auditoeList = JSON.parse(data['auditor_list']);
          var modifidA;
          if (auditoeList) {
            var selectedobj = auditoeList.map(element => {
              return {
                'emailaddress': element.emailaddress,
                'rolename': element.rolename,
                'fullname': element.fullname,
                'cellno': element.cellno
              };
            });
            modifidA = JSON.stringify(selectedobj);
          }

          if (modifidA) {
            modifidA = modifidA.toLowerCase();
          } else {
            modifidA = [];
          }
          return (data['clientName'].toLowerCase().includes(search) ||
            data['engagementName'].toLowerCase().includes(search) ||
            data['engagementtype'].toLowerCase().includes(search) ||
            data['yearEnd'].toString().toLowerCase().includes(search) || //newly added key
            data['dateCreated'].toString().toLowerCase().includes(search) || //newly added key
            data['emailaddress'].toLowerCase().includes(search) ||  //newly added key
            data['cellphonenumber'].toLowerCase().includes(search) ||  //newly added key
            data['status'].toLowerCase().includes(search) ||
            modifidA.includes(search)
          );
        };
    } catch (e) {
      console.log(e);
    }
    //this.gridDataEngage.sort = this.sort;
    this.gridDataEngage.paginator = this.paginator;
    if (this.enagementFilter === this.sixMonthsFilter) {
      this.hidePagination = this.sixMonthFilterEngagementData && this.sixMonthFilterEngagementData.length > 10 ? false : true;
    } else {
      this.hidePagination = this.filterEngagementData && this.filterEngagementData.length > 10 ? false : true;
    }
  }

  getShortName(name) {
    let shortName = '';
    if (name) {
      let matches = name.match(/\b(\w)/g);
      let acronym = matches.join('');
      if (acronym && acronym.length > 2) {
        acronym = acronym.slice(0, 2);
      }
      shortName = acronym;
    }
    return shortName;
  }

  getRoleCount(rowindex) {
    let roleNumberArray = ['', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eigth', 'Ninth', 'Tenth'];
    if (rowindex <= 10) {
      return roleNumberArray[rowindex];
    } else {
      return rowindex;
    }
  }

  engagementsStatusLbl() {
    this.apiservice.getEngagementStatus().subscribe(data => {
      this.engagementsStatusLabels = data;
      this.completed = this.engagementsStatusLabels[1].statusname;
      this.isProgress = this.engagementsStatusLabels[0].statusname;
      this.newsts = this.engagementsStatusLabels[2].statusname;
      this.engagementsStatusLabels.forEach(item => {
        item.IsSelected = false;
      });

    }, error => {
    });

  }

  //Engagements status filter
  engagementsStatusFilter(e, selected) {

    this.engagementsStatusLabels.forEach(status => {
      if (status.statusname === selected) {
        status.IsSelected = e;
      }
    });
    let engagementsStatusFilterArray = [];
    let tempStatus = this.engagementsStatusLabels;
    tempStatus.forEach(item => {
      if (item.statusname === selected) {
        if (e) {
          item.IsSelected = true;
        } else {
          item.IsSelected = false;
        }
      }
    });
    let selectnone = [];
    selectnone = tempStatus.filter(temp => {
      return temp.IsSelected;
    });
    if (selectnone.length > 0) {
      tempStatus.forEach(status => {
        let data = (this.enagementFilter === 'Engagements') ? this.filterEngagementData : this.sixMonthFilterEngagementData;
        data.forEach(item => {
          if (item.status === status.statusname && status.IsSelected) {
            engagementsStatusFilterArray.push(item);
          }
        });
      });
    } else {
      engagementsStatusFilterArray = (this.enagementFilter === 'Engagements') ? this.filterEngagementData : this.sixMonthFilterEngagementData;
    }
    this.cdr.detectChanges();
    this.bindGrid(engagementsStatusFilterArray);
  }

  //engagements status filter end
  redirectToTrialBal(engagement) {
    //console.log("data="+ JSON.stringify(engagement));
    this.spinner.show();
    this.apiservice.getEngagementYearDetails(engagement.engagementsid).subscribe(response => {
      if (response) {
        this.EngagementYearDetails = response;
        if (this.EngagementYearDetails && this.EngagementYearDetails.length > 0) {
          this.trialBalYear = this.EngagementYearDetails[0].acctyear;
          localStorage.setItem('trailBalYear', this.trialBalYear);
          localStorage.setItem('engagementperiodYearId', this.EngagementYearDetails[0].periodYearId);
        }
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
    this.engApiService.getEngagementsDetail(engagement.engagementsid).subscribe(res => {
      res = this.engService.updateFirmEngagement(res);
      this.sharedService.engagementId = res.engagementsid;
      this.sharedService.engagementDetails = res;
      this.sharedService.headerObj.next(res);
      localStorage.setItem('incorporationDate', res.incorporationdate);
      localStorage.setItem('engagementID', res.engagementsid);
      localStorage.setItem('engagementClientID', res.clientfirmid);
      localStorage.setItem('selectedEngagementObj', JSON.stringify(res));
      localStorage.setItem('prevYear', JSON.stringify(res.prvYear));
      localStorage.setItem('selectedEngagementObjCopy', JSON.stringify(res));
      localStorage.setItem('endYear', res.yearEnd); //both are same
      setTimeout(() => {
        this.trackerService.timeIconCount().subscribe(count => {
          this.sharedService.updateTimerCount.next(count);
          this.sharedService.isTimeTrackerStart.next('startTimer');
        });
        let routeUrl: string;
        if (res.pengagementtypeid === 1) {
          routeUrl = RoutePath.CLIENT_ACCEPTANCE;
        } else if (res.pengagementtypeid === 34) {
          routeUrl = RoutePath.ENGAGEMENT_LETTER;
        } else {
          routeUrl = RoutePath.REVIEW_CHECKLISTS;
        }
        this.spinner.hide();
        res.pengagementtypeid === 2 && this.engService.navigateToReviewEngagement$.next(SubModuleUniqueId.PLANNING_REVIEW);
        this.router.navigate([routeUrl]);
      }, 50);
    }, error => {
      this.spinner.hide();
      this.toastr.error(error.error);
    });
  }

  engagementFilter(event) {
    this.clearSearch.emit(true);
    this.processEngagementFilter(event.value);
  }

  processEngagementFilter(id) {
    var data = this.getFilterEngagementDate(id);
    if (data) {
      this.bindGrid(data);
    }
  }

  getFilterEngagementDate(id): any {
    this.enagementFilter = id;
    var create = 1;
    var assign = 2;
    var createNassign = 3;
    if (id == 'Created') {
      if (this.filterEngagementData && this.filterEngagementData.length > 0) {
        var engagementFilterData = this.filterEngagementData.filter(x => (x.isowned == create || x.isowned == createNassign));
        this.spinner.hide();
        this.engagementsStatusLabels.forEach(item => {
          item.IsSelected = false;
        });
        return engagementFilterData;
      } else {
        return [];
      }
    } else if (id == 'Assigned') {
      if (this.filterEngagementData && this.filterEngagementData.length > 0) {
        var engagementFilterData = this.filterEngagementData.filter(x => (x.isowned == assign || x.isowned == createNassign));
        this.spinner.hide();
        this.engagementsStatusLabels.forEach(item => {
          item.IsSelected = false;
        });
        return engagementFilterData;
      } else {
        return [];
      }
    } else if (id == this.sixMonthsFilter || id == 'Engagements') {
      this.getEngagementsData(id);
    } else if (id === 'Client-Engagements') {
      return JSON.parse(JSON.stringify(this.filterEngagementData));
    } else {
      if (this.filterEngagementData && this.filterEngagementData.length > 0) {
        this.spinner.hide();
        return this.filterEngagementData;
      } else {
        this.spinner.show();
        this.getAllengagement();
      }
    }
  }

  /* Select all engagements in Client engagements */
  isSelectedAll(mainData) {
    let multiEngagementSelected = [];
    if (mainData.checked) {
      mainData.map(x => {
        if (x.checked != true) {
          x.checked = true;
          multiEngagementSelected.push({
            'engagementid': x.engagementsid,
            'loginid': this.loggedInUserData.useracctid,
            'isdelete': 1,
            'statusid': x.statusid
          });
        } else {
          x.checked = true;
          multiEngagementSelected.push({
            'engagementid': x.engagementsid,
            'loginid': this.loggedInUserData.useracctid,
            'isdelete': 1,
            'statusid': x.statusid
          });
        }
      });
      this.multiEngagementData = multiEngagementSelected;
      this.sharedService.selectedEngObjSub.next(this.multiEngagementData);

    } else {
      this.multiEngagementData = [];
      this.sharedService.getArchivedCompletedEngs.next({'checkBoxStatus': true, 'type': 'multiple'});
      this.sharedService.selectedEngObjSub.next(this.multiEngagementData);
      mainData.map(x => {
        if (x.checked == true) {
          x.checked = false;
        } else {
          x.checked = false;
        }
      });
    }
  }

  /* Select individual engagements in Client engagements */
  selectedItemsData(mainData, element?) {
    if (mainData) {
      let multiEngagementSelected = [];
      var count = 0;
      /* Selected List sending to Client Engagement info page */
      let isShowDialog: boolean = false;
      let isAvailableIndex = this.selectedEngList.findIndex(x => x.engagementid == element.engagementsid && (x.completedId == element.statusid || x.archivedId == element.statusid));
      if (isAvailableIndex == -1 && element.checked) {
        if (element.statusid == 19 || element.statusid == 8) {
          isShowDialog = true;
        }
        this.selectedEngList.push({
          'engagementid': element.engagementsid,
          'loginid': this.loggedInUserData.useracctid,
          'isdelete': 1,
          'statusid': element.statusid,
          'completedId': element.statusid == 8 ? element.statusid : '',
          'archivedId': element.statusid == 19 ? element.statusid : ''
        });
      } else {
        isShowDialog = false;
        this.selectedEngList.splice(isAvailableIndex, 1);
      }
      /*End  */
      mainData.map(x => {
        if (x.checked) {
          count = count + 1;
          multiEngagementSelected.push({
            'engagementid': x.engagementsid,
            'loginid': this.loggedInUserData.useracctid,
            'isdelete': 1,
            'statusid': x.statusid
          });
        }
      });
      if (this.gridDataEngage.data.length == count) {
        mainData.checked = true;
      } else {
        mainData.checked = false;
      }
      this.multiEngagementData = multiEngagementSelected;
      this.sharedService.getArchivedCompletedEngs.next({'checkBoxStatus': isShowDialog, 'type': 'single'});
      this.sharedService.selectedEngObjSub.next(this.multiEngagementData);
    }
  }

  /* Confirmation Dailog of Multi Eng in Client engagement view */
  dailogDeleteEngagement() {
    const dialogRef = this.dialog.open(this.deleteMultiEngagementDialog);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /* Delete Multiple Engagements in Client engagement view */
  confirmMultiDeleteEngagement() {
    const dialogRef = this.dialog.closeAll();
    let inputString = {
      'deleteeng': this.multiEngagementData
    };
    const inputObject = JSON.stringify(inputString);
    const object = {
      'procedureName': 'deleteengfromclientinfo',// Delete Multiple engagements SP
      'inputParameters': inputObject
    };
    this.spinner.show();
    this.apiservice.saveData(object).subscribe(response => {
      this.toastr.success('Selected engagement deleted successfully');
      if (this.enagementFilter === this.sixMonthsFilter) {
        this.getSixMonthEng();
      } else {
        this.getAllengagement();
      }
      this.multiEngagementData = [];
      this.sharedService.selectedEngObjSub.next(this.multiEngagementData);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error('error in deleting');
    });

  }

  //
  trackByEngList(index: number, item) {
    return index; // or item.id
  }

  sortData(sort: Sort) {

    let sortingData = this.gridDataEngage.data;
    const data = (sortingData).slice();
    if (!sort.active || sort.direction === '') {
      this.gridDataEngage.data = data;
      return;
    }
    sortingData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'clientName':
          return this.compare(a.clientName.toLowerCase(), b.clientName.toLowerCase(), isAsc);
        case 'dateCreated':
          return this.compareDate(a.dateCreated, b.dateCreated, isAsc);
        case 'engagementName':
          return this.compare(a.engagementName, b.engagementName, isAsc);
        case 'yearEnd':
          return this.compareDate(a.yearEnd, b.yearEnd, isAsc);
        default:
          return 0;
      }
    });
    this.bindGrid(sortingData);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? 1 : -1) * (isAsc ? 1 : -1);
  }

  compareDate(a: string, b: string, isAsc: boolean) {
    const date1 = new Date(a);
    const date2 = new Date(b);
    return (date1.getTime() < date2.getTime() ? 1 : -1) * (isAsc ? 1 : -1);
  }

  loadChecklistFromTemplates(id: number) {
    const Obj = {
      'engagementsid': id
    };
    this.spinner.show();
    this.engApiService.mapChecklists(Obj).subscribe({
      next: resp => {
      }
    }).add(() => {
      this.spinner.hide();
      this.sharedService.loadMapChecklists.next(0);
    });
  }

  ngOnDestroy() {
    this.openMultiEngDailogSub.unsubscribe();
    //this.onOffSub ? this.onOffSub.unsubscribe():'';
    this.sharedService.loadMapChecklists.next(0);
    this.subscriptions && this.subscriptions.unsubscribe();
    if (this.engagementsNavigationSubscription) {
      this.engagementsNavigationSubscription.unsubscribe();
    }
    if (this.sixMonthEngagementsNavigationSubscription) {
      this.sixMonthEngagementsNavigationSubscription.unsubscribe();
    }
    if (this.checkConnectionSub) {
      this.checkConnectionSub.unsubscribe();
    }
  }

  //get team member details
  getTeamMemberDetails() {
    var getobj = {
      'useraccountid': this.loggedInUserData.useracctid
    };
    const Obj = {
      'procedureName': 'getassignteam_new',
      'inputParameters': JSON.stringify(getobj),
      'inputEnc': generateId[Math.floor(Math.random() * generateId.length)]
    };

    this.apiservice.getData(Obj).subscribe({
      next: resp => {
        if (resp && JSON.parse(resp)?.preparerlist) {
          this.cmsMemeberList.set(JSON.parse(resp)?.preparerlist.filter(e => (e.userroleid === 11)));
        }
      },
      error: error => {
        this.toastr.error(error.error);
      }
    });
  }

  clearFilters() {
    this.engagementsStatusLabels.forEach(status => {
      status.IsSelected = false;
    });
    this.cdr.detectChanges();
    this.bindGrid(this.getFilterEngagementDate(this.enagementFilter));
  }

  getEngagementsData(filter) {
    this.spinner.show();
    this.engApiService.getAllEngagementsList(filter).subscribe(response => {
      if (response) {
        const data = this.engService.getRefinedAllEngagementsList(response, this.router.url);
        const engagementData = data.firmEngagementsList ? data.firmEngagementsList : [];
        this.filterEngagementData = data.firmEngagementsList ? data.firmEngagementsList : [];
        this.isEngagementListAvailable = engagementData && engagementData.length > 0;
        this.bindGrid(engagementData);
        this.firmService.getEngCount(true).subscribe();
        this.engagementsStatusLabels.forEach(item => {
          item.IsSelected = false;
        });
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
    });
  }

  exportEngagementList() {
    this.spinner.show();
    this.disableExportBtn = true;
    let payload: any;
    if (this.gridDataEngage.filter) {
      payload = this.gridDataEngage?.filteredData;
    } else {
      payload = this.gridDataEngage?.data;
    }
    const engagementIds = payload.map((engagement: any) => engagement.engagementsid);
    this.engApiService.exportEngagementList(engagementIds).subscribe((response: any) => {
      let data = response;
      if (data.data && data.status === 200) {
        this.disableExportBtn = false;
        let sliceSize = 512;
        let byteCharacters = atob(data.data);
        let byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          let slice = byteCharacters.slice(offset, offset + sliceSize);
          let byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          let byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        // Set the content type for Excel files
        let contentType = BlobType.EXCEL;
        let blob = new Blob(byteArrays, {type: contentType});
        let a = document.createElement('a');
        document.body.appendChild(a);
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        // Get the client name for the export file
        const engagementListExport  = 'Engagements';
        const currentDate = new Date().toISOString().slice(0, 10);
        const fileExtension = MSOfficeExtensions.XLSX;
        const cleanFileName = `${engagementListExport}_${currentDate}.${fileExtension}`;
        // Download as .xlsx file
        a.download = cleanFileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        this.spinner.hide();
      } else {
        if (data?.errors) {
          this.toastr.error(data?.errors[0]);
        }
        this.disableExportBtn = false;
        this.spinner.hide();
      }
    }, (error) => {
      this.disableExportBtn = false;
      this.toastr.error(error.errors);
      this.spinner.hide();
    });
  }

  customSortData(columnName: string, sortOrder: string) {
    this.isDefaultSortMode = sortOrder === 'def';
    this.isAscSortMode = sortOrder === 'asc';
    this.isDesSortMode = sortOrder === 'dec';
    // Set selected sort option for the current column and reset others
    Object.keys(this.selectedSortOptions).forEach(col => {
      if (col !== columnName) {
        this.selectedSortOptions[col] = 'def'; // Reset other columns to default
      }
    });
    // Set selected sort option
    this.selectedSortOptions[columnName] = sortOrder;
    // Perform sorting on a copy of the data
    const sortedData = this.gridDataEngage.data.sort((a, b) => {
      let valA = a[columnName];
      let valB = b[columnName];
      // Check if the column is 'yearEnd' to perform date comparison
      if (columnName === 'yearEnd') {
        valA = new Date(valA);  // Convert string to date
        valB = new Date(valB);  // Convert string to date
      }

      if (sortOrder === 'asc') {
        return valA > valB ? 1 : valA < valB ? -1 : 0;
      } else if (sortOrder === 'dec') {
        return valA < valB ? 1 : valA > valB ? -1 : 0;
      }
    });

    // Bind the sorted data using your bindGrid method
    this.bindGrid(sortedData);
  }

}
