import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mapFilter'
})
export class MapFilterPipe implements PipeTransform {
    transform(items: any[], value: string): any {
        const args = arguments;
        const isExpandSub = args[2];
        if (isExpandSub && value) {
            if(value.length > 1){
                isExpandSub.next(true);
            }  
            else{
                isExpandSub.next(false);
            }   
        }
        if (!items || !value) {
            return items;
        }
       
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => item.description.toLowerCase().indexOf(value.toLowerCase()) !== -1 || item.mapno.indexOf(value) !== -1);
        /* let maps = items.filter(e => e.subgroup.filter( x => {
            x.maping.filter(item => {
              return item.description.toLowerCase().indexOf(value.toLowerCase()) !== -1 || item.mapno.indexOf(value) !== -1;
                 
                e['isExpand'] = true;
                x['isExpand'] = true;
                return item;
              }
              else{
                e['isExpand'] = false;
                x['isExpand'] = false;
                //return item;
              } 
            })
          }))
          
          return maps; */
    }
}
