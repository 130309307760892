import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MyErrorStateMatcher } from '../../helpers/error-state-matcher';
import { CurrentPasswordValidationModel } from '../../model/pasword/current-password-validation.model';
import { PasswordValidationModel } from '../../model/pasword/password-validation.model';
import { AuthService } from '../../services/auth.service';
import { CaptchaService } from '../../services/captcha.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy, AfterViewInit {

  currentPasswordEntity = new CurrentPasswordValidationModel();
  newPasswordEntity = new PasswordValidationModel();
  showPwdSuccessful: boolean = false;
  resetForm: UntypedFormGroup;
  isSetPasswordEnabled: boolean = false;
  matcher = new MyErrorStateMatcher();

  get newPassword() {
    return this.resetForm.get('newPassword');
  }

  constructor(private authService: AuthService, private fb: UntypedFormBuilder,
              private spinner: NgxSpinnerService, private toastr: ToastrService, private captchaService: CaptchaService) {
  }

  ngAfterViewInit(): void {
    this.captchaService.showCaptchaBanner();
  }

  ngOnDestroy() {
    this.captchaService.hideCaptchaBanner();
  }

  ngOnInit() {
    this.resetForm = this.fb.group({
      currentPassword: new UntypedFormControl('', [Validators.required]),
      newPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(25)]),
      confirmPassword: new UntypedFormControl('', [Validators.required, Validators.maxLength(25)])
    }, {validator: this.checkPasswords});
  }

  checkPasswords(group: UntypedFormGroup) {
    let pass = group.controls.newPassword.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : {notSame: true};
  }

  checkCurrentPassword(event) {
    this.currentPasswordEntity.validate(event.target.value);
    this.enableSubmitButton();
  }

  checkNewPassword(event) {
    this.newPasswordEntity.validate(event.target.value);
    this.enableSubmitButton();
  }

  public changePassword() {
    if (!this.resetForm.valid) {
      return;
    }

    this.spinner.show().then(() => {
      this.captchaService.getCaptchaToken('change_password').then((token) => {
        const req = {
          oldPassword: this.resetForm.value.currentPassword,
          newPassword: this.resetForm.value.newPassword,
          captcha: token
        };

        this.authService.changePassword(req).subscribe(res => {
          this.spinner.hide().then();
          if (res.status === 200) {
            this.newPasswordEntity.isValid = false;
            this.showPwdSuccessful = true;
            setTimeout(() => {
              this.authService.logout();
            }, 5000);
          }
        }, error => {
          this.spinner.hide().then();
          this.toastr.error(error.error.description);
        });
      });
    });
  }

  enableSubmitButton() {
    this.isSetPasswordEnabled = (this.resetForm.valid && this.currentPasswordEntity.isValid && this.newPasswordEntity.isValid);
  }
}
