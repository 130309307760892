import { Injectable, Optional, SkipSelf } from '@angular/core';
import { environment } from '../../environments/environment';

declare var document: any;

@Injectable({
  providedIn: 'root'
})
export class LoadScriptService {

  public static ADOBE_PDF_VIEWER = 'adobe_pdf_viewer';
  public static GOOGLE_ADDRESS = 'google_address';
  public static GOOGLE_CAPTCHA = 'google_captcha';
  public static GOOGLE_SSO = 'google_sso';

  private scripts: Map<string, any> = new Map([
    [LoadScriptService.ADOBE_PDF_VIEWER, {
      src: 'https://documentservices.adobe.com/view-sdk/viewer.js',
      loaded: false,
      isLoading: false
    }],
    [LoadScriptService.GOOGLE_ADDRESS, {
      src: 'https://maps.googleapis.com/maps/api/js?libraries=places&key=' + environment.GOOGLE_MAP_API_KEY,
      loaded: false,
      isLoading: false
    }],
    [LoadScriptService.GOOGLE_CAPTCHA, {
      src: 'https://www.google.com/recaptcha/api.js?render=' + environment.CAPTCHA_SITE_KEY,
      loaded: false,
      isLoading: false
    }],
    [LoadScriptService.GOOGLE_SSO, {
      src: 'https://accounts.google.com/gsi/client',
      loaded: false,
      isLoading: false
    }]
  ]);

  constructor(@Optional() @SkipSelf() anotherLoadScriptService: LoadScriptService) {
    if (anotherLoadScriptService) {
      throw new Error('LoadScriptService is singleton, please use that instead of instantiating another one!');
    }
  }

  async loadScript(key: string) {
    return new Promise((resolve, reject) => {
      if (this.scripts.get(key).isLoading) {
        // resolve after 3 seconds if it is being loaded
        new Promise(resolve => setTimeout(resolve, 3000)).then(() => {
          resolve({script: key, loaded: true, status: 'Already Loaded'});
        });
      } else if (this.scripts.get(key).loaded) {
        // resolve if already loaded
        resolve({script: key, loaded: true, status: 'Already Loaded'});
      } else {
        this.scripts.get(key).isLoading = true;
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts.get(key).src;
        if (script.readyState) {  // IE
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              this.scripts.get(key).loaded = true;
              this.scripts.get(key).isLoading = false;
              resolve({script: key, loaded: true, status: 'Loaded'});
            }
          };
        } else {  // Others
          script.onload = () => {
            this.scripts.get(key).loaded = true;
            this.scripts.get(key).isLoading = false;
            resolve({script: key, loaded: true, status: 'Loaded'});
          };
        }
        script.onerror = (error: any) => resolve({script: key, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('body')[0].appendChild(script);
      }
    });
  }
}
