import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class StockholderService {
    stockholderData : any;

    setStockholderData(data : any) {
        this.stockholderData = JSON.parse(JSON.stringify(data));
    }

    getStockholderData() {
        return this.stockholderData;
    }
}