export const environment = {
  env: 'qa-01',
  apiV1: 'https://qa1-api.adinovis.com/api-adinovis/v1',
  apiV2: 'https://qa1-api.adinovis.com/api-adinovis/v2',
  api: 'https://qa1-api.adinovis.com/api-adinovis',
  region: 'us-east-2',
  ai: 'https://qa1-ai.adinovis.com/luca/v1/',
  ADOBE_EMBED_PDF_KEY: '53276eb1c12b4109951de62b4b834769',
  CAPTCHA_SITE_KEY: '6LcvKMokAAAAAPmcK-_NiLxQahU_WnHNCMFNDxNa',
  GOOGLE_MAP_API_KEY: 'AIzaSyAYgmbvlJf1ar3AWraqrZaMicVs9cRcYbE',
  EMAIL_REG_EXP: /^\w+([%+_.-]?\w+)*@\w+([_.-]?\w+)*(\.\w{2,})+$/,
  GOOGLE_CLIENT_ID: '868776752656-j3qhj3k5smp2pq4ddm58rij80f4asitu.apps.googleusercontent.com',
  STRIPE_PK: 'pk_test_51PzkDCEvSvoKePgmxYGv5Pdo03QvhedlmQ89G8BTKdrOVP69yoWNpHEisCTDqQz9mP4fs4kluTRfvUWkqUaGnaVz00lb3zo1Vc'
};
