import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExportModel, ExportPDFModel, FSExportModel, StatementPreviewModel } from 'countable@model';
import { saveAs } from 'file-saver';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { EncodingService } from '../services/encoding.service';

const textResponse: any = {responseType: 'text'};

@Injectable({
  providedIn: 'root'
})

export class AdobeExportService {

  private readonly PATH = environment.apiV1;
  private readonly EXPORT_TRIAL_BALANCE = this.PATH + '/tb/export/pdf';
  private readonly EXPORT_ADJUSTMENT_ENTRIES = this.PATH + '/adjustment-entries/export/pdf';
  private readonly EXPORT_TRIAL_BALANCE_GROUP = this.PATH + '/export/grouping/pdf';
  private readonly EXPORT_TB_CHECK: string = this.PATH + '/tb/export/check/pdf';
  private readonly EXPORT_LETTER = this.PATH + '/export/engagement/letter';
  private readonly FS_PREVIEW_WORKBOOK: string = this.PATH + '/get-workbook-preview';
  private readonly FS_STATEMENT_PREVIEW: string = this.PATH + '/get-preview-document';
  private readonly FS_PACKAGE: string = this.PATH + '/generate-pdf';
  private readonly MANAGEMENT_LETTER = this.PATH + '/export/management/letter';

  constructor(private httpClient: HttpClient, private datePipe: DatePipe, private encodingService: EncodingService) {}

  public exportTrailBalance(Obj: ExportPDFModel, fileName: string): Observable<boolean> {
    return this.httpClient.post(this.EXPORT_TRIAL_BALANCE, Obj, textResponse).pipe(
      map(response => this.decodeToBlobFile(response, fileName))
    );
  }

  public exportAdjustmentEntries(Obj: ExportModel, fileName: string): Observable<boolean> {
    return this.httpClient.post(this.EXPORT_ADJUSTMENT_ENTRIES, Obj, textResponse).pipe(
      map(response => this.decodeToBlobFile(response, fileName))
    );
  }

  public exportTrailBalanceGrouping(Obj: ExportPDFModel, fileName: string): Observable<boolean> {
    return this.httpClient.post(this.EXPORT_TRIAL_BALANCE_GROUP, Obj, textResponse).pipe(
      map(response => this.decodeToBlobFile(response, fileName))
    );
  }

  public exportTBCheck(Obj: ExportModel, fileName: string): Observable<boolean> {
    return this.httpClient.post(this.EXPORT_TB_CHECK, Obj, textResponse).pipe(
      map(response => this.decodeToBlobFile(response, fileName))
    );
  }

  public getYearFromString(dateString: string): number {
    const parsedDate = this.datePipe.transform(dateString, 'MMM dd, yyyy');
    return parsedDate ? new Date(parsedDate).getFullYear() : null;
  }

  public exportEngagementLetter(data): Observable<any> {
    return this.httpClient.post(this.EXPORT_LETTER, this.encodingService.enData(data), {responseType: 'text'}).pipe(
      map(resp => {
        const byteCharacters = atob(resp);
        const byteArrays: any[] = [];
        const sliceSize: 512 = 512;
        for (let offSet = 0; offSet < byteCharacters.length; offSet += sliceSize) {
          const slice = byteCharacters.slice(offSet, offSet + sliceSize);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, {type: 'application/pdf'});
      })
    );
  }

  public getWorkbookFSPreview(Obj: FSExportModel): Observable<any> {
    return this.httpClient.post(this.FS_PREVIEW_WORKBOOK, Obj, textResponse);
  }

  public getFSDocPreview(Obj: StatementPreviewModel): Observable<any> {
    return this.httpClient.post(this.FS_STATEMENT_PREVIEW, Obj, textResponse);
  }

  public getFSPackage(Obj): Observable<any> {
    return this.httpClient.post(this.FS_PACKAGE, Obj, textResponse);
  }

  public exportManagementLetter(Obj: any): Observable<any> {
    return this.httpClient.post(this.MANAGEMENT_LETTER, Obj, {responseType: 'text'}).pipe(
      map(resp => {
        const byteCharacters = atob(resp);
        const byteArrays: any[] = [];
        const sliceSize: 512 = 512;
        for (let offSet = 0; offSet < byteCharacters.length; offSet += sliceSize) {
          const slice = byteCharacters.slice(offSet, offSet + sliceSize);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, {type: 'application/pdf'});
      })
    );
  }

  private decodeToBlobFile(resp, fileName: string): boolean {
    const byteCharacters = atob(resp);
    const byteArrays: any[] = [];
    const sliceSize: 512 = 512;
    for (let offSet = 0; offSet < byteCharacters.length; offSet += sliceSize) {
      const slice = byteCharacters.slice(offSet, offSet + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {type: 'application/pdf'});
    saveAs(blob, fileName);
    return true;
  }
}
