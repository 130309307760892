export class UserDetailModel {
  address: string;
  addressDelete: boolean;
  addressId: string;
  auditroleid: number;
  businessName: string; //'Firm19'
  businessphoneDelete: boolean;
  businessphoneId: number;
  businessphoneNumber: string;
  businessphonetype: string;
  cellphoneId: number;
  cellphonedelete: boolean;
  cellphonenumber: string;
  cellphonetype: string; //'cell phone'
  city: string;
  clientfirmid: number;
  country: string;
  countryorigin: string; // 'Canada'
  designationname: string; // 'staff'
  emailAddress: string; //'mohamade+firm19@countable.co'
  firmBusinessPhoneNo: string; // '-';
  firmCellPhoneNo: string; // '(647) 888-0605';
  firmClientId: number; // 0;
  firmEmailAddress: string; //null;
  firmName: string; // 'Firm19';
  firmUserAcctId: number; //7369;
  firmcity: string; // null;
  firmcountry: string; // null;
  firmdesignationid: number; //null;
  firmpartnerid: number; // 0;
  firmprocessstatus: number; // 1;
  firmstaffid: number; // 0;
  firmstate: string; //null;
  firstname: string; // 'Firm';
  fullName: string; //'Firm Nineteen';
  hourlyrate: number; //0;
  isbillingenabled: boolean; //0;
  isfirstsuperadmin: boolean; // 0;
  ispegpacklicenseid: boolean; // 0;
  isstripesubscribed: number; // 2;
  istemplateenabled: boolean; //0;
  istimetrackingon: boolean; // 1;
  isupgradeplanredirect: boolean; // 0;
  lastname: string; //'Nineteen';
  licenseno: string; //'-';
  loginpassword: string; //'{bcrypt}$2a$10$nlia3L5yFXaRwdVgx9MNuujMHnJiAGsc1kGvY06uRVhfcFIMIItn6';
  memberid: string; //'';
  origin_country_id: number = null; //38;
  partnerName: string; //;
  partneruseraccountid: number; // null;
  postCode: string; //;
  profilepicture: string; //null;
  roleName: string; //'Firm Super Admin';
  s3BucketName: string; //'client-docs-qa-01';
  state: string; //null;
  status: number; //'3';
  title: string; //null;
  titleid: string; //null;
  tokenvalue: string; //null;
  toshowtermpolicy: boolean; //0;
  userDelete: boolean; //0;
  useraccessname: string; //'Super Admin';
  useraccesstypeid: number; //2;
  useracctid: number; //7369;
  userroleId: number; // 10;
  userroledelete: boolean; //0;
  vfirstsuperadminemail: string; // 'mohamade+firm19@countable.co';
  pegexpirydate: string;
  is_multi_firm_enabled: number;
}
