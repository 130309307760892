export class AIInputModel {
  imgPath: string;
  type: 'SEND' | 'VOICE_START' | 'CHAT_STOP' | 'NONE' | 'VOICE_PROGRESS';
  chatType: 'VOICE' | 'INPUT';
  isDisabled: boolean = false;
}

export const VOICE_INPUT: AIInputModel = {
  imgPath: '/assets/images/ui-uplift-icons/luca/voice-mic.svg',
  type: 'VOICE_START',
  chatType: 'VOICE',
  isDisabled: false
};

export const VOICE_PROGRESS: AIInputModel = {
  imgPath: '/assets/images/ui-uplift-icons/luca/stop-chat.png',
  type: 'VOICE_PROGRESS',
  chatType: 'VOICE',
  isDisabled: false
};

export const VOICE_SEND: AIInputModel = {
  imgPath: '/assets/images/ui-uplift-icons/luca/send-icon-white.svg',
  type: 'SEND',
  chatType: 'VOICE',
  isDisabled: false
};

export const VOICE_SEND_INPUT: AIInputModel = {
  imgPath: '/assets/images/ui-uplift-icons/luca/send-icon-white.svg',
  type: 'SEND',
  chatType: 'INPUT',
  isDisabled: false
};

export class ResponseActionModel {
  actionType: 'DOWNLOAD' | 'SAVE' | 'NONE';
  index: number;
  actionProgress: 'PROGRESS' | 'COMPLETED';
}
