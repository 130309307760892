export enum STORAGE_KEYS {
  FIRM_ENGAGEMENT_DETAILS = '',
  FIRM_ENGAGEMENT_DETAILS_COPY = '',
  CLIENT_ENGAGEMENT_DETAILS = 'selectedClientEngagementObj',
  USER_DETAILS = 'userDetails',
  ENGAGEMENT_ID = 'engagementID',
  ENGAGEMENT_DETAILS = 'selectedEngagementObj',
}

export class StorageHelper {
  static getItem(key: string) {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  static setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static setStringItem(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  static removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
