import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { STORE_KEYS } from 'countable@helpers';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EncodingService } from '../services/encoding.service';

const textResponse: any = { responseType: 'text' };
@Injectable({
  providedIn: 'root'
})
export class EngagementStore {
  private dataMap = new Map<STORE_KEYS, any>();
  private dataStore: Map<string, BehaviorSubject<any>> = new Map<string, BehaviorSubject<any>>();
  public checklistData$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor(private httpClient: HttpClient, private encodingService: EncodingService) { }

  setData(input: STORE_KEYS, data: any) {
    if (!this.dataStore.has(input)) {
      this.dataStore.set(input, new BehaviorSubject<any>(null));
    }
    this.dataStore.get(input)?.next(data);
    this.dataMap.set(input, data);
  }

  getData(input: STORE_KEYS): Observable<any> {
    if (!this.dataStore.has(input)) {
      this.dataStore.set(input, new BehaviorSubject<any>(null));
    }
    return this.dataStore.get(input)?.asObservable();
  }

  hasData(input: STORE_KEYS): boolean {
    return this.dataMap.has(input) && (this.dataMap.get(input) !== null);
  }

  getStoreKeyValue(input: STORE_KEYS) {
    return this.dataStore.get(input)?.getValue();
  }


  getCheckListData(subModuleId: number, userAccId: number, MasterChecklistId?: number) {
    let Master_checklist_id = MasterChecklistId ? MasterChecklistId : null;
    let inputString = {
      "submoduleid": subModuleId,
      "loginid": userAccId,
      "Master_checklist_id": Master_checklist_id
    }

    let data = {
      "procedureName": "get_checklist_objective",
      "inputParameters": JSON.stringify(inputString)
    }
    this.checklistData$.next(undefined);
    const path = environment.apiV1 + '/get-procedure-json-data';
    this.getHeaderData(path, data).pipe(
      tap(res => {
        if (res && JSON.parse(res)?.length) {
          let headerData = JSON.parse(res)[0].objective;
          headerData = headerData.trimLeft();
          headerData = headerData.trim();
          // if(headerData!=null){
          //   headerData=headerData.replace(/[\n\r]/g, '');
          // }
          this.checklistData$.next(headerData);
        } else {
          this.checklistData$.next(undefined);
        }
      })
    ).subscribe();
  }


  getHeaderData(path, data): Observable<any> {
    return this.httpClient.post(path, this.encodingService.enData(data), textResponse)
  }
}
