import { ApiService } from '../services/api.service';
import { AsyncValidatorFn, AsyncValidator, NG_ASYNC_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { Directive } from '@angular/core';
import { map, catchError } from 'rxjs/operators';

export function uniqueIdValidator(apiService: ApiService): AsyncValidatorFn {
    return (c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        if(!c.value){
            return of(null);
        }
        let useraccountId,loggedInUserData,teamEditData,teamId;
        loggedInUserData = JSON.parse((localStorage.getItem('userDetails')));
        if (localStorage.getItem("teamMemberData")) {
            teamEditData = JSON.parse((localStorage.getItem('teamMemberData')));
        }
        if (localStorage.getItem("settingsUserAccessTeamMemberData")) {
            teamEditData = JSON.parse((localStorage.getItem('settingsUserAccessTeamMemberData')));
        }

        if(teamEditData){
            teamId = teamEditData.id;
        }
        if (loggedInUserData && loggedInUserData.useracctid) {
            useraccountId = loggedInUserData.useracctid;
        }
        const inputData = {
            "validateid":
                [{
                    "id": c.value,
                    "validatestatus": 0,
                    "loginid": useraccountId
                }]
        };
        const inputString = JSON.stringify(inputData);
        let getData = {
            "procedureName": "validateuserid",
            "inputParameters": inputString
        };
        let debounceTime = 500; //milliseconds
        return timer(debounceTime).pipe(() => {
            if(teamId == c.value){
                return of(null);
            }
            else{
                return apiService.getData(getData).pipe(
                    map(res => {
                        let result: any = [];
                        result = JSON.parse(res);
                        return result[0].validatestatus === 1 ? { 'uniqueId': true } : null;
                    }),
                    catchError(() => of(null))
                );
            }
        });
    };
}

@Directive({
    selector: '[uniqueId][formControlName],[uniqueId][formControl],[uniqueId][ngModel]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: UniqueIdValidatorDirective, multi: true }]
})
export class UniqueIdValidatorDirective implements AsyncValidator {
    constructor(public apiService: ApiService) { }
    validate(c: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
        return uniqueIdValidator(this.apiService)(c);
    }
}
