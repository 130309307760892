import { EL_KEYS, LETTER_TYPES, LETTER_VIEW_TYPES } from 'countable@helpers';
import { ClientSignatureModel } from './client-eng-letter.model';

export class AutomatedElements {
  date :string = 'Date';
  entityName :string = 'Entity Name';
  addManagement :string  = 'Addresse - Management';
  addBoDir: string = 'Addresse - Board of directors';
  addShare :string = 'Addresse - Shareholders';
  yearEndCurr :string = 'Year end date (CY)';
  yearEndPrev :string = 'Year end date (PY)';
  note :string = 'Note X';
  clientCity :string = 'Client City';
  cityState :string = 'City,State'; //us
  cityProvince :string = 'City, Province';//ca
  addComplete :string = 'Complete address';
  postalCode :string = 'Postal Code';
  firstName :string = 'First Name';
  lastName :string = 'Last Name';
  engId :string = 'Engagement ID';
  balanceSheet :string = 'Balance Sheet';
  sire: string = 'Statement of income and Retained Earnings';
  sc: string = 'Statement of cashflow';
  scse: string = 'Statement of changes in stockholders equity';
  firmName: string = 'Firm Name';
  firmSignature: string = 'Firm Sign here';
  clientSignature: string = 'Client Signature';
  clientSignedDate: string = 'Client Signed Date';
  firmAddress: string = 'Firm Address';
  partnerName: string = 'Partner Name';
  streetAddress: string = 'Street Address';
}

//Model File for Navigating from List -> Preview -> Edit
export class LetterViewModel {
  engagementtemplateid :number = 0;
  engleftsidedocid :number = 0;
  fileName :string = ''; //fileNameWithoutExt
  orginalFileName :string = ''; //originalfilename
  s3FilePath :string = '';
  isResendEnableAfterEdit:boolean = false;
  isDialogShow: 'yes' | 'no' = 'no';
  isFrom:  LETTER_VIEW_TYPES = 'Client-Onboarding';
  isSharedToClient: boolean = false;
}


export class RegExpModel {
  date : RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Date<\/span>/gi;
  entityName :RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Entity Name<\/span>/gi;
  addManagement :RegExp  = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Addresse - Management<\/span>/gi;
  addBoDir: RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Addresse - Board of directors<\/span>/gi;
  addShare :RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Addresse - Shareholders<\/span>/gi;
  yearEndCurr :RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Year end date \(CY\)<\/span>/gi;
  yearEndPrev :RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Year end date \(PY\)<\/span>/gi;
  note :RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Note X<\/span>/gi;
  clientCity :RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Client City<\/span>/gi;
  cityState :RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">City,State<\/span>/gi;    //us
  cityProvince :RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">City, Province<\/span>/gi; //ca
  addComplete :RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Complete address<\/span>/gi;
  balanceSheet :RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Balance Sheet<\/span>/gi;
  postalCode :RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Postal Code<\/span>/gi;
  firstName :RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">First Name<\/span>/gi;
  lastName :RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Last Name<\/span>/gi;
  scse : RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Statement of changes in stockholders equity<\/span>/gi; //us
  sc :RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Statement of cashflow<\/span>/gi; //us
  sire : RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Statement of income and Retained Earnings<\/span>/gi;
  firmName: RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Firm Name<\/span>/gi;
  firmSignature: RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Firm Signature<\/span>/gi;
  clientSignature: RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Client Signature<\/span>/gi;
  clientSignedDate: RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Client Signed Date<\/span>/gi;
  firmAddress: RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Firm address<\/span>/gi;
  partnerName: RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Partner Name<\/span>/gi;
  streetAddress: RegExp = /<span class="auto-elements is-locked" style="background-color: #DDF0DF;">Street Address<\/span>/gi;
}

export class ImageLetterModel {
  s3FilePath: string | null = '';
  s3ImagePath : string | null = '';
  imageId : number = 0;
  imageType : 'header' | 'footer' | 'sign';
  isSignedDate: any;
  signatureText?: string;
  signatureType?: 'draw' | 'upload' | 'text';
  alignmentvalue?: 'Left' | 'Center' | 'Right' | null;
  is_stretch_fit?: number;
  picture_alignment?: string;
  resizeJsonFilePath?: string;
}


export class saveLetterModel {
  headerImageObj : ImageLetterModel = new ImageLetterModel();
  footerImageObj : ImageLetterModel = new ImageLetterModel();
  signatureObj: ImageLetterModel = new ImageLetterModel();
  coverLetter : any[] = [];
  content : any[] = [];

}

export class LetterStatusModel {
  createdDate:  string = '';
  signedDate: string = '';
  signatureData: ImageLetterModel = new ImageLetterModel();
  isLetterSigned: boolean = false;
  isReadyToSend :boolean = false;
  isLetterSend :boolean = false;
  isReadyToResend :boolean = false;
  isLetterResend :boolean = false;
  isSendToClient: 0 | 1 | 2 = 0;  // notusinganymore
  sendTimeStamp?:string = '';
  ClientSignatureDetails: ClientSignatureModel | null = new ClientSignatureModel();
  isclientaccepted: 0 | 1 = 0;
  clientaccepteddate: string = '';
  isClientSignEnable:boolean = false;
  isClientAcceptedSigned :boolean = false;
  isRemoveHide:boolean = false; //to hide remove anchor tag when sent to client
  isResendEnableAfterEdit: boolean = false;
  isPDFSignedOff: boolean = false;
  hasSharedToClient: boolean = false;
}

export class GetStatusModel {
  engagementtemplateid: number = 0;
  engagementsid: number;
  useracctid: number = 0;
  engleftsidedocid: number = 0;
}


export class setStatusModel extends GetStatusModel {
  SignatureImageId :number = 0;
  isdelete :number = 0;
  issent :number = 0;
  isresent :number  =0;
  clientaccepteddate: any;
  isremoval: 0 | 1 = 0;
  isclientaccepted?: 0 | 1 = 0;
}

export class SendMailModel {
  email: string = '';
  firmName: string = '';
  letterName: string  = '';
  isResend: boolean = false;
}

export class ElementsInputObj {
  engagementId: number;
  clientFirmId: number;
  loginId:number;
  firmHash: string;
}

export class CanadaAutomatedElements {
  entityName :string;
  yearEndCurr :string;
  yearEndPrev :string;
  clientCity :string ;
  cityProvince :string ;
  addComplete :string;
  postalCode :string;
  firstName :string;
  lastName :string;
}

export class UsAutomatedElements {
  entityName :string;
  yearEndCurr :string;
  yearEndPrev :string ;
  clientCity :string ;
  cityState :string ;
  addComplete :string ;
  postalCode :string;
  firstName :string;
  lastName :string;
}

export class CompletionLetterModel {
  fileName: string;
  jsonFilePath: string;
  subModuleUUID: string;
  isFrom: LETTER_TYPES = 'None';
  subModuleId: number;
  isResendEnableAfterEdit:boolean = false;
  isDialogShow: 'yes' | 'no' = 'no';
  isLetterMappedFromTemplateSaved: boolean = false;
  isSharedToClient: boolean = false;
}

export class ManagementLetterModel {
  fileName: string;
  jsonFilePath: string;
  subModuleUUID: string;
  isFrom: LETTER_TYPES = 'None';
  subModuleId: number;
  isResendEnableAfterEdit: boolean = false;
  isDialogShow: 'yes' | 'no' = 'no';
  isLetterMappedFromTemplateSaved: boolean = false;
  isSharedToClient: boolean = false;
  jsonFileName: string;
  sectionTemplateDetails: TemplateMappedSectionDetails;
}

export class TemplateMappedSectionDetails {
  jsonFileName: string;
  jsonFilePath: string;
  masterTemplateId: number;
  firmTemplateId: number;
  isCopiedToEngagement: boolean = true;
  templateName: string;
  subModuleId: number;
  subModuleUUID: string;
}

export class ExportLetterModel {
  engtemplateId: number = 0;
  headerImage: string = '';
  footerImage: string = '';
  firmSignature: string = '';
  clientSignature: string = '';
  firmSignatureType: string = '';
  clientSignatureType: string = '';
  isClientSignature: boolean = false;
  exportContent: Array<any> = [];
  clientSignatureText: string = null;
  isHeaderStretch: boolean = false;
  headerAlignment: string = 'center';
  isFooterStretch: boolean = false;
  footerAlignment: string = 'center';
  isPdf: boolean;
  headerId?: string;
  footerId?: string;
  firmId?: string;
  footerName?: string;
  headerName?: string;
}

export class ML {
  engagementId: number;
  subModuleId: number;
  engagementTemplateId: number;
}

export class AddManagementLetterModel {
  firmTemplateId: number;
  templateName: string = '';
  engagementId: number;
  loginId: number;
  jsonFileName: string;
  jsonFilePath: string;
  subModuleId: number;
  masterTemplateId: number = 0;
  // sourceFileName: string;
  // sourceFilePath: string;
}

export class ManagementLetter {
  clientAcceptedDate: string;
  clientSignatureId: number;
  engLeftSideDocId: number;
  engagementTemplateId: number;
  firmTemplateId: number;
  isClientAccepted: number;
  isResendToClient: boolean;
  isSendToClient: boolean;
  jsonFileName: string;
  jsonFilePath: string;
  masterTemplateId: number;
  resentDate: string;
  subModuleId: number;
  templateName: string;
  sendToClientDate: string;
  clientSignedDate: string;
  isFirmSigned: boolean = false;
  firmSignatureDetails: FirmSignatureModel | null = null;
}

export class S3MlInputObj {
  readonly status: number = 0;
  readonly foldername: string = 'Management Letter';
  filenames: string[];
  destinationFileNames: string[];
  firmId: number;
  appSyncId: number;
}

export class ShareToClientModel extends ML {
  readonly shareWithClient = 1;
  readonly isSendToClient: 1 = 1;
}

export class ReShareToClientModel extends ML {
  readonly isResent = 1;
}


export class ApprovalMailModel {
  engagementName: string;
  firmName: string;
  email: string;
  letterName: string;
}

export class ELValidationModel {
  validationKey: EL_KEYS = null;
  imagePath: string = '';
  isValid: boolean = false;
  keyId: 0 | 1 | 2 | 3;
  validationContent: string;
}

export const ElValidList: Array<ELValidationModel> = [
  {
    validationKey: 'Firm Signature',
    keyId: 0,
    imagePath: '/assets/images/ui-uplift-icons/global/cross-red-circle.svg',
    isValid: false,
    validationContent: 'Firm Signature as an element'
  },
  {
    validationKey: 'Client Signature',
    keyId: 1,
    imagePath: '/assets/images/ui-uplift-icons/global/cross-red-circle.svg',
    isValid: false,
    validationContent: 'Client Signature as an element'
  },
  {
    validationKey: 'Firm Signed',
    keyId: 2,
    imagePath: '/assets/images/ui-uplift-icons/global/cross-red-circle.svg',
    isValid: false,
    validationContent: 'Firm should sign the letter'
  },
  {
    validationKey: 'Client Portal',
    keyId: 3,
    imagePath: '/assets/images/ui-uplift-icons/global/check-green.svg',
    isValid: true,
    validationContent: 'Client Portal Activation'
  }
]

export const ImageClasses = {
  stretchFit: 'image-align-Stretch',
  stretchFitHeader: 'image-align-Stretch-header',
  stretchFitFooter: 'image-align-Stretch-footer',
  center: 'justify-content-center',
  left: 'justify-content-start',
  right: 'justify-content-end',
};

export class NavigateReportModel {
  reportId: string;
  reportFolderId: string;
  selectedMoveType: 'New' | 'Exist';
  isNavigate: boolean = false;
}

export class FirmSignatureModel {
  firmSignedDate: string;
  firmSignatureId: number;
  firmSignatureType: 'draw' | 'text' | 'upload';
  firmSignatureText: string;
  firmSignatureS3Path: string;
}

export class EngLetterStatusInputs {
  engagementTemplateId: number;
  engagementId: number;
  engLeftSideDocId: number;
}

export class compArchiveModel {
  engagementId: number;
  statusId: number;
  statusName: string;
  completionType: string;
  reopenReason: string;
  completedArchiveId: number;
  daysToComplete: number;
}
