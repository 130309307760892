// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newlineEnter {
  rotate: 22 -5 0 207deg;
}

.activeBtnFormat {
  background: #E1EEFA !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/remote-shared/countable-rename/countable-rename.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;AACJ;;AAEA;EACI,8BAAA;AACJ","sourcesContent":[".newlineEnter{\n    rotate: 22 -5 0 207deg;\n}\n\n.activeBtnFormat{\n    background: #E1EEFA !important;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
