import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
    name: 'currentDatePipe',
  })


export class CurrentDatePipe extends DatePipe implements PipeTransform {
    override transform(value: any, args?: any): any {
      return super.transform(value, 'dd/MM/yyyy');
    }
  }
  