import { Component, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html'
})
export class FilePreviewComponent implements OnInit {

  @Output() public fileType: string;
  @Output() public isInline: boolean;
  @Output() public viewMode: string;
  @Output() public hash: string;
  public isReadOnly: boolean;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.fileType = params.get('file-type');
      this.hash = params.get('hash');
      this.isInline = params.get('is-inline') && params.get('is-inline') === 'true';
      this.viewMode = params.get('view-mode') === 'view' ? 'view' : 'edit';
      this.isReadOnly = params.get('isReadOnly') && params.get('isReadOnly') === 'true';
    });
  }

  isOfficeFile() {
    return this.fileType === 'ms';
  }

  isGeneralFile() {
    return this.fileType === 'o';
  }

  isPdfFile() {
    return this.fileType === 'pdf';
  }
}
