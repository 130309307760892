import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'commentFilter'
})

export class CommentFilterPipe implements PipeTransform{

    transform(comments: any[], searchTerm: string){
        // Arguments sent from HTML.
        const args = arguments;
        const filterredCountSubj = args[2];
        let isToggleComments = args[3];
        if(!comments || !searchTerm){
            return comments;
        }
        if(searchTerm.length < 3){
            
            if(isToggleComments){
                filterredCountSubj.next(comments.length);
            }
            else{
                if (filterredCountSubj) {
                    filterredCountSubj.next(comments.length);
                }
            }
            return comments;
        }
        else{
            const comm = comments.filter(comment => comment.reftitle.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 );
            // Get the "filterredCountSubj" from arguments. 
            if (filterredCountSubj) {
                filterredCountSubj.next(comm.length);
            }
            return comm;
        }
        
    }
}