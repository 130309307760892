import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'summary'
})
export class SummaryPipe implements PipeTransform {

  transform(value: any, limit?: any): any {
    if(value.length < 25){
        return value;
    }
    if(value.length >= 25){
      let actualLimit = (limit) ? limit : 55;
      let shorten = value.substr(0, actualLimit);
      return shorten+'...';
    }
  }

}
