import { Injectable } from '@angular/core';
import { ACTIVE_PAGE_MAP, FolderTypes, FS_STATEMENTS_PREVIEWS, STATEMENT_VIEW_TYPES, WORKBOOK_ACTIVE_PAGE_MAP } from 'countable@helpers';
import { ESLeadSheetModel, RoundingModel } from 'countable@model';
import { AdobeExportService, DialogService, EngagementStore } from 'countable@services';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ChecklistActionModel } from 'src/app/model/eng/checklist.model';
import { ApiService } from 'src/app/services/api.service';
import { BillingService } from 'src/app/services/billing.service';
import { FirmService } from 'src/app/services/firm.service';


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  loginForms = false;
  isReadOnly: boolean = false;
  isEngScopeProcedureModified = false;
  isFirmTemplatesNotesModified = false;
  isFirmTemplatesNotesEditoropened = false;
  public cancelUnsavedChanges = new Subject<boolean>()
  public selectedClient: any = {};
  public selectedPartner: any = {};
  public routeObj = new BehaviorSubject<any>({});
  public routeData = this.routeObj.asObservable();
  public headerObj = new BehaviorSubject<any>({});
  public headerDetails = this.headerObj.asObservable();
  public profileObj = new BehaviorSubject<any>({});
  public profileDetails = this.profileObj.asObservable();
  public selectedEnagagementObj = new BehaviorSubject<any>({});
  public selectedEngagementDetails = this.selectedEnagagementObj.asObservable();
  public duplicateTemplateToFirm = new BehaviorSubject<Object>({});
  public gridData: any;
  public gridDataEngage: any;
  public gridTemplate: any;
  public selectedClientId: any;
  public filterEngagements: any;
  public selectedPartnerId: any;
  public teamFilterRole: string;
  public filterClients: any;
  public engagementId: any;
  public isProcedureEditor= false;
  public refreshClientListCount = new Subject<boolean>();
  public engagemantListCount = new Subject<string>();
  public clientStausCount = new Subject<string>();
  public clientSearchString = new Subject<string>();
  public leadsheetId = new Subject<any>();
  public leadsheetTrialBalance = new Subject<any>();
  public questionsData = new Subject<any>();
  public menuData = new Subject<any>();
  public showTrialBalance = new Subject<any>();
  public showImportTrialBalance = new Subject<any>();
  public isDocReference = new Subject<any>();
  public isProceduresRefreshed = new Subject<any>();
  public isTrialBalLoaded = new Subject<any>();
  public isAdjustmentEntrySave = new BehaviorSubject<any>("");
  public adjustmentEntrysaved = this.isAdjustmentEntrySave.asObservable();
  public trialBalanceAdjEntry: Subject<{ engId: any, tbData: any[] }> = new Subject<{ engId: number, tbData: any[] }>();
  public lastUpdateDate = new Subject<any>();
  public clientToken: any;
  public isBalSheetUpdate = new Subject<any>(); //old approach
  public isStockholderEquityUpdate = new Subject<any>();
  public isBalSheetHeaderUpdate = new Subject<any>(); //old approach
  public isBalSheetFooterUpdate = new Subject<any>();
  public isIncomeStatementFooterUpdate = new Subject<any>();
  public isFsNotesHeaderUpdate = new Subject<any>();
  public isIncomeStmtUpdate = new Subject<any>();
  public isCommentsActive = new Subject<any>();
  public isCompletionCommentsActive = new Subject<any>();
  public isCompletionDocRequestActive = new Subject<any>();
  public issIssuesActive = new Subject<any>();
  public isIssuesActive = new Subject<any>();
  public isCalling = new Subject<any>();
  public refreshMiddleSection = new Subject<any>();
  public signofType = new Subject<any>();
  public isSignOff = new Subject<any>();
  public isSignOffPanelExpanded = new Subject<any>();
  public clientAcceptanceDocDetails = new Subject<any>();
  public partnersCapitalView = new Subject<any>();
  public leftSideMenuNameChange = new Subject<any>();
  //public tbwsToggleDrawerStatus = new Subject<any>();
  public isEnableSettings = new Subject<any>();
  public setPCViewType = new Subject<any>();
  public refreshPreview = new Subject<any>();
  public closePreview = new Subject<any>();
  public partnerCleintListRightPanelStatus = new Subject<any>();
  public refreshWindow = new Subject<any>();
  public moduleId = new Subject<any>();
  public clientEngStatusCount = new Subject<any>();
  public openClientRepository = new Subject<any>();
  public multiEngOfClient = new Subject<any>();
  public openMultiEngDailog = new Subject<any>();
  public selectedEngObjSub = new Subject<any>();
  public newRequestDropDownInput = new Subject<any>();
  public refreshDocsDropdown = new Subject<any>();
  public updateUnreadMessages = new Subject<any>();
  public deleteDocReference = new Subject<any>();
  public staffRightPanelHideAndShow = new Subject<any>();
  public isMovingLeadsheet = new Subject<any>();
  public isBulkMap = new Subject<any>();
  public unAssignMapNumber = new Subject<any>();
  public engagementDetails: any;
  public engagementName: any;
  public yearEnd: any;
  public trailBalYear: any;
  public balStatementEngagementName: any;
  public balStmtYearEnd: any;
  public tbslectedYear: any;
  public clientEngDetails: any;
  public termsPrivacyShow = new Subject<any>();
  public onNotifications = new Subject<any>();
  public sortingPosition = new Subject<any>();
  public refreshShortCutButtons = new Subject<any>();
  public IncomestatementPreviewheaderInfo = new Subject<any>(); //old approach
  public StockholderPreviewheaderInfo = new Subject<any>();
  public TSselectedYear = new Subject<any>(); //old approach
  public retainedSarnings = new Subject<any>();
  public hideMoveButton = new Subject<boolean>();
  public refreshFsMenu = new Subject<boolean>();
  public reloadRetainEarningsPreview = new Subject<any>();
  public reloadLeftsideSignoffData = new Subject<any>();
  public availableDocsIds = new Subject<any>();
  public availableDocsIdsShared = new Subject<any>();
  public ntrDashboardIssigned = new Subject<any>();
  public engLetterTemplate = new BehaviorSubject<any>("");
  public engagementLetter = new Subject<any>();
  public updateFInancialSettings = new Subject<any>();
  public isCompletionIssues = new Subject<any>();
  public isCompletionComment = new Subject<any>();
  public isCompletionDocRequest = new Subject<any>();
  public dragMiddleLeft = new Subject<any>();
  public fsSettingsChanged = new Subject<any>();
  public dragRightMiddle = new Subject<any>();
  public refreshBlIS = new Subject<any>();
  public refreshSCInStock = new Subject<any>();
  public documentRequestObject = new Subject<any>();
  public docReqFromMiddleSection = new Subject<any>();
  public docReferencedSuccessfully = new Subject<any>();
  public retainedToIncome = new Subject<any>(); //old approach
  public balanceSheetRefresh = new Subject<any>(); //old approach
  public incomeSheetRefresh = new Subject<any>(); //old approach
  public isUserSettingsChangePassword = new Subject<any>();
  public teamAccessMemberData = new Subject<any>();
  public cashFlowRefresh = new Subject<any>();
  printCoverPage = false;
  printTableOfContent = false;
  printCompilationReport = false;
  printBalanceSheet = false;
  printIncomeStatement = false;
  printStatementStockholdersEquity = false;
  printRetaainedEarnings = false;
  printNotesToFS = false;
  isAuthenticationTokenExpired = new Subject<any>();
  clientObjForRiposotory = new Subject<any>();
  public isDocFSnewFolderChanges = new Subject<any>();
  public documenthubRequestCount = new Subject<number>();
  public notifyCountSubject = new Subject<any>();
  public notifyOverdueCountSubject = new Subject<any>();
  public notifyRemindercountSubject = new Subject<any>();
  public intialLoginNotifySubject = new Subject<any>();
  public notifyAlertSubject = new Subject<any>();
  public sendNotifySubject = new Subject<any>();
  public updateTrackerOnOff = new Subject<number>();
  public notifySubject = new Subject<Boolean>();
  public showdraft = new BehaviorSubject<any>(null);
  public refreshCompilationReport = new Subject<any>();
  public engAssignedSub = new Subject<any>();
  public getS3JsonData = new Subject<any>();
  public getArchivedCompletedEngs = new Subject<any>();
  public refreshWorkbookmenu = new Subject<boolean>();
  public redirectToarchiveNow = new Subject<boolean>();
  public refreshSubData = new Subject<boolean>();
  public accessPermissionsSubject = new Subject<any>();
  public notificationRedirectionSubject = new Subject<object>();
  public refreshPEGPACTSubscription = new Subject<boolean>();
  public isTimeTrackerStart = new Subject<string>();
  public updateTimerCount = new BehaviorSubject<number>(0);
  public refreshRecordBtn = new Subject<boolean>();
  // public tbSubject = new ReplaySubject<any>(1);
  public tbSubject = new BehaviorSubject<any>({});
  public templatesSubject = new BehaviorSubject<any>("");
  public componentHeaderSave = new Subject<any>();
  public componentFooterSave = new Subject<any>();
  public changeHeaderSelection = new Subject<any>();
  public changeFooterSelection = new Subject<any>();
  public refreshHeaderFooter = new Subject<any>();
  public componentSignatureSave = new Subject<any>();
  public clientTemplateData = new Subject<any>();
  public frimTemplateStyle = new BehaviorSubject<any>(false);
  public subModuleId = new BehaviorSubject<object>(null);
  public frimSubModuleId = new BehaviorSubject<object>(null);
  public selectedNote = new BehaviorSubject<object>(null);
  public refreshNotesToTemplates = new Subject<any>()
  public updateNoteNameInLeftSide = new Subject<any>()
  public reloadCompilationReport = new Subject<boolean>()
  public compilationReportEdited = new Subject<boolean>()
  public openNotesInEditMode = new Subject<any>();
  // Report templates
  public selectedReport = new BehaviorSubject<object>(null);
  public refreshReportsFirm = new BehaviorSubject<any>(null);
  public refreshFirmReports = new BehaviorSubject<any>(null);
  public getCountOfSelectedMasterReports = new BehaviorSubject<any>({});
  public showSpinnerTrailBalance: Subject<string> = new Subject<string>();
  public spinnerArrayReference = ["adjstApiCall", "csvData", "gifiApiCall", "leadApiCall", "leadCodeApiCall", "mapApiCall", "mapStatus"]
  public saveCompletionArchiveApplyNow : BehaviorSubject<any> = new BehaviorSubject<any>('default');
  josnData;
  products$: Observable<any>;
  loggedInUserData: any;
  public masterTemplatesData: any;
  public masterCheckListData: any;
  public frimtemplatesSubject = new Subject<any>();
  public isExpandSubject = new Subject<any>();
  public completionSubModuleId = new BehaviorSubject<object>(null);
  public callCompletion = new Subject<any>();
  public refreshMiddleSec = new Subject<any>();
  public refreshRightPanel = new Subject<boolean>();
  public refreshCompRef = new Subject<any>();
  public refreshWithdrawalRef = new Subject<any>();
  public refreshNotesToFsPreview = new Subject<any>()
  public letterBtnDisabled = new Subject<any>();
  public isMasterCheckList = new Subject<boolean>();
  public onloadChecklist = new Subject<boolean>();
  userLoginId: any;
  public compilationimgwidth = new BehaviorSubject<string>(null);
  // notification disable
  public notificationdbl = new BehaviorSubject<any>(null);
  // FS settings refresh
  public showAppByBoard = new BehaviorSubject<any>(null); //old approach
  public blPageBreak = new BehaviorSubject<any>(null);  //old approach
  public DisableTBbtns = new Subject<boolean>();
  //Index for Dragged File From Document Repository
  public refreshAllleftsidedocs: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public allLeftSideDocs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public teamDocId: number | null = null;
  public allLeftSideTeamDocId: Array<any> | null = [];
  public dragFileOrigin: 'Right' | 'Left' | null = null;
  public fileName: string | null = null;
  public mandatorymfa = new BehaviorSubject<boolean>(false);
  public navigateToReview$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public navigateToWorksheetMateriality$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public navigateToWorksheetProcedure$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public selectedTab$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public navigateToEngagementScope$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public isMasterEngagement: Subject<any> = new Subject<boolean>();
  public selectedNotesCount$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notesTrialBalanceData$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public notesLeadSheetData$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public navigateToCompilationWorksheet$ : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public fsaLeadSheet$: BehaviorSubject<ESLeadSheetModel[]> = new BehaviorSubject<ESLeadSheetModel[]>(null);
  public worksheetProcedures$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public clientEngList$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public leftSideDocMoved: Subject<boolean> = new Subject<boolean>();
  public firmAutoNoteIds$ : BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  BlanceSheetDataFromWorkBook=[];
  public isReportEdit: boolean = false;
  public identifyReportEditedtrue = new Subject<string>();
  public filePathForMateriality$ :BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public hasSourceConnection$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refreshLeftFolder$: BehaviorSubject<FolderTypes> = new BehaviorSubject<FolderTypes>(null);
  public engUserAccess: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  //for eng-letter pdf signedoff
  public isClientSignedOffId: number = 0;
  public docRepoRefresh : BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public platformDocRepoRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  //checklists
  public refreshChecklist$: BehaviorSubject<ChecklistActionModel> = new BehaviorSubject<ChecklistActionModel>(null);
  public checklistModuleId$: Subject<ChecklistActionModel> = new Subject<ChecklistActionModel>();
  public navigateToSAE: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public loadMapChecklists: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public updateFinStatement$ : BehaviorSubject<Map<FS_STATEMENTS_PREVIEWS,boolean>> = new BehaviorSubject<Map<FS_STATEMENTS_PREVIEWS,boolean>>(undefined);
  private fsRefreshMap :  Map<FS_STATEMENTS_PREVIEWS,boolean> = new Map<FS_STATEMENTS_PREVIEWS,boolean>;
  public waterMarkUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public materialitycy: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public updateWorkBookPreview: Subject<any> = new Subject<any>();
  public disableAIMRows = new Subject<any>();
  public disableDraginAIM = new Subject<any>();
  public letterscount:BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public engcount:BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public selectedmasterid:BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public rftemplateEngId: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public rfunCorrectedMisstatementAmount : BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public updateEngTbYears: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // send final package
  public updateClientFinalPackage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public selectedClientFinalPackage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public validatingTbData: BehaviorSubject<any> = new BehaviorSubject<any>(null); // checking whether the current year eng Trial balance data loaded or not
  public proceduresMappedData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public updateReOrderAndReNumber$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // this will use when the reorder and renumber updates then if current page checklist/procedures/AIM/workbook then it will update the data in middle section
  public refreshReqData$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isCFTemplateRefresh$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loadDocumentInCenter$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public loadAllDocsInCenter$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public addDocuments$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public triggerNtrSignoff$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public addQuetoBlankTemplate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public disablekebabmenuopt$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(public apiService: ApiService,public dialogService: DialogService, public engStore: EngagementStore,public exportService: AdobeExportService,public firmService:FirmService,public billingService: BillingService ) {
  }

  public getS3url(s3BucketName, filepath) {
    this.products$ = this.apiService.getFiles(filepath);
    return this.products$;
  }

  public capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  //sort by property:
  sortByPropertyForNotes(data, property) {
    data = data.sort((a, b) => a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1);
    data.forEach(element => {
      if (element.subcategories.length > 0) {
        element.subcategories = element.subcategories.sort((a, b) => a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1);
        element.subcategories.forEach(note => {
          if (note.notes.length > 0) {
            note.notes = note.notes.sort((a, b) => a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1);
          }
        });
      }
      if (element.notes.length > 0) {
        element.notes = element.notes.sort((a, b) => a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1);
      }
    });
    // console.log(data)
    return data
  }

  //Get show or hide user permissions
  public getShowHideUserPermissions(id) {
    const inputData = { "useraccountid": id };
    let inputString = JSON.stringify(inputData);
    const data = {
      "procedureName": "getshoworhideuserpermissions",
      "inputParameters": inputString
    }
    this.apiService.getData(data).subscribe(response => {
      if (response) {
        let apdata = [];
        let result = {};
        let keyName = "jjjjj";
        //let stringify = JSON.stringify(response);
        apdata = JSON.parse(response);
        apdata.forEach((x, i) => {
          // result[x.accesstypeitemname.replace(/ /g, '').toLowerCase()] = x.ufid ? true : false;
          if (x.accesstypeitemufi == 1) {
            keyName = 'accessdashboard';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 2) {
            keyName = 'editfirminformation';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 3) {
            keyName = 'billing';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 4) {
            keyName = 'exportdata';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 5) {
            keyName = 'addteammember';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 6) {
            keyName = 'modifyotherteammembers';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 7) {
            keyName = 'deleteteammember';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 8) {
            keyName = 'addclient';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 9) {
            keyName = 'viewclients';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 10) {
            keyName = 'modifyclients';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 11) {
            keyName = 'deleteclient';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 12) {
            keyName = 'viewengagements';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 13) {
            keyName = 'createengagement';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 14) {
            keyName = 'modifyengagement';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 15) {
            keyName = 'deleteengagement';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 16) {
            keyName = 'archiveengagement';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 17) {
            keyName = 'reopenengagement';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 18) {
            keyName = 'viewAllTemplates';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 19) {
            keyName = 'createModifyDeleteOwnTemplates';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 20) {
            keyName = 'ModifyDeleteAllTemplates';
          }
          else if (x.accesstypeitemufi == 21) {
            keyName = 'viewTimeSummary';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 22) {
            keyName = 'editForAssignedEngagements';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 23) {
            keyName = 'exportForAssignedEngagements';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 24) {
            keyName = 'editForAllEngagements';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 25) {
            keyName = 'exportForAllEngagements';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 27) {
            keyName = 'abilityToExportPlatformData';
            result[keyName] = !!x.ufid;
          }
          else if (x.accesstypeitemufi == 999) {
            keyName = 'viewmodifiengagement';
            result[keyName] = !!x.ufid;
          }

        });
        this.accessPermissionsSubject.next(result);
      }
    });
  }
  getUserData(type: "field" | "fullData", name?) {
    this.loggedInUserData = JSON.parse((localStorage.getItem('userDetails')));
    if (this.loggedInUserData && this.loggedInUserData.useracctid) {
      return type === "field" ? this.loggedInUserData[name] : this.loggedInUserData;
    }
  }
  parseData(data) {
    if (!data) return {};
    if (typeof data === 'object') return data;
    if (typeof data === 'string') return JSON.parse(data);
    return {};
  }
  getEngData(type: "field" | "fullData", fromData: string, fieldName: string) {
    let engData = JSON.parse((localStorage.getItem(fromData)));
    if (engData && engData.engagementsid) {
      return type === "field" ? engData[fieldName] : engData;
    }
  }
  getAddBase64ConvertedImage(type: string) {
    if (type === 'green') {
      return `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAABICAYAAACwc3YrAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQU
              AAAQrSURBVHgB7Zs7TFNRGMe/GuMjoZHHwmWQksijwiDUQUi0xTgRGHAwKU46wK6sSl2crKMGojKY0A1MAGUgoTAoDoiDD0ATikPbhUcsCY9Fz//SW24LhXvOue2FcH/JDb1N
              C993z//c8/2/c3F4Xj36R8eYU3TMsROwGjsBq7ETsJrTZBKeUhf5yt2kOAupulhRf2oktjYptr5Kie1NmolFKLz0kxZW4mQGDtmFrO1SPXU2NKcFbIRoYpX6vkzQyO+vJIPwC
              DjPnKOe6+3kc7lJhDJnEQW8t6m6pJSCn8dIFOEEelvuUVWJkvYeJKLKYzlO6+w1zgGSVQoK1c97FJd6ruGva1Lf73rfTyIIJdBZ70sLPsr0/WTqHdP3oqHvt1UmZVewIzuPUs
              F+ZzP1zU4QL9x3oTL2RzsbbqbOEfzdoZeGgwfDv2apa/QNm9hrqff8dY1pI2MU7gQ8pRVp512j/Smp8BBlwQcmB1PnCL6VjQwv3Al4dZMWetdfRV5m4pG0kcP84EVAQhd2A4h
              FSJbw0lzqdVWxQrxwJ+A8cz71WkQ6mcTYeqBRxrmWAMtLCdmLcLJrIV95jXpblUH2+5IJuNXDSk6ehOZZGRyVuPebDXcC3eMDdJSwLaXVmGYpM1F0t0e9NzAbUxJQK0lmLT1l
              rj1+WM/CcozmmdmZZEVg+M8cmYGUJ0bg/tpr7Ggi51m+Wt4sTyycAFbQZ7f8e2wlLxiVh+Mh4bJcSEK48r0t9/dIBeU16nu0TKB5uDVUr/g8DtT7autFNz9wAYLsQsATi8wTo
              REIsG5Ea9Wue1pYiVFwesywrYQHDtxoT0sk9O2jUHdCyBPrg8dV7+D0xPhsx9ALVT4a6E7kxRN7M4q3wNQgiQC5dDPt6/HXNhIv3AmghNZAd0HGE6Omyrsn1g8zGliy6D2xUl
              BEvNieWBbbE5MEbZVXhCaeHpFbpx6pBLAgechaTp6EBr5/kh52M+FOIMQSOErYntgMUJX6Lzeq21a85MwTH4a2LattzYqStwQw8X0X3dTAfDN+8lrQbOQ0AUgDwfpcNeqacRD
              aDicvpifAIw3NgmZuNfEgnYCINOAjnk9/MKWaFUrAqDQQ4AgLFr6hh3lgDVx5sxpd3AkM33mQVRoICsFCy5CF3vPqEzAT7gT2a6WgKwFnhYBz1ULMhtRChu5aYnuDon/XKLG1
              kffgAfcIIEitmMMTJzi0bSY8FzQTX6RwZEdCsTxshHAn0Pz2qTpx0Z3I7LLhDqTfN4OktAeccoX0A09odHmTQRt1Z0F2CzWrqnWY+eixuiYkE0FSB/kGzB+MzmTyjiU6fxy5f
              HY6m9T2AytxODJHoR98I+PI18PfRqV29fVj4iFv1SjaiNB9KGlJjUrtMCzxA9A76iEcRENpUuPFYf//gMXYCViNnYDV2AlYzX89pcZvcLBfkgAAAABJRU5ErkJggg==`
    }
    else if (type === 'red') {
      return `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAABICAYAAACwc3YrAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQU
              AAALwSURBVHgB7ZqNUfJAEIaXb74C6ADswA7ACrAD6IASLEE7oAS1ArQCtALsAK0g+pi5mfVA4HYvORnyjDf4k8R7L3t3+27Sq0S+vk6Xf3LidAJK0wkoTSegNP8lF6ORyPW1
              yHAocnlZfwbe30Xe3urPpyeRhweR11fJAhuZq02nVbVeV8lwDuc6/79dQL9fVff3lZvbW5eAnjmVWK3qUNEQIoTHy4vIx0f9M/T7IoNBffx4XP+sIayursSESfnNzXY4jMe+s
              OOarYTQcLjdecLJch0tYrMxXSddAKOnGQxMI/fdRqOf15rPWxCgJy7fWzsf2nLpul76RqbXdyafFyZ9IF4UjiBdgF5BwirjgQ0uoAfnSMqnEs5BOPNcKOQ+Hpznp+/E67W/0/
              vo9ZIOP8MQIs/RK0dh7MncH6GzlKXJZyljyP8D2htkJo8A0ovptDYrsR/WsADQyH8eHyULrkyS/B0jQi6fSnFPjCFZrSo3XMPhKWzLKCGDJ45DhfSaRskklFI4NjRdetEQVnh
              iyzwxKV8stkcxxRNzbOyJjdUJvyfGUVkGgfkTh2ArnhjfqvF44ngwDJWJdAHawxJK1s7vup7hbvosJZPPi/bErVjKzhNHdJ7Yw2xW5z8e4kJvIj4B3s5n4AxD6O7Ofdtz0nni
              0vwNAdjP+VxkuZRUmvPEhwjewFmebE8AE38yqZdeOp1pIWhWAKERRvnQnhGecCaSX0BKaAQL+vxsf9rjzudxUVQX8AbHVic41vJkM4uhoeHCcGbajOwCQXjd2ezn7zOUU0JLD
              6F9zweI41C4Iiy04VkspAnSBewqpdBRKm18NlRC/A3fRhZeoeFzs2m9898kx92+icrfeFhNjMfViobmgG0SU5hich56T4i6D8fFrxQUF6AbtZ1jViSN4Z2I5gTolrIncPc4bj
              Jx7Ql5BVhDDbiDhjvTnqFh+SWZO5QXJT4nLuPI9mWmJyEgBiHh7lxcpJzZeeLidAJK0wkoTSegNJ8fXVykHzJYyQAAAABJRU5ErkJggg==`
    }
  }
  // Function which pushes all particulartype values to end of an array.
  pushAllRecordsConatingSpecificValueToEnd(arr, key, specificValue) {

    let count = 0; // Count of non-zero elements

    // Traverse the array. If element encountered is non-
    // specified value, then replace the element at index 'count'
    // with this element
    for (let i = 0; i < arr.length; i++)
      if (arr[i][key] != specificValue)
        arr[count++] = arr[i]; // here count is
    // incremented

    // Now all non-zero elements have been shifted to
    // front and 'count' is set as index of first 0.
    // Make all elements 0 from count to end.
    while (count < arr.length)
      arr[count++] = 0;
  }

   /**
    * Converting a String to titleCase
    * @param value is an Input String value to convert to titlecase
    */
   toTitleCase(value: string): string {
    return value.toLowerCase().replace(/(?:^|\s|-)\w/g, (match) => match.toUpperCase());
  }

  // add notes  in balancesheet and financialstatements
  searchlinkNotes(searchterm, array) {
    if (searchterm != "" || searchterm.trim() != "") {
      if (!array.some(obj => obj.hasOwnProperty('categoryId'))) {
        return array
      }
      for (let k = 0; k < array.length; k++) {
        if (array[k].notes && array[k].notes.filter(x => x.name.toLowerCase().includes(searchterm))) {
          var indx = array[k].notes.findIndex(y => y.name.toLowerCase().includes(searchterm));
          array[k].isExpand = indx > -1 ? true : false
          return array
        }
      }
    } else {
      array.forEach(exp => {
        exp.isExpand = false;
      })
      return array
    }
  }

  public setRefreshOfStatement(typeOfStatement: FS_STATEMENTS_PREVIEWS): Map<FS_STATEMENTS_PREVIEWS,boolean> {
    !this.fsRefreshMap.has(typeOfStatement) && this.fsRefreshMap.set(typeOfStatement,false);
    if(this.fsRefreshMap.has(typeOfStatement) && this.fsRefreshMap.get(typeOfStatement)) return this.fsRefreshMap
    this.fsRefreshMap.set(typeOfStatement,true);
    return this.fsRefreshMap;
  }

  public isRefreshOfStatement(typeOfStatement: FS_STATEMENTS_PREVIEWS): boolean {
    !this.fsRefreshMap.has(typeOfStatement) && this.fsRefreshMap.set(typeOfStatement,false);
    return this.fsRefreshMap.get(typeOfStatement);
  }

  public removeAfterRefresh(typeOfStatement: FS_STATEMENTS_PREVIEWS) : Map<FS_STATEMENTS_PREVIEWS,boolean>{
     this.fsRefreshMap.delete(typeOfStatement);
     return this.fsRefreshMap;
  }

  public clearAllRefreshValues() {
     this.fsRefreshMap.clear();
  }

  public refreshActiveDocAfterSettingsChanged(routeUrl: string) :Map<FS_STATEMENTS_PREVIEWS,boolean>{
     const activePage = routeUrl.split('/').pop();
      if(!ACTIVE_PAGE_MAP.has(activePage)) return null;
      const pageData = ACTIVE_PAGE_MAP.get(activePage);
      this.fsRefreshMap.clear();
      this.fsRefreshMap.set(pageData,true);
      return this.fsRefreshMap
  }

  public setPlaceofPreview(routeUrl: string): STATEMENT_VIEW_TYPES {
     return routeUrl.includes('/FinancialStatement/doc/') ? "Engagement" : (routeUrl.includes('trial-balance-working-sheet') ? "Workbook" : "None")
  }

  public refreshWorkbookPreview(routeUrl: string) :Map<FS_STATEMENTS_PREVIEWS,boolean>{
    const activePage = routeUrl.split('/').pop();
    if(!WORKBOOK_ACTIVE_PAGE_MAP.has(activePage)) return null;
    const pageData = WORKBOOK_ACTIVE_PAGE_MAP.get(activePage);
    this.fsRefreshMap.clear();
    this.fsRefreshMap.set(pageData,true);
    return this.fsRefreshMap;
  }

  public showRoundingDifference(incomeStatement: any): RoundingModel {
    const data = structuredClone(incomeStatement);
    delete data.groups;
    delete data.searchexist;
    delete data.isnotesshow;
    const roundingSPData = structuredClone(data);

    const roundingViewData : RoundingModel = new RoundingModel();
    roundingViewData.hasCYRoundingDifference = roundingSPData.hasOwnProperty('openingretainedearningseficitcheck') &&
      ((roundingSPData.openingretainedearningseficitcheck === 1) && (roundingSPData.vopeinginbalancevalue !== null))
    roundingViewData.hasPyRoundingDifference =  roundingSPData.hasOwnProperty('openingretainedearningsdeficitcheckpy2') &&
      ((roundingSPData.openingretainedearningsdeficitcheckpy2 === 1) && (roundingSPData.vopeinginbalancevaluepy2 !== null));
    roundingViewData.isCyNegative =  roundingViewData.hasCYRoundingDifference ? this.validateRoundingValue(roundingSPData.vopeinginbalancevalue) : false;
    roundingViewData.isPyNegative =  roundingViewData.hasPyRoundingDifference ? this.validateRoundingValue(roundingSPData.vopeinginbalancevaluepy2) : false;
    roundingViewData.cyDisplayValue = roundingSPData.vopeinginbalancevalue ? roundingSPData.vopeinginbalancevalue : null;
    roundingViewData.pyDisplayValue = roundingSPData.vopeinginbalancevaluepy2 ? roundingSPData.vopeinginbalancevaluepy2 : null;
    console.table(roundingViewData);
    return roundingViewData;
  }

  private validateRoundingValue(inputValue: any): boolean {
    if(typeof inputValue === 'string') {
      const hasParenthesis =  /\(.*\)/.test(inputValue);
      return hasParenthesis;
    }
    if(typeof inputValue === 'number') {
      return inputValue > 0
    }
  }

  getLeftDocList(acctYear: any, engagementID: any, useracctid: any) {
    let referenceDocLeftSideList = [];
    let obj = {
      "engagementid": engagementID,
      "acctyear": acctYear,
      "loginid": useracctid
    };

    let dataJsonString = JSON.stringify(obj);
    let dataForLeft = {
      "procedureName": "getproleftsectiondocs",
      "inputParameters": dataJsonString
    }
    return new Promise((resolve, reject) => {
      this.apiService.getData(dataForLeft).subscribe(response => {
        const responseLeft = JSON.parse(response);
        const docsArray = responseLeft.docs ? [...responseLeft.docs] : [];
        const arrList = docsArray && docsArray.length > 0 ? docsArray.filter((doc: any) => doc && doc.engleftsidedocid && doc.engteamclientdocid) : [];
        const filterdList = arrList && arrList.length > 0 ? arrList : [];
        referenceDocLeftSideList = _.uniqBy(filterdList, 'engleftsidedocid'); //to remove duplicate files from response (same obj with diffrent sectionpath from db)
        resolve(referenceDocLeftSideList);
      }, error => {
				reject([]);
			});
    });
  }

  getRightDocList(acctYear: any, engagementID: any, useracctid: any) {
    let referenceDocRightSideList = [];
    let obj = {
      "engagementid": engagementID,
      "acctyear": acctYear,
      "loginid": useracctid
    };

    let dataJsonString = JSON.stringify(obj);
    let dataForRight = {
      "procedureName": "getprorightsectiondocs",
      "inputParameters": dataJsonString
    }
    return new Promise((resolve, reject) => {
      this.apiService.getData(dataForRight).subscribe(response => {
        const responseRight = JSON.parse(response);
        referenceDocRightSideList = responseRight.docs ? [...responseRight.docs] : [];
        resolve(referenceDocRightSideList);
      }, error => {
				reject([]);
			});
    });
  }

  //Check for reference name change
  checkForWpRefDocChanges(referenceDocLeftSideList: any[], referenceDocRightSideList: any[], tableData: any[] , property: string) {
    let isRefChanged = false;
    if (referenceDocLeftSideList.length || referenceDocRightSideList.length) {
      tableData.forEach((item, mainIndex) => {
        if (item[property] && item[property].length) {
          item[property].forEach((doc) => {
            if(doc.engteamclientdocid){
              let leftIndex = referenceDocLeftSideList.findIndex(x => x.engteamclientdocid === doc.engteamclientdocid);
              let rightIndex = referenceDocRightSideList.findIndex(x => x.engteamclientdocid === doc.engteamclientdocid);
              if (leftIndex > -1 && doc.REF !== referenceDocLeftSideList[leftIndex].REF) {
                isRefChanged = true;
              }
              if (rightIndex > -1 && doc.REF !== referenceDocRightSideList[rightIndex].REF) {
                isRefChanged = true;
              }
              if(leftIndex === -1 && rightIndex === -1){
                isRefChanged = true;
              }
            }
          });
        }
      });
    }
    return isRefChanged;
  }

  /** Generic filter function for mat-grid
   * @input mat-grid datasource, column key
   * returns the filter list for that particular column
   */
  getColumnFilterData(data, columnKey): any[] {
    let list: string[] =[];
    list = data.map(e => e[columnKey]);
    const unique = [...new Set(list)];
    const arr = unique.map(e =>{
      return {value : e, isChecked: false};
    })
    return arr;
  }

  createAppliedFilterObject(displayedColumns){
    const appliedFilterObj = {};
    displayedColumns.forEach(col => {
        appliedFilterObj[col] = [];
    });
    return appliedFilterObj;
  }

  getFilteredGridData(data:any, appliedFilters: any){
    data = JSON.parse(JSON.stringify(data));
    appliedFilters = JSON.parse(JSON.stringify(appliedFilters));
    const filterValues: any[][] = Object.values(appliedFilters);
    if(filterValues.every(e => e.length === 0)){
        return data;
    }
    let filteredDataList: any[] = [];
    const filterKeys = Object.keys(appliedFilters);
    filterKeys.forEach(key => {
        if(appliedFilters[key].length !== 0){
            const colFilterArray = appliedFilters[key];
            data.forEach(row => {
                if(colFilterArray.includes(row[key])){
                    filteredDataList.push(row);
                }
            });
            data = JSON.parse(JSON.stringify(filteredDataList));
            filteredDataList = [];
        }
    })
    return data;
  }
}

