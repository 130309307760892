import {COMPONENTS_TYPES} from 'countable@helpers';
import {ImageLetterModel} from './eng-letter.model';

export class TemplateTypeModel {
    templateType: 'Letters' | 'Reports';
    templateFolderType: 'Firm' | 'Master' | 'Engagement';
    templateActionType: 'Edit' | 'View';
    headerImage?: ImageLetterModel | null = new ImageLetterModel()
    footerImage?: ImageLetterModel | null = new ImageLetterModel()
    firmSignatureImage?: ImageLetterModel | null = new ImageLetterModel();
    clientSignatureImage?: ImageLetterModel | null = new ImageLetterModel();
    headersList?: Array<any> = [];
    footersList?: Array<any> = [];
    elementList?: Array<string> = [];
    canUploadComponents: boolean = false;
    disableLockSections: boolean = false;
    templateHeading?: string = '';
    isManagementLetter: boolean = false;
}

export class TemplateContentModel {
    EditableMode: boolean;
    checked: boolean | number;
    content: string;
    editSection: boolean;
    header: string;
    id: number;
    islock: boolean;
    sortId: number;
    userAdded: number;
}

export class PageModel {
    page: string;
    pageContent: Array<TemplateContentModel> | Array<any> | null;
}

export class UploadImageModel {
  typeOfComponent: COMPONENTS_TYPES;
  typeOfUpload: 'DragDrop' | 'InputImage';
  imageEvent: any;
}
