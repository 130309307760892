import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class ApiCallInterceptor implements HttpInterceptor {

  private static noAuthPages = ['/resetpassword', '/login', '/signup', 'forgotpassword'];
  private static tokenExemptions = ['/auth/login', '/signup', '/address/country/list', '/about-us', '/auth/login-google',
    '/auth/login-oauth', '/auth/signup-prepare-sso-google', '/token-validation', '/set-password'];

  constructor(private authService: AuthService, private toaster: ToastrService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith(environment.api) || req.url.startsWith(environment.ai)) {
      let headers: HttpHeaders = req.headers;
      const token = this.authService.getToken();
      if (req.body instanceof FormData) {
        headers = headers.delete('Content-Type');
      } else if (!req.headers.has('Content-Type')) {
        headers = headers.set('Content-Type', 'application/json');
      }
      if (token && !req.headers.has('Authorization') && !ApiCallInterceptor.tokenExemptions.find(i => req.url.endsWith(i))) {
        headers = headers.set('Authorization', `Bearer ${token}`);
      }
      req = req.clone({headers: headers.set('Accept', 'application/json')});
    }
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse): Observable<any> => {
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ProgressEvent) {
            this.toaster.error('Cannot connect to server, please try again later.');
          }
          if (err.status === 401 && !ApiCallInterceptor.tokenExemptions.find(item => req.url.endsWith(item))) {
            this.authService.logout(ApiCallInterceptor.noAuthPages.includes(location.pathname));
            console.warn('Interceptor: called logout for: ', location.pathname);
          }
          return throwError(() => err);
        }
      }));
  }
}
