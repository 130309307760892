export const InvalidExtensions = {
  MPG: 'mpg',
  MPEG: 'mpeg',
  WAV: 'wav',
  MOV: 'mov',
  TIF: 'tif',
  TIFF: 'tiff',
  GFI: 'gfi',
  T1: 't1',
  T2: 't2',
  EXCEL: 'excel',
  XLT: 'xlt',
  XLTX: 'xltx',
  XLTM: 'xltm',
  XLA: 'xla',
  XLAM: 'xlam',
  TXT: 'txt',
  T2013T2: '2013t2',
  T2014T2: '2014t2',
  T2015T2: '2015t2',
  T2016T2: '2016t2',
  T2017T2: '2017t2',
  T2018T2: '2018t2',
  T2019T2: '2019t2',
  T2020T2: '2020t2',
  T2021T2: '2021t2',
  T2022T2: '2022t2',
  T2023T2: '2023t2',
  T2024T2: '2024t2',
  T2025T2: '2025t2',
  T2026T2: '2026t2',
  T2027T2: '2027t2',
  T2028T2: '2028t2',
  T112: '112',
  T113: '113',
  T114: '114',
  T115: '115',
  T116: '116',
  T117: '117',
  T118: '118',
  T119: '119',
  T120: '120',
  T121: '121',
  T122: '122',
  T123: '123',
  T124: '124',
  T125: '125',
  T126: '126',
  T127: '127',
  T210: '210',
  T211: '211',
  T212: '212',
  T213: '213',
  T214: '214',
  T215: '215',
  T216: '216',
  T217: '217',
  T218: '218',
  T219: '219',
  T220: '220',
  T221: '221',
  T222: '222',
  T223: '223',
  T224: '224',
  T225: '225',
  T226: '226',
  T227: '227',
  T312: '312',
  T313: '313',
  T314: '314',
  T315: '315',
  T316: '316',
  T317: '317',
  T318: '318',
  T319: '319',
  T320: '320',
  T321: '321',
  T322: '322',
  T323: '323',
  T324: '324',
  T325: '325',
  T326: '326',
  T327: '327',
  AYT: 'ayt',
  BYT: 'byt',
  T00C: '00c',
  T13T: '13t',
  C00: 'c00',
  CCHLCK: 'cchlck',
  CLT: 'clt',
  CMF: 'cmf',
  D00: 'd00',
  H00: 'h00',
  IYT: 'iyt',
  M00: 'm00',
  N00: 'n00',
  RYT: 'ryt',
  T12: 't12',
  T13: 't13',
  T14: 't14',
  T15: 't15',
  T16: 't16',
  T17: 't17',
  T18: 't18',
  T19: 't19',
  T20: 't20',
  T21: 't21',
  T22: 't22',
  T23: 't23',
  T24: 't24',
  T25: 't25',
  T26: 't26',
  T27: 't27',
  UYT: 'uyt',
  '%yt': '%yt',
  LYT: 'lyt',
  PYT: 'pyt'
};

export const MSOfficeExtensions = {
  DOC: 'doc',
  DOCX: 'docx',
  PPTX: 'pptx',
  PPT: 'ppt',
  POT: 'pot',
  POTX: 'potx',
  PPSX: 'ppsx',
  XLSX: 'xlsx',
  XLS: 'xls',
  XLSM: 'xlsm',
  CSV: 'csv',
  WOPITEST: 'wopitest'
};
