import { Component, Inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddManagementLetterModel, DialogInputModel, FirmModel, UserDetailModel } from 'countable@model';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { SharedService } from 'src/app/components/shared/shared.service';
import { DIALOG_TYPES } from 'src/app/helpers/stored-procedures.constant';
import { AclModel } from 'src/app/model/acl.model';
import { BillingModel } from 'src/app/model/billing/billing.model';
import { AclService } from 'src/app/services/acl.service';
import { ApiService } from 'src/app/services/api.service';
import { BillingService } from 'src/app/services/billing.service';
import { EngService } from 'src/app/services/eng.service';
import { EngLetterService } from '../../../../services/eng-letter.service';
import { FirmService } from 'countable@services';
import moment from 'moment';

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.scss']
})
export class CommonDialogComponent implements OnInit, OnDestroy {

  dialogType: DIALOG_TYPES = null;
  dialogHeader: string;
  dialogData: string;
  private templatesSubject: Subject<any> = new Subject<any>()
  public templatesData$: Observable<any>;
  public masterTemplatesData$: Observable<any>;
  private searchText: string = '';
  private userDetails: UserDetailModel | null = null;
  public templatesData: any[] = [];
  selectedToMoveObj: any;
  aclSubscription: Subscription;
  aclModel: AclModel = new AclModel();
  public restrictModuleIds: string[] = ['2f23ac84-00d', '57070136-bc4', '2f210e74-00d', '2f20fb34-00d', 'Trial_balance'];
  public searchContent: FormControl<string | null> = new FormControl<string | null>('', [Validators.minLength(3)]);
  public searchChecklist: FormControl<string | null> = new FormControl<string | null>('', [Validators.minLength(3)]);
  public letterTabIndex: number = 0;
  public selectedEngagement: any;
  public selectedEngagementID: any;
  public selectedEngagementTypeID: any;
  get isPegPactSubscribed(): boolean {
    return this.userDetails.ispegpacklicenseid;
  }

  isMoveExpandAll: any;
  folderData$: Observable<any> = this.templatesSubject.asObservable();
  public billing: BillingModel;
  private allSubscriptions: Subscription = new Subscription();
  public firm: FirmModel;
  isPegPactExpired = signal<boolean>(false);
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogInputModel, private apiService: ApiService,
              private sharedService: SharedService, public dialogRef: MatDialogRef<CommonDialogComponent>,
              private engService: EngService, private spinner: NgxSpinnerService, public aclService: AclService,
              public router: Router, private billingService: BillingService, private engLetterService: EngLetterService,
              private firmService: FirmService) {
    this.aclSubscription = this.aclService.subject.subscribe(res => {
      if (res) {
        this.aclModel = res;
      }
    });

    this.allSubscriptions.add(
      this.billingService.subject.subscribe({
        next: billing => {
          if (billing) {
            this.billing = billing;
          }
        }
      })
    )
    this.allSubscriptions.add(
      this.firmService.subject.subscribe({
        next: (firm) => {
          if (firm) {
            this.firm = firm;
            this.isPegPactExpired.set(firm.isPegPacValid);
          }
        },
        error: (err) => {
          console.log(err);
        }
      })
    );
  }

  ngOnInit(): void {
    this.selectedEngagement = this.engService.getSelectedEngagementObj();
    this.selectedEngagementID = this.selectedEngagement?.engagementsid;
    this.selectedEngagementTypeID = this.selectedEngagement?.engagementtypeid ? this.selectedEngagement?.engagementtypeid : this.selectedEngagement?.pengagementtypeid;
    this.userDetails = this.engService.authService.getUserDetail();
    if (this.data.dialogType === 'delete_checklist') {
      this.dialogType = this.data.dialogType;
      this.dialogHeader = this.data.dialogHeader;
    }
    if (this.data.dialogType === 'save_new_checklist' || this.data.dialogType === 'replace_checklist') {
      this.dialogHeader = this.data.dialogHeader;
      this.dialogType = this.data.dialogType;
      this.getFirmTemplatesData();
    }
    if (this.data.dialogType === 'move_leftsidedoc') {
      this.dialogType = 'move_leftsidedoc';
      this.templatesSubject.next(this.data.data);
      this.templatesData = structuredClone(this.data.data);
      this.data.originId && this.restrictModuleIds.push(this.data.originId);
    }
    if (this.data.dialogType === 'add_letter') {
      this.dialogHeader = this.data.dialogHeader;
      this.dialogType = this.data.dialogType;
      this.getLetterTemplatesData();
    }
    if (this.data.dialogType === 'upgrade_plan') {
      this.dialogType = 'upgrade_plan';
      this.dialogData = this.data.data;
      this.dialogHeader = this.data.dialogHeader;
    }
    // Account Paused
    if (this.data.dialogType === 'account_paused') {
      this.dialogType = 'account_paused';
    }

    // free trial expired
    if (this.data.dialogType === 'trial_expired') {
      this.dialogType = 'trial_expired';
    }

    // storage Limit exceeds
    if (this.data.dialogType === 'storage_limit') {
      this.dialogData = this.data.data;
      this.dialogType = 'storage_limit';
    }

    // engagement limit reached
    if (this.data.dialogType === 'eng_limit') {
      this.dialogData = this.data.data;
      this.dialogType = 'eng_limit';
    }

    this.searchContent.valueChanges.pipe(
      distinctUntilChanged(),
      // filter(s => s && s.length > 3),
      debounceTime(1000)
    ).subscribe({
      next: res => {
        this.getLetterTemplatesData(res);
      }
    });

    // peg/pact subscription expires in 1 month and 3 days
    if (this.data.dialogType === 'pegPactExpiresInMonthOrDays') {
      this.dialogData = this.data.data;
      this.dialogHeader = this.data.dialogHeader;
      this.dialogType = 'pegPactExpiresInMonthOrDays';
    }

    // add team member Billing Info
    if (this.data.dialogType === 'addTeamBillingInfo') {
      this.dialogData = this.data.data;
      this.dialogType = 'addTeamBillingInfo';
    }

    // scheduled To Delete Or Pause
    if (this.data.dialogType === 'scheduledToDeleteOrPause') {
      this.dialogData = this.data.data;
      this.dialogHeader = this.data.dialogHeader;
      this.dialogType = 'scheduledToDeleteOrPause';
    }

    this.searchChecklist.valueChanges.pipe(
      distinctUntilChanged(),
      // filter(s => s && s.length > 3),
      debounceTime(1000)
    ).subscribe({
      next: res => {
        this.getFirmTemplatesData(res);
      }
    });
  }

  getFirmTemplatesData(searchInput: string = '') {
    const search = searchInput ? searchInput : null;
    const inputData = { "loginid": this.userDetails.useracctid, "searchindex": search ? search : null, "engagementsid": this.selectedEngagementID ? this.selectedEngagementID : '' }
    const data = {
      "procedureName": "getfirmtemplate_test",
      "inputParameters": JSON.stringify(inputData)
    }
    this.spinner.show();
    this.apiService.getData(data).pipe(
      map(res => this.sharedService.parseData(res)),
      map(response => response["foldercheklistdata"] ? response["foldercheklistdata"] : []),
      tap(data => {
        data?.forEach(f => {
          f?.checklisttemplates?.forEach(t => {
            t['isDisabled'] = this.engLetterService.updateTemplateValiditiyByEngTypeId(t?.engagementtypeid);
          });
        });
        this.templatesData$ = of(data);
      })
    ).subscribe().add(() => {
      this.dialogType = this.data.dialogType;
      this.spinner.hide();
    })
  }

  getMasterChecklistData() {
    const masterObj = {
      "procedureName": "getmasterchecklistleftside",
      "inputParameters": JSON.stringify({ "useraccountid": this.userDetails.useracctid, "loginid": this.userDetails.useracctid, "searchindex": null })
    }
    this.spinner.show();
    this.apiService.getData(masterObj).pipe(
      map(res => this.sharedService.parseData(res)),
      map(response => response["Checklist"] ? response["Checklist"] : []),
      tap(data => this.sharedService.engStore.setData('masterChecklist', data))
    ).subscribe()
  }

  getLetterTemplatesData(searchInput: string = '') {
    const data = {
      "procedureName": "getfirmtemplate",
      'inputParameters': JSON.stringify({
        'loginid': this.userDetails.useracctid,
        'searchindex': searchInput ? searchInput : '',
        'ismaster': this.letterTabIndex === 1 ? 1 : 0
      })
    }
    this.spinner.show();
    this.apiService.getData(data).pipe(
      map(res => this.sharedService.parseData(res)),
      map(res => {
        if (!searchInput) {
          res && res.folderdata && res.folderdata.forEach((f: any) => {
            f['isexpand'] = 'false';
            f['ishighlight'] = 'false';
            f.templates && f.templates.forEach((t: any) => {
              t['isexpand'] = 'false';
              t['ishighlight'] = 'false';
            });
          });
          res && res.mastertemplate && res.mastertemplate.forEach(((f: any, index: number) => {
            f['isexpand'] = 'false';
            f['ishighlight'] = 'false';
            if (this.userDetails?.origin_country_id === 226) {
              f['StandardName'] = index === 0 ? 'Compilation' : 'Review';
            }
            f.template && f.template.forEach((t: any) => {
              t['isexpand'] = 'false';
              t['ishighlight'] = 'false';
            });
          }));
        }
        return res;
      }),
      tap(data => {
        data?.folderdata?.forEach((f: any) => {
          f?.templates?.forEach((t: any) => {
            t['isDisabled'] = this.engLetterService.updateTemplateValiditiyByEngTypeId(t?.engagementtypeid);
          });
        });
        data?.mastertemplate?.forEach(((f: any) => {
          f?.template?.forEach((t: any) => {
            t['isDisabled'] = this.engLetterService.updateTemplateValiditiyByEngTypeId(t?.engagementtypeid);
          });
        }));
        this.templatesData$ = of(data);
      })
    ).subscribe().add(() => {
      this.dialogType = this.data.dialogType;
      this.spinner.hide();
    })
  }

  saveSelection(template) {
    this.sharedService.dialogService.setSelectedData = { ...template };
    this.dialogRef.close(true);
  }

  selectedMoveChoice(Obj) {
    this.selectedToMoveObj = { ...Obj };

  }

  moveDocument() {
    this.sharedService.dialogService.setSelectedData = { ...this.selectedToMoveObj };
  }

  goToHome() {

  }

  onChangeTabSections(event: any) {
    this.letterTabIndex = event;
    this.searchContent.reset();
    this.getLetterTemplatesData('');
  }

  setManagementLetter(templateData: any, from: 'master' | 'firm') {
    let templateName = from === 'firm' ? templateData.TemplateName : templateData.templatename;
    const Obj: AddManagementLetterModel = new AddManagementLetterModel();
    Obj.firmTemplateId = from === 'firm' ? templateData.FirmTemplateID : 0;
    Obj.masterTemplateId = from === 'master' ? templateData.mastertemplateid : 0;
    Obj.jsonFilePath = templateData.JsonFilePath;
    Obj.jsonFileName = templateName + '.json';
    this.sharedService.dialogService.setSelectedData = { ...Obj };
  }


  trackByFirmFolder(index, item) {
    return index;
  }

  trackByFirmTemplate(index, item) {
    return index;
  }

  trackByMoveFolders(index, item) {
    return index;
  }


  private searchInTemplates(searchInput: string) {
    if (!searchInput) {
      return;
    }
    const lettersData = this.sharedService.engStore.getStoreKeyValue('letters');
    this.letterTabIndex === 0 && lettersData && lettersData.folderdata && lettersData.folderdata.forEach((f: any) => {
      f.templates && f.templates.forEach((t: any) => {
        if (t.TemplateName.includes(searchInput)) {
          f['isexpand'] = 'true';
          t['ishighlight'] = 'true';
        } else {
          t['ishighlight'] = 'false';
        }
      });
    });

    this.letterTabIndex === 1 && lettersData && lettersData.mastertemplate && lettersData.mastertemplate.forEach((f: any) => {
      f.template && f.template.forEach((t: any) => {
        if (t.templatename.includes(searchInput)) {
          f['isexpand'] = 'true';
          t['ishighlight'] = 'true';
        } else {
          t['ishighlight'] = 'false';
        }
      });
    });
    const data = new BehaviorSubject<any>(structuredClone(lettersData));
    this.templatesData$ = data.asObservable();

  }

  ngOnDestroy(): void {
    if (this.aclSubscription) {
      this.aclSubscription.unsubscribe();
      this.allSubscriptions && this.allSubscriptions.unsubscribe();
    }
  }

  upgradePlanDialog() {
    this.billingService.upgradePlanDialog('upgrade_plan','Update');
  }

}
