export class TbImportModel {
  accountNO: any;
  accountName: any;
  debit: any;
  credit: any;
  group: any;
}

export class TbImportErrorsList {
  error: string;
  id: number;
  hasError: boolean;
}
