import { GenericResModel } from '../generic-res.model';
import { CHAT_MESSAGE_TYPE, ChatsModel, SavedChatHistoryModel } from './chat.model';
import { ContextResModel } from './context.model';
import { GlTbModel, GLTbYearModel } from './prompts.model';
import { SuggestedPromptsModel } from './suggested-prompts.model';

export class SetupLucaModel {
  step: number;
  stepTitle: string;
  isStarted: boolean;
  isCompleted: boolean;
  inProgress: boolean;
  title: string;
  subTitle: string;
}

export class ChatInputModel {
  history: ChatHistoryOLDModel[] | null = null;
  query: string;
  context?: any;
  chat_object: ChatSavingInputModel = null;
}

export class ChatSavingInputModel {
  is_first_message: boolean = false;
  chat_id: number = null;
  engagement_id: number = 0;
  chat_title: string = '';
  previous_messages_to_append: any[] = [];
  prompt_id: number = 0;
  query: string = '';
  free_context: ContextApiInputModel;
}

export class ContextApiInputModel {
  generalLedger: ContextGlInputModel;
  yearEnd: string;
  sourceLink: number = 0;
}

export class ContextGlInputModel {
  isCYSelected: boolean = false;
  isPY1Selected: boolean = false;
  isPY2Selected: boolean = false;
  trialBalanceIds: number[] = [];
  selectedFinGroupIds: number[] = [];
}

export class ChatHistoryOLDModel {
  role: CHAT_MESSAGE_TYPE;
  content: string;
  isShowSpinner?: boolean;
}

export class ChatHistoryModel extends ChatHistoryOLDModel {
  filePath: string;
  chatId: number;
  userQuery: string;
  userPrompt: string;
  inputTokens: string;
  userInputFileNames: LucaFileModel[];
  lucaResponseHtml: string;
  lucaResponse: string;
  lucaOutputFilePath: string;
  outputTokens: string;
  userPromptInputs: string;
  messageType: string;
  isUpdateContext: string;
  hasActions: 'AGED_AR_CUSTOMERS' | 'FILTERED_GL';
  actionInformation: any;
  contentType: 'HTML' | 'MARK' = 'MARK';
  loadingContent: string;
  htmlContent: string;
  markdownContent: string;
  hasSystemFile: boolean = false;
  previousMessagesToAppend: any[] = [];
}

export class ChatResponseModel {
  response: string;
  history: ChatHistoryModel[] | null = null;
  chat_object: GenericResModel<any>;
}

export class pageInfo {
  clientId: number = 0;
  clientName: string;
  pageType: 'CLIENT' | 'ENGAGEMENT' | 'NONE' | 'RESTRICTED';
  engName: string;
  engId: number = 0;
}

export class LucaClosedModel {
  closedType: 'CLOSE' | 'MINIMIZE' | 'CONVERTED' | 'NONE' | 'NAVIGATE' | 'CLOSE_DIALOG';
  chatHistory: SavedChatHistoryModel;
  selectedPrompt: SuggestedPromptsModel;
  engagementInfo: LucaEngListModel;
  context: any;
  filePath: string;
  isFromHistory: boolean;
  disableLauncher: boolean = false;
  route: string = '';
  activeChatInfo: ChatsModel = null;
  chatsList: ChatsModel[] = null;
  contextRes: ContextResModel = null;
  hasOnGoingApiCallUUID: string = null;
}

export class LucaDialogInputModel {
  dialogInfo: LucaClosedModel;
  engList: LucaEngListModel[];
  pageInfo: pageInfo;
  isMinimizedState: boolean = false;
  hasNoEngagements: boolean = false;
  isAiRestricted: boolean = false;
}

export class LucaEngListModel {
  engId: number;
  engName: string;
  clientId: number;
  clientName: string;
  yearEnd: string;
  engType: string;
  engStatus: string;
  engStatusId: number;
  engCreatedDate: string;
  sourceType: 'QUICKBOOK' | 'XERO' | 'SAGE' | 'CSV' | 'NONE';
  isSourceEligible: boolean = false;
  isActiveConnection: boolean = false;
  logoPath: string;
  sourceClientName: string;
  year: string;
  engTypeId: number;
  integrationId: number;
}

export class CommonInputModel {
  type: AI_API_TYPES;
  engagement_id: number = 0;
  start_date: string = '';
  end_date: string = '';
  year: number;
  materiality: number;
  change_dollar_input: number;
  change_percent_input: number;
  ar_account_name: string = null;
  engagement_name: string;
  opening_adj_cost: string;
  opening_balance: string;
  fx_rate: string;
  tb_account_uuids: string[];
  customer_list: string[];
  ar_details: any;
  gl_details: any;
  chat_object: any;
  file_paths: string[];
  clientName: string;
  confirmation: boolean = false;
}

export class CommonAIResModel {
  chat_object: GenericResModel<ChatsModel>;
  data: AIResModel;
  errors: string[];
  messages: string[];
  status: number;
}

export class AIResModel {
  context: any;
  file_url: string;
  file_path: string;
  markdown: any;
  accounts: any[];
  categoriesList: any[] = [];
  amortization_schedule: any[];
  adj_entry: any[];
  generalLedger: any[];
  tbAccounts: any[];
  message: string;
  hasAllMonths?: boolean;
  hasInvalidFile?: boolean;
}

export type AI_API_TYPES =
  'ar_analysis'
  | 'variance_analysis_with_api'
  | 'gl_data'
  | 'generate_markdown'
  | 'generate_markdown_ar '
  | 'generate_markdown_gl'
  | 'hst_gst_plausibility'
  | 'investment_schedule_analysis'
  | 'file_analysis';

export class LucaFileModel {
  file: File | null = null;
  filePath: string;
  fileName: string;
  size: number;
  fileContent: any[] | [];
  isInProgress: boolean = false;
  hasUploaded: boolean = false;
  extension: '.pdf' | '.xlsx' | '.doc' | any | null = null;
  fileType: string;
  progressPercent: 100 = 100;
}

export class LucaSortModel {
  imgPath: string;
  sortType: 'ASC' | 'DSC' | 'DEF' | 'NONE';
  sort: 'YEAR' | 'DATE';
}

export const SORT_OPTIONS: LucaSortModel[] = [
  {
    imgPath: '/assets/images/ui-uplift-icons/luca/default-order.svg',
    sortType: 'DEF',
    sort: 'YEAR'
  },
  {
    imgPath: '/assets/images/ui-uplift-icons/luca/ascending-order.svg',
    sortType: 'ASC',
    sort: 'YEAR'
  },
  {
    imgPath: '/assets/images/ui-uplift-icons/luca/descending-order.svg',
    sortType: 'DSC',
    sort: 'YEAR'
  },
  {
    imgPath: '/assets/images/ui-uplift-icons/luca/ascending-order.svg',
    sortType: 'ASC',
    sort: 'DATE'
  },
  {
    imgPath: '/assets/images/ui-uplift-icons/luca/descending-order.svg',
    sortType: 'DSC',
    sort: 'DATE'
  },
  {
    imgPath: '/assets/images/ui-uplift-icons/luca/default-order.svg',
    sortType: 'DEF',
    sort: 'DATE'
  }
];

export class ChangeEngModel {
  type: 'CONTINUE' | 'NEW' | 'NORMAL';
  engId: number;
  searchText: string;
}

export class ARAccountModel {
  isChecked: boolean = false;
  accountCode: string;
  accountName: string;
  accountUUID: string;
  changePercent: string;
  finalAmount: number;
  py1Amount: number;
  trialBalanceId: number;
}

export type CHAT_ACTION_TYPE = 'NEW' | 'DELETE' | 'RENAME' | 'PIN';

export class DialogResizeModel {
  state: 'FULL' | 'DEFAULT' | 'NONE' = 'DEFAULT';
  imgPath: string = '/assets/images/ui-uplift-icons/luca/expand-white.svg';
  hasExpanded: boolean = false;
  title: string;
}

export class GLSelectionModel {
  groups: any;
  subGroups: any;
  leadSheets: any;
  accounts: any;
  year: any;
  originalGl: GLTbYearModel[] = [];
  selectedRows: GlTbModel[] = [];
  isDisabled: boolean = false;
}

export class AdjustmentObj {
  comment: string = null;
  comment1: string = null;
  groupid: number = 0;
  children: AdjustmentRowModel[];
  isdelete: number = 0;
  groupname: string = null;
  debittotal: number;
  entry_date: string;
  credittotal: number;
  tbadjustuid: string;
  modifieddate: string = '';
  debittotaldiff: any;
  credittotaldiff: any;
  adjustmenttypeid: number = 1;
  ispushedtosource: number = 0;
  adjustmenttypename: string;
  adjustmententryabbr: string;
}

export class AdjustmentRowModel {
  acctyear: number;
  isdelete: number = 0;
  isrecurring: number = 0;
  acctdebit: any;
  acctcredit: any;
  accountcode: any;
  accountname: string;
  accountuuid: string;
  isnewrecord: number = 0;
  leadsheetcode: string;
  trailbalanceid: number;
  issourcevalidated: number = 0;
  trailadjustmentid: number = 0;
  ispushedtosourcetb: number = 0;
  loginid: number;
  engagementsid: number;
}

export class FreeContextModel {
  isCY: boolean = false;
  isPY1: boolean = false;
  isPY2: boolean = false;
  trialBalance: number[] = [];
  sourceLink: number = 0;
  selectedFinGroupIds: number[] = [];
  yearEnd: string;
}
