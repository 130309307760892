import { BlacklistedPaths, SURVEY_INTERVALS, WhitelistedUrls } from '../helpers/survey.contant';

export class SurveyUtils {
  static isPathBlacklisted(path: string): boolean {
    return BlacklistedPaths.some(blacklistedPath => path.startsWith(blacklistedPath));
  }

  static isUrlWhitelisted(url: string): boolean {
    const isWhitelisted = WhitelistedUrls.some(whitelistedUrl => url.startsWith(whitelistedUrl));
    return isWhitelisted;
  }

  static calculateNextSurveyShowDate(lastIgnoreDate: Date, ignoreCount: number): Date {
    let nextSurveyDate = new Date(lastIgnoreDate);
    switch (ignoreCount) {
      case 1:
      case 2:
        nextSurveyDate.setDate(nextSurveyDate.getDate() + SURVEY_INTERVALS.INITIAL);
        break;
      case 3:
      case 4:
      case 5:
        nextSurveyDate.setDate(nextSurveyDate.getDate() + SURVEY_INTERVALS.WEEKLY);
        break;
      case 6:
      case 7:
      case 8:
        nextSurveyDate.setMonth(nextSurveyDate.getMonth() + SURVEY_INTERVALS.MONTHLY);
        break;
      case 9:
        nextSurveyDate.setFullYear(nextSurveyDate.getFullYear() + SURVEY_INTERVALS.NEVER);
    }

    return nextSurveyDate;
  }
}