import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../../../../../services/api.service';

@Component({
  selector: 'app-cash-flow-work-statement',
  templateUrl: './cash-flow-work-statement.component.html',
  styleUrls: ['./cash-flow-work-statement.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers     : [DatePipe],
})
export class CashFlowWorkStatementComponent implements OnInit {
  //properties
  testDate = "12-31-2019";
  engagementId              : number;
  selectedEngagementObj     : any;
  yearEnd                   : number;
  acctYear                  : number;
  userDetails               : any;
  userLoginId               : number;
  cashFlowData              : any;
  pageHeading               : string = "cash flow statement";
  currentYear               : string;
  previousYear              : string;
  constructor(
      private apiService  : ApiService,
      public dialog       : MatDialog,
      private datePipe    : DatePipe,
      private toaster     : ToastrService,
      private spinner     : NgxSpinnerService
    ) { }

  ngOnInit() {
    this.selectedEngagementObj = JSON.parse((localStorage.getItem('selectedEngagementObj')));
    let year = this.datePipe.transform(this.selectedEngagementObj.yearEnd, 'y');
    this.yearEnd = +year;
    this.engagementId = this.selectedEngagementObj.engagementsid;
    this.acctYear = +year;
    //get the user details
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.userLoginId = this.userDetails.useracctid;
    this.getCashFlowStatement();
  }
  getCashFlowStatement() {
    const inputData = {
      "engagementid": this.engagementId,
      "acctyear": this.yearEnd
    };
    const inputString = JSON.stringify(inputData);
    let data = {
      "procedureName": "getcashflowreport",
      "inputParameters": inputString
    };
    this.spinner.show();
    this.apiService.getData(data).subscribe(response => {
      this.spinner.hide();
      const responseData = JSON.parse(response);
      if (responseData && responseData.length > 0) {
        this.cashFlowData = responseData;
        this.currentYear = this.cashFlowData[0].years.curyear;
        this.previousYear = this.cashFlowData[0].years.prevyear;

      }
      else {
        this.cashFlowData = [];
      }
    }, error => {
      this.spinner.hide();
    });
  }

  refresh() {
    this.getCashFlowStatement();
  }
}
