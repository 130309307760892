import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MatMenuTrigger} from '@angular/material/menu';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {DatePipe} from '@angular/common';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import {DeleteLeadsheetModalComponent} from './delete-leadsheet/delete-leadsheet-model.component';
import {ApiService} from '../../../../../services/api.service';
import {SharedService} from '../../../../shared/shared.service';

@Component({
    selector: 'ls-dropdown',
    templateUrl: './ls-dropdown.component.html',
    styleUrls: ['./ls-dropdown.component.scss'],
    encapsulation: ViewEncapsulation.None,
})

export class LSDropDownComponent implements OnInit {
    /* leadsheet parameters */
    @ViewChild('closeNlsMenu') closeNlsMenu: MatMenuTrigger;
    @ViewChild('closeAlsMenu') closeAlsMenu: MatMenuTrigger;
    addLeadSheetForm: UntypedFormGroup;
    private lsSubject: Subject<string> = new Subject();
    isLsActive: boolean = false;
    lsIndex: number;
    allLs: any = [];
    newLs: any = [];
    originalNewLs: any = [];
    originalAllLs: any = [];
    leadSheetNextCode: any = [];
    isEditLeadSheetCode: boolean = false;
    isShowLsError: boolean = false;
    deleteLsData: { lsCode: any; lsId: any; loginId: any };
    //leadSheetId: number;
    isActiveLsCode: boolean = false;
    lsTabIndex: number = 0;
    changedLsType: string = "ALL";
    userData: any;
    engagementData: any;
    selectedEngagementDetails: any;
    userLoginId: any;
    groups: any = [];
    subGroups: any = [];
    @Input() TbLeadSheetId: number;
    @Input() parentObject: {};
    @Input() moveType: string;
    @Input() yearType: string;
    @Input() subscribeFrom: string;
    engagementId: any;
    lsSearchTerm: string;
    lsObject: any;
    groupTitle: string;
    subgroupTitle: string;
    lsCodeTitle: string;

    constructor(private apiService: ApiService,
        private sharedService: SharedService,
        public dialog: MatDialog,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService,
        public datePipe: DatePipe,
        private fb: UntypedFormBuilder) {
    }

    ngOnInit() {
        this.userData = JSON.parse(localStorage.getItem('userDetails'))
        this.engagementData = JSON.parse(localStorage.getItem("selectedEngagementObj"))
        this.selectedEngagementDetails = JSON.parse((localStorage.getItem('selectedEngagementObj')));
        this.userLoginId = this.userData['useracctid'];
        this.engagementId = this.engagementData.engagementsid;
        this.lsSearchTerm = '';
        this.getLeadSheetDetails("");
        this.loadLsForm();
        this.lsSubject.pipe(
            filter(res => res.length > 2 || res.length < 3),
            debounceTime(1000),
            distinctUntilChanged()
        ).subscribe((text: string) => {     
            this.filterLeadSheetName(text);
        });
    }
    handleLsSearch(searchTerm: string) {
        this.lsSubject.next(searchTerm);
    }
    loadLsForm() {
        this.addLeadSheetForm = this.fb.group({
            'group': ["", Validators.required],
            'subGroup': ["", Validators.required],
            'lsCode': ["", Validators.required],
            'lsName': ["", [Validators.required, Validators.maxLength(100)]],
            'leadSheetId': [""],
        });
        this.getGroups();
    }
    getLeadSheetDetails(searchTerm) {
        let search = searchTerm ? searchTerm : null;
        let obj = {
            "searchindex": search,
            "engagementsid": this.engagementId,
            "loginid": this.userLoginId
        };
        let inputString = JSON.stringify(obj);
        let data = {
            "procedureName": "getleadsheet",
            "inputParameters": inputString
        };
        this.spinner.show();
        this.apiService.getData(data).subscribe(response => {
            if (response) {
                let res = JSON.parse(response);
                this.spinner.hide();
                this.allLs = res.all ? res.all : [];
                this.originalAllLs = res.all ? res.all : [];
                this.newLs = res.new ? res.new : [];
                this.originalNewLs = res.new ? res.new : [];
                this.changeLsTab(this.changedLsType);

            }
            else {
                this.toastr.warning("Fail to load lead sheet");
            }
        }, error => {
            this.spinner.hide();
            this.toastr.error(error.error);
        });
    }
    changeLsTab(type: string): void {
        this.changedLsType = type;
        if (type === "ALL") { // All maps
            this.lsTabIndex = 0;
        }
        else { // New map
            this.lsTabIndex = 1;
        }
    }
    getGroups() {
        let obj = {
            "loginid": this.userLoginId
        };
        let inputString = JSON.stringify(obj);
        let data = {
            "procedureName": "getmapgroup",
            "inputParameters": inputString
        };
        this.apiService.getData(data).subscribe(response => {
            if (response) {
                this.groups = JSON.parse(response);

            }
            else {
                this.toastr.warning("Fail to load groups");
            }
        }, error => {
            this.toastr.error(error.error);
        });
    }
    createLs() {
        this.isActiveLsCode = true;
        this.isEditLeadSheetCode = false;
        this.enableLsInputs();
        this.addLeadSheetForm.reset();
        this.hideMoveButton(true);
    }
    backToLsCode(type: "ADD" | "EDIT") {
        if (type === "ADD") {
            if (this.addLeadSheetForm.controls['group'].value
            || this.addLeadSheetForm.controls['subGroup'].value
            || this.addLeadSheetForm.controls['lsCode'].value
            || this.addLeadSheetForm.controls['lsName'].value) {
                this.isActiveLsCode = true;
                this.isShowLsError = true;
            }
            else {
                this.isShowLsError = false;
                this.isActiveLsCode = false;
                this.addLeadSheetForm.reset();
                this.hideMoveButton(false);
            }
        }
        else {
            this.isShowLsError = false;
            this.isActiveLsCode = false;
            this.addLeadSheetForm.reset();
            this.hideMoveButton(false);
        }
    }
    cancelLsCode() {
        this.isShowLsError = false;
        this.isActiveLsCode = false;
        this.hideMoveButton(false);
        this.addLeadSheetForm.reset();
    }

    getLeadSheetNextCode(finSubGId?, finGroupId?) {
        let finSubGroupId = finSubGId ? finSubGId : this.addLeadSheetForm.get('subGroup').value;
        let type = finSubGId ? "true" : "false";
        let obj = {
            "finsubgroupid": finSubGroupId,
            "isedit": type,
            "engagementsid": this.engagementId,
            "loginid": this.userLoginId
        };
        let inputString = JSON.stringify(obj);
        let data = {
            "procedureName": "getleadsheetnextcode",
            "inputParameters": inputString
        };
        this.apiService.getData(data).subscribe(response => {
            if (response) {
                let res = JSON.parse(response);
                this.leadSheetNextCode = res.Lsnextorder;
                this.addLeadSheetForm.controls['lsCode'].reset;

            }
        }, error => {
            this.toastr.error(error.error);
        });
    }
    saveNewLeadSheetCode() {
        if (!this.addLeadSheetForm.valid) {
            return;
        }
        else {
            let obj;
            obj = this.addLeadSheetForm.value;

            const saveLsObj = {
                "lssave": [{
                    "leadsheetid": 0,
                    "fingroupid": obj.group,
                    "leadsheetcode": obj.lsCode,
                    "leadsheetname": obj.lsName,
                    "finsubgroupid": obj.subGroup,
                    "engagementsid": this.engagementId,
                    "isdelete": 0,
                    "loginid": this.userLoginId
                }]
            }
            const finalSaveLsObj = JSON.stringify(saveLsObj);
            const saveObj = {
                "inputParameters": finalSaveLsObj,
                "procedureName": "saveleadsheet"
            }

            this.spinner.show();
            this.apiService.saveData(saveObj).subscribe(response => {
                if (response) {
                    this.isActiveLsCode = false;
                    this.spinner.hide();
                    this.addLeadSheetForm.reset();
                    this.hideMoveButton(false);
                  this.toastr.success("New leadsheet added successfully");
                    this.getLeadSheetDetails(this.lsSearchTerm);
                }
            }, error => {
                this.isActiveLsCode = false;
                this.spinner.hide();
                this.toastr.error(error.error);
                this.addLeadSheetForm.reset();
            });
        }
    }

    editLeadSheetCode() {

        let obj = this.lsObject;
        this.disableLsInputs();
        this.isActiveLsCode = true;
        this.isEditLeadSheetCode = true;
        this.getSubGroups(obj.fingroupid);
        this.getLeadSheetNextCode(obj.finsubgroupid, obj.fingroupid);
        this.hideMoveButton(true);
        setTimeout(() => {
            this.addLeadSheetForm.patchValue({
                'group': obj.fingroupid,
                'subGroup': obj.finsubgroupid,
                'lsCode': obj.leadsheetcode,
                'lsName': obj.leadsheetname,
                'leadSheetId': obj.leadsheetid
            });
        }, 1000);
    }
    updateLeadSheetCode() {

        let obj = this.addLeadSheetForm.value;
        const updateMapObj = {
            "uplsname": [{
                "leadsheetid": obj.leadSheetId,
                "leadsheetname": obj.lsName,
                "loginid": this.userLoginId
            }]
        }
        const updateMapnoObj = JSON.stringify(updateMapObj);
        const saveObj = {
            "inputParameters": updateMapnoObj,
            "procedureName": "updateleadsheetname"
        }

        this.spinner.show();
        this.apiService.saveData(saveObj).subscribe(response => {
            if (response) {
                this.isActiveLsCode = false;
                this.isEditLeadSheetCode = false;
                this.spinner.hide();
                this.addLeadSheetForm.reset();
                this.hideMoveButton(false);
                this.toastr.success("Updated leadsheet name successfully");
                this.getLeadSheetDetails(this.lsSearchTerm);
            }
        }, error => {
            this.isActiveLsCode = false;
            this.isEditLeadSheetCode = false;
            this.spinner.hide();
            this.toastr.error(error.error);
            this.addLeadSheetForm.reset();
        });
    }
    deleteLsDialog(type: "all"|"new") {

        let obj = this.lsObject;
        this.deleteLsData = {
            "lsCode": obj.leadsheetcode,
            "lsId": obj.leadsheetid,
            "loginId": this.userLoginId
        }
        const dialogRef = this.dialog.open(DeleteLeadsheetModalComponent, { data: this.deleteLsData, disableClose: true });
        dialogRef.afterClosed().subscribe(result => {

            this.hideMoveButton(false);
            if(result === true){
                this.getLeadSheetDetails(this.lsSearchTerm);
            }

        });
        if(type === "all"){
            this.closeAlsMenu.closeMenu();
        }
        else{
            this.closeNlsMenu.closeMenu();
        }
    }
    getSubGroups(gId?) {
        let groupId: number;
        groupId = gId ? gId : this.addLeadSheetForm.get('group').value;
        let obj = {
            "fingroupid": groupId,
            "loginid": this.userLoginId
        };
        let inputString = JSON.stringify(obj);
        let data = {
            "procedureName": "getmapsubgroup",
            "inputParameters": inputString
        };
        this.apiService.getData(data).subscribe(response => {
            if (response) {
                this.subGroups = JSON.parse(response);
                if (!gId) {
                    this.leadSheetNextCode = [];
                    this.addLeadSheetForm.controls['subGroup'].setValue(null);
                    //this.addLeadSheetForm.controls['subGroup'].reset;
                    this.addLeadSheetForm.controls['lsCode'].setValue(null);
                    //this.addLeadSheetForm.controls['lsCode'].reset;
                }
            }
            else {
                this.toastr.warning("Fail to load groups");
            }
        }, error => {
            this.toastr.error(error.error);
        });
    }
    confirmSaveLeadsheet(parentData, element, type) {
        if(type === "SINGLE"){
            parentData.isLeadSheet = false;
        }
        let data = {
            "subscribeFrom": this.subscribeFrom,
            "parent": parentData,
            "child": element,
            "moveType": type,
            "moveFrom": 'LS',
            "isDisableMoveButton": element.leadsheetid ? false : true,
            "yearType": this.yearType
        }
        this.TbLeadSheetId = element.leadsheetid;
        this.sharedService.isMovingLeadsheet.next(data);
    }
    disableLsInputs(){
        this.addLeadSheetForm.controls.group.disable();
        this.addLeadSheetForm.controls.subGroup.disable();
        this.addLeadSheetForm.controls.lsCode.disable();
    }
    enableLsInputs(){
        if(this.addLeadSheetForm.controls.group.disabled){
            this.addLeadSheetForm.controls.group.enable();
        }
        if(this.addLeadSheetForm.controls.subGroup.disabled){
            this.addLeadSheetForm.controls.subGroup.enable();
        }
        if(this.addLeadSheetForm.controls.lsCode.disabled){
            this.addLeadSheetForm.controls.lsCode.enable();
        }
    }
    trackByAllLeadsheets(index, item){
        return index; // or item.id
    }
    trackByNewLeadsheets(index, item){
        return index; // or item.id
    }
    hideMoveButton(hideType: boolean){
        if(this.moveType === "MULTI" && hideType){
            this.sharedService.hideMoveButton.next(true);
        }
        else{
            this.sharedService.hideMoveButton.next(false);
        }
    }
    onChangeGroup(event) {
        if (event) {
            this.groupTitle = event.fingroupname.trim();
        }
    }
    onChangeSubGroup(event) {
        if (event) {
            this.subgroupTitle = event.finsubgroupname.trim();
        }
    }
    onChangelsCode(event) {
        if (event) {
            this.lsCodeTitle = event.leadsheetcode.trim();
        }
    }

    filterLeadSheetName(searchTerm) {
        if (!searchTerm) {
            this.allLs = this.originalAllLs.slice();
            this.newLs = this.originalNewLs.slice();
        } else {
            this.allLs = this.originalAllLs.filter(item =>
                item.leadsheetname.toLowerCase().includes(searchTerm.toLowerCase())
            );

            this.newLs = this.originalNewLs.filter(item =>
                item.leadsheetname.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
    }
}
