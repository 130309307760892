import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserDetailModel } from 'countable@model';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { ExportPlatformService } from '../../services/export-platform.service';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, OnDestroy {

  @ViewChild('pegPactTemplateDialog') pegPactTemplateDialog: TemplateRef<any>;
  @ViewChild('downloadFailedPopup') downloadFailedPopup: TemplateRef<any>;

  toggleMenu: boolean = true;
  userroleId: any;
  loggedInUserData: UserDetailModel;
  accessPermissions: any;
  permissionsSub: Subscription;
  headerStyle: number;
  firmTemplateStyleUnsubscribe: Subscription;
  public mfamandtory: boolean = false;
  mfamandtorySub: Subscription;
  private allSubscriptions: Subscription = new Subscription();
  private readonly authSubject: Subscription = null;

  constructor(private sharedServices: SharedService, public router: Router, private authService: AuthService,
              private exportPlatformService: ExportPlatformService, private toaster: ToastrService, private dialog: MatDialog) {
    this.authSubject = this.authService.subject.subscribe(status => {
      if (status) {
        this.loggedInUserData = this.authService.getUserDetail();
        this.userroleId = this.loggedInUserData?.userroleId;
      }
    });
    this.permissionsSub = this.sharedServices.accessPermissionsSubject.subscribe(res => {
      if (res) {
        this.accessPermissions = res;
      }
    });
    this.firmTemplateStyleUnsubscribe = this.sharedServices.frimTemplateStyle.subscribe(data => {
      if (data == true) {
        this.headerStyle = 1;
      } else {
        this.headerStyle = 0;
      }
    });
    this.mfamandtorySub = this.sharedServices.mandatorymfa.subscribe({
      next: (response) => {
        this.mfamandtory = response;
      }
    });
    this.allSubscriptions.add(
      this.exportPlatformService.exportList$.pipe().subscribe({
        next: res => {
          if (res) {
            const exportDetails = Array.from(res.entries()).map(([key, value]) => ({key, value}));
            if (exportDetails && exportDetails?.some(r => r?.value === 'STARTED')) {
              const exportInfo = res;
              exportDetails && exportDetails?.forEach(row => {
                if (row?.value === 'STARTED') {
                  exportInfo.set(row?.key, 'PROGRESS');
                  this.downloadPlatformExportZip(row?.key);
                }
              });
              this.exportPlatformService.exportList$.next(exportInfo);
            }
          }
        }
      })
    );
  }

  ngOnInit() {
  }

  toggleDrawer() {
    this.toggleMenu = !this.toggleMenu;
  }

  onRouteChange() {
    let routeData = {'url': this.router.url};
    this.sharedServices.routeObj.next(routeData);
    if (this.loggedInUserData && this.loggedInUserData.useracctid) {
      this.getUserPermissions(this.loggedInUserData.useracctid);
    } else {
      setTimeout(() => {
        this.getUserPermissions(this.loggedInUserData.useracctid);
      }, 100);
    }
  }

  redirectToSettings() {
    this.router.navigate(['']);
  }

  createNewEngagement() {
    this.sharedServices.selectedClient = {};
    this.router.navigate(['/dashboard/engagement/createengagement']);
  }

  clientListSearch(filterValue: string) {
    this.sharedServices.clientSearchString.next(filterValue);
  }

  engagementListSearch(filterValue: string) {
    this.sharedServices.gridDataEngage.filter = filterValue.trim().toLowerCase();
  }

  getUserPermissions(id) {
    this.sharedServices.getShowHideUserPermissions(id);
  }

  downloadPlatformExportZip(id: string) {
    this.exportPlatformService.downloadFile(id).pipe(
      distinctUntilChanged(),
      tap(() => this.exportPlatformService.exportSuccess$.next(id))
    ).subscribe({
      next: (response: any) => {
        if (response) {
          saveAs(response, 'Platform Export.zip');
          this.exportPlatformService.exportSuccess$.next(id);
          this.toaster.success('File downloaded successfully');
          const exportInfo = this.exportPlatformService.exportList$.getValue();
          exportInfo.delete(id);
          this.exportPlatformService.exportList$.next(exportInfo);
        } else {
          this.retryDownload(id);
        }
      },
      error: () => {
        this.retryDownload(id);
      }
    });
  }

  ngOnDestroy() {
    this.permissionsSub && this.permissionsSub.unsubscribe();
    this.firmTemplateStyleUnsubscribe && this.firmTemplateStyleUnsubscribe.unsubscribe();
    this.authSubject && this.authSubject.unsubscribe();
  }

  private retryDownload(id: string) {
    this.dialog.open(this.downloadFailedPopup).afterClosed().subscribe({
      next: res => {
        if (res) {
          const exportInfo = this.exportPlatformService.exportList$.getValue();
          exportInfo.set(id, 'STARTED');
          this.exportPlatformService.exportList$.next(exportInfo);
        }
      }
    });
  }
}
