import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listFilter'
})
export class ListFilterPipe implements PipeTransform {

  transform(value: any, args?: any): any {

    if (!value) return null;
    if (!args) return value;

    args = args.toLowerCase();
    let key = '';
    return value.filter(function (item: any) {
      key = Object.keys(item)[0]
      if (key === 'mapno' || key === 'gificode') {
        key = 'accountname';
      }
      if (key === 'leadsheetid') {
        key = 'leadsheetname';
      }
      return (item[key]+'').toLowerCase().includes(args);
    });
  }
}
