import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericResModel } from 'countable@model';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { EngagementInterface } from '../model/eng/engagement.interface';
import { IntegrationSourceInterface } from '../model/eng/integration-source.interface';
import { IntegrationInterface } from '../model/integration/integration.interface';
import { OauthDisconnectModel } from '../model/oauth/oauth-disconnect.model';
import { OauthKickOffModel } from '../model/oauth/oauth-kick-off.model';
import { IntegrationApiService } from './integration-api.service';
import { StorageService } from './storage.service';

export enum STATUS {
  NO_CONNECTION_TO_SOURCE = 'NO_CONNECTION',
  CONNECTION_ESTABLISHED = 'ACTIVE_CONNECTION',
  FETCH_FROM_SOURCE = 'REFRESH_TRAIL_BALANCE',
  STORAGE_KEY = 'sourceConnectToEngStatus'
}

export enum OauthConnectionType {
  CLIENT_ACCOUNT_SETUP = 1,
  CLIENT_INTEGRATION = 2,
  PARTNER_INTEGRATION = 5,
  DOC_LIST = 10,
  SSO = 15,
  LOGIN = 20,
  SIGNUP = 30
}

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  private static readonly oauthBaseUri = environment.api + '/oauth';

  constructor(private api: IntegrationApiService, private httpClient: HttpClient) {}

  public async getAccountingIntegration(code: number): Promise<IntegrationInterface> {
    if (!StorageService.applicationModel.integrations.has(code)) {
      StorageService.applicationModel.integrations.set(code, await this.api.fetchAccountingIntegration(code));
    }
    return StorageService.applicationModel.integrations.get(code);
  }

  public async getAccountingIntegrations(): Promise<Map<number, IntegrationInterface>> {
    if (!StorageService.applicationModel.integrationsFullyLoaded) {
      await this.api.fetchAccountingIntegrations().then(result => {
        result.forEach(integration => {
          StorageService.applicationModel.integrations.set(integration.id, integration);
        });
        StorageService.applicationModel.integrationsFullyLoaded = true;
      });
    }
    return StorageService.applicationModel.integrations;
  }

  public async getAccountingIntegrationByClient(clientId: number): Promise<IntegrationSourceInterface> {
    return await this.api.fetchAccountingIntegrationByClientId(clientId).then(integration => {
      return integration;
    });
  }

  public async getAccountingIntegrationByEngagement(engagementId): Promise<IntegrationSourceInterface> {
    let engagement: EngagementInterface = StorageService.applicationModel.engagements.get(engagementId);
    if (!engagement || !engagement.integrationSource  || (engagement?.integrationSource?.isConnected === false)) {
      await this.api.fetchAccountingIntegrationByEngagement(engagementId).then(result => {
        if (!engagement) {
          engagement = new EngagementInterface();
        }
        engagement.integrationSource = result;
        StorageService.applicationModel.engagements.set(engagementId, engagement);
      });
    }
    return StorageService.applicationModel.engagements.get(engagementId).integrationSource;
  }

  public deleteIntegrationSourceByFirm(firmId: number) {
    StorageService.applicationModel.engagements.forEach((value, key) => {
      if (value && value.integrationSource && value.integrationSource.client === firmId) {
        StorageService.applicationModel.engagements.get(key).integrationSource = null;
      }
    });
  }

  public async getSageOrganizations(clientId: number) {
    return await this.api.fetchSageOrganizationDetails(clientId).then(organizations => {
      return organizations;
    });
  }

  public async saveSageOrganization(orgDetails) {
    return await this.api.saveSageOrganizationDetails(orgDetails).then(saveOrgDetails => {
      return saveOrgDetails;
    });
  }

  public checkConnection(source: number, hash: string): Observable<boolean> {
    return this.api.checkConnection({source: source, hash: hash});
  }

  public oauthProvidersKickOff(data: OauthKickOffModel): Observable<GenericResModel<string>> | null {
    if (data.redirectMode == OauthConnectionType.PARTNER_INTEGRATION ||
      data.redirectMode == OauthConnectionType.CLIENT_INTEGRATION ||
      data.redirectMode == OauthConnectionType.CLIENT_ACCOUNT_SETUP) {
      return this.api.oauthKickOff(data);
    } else {
      window.open(IntegrationService.oauthBaseUri + '/connect/' + data.captcha + '/' + data.key + '/' + data.redirectMode, '_self');
    }
  }

  public disconnect(tenant: IntegrationSourceInterface): Promise<boolean> {
    const data: OauthDisconnectModel = {
      source: tenant.id,
      client: tenant.client,
      hash: tenant.tenant
    };

    return this.api.disconnect(data);
  }

  public sourceToEngagementConnection(engagementsId: any, clientFirmId: any): Observable<GenericResModel<boolean>> {
    return this.api.connectEngagementToSource(engagementsId, clientFirmId);
  }

  public checkConnectionNeeded(): boolean {
    let checkConnectionUpdatedAt = StorageService.applicationModel.checkConnectionUpdatedAt;
    let checkConnectionUpdatedAtDate: Date;
    let checkNeeded: boolean = false;
    if (checkConnectionUpdatedAt) {
      checkConnectionUpdatedAtDate = new Date(Date.parse(checkConnectionUpdatedAt));
      let current = new Date();
      current.setHours(current.getHours() - 2);
      if (checkConnectionUpdatedAtDate < current) {
        checkNeeded = true;
        StorageService.applicationModel.checkConnectionUpdatedAt = new Date().toString();
      }
    } else {
      checkNeeded = true;
      StorageService.applicationModel.checkConnectionUpdatedAt = new Date().toString();
    }
    return checkNeeded;
  }

  public checkConnectionStatus(connectedClients): Observable<GenericResModel<boolean>> {
    return this.httpClient.post<GenericResModel<boolean>>(environment.apiV1 + '/integration' + '/check-connections', connectedClients);
  }

  public getSsoProviders(): Observable<GenericResModel<number[]>> {
    return this.api.fetchSsoProviders();
  }

  public disconnectSsoProviders(integration: number): Observable<GenericResModel<void>> {
    return this.api.disconnectSsoProvider(integration);
  }
}
