import { CommonModule } from '@angular/common';
import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogInputModel, FirmModel } from 'countable@model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PLANS_ADDONS_MESSAGES } from 'src/app/helpers/plan-addons.constant';
import { DIALOG_TYPES } from 'src/app/helpers/stored-procedures.constant';
import { AclModel } from 'src/app/model/acl.model';
import { BillingModel } from 'src/app/model/billing/billing.model';
import { AclService } from 'src/app/services/acl.service';
import { AuthService } from 'src/app/services/auth.service';
import { FirmService } from 'src/app/services/firm.service';
import { StorageService } from '../../../services/storage.service';
import { SharedModule } from '../../shared/shared.module';

@Component({
  standalone: true,
  imports: [CommonModule, SharedModule, ReactiveFormsModule],
  selector: 'app-common-flex',
  templateUrl: './common-flex.component.html',
  styleUrls: ['./common-flex.component.scss']
})
export class CommonFlexComponent {

  @ViewChild('unhealthyFirmSwitchDialog') unhealthyFirmSwitchDialog: TemplateRef<any>;

  public billing: BillingModel;
  dialogType: DIALOG_TYPES = null;
  dialogHeader: string;
  dialogData: any;
  aclSubscription: Subscription;
  aclModel: AclModel = new AclModel();
  selectedData: any = null;
  firmSearch: FormControl;
  deleteCmsMember: FormControl;
  firm: FirmModel = null;
  firms: FirmModel[] = [];
  public plan_addons_messages = PLANS_ADDONS_MESSAGES;

  private readonly firmSubject: Subscription;

  constructor(private router: Router, public dialogRef: MatDialogRef<CommonFlexComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogInputModel,
              private toaster: ToastrService, private firmService: FirmService, public aclService: AclService,
              private authService: AuthService, private spinner: NgxSpinnerService, private dialog: MatDialog) {
    this.aclSubscription = this.aclService.subject.subscribe(res => {
      if (res) {
        this.aclModel = res;
      }
    });
    this.firmSubject = this.firmService.subject.subscribe(firm => {
      if (firm) {
        this.firm = firm;
        this.firms = StorageService.applicationModel.firms;
      }
    });
  }

  ngOnInit(): void {
    this.firmSearch = new FormControl();
    this.deleteCmsMember = new FormControl();
    this.dialogData = this.data.data;
    this.dialogHeader = this.data.dialogHeader;
    this.dialogType = this.data.dialogType;
  }

  ngOnDestroy(): void {
    if (this.aclSubscription) {
      this.aclSubscription.unsubscribe();
    }
    if (this.firmSubject) {
      this.firmSubject.unsubscribe();
    }
  }

  markTheFirmAsSelected(firm: FirmModel) {
    this.selectedData = firm;
  }

  switchFirm() {
    if (!this.selectedData || !this.selectedData.hash) {
      this.toaster.error('Please select the firm to switch');
      return;
    }
    this.spinner.show().then();
    this.firmService.switchFirm(this.selectedData.hash).subscribe({
      next: res => {
        if (res.status === 200) {
          this.toaster.success('Firm switched successfully');
          this.authService.prepareForRefreshAfterSwitchFirm(res.data);
          this.authService.refreshUserProfile().subscribe(() => {
            this.dialogRef.close();
            this.spinner.hide().then();
            this.selectedData = null;
            this.router.navigateByUrl('/dashboard/members/Dashboard').then(() => { window.location.reload(); });
          });
        } else if (res.status === 403) {
          this.dialogRef.close();
          this.spinner.hide().then();
          this.dialog.open(this.unhealthyFirmSwitchDialog, {disableClose: true}).afterClosed();
        } else {
          this.dialogRef.close();
          this.spinner.hide().then();
          res.errors.forEach(error => this.toaster.error(error));
        }
      },
      error: () => {
        this.spinner.hide().then();
        this.dialogRef.close();
        this.toaster.error('Could not switch the firm, please contact Countable support team');
      }
    });
  }

  deleteCmsReassigned(value: string) {
    this.dialogRef.close(value);
  }
}
