import { AfterViewInit, Component, ContentChildren, ElementRef, Input, Output, OnInit, QueryList, ViewChild, ViewEncapsulation, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/components/shared/shared.service';

@Component({
  selector: 'app-page-view',
  templateUrl: './page-view.component.html',
  styleUrls: ['./page-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageViewComponent implements OnInit, AfterViewInit {

  @Input() pageSize: "A3" | "A4" = "A4";
  @Input() documentDetails: any = [];
  @Input() footerImagePath: any;
  @Input() headerImagePath: any;
  @Input() alignmentValue: any;
  @Input() isEditable: boolean = false;
  @Input() isShowDraft: boolean = false;

  @Output("openHeaderDialog") openHeaderDialog: EventEmitter<any> = new EventEmitter();
  @Output("openFooterDialog") openFooterDialog: EventEmitter<any> = new EventEmitter();


  @ViewChild("pageView") pageView: ElementRef<HTMLDivElement>;

  @ContentChildren("pageContent", { read: ElementRef }) content: QueryList<ElementRef>;

  constructor(public router: Router,public shareService :SharedService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.updatePages();

    this.content.changes.subscribe(item => {
      this.updatePages();
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.pageView) {
      this.updatePages();
    }
  }
  updatePages() {
    if (this.pageView && this.pageView.nativeElement) {
      this.pageView.nativeElement.innerHTML = "";
    }
    let page = this.getNewPage();
    let pageContent = page.getElementsByClassName('page-content');
    if (this.pageView && this.pageView.nativeElement) {
      this.pageView.nativeElement.appendChild(page);
    }

    // height of the space that can be occupied by the content section of the page.
    let headerDivElement = <HTMLDivElement>page.querySelectorAll('.header img').item(0);
    let footerDivElement = <HTMLDivElement>page.querySelectorAll('.footer img').item(0);
    // let typeAreaHeight = page.offsetHeight -  headerDivElement.offsetHeight - footerDivElement.offsetHeight - 90;
    let footerHeaderHeight = (headerDivElement && typeof headerDivElement !== 'undefined'? headerDivElement.offsetHeight :80 + 50 ) + (footerDivElement  && typeof footerDivElement !== 'undefined' ? footerDivElement.offsetHeight: 52);
    let typeAreaHeight = 1300;
    typeAreaHeight = 1300 - footerHeaderHeight;
     // console.log(' header >>> ' + headerDivElement.offsetHeight);
    //console.log(' footer >>> ' +footerDivElement.offsetHeight);
    // console.log(' page >>> ' +page.offsetHeight);


    this.content.forEach((itemRef,gapCount )=> {
      const item = itemRef.nativeElement;

      if (item.clientHeight > page.clientHeight) {
        return;
      }

      pageContent[0].appendChild(item);

      footerHeaderHeight = (headerDivElement && typeof headerDivElement !== 'undefined'? headerDivElement.offsetHeight :80 + 50 ) + (footerDivElement  && typeof footerDivElement !== 'undefined' ? footerDivElement.offsetHeight: 52);
      typeAreaHeight = 1000- footerHeaderHeight -(gapCount * 20); //5px for the spacing


      if (pageContent[0].scrollHeight > typeAreaHeight) {

        page = this.getNewPage();
        pageContent = page.getElementsByClassName('page-content');
        this.pageView.nativeElement.appendChild(page);
        pageContent[0].appendChild(item);
      }
    });
    if (this.content.first) {
          this.content.first.nativeElement.scrollIntoViewIfNeeded();
      //this.content.first.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }

  }

  // Creates a new page
  getNewPage(): HTMLDivElement {
    const page = document.createElement("div");
    page.classList.add("page");
    page.classList.add(this.pageSize);

    if (this.isShowDraft)
      page.classList.add("show-draft");

    const header = document.createElement("div");
    header.classList.add("header");

    if (this.documentDetails?.headerId && this.documentDetails.headerId > 0 && this.headerImagePath) {
      const img = document.createElement("img");
      img.src = this.headerImagePath;
      img.style.minHeight = '80px';
      img.style.maxHeight = '200px';
      //img.style.height = '200px';
      header.appendChild(img);
      header.style.backgroundColor ='transparent';
      header.style.textAlign = this.alignmentValue?.toLowerCase();
    }
    else{
      header.style.paddingTop = '33px';
      header.style.textAlign = 'center';

      if(this.documentDetails.fromEngagement && this.headerImagePath) {
        header.style.backgroundColor = 'transparent';
      } else {
        header.innerHTML = "Header goes here";
        header.style.color = "#6D6D6D";
        header.style.marginBottom = "50px";
        header.classList.add("w-100");
      }
    }
    //edit icon
    if (this.isEditable) {
      const editButton = document.createElement("div");
      editButton.classList.add("edit");
      editButton.innerHTML = '<i class="material-icons">create</i>';
      header.appendChild(editButton);
      header.classList.add("editable");
      header.onclick = () => { this.openHeaderDialog.emit() };
    }

    page.appendChild(header);

    //add the page content area
    const pageContent = document.createElement("div");
    pageContent.classList.add('page-content', 'd-flex', 'flex-column', 'gap-20');
    page.appendChild(pageContent);

    //add the footer to the page
    const footer = document.createElement("div");
    footer.classList.add("footer");
    if (this.documentDetails?.footerId && this.documentDetails.footerId > 0 && this.footerImagePath) {
      const img = document.createElement("img");
      img.src = this.footerImagePath;
      img.style.minHeight = '52px';
      img.style.maxHeight = '70px';
      //img.style.height = '70px';
      footer.appendChild(img);
      footer.style.backgroundColor ='transparent';
    }
    else{
      footer.style.paddingTop = '19px';
      footer.style.textAlign = 'center';

      if(this.documentDetails.fromEngagement && this.footerImagePath) {
        footer.style.backgroundColor = 'transparent';
      } else {
        footer.innerHTML = "Footer goes here";
        footer.style.color = "#6D6D6D";
      }
    }
    if (this.isEditable) {

      //edit icon
      const editButton = document.createElement("div");
      editButton.classList.add("edit");
      editButton.innerHTML = '<i class="material-icons">create</i>';
      footer.appendChild(editButton);
      footer.classList.add("editable");
      footer.onclick = () => { this.openFooterDialog.emit() };
    }

    page.appendChild(footer);

    //add the page number to the page
    const pageNum = document.createElement("div");
    pageNum.classList.add("page-num");
    pageNum.innerHTML = "Page ";
   // page.appendChild(pageNum);

    return page;
  }


}
