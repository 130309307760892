import { ChatLucaMsgModel, SavedChatHistoryModel } from 'countable@model';

export class AiMessagesUtil {
  static getChatWelcomeRes(msgType: 'SELECT_ENGAGEMENT' | 'HAS_ENGAGEMENT' = null) {
    if (msgType === 'SELECT_ENGAGEMENT') {
      return AiResponses?.selectEngagement;
    }
    if (msgType === 'HAS_ENGAGEMENT') {
      return AiResponses.engSelected;
    }
  }

  static setNewChat(engId: number = 0) {
    const chatInfo: SavedChatHistoryModel = new SavedChatHistoryModel();
    chatInfo.engagementId = engId;
    chatInfo.chat_id = null;
    chatInfo.messages = [];
    const systemMsg: ChatLucaMsgModel = new ChatLucaMsgModel();
    systemMsg.showSpinner = false;
    systemMsg.messageType = 'SYSTEM_AI';
    systemMsg.lucaResponseType = 'MARKDOWN';
    systemMsg.lucaMarkdownResponse = engId > 0 ? this.getChatWelcomeRes('HAS_ENGAGEMENT') :
      this.getChatWelcomeRes('SELECT_ENGAGEMENT');
    chatInfo.messages = [systemMsg];
    return chatInfo;
  }
}

export enum AiResponses {
  selectEngagement = ` I'm Luca, your AI assistant here to help you navigate our platform
and make your accounting tasks easier. To get started, please enter the name of the
client or engagement ID in the search bar on the top that you wish to work on. I'll take it from
there! If you have any questions or need assistance at any point, just let me know. I'm here
to help you every step of the way.`,
  engSelected = `Great! I've got the client name and engagement ID. If you have any questions about the
engagement, just ask! Or, you can pick from the prompts below and we'll tackle your
requirements together`,
  loadingContent = `Setting things up for you...`,
  glInput = `No problem, (User Name)! I just need few inputs from you to pull together the General Ledger Data.
Please select the year along with the criteria's. You can select multiple options.`,
  fileError = `Oops! It looks like you've exceeded the upload limit. You can upload up to 15 documents, with a total size of 25MB.
   Please adjust your files and give it another try.`,
  resError = `We encountered an issue while trying to process your request.
   please start a new chat for a different prompt. If the problem persists, contact support for further assistance. `
}
