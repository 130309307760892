export class ProceduresNotesModel {
  procedureGeneralNotesId: number;
  generalNotes: string;
  hasNoteCreated: boolean;
  createdAt: string;
  updatedByUserName: string;
  updatedAt: string;
}

export class ProceduresNotesReqModel {
  engagementId: number;
  subModuleId: number;
  finSubGroupId: number;
  leadSheetId: number;
}
