import { Directive, OnInit, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[required]'
})
export class MarkAsteriskDirective implements OnInit {

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit() {
    const parent = this.renderer.parentNode(this.el.nativeElement);
    let text = parent.getElementsByClassName('ng-placeholder')[0];
    if (parent.getElementsByClassName('ng-placeholder').length && !parent.getElementsByClassName('required-asterisk').length) {
        setTimeout(() => {
            parent.getElementsByClassName('ng-placeholder')[0].innerHTML += '<span class="required-asterisk">*</span>';
        }, 0)
    }
  }
}