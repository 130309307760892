import { PaymentFrequencyEnum, PricePeriodType } from '../../services/billing.service';

export class ChangePlanReqModel {
  // the below 3 params, will define the change
  product: string = null; // product.hash
  pricePeriodType: PricePeriodType = PricePeriodType.MONTHLY;
  paymentFrequency: PaymentFrequencyEnum = PaymentFrequencyEnum.MONTHLY;
  price: string = null; // product.price.code, will be ignored by api
  flex: boolean = null;
  ai: boolean = null;
  seats: number = null;
  promotionCode?: string = null;
  selectedCardId?: string = null;
  superAdminHash?: string = null;
  prorationTime?: number = null;
  goToStep?: string = null;
}
