import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';


@Component({
	selector: 'app-unsaved-changes-dialog',
	templateUrl: 'unsavedChangesDialog.component.html',
	styleUrls: ['./unsavedChangesDialog.component.scss']
})

export class UnsavedChangesDialogComponent implements OnInit {
	constructor(public dialogRef: MatDialogRef<UnsavedChangesDialogComponent>) {}
	ngOnInit() {}
}
