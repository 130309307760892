import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, signal, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation, WritableSignal } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { FS_TABLE_COLUMNS, FS_TABLE_FORMATS, FS_TABLE_ROW_ACTIONS, FS_TABLE_ROW_DEFAULT, FS_TABLE_ROWS, NOT_MANUAL_TABLE, NOTES, NOTES_MESSAGES } from 'countable@helpers';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, range, Subscription } from 'rxjs';
import { Column, ColumnValues, RenameInputModel, RenameUpdateModel, Row, TableSection } from 'src/app/model/notes/notes-to-fs.model';
import { ApiService } from '../../../services/api.service';
import { SharedService } from '../../shared/shared.service';
import { NotesToFsService } from 'src/app/services/notes-to-fs.service';
import { CountableRenameComponent } from '../../remote-shared/countable-rename/countable-rename.component';

var useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

@Component({
    selector: "app-fs-table",
    templateUrl: './fs-table.component.html',
    styleUrls: ['./fs-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FsTableComponent implements OnInit, OnChanges, OnDestroy {
    @Input() isAutomated: boolean = false;
    @Input() htmlContent: any;
    @Input() isViewMode: boolean;
    @Output() updateTableData = new EventEmitter<any>();
    @Output() deleteSection = new EventEmitter<boolean>();
    @Output() updateTableEdit = new EventEmitter<boolean>();
    @ViewChild('deleteConfirmDialog') deleteConfirmDialog: TemplateRef<any>;
    @ViewChild('applyFormatDialog') applyFormatDialog: TemplateRef<any>;
    @ViewChild('focusInputField') focusInputField: ElementRef;
    @ViewChild('formatColDialog') formatColDialog: TemplateRef<any>;
    @ViewChild('addManualColMenu') addManualColMenu: MatMenuTrigger;
    @ViewChild('addColumnMenu') addColumnMenu: MatMenuTrigger;


    displayedColumns: string[] = ['hide', 'description', 'search', 'select'];
    dataSource = new MatTableDataSource<Row>();
    rowOptions = structuredClone(FS_TABLE_ROWS);
    // by default loading the firm templates options
    columnActions = structuredClone(FS_TABLE_ROW_DEFAULT);
    rowActionsData = structuredClone(FS_TABLE_ROW_ACTIONS);
    rowsData: Row[] = [];
    colCounter = 0;
    columnsData: Column[] = [];
    notManualCol = structuredClone(NOT_MANUAL_TABLE);
    columnIdToDelete = '';
    tableSectionData: TableSection;
    oldColumnName = '';
    editColumnId = '';
    initalData: TableSection;
    editRowId = '';
    oldRowDesc = '';
    rowIdToDelete = '';
    isHeaderHidden = false;
    viewModeRowsData: Row[] = [];
    viewModeDispCol: string[] = [];
    isInsideWorkbook = false;
    isMasterTab = false;
    trialBalanceData: any[] = [];
    leadSheetData: any[] = [];
    columnIdsOnly = [];
    currentDate: string;
    previousDate: string;
    search = 'Search';
    leadSheetDataSub: Subscription;
    trialBalanceDataSub: Subscription;
    lsIdsInTable: number[] = [];
    formatList = structuredClone(FS_TABLE_FORMATS);
    activeFormatList = [] // for active column actions like format
    activeRowIndex = null;
    public showMenuView: WritableSignal<boolean> = signal<boolean>(true);
    public renameObj: WritableSignal<RenameInputModel> = signal<RenameInputModel>(null);
    public columnOptions: WritableSignal<any> = signal<any>(structuredClone(FS_TABLE_COLUMNS));
    public readonly notes: typeof NOTES = NOTES;
    public readonly notes_messages: typeof NOTES_MESSAGES = NOTES_MESSAGES;

    constructor(
        private apiService: ApiService,
        public toaster: ToastrService,
        public dialog: MatDialog,
        public router: Router,
        public sharedService: SharedService,
        private notesService: NotesToFsService,
        private changeDetectorRefs: ChangeDetectorRef
    ) { }

    ngOnDestroy() {
        if (this.trialBalanceDataSub) {
            this.trialBalanceDataSub.unsubscribe();
        }
        if (this.leadSheetDataSub) {
            this.leadSheetDataSub.unsubscribe();
        }
    }

    ngOnInit() {
        this.trialBalanceDataSub = this.sharedService.notesTrialBalanceData$.subscribe(response => {
            if (response) {
                this.trialBalanceData = [...response];
            }
        });

        this.leadSheetDataSub = this.sharedService.notesLeadSheetData$.subscribe(response => {
            if (response) {
                this.leadSheetData = [...response];
            }
        });

        if (this.router.url === "/trial-balance-working-sheet/fs-notes") {
            this.isInsideWorkbook = true;
            this.columnActions.forEach(e => {
                if (e.key === 'mmyyformat') {
                    e.subMenu = [
                        { key: 'long', name: 'Long Format (Month DD, 20XX)', value: '' },
                        { key: 'short', name: 'Short Format (MON DD, 20XX)', value: '' },
                        { key: 'year', name: 'Year Only (20XX)', value: '' }
                    ]
                }
            })
        }
        this.initalData = this.htmlContent;
        this.columnsData = JSON.parse(JSON.stringify(this.initalData.columns));
        this.colCounter = this.columnsData.length;
        this.initializeData();
        this.setViewModeData();

        this.sharedService.selectedTab$.subscribe(response => {
            this.isMasterTab = (response === 1) ? true : false;
        });
    }

    initializeData() {
        this.columnsData = JSON.parse(JSON.stringify(this.initalData.columns));
        if (this.columnsData.length > 0) {
            this.displayedColumns = ['hide', 'description', 'search'];
            const newCols = this.columnsData.map(col => col.colId);
            this.displayedColumns = [...this.displayedColumns, ...newCols];
            this.columnIdsOnly = [...newCols];
            this.workbookFunctionality();
        } else {
            this.displayedColumns = ['hide', 'description', 'search', 'select'];
        }
        this.checkManualColumns();
        this.rowsData = (this.initalData.rows.length) ? JSON.parse(JSON.stringify(this.initalData.rows)) : [];
        const totaRowIndex = this.rowsData.findIndex(val => val.rowId === 'totalrow');
        const crossCheckRowIndex = this.rowsData.findIndex(val => val.rowId === 'crossCheck');
        if (!this.rowsData.length) {
            this.rowsData.push(this.createRowObject(this.apiService.makeId(4), '', false, this.columnsData));
        }
        if (totaRowIndex > -1) {
            this.rowsData.splice(totaRowIndex, 0, this.createRowObject('addrow', 'Add Row', false, []));
        } else {
            this.rowsData.push(this.createRowObject('addrow', 'Add Row', false, []));
            this.rowsData.push(this.createRowObject('totalrow', 'Total', false, []));
        }
        if (this.isInsideWorkbook && this.isAutomated && crossCheckRowIndex === -1) {
            this.rowsData.push(this.createRowObject('crossCheck', 'Difference', false, this.columnsData));
        }
        if (this.isAutomated && this.isInsideWorkbook) {
            const addRow = this.rowsData.findIndex(row => row.rowId === 'addrow') === -1 ? [] : this.rowsData.splice(this.rowsData.findIndex(row => row.rowId === 'addrow'), 1);
            const totalRow = this.rowsData.findIndex(row => row.rowId === 'totalrow') === -1 ? [] : this.rowsData.splice(this.rowsData.findIndex(row => row.rowId === 'totalrow'), 1);
            const crossCheck = this.rowsData.findIndex(row => row.rowId === 'crossCheck') === -1 ? [] : this.rowsData.splice(this.rowsData.findIndex(row => row.rowId === 'crossCheck'), 1);
            addRow.length ? this.rowsData.push(addRow[0]) : '';
            totalRow.length ? this.rowsData.push(totalRow[0]) : '';
            crossCheck.length ? this.rowsData.push(crossCheck[0]) : '';
        }
        this.dataSource = new MatTableDataSource(this.rowsData);
        this.findLsIdsInTable();
        this.validationForMaxSubHeader('subheader', this.rowsData.filter(e => e.rowId === 'subheader').length >= 3);
        this.calculateSubTotalRows();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.htmlContent) {
            if (changes.htmlContent.currentValue) {
                this.initalData = JSON.parse(JSON.stringify(changes.htmlContent.currentValue));
                this.initializeData();
            }
        }
        if (changes.isViewMode) {
            if (this.initalData) {
                this.initalData.isEdit = changes.isViewMode.currentValue;
                this.setViewModeData();
            }
        }
    }

    getFormattedDate(date: string, colIndex: number, isCurrentYear?: boolean) {
        if (this.columnsData[colIndex]) {
            let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            if (this.columnsData[colIndex]?.format === 'short') {
                months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            }
            const dateObj = new Date(date);
            if (isCurrentYear) {
                return this.columnsData[colIndex].format === 'year' ? `${dateObj.getFullYear()}` : `${months[dateObj.getMonth()]} ${dateObj.getDate().toString().padStart(2, "0")}, ${dateObj.getFullYear()}`;
            } else {
                return this.columnsData[colIndex].format === 'year' ? `${dateObj.getFullYear() - 1}` : `${months[dateObj.getMonth()]} ${dateObj.getDate().toString().padStart(2, "0")}, ${dateObj.getFullYear() - 1}`;
            }
        }
    }

    openInEditMode() {
        if ((!this.isMasterTab && !this.isAutomated) || (this.isInsideWorkbook)) {
            this.initalData.isEdit = true;
            this.updateTableEdit.emit(this.initalData.isEdit);
        }
    }

    setViewModeData() {
        this.editRowId = '';
        this.editColumnId = '';
        this.viewModeDispCol = ['description', 'search'];
        if (this.columnsData.length) {
            const colIds = this.columnsData.map(col => col.colId);
            this.viewModeDispCol = [...this.viewModeDispCol, ...colIds];
            this.columnIdsOnly = [...colIds];
        }
        this.viewModeRowsData = this.rowsData.filter(row => row.rowId !== 'addrow');
    }

    emitTableSectiondata(isApplyAll?: boolean, isRefresh?: boolean) {
        this.tableSectionData = {} as TableSection;
        this.tableSectionData.id = this.initalData.id;
        this.tableSectionData.type = this.initalData.type;
        this.tableSectionData.isEdit = this.initalData.isEdit;
        this.tableSectionData.isHeaderHidden = this.initalData.isHeaderHidden;
        this.tableSectionData.isHeaderLine = this.initalData.isHeaderLine;
        this.tableSectionData.columns = JSON.parse(JSON.stringify(this.columnsData));
        this.tableSectionData.rows = JSON.parse(JSON.stringify(this.dataSource.data.filter(row => !(['addrow'].includes(row.rowId)))));
        this.tableSectionData.subtotalCount = this.dataSource.data.filter(row => (row.rowId === 'subtotal')).length;
        this.tableSectionData.jsonUpdated = 'release_28';
        this.updateTableData.emit({ selectedTable: this.tableSectionData, isUpdate: isApplyAll, isRefresh: isRefresh });
    }

    createRowObject(rowId: string, name: string, isHide: boolean, columns: Column[]): Row {
        const colvalues: ColumnValues[] = [];

        columns.forEach(val => {
            const obj: ColumnValues = {
                colId: val.colId,
                colValue: 0
            }
            colvalues.push(obj);
        })

        const row: Row = {
            rowId,
            name,
            isHide,
            values: JSON.parse(JSON.stringify(colvalues)),
            isLineHide: (rowId === 'subheader') ? true : false,
            styles: (rowId === 'subtotal') ? ['bold'] : []
        }
        return row;
    }

    addColumn(column: any, isManualCoulmn: boolean = false) {
        if (this.displayedColumns.includes('select')) {
            const ind = this.displayedColumns.indexOf('select');
            if (ind > -1) {
                this.displayedColumns.splice(ind, 1);
            }
        }
        let columnObject: ColumnValues = {} as ColumnValues;
        const colId = (column.key === 'manual') ? this.apiService.makeId(4) : column.key;
        columnObject = { colId: colId, colValue: 0 };

        if (this.displayedColumns.includes('total')) {
            const index = this.displayedColumns.length - 1;
            this.displayedColumns.splice(index, 0, colId);
            const colIndex = this.columnsData.length - 1;
            // this.columnsData.splice(colIndex, 0, this.createColumnObject(colId, column.value));
            if (this.columnsData.findIndex(r => r.colId === 'priorYear') >= 0 && column.key === 'currYear') {
                this.displayedColumns.splice(this.columnsData.findIndex(r => r.colId === 'priorYear'), 0, colId);
                this.columnsData.splice(this.columnsData.findIndex(r => r.colId === 'priorYear'), 0, this.createColumnObject(colId, column.value));
            } else {
                this.columnsData.splice(colIndex, 0, this.createColumnObject(colId, column.value));
            }
            this.colCounter = this.columnsData.length;
        } else {
            if (this.columnsData.findIndex(r => r.colId === 'priorYear') >= 0 && column.key === 'currYear') {
                this.displayedColumns.splice(this.columnsData.findIndex(r => r.colId === 'priorYear'), 0, colId);
                this.columnsData.splice(this.columnsData.findIndex(r => r.colId === 'priorYear'), 0, this.createColumnObject(colId, column.value));
            } else {
                this.displayedColumns.push(colId);
                this.columnsData.push(this.createColumnObject(colId, column.value));
            }
            this.colCounter = this.columnsData.length;
        }
        if (column.key === 'manual') {
            this.colCounter = this.columnsData.length;
            // this.colCounter++;
        }

        this.columnIdsOnly = this.columnsData.map(col => col.colId);

        this.rowsData.forEach(val => {
            if (column.key === 'manual' && val.trialBalanceId) {
                delete val.trialBalanceId;
            }
            if (val.rowId !== 'addrow') {
                val.values.push(columnObject);
            }
        });
        this.dataSource = new MatTableDataSource(this.rowsData);
        this.workbookFunctionality();
        this.calculateSubTotalRows(true);
    }

    createColumnObject(colId: string, name: string): Column {
        const currIndex = this.columnsData.findIndex(ele => ele.colId === 'currYear');
        const prevIndex = this.columnsData.findIndex(ele => ele.colId === 'priorYear');
        let formatted: 'short' | 'long' | 'year' = 'long';
        let yearValue = 'Month DD, 20XX';
        if (this.columnsData[currIndex] && !(currIndex === -1)) {
            formatted = this.columnsData[currIndex].format;
            yearValue = this.columnsData[currIndex].formatValue;
        }
        if (this.columnsData[prevIndex] && !(prevIndex === -1)) {
            formatted = this.columnsData[prevIndex].format;
            yearValue = this.columnsData[prevIndex].formatValue;
        }
        const colObj: Column = {
            colId,
            name,
            isHide: false,
            format: formatted,
            styles: colId === 'currYear' ? ['bold'] : [],
            extName: null,
            formatValue: (colId === 'currYear' || colId === 'priorYear') ? yearValue : null
        }
        return colObj;
    }

    isColDisabled(column: string): boolean {
        if (column === 'priorYear') {
            return (this.displayedColumns.includes('priorYear') || this.isManualColExists()) ? true : false;
        } else if (column === 'currYear') {
            return (this.displayedColumns.includes('currYear') || this.isManualColExists()) ? true : false;
        } else if (column === 'manual') {
            return (this.displayedColumns.includes('priorYear') || this.displayedColumns.includes('currYear')) ? true : false;
        } else if (column === 'total' && (this.displayedColumns.includes('total'))) {
            return true;
        }
        return false;
    }

    isManualColExists(): boolean {
        for (let i = 0; i < this.displayedColumns.length; i++) {
            if (!this.notManualCol.includes(this.displayedColumns[i])) {
                return true;
            }
        }
        return false;
    }

    public executeColumnActions(event: Event, column: Column, action: string, option?: any) {
        if (action === 'rename') {
            this.showMenuView.set(false);
            // this.editColumnId = column.colId;
            this.oldColumnName = (this.isAutomated) ? column.extName : column.name;
            const obj: RenameInputModel = new RenameInputModel();
            obj.name = this.oldColumnName;
            obj.id = column?.colId;
            obj.isBolded = column?.styles?.includes('bold');
            obj.isSubheader = false;
            this.renameObj.set(obj);
            this.openRenameComp(event);
        } else {
            this.applyColAction(column, action, option);
        }
    }

    public updateRenameAction(info: RenameUpdateModel) {
        this.renameObj.set(null);
        this.showMenuView.set(false);
        this.addColumnMenu?.closeMenu();
        if (info?.isUpdate && info.updateId.includes('_subheader_')) {
            this.dataSource.data[info.updateId.split('_subheader_')[0]].values.forEach((col, ind) => {
                if (ind == info.updateId.split('_subheader_')[1]) {
                    col.styles = info.isBolded ? ['bold'] : [];
                    col.extName = info.updatedName;

                }
            });
            this.messageByType(this.notes_messages.SUB_HEADER_UPDATED, 'success');
            this.emitTableSectiondata();
        } else {
            if (info?.isUpdate && info?.updateId) {
                const columnIndex = this.columnsData.findIndex((column) => column.colId === info?.updateId);
                if (columnIndex !== -1) {
                    if (this.columnsData[columnIndex]?.styles) {
                        const boldIndex = this.columnsData[columnIndex]?.styles.findIndex((value) => value === 'bold');
                        if (boldIndex === -1 && info.isBolded) {
                            this.columnsData[columnIndex].styles.push('bold');
                        } else if (!info.isBolded) {
                            this.columnsData[columnIndex].styles.splice(boldIndex, 1);
                        }
                    } else {
                        this.columnsData[columnIndex].styles = info.isBolded ? ['bold'] : [];
                    }

                    this.columnsData[columnIndex] = this.isAutomated ? { ...this.columnsData[columnIndex], extName: info?.updatedName } : { ...this.columnsData[columnIndex], name: info?.updatedName };

                    this.changeDetectorRefs.detectChanges();
                    this.messageByType(this.notes_messages.COLUMN_UPDATED, 'success');
                    this.emitTableSectiondata(false, true);
                }

            }
        }

    }

    applyColAction(column: Column, action: string, option?: any) {
        switch (action) {
            case 'rename':
                this.editColumnId = column.colId;
                this.oldColumnName = (this.isAutomated) ? column.extName : column.name;
                this.focusInput();
                break;
            case 'delete':
                const config: MatDialogConfig<any> = new MatDialogConfig<any>();
                config.data = {
                    subHeader: 'Deleting the column will permanently erase the data',
                    text: "Do you wish to proceed with the Deletion?",
                    toDelete: 'Column(s)',
                    cancelText: 'Cancel',
                    doneText: 'Delete'
                };
                config.disableClose = true;
                const dialogRef = this.dialog.open(this.deleteConfirmDialog, config);
                dialogRef.afterClosed().subscribe(result => {
                    if (result && option?.key === 'one') {
                        this.columnIdToDelete = column.colId;
                        this.deleteColumn();
                        this.messageByType(this.notes_messages.COLUMN_DELETED, 'success');
                    } else if (result && option?.key === 'all') {
                        const colsData = JSON.parse(JSON.stringify(this.columnsData))
                        colsData.forEach(e => {
                            this.columnIdToDelete = e.colId;
                            this.deleteColumn();
                        });
                        this.messageByType(this.notes_messages.COLUMN_DELETED, 'success');
                    } else {
                        this.cancelDelete();
                    }
                });
                break;
            case 'format':
                this.activeFormatList = [];
                this.activeFormatList.push(column.colId);
                this.activeFormatList.push('bold');
                this.activeFormatList.push('italic');
                this.formatList.forEach(e => {
                    e.active = false;
                    column?.styles?.forEach(c => {
                        if (e.value === c) {
                            e.active = true;
                        }
                    })
                });
                break;
            case 'mmyyformat':
                if (this.isInsideWorkbook) {
                    const config: MatDialogConfig<any> = new MatDialogConfig<any>();
                    config.data = {};
                    config.disableClose = true;
                    const dialogRef = this.dialog.open(this.applyFormatDialog, config);
                    dialogRef.afterClosed().subscribe(result => {
                        if (result === 'one') {
                            this.upToOnlySelectedTable(option);
                        } else if (result === 'all') {
                            this.upToOnlySelectedTable(option, true);
                        }
                    });
                } else {
                    this.upToOnlySelectedTable(option);
                }
                break;
            default: break;
        }
    }

    cancelRename(column: Column) {
        if (this.isAutomated) {
            column.extName = this.oldColumnName;
        } else {
            column.name = this.oldColumnName;
        }
        this.editColumnId = '';
    }

    renameColumn(column: Column) {
        this.editColumnId = '';
        this.columnsData.forEach(c => {
            if (c.colId === column.colId) {
                if (this.isAutomated) {
                    c.extName = column.extName;
                } else {
                    c.name = column.name;
                }
            }
        });
        this.emitTableSectiondata();
    }

    deleteColumn() {
        const displayIndex = this.displayedColumns.indexOf(this.columnIdToDelete);
        if (displayIndex > -1) {
            this.displayedColumns.splice(displayIndex, 1);
        }
        const colIndex = this.columnsData.findIndex(val => val.colId === this.columnIdToDelete);
        if (colIndex > -1) {
            this.columnsData.splice(colIndex, 1);
        }
        this.removeColumnfromRowValues(this.columnIdToDelete);
        this.colCounter = this.columnsData.length;
        if (this.colCounter > 0 && this.columnIdToDelete !== 'total') {
            this.colCounter--;
        }
        this.columnIdToDelete = '';
        // this.emitTableSectiondata();
        if (!this.columnsData.length) {
            this.displayedColumns.push('select');
        }
        this.columnIdsOnly = this.columnsData.map(col => col.colId);
        this.checkManualColumns();
    }

    removeColumnfromRowValues(columnId: string) {
        this.rowsData.forEach(row => {
            const colIndex = row.values.findIndex(val => val.colId === columnId);
            if (colIndex > -1) {
                row.values.splice(colIndex, 1);
            }
        })
    }

    isColActionDisabled(column: string, action: string): boolean {
        if (['priorYear', 'currYear', 'total'].includes(column) && action === 'rename') {
            return true;
        }
        return false;
    }

    hideColumnHeaderAndSubHeader(type: string, index: number) {
        if (type === 'header') {
            this.initalData.isHeaderHidden = !this.initalData.isHeaderHidden;
            if (this.dataSource.data.filter(row => row.rowId === 'subheader').length) {
                this.dataSource.data.forEach(row => {
                    if (row.rowId === 'subheader') {
                        row.isHide = this.initalData.isHeaderHidden;
                    }
                });
                this.messageByType(this.notes_messages.HEADER_AND_SUB_HEADER_UPDATED, 'success');

            } else {
                this.messageByType(this.notes_messages.HEADER_UPDATED, 'success');
            }
        } else {
            this.dataSource.data[index].isHide = !this.dataSource.data[index].isHide;
            this.messageByType(this.notes_messages.SUB_HEADER_UPDATED, 'success');
        }

    }

    addRow(index: number) {
        const newId = this.apiService.makeId(4);
        this.rowsData.splice(index, 0, this.createRowObject(newId, '', false, this.columnsData));
        if (this.isInsideWorkbook) {
            this.editRowId = newId;
        }
        this.dataSource = new MatTableDataSource(this.rowsData);
        this.emitTableSectiondata();
    }

    deleteRow() {
        const index = this.rowsData.findIndex(row => row.rowId === this.rowIdToDelete);
        if (index > -1) {
            if (this.isAutomated && this.rowsData[index].trialBalanceId) {
                this.rowsData[index].isDeleted = true;
            } else {
                this.rowsData.splice(index, 1);
            }
            this.dataSource = new MatTableDataSource(this.rowsData);
            this.findLsIdsInTable();
            // this.emitTableSectiondata();
            this.calculateSubTotalRows();
        }
    }

    cancelDelete() {
        this.columnIdToDelete = '';
        this.rowIdToDelete = '';
    }

    hideRow(event: any, row: Row, index: number) {
        this.dataSource.data[index].isHide = !this.dataSource.data[index].isHide;
        if (row.rowId === 'subheader') {
            this.messageByType(this.notes_messages.SUB_HEADER_UPDATED, 'success');
            this.initalData.isHeaderLine = (this.rowsData.filter(e => (e.rowId === 'subheader')).length === this.rowsData.filter(e => (e.rowId === 'subheader' && e.isHide)).length) ? true : this.initalData.isHeaderLine;
        }
    }

    applyRowAction(row: Row, action: string, rowIndex: number) {
        switch (action) {
            case 'edit': this.editRowId = row.rowId;
                this.oldRowDesc = row.name;
                this.focusInput();
                break;
            case 'hide':
                this.dataSource.data[rowIndex].isDescHide = !this.dataSource.data[rowIndex].isDescHide;
                this.messageByType(this.notes_messages.ROW_UPDATED, 'success');
                this.emitTableSectiondata();
                break;
            case 'delete':
                let deleteText = {};
                if (row.rowId === 'textonly') {
                    deleteText = {
                        subHeader: 'Removing the text-only row will result in the permanent deletion of the associated subrows',
                        text: "Do you wish to proceed with the Deletion?",
                        toDelete: 'Row(s)',
                        cancelText: 'Cancel',
                        doneText: 'Delete'
                    };

                } else {
                    deleteText = {
                        subHeader: 'Deleting the row would remove the data permanently, Do you want to delete row?',
                        text: "Do you wish to proceed with the Deletion?",
                        toDelete: 'Row(s)',
                        cancelText: 'Cancel',
                        doneText: 'Delete'
                    };
                }
                const dialogRef = this.dialog.open(this.deleteConfirmDialog, {
                    data: deleteText
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        if (this.dataSource.data.find(e => e.isChecked)) {
                            let indexes = [];
                            this.dataSource.data.forEach((d, indx) => {
                                if (d.isChecked) {
                                    indexes.push(indx);
                                    if (d.rowId === 'textonly') {
                                        let count = indx + 1;
                                        while (count < this.dataSource.data.length) {
                                            if (this.dataSource.data[count].rowId.includes('_subrow')) {
                                                indexes.push(count);
                                                count++;
                                            } else {
                                                count = this.dataSource.data.length + 1;
                                            }
                                        }
                                    }
                                }
                            });
                            this.deleterowsWithSubtotal(this.rowsData.filter((value, index) => !indexes.includes(index)));
                        } else if (row.rowId === 'textonly') {
                            let indexes = [];
                            indexes.push(rowIndex);
                            let count = rowIndex + 1;
                            while (count < this.rowsData.length) {
                                if (this.rowsData[count].rowId.includes('_subrow')) {
                                    indexes.push(count);
                                    count++;
                                } else {
                                    count = this.rowsData.length + 1;
                                }
                            }
                            const rowsdata = this.rowsData.filter((value, index) => !indexes.includes(index));
                            this.rowsData = rowsdata;
                            this.dataSource = new MatTableDataSource(this.rowsData);
                            this.findLsIdsInTable();
                            // this.emitTableSectiondata();
                            this.messageByType('Row{s} deleted successfully', 'success');
                        } else {
                            this.deleterowsWithSubtotal(this.rowsData.filter((value, index) => !(rowIndex === index)), row);
                        }
                    }
                });

                break;
            case 'format':
                this.activeRowIndex = rowIndex;
                if (row.rowId === 'subheader') {
                    this.formatHeaderAndSubHeader(row, 'border-bottom', rowIndex);
                } else {
                    if (this.dataSource.data.find(e => e.isChecked)) {
                        this.activeFormatList = ['row', 'italic', 'bold', 'underline', 'border-bottom', 'border-top'];
                        this.formatList.forEach(s => {
                            s.active = false;
                        });
                    } else {
                        let formatOptions = ['row', row.rowId, 'italic', 'bold', 'underline', 'border-bottom', 'border-top'];
                        if (this.dataSource.data.findIndex(r => (row.rowId === r.rowId)) === 0 || (this.dataSource.data[this.dataSource.data.findIndex(r => (row.rowId === r.rowId)) - 1] &&
                            this.dataSource.data[this.dataSource.data.findIndex(r => (row.rowId === r.rowId)) - 1].rowId === 'subheader')) {
                            formatOptions.pop();
                        }
                        this.activeFormatList = formatOptions;
                        this.formatList.forEach(s => {
                            s.active = false;
                            row?.styles && row.styles.forEach(e => {
                                if (s.value === e) {
                                    s.active = true;
                                }
                            });
                        });
                    }
                }
                break;
            case 'subrow':
                let count = rowIndex + 1;
                while (count < this.rowsData.length) {
                    if (this.rowsData[count].rowId.includes('_subrow')) {
                        count++;
                    } else {
                        rowIndex = count;
                        count = this.rowsData.length + 1;
                    }
                }
                this.rowsData.splice(rowIndex, 0, this.createRowObject(this.apiService.makeId(4) + '_subrow', '', false, this.columnsData));
                this.dataSource = new MatTableDataSource(this.rowsData);
                this.emitTableSectiondata();
                break;
            default: break;
        }
    }

    cancelRowDescChange(row: Row) {
        row.name = this.oldRowDesc;
        this.editRowId = '';
    }

    changeRowDesc(row: Row) {
        this.editRowId = '';
        this.emitTableSectiondata();
    }

    drop(event: CdkDragDrop<Row[]>) {
        this.rowsData = structuredClone(this.dataSource.data);
        if (this.isAutomated && this.isNoDifference(this.rowsData[this.rowsData.length - 1])) {
            event.currentIndex = event.currentIndex - 1;
            event.previousIndex = event.previousIndex - 1;
        }
        if (event.item.data.rowId === 'addrow' || event.item.data.rowId === 'totalrow' || event.item.data.rowId === 'crossCheck' ||
            (event.currentIndex === (this.rowsData.length - 1)) || this.rowsData[event.currentIndex].rowId === 'addrow' || this.rowsData[event.currentIndex].rowId === 'subheader' ||
            (this.rowsData[event.currentIndex].rowId === 'subtotal' && this.rowsData[event.previousIndex].rowId === 'subtotal') ||
            (this.rowsData[event.currentIndex].rowId === 'subtotal' && (this.rowsData[event.previousIndex - 1] && this.rowsData[event.previousIndex - 1].rowId === 'subheader')) ||
            (event.currentIndex === event.previousIndex && this.rowsData[event.currentIndex + 1].rowId === 'subtotal')) {
            return;
        }

        if (this.rowsData[event.previousIndex].rowId === 'textonly') {
            let listOfSubRows = [];
            this.rowsData[event.previousIndex].isDeleted = true;
            listOfSubRows.push(this.rowsData[event.previousIndex]);
            let count = event.previousIndex + 1;
            while (count < this.rowsData.length) {
                if (this.rowsData[count].rowId.includes('_subrow')) {
                    listOfSubRows.push(this.rowsData[count]);
                    this.rowsData[count].isDeleted = true;
                    count++;
                } else {
                    count = this.rowsData.length + 1;
                }
            }

            const textOnlyRows = structuredClone(listOfSubRows);
            textOnlyRows.forEach(e => {
                e.isDeleted = false;
            });
            this.rowsData.splice(event.currentIndex, 0, ...textOnlyRows);
            this.findSubtotalBeside(this.rowsData.filter((value) => !value?.isDeleted));
        } else if (this.rowsData[event.previousIndex].rowId === 'subtotal') {
            if ((this.rowsData[event.currentIndex - 1] && (this.rowsData[event.currentIndex - 1].rowId === 'subheader'))
                || (event.currentIndex === 0) || (this.rowsData[event.previousIndex].rowId === 'subtotal' &&
                    ((this.rowsData[event.currentIndex - 1] && this.rowsData[event.currentIndex - 1].rowId === 'textonly')))) {
                this.messageByType('please drop it below rows', 'error');
            } else {
                this.rowsData[event.previousIndex].rowId = this.rowsData[event.previousIndex].rowId === 'subtotal' ? this.rowsData[event.previousIndex].rowId :
                    (this.rowsData[event.currentIndex].rowId.includes('_subrow') ? (this.dataSource.data[event.previousIndex].rowId.split('_')[0] + '_subrow') : this.dataSource.data[event.previousIndex].rowId.split('_')[0]);
                this.rowsData.splice(event.currentIndex, 0, (this.rowsData.splice(event.previousIndex, 1)[0]));
                this.findSubtotalBeside(this.rowsData);
            }

        } else if (this.rowsData[event.currentIndex].rowId.includes('_subrow')) {
            if ((this.rowsData[event.previousIndex].rowId === 'subtotal' && this.rowsData[event.currentIndex - 1].rowId === 'textonly')) {

            } else if (this.rowsData[event.previousIndex - 1] && this.rowsData[event.previousIndex - 1].rowId === 'textonly' &&
                this.rowsData[event.previousIndex + 1] && this.rowsData[event.previousIndex + 1].rowId === 'subtotal'
            ) {
                this.rowsData[event.previousIndex].rowId = this.rowsData[event.currentIndex].rowId.includes('_subrow') ?
                    this.rowsData[event.previousIndex].rowId.split('_')[0] + '_subrow' : this.rowsData[event.previousIndex].rowId.split('_')[0];
                this.checkgeneralRowWithSubtotal(null, null, 'drop', event);
            } else {
                if ((this.rowsData[event.previousIndex + 1].rowId === 'subtotal') && (event.previousIndex === 0 || (this.rowsData[event.previousIndex - 1] && this.rowsData[event.previousIndex - 1].rowId === 'subheader'))) {
                    this.rowsData[event.previousIndex].rowId = this.rowsData[event.previousIndex].rowId.split('_')[0] + '_subrow';
                    this.checkgeneralRowWithSubtotal(null, null, 'drop', event);
                } else {
                    this.rowsData[event.previousIndex].rowId = this.dataSource.data[event.previousIndex].rowId === 'subtotal' ? this.dataSource.data[event.previousIndex].rowId : this.dataSource.data[event.previousIndex].rowId.split('_')[0] + '_subrow';
                    this.rowsData.splice(event.currentIndex, 0, (this.rowsData.splice(event.previousIndex, 1)[0]));
                    this.findSubtotalBeside(this.rowsData);
                }
            }
        } else if (this.rowsData[event.currentIndex].rowId === 'textonly') {
            if ((this.rowsData[event.previousIndex + 1].rowId === 'subtotal') && (event.previousIndex === 0 || (this.rowsData[event.previousIndex - 1] && this.rowsData[event.previousIndex - 1].rowId === 'subheader'))) {
                this.rowsData[event.previousIndex].rowId = this.rowsData[event.previousIndex].rowId.split('_')[0] + '_subrow';
                this.checkgeneralRowWithSubtotal(null, null, 'drop', event);
            } else if (this.rowsData[event.previousIndex - 1] && this.rowsData[event.previousIndex - 1].rowId === 'textonly' &&
                this.rowsData[event.previousIndex + 1] && this.rowsData[event.previousIndex + 1].rowId === 'subtotal'
            ) {
                this.rowsData[event.previousIndex].rowId = this.rowsData[event.currentIndex].rowId.includes('_subrow') ?
                    this.rowsData[event.previousIndex].rowId.split('_')[0] + '_subrow' : this.rowsData[event.previousIndex].rowId.split('_')[0];
                this.checkgeneralRowWithSubtotal(null, null, 'drop', event);
            } else {
                this.rowsData[event.previousIndex].rowId = this.rowsData[event.previousIndex].rowId.split('_')[0] + '_subrow';
                this.rowsData.splice(event.currentIndex, 0, (this.rowsData.splice(event.previousIndex, 1)[0]));
                this.findSubtotalBeside(this.rowsData);
            }

        } else {
            if ((this.rowsData[event.previousIndex + 1].rowId === 'subtotal') && (event.previousIndex === 0 || (this.rowsData[event.previousIndex - 1] && this.rowsData[event.previousIndex - 1].rowId === 'subheader'))) {
                this.checkgeneralRowWithSubtotal(null, null, 'drop', event);
            } else if ((this.rowsData[event.previousIndex + 1].rowId === 'subtotal') && (event.previousIndex === 0 || (this.rowsData[event.previousIndex - 1] && this.rowsData[event.previousIndex - 1].rowId === 'textonly'))) {
                this.rowsData[event.previousIndex].rowId = this.rowsData[event.currentIndex].rowId.includes('_subrow') ? this.rowsData[event.previousIndex].rowId.split('_')[0] + '_subrow' : this.rowsData[event.previousIndex].rowId.split('_')[0];
                this.checkgeneralRowWithSubtotal(null, null, 'dropInCurrentIndex', event);
            } else if (this.rowsData[event.currentIndex - 1] && this.rowsData[event.currentIndex - 1].rowId === 'textonly' &&
                !(this.rowsData[event.previousIndex].rowId === 'textonly' || this.rowsData[event.previousIndex].rowId === 'subtotal' || this.rowsData[event.previousIndex].rowId.includes('_subrow'))) {
                this.rowsData[event.previousIndex].rowId = this.rowsData[event.previousIndex].rowId.split('_')[0] + '_subrow';
                this.rowsData.splice(event.currentIndex, 0, (this.rowsData.splice(event.previousIndex, 1)[0]));
                this.findSubtotalBeside(this.rowsData);
            } else {
                const highestIndex = this.dataSource.data.length - 3;
                if (event.currentIndex > highestIndex) {
                    event.currentIndex = highestIndex;
                }
                this.rowsData[event.previousIndex].rowId = this.dataSource.data[event.previousIndex].rowId === 'subtotal' ? this.dataSource.data[event.previousIndex].rowId : this.dataSource.data[event.previousIndex].rowId.split('_')[0];
                this.rowsData.splice(event.currentIndex, 0, (this.rowsData.splice(event.previousIndex, 1)[0]));
                this.findSubtotalBeside(this.rowsData);
            }

        }
        setTimeout(() => {
            this.calculateSubTotalRows();
        }, 10);
    }

    checkgeneralRowWithSubtotal(filterRows: any, rowsdata: any, isFrom: string, event?: any) {
        const config: MatDialogConfig<any> = new MatDialogConfig<any>();
        config.data = {
            subHeader: 'After deletion or movement, the subtotal row will be automatically removed since there are no items for calculation',
            text: "Are you sure you want to proceed?",
            toDelete: 'Subtotal(s)',
            cancelText: 'No',
            doneText: 'Yes'
        };
        config.disableClose = true;
        const dialogRef = this.dialog.open(this.deleteConfirmDialog, config);
        dialogRef.afterClosed().subscribe(result => {
            if (result && (isFrom === 'multipleDeletion')) {
                filterRows.forEach((e, i) => {
                    if ((e.rowId === 'subtotal' && i == 0) || (filterRows[i + 1] && e.rowId === 'subtotal' && filterRows[i + 1].rowId === 'subtotal')) {
                        rowsdata.splice(e.index, 1);
                    }
                });
                this.rowsData = structuredClone(rowsdata);
                this.dataSource = new MatTableDataSource(this.rowsData);
                this.calculateSubTotalRows();
            } else if (result && isFrom === 'drop') {
                this.rowsData.splice(event.currentIndex - 1, 0, (this.rowsData.splice(event.previousIndex, 2)[0]));
                this.findSubtotalBeside(this.rowsData);
            } else if (result && isFrom === 'dropInCurrentIndex') {
                this.rowsData.splice(event.currentIndex, 0, (this.rowsData.splice(event.previousIndex, 2)[0]));
                this.findSubtotalBeside(this.rowsData);
            }
        });
    }

    focusInput() {
        setTimeout(() => {
            if (this.focusInputField) {
                this.focusInputField.nativeElement.focus();
            }
        }, 0);
    }

    deleteTable() {
        this.deleteSection.emit(true);
    }

    roundUpNegativeValue(val: number): number {
        val = val * -1;
        val = Math.round(val);
        val = val * -1;
        return val;
    }

    setColvalue(columnValues: ColumnValues[], colId: string, docId: string) {
        let value = parseFloat((document.getElementById(docId) as HTMLInputElement).value);
        const index = columnValues.findIndex(col => col.colId === colId);
        if (index > -1) {
            columnValues[index].colValue = isNaN(value) ? 0 : ((value < 0) ? this.roundUpNegativeValue(value) : Math.round(value));
        }
        if (this.isAutomated && ["cost", "amortization"].includes(colId)) {
            const indexCY = columnValues.findIndex(col => col.colId === "currYear");
            const indexCost = columnValues.findIndex(col => col.colId === "cost");
            const indexAmort = columnValues.findIndex(col => col.colId === "amortization");
            columnValues[indexCY].colValue = (columnValues[indexCost].colValue - columnValues[indexAmort].colValue);
        }
        // this.emitTableSectiondata();
        this.rowsData = structuredClone(this.dataSource.data)
        this.calculateSubTotalRows()
    }

    getColValue(columnValues: ColumnValues[], colId: string): number {
        const index = columnValues.findIndex(col => col.colId === colId);
        return (index > -1) ? (columnValues[index].colValue + '' === '-' ? 0 : columnValues[index].colValue) : 0;
    }

    getTotalColValue(colId: string): number {
        let cols: ColumnValues[] = []
        this.dataSource.data.forEach(row => {
            if (row.values.length && !row.isHide && !row.isDeleted && !(row.rowId === 'subtotal')) {
                cols.push(row.values.filter(val => val.colId === colId)[0]);
            }
        });
        const values = cols.map(col => {
            return (col && col.colValue && typeof col.colValue === 'number') ? col.colValue : 0;
        });
        return values.reduce((a, b) => a + b, 0);
    }

    getRowTotalValue(columnValues: ColumnValues[]): number {
        const values = columnValues.map(col => {
            return (col && col.colValue && typeof col.colValue === 'number') ? col.colValue : 0;
        });
        return values.reduce((a, b) => a + b, 0);
    }

    getGrandTotalValue() {
        const values: number[] = [];
        this.dataSource.data.forEach(row => {
            if (row.values.length && !row.isHide && !(row.rowId === 'subtotal')) {
                row.values.forEach(col => {
                    const val = (col && col.colValue && typeof col.colValue === 'number') ? col.colValue : 0;
                    values.push(val);
                })
            }
        });
        return values.reduce((a, b) => a + b, 0);
    }

    showFormattedNumber(data: number | string) {
        if (typeof data === 'number') {
            if (data > 0) {
                return data.toLocaleString('en-us');
            } else if (data < 0) {
                const absoluteNumber = data * -1;
                const formatted = absoluteNumber.toLocaleString('en-US');
                return '(' + formatted + ')';
            } else {
                return this.initalData.isEdit ? 0 : '-';
            }
        } else {
            return data;
        }
    }

    setDescription(row: Row) {
        if (this.isAutomated && this.isInsideWorkbook && row.trialBalanceId) {
            const ind = this.leadSheetData.findIndex(e => e.trialBalanceId === row.trialBalanceId);
            if (ind > -1) {
                if (this.leadSheetData[ind].accountName !== row.name) {
                    row.isDescManuallyChanged = true;
                }
            }
        }
        this.emitTableSectiondata();
    }

    editRow(row: Row) {
        this.editRowId = row.rowId;
        this.oldRowDesc = row.name;
        // this.focusInput();
    }

    setTrialIdToRow(trial: any, row: Row) {
        row.trialBalanceId = trial.trialBalanceId;
        row.name = trial.description;
        const currYearIndex = row.values.findIndex(ele => ele.colId === 'currYear');
        const prevYearIndex = row.values.findIndex(ele => ele.colId === 'priorYear');
        if (currYearIndex > -1) {
            row.values[currYearIndex].colValue = trial.currYearValue;
        }
        if (prevYearIndex > -1) {
            row.values[prevYearIndex].colValue = trial.prevYearValue;
        }
        // this.emitTableSectiondata();
        this.rowsData = this.dataSource.data;
        this.calculateSubTotalRows();
    }

    setLeadSheetRow(ls: any, row: Row) {
        const ind = this.rowsData.findIndex(e => e.trialBalanceId === ls.trialBalanceId);
        if (ind > -1) {
            this.rowsData.splice(ind, 1);
        }
        row.trialBalanceId = ls.trialBalanceId;
        row.name = ls.accountName;
        row.isDescManuallyChanged = false;
        const costIndex = row.values.findIndex(ele => ele.colId === 'cost');
        const amortizationIndex = row.values.findIndex(ele => ele.colId === 'amortization');
        const currYearIndex = row.values.findIndex(ele => ele.colId === 'currYear');
        const priorYearIndex = row.values.findIndex(ele => ele.colId === 'priorYear');
        if (costIndex > -1) {
            row.values[costIndex].tbValue = ls.cost;
            row.values[costIndex].colValue = ((ls.cost || 0) < 0) ? this.roundUpNegativeValue(ls.cost || 0) : Math.round(ls.cost || 0);
        }
        if (amortizationIndex > -1) {
            row.values[amortizationIndex].tbValue = ls.amortizationFinalAmount;
            row.values[amortizationIndex].colValue = ((ls.amortizationFinalAmount || 0) < 0) ? this.roundUpNegativeValue((ls.amortizationFinalAmount || 0)) : Math.round((ls.amortizationFinalAmount || 0));
        }
        if (currYearIndex > -1) {
            row.values[currYearIndex].tbValue = ls.netBookValue;
            row.values[currYearIndex].colValue = ((ls.netBookValue || 0) < 0) ? this.roundUpNegativeValue(ls.netBookValue || 0) : Math.round(ls.netBookValue || 0);
        }
        if (priorYearIndex > -1) {
            row.values[priorYearIndex].tbValue = ls.py1NetBookValue;
            row.values[priorYearIndex].colValue = ((ls.py1NetBookValue || 0) < 0) ? this.roundUpNegativeValue(ls.py1NetBookValue || 0) : Math.round(ls.py1NetBookValue || 0);
        }
        this.dataSource = new MatTableDataSource(this.rowsData);
        this.findLsIdsInTable();
        this.emitTableSectiondata();
    }

    onTabChange(evt, index, columnIndex) {
        const isDynamicColumn = columnIndex === undefined ? 0 : columnIndex + 1;
        let ordering = {
            "currYear": 0,
            "priorYear": 1,
            "total": 2
        };
        this.dataSource.data[index].values.sort(function (a, b) {
            return (ordering[a.colId] - ordering[b.colId]);
        });

        if (this.dataSource.data && this.dataSource.data.length) {
            const tableData = (JSON.parse(JSON.stringify(this.dataSource.data))).slice(0, -2);
            tableData.forEach((row, i) => {
                row?.values && row.values.forEach((col, j) => {
                    if (col.colId.toLowerCase() == 'total') {
                        tableData[i].values.splice(j, 1);
                    }
                });
            });
            if (tableData[index] && tableData[index].values[isDynamicColumn]) {
                const rowByColumnInputID = tableData[index].rowId + 'NFS' + tableData[index].values[isDynamicColumn].colId;
                setTimeout(() => {
                    document.getElementById(rowByColumnInputID).focus();
                }, 5);
            }

        }

    }

    findLsIdsInTable() {
        if (this.dataSource.data.length && this.isAutomated) {
            const filterData = this.dataSource.data.filter(val => val.trialBalanceId && !val.isDeleted);
            const id = filterData.map(val => val.trialBalanceId);
            this.lsIdsInTable = [...new Set(id)];
        } else {
            this.lsIdsInTable = [];
        }
    }

    getCrossCheckVal(type: 'currYear' | 'priorYear') {
        const attribute = type === 'currYear' ? 'netBookValue' : 'py1NetBookValue';
        const columnTotal = this.getTotalColValue(type);
        let balanceSheetTotal = 0;
        if (this.leadSheetData.length) {
            this.leadSheetData.forEach(x => {
                balanceSheetTotal = balanceSheetTotal + ((x[attribute] < 0) ? this.roundUpNegativeValue(x[attribute]) : Math.round(x[attribute]));
            })
        }
        return (balanceSheetTotal - columnTotal);
    }

    getLSFinalAmt(data: number | string) {
        if (typeof data === 'number') {
            if (data >= 0) {
                data = Math.round(data);
                return data.toLocaleString('en-us');
            } else if (data < 0) {
                data = this.roundUpNegativeValue(data);
                const absoluteNumber = data * -1;
                const formatted = absoluteNumber.toLocaleString('en-US');
                return '(' + formatted + ')';
            } else {
                return 0;
            }
        } else {
            return data;
        }
    }

    isNoDifference(row: Row): boolean {
        const currDiff = this.getCrossCheckVal('currYear');
        const prevDiff = this.getCrossCheckVal('priorYear');
        return (row.rowId !== 'crossCheck' && !row.isDeleted) ? false : (row.rowId === 'crossCheck' && (currDiff !== 0 || prevDiff !== 0)) ? false : true;
    }

    upToOnlySelectedTable(option: any, isApplyAll?: boolean) {
        this.columnsData.forEach(e => {
            if ((e.colId === 'currYear') || (e.colId === 'priorYear')) {
                e.format = option.key;
                e.formatValue = option.value;

            }
        });
        this.workbookFunctionality(isApplyAll);
        this.messageByType(this.notes_messages.COLUMN_UPDATED, 'success');
    }

    workbookFunctionality(isApplyAll?: boolean) {
        if (this.isInsideWorkbook) {
            const currIndex = this.columnsData.findIndex(ele => ele.colId === 'currYear');
            const prevIndex = this.columnsData.findIndex(ele => ele.colId === 'priorYear');
            const engagementYearEnd = localStorage.getItem("endYear");
            this.currentDate = this.getFormattedDate(engagementYearEnd, currIndex, true);
            this.previousDate = this.getFormattedDate(engagementYearEnd, prevIndex, false);
            if (currIndex > -1) {
                this.columnsData[currIndex].formatValue = null;
                this.columnsData[currIndex].name = (this.columnsData[currIndex].colId === 'currYear' || this.columnsData[currIndex].colId === 'priorYear') ? this.currentDate : this.columnsData[currIndex].name;
            }
            if (prevIndex > -1) {
                this.columnsData[prevIndex].formatValue = null;
                this.columnsData[prevIndex].name = (this.columnsData[prevIndex].colId === 'currYear' || this.columnsData[prevIndex].colId === 'priorYear') ? this.previousDate : this.columnsData[prevIndex].name;
            }
        }
        isApplyAll && this.emitTableSectiondata(isApplyAll);
    }

    //  apply styles on columns and rows
    applyStylesOnSelectedColumn(isApply: boolean) {
        if (isApply) {
            if (this.activeFormatList.includes('header')) {
                this.initalData.isHeaderLine = !this.initalData.isHeaderLine;
                this.clearSelection(this.notes_messages.HEADER_UPDATED);
            } else if (this.activeFormatList.includes('subheader')) {
                this.dataSource.data[this.activeRowIndex].isLineHide = !this.dataSource.data[this.activeRowIndex].isLineHide;
                this.clearSelection(this.notes_messages.SUB_HEADER_UPDATED);
            } else if (this.activeFormatList.includes('row')) {
                if (this.dataSource.data.find(e => e.isChecked)) {
                    this.dataSource.data.forEach(row => {
                        if (row.isChecked) {
                            row.styles = this.formatList.filter(f => f.active).map(c => c.value);
                        }
                    });
                } else {
                    this.dataSource.data.forEach(row => {
                        if (this.activeFormatList.includes(row.rowId)) {
                            row.styles = this.formatList.filter(f => f.active).map(c => c.value);
                        }
                    });
                }
                this.clearSelection(this.notes_messages.ROW_UPDATED);
            } else {
                if (this.columnsData.find(col => (col.colId === 'currYear' || col.colId === 'priorYear'))) {
                    this.applyFormatToCoulms(false);
                } else {
                    this.formatColumns();
                }
            }
        } else {
            this.clearSelection('');
        }
    }

    clearSelection(message: string) {
        if (message && message.trim().length) {
            // this.emitTableSectiondata();
            this.messageByType(message, 'success');
        }
        this.formatList.forEach(e => {
            e.active = false;
        })
        this.activeFormatList = [];
        this.activeRowIndex = null;
    }

    onChangeFormatStyles(index: number) {
        this.formatList[index].active = !this.formatList[index].active;
    }

    addRowAction(row: Row, action: any, option?: any) {
        switch (action.key) {
            case 'subheader':
                if (this.rowsData.filter(row => row.rowId === 'subheader').length === 0) {
                    this.initalData.isHeaderLine = false;
                    // this.emitTableSectiondata();
                }

                if (this.rowsData.map(el => el.rowId).lastIndexOf(action.key) >= 0) {
                    this.rowsData.splice(((this.rowsData.map(el => el.rowId).lastIndexOf(action.key)) + 1), 0, this.createRowObject(action.key, action.value, false, this.columnsData));
                } else {
                    this.rowsData.splice(0, 0, this.createRowObject(action.key, action.value, false, this.columnsData));
                }
                this.validationForMaxSubHeader(action.key, this.rowsData.filter(e => e.rowId === action.key).length >= 3);

                this.dataSource = new MatTableDataSource(this.rowsData);
                // this.emitTableSectiondata();
                this.changeDetectorRefs.detectChanges();
                break;
            case 'general':
                this.addingGenAndTextRows(0, option, action.key);
                this.changeDetectorRefs.detectChanges();
                break;
            case 'textonly':
                this.rowsData.splice((this.rowsData.length - (this.isAutomated ? 3 : 2)), 0, this.createRowObject(action.key, '', false, this.columnsData));
                if (option.key) {
                    this.addingGenAndTextRows(0, option, action.key);
                } else {
                    this.dataSource = new MatTableDataSource(this.rowsData);
                    // this.emitTableSectiondata();
                }
                setTimeout(() => {
                    document.getElementById(action.key + '_' + (this.rowsData.map(el => el.rowId).lastIndexOf("textonly"))).focus();
                }, 5);
                this.changeDetectorRefs.detectChanges();
                break;
            case 'subtotal':
                let isRowExists = false;
                this.rowsData.forEach(e => {
                    if (!((e.rowId === 'addrow') || (e.rowId === 'totalrow') || (e.rowId === 'subheader') || (e.rowId === 'general') || (e.rowId === 'subtotal'))) {
                        isRowExists = true;
                    }
                });

                if (isRowExists && this.rowsData[(this.rowsData.findIndex(e => e.rowId === 'addrow') - 1)] && !((this.rowsData[(this.rowsData.findIndex(e => e.rowId === 'addrow') - 1)].rowId === 'subtotal') ||
                    (this.rowsData[(this.rowsData.findIndex(e => e.rowId === 'addrow') - 1)].rowId === 'textonly'))) {
                    this.rowsData.splice(((this.rowsData.findIndex(e => e.rowId === 'addrow'))), 0, this.createRowObject(action.key, 'Subtotal', false, this.columnsData));
                    this.dataSource = new MatTableDataSource(this.rowsData);
                    setTimeout(() => {
                        this.calculateSubTotalRows();
                    }, 10);
                }
                break;

            default:
                break;
        }
    }

    validationForMaxSubHeader(key: string, value: boolean) {
        this.rowOptions.forEach(r => {
            if (r.key === key) {
                r.disabled = value;
            }
        });
    }

    addingGenAndTextRows(count: number, option: any, key: string) {
        while (count < option.key) {
            const newId = (key === 'textonly') ? this.apiService.makeId(4) + '_subrow' : this.apiService.makeId(4);
            this.rowsData.splice((this.rowsData.length - (this.isAutomated ? 3 : 2)), 0, this.createRowObject(newId, '', false, this.columnsData));
            count++;
        }
        this.dataSource = new MatTableDataSource(this.rowsData);
        // this.emitTableSectiondata();
    }

    calculateSubTotalRows(isEmitTable?: boolean) {
        this.dataSource.data.forEach((e, ind) => {
            if ((e.rowId === 'subtotal') && this.dataSource.data[ind + 1] && this.dataSource.data[ind + 1].rowId === 'subtotal') {
                this.dataSource.data.splice(ind + 1, 0);
            }
            if ((e.rowId === 'addrow') && !(this.dataSource.data[ind + 1] && this.dataSource.data[ind + 1].rowId === 'totalrow')) {
                this.dataSource.data.splice(ind, 0, this.dataSource.data.splice(ind + 1, 1)[0]);
            }
            if ((e.rowId === 'textonly') && this.dataSource.data[ind - 1] && this.dataSource.data[ind - 1].rowId.includes('_subrow') &&
                !(this.dataSource.data[ind - 2] && (this.dataSource.data[ind - 2].rowId.includes('_subrow') || this.dataSource.data[ind - 2].rowId === 'textonly'))) {
                this.dataSource.data[ind - 1].rowId = this.dataSource.data[ind - 1].rowId.split('_')[0];
            }

            if ((e.rowId === 'textonly') && this.dataSource.data[ind + 1] && this.dataSource.data[ind + 1].rowId === 'subtotal') {
                this.dataSource.data.splice(ind, 0, this.dataSource.data.splice(ind + 1, 1)[0]);
            }

        });



        let subTotalValues = [];
        this.dataSource.data.forEach((e, ind) => {
            if (e.rowId === 'subtotal' && e.values.length) {
                e.values.forEach((c, j) => {
                    subTotalValues.forEach(d => {
                        if (c.colId == d.colId) {
                            c.colValue = structuredClone(d.colValue);
                            this.dataSource.data[ind].values[j].colValue = structuredClone(d.colValue);
                        }
                    })
                });
                subTotalValues = [];
            } else if (e.values.length) {
                if (!((e.rowId === 'addrow') || (e.rowId === 'totalrow') || (e.rowId === 'subheader') || (e.rowId === 'general')) && subTotalValues.length) {
                    e.values.forEach((c: any) => {
                        subTotalValues.forEach(d => {
                            if (c.colId == d.colId) {
                                d.colValue = structuredClone(((d.colValue == '-' ? 0 : d.colValue) + (c.colValue == '-' ? 0 : c.colValue)));
                            }
                        })
                    });
                } else {
                    subTotalValues = structuredClone(e.values);
                }
            }

            if ((e.rowId === 'textonly') && this.dataSource.data[ind - 1] && this.dataSource.data[ind - 1].rowId.includes('_subrow') &&
                !(this.dataSource.data[ind - 2] && (this.dataSource.data[ind - 2].rowId.includes('_subrow') || this.dataSource.data[ind - 2].rowId === 'textonly'))) {
                this.dataSource.data[ind - 1].rowId = this.dataSource.data[ind - 1].rowId.split('_')[0];
            }

            if ((e.rowId === 'textonly') && this.dataSource.data[ind + 1] && this.dataSource.data[ind + 1].rowId === 'subtotal') {
                this.dataSource.data.splice(ind, 0, this.dataSource.data.splice(ind + 1, 1)[0]);
            }

        });

        setTimeout(() => {
            this.changeDetectorRefs.detectChanges();
            this.findLsIdsInTable();
            isEmitTable && this.emitTableSectiondata();
        }, 0);
    }

    formatHeaderAndSubHeader(rowData: any, value: string, index: number) {
        this.activeRowIndex = index;
        this.activeFormatList = [];
        this.activeFormatList.push((rowData === 'header') ? rowData : rowData.rowId);
        this.activeFormatList.push(value);
        this.formatList.forEach(e => {
            e.active = false;
            if (e.value === value) {
                e.active = (rowData === 'header') ? this.initalData.isHeaderLine : rowData.isLineHide;
            }
        });

    }

    public addMultiColumns(addNoOfCol: number) {
        range(0, addNoOfCol).pipe().subscribe({
            next: response => {
                const hasTotalColumn = this.displayedColumns.includes('total');
                const manualCol = this.columnsData?.length ?? 0;
                const addTotalCol = !hasTotalColumn && manualCol === 4;
                const columnId = !addTotalCol ? this.apiService.makeId(4) : this.notes?.TOTAL_COL_NAME;
                // this.addManualColumn(columnId, !addTotalCol);
                this.addColumn({ key: 'manual', value: 'Manual' })
                this.checkManualColumns();
            }, complete: () => {
                this.emitTableSectiondata();
            }
        });
    }

    private addManualColumn(columnId: string, isTotalColumn: boolean = false) {
        const hasTotalColumn = this.displayedColumns.includes('total');
        let columnObject: ColumnValues = {} as ColumnValues;
        columnObject = { colId: columnId, colValue: 0 };
        if (this.displayedColumns.includes('total')) {
            const index = this.displayedColumns.length - 1;
            this.displayedColumns.splice(index, 0, columnObject['colId']);
            const colIndex = this.columnsData.length - 1;
            this.columnsData.splice(colIndex, 0, this.createColumnObject(columnObject['colId'], 'Manual'));
        } else {
            this.displayedColumns.push(columnObject['colId']);
            this.columnsData.push(this.createColumnObject(columnObject['colId'], isTotalColumn ? 'Manual' : 'Total'));
        }
        this.colCounter = this.columnsData.length;
        this.columnIdsOnly = this.columnsData.map(col => col.colId);
        this.rowsData.forEach(val => {
            if (val?.trialBalanceId) {
                delete val.trialBalanceId;
            }
            if (val?.rowId !== 'addrow') {
                val.values.push(columnObject);
            }
        });
        this.dataSource = new MatTableDataSource(this.rowsData);
    }

    private checkManualColumns() {
        const addedColumns = this.columnsData?.length ?? 0;
        const hasTotalColumn = this.columnsData?.some(c => c?.colId === this.notes?.TOTAL_COL);
        const colData = structuredClone(this.columnOptions());
        colData?.forEach((c: any) => {
            if (c?.key === 'manual' && c?.subMenu) {
                c?.subMenu?.forEach((s: any) => {
                    s['isDisabled'] = !(s['max'] >= addedColumns);
                });
            }
        });
        this.columnOptions.set(structuredClone(colData));
    }

    public formatColumns() {
        this.dialog.open(this.formatColDialog).afterClosed().subscribe({
            next: res => {
                switch (res) {
                    case this.notes?.NONE_FORMAT:
                        break;
                    case this.notes?.SINGLE_FORMAT:
                        this.applyFormatToCoulms(false);
                        break;
                    case this.notes?.ALL_FORMAT:
                        this.applyFormatToCoulms(true);
                        break;
                    default:
                        break;
                }
            }
        });

    }

    public columnActionsMenu(action: 'OPEN' | 'CLOSED') {
        if (action === 'CLOSED') {
            this.renameObj.set(null);
            this.showMenuView.set(true);
        }
    }

    onChangeSelection(value: boolean, i: number) {
        this.dataSource.data[i].isChecked = value;
        this.rowsData[i].isChecked = value;
        if (this.rowsData.find(e => (e.isChecked && e.rowId === 'subtotal'))) {
            this.rowActionsData.forEach(o => {
                o.key === 'format' ? o.disable = true : '';
            });
        } else {
            this.rowActionsData.forEach(o => {
                o.key === 'format' ? o.disable = false : '';
            });
        }
    }

    public trackByColumn(index, item) {
        return index;
    }

    messageByType(message: string, type: 'success' | 'error') {
        if (type === 'success') {
            this.toaster.success(message);
        } else {
            this.toaster.error(message);
        }
    }

    applyFormatToCoulms(isAppliedToAll: boolean) {
        this.activeFormatList.forEach(a => {
            this.columnsData.forEach(e => {
                if (isAppliedToAll) {
                    e.styles = this.formatList.filter(obj => obj.active).map(filteredObj => filteredObj.value);
                } else {
                    e.styles = (e.colId === a) ? this.formatList.filter(obj => obj.active).map(filteredObj => filteredObj.value) : e.styles;
                }
            });
        });
        this.clearSelection(this.notes_messages.COLUMN_UPDATED);
    }

    public subHeaderColActions(event: Event, row: Row, rowIndex: number, columnIndex: number, action: string) {
        if (action === 'rename') {
            this.showMenuView.set(false);
            // this.editColumnId = column.colId;
            this.oldColumnName = row.values[columnIndex]?.extName && row.values[columnIndex]?.extName?.trim().length ? row.values[columnIndex].extName : row.name;
            const obj: RenameInputModel = new RenameInputModel();
            obj.name = this.oldColumnName;
            obj.id = rowIndex + '_subheader_' + columnIndex;
            obj.isBolded = row.values[columnIndex]?.styles?.includes('bold');
            obj.isSubheader = true;
            this.renameObj.set(obj);
            this.openRenameComp(event);
        } else {
            // this.applyColAction(column, action, option);
            const config: MatDialogConfig<any> = new MatDialogConfig<any>();
            config.data = {
                subHeader: 'Removing the chosen subheader will result in deletion of all subheaders within the table note',
                text: "Do you wish to proceed with the Deletion?",
                toDelete: 'Subheader(s)',
                cancelText: 'Cancel',
                doneText: 'Delete'
            };
            config.disableClose = true;
            const dialogRef = this.dialog.open(this.deleteConfirmDialog, config);
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.rowsData.splice(rowIndex, 1);
                    this.dataSource = new MatTableDataSource(this.rowsData);
                    this.findLsIdsInTable();
                    this.emitTableSectiondata();
                    this.messageByType(this.notes_messages.SUB_HEADER_DELETED, 'success');
                    this.validationForMaxSubHeader('subheader', this.rowsData.filter(e => e.rowId === 'subheader').length >= 3);
                    this.initalData.isHeaderLine = this.rowsData.filter(e => e.rowId === 'subheader').length ? this.initalData.isHeaderLine : true;
                    this.emitTableSectiondata();
                }
            });
        }
    }


    findSubtotalBeside(rowsdata: any) {
        let rowList = [];
        let isSubtotalExists = false;
        rowsdata.forEach(e => {
            if (!((e.rowId === 'addrow') || (e.rowId === 'totalrow') || (e.rowId === 'subheader'))) {
                rowList.push(e.rowId);
            }
        });
        rowList.forEach((value, i) => {
            if (rowList[i + 1] && value === 'subtotal' && rowList[i + 1] === 'subtotal') {
                isSubtotalExists = true;
            }
        });
        if (isSubtotalExists) {
            this.messageByType('No two subtotals beside', 'error');
            this.rowsData = structuredClone(this.dataSource.data);
        } else {
            this.rowsData = JSON.parse(JSON.stringify(rowsdata));
            this.dataSource = new MatTableDataSource(this.rowsData);
        }
        this.calculateSubTotalRows();
    }

    deleterowsWithSubtotal(rowsdata: any, row?: any) {
        let filterRows = [];
        let isSubtotal = false;
        rowsdata.forEach((e, ind) => {
            if (!((e.rowId === 'addrow') || (e.rowId === 'totalrow') || (e.rowId === 'subheader') || (e.rowId === 'textonly'))) {
                filterRows.push({ rowId: e.rowId, index: ind });
            }
        });
        filterRows.forEach((e, i) => {
            if ((e.rowId === 'subtotal' && i == 0) || (filterRows[i + 1] && e.rowId === 'subtotal' && filterRows[i + 1].rowId === 'subtotal')) {
                isSubtotal = true;
            }
        });
        if (isSubtotal) {
            this.checkgeneralRowWithSubtotal(filterRows, rowsdata, 'multipleDeletion');
        } else if (row && !(row.rowId === 'subtotal')) {
            this.rowIdToDelete = row.rowId;
            this.deleteRow();
        } else {
            this.rowsData = rowsdata;
            this.dataSource = new MatTableDataSource(this.rowsData);
            this.findLsIdsInTable();
            // this.emitTableSectiondata();
            this.calculateSubTotalRows();

        }
    }


    openRenameComp(event) {
        const dialogWidth = 300;
        const dialogHeight = 300;
        let top = event.clientY;
        let left = event.clientX;
        // Check if the dialog goes out of the window bounds and adjust if necessary
        if (top + dialogHeight > window.innerHeight) {
            top = window.innerHeight - dialogHeight;
        }
        if (left + dialogWidth > window.innerWidth) {
            left = window.innerWidth - dialogWidth;
        }
        const config: MatDialogConfig<any> = new MatDialogConfig<any>();
        config.data = {
            renameInfo: this.renameObj()
        };
        config.position = {
            top: `${top}px`,
            left: `${left}px `
        };
        config.width = `${dialogWidth}px`;
        config.height = `${dialogHeight}px`
        config.disableClose = true;
        const dialogRef = this.dialog.open(CountableRenameComponent, config);
        dialogRef.afterClosed().subscribe(result => {
            this.updateRenameAction(result.updatedName);
        });
    }


}
