import { Component, HostListener, OnInit, Input, Renderer2, ElementRef, OnDestroy } from '@angular/core';
import { DocumentPreviewTypes, STATEMENT_VIEW_TYPES } from 'countable@helpers';
import { FsExportService, SpinnerService } from 'countable@services';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { StockholderService } from 'src/app/components/index/trail-balance-working-sheet/stockholdersequity/stockholdersequity.service';
import { SharedService } from 'src/app/components/shared/shared.service';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { modules } from 'src/app/services/app-config';

@Component({
	selector: 'stockholder-equity-preview',
	templateUrl: 'stockholder-equity-preview.component.html',
	styleUrls: ['./stockholder-equity-preview.component.scss']
})

export class StockholderEquityPreviewComponent implements OnInit, OnDestroy {
	prevYearData: any = [];
	currYearData: any = [];
	footerNotes: string = "";

	currentYear = '';
	clientName: string;
	headerText = '';
	subheaderText = '';
	userData: any;
	selectedEngagementDetails: any;
	columnsList: any = [];
	isStockholderEquityUpdateSubscription: any;
	submoduleId: number;
	engYearDetails: any;
	currEndYearDate: any;
	prevEndYearDate: any;
	prevYear = '';
	defaultRowsData: any;
	isprevYear: boolean = true;
	isShowDraft: boolean = false;
	refreshStockholder: Subscription;
	leftSideWidth: string = "auto";
	shortCutMenuDetails: any;
	refreshHeader: Subscription;
	headerNotesColumn3: string;
	crossCheck = {
		cyCrossCheckTotal: 0,
		pyCrossCheckTotal: 0,
		cyCrossCheckMinuend: 0,
		pyCrossCheckMinuend: 0
	}

	public subscriptions: Subscription = new Subscription();
	public stockholder$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	isWorkBook: boolean;

	// Footer text
	NotesExists: string = 'false';
	isHideNotesToFS = false;
	footerText: string = '';

	@Input() isPreview: string = 'false';
	@HostListener('window:storage', ['$event'])
	onStorageChange(ev: StorageEvent) {
		// this.NotesExists = localStorage.getItem('NotesExists')   //Show notes column only if Notes exist.
		if (ev.key === 'refreshFinancialStatements-StockEquity' && ev.newValue === "success") {
			if (this.router.url == "/dashboard/ntrdashboard/FinancialStatement/doc/StatementStockholdersEquity") {
				this.fetchStockHolderData();
			}
		}
		if (ev.key == 'refreshFinancialStatements-STH') {
			if (this.router.url == '/dashboard/ntrdashboard/FinancialStatement/doc/StatementStockholdersEquity') {
				this.getStockholderHeaderNotes();
			} else {
				localStorage.removeItem("refreshFinancialStatements-STH");
			}
		}
		if (ev.key == 'isfooterUpdated') {
			const note = localStorage.getItem('isfooterUpdated');
			this.updateFooter(note);
		}
	}

	constructor(private spinner: NgxSpinnerService, public apiService: ApiService, public router: Router,
		private sharedService: SharedService, public toaster: ToastrService, private stockService: StockholderService,
		private fsService: FsExportService, private renderer: Renderer2, private el: ElementRef, private spinnerService: SpinnerService) {
		this.isStockholderEquityUpdateSubscription = this.sharedService.isStockholderEquityUpdate.subscribe(resp => {
			if (resp.isRefresh == true) {
				const apiResponse = this.stockService.getStockholderData();
				this.updatePreview(apiResponse);
			}
		});
	}

	ngOnDestroy() {
		localStorage.removeItem('workbookChangeDate');
		localStorage.removeItem('workbookChangeYearEnd');
		// const refreshMap = this.sharedService.removeAfterRefresh(DocumentPreviewTypes.STOCK_HOLDERS);
		// this.sharedService.updateFinStatement$.next(refreshMap);
		// this.subscriptions && this.subscriptions.unsubscribe();
	}

	async ngOnInit() {
		localStorage.removeItem('workbookChangeDate');
		localStorage.removeItem('workbookChangeYearEnd');
		localStorage.removeItem("refreshFinancialStatements-STH");
		this.userData = JSON.parse(localStorage.getItem("userDetails"));
		this.selectedEngagementDetails = JSON.parse((localStorage.getItem('selectedEngagementObj')));
		this.clientName = this.selectedEngagementDetails.clientName;
		this.shortCutMenuDetails = JSON.parse(localStorage.getItem('shortCutMenuDetails'));
		this.isWorkBook = localStorage.getItem('isWorkBook') == "true" ? true : false;

		if (this.router.url == "/dashboard/ntrdashboard/FinancialStatement/doc/StatementStockholdersEquity") {
			this.submoduleId = JSON.parse(localStorage.getItem('FsSubmoduleId'));
		} else {
			this.submoduleId = JSON.parse(localStorage.getItem('workBookSubmoduleId'));
		}
		await this.getEngagementYeardata();
		await this.getStockholderDefaultRows();
		this.fetchStockHolderData();

		this.refreshStockholder = this.sharedService.refreshSCInStock.subscribe(res => {
			if (res) {
				this.checkfsSettings();
				this.fetchStockHolderData();
			}
		});

		if (this.router.url == "/dashboard/ntrdashboard/FinancialStatement/doc/StatementStockholdersEquity") {
			this.checkfsSettings();
		}

		this.refreshHeader = this.sharedService.StockholderPreviewheaderInfo.subscribe(res => {
			this.headerText = res[0].header[0].changednameline1;
			this.subheaderText = res[0].header[0].changednameline2;
			this.headerNotesColumn3 = res[0].header[0].changednameline3;
			this.footerNotes = res[1].note ? res[1].note[0].note : "";
			let preview = document.getElementById("stockholder-preview");
			if (preview) {
				let footerElements = preview.getElementsByClassName("my-custom-footer");
				for (let i = 0; i < footerElements.length; i++) {
					footerElements[i].remove();
				}
				let newFooterText = "<div class='my-custom-footer' style = 'position: absolute; bottom: 0px;left: 0; margin-top: 35px; padding-bottom: 17px; width: 100%; text-align: center;'> <p style = 'font-family: Arial,sans-serif !important; font-size: 12px;'>" + this.footerNotes + "</p></div>";
				preview.innerHTML += newFooterText;
			}
		});

		this.subscriptions.add(
			this.stockholder$.pipe().subscribe({
				next: stockholderData => {
					if (stockholderData) {
						document.getElementById("stockholder-preview").innerHTML = stockholderData;

						// Setting watermark
						const waterMark = this.sharedService.showdraft.value;
						const waterMarkUrl = this.sharedService.waterMarkUrl.getValue();
						if ((waterMark && waterMark.isShowDraft) && waterMarkUrl) {
							document.getElementById("stockholder-preview").style.backgroundImage = `url(${waterMarkUrl})`;
						} else {
							document.getElementById("stockholder-preview").style.backgroundImage = "none";
						}

						if (this.footerText == '') {
							this.getStockholderHeaderNotes();
						} else {
							this.updateFooter(this.footerNotes);
						}

						setTimeout(() => {
							const eleHeight = document.getElementById('stockholder-preview') as HTMLElement;
							if (eleHeight.clientHeight < 759) {
								eleHeight.style.height = '760px';
							}
						}, 10);
						this.spinner.hide();
					}
				}
			})
		);
	}

	checkfsSettings() {
		let draft = JSON.parse((localStorage.getItem('showdraft')));
		if (draft) {
			this.isShowDraft = draft.isShowDraft ? draft.isShowDraft : '';
		}
		const prevYearVal = localStorage.getItem('prevYear');
		this.isprevYear = (prevYearVal === '0') ? false : true;
	}

	getStockholderHeaderNotes() {
		let obj = {
			"engagementsid": this.selectedEngagementDetails.engagementsid.toString(),
			"submoduleid": this.submoduleId
		};

		let finalObj = JSON.stringify(obj);
		let data = {
			"inputParameters": finalObj,
			"procedureName": "getwbheadernote"
		};

		this.spinner.show();
		this.apiService.getData(data).subscribe(response => {
			if (response) {
				const incomeHeaderInfo = JSON.parse(response);
				this.headerText = incomeHeaderInfo[0].header[0].changednameline1;
				this.subheaderText = incomeHeaderInfo[0].header[0].changednameline2;
				this.headerNotesColumn3 = incomeHeaderInfo[0].header[0].changednameline3;
				this.footerNotes = incomeHeaderInfo[1].note ? incomeHeaderInfo[1].note[0].note : "";
				this.getFSNotes();
				localStorage.removeItem("refreshFinancialStatements-STH");
				this.spinner.hide();
			}
		}, error => {
			localStorage.removeItem("refreshFinancialStatements-STH");
			this.toaster.error(error.error);
			this.spinner.hide();
		})
	}

	getEngagementYeardata() {
		this.spinner.show();
		return new Promise((resolve, reject) => {
			this.apiService.getEngagementYearDetails(this.selectedEngagementDetails.engagementsid.toString()).subscribe(response => {
				if (response) {
					this.engYearDetails = response;
					this.currentYear = this.engYearDetails[0].acctyear;
					this.currEndYearDate = this.engYearDetails[0].acctdate;
					this.prevYear = this.engYearDetails[1]?.acctyear ? this.engYearDetails[1]?.acctyear : '0';
					this.prevEndYearDate = this.engYearDetails[1]?.acctdate ? this.engYearDetails[1]?.acctdate : '';
					resolve(this.engYearDetails);
				}
			}, error => {
				this.spinner.hide();
				this.toaster.error(error.error);
				reject(error.error);
			});
		});
	}

	getStockholderDefaultRows() {
		var stockdataObj =
		{
			'engagementsId': this.selectedEngagementDetails.engagementsid.toString(),
			'currentYear': this.currentYear,
			'previousYear': this.prevYear
		}
		// this.spinner.show();
		return new Promise((resolve, reject) => {
			this.apiService.getStockholderDefaults(stockdataObj).subscribe(resp => {
				if (resp) {
					this.defaultRowsData = JSON.parse(resp);
					if (this.defaultRowsData?.defaults && this.defaultRowsData.defaults.length) {
						this.defaultRowsData.defaults.forEach(e => {
							if (e.rowUUID == "cyCrossCheckMinuend") {
								this.crossCheck.cyCrossCheckMinuend = e.value;
							}
							if (e.rowUUID == "pyCrossCheckMinuend") {
								this.crossCheck.pyCrossCheckMinuend = e.value;
							}
						});
					}
					resolve(resp);
				}
			}, error => {
				// this.spinner.hide();
				this.toaster.error(error.error);
				reject(error);
			});
		});
	}

	fetchStockHolderData() {
		this.spinner.show();
		this.subscriptions.add(
			this.fsService.fetchFSDocPreview(DocumentPreviewTypes.STOCK_HOLDERS).subscribe({
				next: res => {
					this.spinner.hide();
					if (res) {
						let stockholderJSON = JSON.parse(res);
						stockholderJSON = stockholderJSON.stockholder;
						this.stockholder$.next(stockholderJSON["stockholderPreview"]);
					}
				}
			}).add(() => {
				this.spinner.hide();
			})
		);
	}

	setDefaultValues(yearData: any) {
		if (yearData.rows.length && this.defaultRowsData && this.defaultRowsData.defaults) {
			yearData.rows.forEach(eachRow => {
				const defColVal = this.defaultRowsData.defaults.filter(val => val.rowUUID === eachRow.rowUUID);
				if (defColVal.length) {
					const defObj = {
						colId: defColVal[0].columnUUID,
						value: defColVal[0].value,
						valueId: 0
					}
					eachRow.values.push(defObj);
				}
			});
		}
		return yearData;
	}

	updatePreview(apiResponse: any) {
		this.columnsList = apiResponse['columns'];
		this.columnsList.sort((a, b) => (a.sortOrder) - (b.sortOrder));
		this.columnsList = this.columnsList.filter(val => !val.isHide);
		this.NotesExists = 'false';
		let columnSize = 80;
		if (this.router.url != "/dashboard/ntrdashboard/FinancialStatement/doc/StatementStockholdersEquity")
			columnSize = 66;

		this.leftSideWidth = " calc(100% - " + (this.columnsList.length + (this.NotesExists ? 2 : 1)) * columnSize + "px )";
		if (apiResponse.year1) {
			const year1 = this.setDefaultValues(apiResponse.year1);
			this.currYearData = this.generateDisplayData(this.columnsList, year1?.rows, apiResponse?.year1?.year);
		}
		if (apiResponse.year2) {
			const year2 = this.setDefaultValues(apiResponse.year2);
			this.prevYearData = this.generateDisplayData(this.columnsList, year2?.rows, apiResponse?.year2?.year);
		}
	}

	generateDisplayData(colDetails, curDetails, year) {
		let columnsDetails = colDetails;
		let visibleColIds = columnsDetails.map(ele => ele.columnUUID);
		if (curDetails) {
			curDetails.sort((a, b) => (a.sortOrder) - (b.sortOrder));
		}
		let currentDetails = JSON.parse(JSON.stringify(curDetails));

		let rowMapping: any = [];
		let columnTotal = new Map<string, number>();
		let sectionTotal: number = 0;


		currentDetails.forEach((row, index) => {
			let rowTotal = new Map<string, number>();
			let columnValues = new Map<string, number>();
			let rowContentMapping = {};

			//set row name
			rowContentMapping['name'] = (index == 0) ? (year === this.currentYear) ? this.getFormattedDate(this.currEndYearDate, true) : this.getFormattedDate(this.prevEndYearDate, true) : row.name;

			// set the notes
			if (row?.notes && row.notes.length) {
				this.NotesExists = 'true';
			}
			let notes: string = '';
			row.notes.forEach((note, index) => {
				notes += (index == 0 ? '' : ',') + note.engNoteId;
			});
			rowContentMapping['notes'] = notes;

			//filter row values based on hidden property
			row.values = row.values.filter(ele => visibleColIds.includes(ele.colId));

			// calculating the totals
			row.values.forEach((column, index) => {
				columnTotal.set(column.colId, (columnTotal.get(column.colId) ? columnTotal.get(column.colId) : 0) + Number.parseInt(column.value));
				rowTotal.set(row.rowUUID, (rowTotal.get(row.rowUUID) ? rowTotal.get(row.rowUUID) : 0) + Number.parseInt(column.value));
				columnValues.set(column.colId, Number.parseInt(column.value));
				sectionTotal += Number.parseInt(column.value);
			});

			//inserting the columns of the row
			columnsDetails.forEach((column, index) => {
				rowContentMapping[column.columnUUID] = this.showFormattedNumber(columnValues.get(column.columnUUID) || 0);
			});

			//inserting the row total
			rowContentMapping['rowTotal'] = this.showFormattedNumber(rowTotal.get(row.rowUUID));
			rowMapping.push(rowContentMapping);

		});

		// inserting the last row
		let lastRowContent = {};
		lastRowContent['name'] = (year === this.currentYear) ? this.getFormattedDate(this.currEndYearDate) : this.getFormattedDate(this.prevEndYearDate);
		lastRowContent['notes'] = '';
		columnsDetails.forEach((column, index) => {
			lastRowContent[column.columnUUID] = this.showFormattedNumber(columnTotal.get(column.columnUUID));
		});
		lastRowContent['rowTotal'] = this.showFormattedNumber(sectionTotal);
		rowMapping.push(lastRowContent);

		// cross check values
		if (year === this.currentYear) {
			this.crossCheck.cyCrossCheckTotal = sectionTotal;
		} else {
			this.crossCheck.pyCrossCheckTotal = sectionTotal;
		}
		return rowMapping;
	}

	showFormattedNumber(data: number) {
		if (data > 0) {
			return data.toLocaleString('en-us');
		} else if (data < 0) {
			const absoluteNumber = data * -1;
			const formatted = absoluteNumber.toLocaleString('en-US');
			return '(' + formatted + ')';
		} else {
			return '-';
		}
	}

	getFormattedDate(date: string, minusOneYear?: boolean) {
		const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		const dateObj = new Date(date);
		if (!minusOneYear) {
			return `Balance as of ${months[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
		} else {
			dateObj.setDate(dateObj.getDate() + 1);
			return `Balance as of ${months[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear() - 1}`;
		}
	}

	getFSNotes() {
		let jsonFileName = this.selectedEngagementDetails.engagementsid + '_notes.json';
		const payloadObj = {
			"module": modules[16],
			"firmId": this.userData.firmUserAcctId,
			"clientId": this.selectedEngagementDetails.clientfirmid,
			"engagementId": this.selectedEngagementDetails.engagementsid,
			"status": 0,
			"folderName": jsonFileName,
			"appSyncId": this.userData.useracctid,
			"extension": 'comp',
		}

		// this.spinner.show();
		this.apiService.getJsonData(payloadObj).subscribe((response: any) => {
			if (response) {
				const notes = !response ? [] : JSON.parse(response);
				this.isHideNotesToFS = notes.length ? true : false;
				let pEl = document.getElementById("stockholder-preview");
				if (pEl) {
					let footerElements = pEl.getElementsByClassName("my-custom-footer");
					for (let i = 0; i < footerElements.length; i++) {
						footerElements[i].remove();
					}
				}
				this.footerText = this.isHideNotesToFS ? "<div class='my-custom-footer' style = 'position: absolute; bottom: 0px;left:0; margin-top: 35px; padding-bottom: 17px; width: 100%; text-align: center;'> <p style = 'font-family: Arial,sans-serif !important; font-size: 12px;'>" + this.footerNotes + "</p></div>" : "";
				let preview = document.getElementById("stockholder-preview").innerHTML;
				document.getElementById("stockholder-preview").innerHTML = preview + this.footerText;
			}
			// this.spinner.hide();
		}, (error) => {
			// this.spinner.hide();
		});
	}

	updateFooter(note) {
		this.footerNotes = note;
		let preview = document.getElementById("stockholder-preview");
		if (preview) {
			let footerElements = preview.getElementsByClassName("my-custom-footer");
			if (footerElements.length) {
				for (let i = 0; i < footerElements.length; i++) {
					footerElements[i].remove();
				}
				let newFooterText = "<div class='my-custom-footer' style = 'position: absolute; bottom: 0px;left: 0; margin-top: 35px; padding-bottom: 17px; width: 100%; text-align: center;'> <p style = 'font-family: Arial,sans-serif !important; font-size: 12px;'>" + note + "</p></div>";
				preview.innerHTML += newFooterText;
			}

		}
	}
}
