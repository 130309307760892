export class TbInfoModel {
  currentYear: string;
  engPeriodYear: string;
  currentDate: string;
  tbYears: EngTbYearModel[] = [];
}

export class EngTbYearModel {
  acctdate: string;
  acctyear: string;
  engagementsid: number;
  periodYear: string;
  periodYearId: number;
}
