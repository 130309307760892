import {Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {NgxSpinnerService} from 'ngx-spinner';
import {ApiService} from '../../../../../services/api.service';
import {SharedService} from '../../../../shared/shared.service';

@Component({
  selector      : 'app-partner-capital-vertical',
  templateUrl   : './partner-capital-vertical.component.html',
  styleUrls     : ['./partner-capital-vertical.component.scss'],
  encapsulation : ViewEncapsulation.None,
  providers     : [DatePipe]
})
export class PartnerCapitalVerticalComponent implements OnInit {
  /*-------------Property Declarations------------*/
  responseData            : any =[];
  isRowAdded              : boolean = false;
  verticalData            : any = [];
  viewType                : number = 0;
  engagementId            : number;
  selectedEngagementObj   : any;
  yearEnd                 : number;
  acctYear                : number;
  userDetails             : any;
  userLoginId             : number;
  isEditable              : boolean = false;
  partnerId               : number;
  lineItemId              : number;
  curAmount               : string;
  preAmount               : string;
  lineItemName            : string;
  confirmMessage          : string;
  partnerShipLineItemId: number;
  parentid                : number;
  isSource                : number;
  descriptionTitle        : string;
  @Input() verticalPanelsState;
  /*-------------Property Declarations Ending------------*/
  @ViewChild('confirmDialog') confirmDialog: TemplateRef<any>;
  constructor(
    private apiService  : ApiService,
    public dialog       : MatDialog,
    private datePipe    : DatePipe,
    private toaster     : ToastrService,
    private spinner     : NgxSpinnerService,
    private shareService: SharedService)
    {
      //this.verticalPanelsState = this.verticalPanelsState;
    }

  ngOnInit() {
    this.selectedEngagementObj  = JSON.parse((localStorage.getItem('selectedEngagementObj')));
    let year                    = this.datePipe.transform(this.selectedEngagementObj.yearEnd, 'y');
    this.yearEnd                = +year;
    this.engagementId           = this.selectedEngagementObj.engagementsid;
    this.acctYear               = +year;
    //get the user details
    this.userDetails            = JSON.parse(localStorage.getItem('userDetails'));
    this.userLoginId            = this.userDetails.useracctid;
    this.getPartnerCapitalVerticalStatement();

    /* this.verticalPanelsState = this.verticalPanelsState;
     */
  }

  ngOnChanges(){
    this.verticalPanelsState = this.verticalPanelsState;
  }

  getPartnerCapitalVerticalStatement(){
    const inputData = {
      "engagementid": this.engagementId,
      //"acctyear"    : this.acctYear,
      "datatype"    : 1
    };
    const inputString = JSON.stringify(inputData);
    let data = {
      "procedureName"   : "getstatementofpartnerscapital",
      "inputParameters" : inputString
    };
    this.spinner.show();
    this.apiService.getData(data).subscribe(response => {
      this.spinner.hide();
      const responseData = JSON.parse(response);
      if (responseData && responseData.length > 0) {
        setTimeout(() => {
          this.descriptionTitle = "Description";
        }, 0)
        this.verticalData = responseData[0];
      }
      else {
        this.verticalData = [];
      }
    }, error => {
      this.spinner.hide();
    });
  }
  activeRow(type: string, row: any, parentId?: number, partnershiplineitemid?: number){
    if(type === "ADD"){
      this.isRowAdded               = true;
      this.isEditable               = false;
      this.lineItemName             = null;
      this.curAmount                = null;
      this.preAmount                = null;
      this.partnerId                = row;
      this.parentid                 = parentId;
      this.partnerShipLineItemId    = partnershiplineitemid;
    }
    else if(type === "EDIT"){
      this.isRowAdded               = false;
      this.isEditable               = true;
      this.partnerId                = row.partnerid;
      this.parentid                 = row.parentid;
      this.lineItemName             = row.lineitemname;
      this.curAmount                = row.curyearamount;
      this.preAmount                = row.preyearamount;
      this.partnerShipLineItemId    = row.partnershiplineitemid;
      this.isSource                 = row.issource;
    }

  }
  closeActiveRow(type: string, row?: any){
    if(type === "ADD"){
      this.isRowAdded   = false;
      this.lineItemName = null;
      this.curAmount    = null;
      this.preAmount    = null;
    }
    else if(type === "EDIT"){
      this.isEditable = false;
      this.partnerId  = row.partnerid;
      this.parentid   = row.parentid;
    }
  }
  //confirm dialog for delete and update
  confirm(type: string, row?: any) {
    if(type === "DELETE"){
      this.confirmMessage = "Are you sure you want to delete?";
    }
    this.lineItemName             = row.lineitemname;
    this.curAmount                = row.curyearamount;
    this.preAmount                = row.preyearamount;
    this.partnerShipLineItemId    = row.partnershiplineitemid;
    this.parentid                 = row.parentid;
    this.partnerId                = row.partnerid;
    this.isSource                 = row.issource;
    const dialogRef               = this.dialog.open(this.confirmDialog, {});
    dialogRef.afterClosed().subscribe(result => {});
  }
  saveRow(type: string, parentId: number, row?: any){
    let partnerShipLineItemId, parentid, partnerId, isSource, isDelete;
    if(type === "ADD"){
      partnerShipLineItemId  = 0;
      parentid               = parentId;
      isSource               = 1;
      partnerId              = row;//partner id
      isDelete               = 0;
    }
    else if(type === "UPDATE"){
      partnerShipLineItemId  = row.partnershiplineitemid;
      parentid               = row.parentid;
      isSource               = this.isSource;
      partnerId              = row.partnerid;
      isDelete               = 0;
    }
    else{
      isDelete               = 1;
      isSource               = this.isSource;
      partnerId              = this.partnerId;
      partnerShipLineItemId  = this.partnerShipLineItemId;
      parentid               = this.parentid;
    }
    if(this.lineItemName === null || this.lineItemName === "" || this.curAmount === null || this.curAmount === "" || this.preAmount === null || this.preAmount === ""){
      this.toaster.error("Please provide required fields!");
    }
    else{
      if(this.lineItemName && this.lineItemName.length > 255){
        this.toaster.error("Not allowing more than 255 characters!");
        return;
      }
      //preparing input data
      const inputString = {
        "addpcnames": [{
          "partnershiplineitemid"     : partnerShipLineItemId,
          "parentid"                  : parentid,
          "partnershipaccountid"      : partnerId,
          "linenamechange"            : this.lineItemName,
          "amountchange"              : this.curAmount,
          "PY1"                       : this.preAmount,
          "Issource"                  : isSource,
          "isdelete"                  : isDelete,
          "acctyear"                  : this.acctYear,
          "engagementsid"             : this.engagementId,
          "loginid"                   : this.userLoginId
        }]
      }
      const inputObject = JSON.stringify(inputString);
      const object = {
        "procedureName": "saveaddpartnerscapital",
        "inputParameters": inputObject
      }

      this.spinner.show();
      this.apiService.saveData(object).subscribe(response => {
        if (response) {
          this.getPartnerCapitalVerticalStatement();
          if(type === "ADD"){
            this.toaster.success("Successfully Saved");
          }
          else if(type === "UPDATE"){
            this.toaster.success("Successfully Updated");
          }
          else{
            this.toaster.success("Successfully Deleted");
          }
          this.isRowAdded  = false;
          this.isEditable  = false;
          this.refreshPreview();
          this.spinner.hide();
        }
      }, error => {
        this.toaster.error("failed");
        this.spinner.hide();
      });
    }
  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57);
  }
  refreshPreview(){
    this.shareService.refreshPreview.next(1);
  }
}
