import { animate, state, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/components/shared/shared.service';
import { ApiService } from '../../../../../services/api.service';
import { TbApiService } from '../../../../../services/tb-api.service';

@Component({
  selector: 'app-partner-capital-settings',
  templateUrl: './partner-capital-settings.component.html',
  styleUrls: ['./partner-capital-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})

export class PartnerCapitalSettingsComponent implements OnInit {
  /* Properties */
  @ViewChild('combineDialog') combineDialog: TemplateRef<any>;
  @ViewChild('deletedDialog') deletedDialog: TemplateRef<any>;
  engagementID: any;
  loggedInUserData: any;
  partnerCapitalsGridData: any;
  settingsPartnersCapital: any;
  selectedAllocationType: any;
  selectedCount: any;
  leadsheetTblforAccount: any;
  selectedEngagement: any;
  trialBalanceDisplayedColumns = ['accountcode', 'accountname'];
  isEditModeEnable: boolean = false;
  isdeletePCSettingsRow: any;
  accountnoPCSettings: any;
  groupnames: any = [];
  allocation: any;
  isEnableGroupButton: boolean = false;
  groupName: string = null;
  selectedGroupName: string = null;
  groupData: any;
  selectedPartners: any = [];
  selectedGroups: any = [];
  selectedIds: any = [];
  groupSelected: boolean = false;
  bottomTotal: any;
  confirmMessage: string;
  row: any;
  readonlyGroupName: string = null;
  buttonHeading: string;
  subHeading: string;
  toggleOkButton: boolean = false;
  deleteHeading: string;
  statementview: any;
  settingsData: any;
  defaultCount: number = 0;
  accountNo: string;
  isEdit: boolean = false;
  partnerName: any;
  description: string;
  partnerId: any;
  oldGroupName: any;
  allocationTypeValue: number;
  isHide: boolean = true;
  partnerShipAccountId: number = 0;

  /* End Properties */
  /* Main Grid */
  displayedColumns: string[] = ['select', 'partnername', 'allocationtypevalue', 'accountno', 'description', 'actions'];

  constructor(public dialog: MatDialog, private datePipe: DatePipe, private fb: UntypedFormBuilder, private apiService: ApiService,
              private sharedService: SharedService, private toaster: ToastrService, private spinner: NgxSpinnerService,
              private tbApi: TbApiService) {}

  ngOnInit() {
    this.loggedInUserData = JSON.parse((localStorage.getItem('userDetails')));
    this.engagementID = localStorage.getItem('engagementID');
    this.selectedEngagement = JSON.parse((localStorage.getItem('selectedEngagementObj')));
    this.getPartnerCapitalSettings();
    this.gettrailBalance();
  }

  // Default SP
  getPartnerCapitalSettings() {

    let inputData = {'engagementsid': this.engagementID};
    let inputString = JSON.stringify(inputData);
    let data = {
      'procedureName': 'getpartnershipaccount',
      'inputParameters': inputString
    };
    this.spinner.show();
    this.apiService.getData(data).subscribe(response => {
        if (response) {
          this.spinner.hide();
          let responseData = JSON.parse(response);

          this.settingsData = responseData[0].setting;
          if (this.settingsData) {
            this.statementview = this.settingsData[0]['statementviewtype'];
            this.selectedAllocationType = this.settingsData[0].allocationtype;
            this.selectedCount = this.settingsData[0].partneraccountcount;
            this.defaultCount = this.settingsData[0].partneraccountcount;
            this.isdeletePCSettingsRow = this.settingsData[0].isdelete;
            this.accountnoPCSettings = this.settingsData[0].accountno;
          }
          const obj = {'defaultCount': this.defaultCount, 'statementViewType': this.statementview};
          this.sharedService.isEnableSettings.next(obj);
          this.partnerCapitalsGridData = new MatTableDataSource(responseData[0].partner);
          this.bottomTotal = responseData[0].bottomtotal.total;
          if (responseData[0].partner) {
            this.groupnames = [];
            responseData[0].partner.forEach(e => {
              if (e.groupname) {
                this.groupnames.push(e.groupname.toLowerCase());
              }
            });
          }
          if (this.partnerCapitalsGridData.data) {
            this.partnerCapitalsGridData.data.forEach(element => {
              if (element.groupname != null) {
                element['isChecked'] = false;
              }
            });
          }
          //this.toaster.success("document referenced successfully");
        }
      },
      error => {
        this.spinner.hide();
        this.toaster.warning(error.error);
      });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(this.combineDialog, {});
    dialogRef.afterClosed().subscribe(result => {});
  }

  //
  updatePartnerCapitalSettings(value, type: string) {
    let inputData;
    let selectedPartners;
    let procedureName;
    if (type == 'count') {
      this.allocation = this.selectedAllocationType;
      //value = this.selectedCount;
      if (this.selectedCount > this.defaultCount) {
        selectedPartners = this.selectedCount;
      } else {
        this.selectedCount = this.defaultCount;
        this.toaster.error('Please delete the partners from below grid to decrease the count. You cannot decrease the count directly from here.');
        return;
      }
      // Heare value is a Selected or Entered partners count
      inputData = {
        'prtsetting': [{
          'engagementsid': +this.engagementID,
          'partneraccountcount': +selectedPartners,
          'allocationtype': this.allocation,
          'statementviewtype': 0,
          'loginid': this.loggedInUserData.useracctid
        }]
      };
      procedureName = 'saveengpartnershipsetting'; // Settings SP
    } else if (type == 'alloacation') {
      this.allocation = +value; // Heare value is Allocation type
      selectedPartners = this.selectedCount;
      //
      inputData = {
        'prtsetting': [{
          'engagementsid': +this.engagementID,
          'partneraccountcount': +selectedPartners,
          'allocationtype': this.allocation,
          'statementviewtype': 0,
          'loginid': this.loggedInUserData.useracctid
        }]
      };
      procedureName = 'saveengpartnershipsetting'; // Settings SP
    } else if (type == 'save') {
      if (this.groupName) {
        let gns = this.groupnames.find(e => e == this.groupName.toLowerCase());

        if (gns && gns !== this.oldGroupName.toLowerCase() && this.readonlyGroupName === null) {
          this.toaster.error('Group name already exists! Try with other one');
          return;
        }
        if (this.groupName.length > 255) {
          this.toaster.error('Not allowing more than 255 characters for group name!');
          return;
        }
      }
      if (this.description && this.description.length >= 1000) {
        this.toaster.error('Not allowing more than 1000 characters for description!');
        return;
      }
      if (this.partnerName && this.partnerName.length > 255) {
        this.toaster.error('Not allowing more than 255 characters for partner name!');
        return;
      }
      inputData = {
        'prtaccount': [{
          'partnershipaccountid': value.partnershipaccountid,
          'partnershipsettingid': value.partnershipsettingid,
          'partnername': this.partnerName,
          'allocationtypevalue': this.allocationTypeValue,
          'accountno': this.accountNo,
          'description': this.description,
          'groupname': this.groupName,
          'oldgroupname': this.oldGroupName,
          'isdelete': 0,
          'engagementsid': this.engagementID,
          'loginid': this.loggedInUserData.useracctid
        }]
      };

      procedureName = 'savepartnershipaccount'; // Save/Delete Settings SP
    } else if (type == 'delete') {
      if (!value.accountno) {
        value.accountno = null;
      } else {
        value.accountno = value.accountno;
      }
      if (!value.groupname) {
        value.groupname = null;
      } else {
        value.groupname = value.groupname;
      }
      inputData = {
        'prtaccount': [{
          'partnershipaccountid': value.partnershipaccountid,
          'partnershipsettingid': value.partnershipsettingid,
          'partnername': value.partnername,
          'allocationtypevalue': +value.allocationtypevalue,
          'accountno': value.accountno,
          'description': value.description,
          'groupname': value.groupname,
          'oldgroupname': value.oldgroupname,
          'isdelete': 1,
          'engagementsid': this.engagementID,
          'loginid': this.loggedInUserData.useracctid
        }]
      };

      procedureName = 'savepartnershipaccount'; // Save/Delete Settings SP
    }
    let inputString = JSON.stringify(inputData);
    let data = {
      'procedureName': procedureName,
      'inputParameters': inputString
    };

    this.spinner.show();
    this.apiService.getData(data).subscribe(response => {
        if (response) {
          this.spinner.hide();

          if (type == 'save') {
            this.isEdit = false;
            this.isHide = true;
            this.toaster.success('Statement of Partners Capital updated successfully');
          } else if (type == 'delete') {
            this.toaster.success('Successfully Deleted');
          } else if (type === 'alloacation') {
            this.isHide = true;
            this.toaster.success('Successfully Allocated');
          } else {
            this.isHide = true;
            this.toaster.success('Successfully Updated Partners Count');
          }
          this.getPartnerCapitalSettings();
          //this.toaster.success("document referenced successfully");
        }
      },
      error => {
        this.spinner.hide();
        this.toaster.warning(error.error);
        this.getPartnerCapitalSettings();
      });
  }

  // Account Number Column Drop down
  gettrailBalance() {
    this.spinner.show();
    this.tbApi.getTrialBalance(this.selectedEngagement.engagementsid, parseInt(localStorage.getItem('trailBalYear'))).subscribe({
      next: res => {
        if (res.status === 200) {
          this.leadsheetTblforAccount = new MatTableDataSource(<any>res.data);
          this.leadsheetTblforAccount.filterPredicate = (data: any, filter: string) => {
            return data['accountcode'].toLowerCase().includes(filter) || data['accountname'].toLowerCase().includes(filter);
          };
        }
      }
    }).add(() => this.spinner.hide());
  }

  // Account Number Column Drop down Search
  applyFilterToMapSheet(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.leadsheetTblforAccount.filter = filterValue;
  }

  //

  /** Whether the number of selected elements matches the total number of rows. */
  isSelected() {
    this.selectedGroups = this.partnerCapitalsGridData.data.filter(x => x.isChecked === true && x.groupname !== null);
    this.selectedPartners = this.partnerCapitalsGridData.data.filter(x => x.checked === true && x.groupname === null);
    this.isEnableGroupButton = this.selectedPartners.length > 1 ? true : false;
    if (this.selectedGroups.length > 0) {
      this.groupSelected = true;
    } else {
      this.groupSelected = false;
      this.readonlyGroupName = null;
      this.groupName = null;
      this.buttonHeading = 'Create & Combine';
      this.subHeading = 'Do you wish to combine the Partners into one Category';
    }
    if (this.selectedGroups.length == 1 && this.selectedPartners.length > 0) {
      this.isEnableGroupButton = true;
      this.groupName = this.selectedGroups[0].groupname;
      this.readonlyGroupName = this.selectedGroups[0].groupname;
      this.buttonHeading = 'Combine';
      this.subHeading = 'Do you wish to combine the Partners into existing group';
    } else if (this.selectedPartners.length > 1) {
      this.isEnableGroupButton = true;
    }
    this.selectedIds = [...this.selectedGroups, ...this.selectedPartners];
  }

  groupingPartners() {
    let gn = this.groupnames.find(e => e == this.groupName.toLowerCase());

    if (gn && this.readonlyGroupName === null) {
      this.toaster.error('Group name already exists! Try with other one');
    } else {
      let obj: any = [];
      this.groupData = this.selectedIds.map(e => {
        obj = {
          'partnershipaccountid': e.partnershipaccountid,
          'partnershipsettingid': e.partnershipsettingid,
          'partnername': e.partnername,
          'allocationtypevalue': +e.allocationtypevalue,
          'accountno': e.accountno,
          'description': e.description,
          'groupname': this.groupName,
          'oldgroupname': null,
          'engagementsid': this.engagementID,
          'isdelete': 0,
          'loginid': this.loggedInUserData.useracctid
        };
        return obj;
      });
      let inputData, procedureName, inputString;
      inputData = {
        'prtaccount': this.groupData
      };

      procedureName = 'savepartnershipaccount';
      inputString = JSON.stringify(inputData);
      const data = {
        'procedureName': procedureName,
        'inputParameters': inputString
      };

      this.spinner.show();
      this.apiService.getData(data).subscribe(response => {
          if (response) {
            //debugger;
            this.spinner.hide();
            this.isEnableGroupButton = false;
            this.groupSelected = false;
            this.toaster.success('Partner Grouped Successfully');
            this.getPartnerCapitalSettings();
          }
        },
        error => {
          this.spinner.hide();
          this.toaster.warning(error.error);
        });
    }
  }

  unGroupingPartners(row) {
    let inputData, procedureName, inputString;
    inputData = {
      'engagementid': this.engagementID,
      'groupname': row.groupname,
      'loginid': this.loggedInUserData.useracctid
    };
    procedureName = 'ungrouppartnership';
    inputString = JSON.stringify(inputData);
    const data = {
      'procedureName': procedureName,
      'inputParameters': inputString
    };
    this.spinner.show();
    this.apiService.getData(data).subscribe(response => {
        if (response) {
          //debugger;
          this.spinner.hide();
          this.toaster.success('Partner Ungrouped Successfully');
          this.getPartnerCapitalSettings();
        }
      },
      error => {
        this.spinner.hide();
        this.toaster.warning(error.error);
      });
  }

  close() {
    this.groupName = null;
    this.readonlyGroupName = null;
  }

  confirm(type: string, row: any) {
    this.row = row;
    if (type === 'DELETE') {
      this.confirmMessage = 'Are you sure you want to delete the partner?';
      this.toggleOkButton = false;
      this.deleteHeading = 'Delete';
    } else if (type === 'UNGROUP') {
      this.confirmMessage = 'Are you sure you want to ungroup the group?';
      this.toggleOkButton = true;
      this.deleteHeading = 'Ungroup';
    } else {
      this.confirmMessage = 'Are you sure you want to delete the group?';
      this.toggleOkButton = false;
      this.deleteHeading = 'Delete';
    }
    const dialogRef = this.dialog.open(this.deletedDialog, {});
    dialogRef.afterClosed().subscribe(result => {});
  }

  selectTrialAdj(row) {
    this.accountNo = row.accountcode;
  }

  activeRow(element: any, type: string) {
    this.groupName = element.groupname;
    this.oldGroupName = element.groupname;
    this.isEdit = true;
    this.accountNo = element.accountno;
    this.partnerName = element.partnername;
    this.allocationTypeValue = element.allocationtypevalue;
    this.description = element.description;
    this.partnerId = element.partnershipaccountid;
  }

  closeRow(element: any) {
    this.isEdit = false;
    this.partnerName = element.partnername;
  }

  onlyNumberKey(event) {

    return (event.charCode == 8 || event.charCode == 0) ? null : (event.charCode >= 48 && event.charCode <= 57);
  }

  hideAllocation(id: number, data) {
    this.partnerShipAccountId = id;
    this.isHide = data ? false : true;
  }
}
