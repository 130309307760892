export class Notes {
  id: string;
  name: string;
  content: (TextSection | TableSection)[];
}

export class TextSection {
  id: string;
  type: string;
  isEdit: boolean;
  lineSpacing: number;
  content: string;
  jsonUpdated?: string;
}

export class TableSection {
  id: string;
  type: string;
  isEdit: boolean;
  isHeaderHidden: boolean;
  rows: Row[];
  columns: Column[];
  isHeaderLine?: boolean;
  subtotalCount?: number;
  jsonUpdated?: string;
}

export class Row {
  rowId: string;
  name: string;
  isHide: boolean;
  values: ColumnValues[];
  trialBalanceId?: number;
  leadSheetId?: number;
  accountCode?: string;
  isDescManuallyChanged?: boolean;
  isDeleted?: boolean;
  isLineHide?: boolean;
  styles?: Array<any>;
  isDescHide?: boolean;
  isChecked?: boolean;
}

export class Column {
  colId: string;
  name: string;
  isHide: boolean;
  extName?: string;
  format?: 'long' | 'short' | 'year';
  styles?: Array<string>;
  formatValue?: string;
}

export class ColumnValues {
  colId: string;
  colValue: number;
  tbValue?: number;
  styles?: Array<any>;
  extName?: string;
}

export class RenameUpdateModel {
  updatedName: string;
  isUpdate: boolean;
  updateId: string;
  updateInfo: any;
  isBolded?: boolean;
}

export class RenameInputModel {
  name: string;
  id: string;
  info: any;
  isBolded?: boolean;
  isSubheader?: boolean;
}
