import { AfterViewInit, Component, Input, OnDestroy, OnInit, signal, TemplateRef, ViewChild, ViewEncapsulation, WritableSignal } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RoutePath } from 'countable@helpers';
import { DialogInputModel, FirmModel, UserDetailModel, UserModel } from 'countable@model';
import { Subscription } from 'rxjs';
import { ReviewService } from 'src/app/services/review.service';
import { TimeTrackerService } from 'src/app/services/time-tracker.service';
import { AclModel } from '../../../model/acl.model';
import { BillingModel } from '../../../model/billing/billing.model';
import { AclService } from '../../../services/acl.service';
import { AuthService } from '../../../services/auth.service';
import { BillingService } from '../../../services/billing.service';
import { FirmService } from '../../../services/firm.service';
import { StorageService } from '../../../services/storage.service';
import { UnsavedChangesDialogComponent } from '../../dialogs/unsavedChangesDialog/unsavedChangesDialog.component';
import { CommonFlexComponent } from '../../remote-shared/common-flex/common-flex.component';
import { SharedService } from '../../shared/shared.service';

@Component({
  selector: 'dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('activeTimeDialog') activeTimeDialog: TemplateRef<any>;
  @ViewChild('unsavedChanges') unsavedChanges: TemplateRef<any>;
  @ViewChild(MatMenuTrigger) unreadPushNotificationTrigger: MatMenuTrigger;
  @Input('permissions') permissions: any;
  engagementDetailsObj: string;
  nameAcronym: any;
  headerTitle: Subscription;
  engagementName: any;
  yearEnd: any;
  profilePic: any = '';
  profileSubscription: Subscription;
  notifyCount: number;
  notifyOverdueCount: number;
  notifyReminderCount: number;
  notifyCoutSub: Subscription;
  notifyOverDueCoutSub: Subscription;
  notifyReminderCoutSub: Subscription;
  disableNotifications: boolean = false;
  uuid: number;
  showUnreadNotifications: boolean = false;
  showOverDueNotifications: boolean = false;
  showReminderNotifications: boolean = false;
  isUserLoggedIn: boolean = false;
  headerStyle: number;
  isOpenTimeTracking = false;
  timeCount: any;
  timerSub: Subscription;
  isTrackerOn: number;
  savechangesstatus: boolean = false;
  isNotificationOn: boolean;
  public mfamandtory: boolean = false;
  firm: FirmModel = null;
  billing: BillingModel;
  user: UserModel;
  userDetail: UserDetailModel;
  acl: AclModel;
  onOffSub: Subscription;
  notificationsub: Subscription;
  savechangesstatusSub: Subscription;
  navigateToRouteSub: Subscription;
  mfamandtorySub: Subscription;
  intialLoginNotifySub: Subscription;
  refreshPEGPACTSubscription: Subscription;
  firmTemplateStyleUnsubscribe: Subscription;
  firmShortName = signal<string>('');
  isMultiFirm = signal<boolean>(false);
  @ViewChild(MatMenuTrigger) menuTriggerForUser: MatMenuTrigger;
  private readonly billingSub: Subscription;
  private readonly firmSubject: Subscription;
  private readonly authSubject: Subscription = null;
  private readonly permissionsSub: Subscription;
  private readonly aclSubject: Subscription;
  private allSubscriptions: Subscription = new Subscription();
  private showLuca: WritableSignal<boolean> = signal<boolean>(false);
  public isClientUser: WritableSignal<boolean> = signal<boolean>(false);

  constructor(public router: Router, private sharedservice: SharedService,
              private timeTracker: TimeTrackerService, private iconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer, private authService: AuthService, private ReviewService: ReviewService,
              private aclService: AclService, private billingService: BillingService, private firmService: FirmService, private dialog: MatDialog) {
    this.aclSubject = this.aclService.subject.subscribe(acl => {
      this.acl = acl;
    });
    this.authSubject = this.authService.subject.subscribe(state => {
      this.user = this.authService.getUser();
      this.userDetail = this.authService.getUserDetail();
    });
    this.billingSub = this.billingService.subject.subscribe(billing => {
      this.billing = billing;
    });
    this.firmSubject = this.firmService.subject.subscribe(firm => {
      if (firm) {
        this.firm = firm;
        if (this.firm?.name) {
          this.firmShortName.set(this.firm?.name.substring(0, 3));
        }
        if (StorageService.applicationModel.hasMultipleFirms) {
          this.isMultiFirm.set(true);
        }
      }
    });
    this.iconRegistry.addSvgIcon(
      'hour_glass',
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/images/hour_glass.svg')
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showLuca.set(true);
    }, 1500);
  }

  ngOnInit() {
    if (!this.userDetail || !this.userDetail.fullName) {
      this.router.navigate(['/login']).then();
    }
    this.timeTracker.timeIconCount().subscribe(count => this.timeCount = count);
    this.timerSub = this.sharedservice.updateTimerCount.subscribe(count => this.timeCount = count);
    this.intialLoginNotifySub = this.sharedservice.intialLoginNotifySubject.subscribe(res => {
      let loggedIn = JSON.parse((localStorage.getItem('isUserLoggedIn')));
      this.isUserLoggedIn = loggedIn ? loggedIn : false;
    });
    this.isTrackerOn = +localStorage.getItem('istimetrackingon');
    this.onOffSub = this.sharedservice.updateTrackerOnOff.subscribe(isOnOff => this.isTrackerOn = isOnOff);
    this.refreshPEGPACTSubscription = this.sharedservice.refreshPEGPACTSubscription.subscribe(result => {
      if (result) {
        this.userDetail = JSON.parse((localStorage.getItem('userDetails')));
      }
    });
    this.notifyCoutSub = this.sharedservice.notifyCountSubject.subscribe(count => {
      this.showUnreadNotifications = false;
      let loggedIn = JSON.parse((localStorage.getItem('isUserLoggedIn')));
      this.isUserLoggedIn = loggedIn ? loggedIn : false;
      this.notifyCount = count;
      this.showUnreadNotifications = this.isUserLoggedIn && this.notifyCount > 0 && !this.userDetail.toshowtermpolicy;
      if (this.showUnreadNotifications) {
        setTimeout(() => {
          this.closePushNotification();
        }, 10000);
      }
    });
    this.notifyOverDueCoutSub = this.sharedservice.notifyOverdueCountSubject.subscribe(count => {
      this.showOverDueNotifications = false;
      this.notifyOverdueCount = count;
      this.showOverDueNotifications = this.isUserLoggedIn && this.notifyOverdueCount > 0;
      if (this.showOverDueNotifications) {
        setTimeout(() => {
          this.closeOverDuePushNotification();
        }, 10000);
      }
    });
    this.notifyReminderCoutSub = this.sharedservice.notifyRemindercountSubject.subscribe(count => {
      this.showReminderNotifications = false;
      this.notifyReminderCount = count;
      this.showReminderNotifications = this.isUserLoggedIn && this.notifyReminderCount > 0;
      if (this.showReminderNotifications) {
        setTimeout(() => {
          this.closeReminderPushNotification();
        }, 10000);
      }
    });
    this.engagementDetailsObj = JSON.parse(localStorage.getItem('selectedEngagementObj'));
    if (this.userDetail) {
      this.uuid = this.userDetail.useracctid;
    }
    this.disableNotifications = this.userDetail?.userroleId == 4;
    if (this.userDetail && this.userDetail.fullName) {
      let matches = this.userDetail.fullName.match(/\b(\w)/g);
      let acronym = matches.join('');
      if (acronym && acronym.length > 2) {
        acronym = acronym.slice(0, 2);
      }
      this.nameAcronym = acronym;
    }
    this.profilePic = this.userDetail ? this.userDetail.profilepicture : '';
    this.headerTitle = this.sharedservice.headerDetails.subscribe(response => {
      if (response) {
        this.engagementName = response.engagementName;
        this.yearEnd = response.yearEnd;
        if (this.yearEnd) {
          localStorage.setItem('yearEnd', this.yearEnd);
        }
        if (this.engagementName) {
          localStorage.setItem('engementName', this.engagementName);
        }
        this.sharedservice.balStatementEngagementName = this.engagementName;
        this.sharedservice.balStmtYearEnd = this.yearEnd;
      }
    });
    try {
      this.profileSubscription = this.sharedservice.profileDetails.subscribe(response => {
        if (response) {
          if (this.userDetail) {
            this.userDetail.fullName = response.fullName ? response.fullName : this.userDetail.fullName;
            this.profilePic = response.profilepicture ? response.profilepicture : this.profilePic;
          }

        }
      });
    } catch (e) { }

    // unread notification
    setTimeout(() => {
      localStorage.removeItem('isUserLoggedIn');
      this.showUnreadNotifications = false;
      this.showReminderNotifications = false;
      this.showReminderNotifications = false;
    }, 10000);

    this.firmTemplateStyleUnsubscribe = this.sharedservice.frimTemplateStyle.subscribe(data => {
      if (data == true) {
        this.headerStyle = 1;
      } else {
        this.headerStyle = 0;
      }
    });

    // notification disable start
    this.notificationsub = this.sharedservice.notificationdbl.subscribe(res => {
      if (res) {
        this.isNotificationOn = res.isNotificationOn;
      }
    });
    // notification disable end
    this.savechangesstatusSub = this.ReviewService.savechangesbtnstatus.subscribe(response => {
      this.savechangesstatus = response;
    });

    this.navigateToRouteSub = this.ReviewService.navigateToRoute.subscribe(response => {
      if (response) {
        this.savechangesstatus = false;
        this.ReviewService.savechangesbtnstatus.next(false);
        this.navigateToRoute(response);
      }
    });
    // notification disable end
    this.mfamandtorySub = this.sharedservice.mandatorymfa.subscribe({
      next: (response) => {
        this.mfamandtory = response;
      }
    });
    this.isClientUser.set(this.userDetail?.userroleId == 4);
  }

  check() {
    if (this.savechangesstatus) {
      const dialogRef = this.dialog.open(UnsavedChangesDialogComponent, {disableClose: true});
      dialogRef.afterClosed().subscribe(closed => {
        if (closed) {
          if (this.router.url.includes(RoutePath.BLANKCHECKLIST_TEMPLATE)){
            this.ReviewService.validateChecklist.next(true);
          } else {
            this.router.navigate(['/dashboard/members/settings/myaccount']);
            this.sharedservice.cancelUnsavedChanges.next(true);
            this.savechangesstatus = false;
            this.ReviewService.savechangesbtnstatus.next(this.savechangesstatus);
          }
        }
      });
    }
  }

  closePushNotification() {
    if (this.showUnreadNotifications) {
      this.showUnreadNotifications = false;
      if (!this.showOverDueNotifications) {
        localStorage.removeItem('isUserLoggedIn');
      }

    }
  }

  closeOverDuePushNotification() {
    if (this.showOverDueNotifications) {
      this.showOverDueNotifications = false;
      if (!this.showUnreadNotifications) {
        localStorage.removeItem('isUserLoggedIn');
      }
    }
  }

  closeReminderPushNotification() {
    if (this.showReminderNotifications) {
      this.showReminderNotifications = false;
      localStorage.removeItem('isUserLoggedIn');
    }
  }

  logout() {
    if (this.savechangesstatus === true) {
      this.ReviewService.savechangesDialog.next('logout');
    } else {
      this.authService.logout();
    }
  }

  storeDropdownState() {
    localStorage.setItem('isOpenTrackerDropdown', 'true');
    this.timeTracker.timeIconCount().subscribe(count => this.sharedservice.updateTimerCount.next(count));
  }

  removeDropdownState() {
    localStorage.setItem('isOpenTrackerDropdown', 'false');
  }

  ngOnDestroy() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
    if (this.headerTitle) {
      this.headerTitle.unsubscribe();
    }

    if (this.notifyCoutSub) {
      this.notifyCoutSub.unsubscribe();
    }
    if (this.notifyOverDueCoutSub) {
      this.notifyOverDueCoutSub.unsubscribe();
    }
    if (this.intialLoginNotifySub) {
      this.intialLoginNotifySub.unsubscribe();
    }
    if (this.firmTemplateStyleUnsubscribe) {
      this.firmTemplateStyleUnsubscribe.unsubscribe();
    }
    if (this.timerSub) {
      this.timerSub.unsubscribe();
    }
    this.onOffSub ? this.onOffSub.unsubscribe() : '';
    if (this.billingSub) {
      this.billingSub.unsubscribe();
    }
    if (this.firmSubject) {
      this.firmSubject.unsubscribe();
    }
    if (this.authSubject) {
      this.authSubject.unsubscribe();
    }
    if (this.permissionsSub) {
      this.permissionsSub.unsubscribe();
    }
    if (this.mfamandtorySub) {
      this.mfamandtorySub.unsubscribe();
    }
    if (this.aclSubject) {
      this.aclSubject.unsubscribe();
    }
    this.allSubscriptions && this.allSubscriptions.unsubscribe();
  }

  navigateToRoute(from: 'My Account' | 'Whats New' | 'Settings' | 'Billing' | 'Video Tutorials' | 'Leave a Feedback' | 'Contact Support' | 'logout') {
    switch (from) {
      case 'My Account':
        this.router.navigate(['/dashboard/members/settings/myaccount']);
        break;
      case 'Whats New':
        this.router.navigate(['/dashboard/whats-new/announcement']);
        break;
      case 'Settings':
        this.router.navigate(['/dashboard/members/settings/myaccount']);
        break;
      case 'Billing':
        this.router.navigate(['/dashboard/members/settings/billing']);
        break;
      case 'Video Tutorials':
        this.router.navigate(['/dashboard/help/video-tutorials']);
        break;
      case 'Leave a Feedback':
        this.router.navigate(['/dashboard/help/feedback']);
        break;
      case 'Contact Support':
        this.router.navigate(['/dashboard/help/support']);
        break;
      case 'logout':
        this.logout();
        break;
    }
  }

  // switch multiple firms
  openFirmSwitchDialog(dialogType) {
    const data: DialogInputModel = new DialogInputModel();
    data.dialogType = dialogType;
    data.dialogHeader = '';
    data.data = '';
    const config: MatDialogConfig<any> = new MatDialogConfig<any>();
    config.data = {...data};
    config.disableClose = false;
    config.id = dialogType;
    this.sharedservice.dialogService.open(CommonFlexComponent, config, dialogType).subscribe();
  }

}
