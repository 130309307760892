import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DIALOG_TYPES } from 'countable@helpers';
import { DialogResponseModel, LucaDialogInputModel } from 'countable@model';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DialogService {

  private dialogRef: MatDialogRef<any>;
  private dialogSubject: Subject<any> = new Subject<any>();
  public dialogClosedResponse: Observable<DialogResponseModel> = this.dialogSubject.asObservable();
  public setSelectedData: any;

  constructor(public dialog: MatDialog) { }

  open(component: any, config: MatDialogConfig<any>, executeActionType: DIALOG_TYPES): Observable<DialogResponseModel> {
    this.dialogRef = this.dialog.open(component, config);
    return new Observable<DialogResponseModel>(observer => {
      this.dialogRef.afterClosed().subscribe(result => {
        //console.log('CLOSEING-AFTER_SUBSCRIBED',result);
        const responseObj = this.setDialogResponseData(config, executeActionType, result);
        observer.next(responseObj);
        observer.complete();
      });
    });
  }

  private setDialogResponseData(config: MatDialogConfig<any>, executeActionType: DIALOG_TYPES, dialogResult: boolean): DialogResponseModel {
    const responseObj: DialogResponseModel = new DialogResponseModel();
    responseObj.executeAction = dialogResult;
    responseObj.executeActionType = executeActionType;
    switch (executeActionType) {
      case 'delete_checklist':
        responseObj.responseData = config.data?.data || null;
        break;
      case 'save_new_checklist':
        responseObj.responseData = this.setSelectedData ? { FirmChecklistTemplateid: this.setSelectedData.FirmChecklistTemplateid, submoduleid: this.setSelectedData.Sourcesubmoduleid, checkListStandardId: this.setSelectedData.ChecklistStandardid } : null;
        break;
      case 'replace_checklist':
        responseObj.responseData = this.setSelectedData ? { FirmChecklistTemplateid: this.setSelectedData.FirmChecklistTemplateid, submoduleid: this.setSelectedData.Sourcesubmoduleid, checkListStandardId: this.setSelectedData.ChecklistStandardid } : null;
        break;
      case 'move_leftsidedoc':
        responseObj.responseData = this.setSelectedData || null;
        break;
      case 'add_letter':
        responseObj.responseData = this.setSelectedData ? this.setSelectedData : null;
        break;
      default:
        responseObj.responseData = this.setSelectedData ? this.setSelectedData : null;

    }
    //console.log('METHOD-setDialogResponseData',responseObj);
    return responseObj
  }


  close(): void {
    if (this.dialogRef) {
      //console.log('CLOSING-FORCEFULLY');
      this.dialogRef.close();
      this.dialogRef = null;
      this.setSelectedData = null;
    }
  }

  public resetDialogResponse() {
    this.dialogSubject.next(null);
  }

  async openDialog(dialogInfo: LucaDialogInputModel): Promise<MatDialogRef<any>> {
    const chunk = await import(
      '../components/ai-bot/ai-bot-window/ai-bot-window.component'
      );

    const dialogComponent = Object.values(chunk)[0] as ComponentType<unknown>;
    return this.dialog.open(dialogComponent, {
      data: structuredClone(dialogInfo),
      disableClose: true,
      width: '1600px',
      hasBackdrop: true,
      panelClass: 'custom-mat-dialog-panel'
    });
  }

  public openAiDialog(dialogInfo: LucaDialogInputModel) {

  }

}
