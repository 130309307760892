import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class MessagesService {
    public firmOnboarding: any = {
        emailExit: 'Email Address already exists. Please use another email',
        passwordSuccess: 'Password set successfully',
        passwordInvalid: 'Invalid password. Please reenter the correct password',
        wrongDocument: 'Duplicate data identified in Step 2. Please check and update',
        partnerDeleteSuccess: 'Partner/Staff deleted successfully',
        partnerUpdateSuccess: 'Partner/staff details updated successfully',
        clientDeleteSuccess: 'Client deleted successfully',
        clientUpdateSuccess: 'Client details updated successfully',
        partnerAssign: 'Partner assigned successfully',
        partnerUnassign: 'Partner unassigned successfully',
        firmSaveSuccess: 'Firm details saved successfully'
    };

    public firmPartner: any = {
        emailExit: 'Email address already exists. Please use another email',
        partnerAddSuccess: 'Partner record added successfully',
        partnerDeleteSuccess: 'Partner record deleted successfully',
        partnerUpdateSuccess: 'Partner record updated successfully',
        partnerSaveSuccess: 'Partner details saved successfully',
        partnerAssign: 'Partner assigned successfully'
    };

    public firmPartnerStaff: any = {
        emailExit: 'Email address already exists. Please use another email',
        staffAddSuccess: 'Staff record added successfully',
        staffCSVDeleteSuccess: 'Staff record deleted successfully',
        staffDBDeleteSuccess: 'is deleted successfully',
        staffUpdateSuccess: 'Staff record updated successfully',
        staffSaveSuccess: 'Staff details saved successfully',
        partnerAssign: 'Partner assigned successfully',
        partnerUnassign: 'Partner unassigned successfully',
        noPartnerAssign: 'No Partner assigned yet',
        noPartnerSelected: 'Select Partner and save',
        engagementDelete: 'Selected engagements deleted successfully'
    };

    public firmPartnerClient: any = {
        emailExit: 'Email address already exists. Please use another email',
        clientAddSuccess: 'Client record added successfully',
        clientCSVDeleteSuccess: 'Client record deleted successfully',
        clientDBDeleteSuccess: 'is deleted successfully',
        clientUpdateSuccess: 'Client record updated successfully',
        clientSaveSuccess: 'Client details saved successfully',
        partnerAssign: 'Partner assigned successfully',
        partnerUnassign: 'Partner unassigned successfully',
        noPartnerAssign: 'No Partner assigned yet',
        noPartnerSelected: 'Select Partner and save',
        noManagerAssign: 'No Manager assigned yet',
        noManagerSelected: 'Select Manager and save',
        managerAssign: 'Manager assigned successfully',
        managerUnassign: 'Manager unassigned successfully',
        clientsAssign: 'Clients assigned to successfully',
        engagementDelete: 'Selected engagements deleted successfully'
    };

    public firmSetting: any = {
        roleSuccess: 'Engagement Role saved successfully'
    };

    public firmPartnerProfile: any = {
        profileUpdated: 'Account details updated successfully',
        passwordChange: 'Password changed successfully',
        invalidPassword: 'Incorrect Password. Please try again',
        passwordSetSuccess: 'Password set successfully'
    };

    // Partner Login

    public clientAcceptance: any = {
        addQuestion: 'Newly added question saved successfully',
        deleteQuestion: 'Newly added question deleted successfully',
        addQuestionValidation: 'Please enter your question and press save'
    };

    public document: any = {
        folderCreated: 'New folder created successfully',
        folderUpdated: 'Folder name updated successfully',
        folderDeleted: 'Folder name deleted successfully',
        documentSaved: 'Requested document saved successfully',
        documentReferenced: 'Document referenced successfully',
        folderExist: 'Folder name already exists. Please choose a different name',
        folderNameLimit: 'Max 50 characters allowed'
    };

    public trialBalance: any = {
        noDataError: 'No data available to present. Please refresh again.',
        multipleFileUploadError: 'Upload one file at a time',
        invalidFileUpload: 'Please import valid file type (CSV)',
        accountNoRequired: 'Input required',
        accountNoLimit: 'Max 10 character allowed',
        description: 'Description is required',
        newRowSaved: 'New row saved successfully',
        newRowDeleted: 'Newly added row deleted successfully',
        mappedSuccess: 'Account mapped successfully',
        allMapSuccess: 'All account(s) mapped successfully',
        unmappedSuccess: 'Account unmapped successfully',
        mappedSaveError: 'Please save the map number first',
        LSSaveError: 'Please save the leadsheet code first',
        gifiSaveError: 'Please save the gifi tax code first',
        importedRowDeleteError: 'Imported row cannot be deleted',
        unMapAllAccountsSuccess: 'All account(s) unmapped successfully',
        mapNoAddSuccess:'New Map No. added successfully',
        mapNoDelSuccess:'Map No. deleted successfully',
        errorTaxCodeMessage: `Change from the Asset/Expenses tax code to Liabilities, Equity and Revenue are restricted.
        Please choose different tax code within the respective categories.`,
        recordUnAssignSuccuess: 'Record unassigned successfully',
        autoMapSuccess:'Applied mapping to relevant accounts successfully',
        docDeleteSuccess:'Document deleted successfully',
        orgUpdateSuccess:'Trial balance original column updated successfully',
        gifiSuccess:'Gifi code updated successfully',
        leadSheetDeleteSuccess:'Leadsheet deleted successfully',
        leadSheetUpdateSuccess:'Updated leadsheet name successfully',
        leadSheetNewSuccess:'New leadsheet added successfully',
        leadSheetCodeUpdateSuccess:'Leadsheet code updated successfully',
        descUpdateSuccess:'Updated description successfully',
        allUnAssignSuccess:'All records unassign successfully'
    };

    public ntrDashboard: Object = {
      dialogBoxSwitchHeader : 'You are switching to another engagement',
      dialogBoxSwitchText : 'Are you sure you want to switch to the selected engagement?'
    }
}
