import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ChildWindowService {


  /* In future all logic releated windows opening can be written here */

  private popupWindow : any[] = [];
  public workbookWindow : Window | null = null;
  proceduresWindow: Map<string, Window> = new Map<string, Window>();
  public refreshSourceConnection$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  addPopupWindow(popup :any) {
    this.popupWindow.push(popup);
  }

  closeAllWindows() {
    this.popupWindow.forEach(popup => {
      popup.close();
    });
  }

  resetPopupWindow() {
    this.popupWindow = [];
  }

  addWorkbookWindow(workbookWindow: Window) {
    if(!window) return;
    this.workbookWindow = workbookWindow;
  }

  addWorkSheetWindow(proceduresWindow: Window, uniqueFilePath: string) {
    if (this.proceduresWindow.has(uniqueFilePath)) {
      this.proceduresWindow.get(uniqueFilePath).close();
    }

    this.proceduresWindow.set(uniqueFilePath, proceduresWindow);
  }

  closeWorksheetWindow() {
    if (!this.proceduresWindow) {
      return;
    }
    this.proceduresWindow.forEach((list) => {
      list && list.close();
    });
  }

}
