// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-mode {
  width: 100%;
  margin-left: 0;
  height: 100vh;
}

.inline-mode {
  height: calc(100vh - 100px);
  margin-left: -24px;
  margin-right: -24px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/file-preview/microsoft-sdk/microsoft-sdk.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,cAAA;EACA,aAAA;AACF;;AAEA;EACE,2BAAA;EACA,kBAAA;EACA,mBAAA;AACF","sourcesContent":[".popup-mode {\n  width: 100%;\n  margin-left: 0;\n  height: calc(100vh);\n}\n\n.inline-mode {\n  height: calc(100vh - 100px);\n  margin-left: -24px;\n  margin-right: -24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
