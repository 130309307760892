import { Overlay } from '@angular/cdk/overlay';
import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';
import { UserDetailModel } from 'countable@model';
import { AuthService } from '../../../services/auth.service';
import { SharedService } from '../../shared/shared.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-privacy',
  templateUrl: './terms-privacy.component.html',
  styleUrls: ['./terms-privacy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermsPrivacyComponent implements OnInit, OnDestroy {
  @ViewChild('termsPrivacyDialog') termsPrivacyDialog: TemplateRef<any>;
  @ViewChild('termsPrivacyChangesDialog') termsPrivacyChangesDialog: TemplateRef<any>;
  @ViewChild('scrollDiv') scrollElement: ElementRef;
  @ViewChild('scrollToTopEle') private scrollToTopEle: ElementRef;

  termsPrivacyDialogSubject: Subscription;
  isTermsOfUse: boolean = false;
  titleLabel: string = '';
  userDetails: UserDetailModel;
  acceptedFrom: UntypedFormGroup;
  userData: any;
  selectedIndex: number = 0;
  countryId: number;
  constructor(private dialog: MatDialog, private shareService: SharedService, private apiService: ApiService,
    private fb: UntypedFormBuilder, private spinner: NgxSpinnerService, private overlay: Overlay, private authService: AuthService, private toastr: ToastrService, public router: Router) {
    this.authService.subject.subscribe({
      next: value => {
        this.userDetails = this.authService.getUserDetail();
      }
    })
  }

  ngOnInit() {
    this.acceptedFrom = this.fb.group({
      'accept': new UntypedFormControl('', [Validators.required])
    });
    this.userDetails = this.authService.getUserDetail();
    const siteName = window.location.protocol + '//' + window.location.hostname;
    if (window.location.href == siteName || window.location.href == siteName + '/login') {
      this.countryId = null;
    } else {
      this.countryId = this.userDetails ? this.userDetails.origin_country_id : null;
    }

    this.termsPrivacyDialogSubject = this.shareService.termsPrivacyShow.subscribe(resp => {
      const modalOptions: MatDialogConfig = {
        width: '1290px',
        panelClass: 'terms-privacy-panel',
        disableClose: true,
        scrollStrategy: this.overlay.scrollStrategies.reposition()
      };
      this.acceptedFrom.get('accept').setValue('');
      if (resp == 'terms-of-Use' || resp == 'privacy-policy') {
        this.selectedIndex = (resp == 'terms-of-Use' ? 0 : 1);
        this.titleLabel = (resp === 'terms-of-Use') ? 'Countable Inc. - Terms of Use' : 'Countable Inc. - Privacy Policy';
        this.isTermsOfUse = (resp === 'terms-of-Use') || (resp == 'privacy-policy');
        this.dialog.open(this.termsPrivacyDialog, modalOptions).afterClosed().subscribe();
      } else if (resp == 'changes-to-terms') {
        this.titleLabel = 'Updated Terms of Use and Privacy Policy (Last updated on October 25, 2024)';
        let dialogRef = this.dialog.open(this.termsPrivacyDialog, modalOptions);
        dialogRef.backdropClick().subscribe((res) => {
          if (this.userDetails.toshowtermpolicy) {
            // this.toastr.error('Please review and accept terms & conditions');
          }
        })
      }
      this.setLabel();
    });

    if (this.scrollElement) {
      this.scrollElement.nativeElement.focus();
    }
  }

  onChangeTab(tabIndex) {
    if (tabIndex == 0) {
      this.titleLabel = 'Terms of Use';
    } else if (tabIndex == 1 || (isNaN(this.countryId) && tabIndex == 2)) {
      this.titleLabel = 'Privacy Policy';
    } else {
      this.titleLabel = 'Service Level Agreement';
    }
    this.selectedIndex = tabIndex;
    this.setLabel();
  }

  accept() {
    this.userDetails = this.authService.getUserDetail();
    const data = {
      'procedureName': 'saveusertermpolicy',
      'inputParameters': JSON.stringify({ 'useraccountid': this.userDetails.useracctid })
    };
    this.spinner.show();
    this.apiService.getData(data).subscribe(response => {
      this.spinner.hide();
      let data = JSON.parse(response);
      if (data.toshowtermpolicy == 1) {
        localStorage.setItem('isUserLoggedIn', 'true');
        this.userDetails.toshowtermpolicy = false;
        this.authService.setUserDetail(this.userDetails);
        setTimeout(() => {
          this.userDetails = this.authService.getUserDetail();
          this.shareService.onNotifications.next(0);
          this.shareService.sendNotifySubject.next({ 'uuid': this.userDetails.useracctid, 'isfirm': 1 });
        }, 300);
      }
    }, error => {
      console.log(error.error);
    });
  }

  ngOnDestroy() {
    if (this.termsPrivacyDialogSubject) {
      this.termsPrivacyDialogSubject.unsubscribe();
    }
  }

  public goToPolicyTab(tabIndex: number) {
    this.selectedIndex = tabIndex;
  }

  public isLoginPage(): boolean {
    return (this.router.url === '/login');
  }

  private setLabel() {
    if (this.isLoginPage()) {
      switch (this.selectedIndex) {
        case 0:
          this.titleLabel = 'Countable Inc. - Terms of Use';
          break;
        case 1:
          this.titleLabel = 'Countable Inc. - Privacy Policy Canada';
          break;
        case 2:
          this.titleLabel = 'Countable Inc. - Privacy Policy US';
          break;
        case 3:
          this.titleLabel = 'Countable Inc. - Service Level Agreement';
          break;
        default:
          break;
      }
    } else {
      this.titleLabel = 'Updated Terms of Use and Privacy Policy (Last updated on October 25, 2024)';
    }
    this.scrollToTopEle ? this.scrollToTopEle.nativeElement.scrollTop = 0 : '';
  }

}
