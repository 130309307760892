import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SharedService } from '../../../shared/shared.service';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-partners-capital',
  templateUrl: './partners-capital.component.html',
  styleUrls: ['./partners-capital.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PartnersCapitalComponent implements OnInit {
  /*-------------Property Declarations------------*/
  settings                    : string = 'Settings';
  viewType                    : number;
  isHorizontal                : boolean = false;
  isVertical                  : boolean = false;
  isSettingsEnabled           : boolean = false;
  isExpandedVerticalPanels    : boolean = true;
  isExpandedHorizantalPanels  : boolean = true;
  tbwsToggleDrawer            : any;
  drawerStatus                : any;
  pcViewType                  : any;
  pcViewTypeStatus            : any;
  viewNum                     : number;
  updateViewNum               : number;
  loggedInUserData            : any;
  engagementID                : any;
  selectedEngagement          : any;
  settingsData                : any = [];
  statementview               : any;
  private isEnableSettingsSub : Subscription;
  status: any;
/*-------------Property Declarations Ending------------*/
  constructor(private sharedService     : SharedService,
              private apiService        : ApiService,
              private toaster           : ToastrService,
              private spinner           : NgxSpinnerService)
              {}

  ngOnInit() {
    //
    this.loggedInUserData = JSON.parse((localStorage.getItem('userDetails'))); //useracctid
    this.engagementID = localStorage.getItem('engagementID');
    this.selectedEngagement = JSON.parse((localStorage.getItem('selectedEngagementObj')));
    this.isEnableSettingsSub = this.sharedService.isEnableSettings.subscribe( res => {
      if(res.statementViewType == 0){
        this.isHorizontal = true;
        this.isVertical   = false;
      }
      else{
        this.isHorizontal = false;
        this.isVertical   = true;
      }
    });
    this.getPartnerCapitalSettings();
    //this.setPartnersCapital();
    this.getTbwsToggleDrawerStatus();

  }

  // Default SP
  getPartnerCapitalSettings() {

    let inputData = { "engagementsid": this.engagementID };
    let inputString = JSON.stringify(inputData);
    let data = {
      "procedureName": "getpartnershipaccount",
      "inputParameters": inputString
    }
    this.spinner.show();
    this.apiService.getData(data).subscribe(response => {
      if (response) {
        this.spinner.hide();
        let responseData = JSON.parse(response);
        this.settingsData = responseData[0].setting;
        if(this.settingsData) {
          this.statementview = this.settingsData[0]['statementviewtype'];

          this.isSettingsEnabled = true;

          if(this.statementview == 0){
            //this.viewNum = 0;
            this.isHorizontal = true;
            this.isVertical   = false;
          }
          else if(this.statementview == 1){
            //this.viewNum = 1;
            this.isVertical   = true;
            this.isHorizontal = false;
          }
          //this.getRelatedData(this.viewNum);
        }
        else{
          this.isSettingsEnabled = false;
        }
        this.sharedService.partnersCapitalView.next(this.isSettingsEnabled);

      }
    },
      error => {
        this.spinner.hide();
        this.toaster.warning(error.error);
      })
  }

  updateView(value: number){


    let inputData = {"engagementid":this.engagementID,"statementviewtype": value, "loginid" : this.loggedInUserData.useracctid};
    let inputString = JSON.stringify(inputData);
    let data = {
      "procedureName": "updatestatementviewtype",
      "inputParameters": inputString
    }
    this.apiService.getData(data).subscribe(response => {
      if (response) {
        this.spinner.hide();
        this.getPartnerCapitalSettings();
        this.refreshPreview(value);
        localStorage.setItem('isChanged', value.toString());
      }
    },
    error => {
      this.spinner.hide();
      this.toaster.warning(error.error);
    })
  }
//
  getTbwsToggleDrawerStatus(){
    this.isEnableSettingsSub = this.sharedService.isEnableSettings.subscribe( res => {
      if(res.defaultCount !== 0){
        this.drawerStatus = false;
        /* this.tbwsToggleDrawer = this.sharedService.tbwsToggleDrawerStatus.subscribe(response => {
          this.drawerStatus = response;

        }); */
      }
      else{
        this.drawerStatus = true;
      }
    });
  }
  //
  setPartnersCapital(){
    this.isSettingsEnabled = !this.isSettingsEnabled;
    this.sharedService.partnersCapitalView.next(this.isSettingsEnabled);
    this.sharedService.closePreview.next(false);
  }

  expandPanles(val){
    if( val === 'verticalPanel'){
      this.isExpandedVerticalPanels = !this.isExpandedVerticalPanels;
    }
    else if(val === 'horizontalPanel'){
      this.isExpandedHorizantalPanels = !this.isExpandedHorizantalPanels;
    }

  }

  ngOnDestroy() {
    if(this.isEnableSettingsSub){
      this.isEnableSettingsSub.unsubscribe();
    }
    if(this.tbwsToggleDrawer){
      this.tbwsToggleDrawer.unsubscribe();
    }
    if(this.pcViewType){
      this.pcViewType.unsubscribe();
    }
  }

  refreshPreview(type){
    this.sharedService.refreshPreview.next(type);
  }
}
