import { Injectable } from '@angular/core';
import { EngagementInterface } from '../model/eng/engagement.interface';
import { ApplicationModel } from '../model/storage/application.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public static applicationModel = new ApplicationModel();

  public static reset(): void {
    this.applicationModel.billingProducts = null;
    this.applicationModel.engagements = new Map<number, EngagementInterface>();
    this.applicationModel.storageUsage = null;
    this.applicationModel.firmTeamCount = null;
    this.applicationModel.EngCount = null;
    this.applicationModel.firm = null;
    this.applicationModel.billing = null;
    this.applicationModel.plan = null;
    this.applicationModel.ChangePlanRes = null;
    this.applicationModel.paymentMethodRes = null;
    this.applicationModel.firmDowngrade = null;
    this.applicationModel.flexUsage = null;
  }
}
