import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetailModel } from 'countable@model';
import * as CryptoJS from 'crypto-js';
import { NEVER, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SharedService } from '../components/shared/shared.service';
import { bot, generateId, procedures, updateProcedures } from './app-config';
import { AuthService } from './auth.service';

const textResponse: any = {responseType: 'text'};

@Injectable({
  providedIn: 'root'
})
export class TimeTrackerService {

  userLoginId: number = 0;
  interval: NodeJS.Timer;
  counter: number = 0;
  startTime: any;
  result: any = [];
  automaticStartTime: string;
  userActivity;
  userInactive: Subject<any> = new Subject();
  isTrackerOn: any;
  ttNotifications: any;
  private userDetails: UserDetailModel | null = null;

  constructor(private http: HttpClient, @Inject(LOCALE_ID) private locale: string, public router: Router,
              private authService: AuthService, private sharedService: SharedService) {
    this.isTrackerOn = this.sharedService.getUserData('field', 'istimetrackingon');
    this.authService.subject.subscribe(status => {
      if (!status) {
        this.cleanupBeforeLogout();
      }
    });
    this.userDetails = this.authService.getUserDetail();
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 3000);
  }

  //----------------Time Tracking APIs------------------------------------------------------------
  public getTimeOnOff(): Observable<any> {
    const path = environment.apiV1 + '/time-on-off';
    return this.http.get(path).pipe(map(isOnOff => {
      this.isTrackerOn = isOnOff;
      return isOnOff;
    }));
  }

  public saveTimeOnOff(data): Observable<any> {
    const path = environment.apiV1 + '/save-time-on-off';
    return this.http.post(path, this.enData(data), textResponse);
  }

  getData(inputdata): Observable<any> {
    this.updateSessionData();
    inputdata['loginId'] = this.userLoginId;
    const path = environment.apiV1 + '/get-procedure-json-data';
    return this.http.post(path, this.enData(inputdata), textResponse).pipe(map(res => {
      if (procedures.includes(inputdata.procedureName) == true) {
        return this.deData(res);
      } else {
        return res;
      }
    }));
  }

  getTimeEntries(engagementId): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.enData(engagementId)) + '/all-time-entries';
    return this.http.get(path, textResponse);
  }

  checkEngStatus(loginId): Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.enData(loginId)) + '/engagement';
    return this.http.get(path, textResponse).pipe(map(res => {
      return JSON.parse(this.deData(res));
    }));
  }

  public  getEngagementsDetail(engId:number) :Observable<any> {
    const path = environment.apiV1 + '/' + encodeURIComponent(this.enData(+engId)) + '/engagement-detail';
    return  this.http.get(path, textResponse).pipe(map(res => {
      return JSON.parse(this.deData(res));
    }));
  }

  getDropdownTimeEntries(inputdata): Observable<any> {
    this.updateSessionData();
    return this.http.get(environment.apiV1 + '/eng-time-entries', textResponse);
  }

  getTimeSummaryEntries(loginId, typeId, clientFirmId, engagementId): Observable<any> {
    this.updateSessionData();
    const path = environment.apiV1 + '/all-time-summary?typeId=' + encodeURIComponent(this.enData(typeId)) + '&clientFirmId=' + encodeURIComponent(this.enData(clientFirmId)) + '&engagementsId=' + encodeURIComponent(this.enData(engagementId));
    return this.http.get(path, textResponse);
  }

  updateSessionData() {
    this.isTrackerOn = +localStorage.getItem('istimetrackingon');
  }

  enData(data, type?) {
    var encryptedBase64Key = btoa(this.botConversion());//"U0Ajc2MlI20xMjk4LTEyIyQtMXJneFplZEUtOTAsIyE=";
    var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
    try {
      let enc = CryptoJS.AES.encrypt(JSON.stringify(data), parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString();
      if (type == undefined) {
        return enc.split('/').join(',');
      } else {
        return enc;
      }

    } catch (e) {
      // console.log(e);
    }
  }

  deData(data) {
    var encryptedBase64Key = btoa(this.botConversion());
    var parsedBase64Key = CryptoJS.enc.Base64.parse(encryptedBase64Key);
    try {
      const bytes = CryptoJS.AES.decrypt(data, parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (e) {
      console.log(e);
    }
  }

  botConversion() {
    let botConversion = atob(bot);
    let botSplit = botConversion.split('-');
    botSplit.shift();
    botSplit.pop();
    botSplit.splice(2, 1);
    return botSplit.join('-');
  }

  startTimer(isIdle?: string) {
    let isIdleStop = 0;
    if (isIdle == 'Active' && localStorage.getItem('timerStatus') == 'Idle') {
      isIdleStop = 1;
    }
    localStorage.setItem('timerStatus', isIdle);
    this.updateSessionData();
    this.startTime = formatDate(new Date(), 'hh:mm a', this.locale);
    this.automaticStartTime = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', this.locale);
    this.interval = setInterval(() => {
      this.counter++;
    }, 1000);
    setTimeout(() => {
      let data = JSON.parse(localStorage.getItem('timeTrackerObj'));
      this.saveTimer(data, localStorage.getItem('timerStatus') == 'Idle' ? 1 : 0, isIdleStop);
    }, 1000);

  }

  terminateTimer() {
    this.updateSessionData();
    let data = JSON.parse(localStorage.getItem('timeTrackerObj'));
    if(!data) return;
    if(!this.userLoginId) {this.userDetails = this.authService.getUserDetail()}
    data['loginId'] = this.userLoginId ? this.userLoginId : this.userDetails.useracctid;
    data['isIdleTime'] = localStorage.getItem('timerStatus') == 'Idle' ? 1 : 0;
    data['endTime'] = formatDate(new Date(), 'hh:mm a', this.locale);
    data['automaticEndTime'] = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', this.locale);
    if (!this.isTrackerOn) {
      this.endTrackerSession(data).subscribe(res => {
        localStorage.removeItem('timerStatus');
        if (localStorage.getItem('isOpenWB') == 'false' || localStorage.getItem('isOpenAAE') == 'false' || localStorage.getItem('isOpenNAE') == 'false') {
          let data = JSON.parse(localStorage.getItem('timeTrackerObj'));
          data['submoduleId'] = +localStorage.getItem('submoduleid');
          data['submoduleUuid'] = localStorage.getItem('UUID');
          localStorage.setItem('timeTrackerObj', JSON.stringify(data));
          if (localStorage.getItem('isOpenWB') == 'false') {
            localStorage.removeItem('isOpenWB');
          }
          if (localStorage.getItem('isOpenAAE') == 'false') {
            localStorage.removeItem('isOpenAAE');
          }
          if (localStorage.getItem('isOpenNAE') == 'false') {
            localStorage.removeItem('isOpenNAE');
          }
        }
      }, error => {
        localStorage.removeItem('timerStatus');
      });
    }
  }

  saveTimer(data, isIdle?, isIdleStop?) {
    this.updateSessionData();
    data['teamUserAccountId'] = this.userLoginId;
    data['timeTrackingEntryId'] = 1; //automatic: 1 for billable, Manual: 0 non billable/1 billable
    data['timeTrackingTypeId'] = 1; //1-automatic, 2 - Manual
    data['timeCategoryId'] = 0; //automatic - 0, Manual- dropdown id
    data['isIdleTime'] = isIdle; //For Idle: 1, Active: 0
    data['date'] = formatDate(new Date(), 'yyyy-MM-dd', this.locale);
    data['startTime'] = this.startTime;
    data['automaticStartTime'] = this.automaticStartTime;
    data['endTime'] = '';
    data['duration'] = '';
    data['timeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
    data['notes'] = null;
    data['loginId'] = this.userLoginId;
    clearInterval(this.interval);
    this.counter = 0;
    let engId = data.engagementsId;
    let status = 0;
    if (!this.isTrackerOn) {
      this.getEngagementsDetail(engId).subscribe(res => {

        if (res.engagementsid == engId) {
          status = res.statusid;
        }

        const engagementId = data.engagementsId ? data.engagementsId : 0;
        if (status == 18) { } else if (status == 19) { } else {
          if (isIdle) {
            this.sendNotification(engagementId, 2);
            this.getNotificationDetails(engagementId);
          } else if (isIdleStop) {
            this.sendNotification(engagementId, 3);
            this.getNotificationDetails(engagementId);
          }
          this.saveEngTimeEntry(data).subscribe(res => {
            if (localStorage.getItem('timerStatus') && localStorage.getItem('isOpenTrackerDropdown') == 'true') {
              this.sharedService.refreshRecordBtn.next(true);
            }
          });
        }

      });
    }
  }

  terminateNotification(engagementsId) {
    let isIdle = localStorage.getItem('timerStatus') == 'Idle' ? 1 : 0;
    if (!this.isTrackerOn) {
      const engagementId = engagementsId ? engagementsId : 0;
      if (isIdle) {
        this.sendNotification(engagementId, 3);
        this.getNotificationDetails(engagementId);
      } else {
        this.sendNotification(engagementId, 1);
        this.getNotificationDetails(engagementId);
      }
    }
  }

  getNotificationDetails(engagementId: any) {
    const inputData = {'loginid': this.userLoginId};
    const inputString = JSON.stringify(inputData);

    let data = {
      'procedureName': 'gettracknotification',
      'inputParameters': inputString,
      'inputEnc': generateId[Math.floor(Math.random() * generateId.length)]
    };
    this.getData(data).subscribe(response => {
      if (response) {
        this.result = JSON.parse(response);
        this.result.filter(function (element) {
          return (element.engagementsid === engagementId);
        });
      }
    });
  }

  sendNotification(engagementId: any, notifId: any) {
    const inputData = {'loginid': this.userLoginId};
    const inputString = JSON.stringify(inputData);

    let data = {
      'procedureName': 'getnotificationstatus',
      'inputParameters': inputString,
      'inputEnc': generateId[Math.floor(Math.random() * generateId.length)]
    };
    this.getData(data).subscribe(response => {
      let result: any = [];
      if (response) {
        result = JSON.parse(response);
        this.ttNotifications = result.timetrackingnotificatinons;
        const startTrackerObject = {'loginId': this.userLoginId, 'engagementsId': engagementId};
        if (notifId == 2 && this.ttNotifications[0].childs[2].isnotify) {
          this.notifyIdleStart(startTrackerObject).subscribe(res => {
            if (res === 'Success') {
              this.sharedService.sendNotifySubject.next({'uuid': this.userLoginId, 'isfirm': 0});
            }
          });
        } else if (notifId == 0 && this.ttNotifications[0].childs[0].isnotify) {
          this.startTimeTracker(startTrackerObject).subscribe(res => {
            if (res === 'Success') {
              this.sharedService.sendNotifySubject.next({'uuid': this.userLoginId, 'isfirm': 0});
            }
          });
        } else if (notifId == 3 && this.ttNotifications[0].childs[3].isnotify) {
          this.notifyIdleStop(startTrackerObject).subscribe(res => {
            if (res === 'Success') {
              this.sharedService.sendNotifySubject.next({'uuid': this.userLoginId, 'isfirm': 0});
            }
          });
        } else if (notifId == 1 && this.ttNotifications[0].childs[1].isnotify) {
          this.notifyEndTime(startTrackerObject).subscribe(res => {
            if (res === 'Success') {
              this.sharedService.sendNotifySubject.next({'uuid': this.userLoginId, 'isfirm': 0});
            }
          });
        }
      }
    }, () => {
    });
  }

  getDashboardTimeEntries(): Observable<any> {
    // return this.http.get(environment.apiV1 + '/time-tracker-dashboard', textResponse);
    return new Observable();
  }

  private cleanupBeforeLogout() {
    if(JSON.parse(localStorage.getItem("timeTrackerObj"))) {
      this.terminateTimer();
    }
  }

  //save-eng-time-entry
  saveEngTimeEntry(inputData) {
    return this.http.post(environment.apiV1 + '/save-eng-time-entry', this.enData(inputData), textResponse);
  }

  notifyIdleStop(inputData): Observable<any> {
    return this.http.post(environment.apiV1 + '/notify-end-idle-time', this.enData(inputData), textResponse);
  }

  notifyIdleStart(inputData): Observable<any> {
    return this.http.post(environment.apiV1 + '/notify-start-idle-time', this.enData(inputData), textResponse);
  }

  notifyEndTime(inputData): Observable<any> {
    return this.http.post(environment.apiV1 + '/notify-end-time-tracking', this.enData(inputData), textResponse);
  }

  notifyManualTime(inputData): Observable<any> {
    return this.http.post(environment.apiV1 + '/notify-manual-time', this.enData(inputData), textResponse);
  }

  //submit-time-entry
  submitTimeEntry(inputData) {
    return this.http.post(environment.apiV1 + '/submit-time-entry', this.enData(inputData), textResponse);
  }

  saveData(data) {
    let inputData;
    data['loginId'] = this.userLoginId;
    if (updateProcedures.includes(data.procedureName) == true) {
      data.inputParameters = this.enData(data.inputParameters, 'body');
      inputData = data;
    } else {
      inputData = data;
    }
    return this.http.post(environment.apiV1 + '/update-procedure-json-data', this.enData(inputData), textResponse);
  }

  logTimeEntries(inputData) {
    return this.http.post(environment.apiV1 + '/log-eng-time-entry', this.enData(inputData), textResponse);
  }

  startTimeTracker(inputData): Observable<any> {
    return this.http.post(environment.apiV1 + '/notify-start-time-tracking', this.enData(inputData), textResponse);
  }

  timeIconCount(): Observable<any> {
    this.updateSessionData();
    return NEVER;
    // return this.http.get(environment.apiV1 + '/time-icon-count', textResponse);
  }

  //common get api
  getApiData(relativePath): Observable<any> {
    return this.http.get(environment.apiV1 + '/' + relativePath, textResponse);
  }

  //common save api
  saveApiData(relativePath, inputData) {
    return this.http.post(environment.apiV1 + '/' + relativePath, this.enData(inputData), textResponse);
  }

  endTrackerSession(inputData) {
    return this.http.post(environment.apiV1 + '/end-time-tracking', this.enData(inputData), textResponse);
  }
}
