import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AdobeEmbedPdfService } from '../../../../services/adobe-embed-pdf.service';
import { LoadScriptService } from '../../../../services/load-script.service';

const viewerConfig = {
  embedMode: 'FULL_WINDOW', // 'FULL_WINDOW', SIZED_CONTAINER, IN_LINE
  defaultViewMode: 'FIT_PAGE', // FIT_WIDTH
  showAnnotationTools: false,
  showCommentsPanel: true,
  dockPageControls: false,
  showLeftHandPanel: false,
  showPageControls: true,
  showDownloadPDF: true,
  showPrintPDF: true,
  showToolbar: true,
  downloadWithAnnotations: true,
  printWithAnnotations: true,
  enableFormFilling: true
};

const editorConfig = {
  embedMode: 'FULL_WINDOW',
  defaultViewMode: 'FIT_WIDTH',
  showAnnotationTools: true,
  dockPageControls: true,
  showLeftHandPanel: true,
  showPageControls: true,
  showDownloadPDF: true,
  showPrintPDF: true,
  showToolbar: true,
  showCommentsPanel: true,
  downloadWithAnnotations: true,
  printWithAnnotations: true,
  enableFormFilling: true
};

@Component({
  selector: 'app-file-preview-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss'],
  providers: [
    AdobeEmbedPdfService
  ]
})
export class PdfComponent implements OnInit, OnChanges {

  static readonly PDF_STORAGE_PREFIX = 'pdf-';
  static readonly PDF_ERROR_TEXT = 'The pdf file can not be shown!';
  static readonly PDF_ERROR_TITLE = 'Technical error!';
  public hideEditButton : boolean = false;
  @Input() fileType: string;
  @Input() isInline: boolean;
  @Input() hash: string;
  @Input() viewMode = 'view';
  @Input() isReadOnly :boolean = false;
  constructor(
    private adobeEmbedPdfService: AdobeEmbedPdfService,
    private scriptService: LoadScriptService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private toaster: ToastrService) {
  }

  ngOnInit() {
    this.scriptService.loadScript(LoadScriptService.ADOBE_PDF_VIEWER);
    this.hideEditButton = this.router.url.includes('final-fs-package/file-preview');
  }

  ngOnChanges() {
    if (!this.hash || !localStorage.getItem(PdfComponent.PDF_STORAGE_PREFIX + this.hash)) {
      this.toaster.error(PdfComponent.PDF_ERROR_TEXT, PdfComponent.PDF_ERROR_TITLE);
      return;
    }
    this.loadTheFile(JSON.parse(localStorage.getItem(PdfComponent.PDF_STORAGE_PREFIX + this.hash)));
  }

  loadTheFile(fileData) {
    this.adobeEmbedPdfService.ready().then(() => {
      this.adobeEmbedPdfService.setFileData(fileData);
      this.adobeEmbedPdfService.previewFile('pdf_div', this.viewMode === 'view' ? viewerConfig : editorConfig);
    }).then(() => {
      this.adobeEmbedPdfService.registerSaveApiHandler();
      this.adobeEmbedPdfService.registerEventsHandler();
    });
  }

  openForEdit() {
    this.router.navigate(['../edit', {}], {relativeTo: this.activatedRoute, replaceUrl: true});
  }

  openForView() {
    this.router.navigate(['../view', {}], {relativeTo: this.activatedRoute, replaceUrl: true});
  }
}
