export class AssignTeamModel {
  fullName: string;
  clientAuditorId: number;
}

export class EngagementFilteredListModel {
  totalengagement: number;
  current: number;
  completed: number;
  archived: number;
  totalclient: number;
}

export class clientEngagementsListModel {
  filteredListObj: EngagementFilteredListModel;
  sortedEngCount: number;
  engagementsList: any[];
  engagementsAvailable: boolean;
  rawResponse: any[];
}

export class EngagementIntegrationInfo {
  logoPath: string;
  integrationId: number;
  integrationName: string;
  orgName: string;
  isActiveConnection: boolean;
  isConnected: boolean;
  classId: 1 | 2 | 3 | 4 | 5 | 6;
  status?: string;
  sourceName?: 'QUICKBOOK' | 'XERO' | 'SAGE' | 'CSV' | 'NONE';
}

export class EnagegementNavigationStartModel {
  userLoginId: number;
  clientFirmId?: number | null;
  startedPlace: "dashboard" | "fromEngList" | "fromNtrDashboard";
  engagemenId: number | null;
  routerUrl: "/dashboard/members/client-engagements" | "/dashboard/engagement/engagement-list";
  engListData?: Array<any> | null;
  rawEngsList?: Array<any> | null;
}

export class EngagementResponseModel {
  firmName: string;
  name: string;
  procedureName: 'savenewengagement' | 'updatenewengagement';
  accessLink: string;
  action: string;
}

// engagement.model.ts

export interface EngagementTypeDetails {
  id: number;
  engagementType: string;
  partOfId: number;
  parentEngagementTypeId: number;
  isActive: boolean;
  isDelete: boolean;
  createdBy: string;
  modifiedBy: string;
  createdDate: string;
  modifiedDate: string;
  entTypeuid: string;
  originCountryId: number;
}
