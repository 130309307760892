import { NUMBER_STATUS } from 'countable@helpers';
import { AmortizationInputsModel, AmortizationScheduleModel } from 'countable@model';

export class CalculationsUtil {
  static formatScheduleRows(scheduleRows: AmortizationScheduleModel[]) {
    scheduleRows && scheduleRows?.forEach(r => {
      r.closingBal = this.formatNumber(r?.closingBal);
      r.openingBalTb = this.formatNumber(r?.openingBalTb);
      r.amortization = this.formatNumber(r?.amortization);
      r.closingBalTb = this.formatNumber(r?.closingBalTb);
      r.currYear = this.formatNumber(r?.currYear);
      r.prevYear = this.formatNumber(r?.prevYear);
      r.balTb = this.formatNumber(r?.balTb);
      r.underOver = this.formatNumber(r?.underOver);
      r.openingBalOne = this.formatNumber(r?.openingBalOne);
      r.addAdj = this.formatNumber(r?.addAdj);
      r.disAdj = this.formatNumber(r?.disAdj);
    });
    return structuredClone(scheduleRows);
  }

  static formatNumber(value: any) {
    if (!value) {
      return '0.00';
    }
    if (typeof value === 'string' && value.includes(',')) {
      return value.replace(/,/g, '');
    }
    if (typeof value === 'string' && value.includes('(') && value.includes(')')) {
      value = value.replace(/[()]/g, '');
      value = `-${value}`;
    }
    return value;
  }

  static getUpdatedTotalAndRowValue(total: any, rowValue: any, isConvertNumber: boolean = false) {
    const formattedTotal = this.formatNumber(total);
    let updatedRowValue = Number(parseFloat(rowValue).toFixed(2)).toLocaleString('en-CA', {
      minimumFractionDigits: 2
    });
    if (isConvertNumber) {
      updatedRowValue = updatedRowValue.replace('-', '(') + updatedRowValue.substring(updatedRowValue?.length) + ')';
    }
    const totalVal = (Number(parseFloat(formattedTotal)?.toFixed(2)) + parseFloat(rowValue));
    return [this.convertValue(totalVal), updatedRowValue];
  }

  static convertValue(value: any) {
    let updatedVal = value?.toLocaleString('en-CA', {
      minimumFractionDigits: 2
    });
    ;
    const valStatus = this.statusOfValue(value);
    if (valStatus === 'NOT_NUMBER') {
      return '0.00';
    }
    if (valStatus === 'NEGATIVE') {
      return updatedVal.replace('-', '(') + updatedVal.substring(updatedVal?.length) + ')';
    }
    return updatedVal;
  }

  static statusOfValue(input: any): NUMBER_STATUS {
    const updatedInput = this.formatNumber(input);
    const val = Math.sign(parseFloat(updatedInput));
    if (isNaN(val)) {
      return 'NOT_NUMBER';
    }
    if (val === 1 || val === 0) {
      return 'POSITIVE';
    }
    if (val === -1) {
      return 'NEGATIVE';
    }
  };

  static calculateDifference(value1: string, value2: string): number {
    const num1 = this.parseValue(value1);
    const num2 = this.parseValue(value2);

    const updatedValue = num1 - num2;
    return this.transformValue(Math.abs(updatedValue));
  }

  static parseValue(value: string): number {
    if ((value?.includes('(') || value?.includes(')')) && value?.startsWith('(') && value?.endsWith(')')) {
      value = value.slice(1, -1); // Remove the parentheses
      return -parseFloat(value.replace(/,/g, ''));
    }
    return parseFloat(value.replace(/,/g, ''));
  }

  static transformValue(value: any) {
    if (isNaN(value)) {
      return value;
    }
    const absoluteValue = Math.abs(value);
    const formattedValue = absoluteValue.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
    if (value < 0) {
      return `(${formattedValue})`;
    }
    return formattedValue;
  }

  static getRowInfo(rowType: 'TB' | 'TOTAL' | 'Difference' | 'Balance' | 'Total') {
    const totalRow = new AmortizationScheduleModel();
    totalRow.rowType = rowType;
    totalRow.description = rowType;
    return structuredClone(totalRow);
  }

  static calculateDiffBtwTotalAndGL(calTotalRow: AmortizationScheduleModel, balanceRow: AmortizationScheduleModel) {
    const diffRow = new AmortizationScheduleModel();
    diffRow.description = 'Difference';
    diffRow.rowType = 'Difference';
    diffRow.openingBalOne = this.calculateDifference(calTotalRow?.openingBalOne?.toString(), balanceRow?.openingBalOne?.toString());
    diffRow.closingBal = this.calculateDifference(calTotalRow?.closingBal?.toString(), balanceRow?.closingBal?.toString());
    diffRow.openingBalTb = this.calculateDifference(calTotalRow?.openingBalTb?.toString(), balanceRow?.openingBalTb?.toString());
    diffRow.closingBalTb = this.calculateDifference(calTotalRow?.closingBalTb?.toString(), balanceRow?.closingBalTb?.toString());
    diffRow.currYear = this.calculateDifference(calTotalRow?.currYear?.toString(), balanceRow?.currYear?.toString());
    diffRow.prevYear = this.calculateDifference(calTotalRow?.prevYear?.toString(), balanceRow?.prevYear?.toString());
    diffRow.addAdj = '';
    diffRow.disAdj = '';
    diffRow.amortization = '';
    diffRow.balTb = '';
    diffRow.underOver = '';
    return diffRow;
  }

  static parseAndReturnAbs(input: any) {
    if (input === 0 || input === '0.00' || !input) {
      return 0;
    }
    let cleanedInput = input.replace(/,/g, '');
    if (cleanedInput.startsWith('(') && cleanedInput.endsWith(')')) {
      cleanedInput = '-' + cleanedInput.slice(1, -1);
    }
    return Math.abs(parseFloat(cleanedInput));
  }

  static calculateAmortization(input: AmortizationInputsModel, OB1: any) {
    const updatedOb1 = this.parseAndReturnAbs(OB1);
    const updatedRate = this.parseAndReturnAbs(input?.rate);
    const updatedUL = this.parseAndReturnAbs(input?.usefulLife);
    const updatedRL = this.parseAndReturnAbs(input?.remainingLife);
    const updatedRSV = this.parseAndReturnAbs(input?.residualValue);
    const updatedEP = this.parseAndReturnAbs(input?.estimatedProduction);
    const updatedUP = this.parseAndReturnAbs(input?.unitProduced);
    if (input?.methodId === 1) {
      const updatedRV = this.parseAndReturnAbs(input?.residualValue);
      const updatedUL = this.parseAndReturnAbs(input?.usefulLife);
      const cal1 = (updatedOb1 - updatedRV) / updatedUL;
      return this.transformValue(cal1);
    }
    if (input?.methodId === 2) {
      const cal2 = (updatedOb1 * (updatedRate / 100));
      return this.transformValue(cal2);
    }
    if (input?.methodId === 5) {
      const soy = (updatedUL * (updatedUL + 1)) / 2;
      const soyd = (updatedRL / soy) * (updatedOb1 - updatedRSV);
      return this.transformValue(soyd);
    }
    if (input?.methodId === 4) {
      const uop = (((updatedOb1 - updatedRSV) / updatedEP) * updatedUP);
      return this.transformValue(uop);
    }
  }

  static performCalculation(valueA: any, valueB: any, type: 'ADD' | 'SUB' = 'SUB'): string {
    function parseNumber(input: any): number {
      let cleanedInput = input.replace(/,/g, '');
      if (cleanedInput.startsWith('(') && cleanedInput.endsWith(')')) {
        cleanedInput = '-' + cleanedInput.slice(1, -1);
      }
      return parseFloat(cleanedInput);
    }
    const valueANum = parseNumber(valueA);
    const valueBNum = parseNumber(valueB);
    let result = 0;
    if (type === 'ADD') {
      result = valueANum + valueBNum;
    } else if (type === 'SUB') {
      result = valueANum - valueBNum;
    }
    const formattedResult = result < 0
      ? `(${Math.abs(result).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})})`
      : result.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});

    return formattedResult;
  }

  static formatScheduleRow(model: AmortizationScheduleModel): AmortizationScheduleModel {
    const keysToSkip = ['accNo', 'accountUUID', 'amortTbId', 'description', 'rowType', 'trialBalId'];

    return Object.keys(model).reduce((acc, key) => {
      if (keysToSkip.includes(key)) {
        acc[key] = model[key];
      } else {
        let value = model[key];
        if (!value || isNaN(Number(value.replace(/,/g, '').replace(/[()]/g, '')))) {
          acc[key] = 0;
        } else {
          let cleanValue = value.replace(/,/g, '');
          if (cleanValue.startsWith('(') && cleanValue.endsWith(')')) {
            cleanValue = cleanValue.replace(/[()]/g, '');
            acc[key] = -Math.abs(Number(cleanValue));
          } else {
            acc[key] = Math.abs(Number(cleanValue));
          }
        }
      }
      return acc;
    }, {} as AmortizationScheduleModel);
  }

  static formatNAStandards(rows: AmortizationScheduleModel[]): AmortizationScheduleModel[] {
    const keysToSkip = ['accNo', 'accountUUID', 'amortTbId', 'description', 'rowType', 'trialBalId'];

    return rows.map(row => {
      const transformedRow: any = {};
      Object.keys(row).forEach(key => {
        if (!keysToSkip.includes(key) && typeof row[key as keyof AmortizationScheduleModel] === 'number') {
          transformedRow[key] = this.formatNANumber(row[key as keyof AmortizationScheduleModel] as number);
        } else {
          transformedRow[key] = row[key as keyof AmortizationScheduleModel];
        }
      });
      return transformedRow;
    });
  }

  static formatNANumber(value: number | undefined): string {
    if (typeof value !== 'number' || isNaN(value)) {
      return '0.00';
    }
    return value < 0 ? `(${Math.abs(value).toFixed(2)})` : value.toFixed(2);
  };

  static formatInputRows(rows: AmortizationInputsModel[]) {
    return rows?.map(item => {
      ['residualValue', 'estimatedProduction', 'unitProduced', 'remainingLife'].forEach(key => {
        const value = item[key];
        if (!value || value === 0 || isNaN(value)) {
          item[key] = '0.00';
        } else {
          item[key] = this.formatToNorthAmericanStandard(value);
        }
      });
      return item;
    }) ?? [];
  }

  static formatToNorthAmericanStandard(value: number): string {
    return parseFloat(value.toString())
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
