import { AfterViewInit, Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogInputModel } from 'countable@model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { AclModel } from 'src/app/model/acl.model';
import { BillingModel } from 'src/app/model/billing/billing.model';
import { ProductModel } from 'src/app/model/billing/product.model';
import { FirmModel } from 'src/app/model/firm/firm.model';
import { AclService } from 'src/app/services/acl.service';
import { BillingService } from 'src/app/services/billing.service';
import { EngApiService } from 'src/app/services/eng-api.service';
import { FirmService } from 'src/app/services/firm.service';
import { EngCountModel } from '../../../../model/eng/eng-count.model';
import { ApiService } from '../../../../services/api.service';
import { generateId } from '../../../../services/app-config';
import { IntegrationService } from '../../../../services/integration.service';
import { StorageService } from '../../../../services/storage.service';
import { SharedService } from '../../../shared/shared.service';
import { CommonDialogComponent } from '../../NTR_Dashboard/common-dialog/common-dialog.component';

@Component({
  selector: 'engagements-list',
  templateUrl: './engagements-list.component.html',
  styleUrls: ['./engagements-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EngagementsListComponent implements OnInit, AfterViewInit {

  @ViewChild('clientDialog') showAllClientDetails: TemplateRef<any>;
  @ViewChild('engInfoDialog') engInfoDialog: TemplateRef<any>;
  @ViewChild('freeEngagementDialog') freeEngagementDialog: TemplateRef<any>;

  totalEngagement: any;
  current: any;
  completed: any;
  loggedInUserData: any;
  auditorId: any;
  clientEngData: any;
  updatedEngagementActivities: Subscription;
  updatedEngStatusDetails: Subscription;
  updateSelectedEngObjSub: Subscription;
  updatedEngStatusObj: any; /* Client Eng detail obj property */
  selectedEngCount: boolean = false;
  isMultiEngDialog: boolean = false;
  updateSelectedEngObj: any;
  clientStatusId: number;
  firmClients: any;
  engagementTypes: any = [];
  clientInformation: any;
  selectClientForm: UntypedFormGroup;
  completedArchiveEngSub: any;
  completedArchiveEngSelected: any;
  selectedValue: string = '';
  accessPermissions: any;
  permissionsSub: Subscription;
  isCreateEngagementDisable: boolean = true; // For this issue kept this condition NV-5528
  isEngagementAccess: boolean = true;
  createEngagementDisabled: boolean = false;
  clientData: any;
  archived: any;
  billing: BillingModel;
  firm: FirmModel;
  engagementCountDetails: EngCountModel;
  firmEngCount: EngCountModel;
  selectedPlan: ProductModel;
  aclModel: AclModel = new AclModel();
  searchForm: UntypedFormGroup;
  private subscriptions: Subscription = new Subscription();

  private readonly firmSubject: Subscription;
  private readonly billingSubject: Subscription;
  private readonly unSubscribe$: Subject<void> = new Subject<void>();

  constructor(private apiservice: ApiService, private toastr: ToastrService, private sharedService: SharedService,
    public router: Router, public dialog: MatDialog, private spinner: NgxSpinnerService,
    private tbService: IntegrationService, private engApiService: EngApiService,
    private firmService: FirmService, private billingService: BillingService, public aclservice: AclService, private fb: UntypedFormBuilder) {

    this.firmSubject = this.firmService.subject.subscribe(firm => {
      if (firm) {
        this.firm = firm;
        this.billingService.subject.subscribe(billing => {
          if (billing) {
            this.billing = billing;
            this.selectedPlan = StorageService.applicationModel.plan;
          }
        });
      }
    });

    this.searchForm = this.fb.group({
      'searchInput': new UntypedFormControl()
    });
    this.subscriptions.add(this.aclservice.subject.subscribe(res => {
      if (res) {
        this.aclModel = res;
      }
    }));
  }

  ngOnInit() {
    this.loggedInUserData = JSON.parse((localStorage.getItem('userDetails')));
    if (this.loggedInUserData && this.loggedInUserData.useracctid) {
      this.auditorId = this.loggedInUserData.useracctid;
    }
    this.getEngagementCountDetail();

    this.updatedEngagementActivities = this.sharedService.refreshClientListCount.subscribe(response => {
      if (response) {
        this.getEngagementCountDetail();
      }
    });
    this.updatedEngStatusDetails = this.sharedService.clientEngStatusCount.subscribe(response => {
      this.updatedEngStatusObj = response;
      this.getEngagementCountDetail();
    });
    this.completedArchiveEngSub = this.sharedService.getArchivedCompletedEngs.subscribe(res => {
      this.completedArchiveEngSelected = res;
    });
    this.updateSelectedEngObjSub = this.sharedService.selectedEngObjSub.subscribe(res => {
      this.updateSelectedEngObj = res;
      let completedEngs = [];
      let archivedEngs = [];
      if (this.updateSelectedEngObj) {
        if (this.updateSelectedEngObj.length > 0) {
          this.updateSelectedEngObj.forEach(x => {
            if (x.statusid == 8) {
              completedEngs.push(x);
            }
            if (x.statusid == 19) {
              archivedEngs.push(x);
            }
          });
          if (completedEngs.length > 0 || archivedEngs.length > 0) {
            //checkBoxStatus
            if (this.completedArchiveEngSelected && this.completedArchiveEngSelected.type == 'single') {
              if (this.completedArchiveEngSelected.checkBoxStatus) {
                this.openEngInfoDialog();
              }
            } else {
              this.openEngInfoDialog();
            }
          }
          this.selectedEngCount = completedEngs.length == 0 && archivedEngs.length == 0 && this.updateSelectedEngObj.length > 1 ? true : false;
        } else {
          this.selectedEngCount = false;
        }
      } else {
        this.selectedEngCount = false;
      }
    });

    this.selectClientForm = new UntypedFormGroup({
      clientData: new UntypedFormControl('', Validators.required),
      engagementType: new UntypedFormControl('', Validators.required)
    });
    this.permissionsSub = this.sharedService.accessPermissionsSubject.subscribe(reponse => {
      if (reponse) {
        this.accessPermissions = reponse;
        this.isEngagementAccess = this.accessPermissions.viewengagements;
      }
    });
  }

  ngAfterViewInit() {
    this.clientData = JSON.parse((localStorage.getItem('selectedClientDetails')));
    this.createEngagementDisabled = this.clientData && this.clientData.clientFilter == 4;
  }

  getEngagementCountDetail() {
    this.spinner.show();
    this.apiservice.getEngagementCountDetail().subscribe(response => {
      if (response) {
        this.engagementCountDetails = response;
        if (this.router.url == '/dashboard/members/client-engagements') {
          this.clientEngData = JSON.parse(localStorage.getItem('clientListObj'));
          this.clientStatusId = this.clientEngData.statusId;
          if (this.clientEngData && this.updatedEngStatusObj) {
            this.totalEngagement = this.updatedEngStatusObj.totalengagement;
            this.current = this.updatedEngStatusObj.current;
            this.completed = this.updatedEngStatusObj.completed;
            this.archived = this.updatedEngStatusObj.archived;
          }
        } else {
          this.totalEngagement = this.engagementCountDetails.totalEngagement;
          this.current = this.engagementCountDetails.current;
          this.completed = this.engagementCountDetails.completed;
          this.archived = this.engagementCountDetails.archived;
        }
      } else {
        this.toastr.error('error occurred');
        this.isCreateEngagementDisable = false;
      }
    }).add(() => {
      this.spinner.hide();
    });

    this.firmService.getEngCount(true).subscribe(res => {
      if (res) {
        this.firmEngCount = res;
      }
    });
  }

  engagementListSearch(filterValue: string) {
    this.sharedService.gridDataEngage.filter = filterValue.trim().toLowerCase();
  }

  createNewEngagement(val) {
    if (this.billing.isPaused) {
      return;
    }
    this.selectedValue = val;
    localStorage.removeItem('selectedEngagementObj');
    // checking eng count validation
    if (StorageService.applicationModel.plan.hasEngLimit) {
      const totalEngCount = (this.billing?.isOldFirm && StorageService.applicationModel.plan?.engLimitOld) ?
        StorageService.applicationModel.plan.engLimitOld - this.firmEngCount.totalEngagement :
        StorageService.applicationModel.plan.engLimit - this.firmEngCount.totalEngagement;
      if (this.billing.isOnTrial && totalEngCount === 1) {
        this.openFreeEngagementDialog();
        return;
      }
      if (totalEngCount < 1) {
        this.billingService.upgradePlanDialog('eng_limit', null, (this.billing.isOnTrial ? StorageService.applicationModel.plan.name : StorageService.applicationModel.plan.name + ' Plan'));
        return;
      }
    }
    if (val === 'allEngagements') {
      this.openClientDetailsDialog();
      this.selectClientForm.reset();
    } else if (val === 'clientEngagements') {
      this.selectClientForm.reset();
      const selectedClientDetails = JSON.parse(localStorage.getItem('selectedClientDetails'));
      this.selectClientForm.controls['clientData'].setValue(selectedClientDetails.clientfirmid ? selectedClientDetails.clientfirmid : selectedClientDetails.clientfirmId);
      this.selectClientForm.get('clientData').disable();
      this.openClientDetailsDialog();
    }
  }

  onYesCreateEngagement() {
    if (this.selectedValue === 'allEngagements') {
      this.openClientDetailsDialog();
      this.selectClientForm.reset();
    } else if (this.selectedValue === 'clientEngagements') {
      let selectedclientDetails = JSON.parse(localStorage.getItem('selectedClientDetails'));
      this.selectClientForm.controls['clientData'].setValue(selectedclientDetails.clientfirmid ? selectedclientDetails.clientfirmid : selectedclientDetails.clientfirmId);
      this.selectClientForm.get('clientData').disable();
      this.openClientDetailsDialog();
    }
  }

  deleteEngDailog() {
    this.isMultiEngDialog = true;
    this.sharedService.openMultiEngDailog.next(this.isMultiEngDialog);

  }

  openClientDetailsDialog() {
    const dialogRef = this.dialog.open(this.showAllClientDetails, {
      disableClose: true,
      panelClass: 'details-container'
    });
    dialogRef.afterClosed().subscribe();
    this.getFirmClientDetails();
    this.getEngagementTypes();
  }

  openEngInfoDialog() {
    const dialogRef = this.dialog.open(this.engInfoDialog, { disableClose: true, panelClass: 'engInfoDialog-container' });
    dialogRef.afterClosed().subscribe();
  }

  redirectToEngagement() {
    this.router.navigate(['/dashboard/engagement/create-engagement/' + 1]);
  }

  //get client information
  getindividualclient() {
    var getclientinfo = {
      'clientfirmid': 743,
      'loginid': 2021
    };
    var obj = {
      'procedureName': 'getindividualclient',
      'inputParameters': JSON.stringify(getclientinfo),
      'inputEnc': generateId[Math.floor(Math.random() * generateId.length)]
    };
    this.spinner.show();
    this.apiservice.getData(obj).subscribe(resp => {
      if (resp) {
        var clientdetails = JSON.parse(resp);
      }
    });
    this.spinner.show();
  }

  //getting firm client name details
  getFirmClientDetails() {
    let inputData = { 'loginid': this.loggedInUserData.useracctid };
    let inputString = JSON.stringify(inputData);
    let data = {
      'procedureName': 'getclientnameforengagement',
      'inputParameters': inputString
    };
    this.spinner.show();
    this.apiservice.getData(data).subscribe(response => {
      if (response) {
        let data = JSON.parse(response);
        this.firmClients = data.firmcleint;
      }
      this.spinner.hide();
    }, error => {
      //localStorage.removeItem("newEngagement");
      this.spinner.hide();
    });
  }

  clientDetailsChange(event) {
    this.getClientDetails(event.clientfirmid);
  }

  getClientDetails(clientfirmId) {
    let inputData = { 'clientfirmid': clientfirmId, 'loginid': this.loggedInUserData.useracctid };
    let inputString = JSON.stringify(inputData);
    let data = {
      'procedureName': 'getindividualclient',
      'inputParameters': inputString,
      'inputEnc': generateId[Math.floor(Math.random() * generateId.length)]
    };
    this.spinner.show();
    this.apiservice.getData(data).subscribe(response => {

      if (response) {
        let data = JSON.parse(response);
        if (data) {
          this.clientInformation = data.clientdata;
          localStorage.setItem('selectedClientDetails', JSON.stringify(this.clientInformation));
        }
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }

  openFreeEngagementDialog() {
    const dialogRef = this.dialog.open(this.freeEngagementDialog, {});
    dialogRef.afterClosed().subscribe();
  }

  /* Last life cycle */
  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
    this.updatedEngagementActivities.unsubscribe();
    this.updatedEngStatusDetails.unsubscribe();
    this.updateSelectedEngObjSub.unsubscribe();
    this.completedArchiveEngSub.unsubscribe();
    if (this.permissionsSub) {
      this.permissionsSub.unsubscribe();
    }

    if (this.billingSubject) {
      this.billingSubject.unsubscribe();
    }

    if (this.firmSubject) {
      this.firmSubject.unsubscribe();
    }
    this.subscriptions && this.subscriptions.unsubscribe();
  }

  clearSearch(event: boolean) {
    if (event) {
      this.searchForm.controls['searchInput'].reset();
    }
  }

  selectedEngagementType(event) {
    localStorage.setItem('selectedEngagementType', JSON.stringify(event));
  }

  resetChanges() {
    this.selectClientForm.reset();
  }

  getEngagementTypes() {
    this.spinner.show();
    this.engApiService.getEngagementTypes().subscribe((response: any) => {
      if (response.status === 200) {
        if (this.billing?.hasReview) {
          this.engagementTypes = response?.data;
        } else {
          this.engagementTypes = response?.data.filter(e => e.id !== 2);
        }
      } else {
        this.engagementTypes = [];
        this.toastr.error(response?.errors[0]);
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
    });
  }
}
